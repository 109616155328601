<template>
    <div v-if="selected">
        <component
            :is="componentName"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Watch} from 'vue-property-decorator';
import {ManagementOption} from '@/models';
import ManagementEnumTypes from '@/views/master-data-management-v2/sub-pages/management-enum-types/management-enum-types.vue';
import {MANAGEMENT_OPTIONS} from '@/utils';
import ManagementEnumTypesEdit from '@/views/master-data-management-v2/sub-pages/management-enum-types/management-enum-types-edit.vue';
import ManagementEnumTypesNew from '@/views/master-data-management-v2/sub-pages/management-enum-types/management-enum-types-new.vue';
import {Dictionary} from 'vue-router/types/router';
import ManagementOperationModes from '@/views/master-data-management-v2/sub-pages/management-operation-modes/management-operation-modes.vue';
import ManagementOperationModesEdit from '@/views/master-data-management-v2/sub-pages/management-operation-modes/management-operation-modes-edit.vue';
import ManagementBusinessPartners from '@/views/master-data-management-v2/sub-pages/management-business-partners/management-business-partners.vue';
import ManagementBusinessPartnersEdit
    from '@/views/master-data-management-v2/sub-pages/management-business-partners/management-business-partners-edit.vue';
import ManagementBusinessPartnersNew
    from '@/views/master-data-management-v2/sub-pages/management-business-partners/management-business-partners-new.vue';
import ManagementEtsUnit from '@/views/master-data-management-v2/sub-pages/management-ets-unit/management-ets-unit.vue';
import ManagementEtsUnitNew from '@/views/master-data-management-v2/sub-pages/management-ets-unit/management-ets-unit-new.vue';
import ManagementEtsUnitEdit from '@/views/master-data-management-v2/sub-pages/management-ets-unit/management-ets-unit-edit.vue';
import ManagementMaterialGroup from '@/views/master-data-management-v2/sub-pages/management-material-group/management-material-group.vue';
import ManagementMaterials from '@/views/master-data-management-v2/sub-pages/management-materials/management-materials.vue';
import ManagementReservoirs from '@/views/master-data-management-v2/sub-pages/management-reservoirs/management-reservoirs.vue';
import ManagementMaterialsNew from '@/views/master-data-management-v2/sub-pages/management-materials/management-materials-new.vue';
import ManagementMaterialsEdit from '@/views/master-data-management-v2/sub-pages/management-materials/management-materials-edit.vue';
import ManagementMaterialGroupNew from '@/views/master-data-management-v2/sub-pages/management-material-group/management-material-group-new.vue';
import ManagementMaterialGroupEdit from '@/views/master-data-management-v2/sub-pages/management-material-group/management-material-group-edit.vue';
import ManagementReservoirsEdit from '@/views/master-data-management-v2/sub-pages/management-reservoirs/management-reservoirs-edit.vue';
import ManagementReservoirsNew from '@/views/master-data-management-v2/sub-pages/management-reservoirs/management-reservoirs-new.vue';
import ManagementOperationModesNew from '@/views/master-data-management-v2/sub-pages/management-operation-modes/management-operation-modes-new.vue';

@Component({
    name: 'master-data-management-v2',
    components: {
        ManagementEnumTypes,
        ManagementEnumTypesEdit,
        ManagementEnumTypesNew,
        ManagementOperationModes,
        ManagementOperationModesEdit,
        ManagementOperationModesNew,
        ManagementBusinessPartners,
        ManagementBusinessPartnersEdit,
        ManagementBusinessPartnersNew,
        ManagementEtsUnit,
        ManagementEtsUnitNew,
        ManagementEtsUnitEdit,
        ManagementMaterials,
        ManagementMaterialsNew,
        ManagementMaterialsEdit,
        ManagementMaterialGroup,
        ManagementMaterialGroupNew,
        ManagementMaterialGroupEdit,
        ManagementReservoirs,
        ManagementReservoirsNew,
        ManagementReservoirsEdit,
    },
})
export default class MasterDataManagementV2 extends Vue {
    private options: ManagementOption[] = MANAGEMENT_OPTIONS;
    private componentName = '';
    private selected: ManagementOption = {
        name: '',
        idTypeSid: null,
        componentName: '',
        icon: '',
    };

    @Watch('$route.path')
    private onPathChange(newVal: string, oldVal: string): void {
        if (newVal && newVal !== oldVal) {
            this.changeComponent();
        }
    }

    private mounted(): void {
        this.changeComponent();
    }

    private changeComponent(): void {
        if (this.$route.query['edit'] || this.$route.query['add']) {
            this.goToEditOrAdd(this.$route.name);
        } else {
            const component = this.options.find((value: ManagementOption) => this.$route.name?.includes(value.componentName));
            this.selectOption(component ?? this.options[0]);
        }

    }

    private selectOption(option?: ManagementOption | null): void {
        if (!option) {
            return;
        }
        this.selected = option;
        this.componentName = this.selected.componentName;
        if (this.$route.path === `/master-data-management-v2/${option.componentName}`) {
            return;
        }
        this.$router.push({
            name: `${this.selected.componentName}`,
            path: `/master-data-management-v2/${this.selected.componentName}`,
        });
    }


    private goToEditOrAdd(name?: string | null | undefined): void {
        if (!name) {
            return;
        }
        this.componentName = name;
        if (this.$router.currentRoute.path.includes('edit') || this.$router.currentRoute.path.includes('new')) {
            return;
        }
        this.$router.push({
            name: `${name}`,
            query: {
                sid: this.$route.query.edit ?? this.$route.query.sid,
                name: this.$route.query.name,
                add: this.$route.query.add,
                backPage: this.$route.query.backPage,
            },
        });
    }

    @Watch('$route.query')
    private onQueryChanged(data: Dictionary<string | Array<string | null> | null | undefined>): void {
        if (data.edit !== undefined) {
            this.changeComponent();
        }
        if (data.add !== undefined) {
            this.changeComponent();
        }
    }
}
</script>


