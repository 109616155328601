<template>
    <PuiSidePanel
        :open.sync="openSide"
        :title="title"
        trigger-position="right"
        style="z-index: 1"
    >
        <component :is="component"/>
    </PuiSidePanel>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import PowerPlantsTree from '@/components/tree/power-plants-tree.vue';
import {Watch} from 'vue-property-decorator';
import SideBarDefault from '@/components/sidebar/sidebars/side-bar-default.vue';
import SideBarMasterDataManagement from '@/components/sidebar/sidebars/side-bar-master-data-management.vue';
import SideBarSinergyLifeLocation from '@/components/sidebar/sidebars/side-bar-sinergy-life-location.vue';

@Component({
    name: 'side-bar',
    components: {
        PowerPlantsTree,
        SideBarDefault,
        SideBarMasterDataManagement,
        SideBarSinergyLifeLocation,
    },
})
export default class SideBar extends Vue {
    private openSide = false;

    private component = SideBarDefault.name;
    private title = '';

    public mounted(): void {
        this.change(this.$route.name ?? '');
    }

    @Watch('$route.name')
    private onChangeRoute(value: string): void {
        this.change(value);
    }

    private change(value: string): void {
        this.openSide = false;
        switch (value) {
            case 'sinergy-life-location':
                this.component = 'side-bar-sinergy-life-location';
                this.title = 'Power Plants';
                break;
            case 'master-data-management-v2':
            case 'management-enum-types':
            case 'management-operation-modes':
            case 'management-business-partners':
            case 'management-ets-unit':
            case 'management-materials':
            case 'management-material-group':
            case 'management-reservoirs':
                this.component = 'side-bar-master-data-management';
                this.title = this.$t('management').toString();
                break;
            default:
                this.component = SideBarDefault.name;
                this.title = '';
        }
    }
}
</script>


