<template>
    <pui-lightbox
        class="modal"
        ref="productAddEdit"
        :default-header-label="modalTitle"
        :show-lightbox-close-icon="false"
        :default-footer-confirm-label="$t('save')"
        :default-footer-cancel-label="$t('cancel')"
        :on-confirm-callback="save"
        :fit-content="true"
        :on-close-callback="closeModal"
    >
        <template #default>
            <validation-observer
                ref="observer"
                v-slot="{ validated }"
            >
                <div class="fit-modal-width" />
                <pui-form-inline-notification
                    v-if="errorMessage"
                    :title="errorMessage"
                    text=""
                />
                <div v-pui-form-grid-row>
                    <select-box
                        v-pui-form-grid-column="s12m6ColSize"
                        :name="$t('operationModeProducts')"
                        :rules="validationRules.productSid"
                        :show-invalid="validated"
                        :select-box-options="products"
                        :input-value="product.productSid"
                        @change="value => product.productSid = value"
                    />
                </div>
                <div v-pui-form-grid-row>
                    <textarea-input
                        v-pui-form-grid-column="12"
                        :name="$t('comments')"
                        :rules="validationRules.lastUpdateComment"
                        :show-invalid="validated"
                        :input-value="product.lastUpdateComment"
                        @change="value => product.lastUpdateComment = value"
                    />
                </div>
            </validation-observer>
        </template>
    </pui-lightbox>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import DateInput from '@/components/shared/inputs-with-validations/date-input.vue';
import TextInput from '@/components/shared/inputs-with-validations/text-input.vue';
import SelectBox from '@/components/shared/inputs-with-validations/select-box.vue';
import TextareaInput from '@/components/shared/inputs-with-validations/textarea-input.vue';
import { OperationModeProduct, SelectOption } from '@/models';
import { namespace } from 'vuex-class';
import { productService } from '@/services';
import { s12m6ColSize } from '@/components/asset-master-data/constants';

const hierarchyAssetsModule = namespace('HierarchyAssetsModule');

@Component({
    name: 'regional-unit-add-edit',
    components: {
        DateInput,
        TextInput,
        SelectBox,
        TextareaInput,
    },
})

export default class ProductAddEdit extends Vue {
    @hierarchyAssetsModule.Action
    private loadProducts!: () => Promise<void>;

    @hierarchyAssetsModule.State
    private products!: SelectOption[];

    private product = new OperationModeProduct();
    private isEditMode = false;
    private errorMessage: string | undefined = '';
    private initialProductId!: number;
    private s12m6ColSize = s12m6ColSize;

    public open(product?: OperationModeProduct, isEditMode = false): void {
        this.loadProducts();
        this.isEditMode = isEditMode;
        this.product = new OperationModeProduct(product);
        if (this.isEditMode) {
            this.initialProductId = this.product.productSid;
        }
        (this.$refs.productAddEdit as any)?.open();
    }

    public closeModal(): void {
        this.product = new OperationModeProduct();
        this.isEditMode = false;
        this.errorMessage = '';
        (this.$refs.productAddEdit as any)?.close();
    }

    private get modalTitle(): string {
        return this.isEditMode
            ? this.$t('editProduct') as string
            : this.$t('addProduct') as string;
    }

    private get operationModeId(): number {
        return Number(this.$route.query.edit);
    }

    private async save(): Promise<void> {
        if (!await this.isValidForm()) {
            return;
        }

        const [isSuccessfulResult, error] = this.isEditMode
            ? await productService.updateOperationModeProduct(this.product, this.initialProductId)
            : await productService.addOperationModeProduct(this.product);

        if (isSuccessfulResult) {
            this.$emit('reload-products');
            this.closeModal();
        } else {
            this.errorMessage = error;
        }
    }

    private async isValidForm(): Promise<boolean> {
        return await (this.$refs?.observer as any)?.validate();
    }

    private get validationRules(): any {
        return {
            productSid: { required: true },
            lastUpdateComment: { max: 1000 },
        };
    }
}

</script>

<style scoped lang="less">
@import "~@/components/asset-master-data/common-assets/modal-style.less";

</style>
