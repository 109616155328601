<template>
    <div class="identification-container">
        <validation-observer ref="validationObserver">
            <div class="identification">
                <div v-if="data.etsId !== 0" class="flex-row manage-input mt-3">
                    <label class="mr-2 default-label" for="etsUnitId">{{ $t('etsId') }}:</label>
                    <input class="default-input" v-model="data.etsId" :placeholder="$t('etsId')" id="etsUnitId" disabled />
                </div>
                <validation-provider
                    tag="div"
                    class="manage-input mt-3"
                    name="shortName"
                    rules="required"
                    #default="{ errors, failed }"
                >
                    <div class="flex-row manage-input mt-3">
                        <label class="mr-2 default-label" for="identifier">{{ $t('shortName') }}*:</label>
                        <input class="default-input" v-model="data.shortName" :placeholder="$t('shortName')" id="shortName" maxlength="60"
                            :class="{ 'invalid': failed }"
                        />
                    </div>
                    <error-list :errors="errors" />
                </validation-provider>
                <validation-provider
                    tag="div"
                    class="manage-input mt-3"
                    name="name"
                    rules="required"
                    #default="{ errors, failed }"
                >
                    <div class="flex-row manage-input mt-3">
                        <label class="mr-2 default-label" for="name">{{ $t('name') }}*:</label>
                        <input class="default-input" v-model="data.name" :placeholder="$t('name')" id="name" maxlength="120"
                            :class="{ 'invalid': failed }"
                        />
                    </div>
                    <error-list :errors="errors" />
                </validation-provider>
                <div class="flex-row manage-input mt-3">
                    <label class="mr-2 default-label" for="referenceNumber">{{ $t('referenceNumber') }}:</label>
                    <input class="default-input" v-model="data.referenceNumber" :placeholder="$t('referenceNumber')" id="referenceNumber" maxlength="60" />
                </div>
                <div class="flex-row manage-input mt-3">
                    <label class="mr-2 default-label" for="registerName">{{ $t('registerName') }}:</label>
                    <input class="default-input" v-model="data.registerName" :placeholder="$t('registerName')" id="registerName" maxlength="120" />
                </div>
                <validation-provider
                    v-if="data.etsId"
                    tag="div"
                    class="manage-input mt-3"
                    name="comments"
                    rules="max:1000"
                    #default="{ errors, failed }"
                >
                    <div class="flex-row manage-input mt-3">
                        <label class="mr-2 default-label" for="lastUpdateComment">{{ $t('comments') }}:</label>
                        <textarea
                            class="default-textarea"
                            maxlength="1000"
                            v-model="data.lastUpdateComment"
                            :placeholder="$t('comments')"
                            id="lastUpdateComment"
                            :class="{ 'invalid': failed }"
                            rows="4"></textarea>
                    </div>
                    <error-list :errors="errors" />
                </validation-provider>
                <div class="flex-row mt-3">
                    <checkbox-input
                        v-model="data.isDeleted"
                        :label="$t('markAsDeleted')" />
                </div>
                <last-updated v-if="data.etsId" :data="data" />
                <error-list :errors="invalidMessages" />
            </div>
            <div class="members-footer">
                <adam-button
                    v-if="hasMasterDataAdmin"
                    class="ml-1"
                    @click="save"
                    secondary>
                    {{ $t('save') }}
                </adam-button>
            </div>
        </validation-observer>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Mixins, Ref } from 'vue-property-decorator';
import { EventBus, extractErrorsFromResponse, InfiniteScrollingHelper } from '@/utils';
import { EtsUnit } from '@/models';
import { ETSService } from '@/services';
import { ValidationObserver } from 'vee-validate';
import CheckboxInput from '@/components/checkbox-input/checkbox-input.vue';
import ErrorList from '@/components/error-list/error-list.vue';
import LastUpdated from '@/components/last-updated/last-updated.vue';
import ComponentSecurity from '@/mixins/component-security';

@Component({
    name: 'ets-units-identification',
    components: {
        checkboxInput: CheckboxInput,
        errorList: ErrorList,
        lastUpdated: LastUpdated,
    },
})
export default class ETSUnitIdentification extends Mixins(ComponentSecurity) {
    @Ref()
    private readonly validationObserver!: InstanceType<typeof ValidationObserver>;
    /*
     *  ETS units for which the identification tab from editing screen is displayed.
    */
    @Prop({ required: true })
    private data!: EtsUnit;

    private etsService: ETSService = new ETSService();

    private formModel: EtsUnit = { ...this.data };
    private errorList: string[] = [];
    private invalidMessages: string[] = [];
    private comments = '';

    public async beforeLeave(): Promise<boolean> {
        if (!this.validationObserver.flags.dirty) {
            return true;
        }

        try {
            await this.$dialog
                .confirm(
                    { body: this.$t('areYouSureLeaveUnsaved').toString() },
                    { view: 'confirm' });
            return true;
        } catch (error: any) {
            return Promise.resolve(false);
        }
    }

    private async mounted(): Promise<void> {
        EventBus.$on(EventBus.DETAIL.CLOSE, this.closeDetailsView);
        this.validationObserver?.reset();
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.DETAIL.CLOSE);
    }

    private checkInput(): void {
        this.errorList = [];
        this.invalidMessages = [];
    }

    private isInvalid(invalidKey: string): boolean {
        return this.errorList.indexOf(invalidKey) > -1;
    }

    private deleteInputFieldError(invalidKey: string): any {
        this.errorList = this.errorList.filter((error: string) => error !== invalidKey);
    }

    private async closeDetailsView(): Promise<void> {
        try {
            const canLeave = await this.beforeLeave();
            /**
             * Fired when the user wishes to exit the details view
             */
            if (canLeave) {
                this.$emit('close');
            }
        } catch (error: any) {
            // action canceled by user
        }
    }

    private async save(): Promise<void> {
        this.invalidMessages = [];
        const isValid = await this.validationObserver.validate();

        if (!isValid) {
            await this.$nextTick();
            InfiniteScrollingHelper.scrollToFirstInvalidElement('.invalid');
            return;
        }
        let message = 'errorAddingEtsUnit';
        this.$store.commit('loading');
        this.formModel = {...this.data};
        try {
            if (this.formModel.etsId > 0) {
                message = 'errorAddingEtsUnit';
                const { result } = await this.etsService.edit(this.formModel);
                this.formModel = { ...result };
                this.$emit('save', this.formModel, false, null);
            } else {
                const addedAsset = (await this.etsService.create(this.formModel)).result;
                this.$emit('save', addedAsset, true, null);
            }
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            this.validationObserver?.reset();
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, message);
            if (!err.response || !err.response.data) {
                throw err;
            }
            this.invalidMessages = extractErrorsFromResponse(err.response.data);
            if (this.invalidMessages.length) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.error-list__message');
                });
            }
        } finally {
            this.$store.commit('loading');
        }
        this.data.lastUpdateComment = '';
    }
}

</script>

<style scoped lang="less">
@import "~@/variables.less";
.identification-container {
    .identification {
        label {
            margin-top: 1rem;
            min-width: fit-content;
        }
        input, textarea {
            width: 100%;
        }
    }
}
</style>
