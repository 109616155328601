<template>
    <power-plants-tree/>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import PowerPlantsTree from '@/components/tree/power-plants-tree.vue';

@Component({
    name: 'side-bar-sinergy-life-location',
    components: {PowerPlantsTree},
})
export default class SideBarSinergyLifeLocation extends Vue {
}
</script>


