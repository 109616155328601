import {IdentificationOperationModesForm} from '@/models/form/identification-operation-modes-form';
import {MIN_DATE} from '@/utils';
import {OperationModesModel} from '@/models/operation-modes-model';
import {format} from 'date-fns';
import {ONLY_DATE_FORMAT} from '@/utils/constants';

// tslint:disable-next-line:max-line-length
export const identificationOperationModesForm = (data?: OperationModesModel): IdentificationOperationModesForm => {
    return {
        // BASE DATA FORM
        validFrom: {
            value: data?.validFrom ? format(new Date(data?.validFrom), ONLY_DATE_FORMAT) : '',
            isValid: true,
            isDate: true,
            validator: (value: string) => new Date(value) > MIN_DATE,
        },
        validTo: {
            value: data?.validTo ? format(new Date(data?.validTo), ONLY_DATE_FORMAT) : '',
            isValid: true,
            isDate: true,
            validator: (value: string) => new Date(value) > MIN_DATE,
        },
        operationModeSid: {
            value: data?.operationModeSid,
            isValid: true,
        },
        unitSid: {
            value: data?.unitSid,
            isValid: true,
        },
        unitSapType: {
            value: data?.unitSapType,
            isValid: true,
        },
        operationModeName: {
            value: data?.operationModeName ?? '',
            isValid: true,
            validator: (value: string) => value.length > 0 && value.length <= 50,
        },
        enumOpModeTypeSid: {
            value: data?.enumOpModeTypeSid ?? -1,
            isValid: true,
            isDropDown: true,
            validator: (value: number) => value.toString() !== '-1',
        },
        enumPriEnergySid: {
            value: data?.enumPriEnergySid ?? -1,
            isValid: true,
            isDropDown: true,
            validator: (value: number) => value.toString() !== '-1',
        },
        enumSecEnergySid: {
            value: data?.enumSecEnergySid ?? -1,
            isDropDown: true,
            isValid: true,
        },
        enumConsolidationSid: {
            value: data?.enumConsolidationSid ?? -1,
            isDropDown: true,
            isValid: true,
        },
        isStdOperationMode: {
            value: data?.isStdOperationMode ?? false,
            isValid: true,
        },
        isRedispatchAvailable: {
            value: data?.isRedispatchAvailable ?? false,
            isValid: true,
        },
        isBlackstart: {
            value: data?.isBlackstart ?? false,
            isValid: true,
        },
        isDeleted: {
            value: data?.isDeleted ?? false,
            isValid: true,
        },
        isActive: {
            value: data?.isActive ?? false,
            isValid: true,
        },
        // PERFORMANCE DATA FORM
        grossCapacity: {
            value: data?.grossCapacity,
            isValid: true,
        },
        netCapacity: {
            value: data?.netCapacity,
            isValid: true,
        },
        accountingNetCapacity: {
            value: data?.accountingNetCapacity,
            isValid: true,
        },
        netMinCapacityTec: {
            value: data?.netMinCapacityTec,
            isValid: true,
        },
        netCapacityContract: {
            value: data?.netCapacityContract,
            isValid: true,
        },
        thermalCapacityChp: {
            value: data?.thermalCapacityChp,
            isValid: true,
        },
        thermalCapacity: {
            value: data?.thermalCapacity,
            isValid: true,
        },
        netMinThermalCapacity: {
            value: data?.netMinThermalCapacity,
            isValid: true,
        },
        firingThermalCapacity: {
            value: data?.firingThermalCapacity,
            isValid: true,
        },
        primaryReservePos: {
            value: data?.primaryReservePos,
            isValid: true,
        },
        primaryReserveNeg: {
            value: data?.primaryReserveNeg,
            isValid: true,
        },
        secondaryReservePos: {
            value: data?.secondaryReservePos,
            isValid: true,
        },
        secondaryReserveNeg: {
            value: data?.secondaryReserveNeg,
            isValid: true,
        },
        minuteReservePos: {
            value: data?.minuteReservePos,
            isValid: true,
        },
        minuteReserveNeg: {
            value: data?.minuteReserveNeg,
            isValid: true,
        },
        maxSpeedPowerInc: {
            value: data?.maxSpeedPowerInc,
            isValid: true,
        },
        startUpRampGradient: {
            value: data?.startUpRampGradient,
            isValid: true,
        },
        startUpRamp: {
            value: data?.startUpRamp,
            isValid: true,
        },
        startUpRampGradZ2: {
            value: data?.startUpRampGradZ2,
            isValid: true,
        },
        startUpRampGradZ3: {
            value: data?.startUpRampGradZ3,
            isValid: true,
        },
        startUpRampGradZ4: {
            value: data?.startUpRampGradZ4,
            isValid: true,
        },
        maxSpeedPowerDec: {
            value: data?.maxSpeedPowerDec,
            isValid: true,
        },
        shutdownRampGradient: {
            value: data?.shutdownRampGradient,
            isValid: true,
        },
        shutdownRamp: {
            value: data?.shutdownRamp,
            isValid: true,
        },
        shutdownRampGradZ2: {
            value: data?.shutdownRampGradZ2,
            isValid: true,
        },
        shutdownRampGradZ3: {
            value: data?.shutdownRampGradZ3,
            isValid: true,
        },
        shutdownRampGradZ4: {
            value: data?.shutdownRampGradZ4,
            isValid: true,
        },
        downtimeMin: {
            value: data?.downtimeMin,
            isValid: true,
        },
        minOperationTime: {
            value: data?.minOperationTime,
            isValid: true,
        },
        startuptimeMin: {
            value: data?.startuptimeMin,
            isValid: true,
        },
        startUpTimeConditionCold: {
            value: data?.startUpTimeConditionCold,
            isValid: true,
        },
        startUpTimeConditionWarm: {
            value: data?.startUpTimeConditionWarm,
            isValid: true,
        },
        startUpTimePminCold: {
            value: data?.startUpTimePminCold,
            isValid: true,
        },
        startUpTimePminWarm: {
            value: data?.startUpTimePminWarm,
            isValid: true,
        },
        durationUpMin: {
            value: data?.durationUpMin,
            isValid: true,
        },
        durationDownMin: {
            value: data?.durationDownMin,
            isValid: true,
        },
        shutdownTimePminGrid: {
            value: data?.shutdownTimePminGrid,
            isValid: true,
        },
        isExternalOperation: {
            value: data?.isExternalOperation ?? false,
            isValid: true,
        },
        efficiency: {
            value: data?.efficiency,
            isValid: true,
        },
        flowRateIntoStoreMax: {
            value: data?.flowRateIntoStoreMax,
            isValid: true,
        },
        flowRateOutOfStoreMax: {
            value: data?.flowRateOutOfStoreMax,
            isValid: true,
        },
        hourlyLossFactor: {
            value: data?.hourlyLossFactor,
            isValid: true,
        },
        storageLevelMax: {
            value: data?.storageLevelMax,
            isValid: true,
        },
        storageLevelStart: {
            value: data?.storageLevelStart,
            isValid: true,
        },
        forwardCapacity: {
            value: data?.forwardCapacity,
            isValid: true,
        },
        reverseCapacity: {
            value: data?.reverseCapacity,
            isValid: true,
        },
        incidentPenaltyThreshold: {
            value: data?.incidentPenaltyThreshold,
            isValid: true,
        },
        mwhLossOrMwCapacityThreshold: {
            value: data?.mwhLossOrMwCapacityThreshold,
            isValid: true,
        },
    };
};

