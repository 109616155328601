export enum IdTypeSid {
    NETWORK_LEVEL = 1350,
    NETWORK_CONNECTION_POINT = 1340,
    CONNECTION_AREA = 1400,
    ENUM_SU_MATERIAL_GROUP = 1450,
    ENUM_MATERIAL_TYPE = 1440,
    DISTRIBUTION_TYPE = 1460,
    AGG_STATE = 1200,
    EU_ETS = 1250,
    DEHST = 1240,
    QUALITY = 1390,
    HOME_COUNTRY = 1310,
    MINE = 1470,
    UNITS = 1320,
    TIME_ZONE = 200,
    EXTERNAL_SYSTEM = 300,
    UNIT_TYPE = 500,
    TECHNOLOGY = 1210,
    OPERATION_STATUS_TYPE = 1220,
    OPERATION_STATUS_CHANGE_REASON = 1225,
    STATE = 1230,
    OPERATION_MODE_TYPE = 1260,
    FLEET = 1270,
    CONSOLIDATION = 1285,
    POWER_PLANT_TYPE = 1290,
    PLANT_GROUP = 1300,
    COUNTRY = 1310,
    MSB_TYPE = 1330,
    ORGANIZATION_LIST = 1360,
    OPERATION_MODE_ENERGY_TYPE = 1370,
    REGION = 1410,
    SAP_EC_TYPE = 1430,
    KKS_CATEGORY = 1480,
    EQUIPMENT_CLASS_TYPE = 1490,
    RIVER = 1540,
    RIVER_GROUP = 1550,
    REGIONAL_UNIT = 1420,
    PRODUCT = 1380,
    INCIDENT_PENALTY_TYPE = 900,
    COOLING_SYSTEM = 1700,
    TRANSFORMER_TYPE = 1710,
    TRANSFORMER_MANUFACTURER = 1720,
}
