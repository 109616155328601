import { ApiResponse, ListResponse, PagedResponse } from '@/models';
import { SinergyLifeData, SynergyLifeQuery } from '@/models/sinergy-life-data';
import { EditSyngeryRequest, SinergyLifeEditData } from '@/models/sinergy-life-edit-data';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
import { generateQuery } from '@/utils/api-helper';

export class SynergyLifeService {
    private api = new SdkGenericApiHelper('synergi-life-mappings');

    public async getTable(filter: SynergyLifeQuery): Promise<ApiResponse<PagedResponse<SinergyLifeData>>> {
        const query = generateQuery(filter as any);
        return (await this.api.get(query)).data;
    }

    public async getById(queryData: { sid: number; slLocation: string }):
    Promise<ApiResponse<ListResponse<SinergyLifeEditData>>> {
        return (await this.api.getByPathOrParam(`${queryData.sid}/versions/${queryData.slLocation}`)).data;
    }

    public async getByValidFrom(queryData: { sid: string; slLocation: string; validFrom: string }):
    Promise<ApiResponse<any>> {
        return (await this.api.getByPathOrParam(`${+queryData.sid}/versions/${queryData.validFrom}/${queryData.slLocation}`)).data;
    }

    public async create(addRequest: any):
    Promise<ApiResponse<SinergyLifeData>> {
        return (await this.api.post(addRequest)).data;
    }

    public async edit(queryDetails: any, editRequest: EditSyngeryRequest):
    Promise<ApiResponse<SinergyLifeData>> {
        return (await this.api.
            put(`${queryDetails.sid}/versions/${queryDetails.validFrom}/${queryDetails.slLocation}`,
         editRequest)).data;
    }

    public async remove(queryData: { sid: string; slLocation: string; validFrom: string }):
    Promise<ApiResponse<any>> {
        return (await this.api.
            delete(`${+queryData.sid}/versions/${queryData.validFrom}/${queryData.slLocation}`)).data;
    }
}
