import { FilterQueryModel, SearchQueryModel, PaginationQueryModel, HiearchicPowerPlantQueryModel, MdFilterModel } from '@/models';

export class FilterQueryFactory {
    public static searchQueryModel(): SearchQueryModel {
        return {
            term: '',
        };
    }

    public static mdFilterModel(): MdFilterModel {
        return {
            countries: [],
            machineTypes: [],
            fleets: [],
            plantGroups: [],
            classTypes: [],
            aggState: [],
            euEts: [],
            dehst: [],
            enumMaterialType: [],
            enumSuMaterialGroup: [],
            primaryEnergy: [],
            status: [],
            technology: [],
            river: [],
            regionalUnit: [],
            legalEntity: [],
            includeDeleted: false,
        };
    }

    public static filterQueryModel(): FilterQueryModel {
        return {
            countries: [],
            machineTypes: [],
            fleets: [],
            plantGroups: [],
            classTypes: [],
            includeDeleted: false,
        };
    }

    public static paginationQueryModel(): PaginationQueryModel {
        return {
            page: 1,
            size: 20,
        };
    }

    public static assetQueryModel(): HiearchicPowerPlantQueryModel {
        return {
            ...FilterQueryFactory.searchQueryModel(),
            ...FilterQueryFactory.filterQueryModel(),
            ...FilterQueryFactory.paginationQueryModel(),
        };
    }
}
