<template>
    <div class="identification-container">
        <validation-observer ref="validationObserver">
            <div class="identification">
                <div v-if="data.locationSid" class="flex-row manage-input">
                    <label class="mr-2 default-label" for="businessPartnerSid">{{ $t('sid') }}:</label>
                    <input class="mb-1 default-input" v-model="data.locationSid" :placeholder="$t('sid')" id="businessPartnerSid" disabled/>
                </div>

                <validation-provider
                    tag="div"
                    class="manage-input"
                    name="identifier"
                    rules="required"
                    #default="{ errors, failed }"
                >
                    <div class="flex-row manage-input">
                        <label class="mr-2 default-label" for="identifier">{{ $t('identifier') }}*:</label>
                        <input class="default-input" v-model="data.locationShort" :placeholder="$t('identifier')" id="identifier" :class="{ 'invalid': failed }"/>
                    </div>
                    <error-list :errors="errors"/>
                </validation-provider>


                <validation-provider
                    tag="div"
                    class="manage-input"
                    name="name"
                    rules="required"
                    #default="{ errors, failed }"
                >
                    <div class="flex-row manage-input">
                        <label class="mr-2 default-label" for="name">{{ $t('name') }}*:</label>
                        <input class="default-input" v-model="data.locationName" :placeholder="$t('name')" id="name" :class="{ 'invalid': failed }"/>
                    </div>
                    <error-list :errors="errors"/>
                </validation-provider>

                <validation-provider
                    tag="div"
                    class="manage-input"
                    #default="{ errors, failed }"
                    name="type"
                    rules="required">
                    <label class="mr-2 default-label" for="locationType">{{ $t('type') }}*:</label>
                    <select class="default-select" id="locationType" v-model="data.enumLocationTypeSid" :placeholder="$t('type')"
                            :class="{ 'invalid': failed }" disabled>
                        <option v-for="type of locationTypes"
                                :key="type.sid"
                                :value="type.sid">
                            {{ type.name }} - {{ type.description }}
                        </option>
                    </select>
                    <error-list :errors="errors"/>
                </validation-provider>
                <validation-provider
                    tag="div"
                    class="manage-input"
                    #default="{ errors, failed }"
                    name="enumCountrySid"
                    rules="required"
                >
                    <label class="mr-2 default-label" for="enumCountrySid">{{ $t('country') }}*:</label>
                    <select class="default-select" id="enumCountrySid" v-model="data.enumCountrySid"
                            :placeholder="$t('country')"
                            :class="{ 'invalid': failed }"
                    >
                        <option v-for="country of countries"
                                :key="country.sid"
                                :value="country.sid">
                            {{ country.name }} - {{ country.description }}
                        </option>
                    </select>
                    <error-list :errors="errors"/>
                </validation-provider>
                <div class="manage-input">
                    <label class="mr-2 default-label" for="enumStateSid">{{ $t('state') }}:</label>
                    <select class="default-select" id="enumStateSid" v-model="data.enumStateSid"
                            :placeholder="$t('state')"
                    >
                        <option :value="null" key="default" selected>
                            {{ $t('none') }}
                        </option>
                        <option v-for="states of states"
                                :key="states.sid"
                                :value="states.sid">
                            {{ states.name }} - {{ states.description }}
                        </option>
                    </select>
                </div>
                <div class="manage-input">
                    <label class="mr-2 default-label" for="zipCode">{{ $t('zipCode') }}:</label>
                    <input class="default-input" v-model="data.zipCode" :placeholder="$t('zipCode')" id="zipCode"/>
                </div>
                <div class="manage-input">
                    <label class="mr-2 default-label" for="city">{{ $t('city') }}:</label>
                    <input class="default-input" v-model="data.city" :placeholder="$t('city')" id="city"/>
                </div>
                <div class="manage-input">
                    <label class="mr-2 default-label" for="street">{{ $t('street') }}:</label>
                    <input class="default-input" v-model="data.street" :placeholder="$t('street')" id="street"/>
                </div>
                <div class="manage-input">
                    <label class="mr-2 default-label" for="houseNumber">{{ $t('streetNumber') }}:</label>
                    <input class="default-input" v-model="data.houseNumber" :placeholder="$t('streetNumber')" id="streetNumber"/>
                </div>
                <div class="manage-input">
                    <label class="mr-2 default-label" for="postBox">{{ $t('postbox') }}:</label>
                    <input class="default-input" v-model="data.postbox" :placeholder="$t('postbox')" id="postBox"/>
                </div>
                <div class="manage-input">
                    <label class="mr-2 default-label" for="phone">{{ $t('phone') }}:</label>
                    <input class="default-input" v-model="data.telephone" :placeholder="$t('phone')" id="phone"/>
                </div>

                <validation-provider
                    v-if="data.locationSid"
                    tag="div"
                    class="manage-input"
                    name="comments"
                    rules="max:1000"
                    #default="{ errors, failed }"
                >
                    <div class="flex-row manage-input ">
                        <label class="mr-2 default-label" for="lastUpdateComment">{{ $t('comments') }}:</label>
                        <textarea
                            class="default-textarea"
                            maxlength="1000"
                            v-model="data.lastUpdateComment"
                            :placeholder="$t('comments')"
                            id="lastUpdateComment"
                            :class="{ 'invalid': failed }"
                            rows="4"></textarea>
                    </div>
                    <error-list :errors="errors"/>
                </validation-provider>
                <div class="flex-row mt-2">
                    <checkbox-input
                        v-model="data.deleted"
                        :label="$t('markAsDeleted')"/>
                </div>
                <last-updated :data="data"/>
                <error-list :errors="invalidMessages"/>
            </div>
            <div class="members-footer">
                <adam-button
                    v-if="hasMasterDataAdmin"
                    class="ml-1"
                    @click="save"
                    secondary>
                    {{ $t('save') }}
                </adam-button>
            </div>
        </validation-observer>
    </div>
</template>

<script lang="ts">

import {Component, Mixins, Prop, Ref} from 'vue-property-decorator';
import {EventBus, extractErrorsFromResponse, InfiniteScrollingHelper} from '@/utils';
import {Asset, BusinessPartnersDetails, IdTypeSid, SearchPaginationQueryModel} from '@/models';
import {AssetService, BusinessPartnersService} from '@/services';
import CheckboxInput from '@/components/checkbox-input/checkbox-input.vue';
import ErrorList from '@/components/error-list/error-list.vue';
import TableView from '@/components/view-details/table-view/table-view.vue';
import LastUpdated from '@/components/last-updated/last-updated.vue';
import ComponentSecurity from '@/mixins/component-security';
import {ValidationObserver} from 'vee-validate';

@Component({
    name: 'business-partner-identification',
    components: {
        checkboxInput: CheckboxInput,
        errorList: ErrorList,
        lastUpdated: LastUpdated,
        tableView: TableView,
    },
})
export default class BusinessPartnerIdentification extends Mixins(ComponentSecurity) {
    @Ref()
    private readonly validationObserver!: InstanceType<typeof ValidationObserver>;
    /*
     *  Business partner for which the identification tab from editing screen is displayed.
    */
    @Prop({required: true})
    private data!: BusinessPartnersDetails;

    @Prop({default: false})
    private isNewBusinessPartner!: boolean;

    private businessPartnerService: BusinessPartnersService = new BusinessPartnersService();
    private assetService: AssetService = new AssetService();


    private formModel: BusinessPartnersDetails = {...this.data};
    private countries: Asset[] = [];
    private states: Asset[] = [];
    private locationTypes: Asset[] = [];
    private assetFilterModel: SearchPaginationQueryModel = {
        page: 1,
        size: 1000,
        term: '',
    };

    private errorList: string[] = [];
    private invalidMessages: string[] = [];

    public async beforeLeave(): Promise<boolean> {
        if (!this.validationObserver.flags.dirty) {
            return true;
        }

        try {
            await this.$dialog
                .confirm(
                    {body: this.$t('areYouSureLeaveUnsaved').toString()},
                    {view: 'confirm'});
            return true;
        } catch (error: any) {
            return Promise.resolve(false);
        }
    }

    private async mounted(): Promise<void> {
        EventBus.$on(EventBus.DETAIL.CLOSE, this.closeDetailsView);
        await Promise.all([
            this.getCountries(),
            this.getStates(),
            this.getLocationTypes(),
        ]);
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.DETAIL.CLOSE);
    }

    private async getCountries(): Promise<void> {
        this.countries = await this.getAssets(IdTypeSid.COUNTRY);
    }

    private async getLocationTypes(): Promise<void> {
        this.locationTypes = await this.getAssets(IdTypeSid.POWER_PLANT_TYPE);
    }

    private async getStates(): Promise<void> {
        this.states = await this.getAssets(IdTypeSid.STATE);
    }

    private async getAssets(idTypeSid: number): Promise<Asset[]> {
        try {
            const {result: {items}} = await this.assetService.getByIdTypeSid(idTypeSid, this.assetFilterModel);

            return items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
    }

    private checkInput(): void {
        this.errorList = [];
        this.invalidMessages = [];
    }

    private isInvalid(invalidKey: string): boolean {
        return this.errorList.indexOf(invalidKey) > -1;
    }

    private deleteInputFieldError(invalidKey: string): any {
        this.errorList = this.errorList.filter((error: string) => error !== invalidKey);
    }

    private getInvalidKey(invalidKey: string): string {
        return this.errorList[this.errorList.indexOf(invalidKey)];
    }


    private async closeDetailsView(): Promise<void> {
        try {
            const canLeave = await this.beforeLeave();
            /**
             * Fired when the user wishes to exit the details view
             */
            if (canLeave) {
                this.$emit('close');
            }
        } catch (error: any) {
            // action canceled by user
        }
    }


    private async save(): Promise<void> {
        this.invalidMessages = [];
        const isValid = await this.validationObserver.validate();

        if (!isValid) {
            await this.$nextTick();
            InfiniteScrollingHelper.scrollToFirstInvalidElement('.invalid');
            return;
        }

        let message = 'errorAddingMaterialGroup';
        this.$store.commit('loading');
        this.formModel = {...this.data};

        try {
            if (this.formModel.locationSid > 0) {
                message = 'errorEditingBusinessPartner';
                const {result} = await this.businessPartnerService.edit(this.formModel);
                this.formModel = {...result};
                this.$emit('save', this.formModel, false, null);
            } else {
                const addedAsset = (await this.businessPartnerService.create(this.formModel)).result;
                this.$emit('save', addedAsset, true, null);
            }
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            this.validationObserver?.reset();
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, message);
            if (!err.response || !err.response.data) {
                throw err;
            }
            this.invalidMessages = extractErrorsFromResponse(err.response.data);
            if (this.invalidMessages.length) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.error-list__message');
                });
            }
        } finally {
            this.$store.commit('loading');
        }
    }
}

</script>

<style scoped lang="less">
@import "~@/variables.less";

.identification-container {
    .identification {
        display: grid;
        grid-template-columns: 40% 40%;
        grid-gap: 4rem;
        margin-top: 4rem;

        label {
            margin-top: 1rem;
            min-width: fit-content;
        }

        input, textarea {
            width: 100%;
        }
    }
}
</style>
