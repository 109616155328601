import { DateHelper } from '@/utils/date-helper';
import { KeyMappingAddRequest } from '@/models';

export class KeyMappingVersion {
    public static mapKeyMappingAddEditModel(keyMappingVersion: KeyMappingVersion): KeyMappingAddRequest {
        return {
            objectSid: keyMappingVersion?.objectSid,
            enumStreamSystemSid: keyMappingVersion?.enumStreamSystemSid,
            keyMapping: keyMappingVersion?.keyMapping,
            isActive: keyMappingVersion?.isActive,
            isDeleted: keyMappingVersion?.isDeleted,
            isExclusive: keyMappingVersion?.isExclusive,
            lastUpdateComment: keyMappingVersion?.lastUpdateComment,
            validFrom: keyMappingVersion?.validFrom,
        } as KeyMappingAddRequest;
    }

    public objectSid: number;
    public enumStreamSystemSid: number;
    public validFrom: string;
    public validTo?: string;
    public keyMappingName?: string;
    public keyMapping?: string;
    public isActive?: boolean;
    public isDeleted?: boolean;
    public isExclusive?: boolean;
    public lastUpdated?: string;
    public lastUpdatedBy?: string;
    public lastUpdateComment?: string;

    constructor(data?: any) {
        this.objectSid = data?.objectSid;
        this.enumStreamSystemSid = data?.enumStreamSystemSid;
        this.validFrom = data?.validFrom ?? DateHelper.formatDate(new Date());
        this.validTo = data?.validTo;
        this.keyMapping = data?.keyMapping;
        this.isActive = data?.isActive ?? true;
        this.isDeleted = data?.isDeleted;
        this.isExclusive = data?.isExclusive;
        this.lastUpdated = data?.lastUpdated;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdateComment = data?.lastUpdateComment;
    }
}
