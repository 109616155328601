import { NetworkOperator } from './network-operator';
import { DateHelper } from '@/utils/date-helper';

export class NetConnectorVersion {

    public static mapToAddEditRequest(netConnector: NetConnectorVersion): any {
        return {
            connectionSid: netConnector?.connectionSid,
            machineSid: netConnector?.machineSid,
            connectionAreaSid: netConnector?.connectionAreaSid,
            isDeleted: netConnector?.isDeleted,
            validFrom: netConnector?.validFrom,
            validTo: netConnector?.validTo,
            lastUpdateComment: netConnector?.lastUpdateComment,
            networkOperators: netConnector?.networkOperators?.map((item) => item) ?? [],
        };
    }

    public connectionSid: number;
    public machineSid: number;
    public connectionAreaSid: number;
    public connectionAreaName: string;
    public validFrom: string;
    public validTo: string;
    public lastUpdateComment: string;
    public lastUpdated: string;
    public lastUpdatedBy: string;
    public isDeleted: boolean;
    public networkOperators?: NetworkOperator[];

    constructor(data?: any) {
        this.connectionSid = data?.connectionSid;
        this.validFrom = data?.validFrom ?? DateHelper.formatDate(new Date());
        this.validTo = data?.validTo;
        this.machineSid = data?.machineSid;
        this.connectionAreaSid = data?.connectionAreaSid;
        this.connectionAreaName = data?.connectionAreaName;
        this.isDeleted = data?.isDeleted;
        this.lastUpdated = data?.lastUpdated;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdateComment = data?.lastUpdateComment;
        this.networkOperators = data?.networkOperators?.map((item: any) => new NetworkOperator(item));
    }
}
