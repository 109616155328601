import { Header } from '@/models';
import { DateHelper } from '@/utils';

const keyMappingHeader: Header = {
    columns: [
        {
            text: 'validFrom',
            displayKey: 'validFrom',
            class: 'flex-1 mr-1',
            formatFunction: (date: string) => DateHelper.formatDate(date),
        },
        {
            text: 'validUntil',
            displayKey: 'validTo',
            class: 'flex-1 mr-1',
            formatFunction: (date: string) => DateHelper.formatDate(date),
        },
        {
            text: 'keyMapping',
            displayKey: 'keyMapping',
            class: 'flex-2 mr-1',
            formatFunction: null,
        },
        {
            text: 'active',
            displayKey: 'isActive',
            class: 'flex-1 mr-1',
            formatFunction: (active: boolean) => active ? 'Y' : 'N',
        },
        {
            text: 'deleted',
            displayKey: 'isDeleted',
            class: 'flex-1 mr-1',
            formatFunction: (active: boolean) => active ? 'Y' : 'N',
        },
        {
            text: 'lastUpdated',
            displayKey: 'lastUpdated',
            class: 'flex-1 mr-1',
            formatFunction: (date: string) => DateHelper.formatDate(date),
        },
        {
            text: 'lastUpdatedBy',
            displayKey: 'lastUpdatedBy',
            class: 'flex-1 mr-1',
            formatFunction: null,
        },
    ],
};

export default keyMappingHeader;
