<template>
    <pui-grid-container v-if="data" class="pui-grid-container-zero-padding">
        <pui-grid-row>
            <pui-grid-column :cols="fullCols">
                <title-form-text
                    :title="$t('synergyLifeData')"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :required="true"
                    :label="$t('synergyLifeLocation')"
                    :name="formNames.LOCATION"
                    :model="data.slLocation"
                    @on-input="validate"
                    @on-blur="onLocationBlur(data.slLocation.value, formNames.LOCATION)"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :required="true"
                    :label="$t('synergyLifeCountry')"
                    :name="formNames.COUNTRY"
                    :model="data.slCountry"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :required="true"
                    :label="$t('synergyLifeLocationLevel1')"
                    :name="formNames.LEVEL_1"
                    :model="data.slLocationLevel1"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :required="true"
                    :label="$t('synergyLifeLocationLevel2')"
                    :name="formNames.LEVEL_2"
                    :model="data.slLocationLevel2"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('synergyLifeLocationLevel3')"
                    :name="formNames.LEVEL_3"
                    :model="data.slLocationLevel3"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('synergyLifeLocationLevel4')"
                    :name="formNames.LEVEL_4"
                    :model="data.slLocationLevel4"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :required="true"
                    :label="$t('synergyLifeLocationLastLevel')"
                    :name="formNames.LAST_LEVEL"
                    :model="data.slLocationLastLevel"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="fullCols">
                <pebble-text-field-area
                    :label="$t('comments')"
                    :name="formNames.LAST_UPDATE_COMMENT"
                    :model="data.lastUpdateComment"
                    :rows="6"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import PebbleTextField from '@/components/pebble-form-element/pebble-text-field.vue';
import {SinergyLifeForm} from '@/models/form/sinergy-life-form';
import PebbleTextFieldArea from '@/components/pebble-form-element/pebble-text-field-area.vue';
import TitleFormText from '@/components/text/title-form-text.vue';
import ComponentSecurity from '@/mixins/component-security';
import {PebbleColConst} from '@/mixins/pebble-col-const';

@Component({
    name: 'sinergy-life-data-form',
    components: {
        TitleFormText,
        PebbleTextFieldArea, PebbleTextField,
    },
})
export default class SinergyLifeDataForm extends mixins(ComponentSecurity, PebbleColConst) {
    @Prop({required: true})
    private data!: SinergyLifeForm;

    private formNames: {[key: string]: string} = {
        LOCATION: 'slLocation',
        COUNTRY: 'slCountry',
        LEVEL_1: 'slLocationLevel1',
        LEVEL_2: 'slLocationLevel2',
        LEVEL_3: 'slLocationLevel3',
        LEVEL_4: 'slLocationLevel4',
        LAST_LEVEL: 'slLocationLastLevel',
        LAST_UPDATE_COMMENT: 'lastUpdateComment',
    };

    @Watch('data')
    private updateData(newData: SinergyLifeForm, oldData: SinergyLifeForm): void {
        if (newData && newData !== oldData) {
            this.data = newData;
        }
    }

    private get isNewSynergyLocation(): boolean {
        return !!this.$route.name
            && [
                'sinergy-life-location',
                'sinergy-life-location-new',
            ].includes(this.$route.name);
    }

    private validate(value: string, name: string): void {
        this.$emit('validate', value, name);
        this.$forceUpdate();
    }

    private autoFillLevelFields(value: string): void {
        const locations = value.split(' - ');
        if (!value || !locations.length) {
            return;
        }

        const country = locations[0];
        if (country && !this.data.slCountry.value) {
            this.data.slCountry.value = country;
            this.validate(country, this.formNames.COUNTRY);
        }
        if (locations.length === 1) {
            return;
        }

        const levelLocations = locations.slice(1);
        const [level1, level2, level3, level4] = levelLocations;

        if (level1 && this.data.slLocationLevel1 && !this.data.slLocationLevel1.value) {
            this.data.slLocationLevel1.value = level1;
            this.validate(level1, this.formNames.LEVEL_1);
            if (!level2 && this.data.slLocationLastLevel) {
                this.data.slLocationLastLevel.value = level1;
                this.validate(level1, this.formNames.LAST_LEVEL);
            }
        }
        if (level2 && this.data.slLocationLevel2 && !this.data.slLocationLevel2.value) {
            this.data.slLocationLevel2.value = level2;
            this.validate(level2, this.formNames.LEVEL_2);
            if (!level3 && this.data.slLocationLastLevel) {
                this.data.slLocationLastLevel.value = level2;
                this.validate(level2, this.formNames.LAST_LEVEL);
            }
        }
        if (level3 && this.data.slLocationLevel3 && !this.data.slLocationLevel3.value) {
            this.data.slLocationLevel3.value = level3;
            this.validate(level3, this.formNames.LEVEL_3);
            if (!level4 && this.data.slLocationLastLevel) {
                this.data.slLocationLastLevel.value = level3;
                this.validate(level3, this.formNames.LAST_LEVEL);
            }
        }
        if (level4 && this.data.slLocationLevel4 && !this.data.slLocationLevel4.value) {
            this.data.slLocationLevel4.value = level4;
            this.validate(level4, this.formNames.LEVEL_4);
            if (this.data.slLocationLastLevel) {
                this.data.slLocationLastLevel.value = level4;
                this.validate(level4, this.formNames.LAST_LEVEL);
            }
        }
    }

    private onLocationBlur(value: string, name: string): void {
        this.validate(value, name);

        if (this.isNewSynergyLocation) {
            this.autoFillLevelFields(value);
        }
    }
}
</script>


