import {
    Asset,
    BusinessPartnerModel,
    FilterGroup,
    FilterGroupType,
    FilterOption,
    FilterRequestFieldName,
    IdTypeSid,
    NodeTypeSid,
    TreeViewEntity,
} from '@/models';
import i18n from '@/utils/i18n';
import {AssetService} from './asset-service';
import {BusinessPartnersService} from './business-partners-service';
import {hierarchyService} from './hierarchy-service';

class FilterService {
    private assetService = new AssetService();

    public async getFilters(): Promise<FilterGroup[]> {
        return Promise.all([
            this.getMachineTypes(),
            this.getCountries(),
            this.getFleets(),
            this.getPlantGroups(),
            this.getClassTypes(),
            this.getShowDeleted(),
        ]);
    }

    public async getMaterialsFilter(): Promise<FilterGroup[]> {
        return Promise.all([
            this.getAggState(),
            this.getDehst(),
            this.getEuEts(),
            this.getShowDeleted(),
        ]);
    }

    public async getNewMaterialsFilter(): Promise<FilterGroup[]> {
        return Promise.all([
            this.getAggState(),
            this.getDehst(),
            this.getEuEts(),
        ]);
    }

    public async getMaterialGroupFilter(): Promise<FilterGroup[]> {
        return Promise.all([
            this.getEnumMaterialType(),
            this.getEnumSuMaterial(),
            this.getShowDeleted(),
        ]);
    }

    public async getNewMaterialGroupFilter(): Promise<FilterGroup[]> {
        return Promise.all([
            this.getEnumMaterialType(),
            this.getEnumSuMaterial(),
        ]);
    }

    public async getAssetOverviewFilter(): Promise<FilterGroup[]> {
        return Promise.all([
            this.getCountries(),
            this.getUnitTypes(),
            this.getPrimaryEnergy(),
            this.getRivers(),
            this.getRegionalUnit(),
            this.getStatus(),
            this.getLegalEntity(),
            this.getUnitTechnologies(),
        ]);
    }

    public async getBusinessPartnerFilter(): Promise<FilterGroup[]> {
        return Promise.all([
            this.getShowDeleted(),
        ]);
    }

    private async getCountries(): Promise<FilterGroup> {
        const items = (await hierarchyService.getHierarchycalAssets({typeIds: NodeTypeSid.COUNTRY}))
            ?.result
            ?.items;
        const options = items
            .map(({sid, name}: TreeViewEntity): any => {
                return {
                    value: sid,
                    name,
                };
            });

        return {
            displayName: i18n.t('country').toString(),
            requestFieldName: FilterRequestFieldName.COUNTRIES,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getFleets(): Promise<FilterGroup> {
        const items = (await this.assetService.getByIdTypeSid(IdTypeSid.FLEET, {size: 999, term: '', page: 1}))
            ?.result
            ?.items;
        const options = items?.map((fleetAsset: Asset): any => this.optionFromAsset(fleetAsset, 'sid', 'description'));

        return {
            displayName: i18n.t('fleet').toString(),
            requestFieldName: FilterRequestFieldName.FLEETS,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getClassTypes(): Promise<FilterGroup> {
        const items = (await this.assetService.getByIdTypeSid(IdTypeSid.EQUIPMENT_CLASS_TYPE, {size: 999, term: '', page: 1}))
            ?.result
            ?.items;
        const options = items?.map((classAsset: Asset): any => this.optionFromAsset(classAsset, 'sid', 'description'));

        return {
            displayName: i18n.t('class').toString(),
            requestFieldName: FilterRequestFieldName.CLASS_TYPES,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getMachineTypes(): Promise<FilterGroup> {
        const items = (await this.assetService.getByIdTypeSid(IdTypeSid.SAP_EC_TYPE, {size: 999, term: '', page: 1}))
            ?.result
            ?.items;
        const options = items?.map((sapEcType: Asset): any => this.optionFromAsset(sapEcType, 'sid', 'description'));

        return {
            displayName: i18n.t('machineType').toString(),
            requestFieldName: FilterRequestFieldName.MACHINE_TYPES,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getPlantGroups(): Promise<FilterGroup> {
        const items = (await this.assetService.getByIdTypeSid(IdTypeSid.PLANT_GROUP, {size: 999, term: '', page: 1}))
            ?.result
            ?.items;
        const options = items?.map((assetPlantGroup: Asset): any => this.optionFromAsset(assetPlantGroup, 'sid', 'description'));

        return {
            displayName: i18n.t('plantGroup').toString(),
            requestFieldName: FilterRequestFieldName.PLANT_GROUPS,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getAggState(): Promise<FilterGroup> {
        const items = (await this.assetService.getByIdTypeSid(IdTypeSid.AGG_STATE, {size: 999, term: '', page: 1}))
                ?.result
                ?.items;
        const options = items?.map((asset: Asset): any => this.optionFromAsset(asset, 'sid', 'description'));

        return {
            displayName: i18n.t('aggState').toString(),
            requestFieldName: FilterRequestFieldName.AGG_STATE,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getEuEts(): Promise<FilterGroup> {
        const items = (await this.assetService.getByIdTypeSid(IdTypeSid.EU_ETS, {size: 999, term: '', page: 1}))
            ?.result
            ?.items;
        const options = items?.map((asset: Asset): any => this.optionFromAsset(asset, 'sid', 'description'));

        return {
            displayName: i18n.t('euEts').toString(),
            requestFieldName: FilterRequestFieldName.EU_ETS,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getDehst(): Promise<FilterGroup> {
        const items = (await this.assetService.getByIdTypeSid(IdTypeSid.DEHST, {size: 999, term: '', page: 1}))
            ?.result
            ?.items;
        const options = items?.map((asset: Asset): any => this.optionFromAsset(asset, 'sid', 'description'));

        return {
            displayName: i18n.t('dehst').toString(),
            requestFieldName: FilterRequestFieldName.DEHST,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getEnumMaterialType(): Promise<FilterGroup> {
        const items = (await this.assetService.getByIdTypeSid(IdTypeSid.ENUM_MATERIAL_TYPE, {size: 999, term: '', page: 1}))
            ?.result
            ?.items;
        const options = items?.map((asset: Asset): any => this.optionFromAsset(asset, 'sid', 'description'));

        return {
            displayName: i18n.t('enumMaterialType').toString(),
            requestFieldName: FilterRequestFieldName.ENUM_MATERIAL_TYPE,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getEnumSuMaterial(): Promise<FilterGroup> {
        const items = (await this.assetService.getByIdTypeSid(IdTypeSid.ENUM_SU_MATERIAL_GROUP, {size: 999, term: '', page: 1}))
            ?.result
            ?.items;
        const options = items?.map((asset: Asset): any => this.optionFromAsset(asset, 'sid', 'description'));

        return {
            displayName: i18n.t('enumSuMaterialGroup').toString(),
            requestFieldName: FilterRequestFieldName.ENUM_SU_MATERIAL_GROUP,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getPrimaryEnergy(): Promise<FilterGroup> {
        const items = (await this.assetService.getByIdTypeSid(IdTypeSid.OPERATION_MODE_ENERGY_TYPE, {size: 999, term: '', page: 1}))
            ?.result
            ?.items;
        const options = items?.map((asset: Asset): any => this.optionFromAsset(asset, 'sid', 'description'));

        return {
            displayName: i18n.t('primaryEnergy').toString(),
            requestFieldName: FilterRequestFieldName.PRIMARY_ENERGY,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getUnitTypes(): Promise<FilterGroup> {
        const items = (await this.assetService.getByIdTypeSid(IdTypeSid.SAP_EC_TYPE, {size: 999, term: '', page: 1}))
            ?.result
            ?.items;
        const options = items?.map((sapEcType: Asset): any => this.optionFromAsset(sapEcType, 'sid', 'description'));

        return {
            displayName: i18n.t('unitType').toString(),
            requestFieldName: FilterRequestFieldName.MACHINE_TYPES,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getRivers(): Promise<FilterGroup> {
        const items = (await this.assetService.getByIdTypeSid(IdTypeSid.RIVER, {size: 999, term: '', page: 1}))
            ?.result
            ?.items;
        const options = items?.map((asset: Asset): any => this.optionFromAsset(asset, 'sid', 'description'));

        return {
            displayName: i18n.t('river').toString(),
            requestFieldName: FilterRequestFieldName.RIVER,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getRegionalUnit(): Promise<FilterGroup> {
        const items = (await this.assetService.getByIdTypeSid(IdTypeSid.REGIONAL_UNIT, {size: 999, term: '', page: 1}))
            ?.result
            ?.items;
        const options = items?.map((asset: Asset): any => this.optionFromAsset(asset, 'sid', 'description'));

        return {
            displayName: i18n.t('regionalUnit').toString(),
            requestFieldName: FilterRequestFieldName.REGION,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getStatus(): Promise<FilterGroup> {
        const items = (await this.assetService.getByIdTypeSid(IdTypeSid.OPERATION_STATUS_TYPE, {size: 999, term: '', page: 1}))
            ?.result
            ?.items;
        const options = items?.map((asset: Asset): any => this.optionFromAsset(asset, 'sid', 'description'));

        return {
            displayName: i18n.t('status').toString(),
            requestFieldName: FilterRequestFieldName.STATUS,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getUnitTechnologies(): Promise<FilterGroup> {
        const items = (await this.assetService.getByIdTypeSid(IdTypeSid.TECHNOLOGY, {size: 999, term: '', page: 1}))
            ?.result
            ?.items;
        const options = items?.map((asset: Asset): any => this.optionFromAsset(asset, 'sid', 'description'));

        return {
            displayName: i18n.t('unitTechnology').toString(),
            requestFieldName: FilterRequestFieldName.TECHNOLOGY,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getLegalEntity(): Promise<FilterGroup> {
        const items = (await new BusinessPartnersService().get({
            page: 1,
            size: 1000,
            term: '',
            includeDeleted: false,
            sortDirection: '',
            sortColumn: '',
        }))
            ?.result
            ?.items;
        const options =
            items?.map((buisnessPartner: BusinessPartnerModel): any =>
                this.optionFromAsset(buisnessPartner, 'businessPartnerSid', 'name'),
            );

        return {
            displayName: i18n.t('legalEntity').toString(),
            requestFieldName: FilterRequestFieldName.LEGAL_ENTITY,
            type: FilterGroupType.OPTIONS,
            options,
        };
    }

    private async getShowDeleted(): Promise<FilterGroup> {
        return {
            displayName: i18n.t('showDeletedItems').toString(),
            requestFieldName: FilterRequestFieldName.SHOW_DELETED_ITEMS,
            type: FilterGroupType.BOOLEAN,
        };
    }

    private optionFromAsset(
        asset: Asset | BusinessPartnerModel,
        valueKey: string,
        nameKey: string,
    ): FilterOption {
        return {
            value: (asset as any)[valueKey],
            name: (asset as any)[nameKey],
        };
    }
}

export const filterService = new FilterService();
