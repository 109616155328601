import {ObjectKeys} from '@/models/object-keys';

export interface SinergyLifeData extends ObjectKeys {
    sid: number;
    country: string;
    uniperCountryShort: string;
    uniperLocationShort: string;
    tableName: string;
    synergiLifeObjectType: string;
    slLocation: string;
    slCountry: string;
    slLocationLastLevel: string;
    slLocationLevel1: string;
    slLocationLevel2: string;
    slLocationLevel3: string;
    slLocationLevel4: string;
    slLocationSid: string;
    isDeleted: boolean;
    lastUpdatedBy: string;
    lastUpdated: string;
    validFrom: string;
    validTo: string;
}

export interface SynergyLifeQuery {
    term: string;
    page: number;
    size: number;
    Country: string[];
    SynergiLifeObjectType: string[];
    SortColumn: string;
    IncludeDeleted: boolean;
    SortDirection: string;
}

export const SinergyLifeDataKeys = [
    'sid',
    'country',
    'uniperCountryShort',
    'uniperLocationShort',
    'tableName',
    'synergiLifeObjectType',
    'slLocation',
    'slCountry',
    'slLocationLastLevel',
    'slLocationLevel1',
    'slLocationLevel2',
    'slLocationLevel3',
    'slLocationLevel4',
    'lastUpdatedBy',
    'lastUpdated',
    'deleted',
    'action',
];
export const SinergyLifeDataSortableKeys = [
    'sid',
    'country',
    'abbreviation',
    'tableName',
    'assetType',
    'slLocation',
    'slCountry',
    'slLocationLastLVL',
    'slLocationLVL1',
    'slLocationLVL2',
    'slLocationLVL3',
    'slLocationLVL4',
    'deleted',
];
