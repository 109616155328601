<template>
    <div class="operation-modes-management flex-col full-height">
        <div v-show="!selectedOperationMode" class="flex-row operation-mode-details">
            <div class="flex flex-4"></div>
            <div class="flex-col">
                <div class="flex-row">
                    <div class="mr-1 pt-1 pb-1 center-align">
                        <search-component
                            @input="searchCards"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-area" id="view-items">
            <operation-mode-details
                v-if="selectedOperationMode"
                :data="selectedOperationMode"
                @close="closeDetailsView()"
            />
            <div v-else-if="operationModesList.length === 0" class="mt-2 ml-2">
                {{ $t('noDataToShow') }}
            </div>
            <div v-else
                class="cards-display">
                <sort-header
                    v-if="headerColumn.length"
                    :headerColumns="headerColumn"
                    :objectToSort="operationModesList"
                    @get-sorted-list="setSortedLocalOperationModes"
                ></sort-header>
                <card-list>
                    <template v-slot:cards>
                        <div v-for="opMode of operationModesList"
                            :key="opMode.operationModeSid"
                            class="card">
                            <operation-mode-card-list
                                :card="opMode"
                                @edit="selectOperationMode" />
                        </div>
                    </template>
                </card-list>
                <div v-if="hasNextPage" class="flex-row mt-2" >
                    <div class="flex-col load-more">
                        <adam-button
                            @click="calculateScroll()"
                            secondary
                        >
                            {{ $t('loadMore') }}
                        </adam-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="ts">

import { Vue, Component, Watch } from 'vue-property-decorator';
import { OperationModesQueryModel, OperationModeGetResponse, OperationModeListDto } from '@/models';
import { AssetService, OperationModesService } from '@/services';
import { EventBus, InfiniteScrollingHelper } from '@/utils';
import OperationModeDetails from '@/components/view-details/operation-mode/operation-mode-details.vue';
import CardList from '@/components/cards/cards-list/cards-list.vue';
import SearchComponent from '@/components/search-component/search-component.vue';
import OperationModeCardList from '@/components/cards/cards-list/operation-mode-card-list/operation-mode-card-list.vue';
import { throttle } from 'lodash';
import { OperationModeFactory } from '@/utils/factories';
import SortHeader from '@/views/sort-header/sort-header.vue';

const SCROLL_THROTTLE_MILLISECONDS = 500;

/**
 * Page for viewing operation modes.
 */
@Component({
    name: 'operation-modes-management',
    components: {
        searchComponent: SearchComponent,
        operationModeDetails: OperationModeDetails,
        cardList: CardList,
        operationModeCardList: OperationModeCardList,
        SortHeader,
    },
})

export default class OperationModesManagement extends Vue {
    private operationModesService: OperationModesService = new OperationModesService();
    private assetService: AssetService = new AssetService();

    private operationModesList: OperationModeListDto[] = [];
    private selectedOperationMode: OperationModeGetResponse | null = null;
    private isLoadingNextPage = false;
    private hasNextPage = false;
    private filterModel: OperationModesQueryModel = {
        page: 1,
        size: 10,
        term: '',
        sortDirection: '',
        sortColumn: '',
    };
    private onScroll = throttle(this.calculateScroll, SCROLL_THROTTLE_MILLISECONDS);
    private sortOrder = 'asc';
    private sortKey = 'name';
    private headerColumn: Array<{
        attributeName: string;
        name: string;
        itemCssClassName: string;
    }> = [];

    @Watch('$route.query.item')
    private async onQueryItemChanged(newValue: string | undefined, oldValue: string | undefined) {
        if (newValue === oldValue) {
            return;
        }
        this.selectedOperationMode = null;

        if (!newValue) {

            if (!this.operationModesList.length) {
                await this.getOperationModes();
            }
            this.addEventListener();
            return;
        }
        this.removeEventListener();

        if (newValue === '0' && this.$route.query.unitSid) {
            this.selectedOperationMode = OperationModeFactory.createGetResponse();

            return;
        }

        try {
            const { result } = await this.operationModesService.getById(Number(newValue));
            this.selectedOperationMode = result;

            return;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingOperationMode');

            this.$router.replace({
                query: undefined,
            });
            return;
        }
    }

    private async mounted(): Promise<void> {
        this.headerColumn = [
            {
                attributeName: 'operationModeName',
                name: this.$t('operationModeName').toString(),
                itemCssClassName: 'omm-operation-mode-name',
            },
            {
                attributeName: 'operationModeSid',
                name: this.$t('sid').toString(),
                itemCssClassName: 'omm-sid',
            },
            {
                attributeName: 'isStdOperationMode',
                name: this.$t('operationMode').toString(),
                itemCssClassName: 'omm-operation-mode',
            },
            {
                attributeName: 'isActive',
                name: this.$t('status').toString(),
                itemCssClassName: 'omm-status',
            },
        ];
        if (!this.$route.query.item) {
            await this.getOperationModes();
            this.addEventListener();
            return;
        }
        this.removeEventListener();

        if (this.$route.query.item === '0' && this.$route.query.unitSid) {
            this.selectedOperationMode = OperationModeFactory.createGetResponse();

            return;
        }
        try {
            const { result } = await this.operationModesService.getById(Number(this.$route.query.item));
            this.selectedOperationMode = result;

            return;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingOperationMode');
            this.$router.replace({
                query: undefined,
            });

            return;
        }
    }

    private beforeDestroy(): void {
        this.removeEventListener();
    }

    private addEventListener(): void {
        const element = document.getElementById('view-items');
        if (element) {
            element.addEventListener('scroll', this.onScroll);
            element.addEventListener('wheel', this.onScroll);
        }
    }

    private removeEventListener(): void {
        const element = document.getElementById('view-items');
        if (element) {
            element.removeEventListener('scroll', this.onScroll);
            element.removeEventListener('wheel', this.onScroll);
        }
    }

    private calculateScroll(): void {
        if (this.selectedOperationMode) {
            return;
        }

        InfiniteScrollingHelper.calculateScroll(document.getElementById('view-items'), this.getNextPage, 200);
    }

    private selectOperationMode(operationMode: OperationModeListDto): void {
        if (this.$route.path === '/master-data-management/operation-modes-management' &&
            this.$route.query.item === operationMode.operationModeSid.toString()) {
            return;
        }

        this.$router.push({
            name: 'manage-option',
            params: {
                option: this.$route.params.option,
            },
            query: {
                item: operationMode.operationModeSid.toString(),
            },
        });
    }

    private closeDetailsView(): void {
        this.addEventListener();
        this.$router.back();
    }

    private goToDefaultRoute(): void {
        if (this.$route.path === '/master-data-management/operation-modes-management' &&
            !this.$route.query.item) {
            return;
        }

        this.$router.push({
            name: 'manage-option',
            params: {
                option: 'operation-modes-management',
            },
        });
    }

    private async searchCards(searchString: string): Promise<void> {
        this.filterModel.term = searchString;
        await this.getOperationModes();
    }

    private async getOperationModes(): Promise<void> {
        this.filterModel.page = 1;
        this.operationModesList = await this.getPagedOperationModes();
    }

    private async getPagedOperationModes(): Promise<OperationModeListDto[]> {
        this.$store.commit('loading');
        try {
            const { result: { items, hasNextPage } } = (await this.operationModesService.get(this.filterModel));
            this.hasNextPage = hasNextPage;
            return items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingOperationModes');
        } finally {
            this.$store.commit('loading');
        }
        return [];
    }

    private async getNextPage(): Promise<void> {
        this.removeEventListener();
        if (this.isLoadingNextPage) {
            return;
        }

        if (!this.hasNextPage) {
            return;
        }
        this.filterModel.page++;
        this.isLoadingNextPage = true;
        const opModes = await this.getPagedOperationModes();
        this.operationModesList.push(...opModes);
        await this.$nextTick();
        this.addEventListener();
        this.isLoadingNextPage = false;
    }

    private async setSortedLocalOperationModes(obj: {
        sortedList: OperationModeListDto[];
        order: string;
        sortKey: string;
    }): Promise<void> {

        const oldpg = this.filterModel.page;
        this.filterModel.size *= oldpg;
        this.filterModel.page = 1;
        if (obj.order === 'asc') {
            this.filterModel.sortDirection = 'Ascending';
        } else {
            this.filterModel.sortDirection = 'Descending';
        }
        this.filterModel.sortColumn = obj.sortKey;

        this.operationModesList = await this.getPagedOperationModes();

        this.filterModel.page = oldpg;
        this.filterModel.size = 10;


    }
}

</script>

<style lang="less">
@import "~@/variables.less";

.operation-modes-management {
    .operation-mode-details {
        background: white;
        border-bottom: 2px solid @grey-lighter;
    }
    .main-area {
        padding: 2.5rem;
        box-sizing: border-box;
        overflow-y: auto;
        height: 100%;
        .name {
            width: 28rem;
        }
        .sid {
            width: 8rem;
        }
        .operation-mode {
            width:22rem;
        }
    }
    .header-row {
        &__item-omm-operation-mode-name,
        &__item-omm-sid,
        &__item-omm-last-updated,
        &__item-omm-operation-mode,
        &__item-omm-status {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            box-sizing: border-box;
            border-left: 2px solid @lighter-grey;
            padding: 0 1rem;
        }

        &__item-omm-operation-mode-name {
            border-left: none;
            width: 22rem;
        }

        &__item-omm-sid {
            width: 10rem;
        }

        &__item-omm-last-updated {
            width: 13rem;
        }

        &__item-omm-operation-mode {
            width: 24rem;
        }

        &__item-omm-status {
            width: 9rem;
        }
    }
}
</style>
