import { LocalStorageHelper } from '@/utils/local-storage-helper';
import { LANGUAGES } from '@/utils/languages';

export default class ApiConfigHelper {
    public static getHeaderConfig(): any {
        return {
            headers: {
                ...this.getLangHeaderConfig(),
            },
        };
    }

    public static getLangHeaderConfig(): any {
        const localeLanguage = LocalStorageHelper.getSelectedLanguage() || this.fallBackLanguage;
        const locale = LANGUAGES[localeLanguage];
        return  {
            'Accept-Language': locale + ',' + localeLanguage.toLowerCase() + ';q=0.9',
            'Language': localeLanguage,
        };
    }

    private static fallBackLanguage = 'EN';
}
