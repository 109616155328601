<template>
    <view-details
        :sid="data.operationModeSid"
        :name="data.operationModeName || $t('newOperationMode')"
        icon="ic-settings">
        <tab-headers 
            :tabs="tabs"
            :active-tab="activeTab"
            @select-tab="selectTab" />
        <component
            :is="activeTab.component"
            :data="data"
            @close="closeEditScreen()" />
    </view-details>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { GenericTab, OperationModeGetResponse } from '@/models';
import TabHeaders from '@/components/view-details/tab-headers/tab-headers.vue';
import OperationModeIdentification from '@/components/view-details/operation-mode/operation-mode-identification/operation-mode-identification.vue';
import OperationModeProduct from '@/components/view-details/operation-mode/operation-mode-product/operation-mode-product.vue';
import ViewDetails from '@/components/view-details/view-details.vue';

@Component({
    name: 'operation-mode-details',
    components: {
        viewDetails: ViewDetails,
        tabHeaders: TabHeaders,
        operationModeIdentification: OperationModeIdentification,
        operationModeProduct: OperationModeProduct,
    },
})
export default class OperationModeDetails extends Vue {
    /**
     * Operation mode for which edit screen is displayed.
     */
    @Prop({ required: true })
    private data!: OperationModeGetResponse;

    private tabs: GenericTab[] = [
        { name: 'identification', component: 'operationModeIdentification'},
        { name: 'products', component: 'operationModeProduct'},
    ];
    private activeTab = this.tabs[0];

    private selectTab(tab: GenericTab) {
        this.activeTab = tab;
    }

    private closeEditScreen(): void {
        this.$emit('close');
    }
}
</script>
