import {IdTypeSid, OperationModeProductDto, OpModeProduct, OperationModeProduct } from '@/models';
import {OperationModeAddProductDto} from '@/models/operation-mode';
import { store } from '@/store';
import { EventBus } from '@/utils';
import ErrorHandler from '@/utils/error-handler';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
import { sdk } from '@/utils/sdk';

class ProductService {
    private api = new SdkGenericApiHelper('operation-modes');

    private idTypeSid = IdTypeSid.PRODUCT;

    public async getAvailableProducts(): Promise<OpModeProduct[]> {
        const {data: {result: {items}}} =
            (await sdk?.backend.request.api.get<any>(`assets/enumerations/${this.idTypeSid}`));
        return items;
    }

    public async getProducts(opModeId: number): Promise<OperationModeProductDto[]> {
        const {data: {result: {items}}} = (await this.api.getByPathOrParam(`${opModeId}/products`));
        return items;
    }

    public async addProduct(opModeId: number, opModeProductDto: OperationModeAddProductDto): Promise<OperationModeProductDto[]> {
        const {data: {result: {items}}} = (await this.api.post(opModeProductDto, `${opModeId}/products`));
        return items;
    }

    public async deleteProduct(opModeId: number, productId: number): Promise<void> {
        await this.api.delete(`${opModeId}/products/${productId}`);
    }

    public async getOperationModeProducts(operationModeId: number): Promise<OperationModeProduct[]> {
        store.commit('loading');
        try {
            return (await this.api.getByPathOrParam(`${operationModeId}/products`))
                ?.data
                ?.result
                .items
                ?.map((item: any) => new OperationModeProduct(item)) ?? [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingOperationMode');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async addOperationModeProduct(product: OperationModeProduct): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            (await this.api.post(OperationModeProduct.mapToAddRequest(product),
                `${product.operationModeSid}/products`));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async updateOperationModeProduct(product: OperationModeProduct, initialProductId: number): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            (await this.api.put(`${product.operationModeSid}/products/${initialProductId}`,
                OperationModeProduct.mapToEditRequest(initialProductId, product)));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async deleteOperationModeProduct(product: OperationModeProduct): Promise<boolean> {
        try {
            store.commit('loading');
            await this.api.delete(`${product.operationModeSid}/products/${product.productSid}`);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            const errorMessage = ErrorHandler.getAxiosErrorMessage(error) ?? 'errorDeleteProduct';
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, errorMessage);
            return false;
        } finally {
            store.commit('loading');
        }
    }
}

const productService = new ProductService();

export {
    ProductService,
    productService,
};
