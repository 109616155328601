<template>
    <pui-headline type="h1" looks-like="form-sheet-title" class="title-form-text">
        {{ title }}
    </pui-headline>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'title-form-text',
})
export default class TitleFormText extends Vue {
    @Prop({required: true})
    private title!: string;
}
</script>

<style scoped>
.title-form-text {
    margin-top: 16px !important;
}
</style>
