<template>
    <div class="ets-units-management flex-col full-height">
        <div v-show="!selectedEtsUnit" class="flex-row ets-units-details">
            <div class="flex flex-4"></div>
            <div class="flex-col">
                <div class="flex-row">
                    <div class="mr-1 pt-1 pb-1 center-align">
                        <search-component @input="searchCards"/>
                    </div>
                    <div class="mr-2 center-align">
                        <adam-button
                            v-if="hasMasterDataAdmin"
                            class="mr-1 mt-1"
                            icon="ic-add-dashboard"
                            @click="addNewEntity()"
                            secondary>
                            {{ $t('addNew') }}
                        </adam-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-area" id="view-items">
            <div v-if="!etsUnits.length" class="mt-2 ml-2">
                {{ $t('noDataToShow') }}
            </div>
            <div v-else-if="!selectedEtsUnit"
                class="cards-display">
                <sort-header
                    v-if="headerColumn.length"
                    :headerColumns="headerColumn"
                    :objectToSort="etsUnits"
                    @get-sorted-list="setSortedLocalEtsUnits"
                ></sort-header>
                <card-list>
                    <template v-slot:cards>
                        <div v-for="unit of etsUnits"
                            :key="unit.etsId"
                            class="card">
                            <ets-unit-card-list
                                :card="unit"
                                @edit="selectUnit"
                                @remove="deleteClicked"
                            />
                        </div>
                    </template>
                </card-list>
                <div v-if="hasNextPage" class="flex-row mt-2" >
                    <div class="flex-col load-more">
                        <adam-button
                            @click="calculateScroll()"
                            secondary
                        >
                            {{ $t('loadMore') }}
                        </adam-button>
                    </div>
                </div>
            </div>
            <div v-else-if="selectedEtsUnit"
                class="edit-screen">
                <ets-units-details
                    :data="selectedEtsUnit"
                    @close="closeDetailsView()"
                    @save="saveChanges()"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import EtsUnitCardList from '@/components/cards/cards-list/ets-unit-card-list/ets-unit-card-list.vue';
import CardList from '@/components/cards/cards-list/cards-list.vue';
import SearchComponent from '@/components/search-component/search-component.vue';
import ViewDetails from '@/components/view-details/view-details.vue';
import ETSUnitsDetails from '@/components/view-details/ets-units/ets-units-details.vue';
import { InfiniteScrollingHelper, EventBus } from '@/utils';
import { EtsUnit, GenericTab, EtsUnitsQueryModel } from '@/models';
import { ETSService } from '@/services';
import { throttle } from 'lodash';
import { Component, Watch, Mixins } from 'vue-property-decorator';
import ComponentSecurity from '@/mixins/component-security';
import { EtsUnitFactory } from '@/utils/factories';
import SortHeader from '@/views/sort-header/sort-header.vue';

const SCROLL_THROTTLE_MILLISECONDS = 500;

@Component({
    name: 'ets-units-management',
    components: {
        searchComponent: SearchComponent,
        cardList: CardList,
        viewDetails: ViewDetails,
        etsUnitCardList: EtsUnitCardList,
        etsUnitsDetails: ETSUnitsDetails,
        sortHeader: SortHeader,
    },
})

export default class EtsUnitsManagement extends Mixins(ComponentSecurity) {
    private etsService: ETSService = new ETSService();
    private selectedEtsUnit: EtsUnit | null = null;
    private etsUnits: EtsUnit[] = [];
    private isLoadingNextPage = false;
    private hasNextPage = false;
    private routeEtsID: string | undefined;
    private isNewUnit = false;
    private onScroll = throttle(this.calculateScroll, SCROLL_THROTTLE_MILLISECONDS);
    private filterModel: EtsUnitsQueryModel = {
        page: 1,
        size: 10,
        term: '',
        sortDirection: '',
        sortColumn: '',
    };
    private tabs: GenericTab[] = [
        { name: 'identification', component: 'estUnits'},
    ];
    private activeTab = this.tabs[0];
    private sortOrder = 'asc';
    private sortKey = 'name';
    private headerColumn: Array<{
        attributeName: string;
        name: string;
        itemCssClassName: string;
    }> = [];

    @Watch('$route.query.item')
    private async onQueryItemChanged(newValue: string | undefined, oldValue: string | undefined) {
        if (newValue === oldValue) {
            return;
        }

        if (!newValue) {
            this.routeEtsID = undefined;
            if (!this.etsUnits.length) {
                await this.getEtsUnits();
            }
            this.addEventListener();
        } else {
            this.routeEtsID = newValue;
            this.removeEventListener();
        }
        await this.assignRouteEtsUnit();
    }

    private async mounted(): Promise<void> {
        await this.getEtsUnits();
        this.addEventListener();
        this.headerColumn = [
            {
                attributeName: 'name',
                name: this.$t('name').toString(),
                itemCssClassName: 'name',
            },
            {
                attributeName: 'etsId',
                name: this.$t('sid').toString(),
                itemCssClassName: 'ets-id',
            },
            {
                attributeName: 'lastUpdated',
                name: this.$t('lastUpdated').toString(),
                itemCssClassName: 'last-updated',
            },
            {
                attributeName: 'lastUpdatedBy',
                name: this.$t('lastUpdatedBy').toString(),
                itemCssClassName: 'last-updated-by',
            },
            {
                attributeName: 'isDeleted',
                name: this.$t('deleted').toString(),
                itemCssClassName: 'is-deleted',
            },
        ];
        if (!this.$route.query.item) {
            await this.getPagedEtsUnits();
            this.addEventListener();
            return;
        }
        this.routeEtsID = this.$route.query.item?.toString();
        await this.assignRouteEtsUnit();
        this.removeEventListener();
    }

    private beforeDestroy(): void {
        this.removeEventListener();
    }

    private addEventListener(): void {
        const element = document.getElementById('view-items');
        if (element) {
            element.addEventListener('scroll', this.onScroll);
            element.addEventListener('wheel', this.onScroll);
        }
    }

    private removeEventListener(): void {
        const element = document.getElementById('view-items');
        if (element) {
            element.removeEventListener('scroll', this.onScroll);
            element.removeEventListener('wheel', this.onScroll);
        }
    }

    private calculateScroll(): void {
        if (this.selectedEtsUnit) {
            return;
        }
        InfiniteScrollingHelper.calculateScroll(document.getElementById('view-items'), this.getNextPage, 200);
    }

    private closeDetailsView(): void {
        this.selectedEtsUnit = null;
        if (!this.isNewUnit) {
            this.$router.push({
                name: 'manage-option',
                params: {
                    option: 'ets-units-management',
                },
            });
        }
        this.isNewUnit = false;
    }
    private addNewEntity(): void {
        this.selectedEtsUnit = EtsUnitFactory.createEtsUnitAddRequest();
        this.isNewUnit = true;
    }
    private async searchCards(searchString: string): Promise<void> {
        this.filterModel.term = searchString;
        await this.getEtsUnits();
    }

    private async assignRouteEtsUnit() {
        if (this.etsUnits?.length && this.routeEtsID) {
            this.selectedEtsUnit =
                this.etsUnits.find((x) => this.routeEtsID && x.etsId === +this.routeEtsID) ?? null;
            if (!this.selectedEtsUnit) {
                this.filterModel.term = this.routeEtsID;
                this.etsUnits = await this.getPagedEtsUnits();
                this.selectedEtsUnit =
                    this.etsUnits.find((x) => this.routeEtsID && x.etsId === +this.routeEtsID) ?? null;
            }
        } else {
            this.selectedEtsUnit = null;
        }
    }

    private async getEtsUnits(): Promise<void> {
        this.filterModel.page = 1;
        this.etsUnits = await this.getPagedEtsUnits();
        await this.assignRouteEtsUnit();
    }

    private async getPagedEtsUnits(): Promise<EtsUnit[]> {
        this.$store.commit('loading');
        let etsUnits: EtsUnit[] = [];
        try {
            const { result: { items, hasNextPage } } = (await this.etsService.get(this.filterModel));
            this.hasNextPage = hasNextPage;
            return etsUnits = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingEtsUnits');
        } finally {
            this.$store.commit('loading');
        }
        return etsUnits;
    }

    private async getNextPage(): Promise<void> {
        this.removeEventListener();
        if (this.isLoadingNextPage) {
            return;
        }

        if (!this.hasNextPage) {
            return;
        }
        this.filterModel.page++;
        this.isLoadingNextPage = true;
        const etsUnits = await this.getPagedEtsUnits();
        this.etsUnits.push(...etsUnits);
        await this.$nextTick();
        this.addEventListener();
        this.isLoadingNextPage = false;
    }


    private saveChanges(etsUnit: EtsUnit, isAdd: boolean): void {
        if (isAdd) {
            return;
        }
        this.$router.push({
            name: 'manage-option',
            params: {
                option: 'ets-units-management',
            },
        });
        this.selectedEtsUnit = null;
        this.getEtsUnits();
    }

    private selectUnit(estUnit: EtsUnit): void {
        if (this.$route.path === '/master-data-management/ets-units-management' &&
            this.$route.query.item === estUnit.etsId.toString()) {
            return;
        }

        this.$router.push({
            name: 'manage-option',
            params: {
                option: this.$route.params.option,
            },
            query: {
                item: estUnit.etsId.toString(),
            },
        });
    }

    private async deleteClicked(card: EtsUnit): Promise<any> {
        try {
            await this.$dialog.confirm(
                { body: this.$t('areYouSureDeleteItem').toString() },
                { view: 'confirm' });
        } catch (error: any) {
            if (typeof error !== 'boolean') {
                throw error;
            }
            return;
        }
        return this.deleteCard(card);
    }

    private async deleteCard(card: EtsUnit): Promise<void> {
        this.$store.commit('loading');
        try {
            await this.etsService.delete(card.etsId);
            this.etsUnits = this.etsUnits.filter((u: EtsUnit) => u.etsId !== card.etsId);
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorRemovingEtsUnit');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async setSortedLocalEtsUnits(obj: {
        sortedList: EtsUnit[];
        order: string;
        sortKey: string;
    }): Promise<void> {

        const oldpg = this.filterModel.page;
        this.filterModel.size *= oldpg;
        this.filterModel.page = 1;
        if (obj.order === 'asc') {
            this.filterModel.sortDirection = 'Ascending';
        } else {
            this.filterModel.sortDirection = 'Descending';
        }
        this.filterModel.sortColumn = obj.sortKey;

        this.etsUnits = await this.getPagedEtsUnits();

        this.filterModel.page = oldpg;
        this.filterModel.size = 10;
    }
}

</script>

<style lang="less">
@import "~@/variables.less";
.ets-units-management {
    .ets-units-details {
        background: @white;
        border-bottom: 2px solid @grey-lighter;
    }
    .main-area {
        padding: 2.5rem;
        box-sizing: border-box;
        overflow-y: auto;
        height: 100%;
        .name {
            width: 25rem;
        }
        .sid {
            width: 8rem;
        }
        .is-deleted {
            width: 8rem;
        }
        .last-updated {
            width: 22rem
        }
        .last-updated-by {
            width: 20rem
        }
    }
    .header-row {
        &__item-ets-id,
        &__item-short-name,
        &__item-name,
        &__item-register-name,
        &__item-last-updated,
        &__item-last-updated-by,
        &__item-is-deleted {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            box-sizing: border-box;
            border-left: 2px solid @lighter-grey;
            padding: 0 1rem;
        }

        &__item-name {
            border-left: none;
            width: 19rem;
        }
        &__item-ets-id {
            width: 10rem;
        }
        &__item-is-deleted {
            width: 10rem;
        }
        &__item-last-updated {
            width: 24rem;
        }
        &__item-last-updated-by {
            width: 22rem;
        }
    }
}
</style>
