import { PlantFleetAddRequest } from '@/models';
import { DateHelper } from '@/utils/date-helper';

export class FleetVersion {

    public static mapGroupAddEditModel(plantGroup: FleetVersion): PlantFleetAddRequest {
        return {
            powerPlantSid: plantGroup?.powerPlantSid,
            validFrom: plantGroup.validFrom,
            fleetSid: plantGroup?.fleetSid,
            lastUpdateComment: plantGroup?.lastUpdateComment,
        } as PlantFleetAddRequest;
    }

    public powerPlantSid: number;
    public validFrom: string;
    public validTo?: string;
    public fleetSid?: number;
    public fleetName?: string;
    public lastUpdatedBy?: string;
    public lastUpdated?: string;
    public lastUpdateComment?: string;

    public constructor(data?: any) {
        this.powerPlantSid = data?.powerPlantSid;
        this.validFrom = data?.validFrom ?? DateHelper.formatDate(new Date());
        this.validTo = data?.validTo;
        this.fleetSid = data?.fleetSid;
        this.fleetName = data?.fleetName;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdated = data?.lastUpdated;
        this.lastUpdateComment = data?.lastUpdateComment;
    }
}
