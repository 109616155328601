import {EtsUnitModel} from '@/models/ets-unit-model';
import {IdentificationEtsUnitForm} from '@/models/form/identification-ets-unit-form';
import {format} from 'date-fns';
import {ONLY_DATE_FORMAT} from '@/utils/constants';

export const identificationEtsUnitForm = (data?: EtsUnitModel): IdentificationEtsUnitForm => {
    return {
        etsId: {
            value: data?.etsId,
            isValid: true,
        },
        shortName: {
            value: data?.shortName,
            isValid: true,
            validator: (val: string) => val.length > 0,
        },
        name: {
            value: data?.name,
            isValid: true,
            validator: (val: string) => val.length > 0,
        },
        registerName: {
            value: data?.registerName,
            isValid: true,
        },
        referenceNumber: {
            value: data?.referenceNumber,
            isValid: true,
        },
        lastUpdateComment: {
            value: data?.lastUpdateComment,
            isValid: true,
        },
        lastUpdated: {
            value: data?.lastUpdated ? format(new Date(data?.lastUpdated), ONLY_DATE_FORMAT) : '',
            isValid: true,
            isDate: true,
        },
        lastUpdatedBy: {
            value: data?.lastUpdatedBy,
            isValid: true,
        },
        isDeleted: {
            value: data?.isDeleted ?? false,
            isValid: true,
        },
    };
};
