import { PlantOperatorAddRequest } from '@/models';
import { DateHelper } from '@/utils/date-helper';

export class OperatorVersion {

    public static mapGroupAddEditModel(plantGroup: OperatorVersion): PlantOperatorAddRequest {
        return {
            powerPlantSid: plantGroup?.powerPlantSid,
            validFrom: plantGroup.validFrom,
            operatorSid: plantGroup?.operatorSid,
            lastUpdateComment: plantGroup?.lastUpdateComment,
        } as PlantOperatorAddRequest;
    }

    public powerPlantSid: number;
    public validFrom: string;
    public validTo?: string;
    public operatorSid?: number;
    public operatorName?: string;
    public lastUpdatedBy?: string;
    public lastUpdated?: string;
    public lastUpdateComment?: string;

    public constructor(data?: any) {
        this.powerPlantSid = data?.powerPlantSid;
        this.validFrom = data?.validFrom ?? DateHelper.formatDate(new Date());
        this.validTo = data?.validTo;
        this.operatorSid = data?.operatorSid;
        this.operatorName = data?.operatorName;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdated = data?.lastUpdated;
        this.lastUpdateComment = data?.lastUpdateComment;
    }
}
