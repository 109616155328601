<template>
    <div class="loader-container" v-show="isLoading">
        <div class="loader-backdrop"></div>
        <div class="loader center-align">
            <h3>{{ $t('loading') }}</h3>
            <div class="bar-container">
                <div class="bar"></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class Loader extends Vue {

    constructor() {
        super();
    }

    get isLoading(): boolean {
        return this.$store.getters.isLoading;
    }
}

</script>

<style scoped lang="less">
@import "~@/variables.less";

.loader-container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 99999;

    .loader-backdrop {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: #000;
        opacity: 0.2;
        z-index: 12;
    }

    .loader {
        width: 30%;
        height: 30%;
        max-height: 100px;
        max-width: 340px;
        z-index: 13;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        border-radius: 3px;
        background: @uniper-blue;
        box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.2);

        h3 {
            color: #fff;
        }

        .bar-container {
            width: 80%;
            height: 18px;
            background: #44ABFF;
            margin: auto;
            overflow: hidden;

            .bar {
                height: inherit;
                width: 33%;
                margin-left: -50px;
                background: #fff;
                opacity: 1;
                animation: left-to-right 1.5s;
                animation-iteration-count: infinite;
            }
        }
    }

    .center-align {
        padding: 2rem 0;
    }

    @keyframes left-to-right {
        0% {
            margin-left: -33%
        }
        100% {
            margin-left: 133%
        }
    }
}
</style>
