import { Header } from '@/models';
import { DateHelper } from '@/utils';

const reservoirHeader: Header = {
    columns: [
        {
            text: 'validFrom',
            displayKey: 'validFrom',
            class: 'flex-1 mr-1',
            formatFunction: (date: string) => DateHelper.formatDate(date),
        },
        {
            text: 'validUntil',
            displayKey: 'validTo',
            class: 'flex-1 mr-1',
            formatFunction: (date: string) => DateHelper.formatDate(date),
        },
        {
            text: 'reservoirName',
            displayKey: 'reservoirName',
            class: 'flex-2 mr-1',
            formatFunction: null,
        },
        {
            text: 'lastUpdated',
            displayKey: 'lastUpdated',
            class: 'flex-1 mr-1',
            formatFunction: (date: string) => DateHelper.formatDate(date),
        },
        {
            text: 'lastUpdatedBy',
            displayKey: 'lastUpdatedBy',
            class: 'flex-1 mr-1',
            formatFunction: null,
        },
    ],
};

export default reservoirHeader;
