<template>
    <div class="flex-row mt-3">
        <span>
            <i>{{ $t('lastUpdated') }}: {{ formatDate(data.lastUpdated) }}</i>
        </span>
        <span class="ml-3"><i>{{ `${$t('lastUpdatedBy')}: ${data.lastUpdatedBy}` }}</i></span>
    </div>
</template>

<script lang="ts">
import { DateHelper } from '@/utils';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'last-updated',
    components: {},
})
export default class LastUpdated extends Vue {
    /**
     *  Item recieved for displaying last updated information for date and user.
     */
    @Prop()
    private data!: any;

    private formatDate(date: string): string {
        return DateHelper.formatDate(date);
    }
}
</script>