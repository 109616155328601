export class UnitVersionItem {
    public index?: number;
    public unitSid: number;
    public abbreviation?: string;
    public name?: string;
    public validFrom: string;
    public validTo: string;
    public lastUpdatedBy?: string;
    public lastUpdated?: Date;
    public lastUpdateComment?: string;

    constructor(data?: any, index?: number) {
        this.index = index;
        this.unitSid = data?.unitSid;
        this.abbreviation = data?.abbreviation;
        this.name = data?.name;
        this.validFrom = data?.validFrom;
        this.validTo = data?.validTo;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdated = data?.lastUpdated;
        this.lastUpdateComment = data?.lastUpdateComment;

    }
}
