<template>
    <div class="key-mapping-management">
        <validation-observer ref="validationObserver">
            <div v-if="selectedVersion || isCreatingNewItem" class="key-mapping-management__form">
                <validation-provider
                    tag="div"
                    class="manage-input mt-3"
                    name="objectId"
                    rules="required"
                    #default="{ errors, failed }">
                    <label class="mr-2 default-label" for="objectsid">{{ $t('fields.objectId') }}*:</label>
                    <input class="default-input" v-model="formModel.keyMapping"
                           :class="{ 'invalid': failed }"
                           :disabled="!isEditMode"/>
                    <error-list :errors="errors"/>
                </validation-provider>
                <validation-provider
                    tag="div"
                    class="manage-input mt-3"
                    name="validFrom"
                    rules="required"
                    #default="{ errors, failed }">
                    <label class="mr-2 default-label">{{ $t('validFrom') }}*:</label>
                    <md-date-picker
                        v-model="formModel.validFrom"
                        :class="{ 'invalid': failed }"
                        :disabled-date="checkDisabledDate"
                        :disabled="!isEditMode"/>
                    <error-list :errors="errors"/>
                </validation-provider>
                <div class="manage-input mt-3"
                     v-if="!isCreatingNewItem && !isEditMode &&
                        selectedVersion.validFrom === formModel.validFrom">
                    <label class="mr-2 default-label">{{ $t('validUntil') }}:</label>
                    <md-date-picker
                        v-model="formModel.validTo"
                        :disabled-date="checkDisabledDate"
                        disabled/>
                </div>
                <validation-provider
                    tag="div"
                    class="manage-input mt-3"
                    name="comments"
                    rules="max:1000"
                    #default="{ errors, failed }">
                    <label class="mr-2 default-label" for="comments">{{ $t('comments') }}:</label>
                    <textarea class="default-textarea" :placeholder="$t('comments')" id="comments" rows="4"
                              maxlength="1000"
                              :disabled="!isEditMode"
                              :class="{ 'invalid': failed }"
                              v-model="formModel.lastUpdateComment"/>
                    <error-list :errors="errors"/>
                </validation-provider>
                <validation-provider tag="div" class="flex-col mt-3">
                    <checkbox-input
                        v-model="formModel.isActive"
                        :label="$t('markAsActive')"
                        :disabled="!isEditMode"
                    />
                </validation-provider>
                <validation-provider tag="div" class="flex-col mt-1 mb-2">
                    <checkbox-input
                        v-model="formModel.isDeleted"
                        :label="$t('markAsDeleted')"
                        :disabled="!isEditMode"
                    />
                </validation-provider>
            </div>
            <error-list :errors="errorList"/>
            <div v-if="!isEditMode"
                 class="key-mapping-management__buttons flex-row manage-input mt-3">
                <adam-button v-if="selectedVersion && hasMasterDataAdmin" icon="ic-remove" secondary @click="handleRemoveClick">
                    {{ $t('remove') }}
                </adam-button>
                <adam-button v-if="selectedVersion && hasMasterDataAdmin" secondary class="ml-1" @click="handleEditClick">
                    {{ $t('edit') }}
                </adam-button>
                <adam-button v-if="hasMasterDataAdmin" icon="ic-add-dashboard" secondary class="ml-1" @click="handleAddNewClick">
                    {{ $t('addNew') }}
                </adam-button>
            </div>
            <div v-else class="key-mapping-management__buttons flex-row manage-input mt-3">
                <adam-button v-if="hasMasterDataAdmin" class="mr-1" secondary @click="handleCancelClick">
                    {{ $t('cancel') }}
                </adam-button>
                <adam-button v-if="hasMasterDataAdmin" @click="handleSaveClick" secondary>
                    {{ $t('save') }}
                </adam-button>
            </div>
        </validation-observer>
    </div>
</template>

<script lang="ts">
import ErrorList from '@/components/error-list/error-list.vue';
import ComponentSecurity from '@/mixins/component-security';
import {Asset, KeyMappingGetResponse} from '@/models';
import {DATE_FORMAT, EventBus, extractErrorsFromResponse, InfiniteScrollingHelper, MIN_DATE} from '@/utils';
import {ValidationObserver} from 'vee-validate';
import {Component, Mixins, Prop, Ref, Watch} from 'vue-property-decorator';
import format from 'date-fns/format';
import CheckboxInput from '@/components/checkbox-input/checkbox-input.vue';
import {KeyMappingService} from '@/services';

@Component({
    name: 'key-mapping-management',
    components: {
        errorList: ErrorList,
        checkboxInput: CheckboxInput,
    },
})
export default class KeyMappingManagement extends Mixins(ComponentSecurity) {
    @Ref()
    private readonly validationObserver!: InstanceType<typeof ValidationObserver>;

    /**
     * Model for the form.
     */
    @Prop({required: true})
    private selectedVersion!: KeyMappingGetResponse;

    /**
     * The system for which we do the key mapping.
     */
    @Prop({required: true})
    private selectedSystem!: Asset | null;

    /**
     * The SID of the object we are mapping for.
     */
    @Prop({required: true})
    private objectSid!: number;

    private keyMappingService: KeyMappingService = new KeyMappingService();

    private formModel: KeyMappingGetResponse = {...this.selectedVersion};
    private isEditMode = false;
    private isCreatingNewItem = false;
    private errorList: string[] = [];

    public isFormDirty(): boolean {
        return this.validationObserver.flags.dirty;
    }

    @Watch('selectedVersion')
    private onSelectedItemChange(newVal: KeyMappingGetResponse): void {
        this.setFormData(newVal);
        this.validationObserver?.reset();
        this.isEditMode = false;
        this.isCreatingNewItem = false;
    }

    private setFormData(formModel: KeyMappingGetResponse) {
        this.errorList = [];
        this.formModel = {...formModel};
    }

    private checkDisabledDate(date: Date): boolean {
        return date < MIN_DATE;
    }

    private handleAddNewClick() {
        this.errorList = [];
        const emptyFormModel: KeyMappingGetResponse = {
            objectSid: this.objectSid,
            validFrom: format(new Date(), DATE_FORMAT),
            validTo: '2999-12-31T00:00:00.000',
            enumStreamSystemSid: this.selectedSystem ? this.selectedSystem.sid : 0,
            keyMapping: '',
            isActive: true,
            isDeleted: false,
            isExclusive: false,
            lastUpdateComment: '',
            lastUpdatedBy: '',
            lastUpdated: '',
        };
        this.setFormData(emptyFormModel);
        this.isCreatingNewItem = true;
        this.isEditMode = true;
    }

    private handleEditClick() {
        this.errorList = [];
        this.isEditMode = true;
    }

    private handleCancelClick() {
        this.validationObserver?.reset();
        this.setFormData(this.selectedVersion);
        this.isCreatingNewItem = false;
        this.isEditMode = false;
    }

    private async handleRemoveClick(): Promise<void> {
        if (!this.selectedVersion || !this.selectedSystem) {
            return;
        }

        try {
            await this.$dialog.confirm(
                {body: this.$t('areYouSureDeleteItem').toString()},
                {view: 'confirm'});
        } catch (error: any) {
            return;
        }

        this.$store.commit('loading');
        try {
            await this.keyMappingService.removeVersion(this.selectedSystem.sid, this.selectedVersion.validFrom,
                this.selectedVersion.objectSid);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            this.$emit('update-versions');
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorRemovingItem');
            if (!error.response || !error.response.data) {
                throw error;
            }
            this.errorList = extractErrorsFromResponse(error.response.data);
            if (this.errorList.length) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.error-list__message');
                });
            }
        } finally {
            this.$store.commit('loading');
        }
    }

    private async handleSaveClick(): Promise<void> {
        if (!this.formModel) {
            return;
        }

        const isValid = await this.validationObserver.validate();
        if (!isValid) {
            await this.$nextTick();
            InfiniteScrollingHelper.scrollToFirstInvalidElement('.invalid');
            return;
        }

        if (this.isCreatingNewItem) {
            await this.handleSaveFlow(this.formModel);
        } else {
            await this.handleUpdateFlow(this.formModel);
        }
    }

    private async handleSaveFlow(payload: KeyMappingGetResponse): Promise<void> {
        if (!this.selectedSystem || !this.objectSid) {
            return;
        }

        this.$store.commit('loading');
        try {
            const {result: {validFrom}} = await this.keyMappingService
                .addVersion(this.selectedSystem.sid, payload, this.objectSid);
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            this.isCreatingNewItem = false;
            this.isEditMode = false;
            this.$emit('update-versions', validFrom);
            this.validationObserver?.reset();
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            if (!error.response || !error.response.data) {
                throw error;
            }
            this.errorList = extractErrorsFromResponse(error.response.data);
            if (this.errorList.length) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.error-list__message');
                });
            }
        } finally {
            this.$store.commit('loading');
        }
    }

    private async handleUpdateFlow(payload: KeyMappingGetResponse): Promise<void> {
        if (!this.selectedVersion.validFrom || !this.objectSid) {
            return;
        }

        this.$store.commit('loading');
        try {
            const {result: {validFrom}} = await this.keyMappingService
                .editVersion(payload, this.selectedVersion.validFrom, this.objectSid);
            this.isEditMode = false;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            this.$emit('update-versions', validFrom);
            this.validationObserver?.reset();
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            if (!error.response || !error.response.data) {
                throw error;
            }
            this.errorList = extractErrorsFromResponse(error.response.data);
            if (this.errorList.length) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.error-list__message');
                });
            }
        } finally {
            this.$store.commit('loading');
        }
    }
}
</script>

<style scoped lang="less">
.key-mapping-management {
    label {
        margin-top: 1rem;
        min-width: fit-content;
    }

    &__buttons {
        justify-content: flex-end;
    }
}
</style>
