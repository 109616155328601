<template>
    <div class="flex-row flex-left">
        <pui-tooltip />
        <pui-filter
            v-if="!isLoading"
            :use-router="false"
            @changed:applied-values="filterValueChanged"
            :config="{
                filters: filtersConfig
            }"
            vuex-namespace="ns1"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SelectOption } from '@/models';
import { namespace } from 'vuex-class';

const assetOverviewModule = namespace('AssetOverviewModule');

@Component({
    name: 'table-multi-filter',
})

export default class AssetOverviewMultiFilter extends Vue {
    @Prop({ required: true }) private selectedCountries!: number[];
    @Prop({ required: true }) private selectedMachineTypes!: number[];
    @Prop({ required: true }) private selectedPrimaryEnergies!: number[];
    @Prop({ required: true }) private selectedRivers!: number[];
    @Prop({ required: true }) private selectedRegionalUnits!: number[];
    @Prop({ required: true }) private selectedStatuses!: number[];
    @Prop({ required: true }) private selectedLegalEntities!: number[];
    @Prop({ required: true }) private selectedUnitTechnologies!: number[];

    @assetOverviewModule.Action
    private loadAssetOverviewAssets!: () => Promise<void>;

    @assetOverviewModule.State
    private countries!: SelectOption[];

    @assetOverviewModule.State
    private machineTypes!: SelectOption[];

    @assetOverviewModule.State
    private primaryEnergies!: SelectOption[];

    @assetOverviewModule.State
    private rivers!: SelectOption[];

    @assetOverviewModule.State
    private regionalUnits!: SelectOption[];

    @assetOverviewModule.State
    private statuses!: SelectOption[];

    @assetOverviewModule.State
    private legalEntities!: SelectOption[];

    @assetOverviewModule.State
    private unitTechnologies!: SelectOption[];

    private filterKeys = {
        country: this.$t('country') as string,
        machineType: this.$t('machineType') as string,
        primaryEnergy: this.$t('primaryEnergy') as string,
        river: this.$t('river') as string,
        regionalUnit: this.$t('regionalUnit') as string,
        status: this.$t('status') as string,
        legalEntity: this.$t('legalEntity') as string,
        unitTechnology: this.$t('unitTechnology') as string,
    };

    private isLoading = false;

    private async created(): Promise<void>  {
        this.isLoading = true;
        await this.loadAssetOverviewAssets();
        this.isLoading = false;
    }

    private filterValueChanged(filters: any): void {
        if (!filters) {
            return;
        }
        const countries = filters[this.filterKeys?.country];
        const machineTypes = filters[this.filterKeys?.machineType];
        const primaryEnergies = filters[this.filterKeys?.primaryEnergy];
        const rivers = filters[this.filterKeys?.river];
        const regionalUnits = filters[this.filterKeys?.regionalUnit];
        const statuses = filters[this.filterKeys?.status];
        const legalEntities = filters[this.filterKeys?.legalEntity];
        const unitTechnologies = filters[this.filterKeys?.unitTechnology];
        this.$emit('onFilterChange', [
            countries, machineTypes, primaryEnergies, rivers, regionalUnits, statuses, legalEntities, unitTechnologies,
        ]);
    }

    private get filtersConfig(): Array<any> {
        const filters = {
            [this.filterKeys?.country]: {
                label: this.filterKeys?.country,
                options: this.countries?.map((item: SelectOption) => ({
                    value: item?.value,
                    displayName: item?.label,
                })),
                selectedValues: this.selectedCountries,
            },
            [this.filterKeys?.machineType]: {
                label: this.filterKeys?.machineType,
                options: this.machineTypes?.map((item: SelectOption) => ({
                    value: item?.value,
                    displayName: item?.label,
                })),
                selectedValues: this.selectedMachineTypes,
            },
            [this.filterKeys?.primaryEnergy]: {
                label: this.filterKeys?.primaryEnergy,
                options: this.primaryEnergies?.map((item: SelectOption) => ({
                    value: item?.value,
                    displayName: item?.label,
                })),
                selectedValues: this.selectedPrimaryEnergies,
            },
            [this.filterKeys?.river]: {
                label: this.filterKeys?.river,
                options: this.rivers?.map((item: SelectOption) => ({
                    value: item?.value,
                    displayName: item?.label,
                })),
                selectedValues: this.selectedRivers,
            },
            [this.filterKeys?.regionalUnit]: {
                label: this.filterKeys?.regionalUnit,
                options: this.regionalUnits?.map((item: SelectOption) => ({
                    value: item?.value,
                    displayName: item?.label,
                })),
                selectedValues: this.selectedRegionalUnits,
            },
            [this.filterKeys?.status]: {
                label: this.filterKeys?.status,
                options: this.statuses?.map((item: SelectOption) => ({
                    value: item?.value,
                    displayName: item?.label,
                })),
                selectedValues: this.selectedStatuses,
            },
            [this.filterKeys?.legalEntity]: {
                label: this.filterKeys?.legalEntity,
                options: this.legalEntities?.map((item: SelectOption) => ({
                    value: item?.value,
                    displayName: item?.label,
                })),
                selectedValues: this.selectedLegalEntities,
            },
            [this.filterKeys?.unitTechnology]: {
                label: this.filterKeys?.unitTechnology,
                options: this.unitTechnologies?.map((item: SelectOption) => ({
                    value: item?.value,
                    displayName: item?.label,
                })),
                selectedValues: this.selectedUnitTechnologies,
            },
        };

        return Object.values(filters).map((filter: any) => ({
            name: filter.label,
            displayName: filter.label,
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: filter.label,
                options: filter.options,
            },
            selectedValues: {
                options: filter.selectedValues,
            },
            appliedValues: {
                options: filter.selectedValues,
            },
        }));
    }
}
</script>
