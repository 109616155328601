<template>
    <div :class="getClasses">
        <slot />
    </div>
</template>


<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'card',
})
export default class Card extends Vue {
    @Prop({ default: false })
    private enableFullHeight!: boolean;

    @Prop({ default: false })
    private enableScroll!: boolean;

    private get getClasses(): string {
        return `card ${this.enableFullHeight ? 'card-full-height' : ''} ${this.enableScroll ? 'card-scroll' : ''}`;
    }
}
</script>

<style scoped lang="less">
@import "~@/variables.less";
@margin-size: 32px;

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: @margin-size;
    padding: 16px;
    background-color: white;
    max-width: @xxxl;
}

.card-full-height {
    height: calc(100% - (@margin-size * 2)) !important;
}

.card-scroll {
    overflow: auto;
}
</style>
