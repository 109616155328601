import { ApiResponse, AssetHierarchy, HierarchicPowerPlantSystem,
    HiearchicPowerPlantQueryModel, PowerPlantListItem, HierarchyRequestParams,
} from '@/models';
import { HiearchicPowerPlantParentQueryModel } from '@/models/filter/query-filter';
import { replaceRefs } from 'json-serialize-refs';
import { EventBus } from '@/utils';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
import { generateQuery } from '@/utils/api-helper';

class HierarchyService {
    private api = new SdkGenericApiHelper('hierarchy');

    public async getHierarchycalAssets(params: any = null): Promise<ApiResponse<AssetHierarchy>> {
        const query = generateQuery(params);
        const { data } = await this.api.get(query);
        const dataWithReplacedRefs = replaceRefs(data);

        return dataWithReplacedRefs;
    }

    public async getHierarchicPowerPlants(
                    params: HiearchicPowerPlantQueryModel): Promise<HierarchicPowerPlantSystem[]> {
        const query = generateQuery(params as any);
        const { data: { result: { items } } } = await this.api.get(query, 'power-plants');

        return items;
    }

    public async getHierarchicPowerPlantsParent(
        params: HiearchicPowerPlantParentQueryModel): Promise<HierarchicPowerPlantSystem[]> {
        const query = generateQuery(params as any);
        const { data: { result: { items } } } = await this.api.get(query, 'power-plants');
        return items;
    }


    public async getHierarchyPowerPlants(requestParams: HierarchyRequestParams): Promise<[PowerPlantListItem[], number]> {
        try {
            const queryParams: any = {...requestParams};
            delete queryParams.nodeLevel;
            const query = generateQuery(queryParams as any);
            const result = (await this.api.get(query, `power-plants/node-level/${requestParams.nodeLevel}`)).data.result;
            const powerPlants = result?.items?.map((item: any) => new PowerPlantListItem(item)) ?? [];
            const totalCount = result?.totalCount ?? 0;

            return [powerPlants, totalCount];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingPowerPlantGroups');
            return [[], 0];
        }
    }

    public async getHierarchyPowerPlantsWithChildren(requestParams: HierarchyRequestParams): Promise<[PowerPlantListItem[], number]> {
        try {
            const items = (await this.getHierarchicPowerPlants({
                countries: requestParams?.countries,
                machineTypes: requestParams?.machineTypes,
                classTypes: requestParams?.classTypes,
                fleets: requestParams?.fleets,
                plantGroups: requestParams?.plantGroups,
                includeDeleted: requestParams?.includeDeleted,
                sortDirection: requestParams?.sortDirection,
                sortColumn: requestParams?.sortColumn,
                term: requestParams?.searchTerm ?? ''
            }));
            const powerPlants = items?.map((item) => new PowerPlantListItem(item)) ?? [];
            const totalCount = items?.length ?? 0;

            return [powerPlants, totalCount];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingPowerPlantGroups');
            return [[], 0];
        }
    }
}

export const hierarchyService = new HierarchyService();
