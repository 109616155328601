import { DateHelper } from '@/utils/date-helper';

export class ObjectDistributionListItem {
    public machineId: number;
    public validFrom: string;
    public validTo: string;
    public distributionChannelId: number;
    public isDeleted: boolean;
    public lastUpdateComment: string;
    public lastUpdatedUser: string;
    public lastUpdateDate: string;

    constructor(data?: any) {
        this.machineId = data?.machineId;
        this.validFrom = data?.validFrom ?? DateHelper.formatDate(new Date());
        this.validTo = data?.validTo;
        this.distributionChannelId = data?.distributionChannelId;
        this.isDeleted = data?.isDeleted;
        this.lastUpdateComment = data?.lastUpdateComment;
        this.lastUpdatedUser = data?.lastUpdatedUser;
        this.lastUpdateDate = data?.lastUpdateDate;
    }
}
