export class NetworkOperator {
    public connectionSid: number;
    public networkOperatorSid: number;
    public locationSid?: number;
    public locationName?: string;
    public newLevelSid?: number;
    public newLevelName?: string;
    public newConnectionSid?: number;
    public newConnectionName?: string;
    public lastUpdateComment?: string;
    public isDeleted?: boolean;
    public tso?: boolean;
    public lastUpdated?: string;
    public lastUpdatedBy?: string;

    constructor(data?: any) {
        this.connectionSid = data?.connectionSid;
        this.networkOperatorSid = data?.networkOperatorSid;
        this.locationSid = data?.locationSid;
        this.locationName = data?.locationName;
        this.newLevelSid = data?.newLevelSid;
        this.newLevelName = data?.newLevelName;
        this.newConnectionSid = data?.newConnectionSid;
        this.newConnectionName = data?.newConnectionName;
        this.lastUpdateComment = data?.lastUpdateComment;
        this.isDeleted = data?.isDeleted;
        this.tso = data?.tso;
        this.lastUpdated = data?.lastUpdated;
        this.tso = data?.tso;
        this.lastUpdatedBy = data?.lastUpdatedBy;
    }
}
