<template>
    <card>
        <pui-grid-container>
            <card-header
                :title="name"
                :chip-title="sid"
                :on-click-back-button="onClickBackButtons"
            />
            <pui-grid-row>
                <pui-grid-column class="pui-grid-column-zero-padding pui-grid-row-little-margin-top">
                    <!--
                    TODO BUG: When I change tab there is no way to stop the change of
                        the tab if by chance a condition is not valid, in fact if you
                        make a change on the form of any page, the latter will be
                        automatically lost.
                    -->
                    <pui-tabs
                        :selected="selectedTab"
                        @changed="onTabChange"
                    >
                        <pui-tab
                            v-for="tab in tabs"
                            :key="tab.title"
                            :title="tab.title"
                        >
                            <component
                                :is="tab.componentName"
                                :sid="sid"
                                :id-type-sid="idTypeSid"
                                @on-form-changed="onFormChanged"
                            />
                        </pui-tab>
                    </pui-tabs>
                </pui-grid-column>
            </pui-grid-row>
        </pui-grid-container>
    </card>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import ComponentSecurity from '@/mixins/component-security';
import Card from '@/components/cards/card.vue';
import BackText from '@/components/text/back-text.vue';
import {PebbleTab} from '@/models/pebble/pebble-tab';
import IdentificationEtsUnitTab from '@/components/tab-bar/tabs/identification-ets-unit-tab.vue';
import {manageResolve} from '@/utils/utils';
import KeyMappingTab from '@/components/tab-bar/tabs/key-mapping-tab.vue';
import {IdTypeSid} from '@/models';
import CardHeader from '@/components/cards/card-header.vue';

@Component({
    name: 'management-ets-unit-edit',
    components: {
        CardHeader,
        BackText, Card,
        IdentificationEtsUnitTab,
        KeyMappingTab,
    },
})
export default class ManagementEtsUnitEdit extends mixins(ComponentSecurity) {
    /* VARIABLES */
    private name?: string = '';
    private sid?: string = '';
    private selectedTab?: number = 0;
    private formIsNotEmpty = false;
    private idTypeSid = IdTypeSid.EXTERNAL_SYSTEM;

    get tabs(): PebbleTab[] {
        return [
            {
                title: this.$t('identification').toString(),
                componentName: 'identification-ets-unit-tab',
            },
            {
                title: this.$t('keyMapping').toString(),
                componentName: 'key-mapping-tab',
            },
        ];
    }

    /* PRIMITIVE METHODS */
    private mounted(): void {
        this.$router.beforeResolve((to, from, next) => manageResolve(this.formIsNotEmpty, next, this));
        this.sid = this.$route.query['edit']?.toString();
        this.name = this.$route.query['name']?.toString() ?? this.$t('unknown');
        this.selectedTab = +this.$route.query['tab'] ?? 0;
        if (this.selectedTab > this.tabs.length - 1) {
            this.selectedTab = 0;
            this.replacePage();
        }
    }

    private destroyed(): void {
        this.formIsNotEmpty = false;
    }

    /* METHODS */
    private async replacePage(index = 0): Promise<void> {
        await this.$router.replace({
            name: 'management-ets-unit-edit',
            query: {
                edit: this.$route.query.edit,
                name: this.$route.query.name,
                tab: `${index}`,
                backPage: this.$route.query.backPage,
            },
        });
    }

    private onTabChange(index: number): void {
        this.formIsNotEmpty = false;
        this.replacePage(index);
    }

    private onFormChanged(value: boolean): void {
        this.formIsNotEmpty = value;
    }

    /* ON CLICK BUTTONS */
    private onClickBackButtons(): void {
        if (this.$route.query['backPage'] === 'true' ?? false) {
            this.$router.back();
        } else {
            this.$router.push({
                name: `management-ets-unit`,
            });
        }
    }
}
</script>


