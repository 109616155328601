import { FormValuesModel } from '@/models/pebble/form-values-model';
import format from 'date-fns/format';
import { ONLY_DATE_FORMAT } from '@/utils/constants';
import { extractErrorsFromResponse } from '@/utils/api-helper';
import { CustomObject } from '@/models/custom-object';
import { PebbleNotification } from '@/models/pebble/pebble-notification';
import i18n from '@/utils/i18n';
import { formatISO } from 'date-fns';

export function timeout(callback: () => void, ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => {
        callback();
        resolve();
    }, ms));
}

export function firstUpperCase(value: string): string {
    if (value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return '';
}

export function validateIfNotEmpty(validationFn: (value: string) => boolean): (value: string) => boolean {
    return (value: string) => {
        if (value === '' || value === null || value === undefined) {
            return true;
        }

        return validationFn(value);
    };
}

export function validateRequired(validationFn: (value: string) => boolean): (value: string) => boolean {
    return (value: string) => {
        if (value === '' || value === null || value === undefined) {
            return false;
        }

        return validationFn(value);
    };
}

export function isNotEmpty(value: any): boolean {
    if (value === '' || value === null || value === undefined) {
        return false;
    }

    return true;
}

export function isNumberAsString(value: string): boolean {
    const parsedNumber = Number(value);

    return !isNaN(parsedNumber);
}

export function isDate(value: string): boolean {
    return new Date(value).toString() !== 'Invalid Date' && formatISO(new Date(value), {
        format: 'extended',
        representation: 'date',
    }) === value.split('T')[0];
}

export function scrollToId(id: string): void {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({
            behavior: 'smooth',
        });
    }
}

export async function showDialog(self: any, message: string) {
    try {
        await self.$dialog
            .confirm(
                { body: self.$i18n.t(message).toString() },
                { view: 'confirm' });
        return true;
    } catch (error: any) {
        return Promise.resolve(false);
    }
}

export function isValidInput(name: string, input: FormValuesModel): void {
    if (input?.validator) {
        input.isValid = input.validator(input.value);
    }
}

export function shouldDisableForm(formValues: any, equalObject?: any): boolean {
    const validator: boolean = Object.keys(formValues).map((inputName) => {
        const input: FormValuesModel = formValues[inputName];
        if (input.validator) {
            return input.validator(input.value);
        }
        return true;
    },
    ).every((value) => value);
    if (equalObject) {
        const values: boolean = Object.keys(formValues).map((inputName) => {
            const input: FormValuesModel = formValues[inputName];
            const data: any = equalObject?.[inputName];
            if (input.isDate) {
                return format(new Date(data), ONLY_DATE_FORMAT) === format(new Date(input.value), ONLY_DATE_FORMAT);
            } else {
                if (data === undefined && input.validator) {
                    return false;
                } else {
                    if (input.value?.toString().length === 0) {
                        return true;
                    }
                    if (input.value && input.value === -1) {
                        return true;
                    }
                    return `${data}` === `${input.value}`;
                }
            }
        },
        ).includes(false);
        return !(validator && values);
    }
    return !validator;
}

export function generateDataFromFormValues(formValues: any): CustomObject {
    const data: CustomObject = {};
    Object.keys(formValues).forEach((inputName: string) => {
        if (formValues[inputName]?.isDropDown) {
            if (formValues[inputName].value?.toString() !== '-1') {
                data[inputName] = formValues[inputName].value;
            }
        } else {
            if (formValues[inputName]?.value?.toString().length > 0) {
                data[inputName] = formValues[inputName].value;
            }
        }
    });
    return data;
}

export function generateErrors(errors: PebbleNotification[], error: any): void {
    if (!error.response || !error.response.data) {
        throw error;
    }
    const messages = extractErrorsFromResponse(error.response.data);
    errors.length = 0;
    errors.push(...messages.map((value) => {
        return {
            type: 'error',
            title: i18n.t('errorTitlePebbleErrorsList').toString(),
            text: value,
        };
    }));
    scrollToId('errors-list');
}

export async function manageResolve(condition: boolean, next: () => void, self: any) {
    if (condition) {
        if (await showDialog(self, 'areYouSureLeaveUnsaved')) {
            next();
        } else {
            await self.$router.go(1);
        }
    } else {
        next();
    }
}
