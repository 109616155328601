import { VuexModule, Module, MutationAction } from 'vuex-module-decorators';
import { HierarchyEvidence, PowerPlantListItem } from '@/models';

@Module({ namespaced: true })
class HierarchyTableModule extends VuexModule {
    public storedTableData: PowerPlantListItem[] = [];
    public storedHierarchyEvidence: HierarchyEvidence[] = [];

    @MutationAction({ mutate: ['storedTableData'] })
    public storeTableData(tableData: PowerPlantListItem[]): any {
        return { storedTableData: tableData };
    }

    @MutationAction({ mutate: ['storedHierarchyEvidence'] })
    public storeHierarchyEvidence(hierarchyEvidence: HierarchyEvidence[]): any {
        return { storedHierarchyEvidence: hierarchyEvidence };
    }
}

export default HierarchyTableModule;
