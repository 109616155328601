import { Vue, Prop } from 'vue-property-decorator';
import { extend } from 'vee-validate';
import { required, numeric,
    alpha_num, min_value, max_value, // eslint-disable-line
} from 'vee-validate/dist/rules';
import i18n from '@/utils/i18n';

extend('required', {
    ...required,
    message: `${i18n.t('validations.isRequired')}`,
});

extend('alpha_num', {
    ...alpha_num, // eslint-disable-line
    message: `${i18n.t('validations.onlyAlphanumericCharacters')}`,
});

extend('numeric', {
    ...numeric,
    message: `${i18n.t('validations.numeric')}`,
});

extend('decimal', {
    validate: (value, { decimals = '*', separator = '.' }: any = {}) => {
        if (value === null || value === undefined || value === '') {
            return {
                valid: false,
            };
        }
        if (Number(decimals) === 0) {
            return {
                valid: /^-?\d*$/.test(value),
            };
        }
        const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
        const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

        return {
            valid: regex.test(value),
        };
    },
    message: `${i18n.t('validations.decimal')}`,
});

extend('min_value', {
    ...min_value, // eslint-disable-line
    params: ['min_value'],
    message: `${i18n.t('validations.equalOrHigher')}`,
    validate(value, args: any) {
        return value >= args.min_value;
    },
});

extend('max_value', {
    ...max_value, // eslint-disable-line
    params: ['max_value'],
    message: `${i18n.t('validations.equalOrLower')}`,
    validate(value, args: any) {
        return value <= args.max_value;
    },
});

extend('max_decimals', {
    params: ['max_decimals'],
    message: `${i18n.t('validations.exactDecimals')}`,
    validate(value: string, args: any) {
        if (!value.toString().includes('.')) {
            return true;
        }
        const regex = new RegExp(`^[0-9]*\\.[0-9]{${args.max_decimals}}$`);
        return regex.test(value);
    },
});

export default class ValidationSetup extends Vue {
    @Prop({required: false, default: () => ({}) })
    protected rules!: any;

    @Prop({required: false, default: false })
    protected showInvalid!: any;

    protected debounceMode(): any {
        return {
            on: ['input'],
            debounce: 350,
        };
    }

    protected markAsError(errorLength: number): boolean {
        return this.showInvalid
            ? errorLength > 0
            : false;
    }

    protected get isRequired(): boolean {
        return this.rules?.required;
    }
}
