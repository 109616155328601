<template>
    <div v-if="isLoaded">
        <slot />
    </div>
    <div
        class="placeholder-div"
        v-else
    >
        <div v-if="isError">
            <pui-loader-error
                :title="errorTitle"
                :message="errorSubTitle"
                icon="error-empty-data"
                :buttons="[{ state: 'secondary', label: refreshButtonTitle, onClick: onRefreshClick }]"
            />
        </div>
        <div v-else>
            <pui-loader-spinner
                :title="waitingTitle"
                size="80px"
                min-height="200px"
            />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Emit, Prop, PropSync } from 'vue-property-decorator';
import { PebbleStatus } from '@/models/pebble/enums/pebble-status';
import Component from 'vue-class-component';
import i18n from '@/utils/i18n';

@Component({
    name: 'pebble-loader',
})
export default class PebbleLoader extends Vue {
    @PropSync('status', { type: Number, default: () => PebbleStatus.LOADING })
    private readonly statusSync!: PebbleStatus;

    @Prop({ default: () => i18n.t('pebble.errorTitle').toString() })
    private readonly errorTitle!: string;

    @Prop({ default: () => i18n.t('pebble.errorSubTitle').toString() })
    private readonly errorSubTitle!: string;

    @Prop({ default: () => i18n.t('pebble.refreshButton').toString() })
    private readonly refreshButtonTitle!: string;

    @Prop({ default: () => i18n.t('pebble.waitingTitle').toString() })
    private readonly waitingTitle!: string;

    @Emit('on-refresh-click')
    private onRefreshClick(): void {
        return;
    }

    private get isLoaded(): boolean {
        return this.statusSync === PebbleStatus.LOADED;
    }

    private get isError(): boolean {
        return this.statusSync === PebbleStatus.ERROR;
    }
}
</script>

<style scoped>
.placeholder-div {
    height: auto !important;
}
</style>
