<template>
    <div class="container">
        <pui-button
            icon="left"
            :light="true"
            :iconOnly="true"
            v-on:click="onPress"
            class="icon-button"/>
        <pui-headline type="h3">{{ title }}</pui-headline>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'back-text',
})
export default class BackText extends Vue {
    @Prop({required: true})
    private title!: string;

    @Prop()
    private onPress?: () => void;
}
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.icon-button {
    padding: 0 8px 0 0 !important;
}
</style>
