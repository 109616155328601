import { Vue } from 'vue-property-decorator';
import ApiConfigHelper from '@/utils/api-config-helper';
import { sdk } from './sdk';

export default class SdkGenericApiHelper {
    private endpoint?: string;

    constructor(endpoint?: string) {
        this.endpoint = endpoint;
    }

    public async get(query?: string, additionalPath?: string) {
        let endpoint = this.mergeEndpoint(additionalPath);
        endpoint = query ? `${endpoint}?${query}` : endpoint;
        return await sdk?.backend.request.api.get<any>(endpoint, ApiConfigHelper.getHeaderConfig());
    }

    public async getByPathOrParam(pathParam: string | number) {
        const endpoint = this.mergeEndpoint(pathParam);
        return await sdk?.backend.request.api.get<any>(endpoint, ApiConfigHelper.getHeaderConfig());
    }

    public async post(data?: any, additionalPath?: string) {
        const endpoint = this.mergeEndpoint(additionalPath);
        return await sdk?.backend.request.api.post<any>(endpoint, data, ApiConfigHelper.getHeaderConfig());
    }

    public async put(pathOrParam?: string | number, data?: any) {
        const endpoint = this.mergeEndpoint(pathOrParam);
        return await sdk?.backend.request.api.put<any>(endpoint, data, ApiConfigHelper.getHeaderConfig());
    }

    public async delete(pathOrParam: string | number) {
        const endpoint = this.mergeEndpoint(pathOrParam);
        return await sdk?.backend.request.api.delete<any>(endpoint, ApiConfigHelper.getHeaderConfig());
    }

    private mergeEndpoint(additionalPath?: string | number): string {
        if (!this.endpoint) {
            return additionalPath
                ? `${additionalPath}`
                : '';
        }

        return additionalPath
            ? `${this.endpoint}/${additionalPath}`
            : this.endpoint;
    }
}
