<template>
    <div class="enum-management flex-col full-height">
        <div v-if="!selectedCard && isDataLoaded" class="flex-row managing-options pl-2 pr-2 pt-2 pb-2">
            <div class="flex-col">
                <select class="default-select" name="" id="" v-model="selectedOption" @change="changeSelection">
                    <option v-for="(option, index) in options" :key="index" :value="option">{{ $t(option.name) }}</option>
                </select>
            </div>
            <div class="flex flex-4"></div>
            <div class="flex-col">
                <div class="flex-row">
                    <div class="mr-1 show-deleted-checkbox">
                        <pui-form-checkbox
                            :title="$t('showDeletedItems')"
                            :label="$t('showDeletedItems')"
                            :name="$t('showDeletedItems')"
                            v-model="showDeletedEnum"
                            :checked="showDeletedEnum"
                            @change="reloadAssets"
                        />
                    </div>
                    <div class="mr-1 center-align">
                        <search-component @input="searchCards"/>
                    </div>
                    <div class="mr-2 center-align">
                        <adam-button
                            v-if="hasMasterDataAdmin"
                            class="mr-1"
                            icon="ic-add-dashboard"
                            @click="addNewEntity()"
                            secondary>
                            {{ $t('addNew') }}
                        </adam-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-area" id="view-items">
            <div v-if="cardList.length === 0" class="mt-2 ml-2">
                {{ $t('noDataToShow') }}
            </div>
            <div v-else-if="!selectedCard"
                class="cards-display">
                <sort-header
                    v-if="headerColumn.length"
                    :headerColumns="headerColumn"
                    :objectToSort="cardList"
                    @get-sorted-list="setSortedLocalEnumManagement"
                ></sort-header>
                <card-list>
                    <template v-slot:cards>
                        <div v-for="card of cardList"
                            :key="card.sid"
                            class="card">
                            <enum-card-list
                                :card="card"
                                @edit="edit" />
                        </div>
                    </template>
                </card-list>
            </div>
            <div v-else
                class="edit-screen">
                <asset-details
                    :data="selectedCard"
                    @close="closeDetailsView()"
                    @save="saveEnumToList" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Watch, Mixins } from 'vue-property-decorator';
import CardList from '@/components/cards/cards-list/cards-list.vue';
import EnumCardList from '@/components/cards/cards-list/enum-card-list/enum-card-list.vue';
import SearchComponent from '@/components/search-component/search-component.vue';
import { AssetService } from '@/services';
import { Asset, SearchPaginationQueryModel, ManagementOption } from '@/models';
import { EnumFactory, EventBus, InfiniteScrollingHelper } from '@/utils';
import throttle from 'lodash/throttle';
import { AdamTreeItemIcon } from 'adam.ui-core/dist/src/lib/components/tree';
import ComponentSecurity from '@/mixins/component-security';
import AssetDetails from '@/components/view-details/asset/asset-details.vue';
import SortHeader from '@/views/sort-header/sort-header.vue';
import { EnumManagementQueryModel } from '@/models/filter';

const SCROLL_THROTTLE_MILLISECONDS = 500;

@Component({
    name: 'enum-management',
    components: {
        cardList: CardList,
        enumCardList: EnumCardList,
        searchComponent: SearchComponent,
        assetDetails: AssetDetails,
        sortHeader: SortHeader,
    },
})
export default class EnumManagement extends Mixins(ComponentSecurity) {
    private options!: ManagementOption[];
    private selectedOption!: ManagementOption;
    private isDataLoaded = false;
    private assetService: AssetService = new AssetService();

    private selectedCard: Asset | null = null;
    private cardList: any[] = [];
    private enumList: Asset[] = [];
    private showDeletedEnum = false;
    private filterModel: EnumManagementQueryModel = {
        term: '',
        size: 10,
        page: 1,
        sortDirection: '',
        sortColumn: '',
        includeDeleted: this.showDeletedEnum,
    };
    private isLoadingNextPage = false;
    private hasNextPage = false;
    private enumListIcon: AdamTreeItemIcon = {
        src: 'ic-company',
        alt: 'client logo',
        width: 'auto',
        height: 'auto',
    };
    private onScroll = throttle(this.calculateScroll, SCROLL_THROTTLE_MILLISECONDS);
    private sortOrder = 'asc';
    private sortKey = 'name';
    private headerColumn: Array<{
        attributeName: string;
        name: string;
        itemCssClassName: string;
    }> = [];

    @Watch('$route.query.item')
    private async onQueryItemChanged(newValue: string | undefined, oldValue: string | undefined) {
        if (newValue === oldValue) {
            return;
        }

        if (!newValue) {
            this.selectedCard = null;
            return;
        } else {
            this.assignAsset();
        }
    }


    @Watch('selectedOption')
    private async selectedOptionChanged(newVal: ManagementOption, oldVal: ManagementOption): Promise<void> {
        if (newVal && newVal.name !== oldVal.name) {
            await this.changeSelection();
        }
    }

    private async changeSelection(): Promise<void> {
        this.enumListIcon.src = this.selectedOption.icon;
        await this.loadAssets();
        this.selectedCard = null;
    }

    private async mounted(): Promise<void> {
        await this.loadOptions();
        await this.loadAssets();
        this.isDataLoaded = true;
        const element = document.getElementById('view-items');
        if (element) {
            element.addEventListener('scroll', this.onScroll);
            element.addEventListener('wheel', this.onScroll);
        }
        this.headerColumn = [
            {
                attributeName: 'name',
                name: this.$t('name').toString(),
                itemCssClassName: 'em-name',
            },
            {
                attributeName: 'sid',
                name: this.$t('sid').toString(),
                itemCssClassName: 'em-sid',
            },
            {
                attributeName: 'description',
                name: this.$t('description').toString(),
                itemCssClassName: 'em-description',
            },
            {
                attributeName: 'isDeleted',
                name: this.$t('deleted').toString(),
                itemCssClassName: 'em-is-deleted',
            },
        ];
    }

    private beforeDestroy(): void {
        const element = document.getElementById('view-items');
        if (element) {
            element.removeEventListener('scroll', this.onScroll);
            element.addEventListener('wheel', this.onScroll);
        }
    }

    private calculateScroll(): void {
        if (this.selectedCard) {
            return;
        }

        InfiniteScrollingHelper.calculateScroll(document.getElementById('view-items'), this.getNextPage, 200);
    }

    private async loadOptions(): Promise<void> {
        this.options = await this.assetService.getEnumList();
        this.selectedOption = this.options[0];
        this.enumListIcon.src = this.selectedOption.icon;
    }

    private async reloadAssets(val: boolean): Promise<void> {
        this.showDeletedEnum = val;
        this.filterModel.includeDeleted = this.showDeletedEnum;
        await this.loadAssets();
    }

    private async loadAssets(): Promise<void> {
        this.filterModel.page = 1;
        this.enumList = await this.getPagedAssets();
        this.cardList = this.enumList.map((e: any) => {
            return {...e, ...{icon: this.enumListIcon}};
        });
        this.assignAsset();
    }

    private assignAsset() {
        if (this.$route.query.item && this.cardList) {
            this.selectedCard = this.cardList.find(
                (x: Asset) => x.sid === +this.$route.query.item);
        } else {
            this.selectedCard = null;
        }
    }

    private async getPagedAssets(): Promise<Asset[]> {
        let assets: Asset[] = [];

        if (!this.selectedOption.idTypeSid) {
            return assets;
        }

        try {
            const { result: { items, hasNextPage } } =
                await this.assetService.getByIdTypeSid(this.selectedOption.idTypeSid, this.filterModel);
            assets = items;
            this.hasNextPage = hasNextPage;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        } finally {
            this.calculateScroll();
        }
        return assets;
    }

    private async getNextPage(): Promise<void> {
        if (this.isLoadingNextPage) {
            return;
        }

        if (!this.hasNextPage) {
            return;
        }
        this.filterModel.page++;
        this.isLoadingNextPage = true;
        const assets = await this.getPagedAssets();
        assets.forEach((a: Asset) => {
            this.cardList.push({...a, ...{icon: this.enumListIcon}});
        });
        await this.$nextTick();
        this.isLoadingNextPage = false;
    }

    private async searchCards(searchString: string): Promise<void> {
        this.filterModel.term = searchString;
        await this.loadAssets();
    }

    private closeDetailsView(): void {
        this.selectedCard = null;
        this.$router.push({
            name: this.$route.name?.toString(),
            params: {
                option: this.$route.params.option,
            },
        });
    }

    private addNewEntity(): void {
        if (!this.selectedOption?.idTypeSid) {
            return;
        }
        this.selectedCard = EnumFactory.create(this.selectedOption.idTypeSid);
    }

    private edit(card: Asset): void {
        this.$router.push({
            name: this.$route.name?.toString(),
            params: {
                option: this.$route.params.option,
            },
            query: {
                ...this.$route.query,
                item: card.sid.toString(),
            },
        });
        this.selectedCard = { ...card };
    }

    private saveEnumToList(asset: Asset, isAdd: boolean): void {
        asset.objectType = this.selectedOption.icon;
        (asset as any).icon = this.enumListIcon;
        if (isAdd) {
            asset.objectType = this.selectedOption.icon;
            if (this.cardList.length === this.filterModel.size) {
                this.cardList.pop();
            }
            this.cardList.push({ ...asset, ...{ icon: this.enumListIcon } });
        } else {
            const indexOf = this.cardList.findIndex((c: Asset) =>
                c.sid === asset.sid);
            if (indexOf >= 0) {
                this.cardList[indexOf] = Object.assign({}, asset);
            }
        }
        this.selectedCard = null;
    }

    private async deleteCard(card: Asset): Promise<void> {
        this.$store.commit('loading');
        try {
            await this.assetService.delete(card.sid);
            this.cardList = this.cardList.filter((u: Asset) => u.sid !== card.sid);
            const filterModel: SearchPaginationQueryModel = {
                size: 1,
                page: this.filterModel.page * this.filterModel.size,
                term: this.filterModel.term,
            };
            if (this.selectedOption.idTypeSid) {
                const { result: { items } } =
                    await this.assetService.getByIdTypeSid(this.selectedOption.idTypeSid, filterModel);

                if (items.length > 0) {
                    this.cardList.push(items[0]);
                }
            }
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorRemovingAsset');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async setSortedLocalEnumManagement(obj: {
        sortedList: any[];
        order: string;
        sortKey: string;
    }): Promise<void> {

        const oldpg = this.filterModel.page;
        this.filterModel.size *= oldpg;
        this.filterModel.page = 1;
        if (obj.order === 'asc') {
            this.filterModel.sortDirection = 'Ascending';
        } else {
            this.filterModel.sortDirection = 'Descending';
        }
        this.filterModel.sortColumn = obj.sortKey;

        this.enumList = await this.getPagedAssets();
        this.cardList = this.enumList.map((e: any) => {
            return {...e, ...{icon: this.enumListIcon}};
        });
        this.assignAsset();

        this.filterModel.page = oldpg;
        this.filterModel.size = 10;
    }
}

</script>

<style lang="less">
@import "~@/variables.less";

.enum-management {
    select {
        height: 100%;
    }

    .managing-options {
        border-bottom: 2px solid @grey-lighter;
        background-color: @white;

        .center-align {
            align-self: center;
        }
    }
    .main-area {
        padding: 2.5rem;
        box-sizing: border-box;
        overflow-y: auto;
        height: 100%;
        .name {
            width: 21rem;
        }
        .sid {
            width: 10rem;
        }
        .description {
            width: 41rem;
        }

        .is-deleted {
            width: 10rem;
        }
    }
    .header-row {
        &__item-em-name,
        &__item-em-sid,
        &__item-em-description,
        &__item-em-is-deleted {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            box-sizing: border-box;
            border-left: 2px solid @lighter-grey;
            padding: 0 1rem;
        }

        &__item-em-name {
            border-left: none;
            width: 15rem;
        }

        &__item-em-sid {
            width: 12rem;
        }

        &__item-em-description {
            width: 43rem;
        }

        &__item-em-is-deleted {
            width: 12rem;
        }
    }
    .show-deleted-checkbox {
        width: 17rem;
        padding-top: 0.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
    }
}
</style>
