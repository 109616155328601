<template>
    <card>
        <pui-grid-container v-if="isInitialDataLoaded">
            <pui-grid-row class="pui-grid-row--between">
                <back-text
                    :title="title"
                    :on-press="onBackPress"
                />
                <pui-button
                        v-if="canEditHydroComponents && !isNewVersion"
                        icon="add"
                        state="secondary"
                        class="footer-button"
                        @click.prevent="onAddVersion"
                    >
                        {{ $t('addNewVersion') }}
                    </pui-button>
            </pui-grid-row>
            <pui-grid-row class="pui-grid-row-little-margin-top">
                <custom-table
                    :data="dataTable"
                    :columns-key="columns"
                    :heading="heading"
                    :sortable-key="[]"
                    :per-page="10"
                    :per-page-values="[2, 5, 10]"
                    :enable-pagination="true"
                    :enable-limit="true"
                    :on-click-action="onClickAction"
                    :custom-template="['action']"
                >
                    <template v-slot:custom_action="{data}">
                        <div class="action-cell">
                            <pui-link
                                :title="$t('hydroComponents.actions.showDetails')"
                                icon="visible"
                                @click="onClickAction(data, false)"
                            />
                            <pui-link
                                v-if="canEditHydroComponents"
                                :title="$t('edit')"
                                icon="edit"
                                @click="onClickAction(data, true)"
                            />
                        </div>
                    </template>
                </custom-table>
            </pui-grid-row>
            <pui-form
                v-if="data"
                aria-label="Form"
            >
                <pui-grid-row>
                    <pui-grid-column class="pui-grid-column-zero-padding">
                        <hydro-components-transformer-form
                            is-edit-mode
                            :data="data"
                            :disabled="!(isNewVersion || isEditMode)"
                            @validate="validate"
                        />
                    </pui-grid-column>
                </pui-grid-row>
                <error-list :errors="invalidMessages" />
                <pui-grid-row class="pui-grid-row--end pui-grid-row-little-margin-top">
                    <pui-button
                        v-if="canEditHydroComponents && (isNewVersion || isEditMode)"
                        state="secondary"
                        class="footer-button"
                        @click.prevent="onBackPress"
                    >
                        {{ $t('cancel') }}
                    </pui-button>
                    <pui-button
                        v-if="canEditHydroComponents && (isNewVersion || isEditMode)"
                        :disabled="shouldDisable"
                        @click.prevent="onSave"
                    >
                        {{ $t('save') }}
                    </pui-button>
                </pui-grid-row>
            </pui-form>
        </pui-grid-container>
    </card>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import Card from '@/components/cards/card.vue';
import { TransformersService } from '@/services/transformers-service';
import BackText from '@/components/text/back-text.vue';
import {
    TransformerEditDataKeys,
    TransformerGetVersionData,
    TransformerVersionData
} from '@/models/hydro-components/transformers-edit-data';
import CustomTable from '@/components/table/custom-table.vue';
import { DateHelper, EventBus, extractErrorsFromResponse } from '@/utils';
import HydroComponentsTransformerForm from '@/components/forms/hydro-components-transformer-form.vue';
import { transformerForm } from '@/utils/pebble-form/hydro-components/transformer-form';
import { TransformerForm } from '@/models/form/transformer-form';
import { FormValuesModel } from '@/models/pebble/form-values-model';
import { AddTransformersData } from '@/models/hydro-components/transformers-data';
import { MAX_DATE } from '@/utils/constants';
import errorList from '@/components/error-list/error-list.vue';
import { LocaleMessage } from 'vue-i18n';
import ComponentSecurity from '@/mixins/component-security';

@Component({
    components: {errorList, HydroComponentsTransformerForm, CustomTable, BackText, Card}
})
export default class HydroComponentsEdit extends Mixins(ComponentSecurity) {
    private readonly transformersService = new TransformersService();

    private columns = TransformerEditDataKeys;
    private dataTable: TransformerVersionData[] = [];

    private data: TransformerForm | null = null;
    private invalidMessages: string[] = [];
    private isNewVersion = false;
    private lastVersionData: TransformerGetVersionData | null = null;
    private isEditMode = false;

    private transformerSid: string | null = null;
    private isInitialDataLoaded = false;

    private get title(): string {
        return this.canEditHydroComponents ? `${this.$t('hydroComponents.titles.edit', { sid: this.transformerSid })}` : `${this.transformerSid}`
    }

    private get heading(): Record<(typeof TransformerEditDataKeys)[number], LocaleMessage> {
        return {
            validFrom: this.$t('validFrom'),
            validTo: this.$t('validUntil'),
            transformerSid: this.$t('hydroComponents.headings.transformerSid'),
            name: this.$t('hydroComponents.headings.name'),
            lastUpdated: this.$t('lastUpdated'),
            lastUpdatedBy: this.$t('lastUpdatedBy'),
            action: '',
        };
    }

    private mounted(): void {
        this.transformerSid = this.$route.query.transformerSid.toString();

        this.loadInitialData().then();
    }

    private async loadInitialData(): Promise<void> {
        if (!this.transformerSid) {
            return;
        }

        this.isEditMode = this.canEditHydroComponents;
        this.isNewVersion = false;
        this.lastVersionData = null;

        await this.loadTableData();
        await this.onClickAction(this.dataTable?.[0], this.isEditMode);

        this.isInitialDataLoaded = true;
    }

    private async loadTableData(): Promise<void> {
        if (!this.transformerSid) {
            return;
        }

        this.$store.commit('loading');

        try {
            const response = await this.transformersService.getVersions(this.transformerSid);
            const data = response.result.items;

            data.forEach(e => {
                e.validFrom = DateHelper.formatDate(e.validFrom);
                e.validTo = DateHelper.formatDate(e.validTo);
                e.lastUpdated = DateHelper.formatDate(e.lastUpdated);
            })

            this.dataTable = data;
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingVersions');
        } finally {
            this.$store.commit('loading');
        }
    }

    private async onClickAction(data: TransformerVersionData, isEditMode: boolean): Promise<void> {
        if (!this.transformerSid) {
            return;
        }

        this.$store.commit('loading');

        try {
            const response = await this.transformersService.getByValidFrom(this.transformerSid, data.validFrom);

            if (response.result) {
                this.setVersionData(response.result, isEditMode);
            }
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingVersionDetails');
        } finally {
            this.$store.commit('loading');
        }
    }

    private setVersionData(data: TransformerGetVersionData, isEditMode: boolean): void {
        this.lastVersionData = data;
        this.data = transformerForm(this.lastVersionData);

        this.isEditMode = isEditMode;
        this.isNewVersion = false;
        this.invalidMessages = [];
    }

    private parseNumber(value: string | undefined | null): number | undefined {
        if (value === '' || value === undefined || value === null) {
            return undefined;
        }

        return Number(value);
    }

    private parseDate(value: string): string | undefined {
        if (value === '') {
            return undefined;
        }

        return (new Date(value)).toISOString();
    }

    private async onSave(): Promise<void> {
        this.invalidMessages = [];
        this.$store.commit('loading');

        try {
            if (!this.data || !this.transformerSid) {
                this.$store.commit('loading');
                return;
            }

            const editRequest: AddTransformersData = {
                transformerSid: this.data.componentId.value,
                validFrom: this.parseDate(this.data.validFrom.value),
                validTo: MAX_DATE.toISOString(),
                deleted: this.data.isDeleted.value,
                transformerName: this.data.transformerName.value,
                plantSid: Number(this.data.powerPlant.value),
                technicalLocation: this.data.functionalLocation.value,
                manufacturerEnumSid: this.parseNumber(this.data.manufacturer.value),
                transformerTypeEnumSid: this.parseNumber(this.data.transformerType.value),
                constructionYear: this.parseNumber(this.data.constructionYear.value),
                nominalPower: this.parseNumber(this.data.nominalPower.value),
                primaryVoltageOs: this.parseNumber(this.data.primaryVoltageOs.value),
                secondaryVoltageOs: this.parseNumber(this.data.secondaryVoltageUs.value),
                coolingSystemEnumSid: this.parseNumber(this.data.coolingSystem.value),
                oilWeight: this.parseNumber(this.data.oilWeight.value),
                totalWeight: this.parseNumber(this.data.totalWeight.value),
                decommissioningDate: this.parseDate(this.data.decommissioningDate.value),
                equipmentNumber: this.parseNumber(this.data.equipment.value),
                isReserve: this.data.isReserve.value,
                storagePlantSid: this.parseNumber(this.data.storagePowerPlant.value),
            };

            let response = null;

            if (this.isNewVersion) {
                response = await this.transformersService.createVersion(this.transformerSid, editRequest);
            } else if (this.isEditMode && this.lastVersionData?.validFrom) {
                response = await this.transformersService.editVersion(this.transformerSid, this.lastVersionData.validFrom, editRequest);
            }
            
            if (!response) {
                throw new Error();
            }

            this.setVersionData(response.result, false);

            await this.loadTableData();

            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'hydroComponents.toastMessages.failedToAddNewVersion');
            this.invalidMessages = extractErrorsFromResponse(err.response.data);
        } finally {
            this.$store.commit('loading');
        }
    }

    private onAddVersion(): void {
        this.isNewVersion = true;
        this.invalidMessages = [];

        if (!this.data) {
            this.data = transformerForm();
        }

        this.data.validFrom.value = DateHelper.formatDate(new Date());
        this.data.validTo.value = DateHelper.formatDate(MAX_DATE);
    }

    private onCancel(): void {
        this.isEditMode = false;
        this.isNewVersion = false;
        this.invalidMessages = [];

        if (!this.lastVersionData) {
            return;
        }

        this.data = transformerForm(this.lastVersionData);
    }

    private onBackPress(): void {
        this.$router.push({
            name: 'hydro-components'
        });
    }

    private validate(value: string, name: string): void {
        const input: FormValuesModel = this.data?.[name];
        if (input.validator) {
            input.isValid = input.validator(input.value, this.data);
        }
    }

    private get shouldDisable(): boolean {
        const validator: boolean = Object.keys(this.data ?? {}).map((inputName) => {
                const input: FormValuesModel = this.data?.[inputName];
                if (input.validator) {
                    return input.validator(input.value, this.data);
                }
                return true;
            },
        ).every((value) => value);
        return !(validator);
    }
}
</script>

<style scoped>
.action-cell {
    display: flex;
    gap: 10px;
}

.footer-button {
    margin-right: 1rem;
}
</style>
