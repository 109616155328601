<template>
    <div class="container">
        <pui-headline type="h3" class="header-title">
            {{ title }}
        </pui-headline>
        <pui-grid-row>
            <pui-grid-column
                v-if="dropdownOrFilterAreEnabled"
                :cols="dropdownGridCols()"
            >
                <pui-form-select
                    label=""
                    v-if="dropdownIsEnabled"
                    class="dropdown"
                    @change="onDropdownSelect"
                    :options="dropdownData"
                    :value="dataLoaded ? dropdownInitialValue : undefined"
                />
                <custom-filter
                    v-if="filtersAreEnabled"
                    :filters="filters"
                    :on-filter-change="onFilterChange"
                />
            </pui-grid-column>
            <pui-grid-column
                :cols="checkBoxAndButtonGridCols()"
                :class="filtersAreEnabled ? 'filters-are-enabled' : ''"
            >
                <pui-grid-container
                    class="pui-grid-container-zero-padding"
                >
                    <pui-grid-row
                        class="pui-grid-row--between"
                    >
                        <pui-grid-column
                            :cols="{s:6, m:6, l:6, xl:6, xxl:6}"
                        >
                            <div class="check-box-container">
                                <pui-form-checkbox
                                    v-if="checkBoxIsEnabled"
                                    class="checkbox-form"
                                    :label="$t('showDeletedItems')"
                                    v-model="deleted"
                                    :checked="deletedInitialValue"
                                />
                            </div>
                        </pui-grid-column>
                        <pui-grid-column
                            :cols="{s:6, m:6, l:6, xl:6, xxl:6}"
                            class="pui-grid-col--end"
                        >
                            <pui-button
                                icon="add"
                                v-if="hasMasterDataAdmin && buttonIsEnabled"
                                v-on:click="onAddNew"
                            >
                                {{ $t('addNew') }}
                            </pui-button>
                        </pui-grid-column>
                    </pui-grid-row>
                </pui-grid-container>
            </pui-grid-column>
        </pui-grid-row>
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import {Mixins, Prop, Watch} from 'vue-property-decorator';
import ComponentSecurity from '@/mixins/component-security';
import CheckBox from '@/components/checkbox/check-box.vue';
import CustomFilter from '@/components/filter/custom-filter.vue';
import {PebbleGridCols} from '@/models/pebble/pebble-grid-cols';
import {Filter} from '@/models/custom-filter/filter';
import {CustomObject} from '@/models/custom-object';

@Component({
    name: 'master-data-management-header',
    components: {CustomFilter, CheckBox},
})
export default class MasterDataManagementHeader extends Mixins(ComponentSecurity) {
    @Prop({required: true})
    private title!: string;

    @Prop({default: false})
    private dataLoaded!: boolean;

    @Prop()
    private dropdownData?: any[];

    @Prop({required: false})
    private dropdownInitialValue?: number;

    @Prop({required: false})
    private deletedInitialValue?: boolean;

    @Prop({default: () => []})
    private filters?: Filter[];

    @Prop({required: false})
    private onAddNew?: () => void;

    @Prop({required: false})
    private onDropdownSelect?: (data: number) => void;

    @Prop({required: false})
    private onFilterChange?: (data: CustomObject) => void;

    @Prop({required: false})
    private onCheck?: (data: boolean) => void;

    private deleted = false;


    @Watch('deleted')
    private check(data: boolean): void {
        if (this.onCheck) {
            this.onCheck(data);
        }
    }

    get dropdownIsEnabled(): boolean {
        return this.dropdownData !== undefined;
    }

    get filtersAreEnabled(): boolean {
        if (this.filters) {
            return this.filters.length > 0;
        } else {
            return false;
        }
    }

    get checkBoxIsEnabled(): boolean {
        return this.onCheck !== undefined;
    }

    get buttonIsEnabled(): boolean {
        return this.onAddNew !== undefined;
    }

    get dropdownOrFilterAreEnabled(): boolean {
        return this.filtersAreEnabled || this.dropdownIsEnabled;
    }

    private dropdownGridCols(): PebbleGridCols {
        return {
            s: this.dropdownOrFilterAreEnabled ? 12 : 0,
            m: this.dropdownOrFilterAreEnabled ? 4 : 0,
            l: this.dropdownOrFilterAreEnabled ? 4 : 0,
            xl: this.dropdownOrFilterAreEnabled ? 3 : 0,
            xxl: this.dropdownOrFilterAreEnabled ? 3 : 0,
        };
    }

    private checkBoxAndButtonGridCols(): PebbleGridCols {
        return {
            s: 12,
            m: this.dropdownOrFilterAreEnabled ? 8 : 12,
            l: this.dropdownOrFilterAreEnabled ? 8 : 12,
            xl: this.dropdownOrFilterAreEnabled ? 9 : 12,
            xxl: this.dropdownOrFilterAreEnabled ? 9 : 12,
        };
    }
}
</script>

<style scoped>
.header-title {
    margin-bottom: 16px;
}

.container {
    margin: 16px 0 16px 0;
    width: 100%;
}

.checkbox-form {
    margin-bottom: 0 !important;
}

.filters-are-enabled {
    margin-top: 20px;
}

.check-box-container {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
</style>
