<template>
    <view-details
        :sid="data.sid"
        :name="data.name || $t('newEnum')"
        icon="ic-settings">
        <tab-headers
            :tabs="tabs"
            :active-tab="activeTab"
            @select-tab="selectTab" />
        <component
            :is="activeTab.component"
            :data="data"
            @close="closeEditScreen()"
            @save="saveChanges" />
    </view-details>
</template>

<script lang="ts">
import { Asset, GenericTab, ManagementOption } from '@/models';
import { Vue, Component, Prop } from 'vue-property-decorator';
import TabHeaders from '@/components/view-details/tab-headers/tab-headers.vue';
import AssetIdentification from '@/components/view-details/asset/asset-identification/asset-identification.vue';
import ViewDetails from '@/components/view-details/view-details.vue';

@Component({
    name: 'asset-details',
    components: {
        tabHeaders: TabHeaders,
        assetIdentification: AssetIdentification,
        viewDetails: ViewDetails,
    },
})
export default class AssetDetails extends Vue {
    /**
     * Enum type entity for which the edit screen is displayed.
     * e.g.: time zone, country, region, state, organization etc.
     */
    @Prop({ required: true })
    private data!: Asset;

    private tabs: GenericTab[] = [
        { name: 'identification', component: 'assetIdentification' },
    ];
    private activeTab = this.tabs[0];
    private selectedOption: ManagementOption | null = null;

    private async mounted(): Promise<void> {
        if (this.$route.query.tab) {
            this.activeTab = this.tabs.find((x) => x.name === this.$route.query.tab.toString()) ?? this.tabs[0];
        }
    }

    private selectTab(tab: GenericTab) {
        this.activeTab = tab;
        this.$router.push({
            name: this.$route.name?.toString(),
            params: {
                option: this.$route.params.option,
            },
            query: {
                ...this.$route.query,
                tab: tab.name.toString(),
            },
        });
    }

    private closeEditScreen(): void {
        this.$emit('close');
    }

    private saveChanges(item: Asset, isAdd: boolean): void {
        this.$emit('save', item, isAdd, null);
    }
}
</script>

<style>

</style>
