<template>
    <pui-tree
        :treeData="treeData"
    >
        <template slot="nodeContent" slot-scope="data">
            {{ generate(data) }}
        </template>
    </pui-tree>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {PebbleTree} from '@/models/tree/pebble-tree';
import {PebbleTreeFather} from '@/models/tree/pebble-tree-father';

@Component({
    name: 'power-plants-tree',
})
export default class PowerPlantsTree extends Vue {
    private treeData: PebbleTreeFather = {nodes: []};

    public created() {
        this.treeData.nodes.push(...[
            {title: 'AT', nodes: [{title: 'test'}]}, {title: 'DE'}, {title: 'HU'}, {title: 'NL'}, {title: 'NO'},
        ]);
    }

    private generate(value: PebbleTree): string {
        return value.title;
    }
}
</script>


