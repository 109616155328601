<template>
    <card>
        <pui-grid-container>
            <card-header
                :title="$t('addNew')"
                :on-click-back-button="onClickBackButton"
            />
            <pui-grid-row>
                <fake-single-tab
                    :title="$t('identification')"
                />
            </pui-grid-row>
            <pui-grid-row
                :class="errors.length > 0 ? 'pebble-errors-list-margin-top' : ''"
            >
                <pebble-errors-list
                    id="errors-list"
                    :errors="errors"
                />
            </pui-grid-row>
            <pui-form aria-label="Form">
                <pui-grid-row>
                    <pui-grid-column class="pui-grid-column-zero-padding">
                        <ets-unit-identification-form
                            :data="formValues"
                            :is-edit="false"
                            @validate="validate"
                        />
                    </pui-grid-column>
                </pui-grid-row>
            </pui-form>
            <cancel-save-buttons
                :save-button-is-disabled="!hasMasterDataAdmin || saveButtonIsDisabled"
                :on-click-cancel-button="onClickBackButton"
                :on-click-save-button="onClickSaveButton"
            />
        </pui-grid-container>
    </card>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import ComponentSecurity from '@/mixins/component-security';
import {ETSService} from '@/services';
import Card from '@/components/cards/card.vue';
import BackText from '@/components/text/back-text.vue';
import FakeSingleTab from '@/components/tab-bar/fake-single-tab.vue';
import EtsUnitIdentificationForm from '@/components/forms/ets-unit-identification-form.vue';
import PebbleErrorsList from '@/components/error-list/pebble-errors-list.vue';
import {PebbleNotification} from '@/models/pebble/pebble-notification';
import {IdentificationEtsUnitForm} from '@/models/form/identification-ets-unit-form';
import {EtsUnitFactory} from '@/utils/factories';
import {identificationEtsUnitForm} from '@/utils/pebble-form/master-data-management/identification-ets-unit-form';
import {generateDataFromFormValues, generateErrors, isValidInput, manageResolve, shouldDisableForm} from '@/utils/utils';
import CancelSaveButtons from '@/components/buttons/cancel-save-buttons.vue';
import {EtsUnitModel} from '@/models/ets-unit-model';
import {EventBus} from '@/utils';
import {EtsUnit} from '@/models';
import CardHeader from '@/components/cards/card-header.vue';

@Component({
    name: 'management-ets-unit-new',
    components: {CardHeader, CancelSaveButtons, PebbleErrorsList, EtsUnitIdentificationForm, FakeSingleTab, BackText, Card},
})
export default class ManagementEtsUnitNew extends mixins(ComponentSecurity) {
    /* VARIABLES */
    private service: ETSService = new ETSService();
    private errors: PebbleNotification[] = [];
    private saveButtonIsDisabled = true;

    /* LATE VARIABLES */
    private formValues!: IdentificationEtsUnitForm;

    /* PRIMITIVE METHODS */
    private created(): void {
        this.formValues = identificationEtsUnitForm(EtsUnitFactory.createEtsUnitAddRequest());
    }

    private mounted(): void {
        this.$router.beforeResolve((to, from, next) => manageResolve(!this.saveButtonIsDisabled, next, this));
        this.saveButtonIsDisabled = shouldDisableForm(this.formValues);
    }

    private destroyed(): void {
        this.saveButtonIsDisabled = true;
    }

    /* METHODS */
    private validate(value: string, name: string): void {
        isValidInput(name, this.formValues[name]);
        this.saveButtonIsDisabled = shouldDisableForm(this.formValues);
    }

    /* ON CLICK BUTTONS */
    private onClickBackButton() {
        this.$router.push({
            name: `management-ets-unit`,
        });
    }

    private onClickSaveButton() {
        this.saveData();
    }

    /* API CALLS */
    private async saveData(): Promise<void> {
        if (await this.saveEtsUnit(generateDataFromFormValues(this.formValues) as EtsUnitModel)) {
            this.saveButtonIsDisabled = true;
            this.onClickBackButton();
        }
    }

    private async saveEtsUnit(data: EtsUnitModel): Promise<boolean> {
        this.$store.commit('loading');
        try {
            await this.service.create(data as EtsUnit);
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingEtsUnit');
            generateErrors(this.errors, error);
            return false;
        } finally {
            this.$store.commit('loading');
        }
    }

}
</script>


