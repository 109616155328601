import { firstNodeLevel } from '@/components/asset-master-data/constants';

export class HierarchyRequestParams {
    public powerPlantSid?: number;
    public nodeLevel: number;
    public countries: number[];
    public fleets: number[];
    public plantGroups: number[];
    public classTypes: number[];
    public machineTypes: number[];
    public includeDeleted: boolean;
    public sortColumn?: string;
    public sortDirection?: string;
    public parentId?: number;
    public pageNumber?: number;
    public pageSize?: number;
    public searchTerm?: string;

    constructor(data?: any) {
        this.powerPlantSid = data?.powerPlantSid;
        this.nodeLevel = data?.nodeLevel ?? firstNodeLevel;
        this.countries = data?.countries;
        this.fleets = data?.fleets;
        this.plantGroups = data?.plantGroups;
        this.classTypes = data?.classTypes;
        this.machineTypes = data?.machineTypes;
        this.includeDeleted = data?.includeDeleted;
        this.sortColumn = data?.sortColumn;
        this.sortDirection = data?.sortDirection;
        this.parentId = data?.parentId;
        this.pageNumber = data?.pageNumber;
        this.pageSize = data?.pageSize;
        this.searchTerm = data?.searchTerm;
    }
}
