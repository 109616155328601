<template>
    <md-card
        icon="ic-unit-blue"
        :name="card.name"
        :sid="card.etsId">
        <template v-slot:card-properties>
            <div class="flex-col last-updated border-right mr-2 pr-2 center-vertically">
                <div>
                    <b>{{ formatDateTime(card.lastUpdated) }}</b>
                </div>
            </div>
            <div class="flex-col last-updated-by border-right mr-2 pr-2 center-vertically">
                <div>
                    <b>{{ card.lastUpdatedBy }}</b>
                </div>
            </div>
            <div class="flex-col is-deleted border-right mr-2 pr-2 center-vertically">
                <div>
                    <b>
                        <span v-if="!card.isDeleted" class="color-green">N</span>
                        <span v-else class="color-amber">Y</span>
                    </b>
                </div>
            </div>
        </template>
        <template v-slot:action-buttons>
            <div class="ets-actions">
                <adam-button
                    icon="ic-settings"
                    fab
                    :outlined.prop="false"
                    @click="edit()"
                    inverted/>
            </div>
        </template>
    </md-card>
</template>

<script lang="ts">

import { Component, Prop, Mixins } from 'vue-property-decorator';
import { EtsUnit } from '@/models';
import { DateHelper } from '@/utils';
import MdCard from '@/components/cards/cards-list/md-card/md-card.vue';
import ComponentSecurity from '@/mixins/component-security';

@Component({
    name: 'ets-unit-card-list',
    components: {
        mdCard: MdCard,
    },
})
export default class EtsUnitCardList extends Mixins(ComponentSecurity) {
    /*
     * Card information which will be displayed.
    */
    @Prop({ required: true })
    private card!: EtsUnit;

    private edit(): void {
        this.$emit('edit', this.card);
    }

    private remove(): void {
        this.$emit('remove', this.card);
    }

    private formatDateTime(date: string): string {
        return DateHelper.formatDate(date);
    }
}

</script>
