import {
    OperationModeGetResponse, OperationModeListDto, OperationModeVersionAddRequest,
    UnitOperationModeAddRequest,
} from '@/models';
import i18n from '@/utils/i18n';
import format from 'date-fns/format';
import {DATE_FORMAT} from '../constants';
import {OperationModesModel} from '@/models/operation-modes-model';


export class OperationModeFactory {
    public static createUnitOperationModeAddRequest(unitSid: number): UnitOperationModeAddRequest {
        return {
            enumOpModeTypeSid: NaN,
            unitSid,
            isStdOperationMode: false,
            isExternalOperation: false,
            isBlackstart: false,
            isRedispatchAvailable: false,
            validFrom: format(new Date(), DATE_FORMAT),
        };
    }

    public static createListDto(): OperationModeListDto {
        return {
            operationModeSid: 0,
            operationModeName: i18n.t('newOperationMode').toString(),
            isActive: false,
            isExternalOperation: false,
            isStdOperationMode: false,
        };
    }

    public static createGetResponse(): OperationModeGetResponse {
        return {
            operationModeSid: 0,
            enumOpModeTypeSid: 0,
            isActive: false,
            isBlackstart: false,
            isExternalOperation: false,
            isRedispatchAvailable: false,
            isStdOperationMode: false,
            isDeleted: false,
            lastUpdatedBy: 'O000021',
            lastUpdated: '2019-02-02',
            lastUpdateComment: '',
            validFrom: '1950-01-01T00:00:00.000',
            validTo: '2999-12-31T00:00:00.000',
        };
    }

    public static createNewOperationMode(unitSid: number): OperationModesModel {
        return {
            unitSid,
            operationModeSid: 0,
            isActive: false,
            isBlackstart: false,
            isDeleted: false,
            isExternalOperation: false,
            isRedispatchAvailable: false,
            isStdOperationMode: false,
            validFrom: format(new Date(), DATE_FORMAT),
            validTo: '2999-12-31T00:00:00.000',
        };
    }

    public static createVersion(operationModeSid: number): OperationModeVersionAddRequest {
        return {
            operationModeSid,
            validFrom: format(new Date(), DATE_FORMAT),
            isActive: false,
            isBlackstart: false,
            isStdOperationMode: false,
            isExternalOperation: false,
            isRedispatchAvailable: false,
            isDeleted: false,
            enumOpModeTypeSid: NaN,
        };
    }
}
