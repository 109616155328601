<template>
    <div class="key-mapping">
        <div class="manage-input mt-2">
            <label class="default-label" for="externalSystem">{{ $t('selectExternalSystem') }}:</label>
            <select class="default-select" id="externalSystem"
                v-model="selectedExternalSystem"
                @change="updateSystemVersionsAndURL">
                <option :value="null" key="default" selected disabled>
                    {{ $t('selectExternalSystem') }}
                </option>
                <option v-for="system of externalSystems"
                    :key="system.sid"
                    :value="system">
                    {{ system.name }}
                </option>
            </select>
        </div>
        <div v-if="selectedExternalSystem">
            <table-view
                :header="keyMappingHeader"
                :items="systemVersions"
                :is-selected-cb="(candidate) => selectedVersion && candidate.validFrom === selectedVersion.validFrom"
                @select-item="onSelectKeyMapping" />
            <key-mapping-management
                ref="keyMappingDetails"
                :selected-version="selectedVersion"
                :selected-system="selectedExternalSystem"
                :object-sid="objectSid"
                @update-versions="updateSystemVersionsAndURL" />
        </div>
        <div class="members-footer"></div>
    </div>
</template>

<script lang="ts">
import { Asset, IdTypeSid, KeyMappingGetResponse } from '@/models';
import { AssetService, KeyMappingService } from '@/services';
import { EventBus, findDefaultVersion } from '@/utils';
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator';
import keyMappingHeader from './key-mapping-header';
import TableView from '@/components/view-details/table-view/table-view.vue';
import KeyMappingManagement from '@/components/view-details/key-mapping/key-mapping-management/key-mapping-management.vue';

@Component({
    name: 'key-mapping',
    components: {
        tableView: TableView,
        keyMappingManagement: KeyMappingManagement,
    },
})
export default class KeyMapping extends Vue {
    @Ref()
    private readonly keyMappingDetails!: InstanceType<typeof KeyMappingManagement>;

    /**
     * Object sid (power plant sid, machine sid, material sid etc)
     * for which the key mapping options are available.
     */
    @Prop({ required: true })
    private objectSid!: number;

    private externalSystems: Asset[] = [];
    private selectedExternalSystem: Asset | null = null;
    private systemVersions: KeyMappingGetResponse[] = [];
    private selectedVersion: KeyMappingGetResponse | null = null;

    private keyMappingHeader = keyMappingHeader;

    public async isFormDirty(): Promise<boolean> {
        if (!this.keyMappingDetails) {
            return false;
        }
        return this.keyMappingDetails.isFormDirty();
    }

    @Watch('$route.query.system')
    private async onQueryItemChanged(newValue: string | undefined, oldValue: string | undefined) {
        if (newValue === oldValue) {
            return;
        }
        this.selectExternalSystem();
        this.updateSystemVersions();
    }

    private async showConfirmDialog(message: string): Promise<boolean> {
        try {
            await this.$dialog
                .confirm(
                    { body: this.$t(message).toString() },
                    { view: 'confirm' });
            return true;
        } catch (error: any) {
            return Promise.resolve(false);
        }
    }

    private async mounted(): Promise<void> {
        EventBus.$on(EventBus.DETAIL.CLOSE, this.closeDetailsView);
        await this.loadExternalSystems();
        this.updateSystemVersions();
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.DETAIL.CLOSE);
    }

    private async loadExternalSystems(): Promise<void> {
        this.$store.commit('loading');
        try {
            this.externalSystems = (await new AssetService().getByIdTypeSid(IdTypeSid.EXTERNAL_SYSTEM,
                {size: 999, page: 1, term: ''})).result.items;
            this.selectExternalSystem();
            this.updateSystemVersions();
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingExternalSystems');
            throw error;
        } finally {
            this.$store.commit('loading');
        }
    }

    private selectExternalSystem() {
        if (this.$route.query.system) {
            this.selectedExternalSystem = this.externalSystems.find(
                (x) => x.sid === +this.$route.query.system) ?? null;
        } else {
            this.selectedExternalSystem = null;
        }
    }

    private async updateSystemVersionsAndURL(): Promise<void> {
        if (!this.selectedExternalSystem) {
            return;
        }
        if (this.$route.query.system) {
            this.changeRouteSystemQuery(this.selectedExternalSystem.sid.toString());
        }
        this.updateSystemVersions();
    }

    private async updateSystemVersions(selectVersionValidFrom = ''): Promise<void> {
        if (!this.selectedExternalSystem) {
            return;
        }

        this.$store.commit('loading');
        try {
            this.systemVersions = (await new KeyMappingService().getVersions(
                this.selectedExternalSystem.sid, this.objectSid )).result.items;
            const defaultVersion = selectVersionValidFrom
                ? this.systemVersions.find(({ validFrom }) => validFrom === selectVersionValidFrom)
                : findDefaultVersion(this.systemVersions);

            if (defaultVersion) {
                this.selectVersion(defaultVersion);
            } else {
                this.selectedVersion = null;
            }
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingVersions');
            throw error;
        } finally {
            this.$store.commit('loading');
        }
    }

    private changeRouteSystemQuery(query: string) {
        this.$router.push({
            name: this.$route.name ?? undefined,
            params: {
                option: this.$route.params.option,
            },
            query: {
                ...this.$route.query,
                system: query,
            },
        });
    }

    private async closeDetailsView(): Promise<void> {
        /**
         * Fired when the user wishes to exit the details view
         */
        this.$emit('close');
    }

    private async onSelectKeyMapping(version: KeyMappingGetResponse): Promise<void> {
        if (!await this.isFormDirty()) {
            this.selectVersion(version);
            return;
        }

        const canLeave = await this.showConfirmDialog('areYouSureSelectAnotherVersion');
        if (canLeave) {
            this.selectVersion(version);
        }
    }

    private selectVersion(version: KeyMappingGetResponse): void {
        this.selectedVersion = { ...version };
    }
}
</script>

<style scoped lang="less">
.key-mapping {
    label {
        margin-top: 1rem;
        min-width: fit-content;
    }
}
</style>
