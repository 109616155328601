import {PebbleGridCols} from '@/models/pebble/pebble-grid-cols';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component({})
export class PebbleColConst extends Vue {
    private readonly itemFormCols: PebbleGridCols = {s: 12, m: 12, l: 6, xl: 6, xxl: 6};
    private readonly smallItemFormCols: PebbleGridCols = {s: 12, m: 12, l: 3, xl: 3, xxl: 3};
    private readonly fullCols: PebbleGridCols = {s: 12, m: 12, l: 12, xl: 12, xxl: 12};
}
