<template>
    <pui-grid-container class="pui-grid-container-fix-margin">
        <pui-grid-row>
            <master-data-management-header
                :title="$t('manageBusinessPartners')"
                :data-loaded="true"
                :on-add-new="onClickAddNewButton"
                :on-check="onClickDeletedCheckBox"
                :deleted-initial-value="showDeleted"
            />
        </pui-grid-row>
        <pui-grid-row>
            <custom-table
                :columns-key="['abbreviation', 'businessPartnerSid', 'name', 'lastUpdated', 'lastUpdatedBy', 'deleted', 'action']"
                :sortable-key="['abbreviation', 'businessPartnerSid', 'name', 'lastUpdated', 'lastUpdatedBy', 'deleted']"
                :heading="heading"
                :data="data"
                :per-page="10"
                :per-page-values="[10, 50, 100]"
                enable-search
                enable-pagination
                enable-limit
                :custom-template="['deleted', 'action']"
            >
                <template v-slot:custom_deleted="{data}">
                    <boolean-text
                        :titles="{
                            textTrue: $t('yes'),
                            textFalse: $t('no'),
                        }"
                        :value="data"
                    />
                </template>
                <template v-slot:custom_action="{data}">
                    <pui-link
                        :title="$t('edit')"
                        icon="edit"
                        @click="onClickEdit(data)"
                    />
                </template>
            </custom-table>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import ComponentSecurity from '@/mixins/component-security';
import MasterDataManagementHeader from '@/components/headers/master-data-management-header.vue';
import CustomTable from '@/components/table/custom-table.vue';
import BooleanText from '@/components/text/boolean-text.vue';
import {BusinessPartnersService} from '@/services';
import {BusinessPartner, BusinessPartnerQueryModel} from '@/models';
import {EventBus} from '@/utils';

@Component({
    name: 'management-business-partners',
    components: {BooleanText, CustomTable, MasterDataManagementHeader},
})
export default class ManagementBusinessPartners extends mixins(ComponentSecurity) {
    /* VARIABLES */
    private service: BusinessPartnersService = new BusinessPartnersService();
    private data: BusinessPartner[] = [];
    private showDeleted = false;
    private filter: BusinessPartnerQueryModel = {
        page: 1,
        size: 100000,
        term: '',
        sortDirection: '',
        sortColumn: '',
        includeDeleted: false,
    };

    get heading(): any {
        return {
            abbreviation: this.$t('identifier'),
            businessPartnerSid: this.$t('sid'),
            name: this.$t('name'),
            lastUpdated: this.$t('lastUpdated'),
            lastUpdatedBy: this.$t('lastUpdatedBy'),
            deleted: this.$t('deleted'),
            action: '',
        };
    }

    /* PRIMITIVE METHODS */
    private mounted(): void {
        this.showDeleted = this.$router.currentRoute.query['deleted'] === 'true' ?? false;
        this.init();
    }

    /* METHODS */
    private async init(): Promise<void> {
        this.data = await this.loadBusinessPartner();
    }

    /* CLICK EVENT */
    private async onClickDeletedCheckBox(value: boolean): Promise<void> {
        await this.$router.replace({
            path: this.$router.currentRoute.path,
            params: this.$router.currentRoute.params,
            query: {
                deleted: value.toString(),
                t: new Date().getTime().toString(),
            },
        });
        await this.init();
    }

    private onClickAddNewButton(): void {
        this.$router.push({
            name: 'management-business-partners-new',
            query: {
                add: 'true',
            },
        });
    }

    private onClickEdit(data: BusinessPartner): void {
        this.$router.push({
            name: 'management-business-partners-edit',
            query: {
                edit: `${data.businessPartnerSid}`,
                name: `${data.name}`,
            },
        });
    }

    /* API CALLS */
    private async loadBusinessPartner(): Promise<BusinessPartner[]> {
        this.$store.commit('loading');
        try {
            this.filter.includeDeleted = this.$router.currentRoute.query['deleted']?.toString().toLowerCase() === 'true' ?? false;
            const {result: {items}} = (await this.service.get(this.filter));
            return items;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingBusinessPartners');
            throw error;
        } finally {
            this.$store.commit('loading');
        }
    }
}
</script>
