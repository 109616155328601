<template>
    <pui-grid-container class="pui-grid-container-zero-padding">
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    name="keyMapping"
                    :model="data.keyMapping"
                    :label="$t('fields.objectId')"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    type="date"
                    name="validFrom"
                    :model="data.validFrom"
                    :label="$t('validFrom')"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols" v-if="!addNew">
                <pebble-text-field
                    type="date"
                    name="validTo"
                    :model="data.validTo"
                    :label="$t('validUntil')"
                    required
                    disable
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="fullCols">
                <pebble-text-field-area
                    name="lastUpdateComment"
                    :model="data.lastUpdateComment"
                    :label="$t('comments')"
                    :max-length="1000"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="fullCols">
                <pebble-check-box
                    name="isActive"
                    :model="data.isActive"
                    :label="$t('markAsActive')"
                    @on-change="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="fullCols">
                <pebble-check-box
                    name="isDeleted"
                    :model="data.isDeleted"
                    :label="$t('markAsDeleted')"
                    @on-change="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import PebbleTextField from '@/components/pebble-form-element/pebble-text-field.vue';
import PebbleTextFieldArea from '@/components/pebble-form-element/pebble-text-field-area.vue';
import PebbleCheckBox from '@/components/pebble-form-element/pebble-check-box.vue';
import {PebbleValidate} from '@/mixins/pebble-validate';
import {Prop} from 'vue-property-decorator';
import {KeyMappingForm} from '@/models/form/key-mapping-form';
import {PebbleColConst} from '@/mixins/pebble-col-const';

@Component({
    name: 'key-mapping-data-form',
    components: {PebbleCheckBox, PebbleTextFieldArea, PebbleTextField},
})
export default class KeyMappingDataForm extends mixins(PebbleValidate, PebbleColConst) {
    @Prop({required: true})
    private data!: KeyMappingForm;

    @Prop({default: false})
    private addNew!: boolean;
}
</script>


