<template>
    <div>
        <component
            :is="component"
        />
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import HydroComponentsList from '@/views/hydro-components/hydro-components-list.vue';
import HydroComponentsNew from '@/views/hydro-components/hydro-components-new.vue';
import HydroComponentsEdit from '@/views/hydro-components/hydro-components-edit.vue';

@Component({
    name: 'hydro-components',
    components: {
        HydroComponentsList,
        HydroComponentsNew,
        HydroComponentsEdit,
    }
})
export default class HydroComponents extends Vue {
    private component = 'hydro-components-list';

    public mounted(): void {
        this.change(this.$route.name ?? '');
    }

    @Watch('$route.name')
    private onChangeRoute(value: string): void {
        this.change(value);
    }

    private change(value: string): void {
        switch (value) {
            case 'hydro-components-new':
                this.component = 'hydro-components-new';
                break;
            case 'hydro-components-edit':
                this.component = 'hydro-components-edit';
                break;
            case 'hydro-components':
            default:
                this.component = 'hydro-components-list';
        }
    }
}
</script>
