<template>
    <div
        :class="[
          'md-filter-group',
          { 'md-filter-group--is-open': isListVisible },
          { 'md-filter-group--has-selected-items' : value.length },
        ]"
        v-click-outside="close">
        <div class="md-filter-group__toggle" @click="onToggleClick">
            <div class="md-filter-group__name">
                {{ option.displayName }}
                <span v-if="value.length" class="md-filter-group__count">({{ value.length }})</span>
            </div>
            <adam-icon icon="ic-nav-breadcrumbs" class="md-filter-group__icon" />
        </div>
        <fade-transition :fast="true">
            <div :class="['md-filter-group__list', {'md-filter-group__list--left' : shouldOpenListToTheLeft()}]" v-if="isListVisible">
              <checkbox-input
                :value="selectAll"
                :label="$t('selectAll')"
                @input="handleSelectAll" />
              <checkbox-input
                v-for="(option, index) in option.options"
                :key="index"
                :label="option.name"
                :value="isOptionSelected(option)"
                @input="handleOptionChange(option, $event)" />
            </div>
        </fade-transition>
    </div>
</template>

<script lang="ts">
import FadeTransition from '@/components/transitions/fade-transition/fade-transition.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CheckboxInput from '@/components/checkbox-input/checkbox-input.vue';
import { FilterGroup, FilterOption } from '@/models';
import clickOutside from '@/directives/click-outside';

@Component({
    name: 'md-filter-group',
    components: {
        checkboxInput: CheckboxInput,
        fadeTransition: FadeTransition,
    },
    directives: {
        clickOutside,
    },
})
export default class MdFilterGroup extends Vue {
    @Prop()
    private option!: FilterGroup;

    @Prop()
    private value!: FilterOption[];

    private isListVisible = false;

    get selectAll(): boolean {
        if (!this.option.options) {
            return false;
        }
        return this.option.options.length === this.value.length;
    }

    private handleOptionChange(option: FilterOption, isSelected: boolean) {
        if (isSelected) {
            this.$emit('input', [...this.value, option]);

            return;
        }
        this.$emit('input', this.value.filter((item) => item.value !== option.value));
    }

    private close(): void {
        this.isListVisible = false;
    }
    private isOptionSelected({ value }: FilterOption): boolean {
        return this.value.some(({ value: selectedValue }) => value === selectedValue);
    }

    private onToggleClick(): void {
        this.isListVisible = !this.isListVisible;
    }

    private handleSelectAll(shouldSelectAll: boolean): void {
        this.$emit('input', shouldSelectAll ? this.option.options : []);
    }

    private shouldOpenListToTheLeft(): boolean {
        const filterGroupOffsetRight = window.innerWidth - this.$el.getBoundingClientRect().right;
        const remValue = parseFloat(getComputedStyle(document.documentElement).fontSize);
        const expectedListWidth = parseFloat(getComputedStyle(this.$el).getPropertyValue('--list-width')) * remValue;

        return filterGroupOffsetRight < expectedListWidth;
    }
 }
</script>

<style lang="less" scoped>
@import "~@/variables.less";

.md-filter-group {
    --list-width: 20rem;
    position: relative;

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
    }

    &__name {
        .md-filter-group--has-selected-items & {
            font-family: @font-roboto-md;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        width: 0.8rem;
        transition: transform 200ms ease-in;

        .md-filter-group--is-open & {
            transform: rotateZ(90deg);
        }
    }

    &__list {
        position: absolute;
        box-sizing: border-box;
        padding: 1rem;
        width: var(--list-width);
        background: @white;
        z-index: 1;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
        top: 0;
        left: 100%;
        max-height: 40vh;
        overflow: hidden;
        overflow-y: auto;

        &--left {
            left: 0;
            transform: translateX(-100%);
        }
    }
}
</style>
