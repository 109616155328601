import {SinergyLifeForm} from '@/models/form/sinergy-life-form';
import {isNumber} from 'lodash';

export const sinergylifeForm = (data?: SinergyLifeForm): SinergyLifeForm => {
    return {
        sid: {
            value: data?.sid ?? 0,
            isValid: true,
            validator: (val: number) => isNumber(val),
        },
        uniperCountryShort: {
            value: data?.uniperCountryShort ?? '',
            isValid: true,
        },
        uniperLocationShort: {
            value: data?.uniperLocationShort ?? '',
            isValid: true,
        },
        uniperLocationName: {
            value: data?.uniperLocationName ?? '',
            isValid: true,
        },
        enumObjectTypeSid: {
            value: data?.enumObjectTypeSid ?? '',
            isValid: true,
        },
        enumLocationTypeSid: {
            value: data?.enumLocationTypeSid ?? '',
            isValid: true,
        },
        locationType: {
            value: data?.locationType ?? '',
            isValid: true,
        },
        tableName: {
            value: data?.tableName ?? '',
            isValid: true,
        },
        objectType: {
            value: data?.objectType ?? '',
            isValid: true,
        },
        objectTypeSid: {
            value: data?.objectTypeSid ?? '',
            isValid: true,
        },
        slLocation: {
            value: data?.slLocation ?? '',
            isValid: true,
        },
        slCountry: {
            value: data?.slCountry ?? '',
            isValid: true,
            validator: (val: string) => val.length > 0,
        },
        slLocationLastLevel: {
            value: data?.slLocationLastLevel ?? '',
            isValid: true,
            validator: (val: string) => val.length > 0,
        },
        slLocationLevel1: {
            value: data?.slLocationLevel1 ?? '',
            isValid: true,
            validator: (val: string) => val.length > 0,
        },
        slLocationLevel2: {
            value: data?.slLocationLevel2 ?? '',
            isValid: true,
            validator: (val: string) => val.length > 0,
        },
        slLocationLevel3: {
            value: data?.slLocationLevel3 ?? '',
            isValid: true,
            validator: (val: string) => val.length > 0,
        },
        slLocationLevel4: {
            value: data?.slLocationLevel4 ?? '',
            isValid: true,
            validator: (val: string) => val.length > 0,
        },
        isDeleted: {
            value: data?.deleted ?? false,
            isValid: true,
        },
        validFrom: {
            value: data?.validFrom ?? '',
            isValid: true,
        },
        validTo: {
            value: data?.validTo ?? '',
            isValid: true,
        },
        lastUpdateComment: {
            value: data?.lastUpdateComment ?? '',
            isValid: true,
        },
    };
};
