<template>
    <view-details
        :sid="data.reservoirSid"
        :name="data.reservoirName || $t('newReservoir')"
        icon="ic-settings">
        <tab-headers
            :tabs="tabs"
            :active-tab="activeTab"
            @select-tab="selectTab" />
        <component
            :is="activeTab.component"
            :data="data"
            :isNewReservoir="isNewReservoir"
            @close="closeEditScreen()"
            @save="saveChanges" />
    </view-details>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { GenericTab, EtsUnit, Reservoir } from '@/models';
import TabHeaders from '@/components/view-details/tab-headers/tab-headers.vue';
import ReservoirIdentification from '@/components/view-details/reservoirs/reservoir-identification/reservoir-identification.vue';
import ViewDetails from '@/components/view-details/view-details.vue';

@Component({
    name: 'reservoir-details',
    components: {
        tabHeaders: TabHeaders,
        reservoirIdentification: ReservoirIdentification,
        viewDetails: ViewDetails,
    },
})
export default class ReservoirDetails extends Vue {
    /**
     * Reservoir for which to show details.
     */
    @Prop({ required: true })
    private data!: Reservoir;

    @Prop({ default: false })
    private isNewReservoir!: boolean;

    private tabs: GenericTab[] = [
        { name: 'identification', component: 'reservoirIdentification'},
    ];
    private activeTab = this.tabs[0];

    private selectTab(tab: GenericTab) {
        this.activeTab = tab;
    }

    private closeEditScreen(): void {
        this.$emit('close');
    }

    private saveChanges(item: EtsUnit, isAdd: boolean): void {
        this.$emit('save', item, isAdd, null);
    }
}
</script>
