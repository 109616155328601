<template>
    <div class="identification-container">
        <validation-observer ref="validationObserver" #default="{ validateWithInfo }">
            <div class="identification">
                <validation-provider
                    name="name"
                    rules="required"
                    #default="{ errors, failed }"
                    class="manage-input mt-3"
                    tag="div">
                    <label class="mr-2 default-label" for="enumName">{{$t('name')}}*:</label>
                    <input class="default-input" v-model="formModel.name" :placeholder="$t('name')" id="enumName" maxlength="50"
                        :class="{ 'invalid': failed }" />
                    <error-list :errors="errors" />
                </validation-provider>
                <validation-provider
                    name="description"
                    #default="{ errors, failed }"
                    class="manage-input mt-3"
                    tag="div">
                    <label class="mr-2 default-label" for="enumDescription">{{$t('description')}}:</label>
                    <textarea class="default-textarea" :placeholder="$t('description')" id="groupDescription" rows="4"
                        maxlength="50"
                        :class="{'invalid': failed }"
                        v-model="formModel.description"></textarea>
                    <error-list :errors="errors" />
                </validation-provider>
                <validation-provider class="mt-3" tag="div">
                    <checkbox-input
                        v-model="formModel.isDeleted"
                        :label="$t('markAsDeleted')" />
                </validation-provider>
                <error-list :errors="invalidMessages" />
            </div>
            <div class="members-footer">
                <adam-button
                    v-if="hasMasterDataAdmin"
                    secondary
                    class="ml-1"
                    @click="validateWithInfo().then(save)"
                >
                    {{ $t('save') }}
                </adam-button>
            </div>
        </validation-observer>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Ref, Mixins } from 'vue-property-decorator';
import { EventBus, extractErrorsFromResponse, InfiniteScrollingHelper } from '@/utils';
import { Asset } from '@/models';
import { AssetService } from '@/services';
import CheckboxInput from '@/components/checkbox-input/checkbox-input.vue';
import ErrorList from '@/components/error-list/error-list.vue';
import { ValidationObserver } from 'vee-validate';
import ComponentSecurity from '@/mixins/component-security';

@Component({
    name: 'asset-identification',
    components: {
        errorList: ErrorList,
        checkboxInput: CheckboxInput,
    },
})
export default class EnumIdentification extends Mixins(ComponentSecurity) {
    @Ref()
    private readonly validationObserver!: InstanceType<typeof ValidationObserver>;

    /*
     *  Enum type entity for which the identification tab of edit screen is displayed.
     *  e.g.: time zone, country, region, state, organization etc.
    */
    @Prop()
    private data!: Asset;

    private assetService: AssetService = new AssetService();

    private formModel: Asset = { ...this.data };
    private invalidMessages: string[] = [];

    private mounted(): void {
        EventBus.$on(EventBus.DETAIL.CLOSE, this.closeDetailsView);
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.DETAIL.CLOSE);
    }

    private closeDetailsView(): void {
        this.$emit('close');
    }

    private async save({ isValid }: any): Promise<void> {
        if (!isValid) {
            return;
        }

        let message = 'errorAddingAsset';
        this.invalidMessages = [];
        this.$store.commit('loading');
        try {
            if (this.formModel.sid > 0) {
                message = 'errorEditingAsset';
                await this.assetService.edit(this.formModel);
                this.$emit('save', this.formModel, false, null);
            } else {
                const addedAsset = (await this.assetService.add(this.formModel)).result;
                this.$emit('save', addedAsset, true, null);
            }
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, message);
            if (!err.response || !err.response.data) {
                throw err;
            }
            this.invalidMessages = extractErrorsFromResponse(err.response.data);
            if (this.invalidMessages.length) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.error-list__message');
                });
            }
        } finally {
            this.$store.commit('loading');
        }
    }
}

</script>

<style scoped lang="less">
@import "~@/variables.less";
.identification-container {
    .identification {
        label {
            margin-top: 1rem;
            min-width: fit-content;
        }
        input, textarea {
            width: 100%;
        }
    }
}
</style>
