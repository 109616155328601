export class PowerPlantAddRequest {
    locationSid?: number;
    enumLocationTypeSid?: number;
    locationShort?: string;
    locationName?: string;
    eicIdentification?: string;
    eicCode?: string;
    eicName?: string;
    zipCode?: string;
    city?: string;
    street?: string;
    houseNumber?: string;
    postbox?: string;
    telephone?: string;
    fax?: string;
    mailAddress?: string;
    enumCountrySid?: number;
    enumStateSid?: number;
    enumRegionSid?: number;
    enumOrganisationSid?: number;
    enumTimezoneSid?: number;
    latitude?: number;
    longitude?: number;
    deleted?: boolean;
    lastUpdateComment?: string;
    sappmSid?: number;
    businessIdentifier?: string;
    businessName?: string;
    
    constructor(data?: PowerPlantAddRequest) {
        this.locationSid = data?.locationSid;
        this.enumLocationTypeSid = data?.enumLocationTypeSid;
        this.locationShort = data?.locationShort;
        this.locationName = data?.locationName;
        this.eicIdentification = data?.eicIdentification;
        this.eicCode = data?.eicCode;
        this.eicName = data?.eicName;
        this.zipCode = data?.zipCode;
        this.city = data?.city;
        this.street = data?.street;
        this.houseNumber = data?.houseNumber;
        this.postbox = data?.postbox;
        this.telephone = data?.telephone;
        this.fax = data?.fax;
        this.mailAddress = data?.mailAddress;
        this.enumCountrySid = data?.enumCountrySid;
        this.enumStateSid = data?.enumStateSid;
        this.enumRegionSid = data?.enumRegionSid;
        this.enumOrganisationSid = data?.enumOrganisationSid;
        this.enumTimezoneSid = data?.enumTimezoneSid;
        this.latitude = data?.latitude;
        this.longitude = data?.longitude;
        this.deleted = data?.deleted;
        this.lastUpdateComment = data?.lastUpdateComment;
        this.sappmSid = data?.sappmSid;
        this.businessIdentifier = data?.businessIdentifier;
        this.businessName = data?.businessName;
    }
}