import { PowerPlantGetResponse } from '@/models/asset-master-data/power-plant-get-response';
import { PowerPlantAddRequest } from '@/models/asset-master-data/power-plant-add-request';
import { PowerPlantEditRequest } from '@/models/asset-master-data/power-plant-edit-request';

export class PowerPlantIdentification {
    public static mapPowerPlantAddModel(powerPlantIdentification: PowerPlantIdentification): PowerPlantAddRequest {
        return new PowerPlantAddRequest({
            locationSid: powerPlantIdentification.locationSid,
            enumLocationTypeSid: powerPlantIdentification.enumLocationTypeSid,
            locationShort: powerPlantIdentification.locationShort,
            locationName: powerPlantIdentification.locationName,
            eicIdentification: powerPlantIdentification.eicIdentification,
            eicCode: powerPlantIdentification.eicCode,
            eicName: powerPlantIdentification.eicName,
            zipCode: powerPlantIdentification.zipCode,
            city: powerPlantIdentification.city,
            street: powerPlantIdentification.street,
            houseNumber: powerPlantIdentification.houseNumber,
            postbox: powerPlantIdentification.postbox,
            telephone: powerPlantIdentification.telephone,
            fax: powerPlantIdentification.fax,
            mailAddress: powerPlantIdentification.mailAddress,
            enumCountrySid: powerPlantIdentification.enumCountrySid,
            enumStateSid: powerPlantIdentification.enumStateSid,
            enumRegionSid: powerPlantIdentification.enumRegionSid,
            enumOrganisationSid: powerPlantIdentification.enumOrganisationSid,
            enumTimezoneSid: powerPlantIdentification.enumTimezoneSid,
            latitude: powerPlantIdentification.latitude,
            longitude: powerPlantIdentification.longitude,
            deleted: powerPlantIdentification.deleted,
            lastUpdateComment: powerPlantIdentification.lastUpdateComment,
            sappmSid: powerPlantIdentification.sappmSid,
            businessIdentifier: powerPlantIdentification.businessIdentifier,
            businessName: powerPlantIdentification.businessName,
        });
    }

    public static mapPowerPlantEditModel(powerPlantIdentification: PowerPlantIdentification): PowerPlantEditRequest {
        return new PowerPlantEditRequest({
            locationSid: powerPlantIdentification.locationSid,
            enumLocationTypeSid: powerPlantIdentification.enumLocationTypeSid,
            locationShort: powerPlantIdentification.locationShort,
            locationName: powerPlantIdentification.locationName,
            eicIdentification: powerPlantIdentification.eicIdentification,
            eicCode: powerPlantIdentification.eicCode,
            eicName: powerPlantIdentification.eicName,
            zipCode: powerPlantIdentification.zipCode,
            city: powerPlantIdentification.city,
            street: powerPlantIdentification.street,
            houseNumber: powerPlantIdentification.houseNumber,
            postbox: powerPlantIdentification.postbox,
            telephone: powerPlantIdentification.telephone,
            fax: powerPlantIdentification.fax,
            mailAddress: powerPlantIdentification.mailAddress,
            enumCountrySid: powerPlantIdentification.enumCountrySid,
            enumStateSid: powerPlantIdentification.enumStateSid,
            enumRegionSid: powerPlantIdentification.enumRegionSid,
            enumOrganisationSid: powerPlantIdentification.enumOrganisationSid,
            enumTimezoneSid: powerPlantIdentification.enumTimezoneSid,
            latitude: powerPlantIdentification.latitude,
            longitude: powerPlantIdentification.longitude,
            deleted: powerPlantIdentification.deleted,
            lastUpdateComment: powerPlantIdentification.lastUpdateComment,
            sappmSid: powerPlantIdentification.sappmSid,
            businessIdentifier: powerPlantIdentification.businessIdentifier,
            businessName: powerPlantIdentification.businessName,
        });
    }

    public locationSid?: number;
    public enumLocationTypeSid?: number;
    public locationShort?: string;
    public locationName?: string;
    public eicIdentification?: string;
    public eicCode?: string;
    public eicName?: string;
    public zipCode?: string;
    public city?: string;
    public street?: string;
    public houseNumber?: string;
    public postbox?: string;
    public telephone?: string;
    public fax?: string;
    public mailAddress?: string;
    public enumCountrySid?: number;
    public enumStateSid?: number;
    public enumRegionSid?: number;
    public enumOrganisationSid?: number;
    public enumTimezoneSid?: number;
    public latitude?: number;
    public longitude?: number;
    public deleted?: boolean;
    public lastUpdatedBy?: string;
    public lastUpdated?: Date;
    public lastUpdateComment?: string;
    public ewisId?: number;
    public sappmSid?: number;
    public businessIdentifier?: string;
    public businessName?: string;

    constructor(data?: PowerPlantGetResponse) {
        this.locationSid = data?.locationSid;
        this.enumLocationTypeSid = data?.enumLocationTypeSid;
        this.locationShort = data?.locationShort;
        this.locationName = data?.locationName;
        this.eicIdentification = data?.eicIdentification;
        this.eicCode = data?.eicCode;
        this.eicName = data?.eicName;
        this.zipCode = data?.zipCode;
        this.city = data?.city;
        this.street = data?.street;
        this.houseNumber = data?.houseNumber;
        this.postbox = data?.postbox;
        this.telephone = data?.telephone;
        this.fax = data?.fax;
        this.mailAddress = data?.mailAddress;
        this.enumCountrySid = data?.enumCountrySid;
        this.enumStateSid = data?.enumStateSid;
        this.enumRegionSid = data?.enumRegionSid;
        this.enumOrganisationSid = data?.enumOrganisationSid;
        this.enumTimezoneSid = data?.enumTimezoneSid;
        this.latitude = data?.latitude;
        this.longitude = data?.longitude;
        this.deleted = data?.deleted;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdated = data?.lastUpdated;
        this.lastUpdateComment = data?.lastUpdateComment;
        this.ewisId = data?.ewisId;
        this.sappmSid = data?.sappmSid;
        this.businessIdentifier = data?.businessIdentifier;
        this.businessName = data?.businessName;
    }
}
