/**
 * Collection of helper methods for handling local storage items.
 */
export class LocalStorageHelper {
    public static setSelectedLanguage(val: any): void {
        localStorage.setItem(LocalStorageHelper.keys.selectedLanguage, JSON.stringify(val));
    }

    /**
     * Get the selected language from localstorage, if not set, return null.
     * @returns {string | null}
     */
    public static getSelectedLanguage(): string | null {
        const languageString = localStorage.getItem(LocalStorageHelper.keys.selectedLanguage);
        if (languageString !== null) {
            return JSON.parse(languageString);
        }
        return null;
    }

    private static keys = {
        selectedLanguage: 'coode.md.selectedLanguage',
    };
}
