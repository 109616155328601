var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.assets.length)?_c('pui-table',{staticClass:"assets-table",attrs:{"vendor-options":_vm.assetTableDetails,"with-pagination":true,"with-limit":true,"with-search":true},scopedSlots:_vm._u([{key:"comment",fn:function(ref){
var row = ref.row;
return [_c('pui-tooltip'),_c('div',{directives:[{name:"pui-tooltip",rawName:"v-pui-tooltip",value:({ message: row.comment }),expression:"{ message: row.comment }"}]},[_vm._v(" "+_vm._s(_vm.formatComment(row.comment))+" ")])]}},{key:"aprEndDateOfUnit",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatDate(row.aprEndDateOfUnit))+" ")]}},{key:"aprEndDateOfUnitStatus",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatDate(row.aprEndDateOfUnitStatus))+" ")]}},{key:"updateDate",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatDate(row.updateDate))+" ")]}}],null,false,2774345112)},[_vm._l((this.columnHeaders),function(columnHeader){return _c('template',{slot:("h__" + columnHeader)},[_vm._v(" "+_vm._s(_vm.$t(("assetOverviewHeaders." + columnHeader)))+" ")])})],2):_c('div',{staticClass:"message-container"},[_c('pui-loader-error',{attrs:{"title":_vm.errorMessage,"icon":"error-empty-data"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }