<template>
    <div>
        <component
            :is="component"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Watch} from 'vue-property-decorator';
import SinergyLifeLocationList from '@/views/sinergy-life-location/sinergy-life-location-list.vue';
import SinergyLifeLocationNew from '@/views/sinergy-life-location/sinergy-life-location-new.vue';
import SinergyLifeLocationEdit from '@/views/sinergy-life-location/sinergy-life-location-edit.vue';

@Component({
    name: 'sinergy-life-location',
    components: {SinergyLifeLocationList, SinergyLifeLocationNew, SinergyLifeLocationEdit},
})
export default class SinergyLifeLocation extends Vue {
    private component = 'sinergy-life-location-list';

    public mounted(): void {
        this.change(this.$route.name ?? '');
    }

    @Watch('$route.name')
    private onChangeRoute(value: string): void {
        this.change(value);
    }

    private change(value: string): void {
        switch (value) {
            case 'sinergy-life-location-new':
                this.component = 'sinergy-life-location-new';
                break;
            case 'sinergy-life-location-edit':
                this.component = 'sinergy-life-location-edit';
                break;
            case 'sinergy-life-location':
            default:
                this.component = 'sinergy-life-location-list';
        }
    }
}
</script>


