<template>
    <card>
        <pui-grid-container>
            <card-header
                :title="$t('newOperationMode')"
                :on-click-back-button="onClickBackButton"
            />
            <pui-grid-row>
                <fake-single-tab
                    :title="$t('identification')"
                />
            </pui-grid-row>
            <pui-grid-row
                :class="errors.length > 0 ? 'pebble-errors-list-margin-top' : ''"
            >
                <pebble-errors-list
                    id="errors-list"
                    :errors="errors"
                />
            </pui-grid-row>
            <pui-form
                aria-label="Form"
                v-if="check.dataLoaded"
            >
                <pui-grid-row>
                    <pui-grid-column class="pui-grid-column-zero-padding">
                        <operation-modes-base-data-form
                            :data="formValues"
                            :is-add-new-operation="true"
                            :operation-mode-types="operationModeTypes"
                            :inclusion-method-list="inclusionMethodList"
                            :operation-mode-energy-types="operationModeEnergyTypes"
                            @validate="validate"
                        />
                    </pui-grid-column>
                </pui-grid-row>
                <pui-grid-row>
                    <pui-grid-column class="pui-grid-column-zero-padding">
                        <operation-modes-performance-data-form
                            :data="formValues"
                            @validate="validate"
                        />
                    </pui-grid-column>
                </pui-grid-row>
            </pui-form>
            <cancel-save-buttons
                :save-button-is-disabled="!hasMasterDataAdmin || check.saveButtonIsDisabled"
                :on-click-cancel-button="onClickBackButton"
                :on-click-save-button="onClickSaveButton"
            />
        </pui-grid-container>
    </card>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import ComponentSecurity from '@/mixins/component-security';
import Card from '@/components/cards/card.vue';
import CardHeader from '@/components/cards/card-header.vue';
import FakeSingleTab from '@/components/tab-bar/fake-single-tab.vue';
import PebbleErrorsList from '@/components/error-list/pebble-errors-list.vue';
import {PebbleNotification} from '@/models/pebble/pebble-notification';
import OperationModesBaseDataForm from '@/components/forms/operation-modes-base-data-form.vue';
import OperationModesPerformanceDataForm from '@/components/forms/operation-modes-performance-data-form.vue';
import {IdentificationOperationModesForm} from '@/models/form/identification-operation-modes-form';
import {PebbleDropDown, pebbleDropDownFromAssets} from '@/models/pebble/pebble-drop-down';
import {CheckModel} from '@/models/check-model';
import {generateDataFromFormValues, generateErrors, isValidInput, shouldDisableForm} from '@/utils/utils';
import {Asset, IdTypeSid, UnitOperationModeAddRequest} from '@/models';
import {EventBus} from '@/utils';
import {AssetService, UnitService} from '@/services';
import CancelSaveButtons from '@/components/buttons/cancel-save-buttons.vue';
import {identificationOperationModesForm} from '@/utils/pebble-form/master-data-management/identification-operation-modes-form';
import {OperationModeFactory} from '@/utils/factories';
import { routeNames } from '@/router/route-names';
import { RawLocation } from 'vue-router';

@Component({
    name: 'management-operation-modes-new',
    components: {
        CancelSaveButtons,
        OperationModesPerformanceDataForm,
        OperationModesBaseDataForm,
        PebbleErrorsList,
        FakeSingleTab,
        CardHeader,
        Card,
    },
})
export default class ManagementOperationModesNew extends mixins(ComponentSecurity) {
    private unitService: UnitService = new UnitService();
    private assetsService: AssetService = new AssetService();

    private unitId?: number;
    private errors: PebbleNotification[] = [];
    private check: CheckModel = {
        dataLoaded: false,
        saveButtonIsDisabled: true,
        showError: false,
    };

    /* LATE VARIABLES */
    private formValues!: IdentificationOperationModesForm;
    private operationModeTypes!: PebbleDropDown[];
    private operationModeEnergyTypes!: PebbleDropDown[];
    private inclusionMethodList!: PebbleDropDown[];

    private mounted(): void {
        this.init();
    }

    /* ON CLICK BUTTON */
    private async onClickBackButton(): Promise<void> {
        const route = this.isRedirectionFromUnit()
            ? {
                name: routeNames.EDIT_UNIT,
                params: {
                    id: this.$route.query?.unitId,
                },
                query: {
                    powerPlantId: this.$route.query?.powerPlantId,
                    tabIndex: '2',
                },
            }
            : {
                name: `management-operation-modes`,
            };

        await this.$router.push(route as RawLocation);
    }

    private isRedirectionFromUnit(): boolean {
        const { powerPlantId,  redirectFromUnit } = this.$route.query;
        return !!powerPlantId && !!redirectFromUnit;
    }

    private onClickSaveButton(): void {
        this.saveData();
    }

    /* METHODS */
    private async init(): Promise<void> {
        this.errors = [];
        this.check.dataLoaded = false;
        this.unitId = Number(this.$route.query?.unitId);
        this.$store.commit('loading');
        const [operationModeTypes, operationModeEnergyTypes, inclusionMethodList] = await Promise.all([
            this.loadAssets(IdTypeSid.OPERATION_MODE_TYPE),
            this.loadAssets(IdTypeSid.OPERATION_MODE_ENERGY_TYPE),
            this.loadAssets(IdTypeSid.CONSOLIDATION),
        ]);
        this.operationModeTypes = pebbleDropDownFromAssets(operationModeTypes);
        this.operationModeEnergyTypes = pebbleDropDownFromAssets(operationModeEnergyTypes);
        this.inclusionMethodList = pebbleDropDownFromAssets(inclusionMethodList);
        this.formValues = identificationOperationModesForm(OperationModeFactory.createNewOperationMode(this.unitId));
        this.$store.commit('loading');
        this.check.dataLoaded = true;
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues);
    }

    private validate(value: string, name: string): void {
        isValidInput(name, this.formValues[name]);
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues);
    }

    private async saveData(): Promise<void> {
        if (await this.saveNew(generateDataFromFormValues(this.formValues) as UnitOperationModeAddRequest)) {
            this.onClickBackButton();
        }
    }

    /* API CALLS */
    private async loadAssets(idTypeSid: number): Promise<Asset[]> {
        let assets: Asset[] = [];
        try {
            const {result: {items}} = await this.assetsService.getByIdTypeSid(idTypeSid, {page: 1, size: 1000, term: ''});
            assets = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
        return assets;
    }

    private async saveNew(model: UnitOperationModeAddRequest): Promise<boolean> {
        this.$store.commit('loading');
        let result = false;
        try {
            await this.unitService.addNewOperationMode(model);
            result = true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingOperationMode');
            generateErrors(this.errors, error);
        } finally {
            this.$store.commit('loading');
        }
        return result;
    }
}
</script>
