<template>
    <pui-grid-container class="pui-grid-container-fix-margin">
        <pui-grid-row>
            <master-data-management-header
                :title="$t('manageEtsUnits')"
                :data-loaded="dataLoaded"
                :on-add-new="onClickAddNewButton"
            />
        </pui-grid-row>
        <pui-grid-row v-if="dataLoaded">
            <custom-table
                :columns-key="['name', 'etsId', 'lastUpdated', 'lastUpdatedBy', 'isDeleted', 'action']"
                :data="data"
                :sortable-key="['name', 'etsId', 'lastUpdated', 'lastUpdatedBy', 'isDeleted']"
                :heading="heading"
                :per-page="10"
                :per-page-values="[10, 50, 100]"
                enable-search
                enable-pagination
                enable-limit
                :custom-template="['isDeleted', 'action']"
            >
                <template v-slot:custom_isDeleted="{data}">
                    <boolean-text
                        :titles="{
                            textTrue: $t('yes'),
                            textFalse: $t('no'),
                        }"
                        :value="data"
                    />
                </template>
                <template v-slot:custom_action="{data}">
                    <pui-link
                        :title="$t('edit')"
                        icon="edit"
                        @click="onClickEditButton(data)"
                    />
                </template>
            </custom-table>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import ComponentSecurity from '@/mixins/component-security';
import MasterDataManagementHeader from '@/components/headers/master-data-management-header.vue';
import {ETSService} from '@/services';
import {EtsUnit, EtsUnitsQueryModel} from '@/models';
import {EventBus} from '@/utils';
import CustomTable from '@/components/table/custom-table.vue';
import BooleanText from '@/components/text/boolean-text.vue';

@Component({
    name: 'management-ets-unit',
    components: {BooleanText, CustomTable, MasterDataManagementHeader},
})
export default class ManagementEtsUnit extends mixins(ComponentSecurity) {
    /* VARIABLES */
    private service: ETSService = new ETSService();
    private dataLoaded = false;
    private data: EtsUnit[] = [];
    private filter: EtsUnitsQueryModel = {
        page: 1,
        size: 100000,
        term: '',
        sortDirection: '',
        sortColumn: '',
    };

    get heading(): any {
        return {
            name: this.$t('name').toString(),
            etsId: this.$t('etsId').toString(),
            lastUpdated: this.$t('lastUpdated').toString(),
            lastUpdatedBy: this.$t('lastUpdatedBy').toString(),
            isDeleted: this.$t('deleted').toString(),
            action: '',
        };
    }

    /* PRIMITIVE METHODS */
    private mounted(): void {
        this.init();
    }

    /* METHODS */
    private async init(): Promise<void> {
        this.dataLoaded = false;
        this.data = await this.loadETSUnits();
        this.dataLoaded = true;
    }

    /* ON CLICK BUTTONS */
    private onClickAddNewButton(): void {
        this.$router.push({
            name: 'management-ets-unit-new',
            query: {
                add: 'true',
            },
        });
    }

    private onClickEditButton(data: EtsUnit): void {
        this.$router.push({
            name: 'management-ets-unit-edit',
            query: {
                edit: `${data.etsId}`,
                name: `${data.name}`,
                tab: '0',
            },
        });
    }

    /* API CALLS */
    private async loadETSUnits(): Promise<EtsUnit[]> {
        this.$store.commit('loading');
        let etsUnits: EtsUnit[] = [];
        try {
            const {result: {items}} = (await this.service.get(this.filter));
            etsUnits = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingEtsUnits');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
        return etsUnits;
    }
}
</script>
