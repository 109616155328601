import Component from 'vue-class-component';
import Vue from 'vue';

@Component({})
export class PebbleValidate extends Vue {
    private validate(value: string, name: string): void {
        this.$emit('validate', value, name);
        this.$forceUpdate();
    }
}
