import {MaterialGroupAddRequest, MaterialGroupModel} from '@/models';
import {IdentificationMaterialGroupForm} from '@/models/form/identification-material-group-form';
import {format} from 'date-fns';
import {ONLY_DATE_FORMAT} from '@/utils/constants';

export const identificationMaterialGroupForm = (data?: MaterialGroupModel | MaterialGroupAddRequest): IdentificationMaterialGroupForm => {
    return {
        shortName: {
            value: data?.shortName,
            isValid: true,
            validator: (val: string) => val.length > 0,
        },
        materialGroupName: {
            value: data?.materialGroupName,
            isValid: true,
            validator: (val: string) => val.length > 0,
        },
        enumSuMaterialGroupSid: {
            value: data?.enumSuMaterialGroupSid ?? -1,
            isValid: true,
            isDropDown: true,
            validator: (val: number) => val.toString() !== '-1',
        },
        enumMaterialTypeSid: {
            value: data?.enumMaterialTypeSid ?? -1,
            isValid: true,
            isDropDown: true,
            validator: (val: number) => val.toString() !== '-1',
        },
        materialGroupSid: {
            value: data?.materialGroupSid,
            isValid: true,
        },
        enumSuMaterialGroup: {
            value: (data as MaterialGroupModel).enumSuMaterialGroup,
            isValid: true,
        },
        enumMaterialType: {
            value: (data as MaterialGroupModel).enumMaterialType,
            isValid: true,
        },
        isDeleted: {
            value: data?.isDeleted ?? false,
            isValid: true,
        },
        lastUpdateComment: {
            value: data?.lastUpdateComment,
            isValid: true,
        },
        lastUpdated: {
            value: data?.lastUpdated ? format(new Date(data?.lastUpdated), ONLY_DATE_FORMAT) : '',
            isValid: true,
            isDate: true,
        },
        lastUpdatedBy: {
            value: data?.lastUpdatedBy,
            isValid: true,
        },
    };
};
