<template>
    <md-card
        icon="ic-unit-blue"
        :name="card.reservoirName"
        :sid="card.reservoirSid">
        <template v-slot:card-properties>
            <div class="flex-col last-updated border-right mr-2 pr-2 center-vertically">
                <div>
                    <b>{{ card.river }}</b>
                </div>
            </div>
            <div class="flex-col last-updated border-right mr-2 pr-2 center-vertically">
                <div>
                    <b>{{ card.riverGroup }}</b>
                </div>
            </div>
        </template>
        <template v-slot:action-buttons>
            <div class="res-actions">
                <adam-button
                    icon="ic-settings"
                    fab
                    :outlined.prop="false"
                    @click="edit()"
                    inverted/>
            </div>
        </template>
    </md-card>
</template>

<script lang="ts">

import { Component, Prop, Mixins } from 'vue-property-decorator';
import { Reservoir } from '@/models';
import { DateHelper } from '@/utils';
import MdCard from '@/components/cards/cards-list/md-card/md-card.vue';
import ComponentSecurity from '@/mixins/component-security';

@Component({
    name: 'reservoir-card-list',
    components: {
        mdCard: MdCard,
    },
})
export default class ReservoirCardList extends Mixins(ComponentSecurity) {
    /*
     * Card information which will be displayed.
    */
    @Prop({ required: true })
    private card!: Reservoir;

    private edit(): void {
        this.$emit('edit', this.card);
    }

    private remove(): void {
        this.$emit('remove', this.card);
    }

    private formatDateTime(date: string): string {
        return DateHelper.formatDateTime(date);
    }
}

</script>
