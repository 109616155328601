import { ApiResponse, PagedResponse, NetConnectorsModel, NetConnectorsAddRequest,
  NetConnectorsEditRequest, EmissionFactorVersion,
} from '@/models';
import { store } from '@/store';
import { EventBus } from '@/utils';
import ErrorHandler from '@/utils/error-handler';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
class EmissionFactorService {
  private api = new SdkGenericApiHelper('units');

  public async get(unitSid: any): Promise<ApiResponse<PagedResponse<NetConnectorsModel>>> {
      return (await this.api.getByPathOrParam(`${unitSid}/emission-factors`)).data;
  }

  public async getEmissionFactors(unitSid: number): Promise<EmissionFactorVersion[]> {
      store.commit('loading');
      try {
          return (await this.get(unitSid))
              ?.result
              .items
              ?.map((item) => new EmissionFactorVersion(item)) ?? [];
      } catch (error: any) {
          EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingEmissionFactors');
          return [];
      } finally {
          store.commit('loading');
      }
  }

  public async getEmissionFactor(machineId?: number, validFrom?: string): Promise<EmissionFactorVersion> {
      try {
          store.commit('loading');
          const result = (await this.api.getByPathOrParam(`${machineId}/emission-factors/versions/${validFrom}`))
              ?.data
              ?.result;
          return new EmissionFactorVersion(result);
      } catch (error: any) {
          EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingEmissionFactor');
          return new EmissionFactorVersion();
      } finally {
          store.commit('loading');
      }
  }

  public async addEmissionFactor(emissionFactor: EmissionFactorVersion): Promise<[boolean, string | undefined]> {
      try {
          store.commit('loading');
          await this.api.post(EmissionFactorVersion.mapToAddEditRequest(emissionFactor),
              `${emissionFactor.unitSid}/emission-factors`);
          EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
          return [true, undefined];
      } catch (error: any) {
          EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
          return [false, ErrorHandler.getAxiosErrorMessage(error)];
      } finally {
          store.commit('loading');
      }
  }

  public async updateEmissionFactor(emissionFactor: EmissionFactorVersion, initialValidFrom: string): Promise<[boolean, string | undefined]> {
      try {
          store.commit('loading');
          await this.api.put(`${emissionFactor.unitSid}/emission-factors/versions/${initialValidFrom}`,
              EmissionFactorVersion.mapToAddEditRequest(emissionFactor));
          EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
          return [true, undefined];
      } catch (error: any) {
          EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
          return [false, ErrorHandler.getAxiosErrorMessage(error)];
      } finally {
          store.commit('loading');
      }
  }

  public async deleteEmissionFactor(emissionFactor: EmissionFactorVersion): Promise<boolean> {
      try {
          store.commit('loading');
          await this.api.delete(`${emissionFactor.unitSid}/emission-factors/versions/${emissionFactor.validFrom}`);
          EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
          return true;
      } catch (error: any) {
          const errorMessage = ErrorHandler.getAxiosErrorMessage(error) ?? 'errorRemovingEmissionFactor';
          EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, errorMessage);
          return false;
      } finally {
          store.commit('loading');
      }
  }
}

const emissionFactorService = new EmissionFactorService();

export {
  EmissionFactorService,
  emissionFactorService,
};
