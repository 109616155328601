import {KeyMappingModel} from '@/models/key-mapping-model';
import format from 'date-fns/format';
import {DATE_FORMAT} from '@/utils';

export class KeyMappingFactory {
    public static create(objectSid: number, enumStreamSystemSid = 0): KeyMappingModel {
        return {
            objectSid,
            enumStreamSystemSid,
            validFrom: format(new Date(), DATE_FORMAT),
            validTo: '2999-12-31T00:00:00.000',
            isActive: true,
            isDeleted: false,
            isExclusive: false,
            keyMapping: '',
            lastUpdateComment: '',
            lastUpdatedBy: '',
            lastUpdated: '',
        };
    }
}
