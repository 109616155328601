<template>
    <pui-grid-container
        v-if="dataLoaded"
        class="pui-grid-container-fix-margin"
    >
        <pui-grid-row>
            <sinergy-life-location-header
                :selected-countries="selectedFilters.countries"
                :selected-asset-types="selectedFilters.assetTypes"
                @filter-change="(value) => getFilterValues(value)"
            />
        </pui-grid-row>
        <pui-grid-row>
            <custom-table
                :columns-key="columns"
                :data="dataTable"
                :heading="heading"
                :sortable-key="sortable"
                :per-page="10"
                :per-page-values="[10, 50, 100]"
                :enable-pagination="true"
                :enable-search="true"
                :enable-limit="true"
                :custom-template="['deleted', 'action']"
                :initial-order-by="{ column: 'sid', ascending: true }"
            >
                <template v-slot:custom_deleted="{data}">
                    <boolean-text
                        :titles="{
                            textTrue: $t('yes'),
                            textFalse: $t('no'),
                        }"
                        :value="data"
                    />
                </template>
                <template v-slot:custom_action="{data}">
                    <pui-link
                        :title="$t('edit')"
                        icon="edit"
                        @click="goToEdit(data)"
                    />
                </template>
            </custom-table>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {SinergyLifeData, SinergyLifeDataKeys, SinergyLifeDataSortableKeys, SynergyLifeQuery} from '@/models/sinergy-life-data';
import BooleanText from '@/components/text/boolean-text.vue';
import SinergyLifeLocationHeader from '@/components/headers/sinergy-life-location-header.vue';
import CustomTable from '@/components/table/custom-table.vue';
import {ActionTableModel} from '@/models/table/action-table-model';
import {SynergyLifeService} from '@/services';
import {DateHelper, EventBus} from '@/utils';
import PersistentFilter from '@/utils/local-storage/persistent-filter';
import { configKeys } from '@/utils/local-storage/config-keys';

const persistentFilter = new PersistentFilter(configKeys.SYNERGY_LIFE_LOCATION);

@Component({
    name: 'sinergy-life-location-list',
    components: {
        CustomTable, SinergyLifeLocationHeader, BooleanText,
    },
})
export default class SinergyLifeLocationList extends Vue {
    private columns = SinergyLifeDataKeys;
    private sortable = SinergyLifeDataSortableKeys;
    private dataTable: SinergyLifeData[] = [];
    private synergyLifeService: SynergyLifeService = new SynergyLifeService();
    private dataLoaded = false;
    private selectedFilters = {
        countries: persistentFilter.getConfigArray(configKeys.COUNTRY_IDS),
        assetTypes: persistentFilter.getConfigArray(configKeys.ASSET_TYPES),
    };
    private synergyDataQuery: SynergyLifeQuery = {
        term: '',
        page: 1,
        size: 999,
        Country: [],
        SynergiLifeObjectType: [],
        SortColumn: '',
        IncludeDeleted: false,
        SortDirection: '',
    };

    public async mounted(): Promise<void> {
        this.synergyDataQuery.Country = this.selectedFilters.countries;
        this.dataTable = await this.getTableData(this.synergyDataQuery);
        this.dataLoaded = true;
    }

    get action(): ActionTableModel {
        return {
            title: 'Edit',
            icon: 'edit',
        };
    }

    get heading(): any {
        return {
            sid: 'SID',
            country: 'Country',
            uniperCountryShort: 'Country short',
            uniperLocationShort: 'Location short',
            abbreviation: 'Abbreviation',
            tableName: 'Table name',
            synergiLifeObjectType: 'Asset type',
            slLocation: 'Synergy Life Location',
            slCountry: 'SL Country',
            slLocationLastLevel: 'SL Location Last Level',
            slLocationLevel1: 'SL Location Level 1',
            slLocationLevel2: 'SL Location Level 2',
            slLocationLevel3: 'SL Location Level 3',
            slLocationLevel4: 'SL Location Level 4',
            deleted: 'Deleted',
            action: '',
        };
    }

    private async getFilterValues(value: any): Promise<void> {
        this.selectedFilters = {
            countries: value.country,
            assetTypes: value.asset,
        };
        persistentFilter.setConfig(configKeys.COUNTRY_IDS, value.country);
        persistentFilter.setConfig(configKeys.ASSET_TYPES, value.asset);

        this.synergyDataQuery = {
            term: '',
            page: 1,
            size: 999,
            Country: value.country,
            SynergiLifeObjectType: value.asset,
            SortColumn: '',
            IncludeDeleted: false,
            SortDirection: '',
        };
        this.dataTable = await this.getTableData(this.synergyDataQuery);
    }

    private async getTableData(query: SynergyLifeQuery): Promise<SinergyLifeData[]> {
        this.$store.commit('loading');
        try {
            const data = (await this.synergyLifeService.getTable(query)).result.items;
            data.forEach((tableData) => {
                if (tableData.lastUpdated) {
                    tableData.lastUpdated = DateHelper.formatDate(tableData.lastUpdated);
                }
            });
            return data;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingSynergyData');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }


    private goToEdit(data: SinergyLifeData) {
        const sid: string | undefined = data.sid?.toString();
        const slLocation: string | undefined = data.slLocationSid;
        if (sid !== undefined) {
            this.$router.push({
                name: 'sinergy-life-location-edit',
                query: {
                    sid,
                    slLocation,
                },
            });
        }
    }
}
</script>

<style scoped lang="less">
@import '../../variables.less';

/deep/ .pui-table.VueTables.pui-table--with-limit .VueTables__limit {
    font-size: 1.6rem;
}

/deep/ .pui-table.VueTables.pui-table--with-pagination .VuePagination__pagination li.active a {
    color: black;
}

/deep/ .pui-table .VueTables__table thead th {
    min-width: 20rem;
    font-weight: 600;
    color: black;
}

</style>
