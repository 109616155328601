import { ObjectKeys } from '@/models/object-keys'

export interface TransformerVersionData extends ObjectKeys {
    validFrom: string;
    validTo: string;
    transformerSid: string;
    name: string;
    lastUpdatedBy: string;
    lastUpdated: string;
}

export interface TransformerGetVersionData extends ObjectKeys {
    transformerSid: string;
    validFrom?: string;
    validTo?: string;
    deleted: boolean;
    transformerName: string;
    plantSid: number;
    technicalLocation?: string;
    manufacturerEnumSid?: number;
    transformerTypeEnumSid?: number;
    constructionYear?: number;
    nominalPower?: number;
    primaryVoltageOs?: number;
    secondaryVoltageOs?: number;
    coolingSystemEnumSid?: number;
    oilWeight?: number;
    totalWeight?: number;
    decommissioningDate?: string;
    equipmentNumber?: number;
    isReserve: boolean;
    storagePlantSid?: number;
}

export const TransformerEditDataKeys = [
    'validFrom',
    'validTo',
    'transformerSid',
    'name',
    'lastUpdated',
    'lastUpdatedBy',
    'action',
] as const;

