<template>
    <div class="status-list mt-2">
        <div class="status-header sticky">
            <div class="flex-row">
                <div v-for="(col, colIndex) of header.columns"
                    :key="colIndex"
                    class="flex-col"
                    :class="col.class">
                    {{ $t(col.text) }}
                </div>
            </div>
        </div>
        <div class="status-content">
            <div v-if="items.length === 0"
                class="flex-row ml-1 mt-1 mb-1">
                {{ $t('noDataToShow') }}
            </div>
            <div v-for="item of items"
                :key="item.validFrom"
                class="flex-row status"
                :class="{ 'selected': isSelectedCb(item) }"
                @click="selectItem(item)">
                <div v-for="(col, colIndex) of header.columns"
                    :key="colIndex"
                    class="flex-col"
                    :class="col.class">
                    <span v-if="col.formatFunction">
                        {{ col.formatFunction(item[col.displayKey]) }}
                    </span>
                    <span v-else>
                        {{ item[col.displayKey] }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Vue, Component, Prop } from 'vue-property-decorator';
import { Header, VersioningBase } from '@/models';

/**
 * Table view component for displaying data in a table format.
 */
@Component({
    name: 'table-view',
})
export default class TableView<T extends VersioningBase> extends Vue {
    /**
     * The header property is used to define how the data is displayed in the
     * table.
     */
    @Prop({ required: true })
    private header!: Header;

    /**
     * Provide a callback method to determine if the candidate row is selected
     * in the table
     *
     * @argument {T} candidate - the candidate for which the callback function
     * is called
     * @returns {boolean} - if true, the row will be displayed as selected
     */
    @Prop({ required: true })
    private isSelectedCb!: (candidate: T) => any;

    /**
     * Table data to be displayed
     */
    @Prop({ default: () => [] })
    private items!: T[];

    private selectItem(item: T): void {
        if (!this.isSelectedCb(item)) {
            /**
             * Fired when an item is clicked
             *
             * @argument {T} item - the item that was clicked
             */
            this.$emit('select-item', item);
        }
    }
}

</script>

<style scoped lang="less">
</style>
