<template>
    <pui-grid-container
        v-if="isInitialDataLoaded"
        class="pui-grid-container-fix-margin"
    >
        <pui-grid-row>
            <hydro-components-header
                :selected-plant-groups="selectedFilters.plantGroup"
                :selected-plants="selectedFilters.plant"
                :show-deleted-items="showDeletedItems"
                @change:filters="getFilterValues"
                @change:deleted="changeShowDeletedItems"
            />
        </pui-grid-row>
        <pui-grid-row>
            <custom-table
                :columns-key="columns"
                :heading="heading"
                :data="dataTable"
                :per-page="10"
                :per-page-values="[10, 50, 100]"
                :enable-pagination="true"
                :enable-search="true"
                :enable-limit="true"
                :custom-template="['isDeleted', 'isReserve', 'action']"
            >
                <template v-slot:custom_isDeleted="{ data }">
                    <boolean-text
                        :titles="{
                            textTrue: $t('yes'),
                            textFalse: $t('no'),
                        }"
                        :value="data"
                    />
                </template>
                <template v-slot:custom_isReserve="{ data }">
                    <boolean-text
                        :titles="{
                            textTrue: $t('yes'),
                            textFalse: $t('no'),
                        }"
                        :value="data"
                    />
                </template>
                <template v-slot:custom_action="{ data }">
                    <pui-link
                        v-if="canEditHydroComponents"
                        :title="$t('edit')"
                        icon="edit"
                        @click="goToEdit(data)"
                    />
                    <pui-link
                        v-else
                        :title="$t('hydroComponents.actions.showDetails')"
                        icon="visible"
                        @click="goToEdit(data)"
                    />
                </template>
            </custom-table>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Mixins } from 'vue-property-decorator';
import { TransformersData, TransformersDataKeys, TransformersQuery } from '@/models/hydro-components/transformers-data';
import { TransformersService } from '@/services/transformers-service';
import { DateHelper, EventBus } from '@/utils';
import CustomTable from '@/components/table/custom-table.vue';
import BooleanText from '@/components/text/boolean-text.vue';
import HydroComponentsHeader from '@/components/headers/hydro-components-header.vue';
import { LocaleMessage } from 'vue-i18n';
import ComponentSecurity from '@/mixins/component-security';

@Component({
    name: 'hydro-components-list',
    components: {
        HydroComponentsHeader,
        BooleanText,
        CustomTable
    }
})
export default class HydroComponentsList extends Mixins(ComponentSecurity) {
    private transformersService = new TransformersService();
    private isInitialDataLoaded = false;

    private columns = TransformersDataKeys;
    private dataTable: TransformersData[] = [];

    private selectedFilters = {
        plantGroup: [],
        plant: [],
    };

    private showDeletedItems = false;

    private transformersQuery: TransformersQuery = {
        term: '',
        pageSize: 999,
        pageNumber: 1,
        assetGroupSids: [],
        plantSids: [],
        showDeleted: false,
    };

    public mounted(): void {
        this.loadInitialData().then();
    }

    private async loadInitialData(): Promise<void> {
        this.dataTable = await this.getTableData(this.transformersQuery);
        this.isInitialDataLoaded = true;
    }

    private get heading(): Record<(typeof TransformersDataKeys)[number], LocaleMessage> {
        return {
            transformerSid: this.$t('hydroComponents.headings.transformerSid'),
            plantGroupName: this.$t('hydroComponents.headings.plantGroupName'),
            plantName: this.$t('hydroComponents.headings.plantName'),
            transformerName: this.$t('hydroComponents.headings.name'),
            technicalLocation: this.$t('hydroComponents.headings.technicalLocation'),
            lastUpdated: this.$t('lastUpdated'),
            lastUpdatedBy: this.$t('lastUpdatedBy'),
            isDeleted: this.$t('hydroComponents.headings.isDeleted'),
            isReserve: this.$t('hydroComponents.headings.isReserve'),
            action: '',
        };
    }

    private async getFilterValues(value: any): Promise<void> {
        this.selectedFilters = {
            plantGroup: value.plantGroup,
            plant: value.plant,
        };

        this.transformersQuery.assetGroupSids = this.selectedFilters.plantGroup;
        this.transformersQuery.plantSids = this.selectedFilters.plant;
        this.dataTable = await this.getTableData(this.transformersQuery);
    }

    private async changeShowDeletedItems(newShowDeletedItems: boolean): Promise<void> {
        this.showDeletedItems = newShowDeletedItems;

        this.transformersQuery.showDeleted = this.showDeletedItems;
        this.dataTable = await this.getTableData(this.transformersQuery);
    }

    private async getTableData(query: TransformersQuery): Promise<TransformersData[]> {
        this.$store.commit('loading');

        try {
            const data = (await this.transformersService.getTable(query)).result.items;

            data.forEach((tableData) => {
                if (tableData.lastUpdated) {
                    tableData.lastUpdated = DateHelper.formatDate(tableData.lastUpdated);
                }
            });

            return data;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'hydroComponents.toastMessages.failedToLoadTableData');
            throw err;
        } finally {
            this.$store.commit('loading')
        }
    }

    private goToEdit(data: TransformersData): void {
        this.$router.push({
            name: 'hydro-components-edit',
            query: {
                transformerSid: data.transformerSid,
            }
        });
    }
}
</script>
