export const configKeys = {
    ASSET_MASTER_DATA: 'assetMasterData',
    ASSET_OVERVIEW: 'assetOverview',
    SYNERGY_LIFE_LOCATION: 'synergyLifeLocation',

    COUNTRY_IDS: 'countryIds',
    FLEET_IDS: 'fleetIds',
    PLANT_GROUP_IDS: 'plantGroupIds',
    CLASS_IDS: 'classIds',
    MACHINE_TYPE_IDS: 'machineTypeIds',

    SEARCH_TERM: 'searchTerm',
    INCLUDE_DELETED: 'includeDeleted',
    SORT_COLUMN: 'sortColumn',
    SORT_DIRECTION: 'sortDirection',
    PAGE_NUMBER: 'pageNumber',
    PAGE_SIZE: 'pageSize',

    PRIMARY_ENERGY_IDS: 'primaryEnergyIds',
    RIVER_IDS: 'riverIds',
    REGIONAL_UNIT_IDS: 'regionalUnitIds',
    STATUS_IDS: 'statusIds',
    LEGAL_ENTITY_IDS: 'legalEntityIds',
    UNIT_TECHNOLOGY_IDS: 'unitTechnologyIds',
    ASSET_TYPES: 'assetTypes',
};
