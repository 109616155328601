<template>
    <div class="reservoirs-identification">
        <template v-if="!isNewReservoir">
            <table-view
                v-if="reservoirList.length"
                :header="reservoirHeader"
                :items="reservoirList"
                :is-selected-cb="(candidate) => selectedResevoir && candidate.validFrom === selectedResevoir.validFrom"
                @select-item="onSelectReservoir" />
        </template>
        <div v-if="reservoirDetails">
            <validation-observer ref="validationObserver">
                <div class="formModel">
                    <validation-provider
                        name="validFrom"
                        rules="required"
                        #default="{ errors, failed }">
                        <div class="manage-input">
                            <label class="mr-2 default-label">{{ $t('validFrom') }}*:</label>
                            <md-date-picker
                                v-model="reservoirDetails.validFrom"
                                :disabled-date="checkDisabledDate"
                                :class="{ 'invalid': failed }" />
                            <error-list :errors="errors" />
                        </div>
                    </validation-provider>
                    <div class="flex-row manage-input"
                        v-if="reservoirDetails.validTo">
                        <label class="mr-2 default-label">{{ $t('validUntil') }}:</label>
                        <md-date-picker
                            v-model="reservoirDetails.validTo"
                            :disabled-date="checkDisabledDate"
                            disabled />
                    </div>
                    <div v-if="data.reservoirSid" class="flex-row manage-input">
                        <label class="mr-2 default-label" for="reservoirSid">{{ $t('reservoirSid') }}:</label>
                        <input class="mb-1 default-input" v-model="data.reservoirSid" :placeholder="$t('reservoirSid')" id="reservoirSid" disabled />
                    </div>
                    <validation-provider
                        name="validFrom"
                        rules="required"
                        #default="{ errors, failed }">
                            <div class="flex-row manage-input">
                                <label class="mr-2 default-label" for="reservoirName">{{ $t('reservoirName') }}*:</label>
                                <input
                                    v-model="reservoirDetails.reservoirName"
                                    :placeholder="$t('reservoirName')"
                                    id="reservoirName"
                                    :class="{ 'invalid': failed }"  />
                            </div>
                        <error-list :errors="errors" />
                    </validation-provider>
                    <validation-provider
                        tag="div"
                        class="manage-input"
                        #default="{ errors, failed }"
                        name="river"
                        rules="required"
                    >
                        <label class="mr-2 default-label" for="rivers">{{ $t('river') }}*:</label>
                        <select class="default-select" id="river" v-model="reservoirDetails.enumRiverSid"
                            :placeholder="$t('river')"
                            :class="{ 'invalid': failed }"
                        >
                            <option
                                v-for="river of rivers"
                                :key="river.sid"
                                :value="river.sid"
                            >
                                {{ river.description }}
                            </option>
                        </select>
                        <error-list :errors="errors" />
                    </validation-provider>
                    <validation-provider
                        tag="div"
                        class="manage-input"
                        #default="{ errors, failed }"
                        name="riverGroup"
                        rules="required"
                    >
                        <label class="mr-2 default-label" for="rivers">{{ $t('riverGroup') }}*:</label>
                        <select class="default-select" id="riverGroup" v-model="reservoirDetails.enumRiverGroupSid"
                            :placeholder="$t('riverGroup')"
                            :class="{ 'invalid': failed }"
                        >
                            <option
                                v-for="riverGroup of riverGroups"
                                :key="riverGroup.sid"
                                :value="riverGroup.sid"
                            >
                                {{ riverGroup.name }}
                            </option>
                        </select>
                        <error-list :errors="errors" />
                    </validation-provider>
                    <div class="manage-input">
                        <label class="mr-2 default-label" for="eqvFactor">{{ $t('eqvFactor') }}:</label>
                        <input class="mb-1 default-input" v-model.number="reservoirDetails.eqvFactor" :placeholder="$t('eqvFactor')" id="eqvFactor" />
                    </div>
                    <validation-provider
                        v-if="data.reservoirSid"
                        tag="div"
                        class="manage-input"
                        name="comments"
                        rules="max:1000"
                        #default="{ errors, failed }"
                    >
                        <div class="flex-row manage-input">
                            <label class="mr-2 default-label" for="lastUpdateComment">{{ $t('comments') }}:</label>
                            <textarea
                                class="default-textarea"
                                maxlength="1000"
                                v-model="reservoirDetails.lastUpdateComment"
                                :placeholder="$t('comments')"
                                id="lastUpdateComment"
                                :class="{ 'invalid': failed }"
                                rows="4"></textarea>
                        </div>
                        <error-list :errors="errors" />
                    </validation-provider>
                </div>
            </validation-observer>
        </div>
        <last-updated v-if="data.reservoirSid" :data="data" />
        <error-list :errors="errorList" />
        <div class="members-footer">
            <adam-button secondary
                icon="ic-remove"
                @click="handleRemoveClick()"
                class="mr-1">
                {{ $t('remove') }}
            </adam-button>
            <adam-button secondary
                icon="ic-add-dashboard"
                @click="addNewVersion()"
                class="mr-1">
                {{ $t('addNewVersion') }}
            </adam-button>
            <adam-button
                v-if="hasMasterDataAdmin"
                secondary
                class="mr-1"
                @click="save">
                {{ $t('save') }}
            </adam-button>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Mixins, Ref } from 'vue-property-decorator';
import { EventBus, extractErrorsFromResponse, InfiniteScrollingHelper, MIN_DATE } from '@/utils';
import { Asset, Header, IdTypeSid, Reservoir, ReservoirAddRequest, ReservoirQueryModel } from '@/models';
import { AssetService, ReservoirService } from '@/services';
import { ValidationObserver } from 'vee-validate';
import TableView from '@/components/view-details/table-view/table-view.vue';
import CheckboxInput from '@/components/checkbox-input/checkbox-input.vue';
import ErrorList from '@/components/error-list/error-list.vue';
import LastUpdated from '@/components/last-updated/last-updated.vue';
import ComponentSecurity from '@/mixins/component-security';
import reservoirHeader from './reservoir-header';
import { ReservoirFactory } from '@/utils/factories';

@Component({
    name: 'material-base-identification',
    components: {
        checkboxInput: CheckboxInput,
        errorList: ErrorList,
        lastUpdated: LastUpdated,
        tableView: TableView,
    },
})
export default class ReservoirIdentification extends Mixins(ComponentSecurity) {
    @Ref()
    private readonly validationObserver!: InstanceType<typeof ValidationObserver>;
    /*
     *  Reservoir for which the identification tab from editing screen is displayed.
    */
    @Prop({ required: true })
    private data!: Reservoir;

    @Prop({ default: false })
    private isNewReservoir!: boolean;

    private reservoirservice: ReservoirService = new ReservoirService();
    private assetService: AssetService = new AssetService();

    private formModel: Reservoir | ReservoirAddRequest | null = null;
    private reservoirDetails: any = {};
    private selectedResevoir: any = {};
    private reservoirList: ReservoirQueryModel[] = [];
    private rivers: Asset[] = [];
    private riverGroups: Asset[] = [];
    private reservoirHeader: Header = reservoirHeader;
    private errorList: string[] = [];
    private invalidMessages: string[] = [];
    private isNewVersion = false;
    private validFrom = '';
    private filterPage = {
        page: 1,
        size: 999,
        term: '',
    };
    private comments = '';

    public async beforeLeave(): Promise<boolean> {
        if (!this.validationObserver.flags.dirty) {
            return true;
        }

        try {
            await this.$dialog
                .confirm(
                    { body: this.$t('areYouSureLeaveUnsaved').toString() },
                    { view: 'confirm' });
            return true;
        } catch (error: any) {
            return Promise.resolve(false);
        }
    }

    public isFormDirty(): boolean {
        return this.validationObserver.flags.dirty;
    }

    private async mounted(): Promise<void> {
        EventBus.$on(EventBus.DETAIL.CLOSE, this.closeDetailsView);
        await this.getReservoirVersions();
        await this.loadRiverGroups();
        await this.loadRivers();
        this.onSelectReservoir(this.reservoirList[0]);
        this.validationObserver?.reset();
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.DETAIL.CLOSE);
    }

    private async loadRiverGroups(): Promise<void> {
        this.riverGroups = await this.getAssets(IdTypeSid.RIVER_GROUP);
    }

    private async loadRivers(): Promise<void> {
        this.rivers = await this.getAssets(IdTypeSid.RIVER);
    }

    private checkDisabledDate(date: Date): boolean {
        return date < MIN_DATE;
    }

    private async getAssets(idTypeSid: number): Promise<Asset[]> {
        try {
            const { result: { items } } = await this.assetService.getByIdTypeSid(idTypeSid, this.filterPage);

            return items;
        } catch (err: any) {
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
    }

    private checkInput(): void {
        this.errorList = [];
        this.invalidMessages = [];
    }

    private isInvalid(invalidKey: string): boolean {
        return this.errorList.indexOf(invalidKey) > -1;
    }

    private deleteInputFieldError(invalidKey: string): any {
        this.errorList = this.errorList.filter((error: string) => error !== invalidKey);
    }

    private async getReservoirVersions(selectVersionValidFrom = ''): Promise<void> {
        this.$store.commit('loading');
        try {
            this.reservoirList = (await this.reservoirservice.getReservoirVersions(this.data)).result.items;
            const version = this.reservoirList.find(({ validFrom }) => validFrom === selectVersionValidFrom);
            if (version) {
                this.selectedReservoir(version);
            } else if (this.reservoirList.length > 0) {
                this.selectedReservoir(this.reservoirList[0]);
            } else {
                this.selectedReservoir(null);
            }
        } catch (err: any) {
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingProcurementData');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async onSelectReservoir(status: ReservoirQueryModel): Promise<void> {
        if (this.isNewReservoir) {
            return;
        }
        if (this.isFormDirty()) {
            try {
                await this.$dialog
                    .confirm(
                        { body: this.$t('areYouSureSelectAnotherVersion').toString() },
                        { view: 'confirm' });
            } catch (error: any) {
                return;
            }
        }
        this.selectedReservoir(status);
    }

    private async selectedReservoir(status: ReservoirQueryModel | null): Promise<void> {
        this.$store.commit('loading');
        try {
            if (status) {
                this.validFrom = status.validFrom;
                const reservoir = ( await this.reservoirservice.getById(status)).result;
                this.selectedResevoir = status
                ? {...reservoir}
                : null;
                this.reservoirDetails = status
                    ? { ...reservoir }
                    : null;
            }
            this.errorList = [];
            this.validationObserver?.reset();
        } catch (err: any) {
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingReservoirs');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async addNewVersion(): Promise<void> {
        this.reservoirDetails = ReservoirFactory.createReservoirVersion(this.data.reservoirSid);
    }

    private async closeDetailsView(): Promise<void> {
        try {
            const canLeave = await this.beforeLeave();
            /**
             * Fired when the user wishes to exit the details view
             */
            if (canLeave) {
                this.$emit('close');
            }
        } catch (error: any) {
            // action canceled by user
        }
    }

    private async handleRemoveClick() {
        this.errorList = [];

        if (!this.selectedResevoir) {
            return;
        }

        try {
            await this.$dialog
                .confirm(
                    { body: this.$t('areYouSureDeleteItem').toString() },
                    { view: 'confirm' });
        } catch (error: any) {
            return;
        }

        try {
            await this.reservoirservice.delete(this.selectedResevoir);
            await this.getReservoirVersions();
        } catch (error: any) {
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorRemovingReservoir');
            this.errorList = extractErrorsFromResponse(error.response.data);
            console.error(error);
            throw error;
        }
    }

    private async save(): Promise<void> {
        this.invalidMessages = [];
        const isValid = await this.validationObserver.validate();

        if (!isValid) {
            await this.$nextTick();
            InfiniteScrollingHelper.scrollToFirstInvalidElement('.invalid');
            return;
        }
        let message = 'errorAddingReservoir';
        this.$store.commit('loading');
        try {
            if (this.reservoirDetails.sid > 0) {
                message = 'errorUpdatingReservoir';
                const { result } = await this.reservoirservice.edit({
                    reservoirSid: this.reservoirDetails.sid,
                    reservoirName: this.reservoirDetails.reservoirName,
                    enumRiverSid: this.reservoirDetails.enumRiverSid,
                    enumRiverGroupSid: this.reservoirDetails.enumRiverGroupSid,
                    eqvFactor: this.reservoirDetails.eqvFactor,
                    lastUpdateComment: this.reservoirDetails.lastUpdateComment,
                    validFrom: this.reservoirDetails.validFrom,
                }, this.validFrom);
                this.formModel = { ...result };
                this.$emit('save', this.formModel, false, null);
            } else {
                const addedAsset = (await this.reservoirservice.create(this.reservoirDetails)).result;
                this.$emit('save', addedAsset, true, null);
            }
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            this.validationObserver?.reset();
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, message);
            if (!err.response || !err.response.data) {
                throw err;
            }
            this.invalidMessages = extractErrorsFromResponse(err.response.data);
            if (this.invalidMessages.length) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.error-list__message');
                });
            }
        } finally {
            this.$store.commit('loading');
        }
        this.data.lastUpdateComment = '';
    }
}

</script>

<style scoped lang="less">
@import "~@/variables.less";
.reservoirs-identification {
    .formModel {
        display: grid;
        grid-template-columns: 40% 40%;
        grid-gap: 4rem;
        margin-top: 4rem;
    }
    label {
        margin-top: 1rem;
        min-width: fit-content;
    }
    input, textarea {
        width: 100%;
    }
}
</style>
