<template>
    <div class="flex-container main-container flex-wrap master-data-management">
        <div class="flex-row full-width full-height">
            <div v-if="hasMasterDataAdmin" class="flex-col flex-1 list-items">
                <div class="list-scrollbox">
                    <div class="item"
                         :class="{'selected': option.name === selectedOption.name, 'border-bottom': optionIndex === 0}"
                         v-for="(option, optionIndex) of options"
                         :key="optionIndex"
                         @click="selectOption(option)">
                        {{ $t(option.name) }}
                    </div>
                </div>
            </div>
            <div class="flex-col flex-4 view-data"
                 v-if="selectedOption">
                <component
                    :is="selectedOption.componentName"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import {Component, Mixins, Watch} from 'vue-property-decorator';
import {ManagementOption} from '@/models';
import EnumManagement from '@/components/master-data-management/enum-management/enum-management.vue';
import OperationModesManagement from '@/components/master-data-management/operation-modes-management/operation-modes-management.vue';
import BusinessPartnersManagement from '@/components/master-data-management/business-partners-management/business-partners-management.vue';
import EtsUnitsManagement from '@/components/master-data-management/ets-units-management/ets-units-management.vue';
import MaterialBaseManagement from '@/components/master-data-management/material-base-management/material-base-management.vue';
import MaterialGroupManagement from '@/components/master-data-management/material-group-management/material-group-management.vue';
import ReservoirsManagement from '@/components/master-data-management/reservoirs-management/reservoirs-management.vue';
import ComponentSecurity from '@/mixins/component-security';
import {MANAGEMENT_OPTIONS_OLD} from '@/utils/management-options';

@Component({
    name: 'master-data-management',
    components: {
        enumManagement: EnumManagement,
        operationModesManagement: OperationModesManagement,
        businessPartnersManagement: BusinessPartnersManagement,
        etsUnitsManagement: EtsUnitsManagement,
        MaterialBaseManagement,
        MaterialGroupManagement,
        ReservoirsManagement,
    },
})

export default class MasterDataManagement extends Mixins(ComponentSecurity) {

    private options: ManagementOption[] = MANAGEMENT_OPTIONS_OLD;
    private selectedOption: ManagementOption = {
        name: '',
        idTypeSid: null,
        componentName: '',
        icon: '',
    };

    @Watch('$route.path')
    private onPathChange(newVal: string, oldVal: string): void {
        if (newVal && newVal !== oldVal) {
            this.init();
        }
    }

    private mounted(): void {
        this.init();
    }

    private init(): void {
        if (!this.options.length) {
            return;
        }

        if (!this.$route.params.option) {
            this.selectOption(this.options[0]);
            return;
        }

        const option = this.options.find((o: ManagementOption) => o.componentName ===
            this.$route.params.option);
        if (!option) {
            this.selectOption(this.options[0]);
            return;
        }

        this.selectOption(option);
    }

    private selectOption(option: ManagementOption): void {
        this.selectedOption = option;

        if (this.$route.path === `/master-data-management/${this.selectedOption.componentName}`) {
            return;
        }

        this.$router.push({
            name: 'manage-option',
            params: {
                option: this.selectedOption.componentName,
            },
        });
    }
}

</script>

<style lang="less">
@import "~@/variables.less";

.master-data-management {
    height: 100%;
    overflow: hidden;


    .list-items {
        overflow: hidden;
        overflow-y: auto;
        background-color: @white;

        .list-scrollbox {
            padding-bottom: 1.5rem;
        }

        .item {
            cursor: pointer;
            padding: 1.5rem;
            border-bottom: 1px solid @grey-lighter;

            &.selected {
                color: @uniper-blue;
            }

            &:hover {
                background-color: @uniper-blue;
                color: @white;
            }
        }

        .border-bottom {
            border-bottom: 1px solid @grey-lighter;
        }
    }

    .view-data {
        border-left: 2px solid @grey-lighter;
        background-color: @background-grey;
        height: calc(100vh - @main-nav-height);
    }
}
</style>
