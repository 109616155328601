<template>
    <pui-grid-container class="pui-grid-container-fix-margin">
        <pui-grid-row>
            <master-data-management-header
                :title="$t('manageOperationModes')"
                :data-loaded="dataLoaded"
            />
        </pui-grid-row>
        <pui-grid-row v-if="dataLoaded">
            <custom-table
                :columns-key="['operationModeName', 'operationModeSid', 'isStdOperationMode', 'isActive', 'action']"
                :data="data"
                :sortable-key="['operationModeName', 'operationModeSid', 'isStdOperationMode', 'isActive']"
                :heading="heading"
                :per-page="10"
                :per-page-values="[10, 50, 100]"
                enable-search
                enable-pagination
                enable-limit
                :custom-template="['isStdOperationMode', 'isActive', 'action']"
            >
                <template v-slot:custom_isStdOperationMode="{data}">
                    <span v-if="data">{{ $t('standardOperationMode') }}</span>
                    <span v-else></span>
                </template>
                <template v-slot:custom_isActive="{data}">
                    <boolean-text
                        :titles="{
                            textTrue: $t('active'),
                            textFalse: $t('inactive'),
                        }"
                        :value="data"
                    />
                </template>
                <template v-slot:custom_action="{data}">
                    <pui-link
                        :title="$t('edit')"
                        icon="edit"
                        @click="onClickEdit(data)"
                    />
                </template>
            </custom-table>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {OperationModesService} from '@/services';
import MasterDataManagementHeader from '@/components/headers/master-data-management-header.vue';
import CustomTable from '@/components/table/custom-table.vue';
import {OperationModeListDto, OperationModesQueryModel} from '@/models';
import {EventBus} from '@/utils';
import BooleanText from '@/components/text/boolean-text.vue';

@Component({
    name: 'management-operation-modes',
    components: {BooleanText, YesOrNotText: BooleanText, CustomTable, MasterDataManagementHeader},
})
export default class ManagementOperationModes extends Vue {

    /* VARIABLES */
    private operationModesService: OperationModesService = new OperationModesService();
    private dataLoaded = false;
    private data: OperationModeListDto[] = [];
    private filter: OperationModesQueryModel = {
        page: 1,
        size: 100000,
        term: '',
        sortDirection: '',
        sortColumn: '',
    };

    get heading(): any {
        return {
            operationModeName: this.$t('operationModeName'),
            operationModeSid: this.$t('sid'),
            isStdOperationMode: this.$t('operationMode'),
            isActive: this.$t('status'),
            action: '',
        };
    }

    /* PRIMITIVE METHODS */
    private mounted(): void {
        this.dataLoaded = false;
        this.init();
    }

    /* METHODS */
    private async init(): Promise<void> {
        this.$store.commit('loading');
        this.data = await this.loadOperationModes();
        this.dataLoaded = true;
        this.$store.commit('loading');
    }

    /* ON CLICK BUTTON */
    private onClickEdit(value: OperationModeListDto): void {
        this.$router.push({
            name: 'management-operation-modes-edit',
            query: {
                edit: `${value.operationModeSid}`,
                name: `${value.operationModeName}`,
                tab: '0',
            },
        });
    }

    /* API CALLS */
    private async loadOperationModes(): Promise<OperationModeListDto[]> {
        let modes: OperationModeListDto[] = [];
        try {
            const {result: {items}} = await this.operationModesService.get(this.filter);
            modes = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
        return modes;
    }

}
</script>
