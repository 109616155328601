var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reservoirs-identification"},[(!_vm.isNewReservoir)?[(_vm.reservoirList.length)?_c('table-view',{attrs:{"header":_vm.reservoirHeader,"items":_vm.reservoirList,"is-selected-cb":function (candidate) { return _vm.selectedResevoir && candidate.validFrom === _vm.selectedResevoir.validFrom; }},on:{"select-item":_vm.onSelectReservoir}}):_vm._e()]:_vm._e(),(_vm.reservoirDetails)?_c('div',[_c('validation-observer',{ref:"validationObserver"},[_c('div',{staticClass:"formModel"},[_c('validation-provider',{attrs:{"name":"validFrom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"manage-input"},[_c('label',{staticClass:"mr-2 default-label"},[_vm._v(_vm._s(_vm.$t('validFrom'))+"*:")]),_c('md-date-picker',{class:{ 'invalid': failed },attrs:{"disabled-date":_vm.checkDisabledDate},model:{value:(_vm.reservoirDetails.validFrom),callback:function ($$v) {_vm.$set(_vm.reservoirDetails, "validFrom", $$v)},expression:"reservoirDetails.validFrom"}}),_c('error-list',{attrs:{"errors":errors}})],1)]}}],null,false,1658365905)}),(_vm.reservoirDetails.validTo)?_c('div',{staticClass:"flex-row manage-input"},[_c('label',{staticClass:"mr-2 default-label"},[_vm._v(_vm._s(_vm.$t('validUntil'))+":")]),_c('md-date-picker',{attrs:{"disabled-date":_vm.checkDisabledDate,"disabled":""},model:{value:(_vm.reservoirDetails.validTo),callback:function ($$v) {_vm.$set(_vm.reservoirDetails, "validTo", $$v)},expression:"reservoirDetails.validTo"}})],1):_vm._e(),(_vm.data.reservoirSid)?_c('div',{staticClass:"flex-row manage-input"},[_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"reservoirSid"}},[_vm._v(_vm._s(_vm.$t('reservoirSid'))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.reservoirSid),expression:"data.reservoirSid"}],staticClass:"mb-1 default-input",attrs:{"placeholder":_vm.$t('reservoirSid'),"id":"reservoirSid","disabled":""},domProps:{"value":(_vm.data.reservoirSid)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "reservoirSid", $event.target.value)}}})]):_vm._e(),_c('validation-provider',{attrs:{"name":"validFrom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"flex-row manage-input"},[_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"reservoirName"}},[_vm._v(_vm._s(_vm.$t('reservoirName'))+"*:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.reservoirDetails.reservoirName),expression:"reservoirDetails.reservoirName"}],class:{ 'invalid': failed },attrs:{"placeholder":_vm.$t('reservoirName'),"id":"reservoirName"},domProps:{"value":(_vm.reservoirDetails.reservoirName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.reservoirDetails, "reservoirName", $event.target.value)}}})]),_c('error-list',{attrs:{"errors":errors}})]}}],null,false,3076845270)}),_c('validation-provider',{staticClass:"manage-input",attrs:{"tag":"div","name":"river","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"rivers"}},[_vm._v(_vm._s(_vm.$t('river'))+"*:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.reservoirDetails.enumRiverSid),expression:"reservoirDetails.enumRiverSid"}],staticClass:"default-select",class:{ 'invalid': failed },attrs:{"id":"river","placeholder":_vm.$t('river')},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.reservoirDetails, "enumRiverSid", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.rivers),function(river){return _c('option',{key:river.sid,domProps:{"value":river.sid}},[_vm._v(" "+_vm._s(river.description)+" ")])}),0),_c('error-list',{attrs:{"errors":errors}})]}}],null,false,2895377994)}),_c('validation-provider',{staticClass:"manage-input",attrs:{"tag":"div","name":"riverGroup","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"rivers"}},[_vm._v(_vm._s(_vm.$t('riverGroup'))+"*:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.reservoirDetails.enumRiverGroupSid),expression:"reservoirDetails.enumRiverGroupSid"}],staticClass:"default-select",class:{ 'invalid': failed },attrs:{"id":"riverGroup","placeholder":_vm.$t('riverGroup')},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.reservoirDetails, "enumRiverGroupSid", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.riverGroups),function(riverGroup){return _c('option',{key:riverGroup.sid,domProps:{"value":riverGroup.sid}},[_vm._v(" "+_vm._s(riverGroup.name)+" ")])}),0),_c('error-list',{attrs:{"errors":errors}})]}}],null,false,1098391348)}),_c('div',{staticClass:"manage-input"},[_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"eqvFactor"}},[_vm._v(_vm._s(_vm.$t('eqvFactor'))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.reservoirDetails.eqvFactor),expression:"reservoirDetails.eqvFactor",modifiers:{"number":true}}],staticClass:"mb-1 default-input",attrs:{"placeholder":_vm.$t('eqvFactor'),"id":"eqvFactor"},domProps:{"value":(_vm.reservoirDetails.eqvFactor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.reservoirDetails, "eqvFactor", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]),(_vm.data.reservoirSid)?_c('validation-provider',{staticClass:"manage-input",attrs:{"tag":"div","name":"comments","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"flex-row manage-input"},[_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"lastUpdateComment"}},[_vm._v(_vm._s(_vm.$t('comments'))+":")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.reservoirDetails.lastUpdateComment),expression:"reservoirDetails.lastUpdateComment"}],staticClass:"default-textarea",class:{ 'invalid': failed },attrs:{"maxlength":"1000","placeholder":_vm.$t('comments'),"id":"lastUpdateComment","rows":"4"},domProps:{"value":(_vm.reservoirDetails.lastUpdateComment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.reservoirDetails, "lastUpdateComment", $event.target.value)}}})]),_c('error-list',{attrs:{"errors":errors}})]}}],null,false,207367944)}):_vm._e()],1)])],1):_vm._e(),(_vm.data.reservoirSid)?_c('last-updated',{attrs:{"data":_vm.data}}):_vm._e(),_c('error-list',{attrs:{"errors":_vm.errorList}}),_c('div',{staticClass:"members-footer"},[_c('adam-button',{staticClass:"mr-1",attrs:{"secondary":"","icon":"ic-remove"},on:{"click":function($event){return _vm.handleRemoveClick()}}},[_vm._v(" "+_vm._s(_vm.$t('remove'))+" ")]),_c('adam-button',{staticClass:"mr-1",attrs:{"secondary":"","icon":"ic-add-dashboard"},on:{"click":function($event){return _vm.addNewVersion()}}},[_vm._v(" "+_vm._s(_vm.$t('addNewVersion'))+" ")]),(_vm.hasMasterDataAdmin)?_c('adam-button',{staticClass:"mr-1",attrs:{"secondary":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }