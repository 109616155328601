import {BusinessPartnersDetails} from '@/models';
import {IdentificationBusinessPartnersForm} from '@/models/form/identification-business-partners-form';
import {format} from 'date-fns';
import {ONLY_DATE_FORMAT} from '@/utils/constants';

// tslint:disable-next-line:max-line-length
export const identificationBusinessPartnersForm = (data?: BusinessPartnersDetails): IdentificationBusinessPartnersForm => {
    return {
        abbreviation: {
            value: data?.abbreviation,
            isValid: true,
        },
        locationShort: {
            value: data?.locationShort,
            isValid: true,
            validator: (val: string) => val.length > 0,
        },
        locationName: {
            value: data?.locationName,
            isValid: true,
            validator: (val: string) => val.length > 0,
        },
        enumLocationTypeSid: {
            value: data?.enumLocationTypeSid ?? -1,
            isValid: true,
            isDropDown: true,
            validator: (val: number) => val.toString() !== '-1',
        },
        enumCountrySid: {
            value: data?.enumCountrySid ?? -1,
            isValid: true,
            isDropDown: true,
            validator: (val: number) => val.toString() !== '-1',
        },
        enumStateSid: {
            value: data?.enumStateSid ?? -1,
            isDropDown: true,
            isValid: true,
        },
        zipCode: {
            value: data?.zipCode,
            isValid: true,
        },
        city: {
            value: data?.city,
            isValid: true,
        },
        street: {
            value: data?.street,
            isValid: true,
        },
        houseNumber: {
            value: data?.houseNumber,
            isValid: true,
        },
        telephone: {
            value: data?.telephone,
            isValid: true,
        },
        lastUpdateComment: {
            value: data?.lastUpdateComment,
            isValid: true,
        },
        deleted: {
            value: data?.deleted ?? false,
            isValid: true,
        },
        enumOrganisationSid: {
            value: data?.enumOrganisationSid,
            isValid: true,
        },
        fax: {
            value: data?.fax,
            isValid: true,
        },
        enumRegionSid: {
            value: data?.enumRegionSid,
            isValid: true,
        },
        enumTimezoneSid: {
            value: data?.enumTimezoneSid,
            isValid: true,
        },
        lastUpdated: {
            value: data?.lastUpdated ? format(new Date(data?.lastUpdated), ONLY_DATE_FORMAT) : '',
            isValid: true,
            isDate: true,
        },
        lastUpdatedBy: {
            value: data?.lastUpdatedBy,
            isValid: true,
        },
        longitude: {
            value: data?.longitude,
            isValid: true,
        },
        latitude: {
            value: data?.latitude,
            isValid: true,
        },
        locationSid: {
            value: data?.locationSid,
            isValid: true,
        },
        mailAddress: {
            value: data?.mailAddress,
            isValid: true,
        },
        name: {
            value: data?.name,
            isValid: true,
        },
        postbox: {
            value: data?.postbox,
            isValid: true,
        },
    };
};
