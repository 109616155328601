import { ApiResponse, PagedResponse, MaterialGroupModel, MaterialGroupEditRequest } from '@/models';
import { MaterialGroupQueryModel } from '@/models/filter';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
import { generateQuery } from '@/utils/api-helper';

export class MaterialGroupService {
    private api = new SdkGenericApiHelper('materials-group');

    public async get(filter: MaterialGroupQueryModel): Promise<ApiResponse<PagedResponse<MaterialGroupModel>>> {
        const query = generateQuery(filter as any);
        return (await this.api.get(query)).data;
    }

    public async create(materialGroup: MaterialGroupModel): Promise<ApiResponse<MaterialGroupModel>> {
        return (await this.api.post(materialGroup)).data;
    }

    public async getById(sid: number): Promise<ApiResponse<MaterialGroupModel>> {
        return (await this.api.getByPathOrParam(sid)).data;
    }

    public async edit(materialGroup: MaterialGroupEditRequest): Promise<ApiResponse<MaterialGroupModel>> {
        return (await this.api.put( materialGroup.materialGroupSid, materialGroup)).data;
    }

    public async delete(materialSid: number): Promise<ApiResponse<any>> {
        return (await this.api.delete(materialSid)).data;
    }
}
