<template>
    <div>
        <pui-table
            v-if="assets.length"
            class="assets-table"
            :vendor-options="assetTableDetails"
            :with-pagination="true"
            :with-limit="true"
            :with-search="true"
        >
            <template
                v-for="columnHeader in this.columnHeaders"
                :slot="`h__${columnHeader}`"
            >
                {{ $t(`assetOverviewHeaders.${columnHeader}`) }}
            </template>

            <template slot="comment" slot-scope="{ row }">
                <pui-tooltip />
                <div v-pui-tooltip="{ message: row.comment }">
                    {{ formatComment(row.comment) }}
                </div>
            </template>
            <template slot="aprEndDateOfUnit" slot-scope="{ row }">
                {{ formatDate(row.aprEndDateOfUnit) }}
            </template>
            <template slot="aprEndDateOfUnitStatus" slot-scope="{ row }">
                {{ formatDate(row.aprEndDateOfUnitStatus) }}
            </template>
            <template slot="updateDate" slot-scope="{ row }">
                {{ formatDate(row.updateDate) }}
            </template>
        </pui-table>
        <div
            v-else
            class="message-container"
        >
            <pui-loader-error
                :title="errorMessage"
                icon="error-empty-data"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { TableDetails, AssetOverviewListItem } from '@/models';
import { DateHelper } from '@/utils';

@Component({
    name: 'asset-overview-table',
})

export default class AssetOverviewTable extends Vue {
    @Prop({ required: true })
    private assets!: AssetOverviewListItem[];

    @Prop({ required: true })
    private isLoading!: boolean;

    private columnHeaders: string[] = [
        'plantName', 'plantId', 'unitName', 'machineId', 'unitNameModule', 'river', 'country',
        'regionalUnit', 'technology', 'legalEntity', 'shareHolder', 'shares', 'operatingCompany', 'grossCapacity', 'netCapacity',
        'thermalCapacity', 'legallyAttributableCapacity', 'accountingView', 'inclusionMethod', 'unitType', 'unitTechnology',
        'detailTechnology', 'primaryEnergy', 'secondaryEnergy', 'mainFuelProcurement', 'co2Procurement', 'marketingOfEnergy',
        'capacityContracts', 'capacityContractDetails', 'commissioningDate', 'status', 'reasonOfUnitStatusChange', 'comment',
        'aprEndDateOfUnit', 'aprEndDateOfUnitStatus', 'updateDate',
    ];

    private get assetTableDetails(): TableDetails {
        return {
            columns: this.columnHeaders,
            data: this.assets,
            options: {
                sortable: this.columnHeaders,
                texts: {
                    limit: this.$i18n.t('resultsPerPage').toString(),
                    filter: this.$i18n.t('searchResults').toString(),
                    filterPlaceholder: this.$i18n.t('search').toString(),
                    noResults: this.$i18n.t('noMatchingRecords').toString(),
                    page: this.$i18n.t('page').toString(),
                },
                perPage: 10,
                perPageValues: [
                    10,
                    20,
                    50,
                ],
                pagination: {
                    edge: false,
                },
            },
        };
    }

    private formatComment(comment: string): string {
        return comment.length > 50
            ? `${comment.substring(0, 50)}...`
            : comment;
    }

    private formatDate(date: string | Date): string {
        return !date
            ? ''
            : DateHelper.formatDateTime(date);
    }

    private get errorMessage(): string {
        return this.isLoading
            ? ''
            : `${this.$t('noDataToShow')}`;
    }

}
</script>

<style lang="less" scoped>
.message-container {
    height: 60rem;
    width: 100%;
}

/deep/ .pui-table .VueTables__table thead th {
    min-width: 25rem;
    font-weight: 600;
    color: black;
}

</style>
