<template>
    <validation-provider
        :rules="rules"
        :mode="debounceMode"
    >
        <div slot-scope="{ errors }">
            <pui-form-group
                :label="name"
                :is-valid="!errors.length"
                :show-required-label="isRequired"
            >
                <pui-form-textarea
                    :is-valid="!markAsError(errors.length)"
                    v-model="value"
                    :rows="rows"
                    :is-disabled="disabled"
                    :grow="grow"
                    :placeholder="disabled ? '' : placeholder"
                    :max-length="maxLength"
                />
                <template #error-message>
                    {{ errors[0] }}
                </template>
                <template #helper-text>
                    <span :style="characterCountStyle">
                        {{ characterCountLabel }}
                    </span>
                </template>
            </pui-form-group>
        </div>
    </validation-provider>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import ValidationSetup from './validation-setup';

@Component({
    name: 'textarea-input',
    components: {
        ValidationProvider,
    },
})

export default class TextareaInput extends ValidationSetup {
    @Prop({ required: false })
    private readonly placeholder!: string;

    @Prop({ required: false, default: '' })
    private inputValue!: any;

    @Prop({ required: false, default: '' })
    private name!: string;

    @Prop({ required: false, default: 3 })
    private rows!: number;

    @Prop({ required: false, default: false })
    private grow!: boolean;

    @Prop({ required: false, default: false })
    private disabled!: boolean;

    get value(): string {
        return this.inputValue;
    }

    set value(changedValue: string) {
        changedValue === ''
            ? this.$emit('change', undefined)
            : this.$emit('change', changedValue);
    }

    private get maxLength(): number | undefined {
        return this.rules?.max;
    }

    private get characterCountLabel(): string {
        if (!this.maxLength) { return ''; }

        const valueLength = this.value?.length ?? 0;
        return `${valueLength}/${this.maxLength}`;
    }

    private get characterCountStyle(): any {
        return {
            float: 'right',
            color: this.value?.length === this.maxLength ? 'red' : '',
        };
    }
}
</script>

