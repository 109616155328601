<template>
    <pui-lightbox
        ref="confirmation"
        :show-lightbox-close-icon="false"
        :default-header-label="$t('pleaseConfirm')"
        :default-footer-confirm-label="$t('yes')"
        :default-footer-cancel-label="$t('no')"
        :fit-content="true"
        :on-confirm-callback="confirm"
        @puilightbox:close="close"
    >
        <template #default>
            {{ confirmationMessage }}
        </template>
    </pui-lightbox>
</template>

<script lang="ts">
import { Component, Vue, Emit } from 'vue-property-decorator';

@Component({
    name: 'confirmation-dialog',
})
export default class ConfirmationDialog extends Vue {
    private confirmationMessage = '';
    private data!: any;

    public open(message = '', data?: any): void {
        this.confirmationMessage = message;
        this.data = data;
        (this.$refs.confirmation as any)?.open();
    }

    private close(): void {
        (this.$refs.confirmation as any)?.close();
        this.confirmationMessage = '';
    }

    @Emit()
    private confirm(): void {
        this.close();
        return this.data;
    }
}
</script>
