<template>
    <pui-form-group
        :label="title"
        :is-valid="isValid"
        :showRequiredLabel="required"
    >
        <pui-form-checkbox
            :label="label"
            :is-readonly="disabled"
            :is-valid="isValid"
            :checked="value"
            @change="onCheck"
        />
        <span slot="error-message">
            {{ requiredError }}
        </span>
    </pui-form-group>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'check-box',
})
export default class CheckBox extends Vue {
    private isValid = true;

    @Prop({required: false, default: false})
    private required!: boolean;

    @Prop({required: false})
    private requiredError: string = this.$t('fieldRequired').toString();

    @Prop({required: false})
    private value?: boolean;

    @Prop({required: true})
    private label!: string;

    @Prop({required: false})
    private title = '';

    @Prop({required: false, default: false})
    private disabled!: boolean;

    private onCheck(val: boolean): void {
        if (this.required) {
            this.isValid = val;
        }
        this.$emit('input', val);
    }

}
</script>


