<template>
    <div class="md-filter-tags">
        <span v-for="option in displayTagList" :key="option.value" class="md-filter-tags__item">
            <div class="md-filter-tags__item-name">
                {{ option.name }}
            </div>
            <div class="md-filter-tags__item-close" @click="onRemoveItem(option.key, option.value)">&times;</div>
        </span>
        <span
            v-if="optionCount > INITIAL_DISPLAY_TAG_NUMBER"
            class="md-filter-tags__overflow"
            @click="onOverflowClick">
            <div class="md-filter-tags__item-name" v-if="!showAllTags">
                {{ $t('and') }} {{ optionCount - displayTagList.length }}  {{ $t('more') }}
            </div>
            <div class="md-filter-tags__item-name" v-else>
                {{ $t('hide') }}
            </div>
        </span>
    </div>
</template>

<script lang="ts">
import { FilterOption, MdFilterModel } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * Display the active filters as a flat list of tags
 */
@Component({
    name: 'md-filter-tags',
})
export default class MdFilterTags extends Vue {
    /**
     * The filter selection to be used for tags. Used as model for two way
     * data binding.
     */
    @Prop({ required: true })
    private value!: MdFilterModel;

    private showAllTags = false;
    private INITIAL_DISPLAY_TAG_NUMBER = 5;


    get displayTagList(): any[] {
        const list: any = [];

        for (const key of Object.keys(this.value)) {
            const filterOption = (this.value as any)[key];

            if (typeof filterOption === 'boolean' && filterOption) {
                list.push({
                    key,
                    name: this.$t('showDeletedItems'),
                    filterOption,
                });
            }

            if (Array.isArray(filterOption)) {
                for (const filterOptionValue of filterOption) {
                    list.push({
                        key,
                        ...filterOptionValue,
                    });

                    if (!this.showAllTags && list.length >= this.INITIAL_DISPLAY_TAG_NUMBER) {
                        return list;
                    }
                }
            }
        }

        return list;
    }

    get optionCount(): number {
        return Object.values(this.value).reduce(
            (accumulator: number, filterOption: FilterOption[] | boolean) => (
                typeof filterOption === 'boolean'
                    ? accumulator + (filterOption ? 1 : 0)
                    : Array.isArray(filterOption)
                        ? accumulator + filterOption.length
                        : accumulator
            ), 0);
    }

    private onRemoveItem(key: string, value: number) {
        const model = { ...this.value };

        if (Array.isArray((model as any)[key])) {
            (model as any)[key] = (model as any)[key].filter((option: FilterOption) => option.value !== value);
        } else {
            (model as any)[key] = false;
        }
        /**
         * Fired when the user clicks to remove a tag from the list.
         *
         * @argument { MdFilterModel } model - the new value of the filter
         * selection after this value is removed
         */
        this.$emit('input', model);
    }

    private onOverflowClick() {
        this.showAllTags = !this.showAllTags;
    }
}
</script>

<style lang="less" scoped>
@import "~@/variables.less";

.md-filter-tags {
    padding: 1.8rem 1.5rem 1.2rem 1.5rem;

    &__item,
    &__overflow {
        display: inline-flex;
        align-items: center;
        padding: 0.5rem 1rem;
        border-radius: @border-radius-standard;
        margin: 0.5rem;
    }

    &__item {
        border: 1px solid @grey-lighter;

        &:hover {
          background-color: @background-grey;
        }
    }

    &__overflow {
        border: 1px solid @uniper-blue;
        color: @uniper-blue;

        &:hover {
            cursor: pointer;
        }
    }

    &__item-close {
        margin-left: 1rem;

        &:hover {
            cursor: pointer;
        }
    }
}
</style>
