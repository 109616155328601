<template>
    <div></div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    name: 'side-bar-default',
})
export default class SideBarDefault extends Vue {
}
</script>


