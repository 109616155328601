<template>
    <pui-grid-container class="pui-grid-container-zero-padding">
        <pui-grid-row>
            <pui-grid-column class="pui-grid-column-zero-padding">
                <pebble-text-field
                    name="name"
                    :label="$t('name')"
                    :model="data.name"
                    :required="true"
                    :max-length="50"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column class="pui-grid-column-zero-padding">
                <pebble-text-field-area
                    name="description"
                    :label="$t('description')"
                    :model="data.description"
                    :max-length="50"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column class="pui-grid-column-zero-padding">
                <pebble-check-box
                    name="isDeleted"
                    :label="$t('markAsDeleted')"
                    :model="data.isDeleted"
                    @on-change="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import {PebbleColConst} from '@/mixins/pebble-col-const';
import {PebbleValidate} from '@/mixins/pebble-validate';
import ComponentSecurity from '@/mixins/component-security';
import PebbleTextField from '@/components/pebble-form-element/pebble-text-field.vue';
import PebbleCheckBox from '@/components/pebble-form-element/pebble-check-box.vue';
import PebbleTextFieldArea from '@/components/pebble-form-element/pebble-text-field-area.vue';
import {Prop} from 'vue-property-decorator';
import {IdentificationEnumTypesForm} from '../../models/form/identification-enum-types-form';

@Component({
    name: 'enum-types-identification-form',
    components: {PebbleTextFieldArea, PebbleCheckBox, PebbleTextField},
})
export default class EnumTypesIdentificationForm extends mixins(PebbleColConst, PebbleValidate, ComponentSecurity) {
    @Prop({required: true})
    private data!: IdentificationEnumTypesForm;
}
</script>

<style scoped>

</style>
