<template>
    <md-card
        :icon="card.iconSrc"
        :name="card.name"
        :sid="card.sid">
        <template v-slot:card-properties>
            <div class="flex-col flex-1 mr-2 center-vertically">
                <checkbox-input
                    :value="card.assigned"
                    :label="$t('assignTo') + ' ' + selectedStreamSystemName" />
            </div>
        </template>
    </md-card>
</template>

<script lang="ts">

import { Component, Vue, Prop } from 'vue-property-decorator';
import { StreamMappingAsset } from '@/models';
import MdCard from '@/components/cards/cards-list/md-card/md-card.vue';
import CheckboxInput from '@/components/checkbox-input/checkbox-input.vue';

@Component({
    name: 'stream-mapping-card-list',
    components: {
        mdCard: MdCard,
        checkboxInput: CheckboxInput,
    },
})
export default class StreamMappingCardList extends Vue {
    /*
     * The stream mapping asset for which we display the info.
    */
    @Prop()
    private card!: StreamMappingAsset;

    /*
     * The selected stream system for which we display the name.
    */
    @Prop()
    private selectedStreamSystemName!: string;
}

</script>
