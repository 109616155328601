import { ApiResponse, PagedResponse, NetConnectorsModel, NetConnectorsAddRequest,
    NetConnectorsEditRequest, NetConnectorVersion,
} from '@/models';
import { store } from '@/store';
import { EventBus } from '@/utils';
import ErrorHandler from '@/utils/error-handler';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
class NetConnectorService {
    private api = new SdkGenericApiHelper('units');

    public async get(unitSid: any): Promise<ApiResponse<PagedResponse<NetConnectorsModel>>> {
        return (await this.api.getByPathOrParam(`${unitSid}/grid-connectors`)).data;
    }

    public async create(netConnector: NetConnectorsAddRequest): Promise<ApiResponse<NetConnectorsModel>> {
        return (await this.api.post(netConnector, `${netConnector.machineSid}/grid-connectors`)).data;
    }

    public async getById(netConnector: any ): Promise<ApiResponse<any>> {
        return (await this.api.getByPathOrParam(`${netConnector.machineSid}/grid-connectors/versions/${netConnector.validFrom}`)).data;
    }

    public async edit(netConnector: NetConnectorsEditRequest, validFrom: string):
    Promise<ApiResponse<NetConnectorsModel>> {
        return (await this.api.put(`${netConnector.machineSid}/grid-connectors/versions/${validFrom}`, netConnector)).data;
    }

    public async delete(netConnector: NetConnectorsModel): Promise<ApiResponse<any>> {
        return (await this.api.
            delete(
                `${netConnector.machineSid}/grid-connectors/versions/${netConnector.validFrom}?valid-from=${netConnector.validFrom}`)).data;
    }
    public async getNetConnectors(unitSid: number): Promise<NetConnectorVersion[]> {
        store.commit('loading');
        try {
            return (await this.get(unitSid))
                ?.result
                .items
                ?.map((item) => new NetConnectorVersion(item)) ?? [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingNetConnectors');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async getNetConnector(machineId?: number, validFrom?: string): Promise<NetConnectorVersion> {
        try {
            store.commit('loading');
            const result = (await this.api.getByPathOrParam(`${machineId}/grid-connectors/versions/${validFrom}`))
                ?.data
                ?.result;
            return new NetConnectorVersion(result);
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingNetConnector');
            return new NetConnectorVersion();
        } finally {
            store.commit('loading');
        }
    }

    public async addNetConnector(netConnector: NetConnectorVersion): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.api.post(NetConnectorVersion.mapToAddEditRequest(netConnector),
                `${netConnector.machineSid}/grid-connectors`);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async updateNetConnector(netConnector: NetConnectorVersion, initialValidFrom: string): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.api.put(`${netConnector.machineSid}/grid-connectors/versions/${initialValidFrom}`,
                NetConnectorVersion.mapToAddEditRequest(netConnector));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async deleteNetConnector(netConnector: NetConnectorVersion): Promise<boolean> {
        try {
            store.commit('loading');
            await this.api.delete(`${netConnector.machineSid}/grid-connectors/versions/${netConnector.validFrom}`);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            const errorMessage = ErrorHandler.getAxiosErrorMessage(error) ?? 'errorRemovingNetConnector';
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, errorMessage);
            return false;
        } finally {
            store.commit('loading');
        }
    }
}

const netConnectorService = new NetConnectorService();

export {
    NetConnectorService,
    netConnectorService,
};
