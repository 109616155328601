var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"key-mapping-container"},[_c('key-mapping-page-header'),_c('div',{staticClass:"key-mapping-body"},[_c('card',{attrs:{"enable-full-height":true,"enable-scroll":true}},[_c('pui-grid-container',[_c('pui-grid-row',[_c('pui-grid-column',{attrs:{"cols":{s: 12, m:8, l:6, xl: 4, xxl: 4}}},[_c('pebble-drop-down-select',{attrs:{"name":"selectExternalSystem","model":_vm.model,"label":_vm.$t('selectExternalSystem').toString(),"options":_vm.externalSystems},on:{"on-input":_vm.onClickExternalSystem}})],1)],1),(_vm.model.value !== -1)?_c('pui-grid-row',{staticClass:"key-mapping-margin"},[_c('pui-grid-column',{attrs:{"cols":{s: 12, m:12, l:12, xl: 12, xxl: 12}}},[_c('pebble-loader',{attrs:{"status":_vm.dataStatus,"error-sub-title":_vm.$t('errorLoadingExternalSystems').toString()},on:{"on-refresh-click":function($event){return _vm.loadKeyMappings(_vm.model.value)}}},[_c('custom-table',{attrs:{"columns-key":['assetName', 'assetId', 'assetType', 'keyMapping', 'validFrom', 'validTo', 'isActive', 'action'],"sortable-key":['assetName', 'assetId', 'assetType', 'keyMapping', 'validFrom', 'validTo', 'isActive'],"data":_vm.keyMappings,"heading":_vm.heading,"per-page":10,"per-page-values":[10, 50, 100],"custom-template":['isActive', 'action'],"enable-search":"","enable-pagination":"","enable-limit":""},scopedSlots:_vm._u([{key:"custom_isActive",fn:function(ref){
var data = ref.data;
return [_c('boolean-text',{attrs:{"titles":{
                                        textTrue: _vm.$t('yes').toString(),
                                        textFalse: _vm.$t('no').toString(),
                                    },"value":data}})]}},{key:"custom_action",fn:function(ref){
                                    var data = ref.data;
return [_c('pui-link',{attrs:{"title":_vm.$t('edit'),"icon":"edit"},on:{"click":function($event){return _vm.onClickEdit(data)}}})]}}],null,false,1485900591)})],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }