<template>
    <div class="flex-container main-container list-col">
        <div class="flex-col flex-1">
            <div class="cards-container">
                <vertical-list-transition class="cards-list" tag="div">
                    <slot name="cards" />
                </vertical-list-transition>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import VerticalListTransition from '@/components/transitions/vertical-list-transition/vertical-list-transition.vue';

@Component({
    name: 'cards-list',
    components: {
        verticalListTransition: VerticalListTransition,
    },
})
export default class CardList extends Vue {
}

</script>

<style scoped lang="less">
@import "~@/variables.less";

.main-container{
    &.list-col {
        width: 100%;
    }
}
.adam-card {
    padding: 1.5rem 1rem !important;
    .adam-card-header {
        font-family: Roboto-Light, sans-serif !important;
    }
    @media(max-width: 1500px) {
        .adam-card-header {
            font-size: 1.4rem !important;
        }
        .adam-card-content {
            font-size: 1.4rem !important;
        }
    }
}

.cards-list {
    display: grid;
    grid-row-gap: 0.5rem;
    grid-template-columns: minmax(0, 1fr);
    overflow-x: visible;
}

</style>
