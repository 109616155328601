<template>
    <div class="operation-mode-identification">
        <template v-if="!isAddingNew">
            <table-view
                v-if="operationModeVersions.length"
                :header="header"
                :items="operationModeVersions"
                :is-selected-cb="isSelectedOperationModeVersion"
                @select-item="onSelectItem"/>
            <div class="operation-mode-identification__actions mt-2">
            </div>
        </template>
        <div v-if="formModel">
            <validation-observer ref="validationObserver">
                <div class="operation-mode-identification__header">{{ $t('baseData') }}</div>
                <div class="formModel">
                    <validation-provider
                        tag="div"
                        class="manage-input"
                        name="validFrom"
                        rules="required"
                        #default="{ errors, failed }">
                        <label class="mr-2 default-label" for="validFrom">{{ $t('validFrom') }}*:</label>
                        <md-date-picker
                            v-model="formModel.validFrom"
                            :disabled-date="checkDisabledDate"
                            :class="{ 'invalid': failed }"/>
                        <error-list :errors="errors"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input"
                                         v-if="shouldShowValidToDatepicker">
                        <label class="mr-2 default-label" for="validTo">{{ $t('validUntil') }}:</label>
                        <md-date-picker
                            v-model="formModel.validTo"
                            :disabled-date="checkDisabledDate"
                            disabled/>
                    </validation-provider>
                    <validation-provider v-if="!isAddingNew" tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="operationModeSid">{{ $t('sid') }}:</label>
                        <input class="default-input" :value="formModel.operationModeSid" :placeholder="$t('sid')" id="operationModeSid"
                               disabled/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="unitSid">{{ $t('unitSid') }}:</label>
                        <input class="default-input" v-model="formModel.unitSid" :placeholder="$t('unitSid')" id="unitSid" disabled/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="unitSapType">{{ $t('unitType') }}:</label>
                        <input class="default-input" v-model="formModel.unitSapType" :placeholder="$t('unitType')" id="unitSapType"
                               disabled/>
                    </validation-provider>
                    <validation-provider
                        tag="div"
                        class="manage-input"
                        name="name"
                        rules="required|max:50"
                        #default="{ failed, errors }">
                        <label class="mr-2 default-label" for="operationModeName">{{ $t('operationModeName') }}*:</label>
                        <input class="default-input" v-model="formModel.operationModeName" :class="{ 'invalid': failed }" :placeholder="$t('name')"
                               id="operationModeName"/>
                        <error-list :errors="errors"/>
                    </validation-provider>
                    <validation-provider
                        tag="div"
                        class="manage-input"
                        name="operationModeType"
                        rules="required"
                        #default="{ failed, errors }">
                        <label class="mr-2 default-label">{{ $t('operationModeType') }}*:</label>
                        <select class="default-select" v-model="formModel.enumOpModeTypeSid"
                                :class="{ 'invalid': failed }"
                                :placeholder="$t('operationModeType')">
                            <option v-for="type of operationModeTypes"
                                    :key="type.sid"
                                    :value="type.sid">
                                {{ type.name }} - {{ type.description }}
                            </option>
                        </select>
                        <error-list :errors="errors"/>
                    </validation-provider>
                    <validation-provider
                        tag="div"
                        class="manage-input"
                        name="operationModePrimaryEnergy"
                        rules="required"
                        #default="{ errors, failed }">
                        <label class="mr-2 default-label">{{ $t('operationModePrimaryEnergy') }}*:</label>
                        <select class="default-select" v-model="formModel.enumPriEnergySid"
                                :class="{ 'invalid': failed }"
                                :placeholder="$t('operationModePrimaryEnergy')">
                            <option v-for="energy of operationModeEnergyTypes"
                                    :key="energy.sid"
                                    :value="energy.sid">
                                {{ energy.name }} - {{ energy.description }}
                            </option>
                        </select>
                        <error-list :errors="errors"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="enumSecEnergySid">{{ $t('operationModeSecondaryEnergy') }}:</label>
                        <select class="default-select" id="enumSecEnergySid" v-model="formModel.enumSecEnergySid"
                                :placeholder="$t('operationModeSecondaryEnergy')">
                            <option :value="null" key="default" selected>
                                {{ $t('none') }}
                            </option>
                            <option v-for="energy of operationModeEnergyTypes"
                                    :key="energy.sid"
                                    :value="energy.sid">
                                {{ energy.name }} - {{ energy.description }}
                            </option>
                        </select>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="enumConsolidationSid">{{ $t('inclusionMethod') }}:</label>
                        <select class="default-select" id="enumSecEnergySid" v-model="formModel.enumConsolidationSid"
                                :placeholder="$t('inclusionMethod')">
                            <option :value="null" key="default" selected>
                                {{ $t('none') }}
                            </option>
                            <option v-for="method of inclusionMethodList"
                                    :key="method.sid"
                                    :value="method.sid">
                                {{ method.name }}
                            </option>
                        </select>
                    </validation-provider>
                    <validation-provider tag="div">
                        <checkbox-input
                            :ref="formModel.isStdOperationMode"
                            @click="checkStdOperationMode"
                            v-model="formModel.isStdOperationMode"
                            :label="$t('standardOperationMode')"/>
                    </validation-provider>
                    <validation-provider tag="div">
                        <checkbox-input
                            v-model="formModel.isRedispatchAvailable"
                            :label="$t('isRedispatchAvailable')"/>
                    </validation-provider>
                    <validation-provider tag="div">
                        <checkbox-input
                            v-model="formModel.isBlackstart"
                            :label="$t('isBlackstart')"/>
                    </validation-provider>
                    <validation-provider tag="div" v-if="!isAddingNew">
                        <checkbox-input
                            v-model="formModel.isDeleted"
                            :label="$t('markAsDeleted')"/>
                    </validation-provider>
                    <validation-provider tag="div">
                        <checkbox-input
                            v-model="formModel.isActive"
                            :label="$t('markAsActive')"/>
                    </validation-provider>
                </div>
                <div class="operation-mode-identification__header">{{ $t('performanceData') }}</div>
                <div class="formModel">
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="grossCapacity">{{ $t('grossCapacity') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.grossCapacity" :placeholder="$t('grossCapacity')"
                               id="grossCapacity"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="netCapacityMW">{{ $t('netCapacityMW') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.netCapacity" :placeholder="$t('netCapacityMW')"
                               id="netCapacityMW"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="accountingNetCapacity">{{ $t('accountingNetCapacity') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.accountingNetCapacity"
                               :placeholder="$t('accountingNetCapacity')" id="accountingNetCapacity"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="netCapacityMin">{{ $t('netCapacityMin') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.netMinCapacityTec" :placeholder="$t('netCapacityMin')"
                               id="netCapacityMin"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="netCapacityContract">{{ $t('netCapacityContract') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.netCapacityContract" :placeholder="$t('netCapacityContract')"
                               id="netCapacityContract"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="thermalShareCapacity">{{ $t('thermalShareCapacity') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.thermalCapacityChp" :placeholder="$t('thermalShareCapacity')"
                               id="thermalShareCapacity"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="thermalCapacity">{{ $t('thermalCapacity') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.thermalCapacity" :placeholder="$t('thermalCapacity')"
                               id="thermalCapacity"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="netMinThermalCapacity">{{ $t('netMinThermalCapacity') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.netMinThermalCapacity"
                               :placeholder="$t('netMinThermalCapacity')" id="netMinThermalCapacity"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="firingThermalCapacity">{{ $t('firingThermalCapacity') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.firingThermalCapacity"
                               :placeholder="$t('firingThermalCapacity')" id="firingThermalCapacity"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="primaryReservePos">{{ $t('primaryReservePos') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.primaryReservePos" :placeholder="$t('primaryReservePos')"
                               id="primaryReservePos"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="primaryReserveNeg">{{ $t('primaryReserveNeg') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.primaryReserveNeg" :placeholder="$t('primaryReserveNeg')"
                               id="primaryReserveNeg"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="secondaryReservePos">{{ $t('secondaryReservePos') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.secondaryReservePos" :placeholder="$t('secondaryReservePos')"
                               id="secondaryReservePos"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="secondaryReserveNeg">{{ $t('secondaryReserveNeg') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.secondaryReserveNeg" :placeholder="$t('secondaryReserveNeg')"
                               id="secondaryReserveNeg"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="minuteReservePos">{{ $t('minuteReservePos') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.minuteReservePos" :placeholder="$t('minuteReservePos')"
                               id="minuteReservePos"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="minuteReserveNeg">{{ $t('minuteReserveNeg') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.minuteReserveNeg" :placeholder="$t('minuteReserveNeg')"
                               id="minuteReserveNeg"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="maxSpeedPowerInc">{{ $t('maxSpeedPowerInc') }}</label>
                        <input class="default-input" type="number" v-model="formModel.maxSpeedPowerInc" :placeholder="$t('maxSpeedPowerInc')"
                               id="maxSpeedPowerInc"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="startUpRampGradient">{{ $t('startUpRampGradient') }}</label>
                        <input class="default-input" type="number" v-model="formModel.startUpRampGradient" :placeholder="$t('startUpRampGradient')"
                               id="startUpRampGradient"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="startUpRamp">{{ $t('startUpRamp') }}</label>
                        <input class="default-input" type="number" v-model="formModel.startUpRamp" :placeholder="$t('startUpRamp')" id="startUpRamp"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="startUpRampGradZ2">{{ $t('startUpRampGradZ2') }}</label>
                        <input class="default-input" type="number" v-model="formModel.startUpRampGradZ2" :placeholder="$t('startUpRampGradZ2')"
                               id="startUpRampGradZ2"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="startUpRampGradZ3">{{ $t('startUpRampGradZ3') }}</label>
                        <input class="default-input" type="number" v-model="formModel.startUpRampGradZ3" :placeholder="$t('startUpRampGradZ3')"
                               id="startUpRampGradZ3"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="startUpRampGradZ4">{{ $t('startUpRampGradZ4') }}</label>
                        <input class="default-input" type="number" v-model="formModel.startUpRampGradZ4" :placeholder="$t('startUpRampGradZ4')"
                               id="startUpRampGradZ4"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="maxSpeedPowerDec">{{ $t('maxSpeedPowerDec') }}</label>
                        <input class="default-input" type="number" v-model="formModel.maxSpeedPowerDec" :placeholder="$t('maxSpeedPowerDec')"
                               id="maxSpeedPowerDec"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="shutdownRampGradient">{{ $t('shutdownRampGradient') }}[%]:</label>
                        <input class="default-input" type="number" v-model="formModel.shutdownRampGradient" :placeholder="$t('shutdownRampGradient')"
                               id="shutdownRampGradient"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="shutdownRamp">{{ $t('shutdownRamp') }}[MW/min]:</label>
                        <input class="default-input" type="number" v-model="formModel.shutdownRamp" :placeholder="$t('shutdownRamp')"
                               id="shutdownRamp"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="shutdownRampGradZ2">{{ $t('shutdownRampGradZ2') }}[MW/min]:</label>
                        <input class="default-input" type="number" v-model="formModel.shutdownRampGradZ2" :placeholder="$t('shutdownRampGradZ2')"
                               id="shutdownRampGradZ2"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="shutdownRampGradZ3">{{ $t('shutdownRampGradZ3') }}[MW/min]:</label>
                        <input class="default-input" type="number" v-model="formModel.shutdownRampGradZ3" :placeholder="$t('shutdownRampGradZ3')"
                               id="shutdownRampGradZ3"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="shutdownRampGradZ4">{{ $t('shutdownRampGradZ4') }}[MW/min]:</label>
                        <input class="default-input" type="number" v-model="formModel.shutdownRampGradZ4" :placeholder="$t('shutdownRampGradZ4')"
                               id="shutdownRampGradZ4"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="downtimeMin">{{ $t('downtimeMin') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.downtimeMin" :placeholder="$t('downtimeMin')" id="downtimeMin"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="minOperationTime">{{ $t('minOperationTime') }}[min]:</label>
                        <input class="default-input" type="number" v-model="formModel.minOperationTime" :placeholder="$t('minOperationTime')"
                               id="minOperationTime"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="startuptimeMin">{{ $t('startuptimeMin') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.startuptimeMin" :placeholder="$t('startuptimeMin')"
                               id="startuptimeMin"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="startUpTimeConditionCold">{{ $t('startUpTimeConditionCold') }}[min]:</label>
                        <input class="default-input" type="number" v-model="formModel.startUpTimeConditionCold"
                               :placeholder="$t('startUpTimeConditionCold')" id="startUpTimeConditionCold"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="startUpTimeConditionWarm">{{ $t('startUpTimeConditionWarm') }}[min]:</label>
                        <input class="default-input" type="number" v-model="formModel.startUpTimeConditionWarm"
                               :placeholder="$t('startUpTimeConditionWarm')" id="startUpTimeConditionWarm"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="startUpTimePminCold">{{ $t('startUpTimePminCold') }}[min]:</label>
                        <input class="default-input" type="number" v-model="formModel.startUpTimePminCold" :placeholder="$t('startUpTimePminCold')"
                               id="startUpTimePminCold"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="startUpTimePminWarm">{{ $t('startUpTimePminWarm') }}[min]:</label>
                        <input class="default-input" type="number" v-model="formModel.startUpTimePminWarm" :placeholder="$t('startUpTimePminWarm')"
                               id="startUpTimePminWarm"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label smaller-text" for="durationUpMin">{{ $t('durationUpMin') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.durationUpMin" :placeholder="$t('durationUpMin')"
                               id="durationUpMin"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label smaller-text" for="durationDownMin">{{ $t('durationDownMin') }}:</label>
                        <input class="default-input" type="number" v-model="formModel.durationDownMin" :placeholder="$t('durationDownMin')"
                               id="durationDownMin"/>
                    </validation-provider>
                    <validation-provider tag="div" class="manage-input">
                        <label class="mr-2 default-label" for="shutdownTimePminGrid">{{ $t('shutdownTimePminGrid') }}</label>
                        <input class="default-input" type="number" v-model="formModel.shutdownTimePminGrid" :placeholder="$t('shutdownTimePminGrid')"
                               id="shutdownTimePminGrid"/>
                    </validation-provider>
                    <validation-provider tag="div">
                        <checkbox-input
                            v-model="formModel.isExternalOperation"
                            :label="$t('externalDispatch')"/>
                    </validation-provider>
                </div>
            </validation-observer>
        </div>
        <last-updated v-if="!isAddingNew && !isAddingNewVersion && formModel" :data="formModel"/>
        <error-list :errors="invalidMessages"/>
        <div class="operation-mode-identification__actions mt-2">
            <adam-button v-if="selectedOperationModeVersion && !isAddingNewVersion"
                         secondary
                         icon="ic-remove"
                         class="ml-1"
                         @click="handleRemoveClick">
                {{ $t('remove') }}
            </adam-button>
            <adam-button v-if="!isAddingNewVersion" icon="ic-add-dashboard"
                         class="ml-1"
                         @click="handleAddNewClick"
                         secondary>
                {{ $t('addNewVersion') }}
            </adam-button>
            <adam-button v-if="isAddingNewVersion"
                         class="ml-1"
                         secondary
                         @click="handleCancelClick">
                {{ $t('cancel') }}
            </adam-button>
            <adam-button
                v-if="formModel && hasMasterDataAdmin"
                class="ml-1"
                @click="handleSave()"
                secondary>
                {{ $t('save') }}
            </adam-button>
        </div>
    </div>
</template>

<script lang="ts">

import {Component, Mixins, Prop, Ref} from 'vue-property-decorator';
import {DATE_FORMAT, EventBus, extractErrorsFromResponse, InfiniteScrollingHelper, MIN_DATE} from '@/utils';
import {
    Asset,
    IdTypeSid,
    OperationModeGetResponse,
    OperationModeVersionAddRequest,
    OperationModeVersionEditRequest,
    OperationModeVersionGetResponse,
    OperationModeVersionListDto,
    Tabbable,
    UnitOperationModeAddRequest,
} from '@/models';
import {AssetService, OperationModesService, UnitService} from '@/services';
import CheckboxInput from '@/components/checkbox-input/checkbox-input.vue';
import ErrorList from '@/components/error-list/error-list.vue';
import tableHeader from './operation-mode-versions-table-header';
import {OperationModeFactory} from '@/utils/factories';
import {ValidationObserver} from 'vee-validate';
import TableView from '@/components/view-details/table-view/table-view.vue';
import ComponentSecurity from '@/mixins/component-security';
import LastUpdated from '@/components/last-updated/last-updated.vue';
import format from 'date-fns/format';

/**
 * Contents for the "Identification" tab of the
 * [`operation-mode-details` component](operation-mode-details.md)
 *
 * Show the versions of an operation mode, and enable the user to make changes
 */
@Component({
    name: 'operation-mode-identification',
    components: {
        checkboxInput: CheckboxInput,
        errorList: ErrorList,
        tableView: TableView,
        lastUpdated: LastUpdated,
    },
})
export default class OperationModeIdentification extends Mixins(ComponentSecurity) implements Tabbable {
    @Ref()
    private readonly validationObserver!: InstanceType<typeof ValidationObserver>;

    /**
     * Operation mode of which general details should be displayed
     */
    @Prop({required: true})
    private data!: OperationModeGetResponse;

    private header = tableHeader;
    private operationModeVersions: OperationModeVersionListDto[] = [];

    private selectedOperationModeVersion: OperationModeVersionGetResponse | null = null;
    private operationModeTypes: Asset[] = [];
    private operationModeEnergyTypes: Asset[] = [];
    private inclusionMethodList: Asset[] = [];

    private isAddingNewVersion = false;
    private isAddingNew = false;
    private invalidMessages: string[] = [];
    private formModel: UnitOperationModeAddRequest |
        OperationModeVersionAddRequest |
        OperationModeVersionEditRequest |
        null = null;

    private get shouldShowValidToDatepicker(): boolean {
        return this.selectedOperationModeVersion && this.formModel ?
            !this.isAddingNewVersion && this.formModel.validFrom === this.selectedOperationModeVersion.validFrom :
            false;
    }

    public async beforeLeave(): Promise<boolean> {
        if (!this.validationObserver || !this.validationObserver.flags.dirty) {
            return true;
        }

        return await this.showDialog('areYouSureLeaveUnsaved');
    }

    private async showDialog(message: string): Promise<boolean> {
        try {
            await this.$dialog
                .confirm(
                    {body: this.$t(message).toString()},
                    {view: 'confirm'});
            return true;
        } catch (error: any) {
            return Promise.resolve(false);
        }
    }

    private async isFormDirty(): Promise<boolean> {
        if (!this.validationObserver || !this.validationObserver.flags.dirty) {
            return true;
        }

        return await this.showDialog('areYouSureSelectAnotherVersion');
    }

    private async mounted(): Promise<void> {
        EventBus.$on(EventBus.DETAIL.CLOSE, this.closeDetailsView);
        const isAddingNew = this.data.operationModeSid === 0
            && this.$route.query.unitSid;
        await Promise.all([
            !isAddingNew
                ? this.setOperationModeVersions()
                : null,
            this.setOperationModeTypes(),
            this.setOperationModeEnergyTypes(),
            this.setInclusionMethod(),
        ]);

        if (isAddingNew) {
            this.isAddingNew = true;
            this.formModel = OperationModeFactory.createUnitOperationModeAddRequest(Number(this.$route.query.unitSid));
        }
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.DETAIL.CLOSE);
    }

    /* Versions table operations */

    private async setOperationModeVersions(selectVersionValidFrom = ''): Promise<void> {
        this.operationModeVersions = await (new OperationModesService()).getVersions(this.data.operationModeSid);

        const defaultVersion = selectVersionValidFrom
            ? this.operationModeVersions.find(({validFrom}) => validFrom === selectVersionValidFrom)
            : this.operationModeVersions.find(({isActive}) => isActive);

        if (!defaultVersion) {
            return;
        }
        this.setSelectedOperationModeVersion(defaultVersion);
    }

    private async onSelectItem(item: OperationModeVersionListDto): Promise<void> {
        const canLeave = await this.isFormDirty();
        if (canLeave) {
            this.setSelectedOperationModeVersion(item);
            this.validationObserver?.reset();
        }
    }

    private async setSelectedOperationModeVersion(item: OperationModeVersionListDto): Promise<void> {
        try {
            this.selectedOperationModeVersion = await (new OperationModesService())
                .getVersion(this.data.operationModeSid, item.validFrom);
            this.formModel = {...this.selectedOperationModeVersion};
            this.isAddingNewVersion = false;
            this.invalidMessages = [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingOperationMode');
            throw error;
        }
    }

    private isSelectedOperationModeVersion(item: OperationModeVersionListDto): boolean {
        return this.selectedOperationModeVersion?.validFrom === item.validFrom;
    }

    /* Event Handlers */

    private async handleAddNewClick() {
        const canLeave = await this.isFormDirty();
        if (!canLeave) {
            return;
        }

        this.formModel = this.selectedOperationModeVersion
            ? {...this.selectedOperationModeVersion, validFrom: format(new Date(), DATE_FORMAT)}
            : OperationModeFactory.createVersion(this.data.operationModeSid);
        this.isAddingNewVersion = true;
        if (this.validationObserver) {
            this.validationObserver?.reset();
        }
    }

    private async handleCancelClick() {
        this.isAddingNewVersion = false;
        this.formModel = this.selectedOperationModeVersion
            ? {...this.selectedOperationModeVersion}
            : null;
        this.invalidMessages = [];
        this.validationObserver?.reset();
    }

    private async handleRemoveClick() {
        if (!this.selectedOperationModeVersion) {
            return;
        }
        try {
            await this.$dialog
                .confirm(
                    {body: this.$t('areYouSureDeleteItem').toString()},
                    {view: 'confirm'});
        } catch (error: any) {
            return;
        }

        this.$store.commit('loading');
        try {
            const {operationModeSid, validFrom} = this.selectedOperationModeVersion;
            await (new OperationModesService()).deleteVersion(operationModeSid, validFrom);
            await this.setOperationModeVersions();
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorRemovingOperationModeVersion');
            if (!error.response || !error.response.data) {
                throw error;
            }
            this.invalidMessages = extractErrorsFromResponse(error.response.data);
            if (this.invalidMessages.length) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.error-list__message');
                });
            }
        } finally {
            this.$store.commit('loading');
        }
    }

    private checkStdOperationMode(): boolean {
        if (!this.selectedOperationModeVersion?.isStdOperationMode &&
            this.formModel?.isStdOperationMode) {
            return true;
        } else {
            return false;
        }
    }

    private async handleSave(): Promise<void> {
        this.invalidMessages = [];

        if (!this.formModel) {
            return;
        }

        try {
            const isValid = await this.validationObserver.validate();
            if (!isValid) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.invalid');
                });
                return;
            }
        } catch (error: any) {
            return;
        }

        let shouldSave = false;

        if (this.checkStdOperationMode()) {
            shouldSave = await this.showDialog('areYouSureCheckStdOperation');
        }

        if (!shouldSave && this.checkStdOperationMode()) {
            this.formModel.isStdOperationMode = false;
            return;
        }

        if (this.isAddingNew) {
            await this.saveNew(this.formModel as UnitOperationModeAddRequest);
        } else if (this.isAddingNewVersion) {
            await this.saveNewVersion(this.formModel as OperationModeVersionAddRequest);
        } else {
            await this.updateVersion(this.formModel as OperationModeVersionEditRequest);
        }
        this.validationObserver?.reset();
    }

    private async saveNew(model: UnitOperationModeAddRequest): Promise<void> {
        this.$store.commit('loading');
        try {
            const {operationModeSid} = (await new UnitService().addOperationMode(model.unitSid, model)).result;
            this.$router.replace({query: {item: operationModeSid.toString()}});
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingOperationMode');
            if (!error.response || !error.response.data) {
                throw error;
            }
            this.invalidMessages = extractErrorsFromResponse(error.response.data);
            if (this.invalidMessages.length) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.error-list__message');
                });
            }
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveNewVersion(model: OperationModeVersionAddRequest): Promise<void> {
        this.$store.commit('loading');
        try {
            const {result: {validFrom}} = await new OperationModesService().createVersion(model);
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            await this.setOperationModeVersions(validFrom);
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingOperationModeVersion');
            if (!error.response || !error.response.data) {
                throw error;
            }
            this.invalidMessages = extractErrorsFromResponse(error.response.data);
            if (this.invalidMessages.length) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.error-list__message');
                });
            }
        } finally {
            this.$store.commit('loading');
        }
    }

    private async updateVersion(model: OperationModeVersionEditRequest): Promise<void> {
        if (!this.selectedOperationModeVersion) {
            return;
        }

        this.$store.commit('loading');
        try {
            const {result: {validFrom}} = await new OperationModesService().updateVersion(
                this.data.operationModeSid,
                this.selectedOperationModeVersion.validFrom,
                model);
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            this.setOperationModeVersions(validFrom);
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorUpdatingOperationModeVersion');
            if (!error.response || !error.response.data) {
                throw error;
            }
            this.invalidMessages = extractErrorsFromResponse(error.response.data);
            if (this.invalidMessages.length) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.error-list__message');
                });
            }
        } finally {
            this.$store.commit('loading');
        }
    }

    /* Form Options operations */

    private async setOperationModeTypes(): Promise<void> {
        this.operationModeTypes = await this.getAssets(IdTypeSid.OPERATION_MODE_TYPE);
    }

    private async setOperationModeEnergyTypes(): Promise<void> {
        this.operationModeEnergyTypes = await this.getAssets(IdTypeSid.OPERATION_MODE_ENERGY_TYPE);
    }

    private async setInclusionMethod(): Promise<void> {
        this.inclusionMethodList = await this.getAssets(IdTypeSid.CONSOLIDATION);
    }

    private async getAssets(idTypeSid: number): Promise<Asset[]> {
        try {
            const {result: {items}} = await (new AssetService())
                .getByIdTypeSid(idTypeSid, {page: 1, size: 1000, term: ''});

            return items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
    }

    private checkDisabledDate(date: Date): boolean {
        return date < MIN_DATE;
    }

    private async closeDetailsView(): Promise<void> {
        const canLeave = await this.beforeLeave();
        if (canLeave) {
            /**
             * Fired when the user wishes to close the `view-details` screen without
             * performing any changes
             */
            this.$emit('close');
        }
    }
}

</script>

<style scoped lang="less">
@import "~@/variables.less";

.operation-mode-identification {
    .formModel {
        display: grid;
        grid-template-columns: 40% 40%;
        grid-gap: 4rem;
        margin-top: 4rem;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
    }

    &__header {
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-size: 2rem;
        font-family: @font-roboto-md;
        border-bottom: 1px solid @grey-lighter;
    }
}

label {
    margin-top: 1rem;
    min-width: fit-content;
}

input, textarea {
    width: 100%;
}

.manage-input {
    .smaller-text {
        font-size: small;
    }
}

</style>
