<template>
    <div class="reservoirs-management flex-col full-height">
        <div v-show="!selectedReservoir" class="flex-row ets-units-details">
            <div class="flex flex-4"></div>
            <div class="flex-col">
                <div class="flex-row">
                    <div class="mr-1 pt-1 pb-1 center-align">
                        <search-component @input="searchCards"/>
                    </div>
                    <div class="mr-2 center-align">
                        <adam-button
                            v-if="hasMasterDataAdmin"
                            class="mr-1 mt-1"
                            icon="ic-add-dashboard"
                            @click="addNewEntity()"
                            secondary
                        >
                            {{ $t('addNew') }}
                        </adam-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-area" id="view-items">
            <div v-if="!reservoirsList.length" class="mt-2 ml-2">
                {{ $t('noDataToShow') }}
            </div>
            <div v-else-if="!selectedReservoir"
                class="cards-display">
                <sort-header
                    v-if="headerColumn.length"
                    :headerColumns="headerColumn"
                    :objectToSort="reservoirsList"
                    @get-sorted-list="setSortedLocalEtsUnits"
                ></sort-header>
                <card-list>
                    <template v-slot:cards>
                        <div v-for="unit of reservoirsList"
                            :key="unit.reservoirSid"
                            class="card">
                            <reservoir-card-list
                                :card="unit"
                                @edit="selectUnit"
                                @remove="deleteClicked"
                            />
                        </div>
                    </template>
                </card-list>
                <div v-if="hasNextPage" class="flex-row mt-2" >
                    <div class="flex-col load-more">
                        <adam-button
                            secondary
                            @click="calculateScroll()"
                        >
                            {{ $t('loadMore') }}
                        </adam-button>
                    </div>
                </div>
            </div>
            <div v-else-if="selectedReservoir"
                class="edit-screen">
                <reservoir-details
                    :data="selectedReservoir"
                    :isNewReservoir="isNewReservoir"
                    @close="closeDetailsView()"
                    @save="saveChanges()"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import ReservoirCardList from '@/components/cards/cards-list/reservoir-card-list/reservoir-card-list.vue';
import CardList from '@/components/cards/cards-list/cards-list.vue';
import SearchComponent from '@/components/search-component/search-component.vue';
import ViewDetails from '@/components/view-details/view-details.vue';
import ReservoirDetails from '@/components/view-details/reservoirs/reservoirs-details.vue';
import { InfiniteScrollingHelper, EventBus } from '@/utils';
import { EtsUnit, GenericTab, Reservoir, ReservoirAddRequest, ReservoirManagementQueryModel } from '@/models';
import { ReservoirService } from '@/services';
import { throttle } from 'lodash';
import { Component, Watch, Mixins } from 'vue-property-decorator';
import ComponentSecurity from '@/mixins/component-security';
import SortHeader from '@/views/sort-header/sort-header.vue';
import { ReservoirFactory } from '@/utils/factories';

const SCROLL_THROTTLE_MILLISECONDS = 500;

@Component({
    name: 'reservoirs-management',
    components: {
        searchComponent: SearchComponent,
        cardList: CardList,
        viewDetails: ViewDetails,
        reservoirCardList: ReservoirCardList,
        reservoirDetails: ReservoirDetails,
        sortHeader: SortHeader,
    },
})

export default class ReservoirsManagement extends Mixins(ComponentSecurity) {
    private reservoirService: ReservoirService = new ReservoirService();
    private selectedReservoir: ReservoirAddRequest | Reservoir | null = null;
    private reservoirsList: Reservoir[] = [];
    private isLoadingNextPage = false;
    private hasNextPage = false;
    private isNewReservoir = false;
    private routeReservoirId: string | undefined;
    private onScroll = throttle(this.calculateScroll, SCROLL_THROTTLE_MILLISECONDS);
    private filterModel: ReservoirManagementQueryModel = {
        page: 1,
        size: 10,
        term: '',
        sortDirection: '',
        sortColumn: '',
    };
    private tabs: GenericTab[] = [
        { name: 'identification', component: 'estUnits'},
    ];
    private activeTab = this.tabs[0];
    private sortOrder = 'asc';
    private sortKey = 'name';
    private headerColumn: Array<{
        attributeName: string;
        name: string;
        itemCssClassName: string;
    }> = [];

    @Watch('$route.query.item')
    private async onQueryItemChanged(newValue: string | undefined, oldValue: string | undefined) {
        if (newValue === oldValue) {
            return;
        }

        if (!newValue) {
            this.routeReservoirId = undefined;
            if (!this.reservoirsList.length) {
                await this.getReservoirs();
            }
            this.addEventListener();
            return;
        } else {
            this.routeReservoirId = newValue;
            this.removeEventListener();
        }
        await this.assignRouteReservoir();
    }

    private async mounted(): Promise<void> {
        await this.getReservoirs();
        this.addEventListener();
        this.headerColumn = [
            {
                attributeName: 'reservoirName',
                name: this.$t('name').toString(),
                itemCssClassName: 'name',
            },
            {
                attributeName: 'reservoirSid',
                name: this.$t('sid').toString(),
                itemCssClassName: 'reservoirSid',
            },
            {
                attributeName: 'river',
                name: this.$t('river').toString(),
                itemCssClassName: 'river',
            },
            {
                attributeName: 'riverGroup',
                name: this.$t('riverGroup').toString(),
                itemCssClassName: 'river-group',
            },
        ];
        if (!this.$route.query.item) {
            await this.getPagedReservoirs();
            this.addEventListener();
            return;
        }
        this.routeReservoirId = this.$route.query.item?.toString();
        await this.assignRouteReservoir();
        this.removeEventListener();
    }

    private beforeDestroy(): void {
        this.removeEventListener();
    }

    private addEventListener(): void {
        const element = document.getElementById('view-items');
        if (element) {
            element.addEventListener('scroll', this.onScroll);
            element.addEventListener('wheel', this.onScroll);
        }
    }

    private removeEventListener(): void {
        const element = document.getElementById('view-items');
        if (element) {
            element.removeEventListener('scroll', this.onScroll);
            element.removeEventListener('wheel', this.onScroll);
        }
    }

    private calculateScroll(): void {
        if (this.selectedReservoir) {
            return;
        }
        InfiniteScrollingHelper.calculateScroll(document.getElementById('view-items'), this.getNextPage, 200);
    }

    private closeDetailsView(): void {
        this.selectedReservoir = null;
        if (!this.isNewReservoir) {
            this.$router.push({
                name: 'manage-option',
                params: {
                    option: 'reservoirs-management',
                },
            });
        }
        this.isNewReservoir = false;
    }
    private addNewEntity(): void {
        this.selectedReservoir = ReservoirFactory.createReservoir();
        this.isNewReservoir = true;
    }
    private async searchCards(searchString: string): Promise<void> {
        this.filterModel.term = searchString;
        await this.getReservoirs();
    }

    private async assignRouteReservoir() {
        if (this.reservoirsList?.length && this.routeReservoirId) {
            this.selectedReservoir =
                this.reservoirsList.
                find((reservoir) => this.routeReservoirId && reservoir.reservoirSid === +this.routeReservoirId) ?? null;
            if (!this.selectedReservoir) {
                this.filterModel.term = this.routeReservoirId;
                this.reservoirsList = await this.getPagedReservoirs();
                this.selectedReservoir =
                    this.reservoirsList.
                    find((reservoir) =>
                    this.routeReservoirId && reservoir.reservoirSid === +this.routeReservoirId) ?? null;
            }
        } else {
            this.selectedReservoir = null;
        }
    }

    private async getReservoirs(): Promise<void> {
        this.filterModel.page = 1;
        this.reservoirsList = await this.getPagedReservoirs();
        await this.assignRouteReservoir();
    }

    private async getPagedReservoirs(): Promise<Reservoir[]> {
        this.$store.commit('loading');
        let reservoirsList: Reservoir[] = [];
        try {
            const reservoirData = (await this.reservoirService.get(this.filterModel)).result;
            this.hasNextPage = reservoirData.hasNextPage;
            return reservoirsList = reservoirData.items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingReservoirs');
        } finally {
            this.$store.commit('loading');
        }
        return reservoirsList;
    }

    private async getNextPage(): Promise<void> {
        this.removeEventListener();
        if (this.isLoadingNextPage) {
            return;
        }

        if (!this.hasNextPage) {
            return;
        }
        this.filterModel.page++;
        this.isLoadingNextPage = true;
        const reservoirsList = await this.getPagedReservoirs();
        this.reservoirsList.push(...reservoirsList);
        await this.$nextTick();
        this.addEventListener();
        this.isLoadingNextPage = false;
    }


    private saveChanges(etsUnit: EtsUnit, isAdd: boolean): void {
        if (isAdd) {
            return;
        }
        this.$router.push({
            name: 'manage-option',
            params: {
                option: 'reservoirs-management',
            },
        });
        this.selectedReservoir = null;
        this.getReservoirs();
    }

    private selectUnit(reservoir: Reservoir): void {
        this.selectedReservoir = {...reservoir};
        if (this.$route.path === '/master-data-management/reservoir-management' &&
            this.$route.query.item === this.selectedReservoir.reservoirSid.toString()) {
            return;
        }

        this.$router.push({
            name: 'manage-option',
            params: {
                option: this.$route.params.option,
            },
            query: {
                item: this.selectedReservoir.reservoirSid.toString(),
            },
        });
    }

    private async deleteClicked(reservoir: Reservoir): Promise<any> {
        try {
            await this.$dialog.confirm(
                { body: this.$t('areYouSureDeleteItem').toString() },
                { view: 'confirm' });
        } catch (error: any) {
            if (typeof error !== 'boolean') {
                throw error;
            }
            return;
        }
        return this.deleteCard(reservoir);
    }

    private async deleteCard(reservoir: Reservoir): Promise<void> {
        this.$store.commit('loading');
        try {
            await this.reservoirService.delete(reservoir);
            this.reservoirsList =
            this.reservoirsList.filter((u: Reservoir) => u.reservoirSid !== reservoir.reservoirSid);
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorRemovingReservoir');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async setSortedLocalEtsUnits(obj: {
        sortedList: Reservoir[];
        order: string;
        sortKey: string;
    }): Promise<void> {
        const oldpg = this.filterModel.page;
        this.filterModel.size *= oldpg;
        this.filterModel.page = 1;
        if (obj.order === 'asc') {
            this.filterModel.sortDirection = 'Ascending';
        } else {
            this.filterModel.sortDirection = 'Descending';
        }
        this.filterModel.sortColumn = obj.sortKey;

        this.reservoirsList = this.reservoirsList = await this.getPagedReservoirs();

        this.filterModel.page = oldpg;
        this.filterModel.size = 10;
    }
}

</script>

<style lang="less">
@import "~@/variables.less";
.reservoirs-management {
    .ets-units-details {
        background: @white;
        border-bottom: 2px solid @grey-lighter;
    }
    .main-area {
        padding: 2.5rem;
        box-sizing: border-box;
        overflow-y: auto;
        height: 100%;
        .name {
            width: 18rem;
        }
        .sid {
            width: 8rem;
        }
        .is-deleted {
            width: 6rem;
        }
        .last-updated {
            width: 22rem
        }
        .last-updated-by {
            width: 18rem
        }
    }
    .header-row {
        &__item-reservoirSid,
        &__item-short-name,
        &__item-name,
        &__item-register-name,
        &__item-river,
        &__item-river-group,
        &__item-is-deleted {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            box-sizing: border-box;
            border-left: 2px solid @lighter-grey;
            padding: 0 1rem;
        }

        &__item-name {
            border-left: none;
            width: 12rem;
        }
        &__item-reservoirSid {
            width: 10rem;
        }
        &__item-is-deleted {
            width: 10rem;
        }
        &__item-river {
            width: 24rem;
        }
        &__item-river-group {
            width: 18rem;
        }
    }
}
</style>
