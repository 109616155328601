import {MaterialAddRequest} from '@/models';

export class MaterialBaseFactory {
    public static createMaterialAddRequest(): MaterialAddRequest {
        return {
            materialSid: null,
            shortName: '',
            materialName: '',
            materialGroupSid: 0,
            euEtsSid: 0,
            aggStateSid: null,
            qualitySid: null,
            homeCountrySid: null,
            mineSid: null,
            dehstSid: null,
            unitSettleSid: null,
            unitAdminSid: null,
            isDeleted: false,
            comment: '',
            standardCalorificValue: null,
            standardDensity: null,
            normDensity: null,
            co2EmissionFactor: null,
            so2EmissionFactor: null,
        };
    }
}
