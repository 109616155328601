<template>
    <validation-provider
        :rules="rules"
    >
        <div slot-scope="{ errors }">
            <div>
                <pui-tooltip />
                <div
                    v-pui-tooltip="{ message: tooltip }"
                >
                    <pui-form-group
                        :label="name"
                        :is-valid="!errors.length"
                        :has-info-layer="showPopover"
                        :show-required-label="isRequired"
                        :pui-popover-config="popoverConfig"
                    >
                        <pui-form-select
                            :class="disabled ? 'readonly-input': ''"
                            :key="selectBoxOptions.length"
                            :is-valid="!markAsError(errors.length)"
                            :is-disabled="disabled"
                            v-model="value"
                            label=""
                            :max-visible-options="maxVisibleOptions"
                            :options="selectBoxOptions"
                            :search-input-placeholder="disabled ? ' ' : placeholder"
                        />
                        <template #error-message>
                            {{ errors[0] }}
                        </template>
                        <template #pui-popover-content>
                            <div class="popover-content">
                                {{ helperText }}
                            </div>
                        </template>
                    </pui-form-group>
                </div>
            </div>
        </div>
    </validation-provider>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import ValidationSetup from './validation-setup';
import { SelectOption } from '@/models';

@Component({
    name: 'select-box',
    components: {
        ValidationProvider,
    },
})

export default class SelectBox extends ValidationSetup {
    @Prop({ default: () => [] })
    private readonly selectBoxOptions!: SelectOption[];

    @Prop({ required: false, default: '' })
    private readonly helperTitle!: string;

    @Prop({ required: false, default: '' })
    private readonly helperText!: string;

    @Prop({ required: false, default: '' })
    private inputValue!: any;

    @Prop({ required: false, default: '' })
    private name!: any;

    @Prop({ required: false, default: false })
    private disabled!: any;

    @Prop({ required: false })
    private readonly placeholder!: string;

    @Prop({ required: false, default: 7 })
    private readonly maxVisibleOptions!: number;

    private get value(): string {
        return this.inputValue;
    }

    private set value(changedValue: string) {
        this.$emit('change', changedValue);
    }

    private get showPopover(): boolean {
        return !!this.helperTitle || !!this.helperText;
    }

    private popoverConfig = {
        'headline': this.helperTitle,
        'position': 'bottom',
        'has-close-icon': false,
    };

    private get tooltip(): string | undefined {
        return (this.disabled && this.inputValue >= 0 && this.selectBoxOptions.length)
            ? this.selectBoxOptions[this.inputValue]?.label
            : '';
    }
}
</script>

<style lang="less" scoped>
.popover-content {
   white-space: pre-line;
}

/deep/ .readonly-input {
    input {
        padding: 0.8rem 1.2rem;
    }
    svg {
        display: none;
    }
}
</style>
