import { Asset } from '@/models';
import { getAdamIcon } from '@/utils';

export class EnumFactory {
    public static create(selectedIdTypeSid: number): Asset {
        return {
            sid: 0,
            name: '',
            description: '',
            idTypeSid: selectedIdTypeSid,
            objectType: getAdamIcon(selectedIdTypeSid),
            isDeleted: false,
        };
    }
}
