<template>
    <pui-navigation-toolbar
        :link-items="linkItems"
        v-on:changed:active-route="onTabClicked"
        style="z-index: 2"
    />
</template>

<script lang="ts">
import Component from 'vue-class-component';
import {Mixins, Watch} from 'vue-property-decorator';
import ComponentSecurity from '@/mixins/component-security';
import {TabBarModel} from '@/models/tab-bar-model';
import {clone} from 'lodash';

@Component({
    name: 'tab-bar',
})
export default class TabBar extends Mixins(ComponentSecurity) {
    private linkItems: TabBarModel[] = [];

    private created(): void {
        this.$router.onReady(() => {
            const currentPath = this.$router.currentRoute.path;
            const temp: TabBarModel[] = this.generateLinkItems();
            temp.forEach((value: TabBarModel) => {
                value.isActive = currentPath.includes(value.href.split('/')[1]) ?? false;
            });
            this.linkItems = temp;
        });
    }

    private onTabClicked(value: TabBarModel): void {
        this.$router.push(value.href);
    }

    private generateLinkItems(): TabBarModel[] {
        const data = [{
            displayName: this.$t('assetMasterData').toString(),
            href: '/asset-master-data',
            isActive: false,
        }];
        if (this.hasMasterDataAdmin) {
            data.push(...[
                {
                    displayName: this.$t('masterDataManagement').toString(),
                    href: '/master-data-management-v2',
                    isActive: false,
                },
                {
                    displayName: this.$t('assetOverview').toString(),
                    href: '/asset-overview',
                    isActive: false,
                },
                {
                    displayName: this.$t('keyMapping').toString(),
                    href: '/key-mapping',
                    isActive: false,
                },
            ]);
        }
        if (this.canViewStreamAssets) {
            data.push(...[{
                displayName: this.$t('streamSystemAssets').toString(),
                href: '/stream-system-assets',
                isActive: false,
            },
            ]);
        }
        if (this.canViewSynergyLife) {
            data.push(...[{
                displayName: this.$t('synergyLifeLocation').toString(),
                href: '/sinergy-life-location',
                isActive: false,
            },
            ]);
        }
        if (this.canViewHydroComponents) {
            data.push(...[{
                displayName: this.$t('hydroComponents.titles.hydro').toString(),
                href: '/hydro-components',
                isActive: false,
            }])
        }

        /*TODO: temp.push({displayName: this.$t('streamMapping'),
        href: '/stream-mapping', isActive: false});*/
        return data;
    }

    @Watch('$route.path')
    private onRouteChanged(path: string): void {
        const temp: TabBarModel[] = clone(this.linkItems);
        temp.forEach((value: TabBarModel) => {
            value.isActive = path.includes(value.href.split('/')[1]) ?? false;
        });
        this.linkItems = temp;
        this.$forceUpdate();
    }
}
</script>

<style scoped>
a {
    min-width: max-content !important;
}
</style>
