<template>
    <key-mapping
        ref="materialGroupKeyMapping"
        :object-sid="data.materialGroupSid"
        @close="closeEditScreen" />
</template>

<script lang="ts">
import { MaterialBaseModel, Tabbable } from '@/models';
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import KeyMapping from '@/components/view-details/key-mapping/key-mapping.vue';

@Component({
    name: 'material-group-key-mapping',
    components: {
        keyMapping: KeyMapping,
    },
})
export default class MaterialGroupKeyMapping extends Vue implements Tabbable {
    @Ref()
    private readonly materialGroupKeyMapping!: InstanceType<typeof KeyMapping>;
    /**
     * Power plant for which the key mapping tab from editing screen is displayed.
     */
    @Prop()
    private data!: MaterialBaseModel;

    public async beforeLeave(): Promise<boolean> {
        const isFormDirty = await this.materialGroupKeyMapping.isFormDirty();
        if (!isFormDirty) {
            return true;
        }

        try {
            await this.$dialog
                .confirm(
                    { body: this.$t('areYouSureLeaveUnsaved').toString() },
                    { view: 'confirm' });
            return true;
        } catch (error: any) {
            return Promise.resolve(false);
        }
    }

    private async closeEditScreen() {
        const canLeave = await this.beforeLeave();
        if (canLeave) {
            this.$emit('close');
        }
    }
}
</script>
