<template>
    <div class="md-filter">
        <div class="md-filter__header">
            <div class="md-filter__name">{{ $t('filterBy') }}</div>
            <div
                class="md-filter__close"
                @click="onClose">&times;</div>
        </div>
        <component
            v-for="filterOption in options"
            :key="filterOption.displayName"
            :is="filterComponentType(filterOption)"
            v-model="model[filterOption.requestFieldName]"
            :option="filterOption" />
        <div class="md-filter__footer">
            <button class="default-button md-filter__apply mb-1" @click="onApply">{{ $t('done') }}</button>
            <button class="default-button md-filter__reset" @click="onReset">{{ $t('resetFilter') }}</button>
        </div>
    </div>
</template>

<script lang="ts">
import MdFilterGroup from './md-filter-group/md-filter-group.vue';
import MdFilterCheckbox from './md-filter-checkbox/md-filter-checkbox.vue';
import { FilterGroup, FilterGroupType, MdFilterModel } from '@/models';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FilterQueryFactory } from '@/utils';

@Component({
    name: 'md-filter',
    components: {
        mdFilterOptions: MdFilterGroup,
        mdCheckboxInput: MdFilterCheckbox,
    },
})
export default class MdFilter extends Vue {
    @Prop({ required: true })
    private value!: MdFilterModel;

    @Prop({ default: () => [] })
    private options!: FilterGroup[];

    private model: MdFilterModel = FilterQueryFactory.mdFilterModel();

    public reset() {
        this.model = FilterQueryFactory.mdFilterModel();
        this.$emit('input', this.model);
    }

    @Watch('value')
    private onValueChange(newValue: MdFilterModel) {
        this.model = { ...newValue };
    }

    private mounted() {
        this.model = { ...this.value };
    }

    private filterComponentType(filterOption: FilterGroup) {
        switch (filterOption.type) {
            case FilterGroupType.OPTIONS:
                return 'md-filter-options';
            case FilterGroupType.BOOLEAN:
                return 'md-checkbox-input';
            default: return null;
        }
    }

    private onApply() {
        this.$emit('input', this.model);
        this.$emit('close');
    }

    private onReset() {
        this.reset();
    }

    private onClose() {
        this.$emit('close');
    }
}
</script>

<style lang="less" scoped>
@import "~@/variables.less";

.md-filter {
    width: 20rem;
    background-color: @white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
    position: absolute;
    top: 100%;
    right: 100%;
    z-index: @z-popover;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: @background-grey;
        padding: 1rem;
    }

    &__close {
        font-size: 1.8rem;
    }

    &__footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 1rem;
    }

    &__apply {
        display: block;
        background-color: @uniper-blue-dark;
        color: @white;
        font-family: @font-roboto-md;
    }

    &__reset {
        display: block;
        font-family: @font-roboto-md;
    }
}
</style>
