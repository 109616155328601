<template>
    <div class="container">
        <span v-for="(item, index) in items" :key="index"
             :class="generateClass(item)"
             @click="onClickItem(item)"
        >
            {{ $t(item.name) }}
        </span>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {PebbleTreeFather} from '@/models/tree/pebble-tree-father';
import {ManagementOption} from '@/models';
import {MANAGEMENT_OPTIONS} from '@/utils/management-options';

@Component({
    name: 'master-data-management-tree',
})

export default class MasterDataManagementTree extends Vue {
    private treeData: PebbleTreeFather = {nodes: []};

    private items: ManagementOption[] = MANAGEMENT_OPTIONS;

    private selected = this.items[0];

    public created() {
        this.items.forEach((value) => {
            this.treeData.nodes.push({
                title: this.$t(value.name).toString(),
                componentName: value.componentName,
            });
        });
    }

    private mounted(): void {
        const component = this.items.find((value: ManagementOption) => value.componentName === this.$route.name);
        this.selected = component ?? this.items[0];
    }

    private generateClass(data: ManagementOption): string {
        if (data.name.toLowerCase() === this.selected.name.toLowerCase()) {
            return 'item item-selected';
        }
        return 'item';
    }

    private onClickItem(selected: ManagementOption): void {
        this.selected = selected;
        this.$emit('on-click-item', this.selected);
    }
}
</script>

<style scoped>
.container {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.item {
    height: 50px;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    padding-left: 24px;
}

.item:hover {
    background-color: rgba(0, 120, 220, 0.25);
}

.item-selected {
    background-color: rgba(0, 120, 220, 0.25) !important;
    font-weight: 600 !important;
}
</style>
