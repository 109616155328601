var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.check.dataLoaded)?_c('pui-grid-container',{staticClass:"pui-grid-container-zero-padding"},[_c('pui-grid-row',{class:_vm.errors.length > 0 ? 'pebble-errors-list-margin-top' : ''},[_c('pebble-errors-list',{attrs:{"id":"errors-list","errors":_vm.errors}})],1),_c('pui-form',{attrs:{"aria-label":"Form"}},[_c('pui-grid-row',[_c('pui-grid-column',[_c('materials-identification-form',{attrs:{"data":_vm.formValues,"material-group-list":_vm.materialGroupList,"eu-ets-list":_vm.euEtsList,"agg-state-list":_vm.aggStateList,"quality-list":_vm.qualityList,"home-contry-list":_vm.homeContryList,"mine-list":_vm.mineList,"dehst-list":_vm.dehstList,"unit-settle-list":_vm.unitSettleList,"is-edit":true},on:{"validate":_vm.validate}})],1)],1)],1),_c('pui-grid-row',{staticClass:"pui-grid-row-little-margin-top"},[_c('pui-grid-column',{attrs:{"cols":{s:12, m:5, l:6, xl:6, xxl: 6}}},[_c('pebble-last-updated',{attrs:{"data":{
                    lastUpdatedBy: _vm.selectedMaterial.lastUpdatedBy,
                    lastUpdated: _vm.selectedMaterial.lastUpdated
                }}})],1),_c('pui-grid-column',{attrs:{"cols":{s:12, m:7, l:6, xl:6, xxl: 6}}},[_c('cancel-save-buttons',{attrs:{"cancel-button-is-disabled":_vm.check.saveButtonIsDisabled,"save-button-is-disabled":!_vm.hasMasterDataAdmin || _vm.check.saveButtonIsDisabled,"on-click-cancel-button":_vm.onClickCancelButton,"on-click-save-button":_vm.onClickSaveButton}})],1)],1)],1):(_vm.check.showError)?_c('pui-grid-container',[_c('pui-grid-row',[_c('pui-loader-error',{attrs:{"title":_vm.$t('errorTitleDataLoaded'),"message":_vm.$t('errorGettingMaterials'),"icon":"error-alert","buttons":[
                {
                    state: 'secondary',
                    label: _vm.$t('refresh'),
                    onClick: _vm.onClickRefreshButton,
                }
            ]}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }