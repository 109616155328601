var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pui-grid-container',{staticClass:"pui-grid-container-fix-margin"},[_c('pui-grid-row',[_c('master-data-management-header',{attrs:{"title":_vm.$t('manageOperationModes'),"data-loaded":_vm.dataLoaded}})],1),(_vm.dataLoaded)?_c('pui-grid-row',[_c('custom-table',{attrs:{"columns-key":['operationModeName', 'operationModeSid', 'isStdOperationMode', 'isActive', 'action'],"data":_vm.data,"sortable-key":['operationModeName', 'operationModeSid', 'isStdOperationMode', 'isActive'],"heading":_vm.heading,"per-page":10,"per-page-values":[10, 50, 100],"enable-search":"","enable-pagination":"","enable-limit":"","custom-template":['isStdOperationMode', 'isActive', 'action']},scopedSlots:_vm._u([{key:"custom_isStdOperationMode",fn:function(ref){
var data = ref.data;
return [(data)?_c('span',[_vm._v(_vm._s(_vm.$t('standardOperationMode')))]):_c('span')]}},{key:"custom_isActive",fn:function(ref){
var data = ref.data;
return [_c('boolean-text',{attrs:{"titles":{
                        textTrue: _vm.$t('active'),
                        textFalse: _vm.$t('inactive'),
                    },"value":data}})]}},{key:"custom_action",fn:function(ref){
                    var data = ref.data;
return [_c('pui-link',{attrs:{"title":_vm.$t('edit'),"icon":"edit"},on:{"click":function($event){return _vm.onClickEdit(data)}}})]}}],null,false,475908068)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }