import {MaterialAddRequest, MaterialBaseModel} from '@/models';
import {IdentificationMaterialsForm} from '@/models/form/identification-materials-form';

export const identificationMaterialsForm = (data?: (MaterialBaseModel | MaterialAddRequest)): IdentificationMaterialsForm => {
    return {
        materialSid: {
            value: (data as MaterialBaseModel)?.materialSid,
            isValid: true,
        },
        shortName: {
            value: data?.shortName,
            isValid: true,
            validator: (val: string) => val.length > 0,
        },
        materialName: {
            value: data?.materialName,
            isValid: true,
            validator: (val: string) => val.length > 0,
        },
        materialGroupSid: {
            value: data?.materialGroupSid ?? -1,
            isValid: true,
            isDropDown: true,
            validator: (val: number) => val.toString() !== '-1',
        },
        euEtsSid: {
            value: data?.euEtsSid ?? -1,
            isValid: true,
            isDropDown: true,
            validator: (val: number) => val.toString() !== '-1',
        },
        aggStateSid: {
            value: data?.aggStateSid ?? -1,
            isValid: true,
            isDropDown: true,
        },
        qualitySid: {
            value: data?.qualitySid ?? -1,
            isValid: true,
            isDropDown: true,
        },
        homeCountrySid: {
            value: data?.homeCountrySid ?? -1,
            isValid: true,
            isDropDown: true,
        },
        mineSid: {
            value: data?.mineSid ?? -1,
            isValid: true,
            isDropDown: true,
        },
        dehstSid: {
            value: data?.dehstSid ?? -1,
            isValid: true,
            isDropDown: true,
        },
        unitSettleSid: {
            value: data?.unitSettleSid ?? -1,
            isValid: true,
            isDropDown: true,
        },
        unitAdminSid: {
            value: data?.unitAdminSid ?? -1,
            isValid: true,
            isDropDown: true,
        },
        comments: {
            value: (data as MaterialBaseModel)?.lastUpdateComment,
            isValid: true,
        },
        isDeleted: {
            value: data?.isDeleted ?? false,
            isValid: true,
        },
        standardCalorificValue: {
            value: data?.standardCalorificValue,
            isValid: true,
        },
        standardDensity: {
            value: data?.standardDensity,
            isValid: true,
        },
        normDensity: {
            value: data?.normDensity,
            isValid: true,
        },
        co2EmissionFactor: {
            value: data?.co2EmissionFactor,
            isValid: true,
        },
        so2EmissionFactor: {
            value: data?.so2EmissionFactor,
            isValid: true,
        },
    };
};
