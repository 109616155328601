<template>
    <md-card
        icon="ic-unit-blue"
        :name="card.operationModeName"
        :sid="card.operationModeSid">
        <template v-slot:card-properties>
            <div class="flex-col operation-mode border-right mr-2 pr-2 center-vertically">
                <div>
                    <b>
                        <span v-if="card.isStdOperationMode">{{ $t('standardOperationMode') }}</span>
                    </b>
                </div>
            </div>
            <div class="flex-col status border-right mr-2 pr-2 center-vertically">
                <div>
                    <b>
                        <span v-if="card.isActive" class="color-green">{{ $t('active') }}</span>
                        <span v-else class="color-amber">{{ $t('inactive') }}</span>
                        <span v-if="card.isDeleted">, <span class="color-red">{{ $t('deleted') }}</span></span>
                    </b>
                </div>
            </div>
        </template>
        <template v-slot:action-buttons>
            <div class="settings">
                <adam-button
                    icon="ic-settings"
                    fab
                    :outlined.prop="false"
                    @click="edit()"
                    inverted/>
            </div>
        </template>
    </md-card>
</template>

<script lang="ts">

import { Component, Vue, Prop } from 'vue-property-decorator';
import { OperationModeListDto } from '@/models';
import MdCard from '@/components/cards/cards-list/md-card/md-card.vue';

/**
 * Card for displaying information about an Operation Mode.
 */
@Component({
    name: 'operation-mode-card-list',
    components: {
        mdCard: MdCard,
    },
})
export default class OperationModeCardList extends Vue {
    /**
     * Operation Mode of which details will be displayed as card content
     */
    @Prop()
    private card!: OperationModeListDto;

    private edit(): void {
        /**
         * Fired when the user clicks on the edit button
         */
        this.$emit('edit', this.card);
    }

    private remove(card: OperationModeListDto): void {
        /**
         * Fired when the user clicks on the remove button
         */
        this.$emit('remove', card);
    }
}

</script>
