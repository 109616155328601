import { ApiResponse, PowerPlantGroupVersion, PowerPlant, PowerPlantFleetVersion,
    PowerPlantOperatorVersion, PlantGroupAddRequest, PlantFleetAddRequest,
    PlantOperatorAddRequest, PowerPlantIdentification, GroupVersion,
    FleetVersion, OperatorVersion,
} from '@/models';

import { PowerPlantGetResponse } from '@/models/asset-master-data/power-plant-get-response';
import { EventBus } from '@/utils';
import { store } from '@/store/index';
import ErrorHandler from '@/utils/error-handler';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';

class PowerPlantService {
    private api = new SdkGenericApiHelper('powerplants');

    public async add(asset: PowerPlant): Promise<ApiResponse<PowerPlant>> {
        return (await this.api.post(asset)).data;
    }

    public async get(countryId?: number): Promise<ApiResponse<any>> {
        const query = countryId ? `countryId=${countryId}` : undefined;
        return (await this.api.get(query)).data;
    }

    public async getById(sid: number): Promise<ApiResponse<PowerPlant>> {
        return (await this.api.getByPathOrParam(sid)).data;
    }

    public async edit(powerPlant: PowerPlant): Promise<ApiResponse<PowerPlant>> {
        return (await this.api.put(`${powerPlant.locationSid}`, powerPlant))
            .data;
    }

    public async addGroup(powerPlantSid: number, powerPlantGroup: PlantGroupAddRequest): Promise<ApiResponse<PowerPlantGroupVersion>> {
        return (await this.api.post(powerPlantGroup, `${powerPlantSid}/groups`))
            .data;
    }

    public async getGroups(powerPlantSid: number): Promise<PowerPlantGroupVersion[]> {
        const { data: { result: { items } } } = (await this.api.getByPathOrParam(`${powerPlantSid}/groups`));
        return items;
    }

    public async updateGroup(powerPlantSid: number, groupValidFrom: string, powerPlantGroup: PlantGroupAddRequest):
        Promise<ApiResponse<PowerPlantGroupVersion>> {
        return (await this.api.put(`${powerPlantSid}/groups/from/${groupValidFrom}`, powerPlantGroup))
            .data;
    }

    public async removeGroup(powerPlantSid: number, { validFrom }: PowerPlantGroupVersion): Promise<ApiResponse<unknown>> {
        const { data: { result } } =
            (await this.api.delete(`${powerPlantSid}/groups/from/${validFrom}`));
        return result;
    }

    public async addFleet(powerPlantSid: number, powerPlantFleet: PlantFleetAddRequest): Promise<ApiResponse<PowerPlantFleetVersion>> {
        return (await this.api.post(powerPlantFleet, `${powerPlantSid}/fleets`)).data;
    }

    public async getFleets(powerPlantSid: number): Promise<PowerPlantFleetVersion[]> {
        const { data: { result: { items } } } =
            (await this.api.getByPathOrParam(`${powerPlantSid}/fleets`));
        return items;
    }

    public async updateFleet(powerPlantSid: number, fleetValidFrom: string, powerPlantFleet: PlantFleetAddRequest):
        Promise<ApiResponse<PowerPlantFleetVersion>> {
        return (await this.api.put(`${powerPlantSid}/fleets/from/${fleetValidFrom}`, powerPlantFleet))
            .data;
    }

    public async removeFleet(powerPlantSid: number, { validFrom }: PowerPlantFleetVersion): Promise<ApiResponse<unknown>> {
        const { data: { result } } =
            (await this.api.delete(`${powerPlantSid}/fleets/from/${validFrom}`));
        return result;
    }

    public async addOperator(powerPlantSid: number, powerPlantOperator: PlantOperatorAddRequest):
        Promise<ApiResponse<PowerPlantOperatorVersion>> {
        return (await this.api.post(powerPlantOperator, `${powerPlantSid}/operators`)).data;
    }

    public async getOperators(powerPlantSid: number): Promise<PowerPlantOperatorVersion[]> {
        const { data: { result: { items } } } =
            (await this.api.getByPathOrParam(`${powerPlantSid}/operators`));
        return items;
    }

    public async updateOperator(powerPlantSid: number,
                                operatorValidFrom: string,
                                powerPlantOperator: PlantOperatorAddRequest,
                               ): Promise<ApiResponse<PowerPlantOperatorVersion>> {
        return (
            await this.api.put(`${powerPlantSid}/operators/from/${operatorValidFrom}`, powerPlantOperator)
        ).data;
    }

    public async removeOperator(powerPlantSid: number,
                                { validFrom }: PowerPlantOperatorVersion,
                               ): Promise<ApiResponse<unknown>> {
        const { data: { result } } =
            (await this.api.delete(`${powerPlantSid}/operators/from/${validFrom}`));
        return result;
    }

    public async getPowerPlant(powerPlantSid: number): Promise<PowerPlantIdentification> {
        store.commit('loading');
        try {
            const result = (await this.getById(powerPlantSid))?.result;
            return new PowerPlantIdentification(result as unknown as PowerPlantGetResponse);
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingPowerPlant');
            return new PowerPlantIdentification();
        } finally {
            store.commit('loading');
        }
    }

    public async addPowerPlant(powerPlantIdentification: PowerPlantIdentification): Promise<boolean> {
        store.commit('loading');
        try {
            await this.add(
                PowerPlantIdentification.mapPowerPlantAddModel(powerPlantIdentification) as any,
            );
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingPowerPlant');
            return false;
        } finally {
            store.commit('loading');
        }
    }

    public async editPowerPlant(powerPlantIdentification: PowerPlantIdentification): Promise<boolean> {
        store.commit('loading');
        try {
            await this.edit(
                PowerPlantIdentification.mapPowerPlantEditModel(powerPlantIdentification) as any,
            );
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorEditingPowerPlant');
            return false;
        } finally {
            store.commit('loading');
        }
    }

    public async getPowerPlantGroups(powerPlantSid: number): Promise<GroupVersion[]> {
        store.commit('loading');
        try {
            return (await this.getGroups(powerPlantSid))
                .map((item) => new GroupVersion(item)) ?? [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingPowerPlantGroups');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async addPlantGroup(plantGroup: GroupVersion): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.addGroup(plantGroup.powerPlantSid,
                GroupVersion.mapGroupAddEditModel(plantGroup));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async editPlantGroup(plantGroup: GroupVersion, initialValidFrom: string): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.updateGroup(plantGroup.powerPlantSid,
                initialValidFrom,
                GroupVersion.mapGroupAddEditModel(plantGroup));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async deletePlantGroup(plantGroup: GroupVersion): Promise<boolean> {
        try {
            store.commit('loading');
            await this.removeGroup(
                plantGroup.powerPlantSid,
                { validFrom: plantGroup.validFrom } as PowerPlantGroupVersion,
            );
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorRemovingItem');
            return false;
        } finally {
            store.commit('loading');
        }
    }

    public async getPlantFleets(powerPlantSid: number): Promise<FleetVersion[]> {
        store.commit('loading');
        try {
            return (await this.getFleets(powerPlantSid))
                .map((item) => new FleetVersion(item)) ?? [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingFleets');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async deleteFleetVersion(fleetVersion: FleetVersion): Promise<boolean> {
        try {
            store.commit('loading');
            await this.removeFleet(
                fleetVersion.powerPlantSid,
                { validFrom: fleetVersion.validFrom } as PowerPlantFleetVersion,
            );
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorRemovingItem');
            return false;
        } finally {
            store.commit('loading');
        }
    }

    public async addPFleetVersion(fleetVersion: FleetVersion): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.addFleet(fleetVersion.powerPlantSid,
                FleetVersion.mapGroupAddEditModel(fleetVersion));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async editFleetVersion(fleetVersion: FleetVersion, initialValidFrom: string): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.updateFleet(fleetVersion.powerPlantSid,
                initialValidFrom,
                FleetVersion.mapGroupAddEditModel(fleetVersion));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async getOperatorVersions(powerPlantSid: number): Promise<OperatorVersion[]> {
        store.commit('loading');
        try {
            return (await this.getOperators(powerPlantSid))
                .map((item) => new OperatorVersion(item)) ?? [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingFleets');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async deleteOperatorVersion(operatorVersion: OperatorVersion): Promise<boolean> {
        try {
            store.commit('loading');
            await this.removeOperator(
                operatorVersion.powerPlantSid,
                { validFrom: operatorVersion.validFrom } as PowerPlantOperatorVersion,
            );
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorRemovingItem');
            return false;
        } finally {
            store.commit('loading');
        }
    }

    public async addPOperatorVersion(operatorVersion: OperatorVersion): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.addOperator(operatorVersion.powerPlantSid,
                OperatorVersion.mapGroupAddEditModel(operatorVersion));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async editOperatorVersion(operatorVersion: OperatorVersion, initialValidFrom: string): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.updateOperator(operatorVersion.powerPlantSid,
                initialValidFrom,
                OperatorVersion.mapGroupAddEditModel(operatorVersion));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }
}

const powerPlantService = new PowerPlantService();

export {
    PowerPlantService,
    powerPlantService,
};
