import { DateHelper } from '@/utils';
import { Unit } from '../unit';
export class UnitIdentification {
    public static mapUnitAddModel(unitIdentification: UnitIdentification): Unit {
        return {
            unitSid: unitIdentification.unitSid,
            parentSid: unitIdentification.parentSid,
            powerPlantSid: unitIdentification.powerPlantSid,
            identifier: unitIdentification.identifier,
            name: unitIdentification.name,
            description: unitIdentification.description,
            validFrom: DateHelper.formatDate(unitIdentification.validFrom),
            validTo: unitIdentification.validTo,
            enumObjectTypeSid: unitIdentification.enumObjectTypeSid,
            eicIdentification: unitIdentification.eicIdentification,
            eicCode: unitIdentification.eicCode,
            eicName: unitIdentification.eicName,
            remitCode: unitIdentification.remitCode,
            enumSapTypeSid: unitIdentification?.enumSapTypeSid,
            enumMbsTypeSid: unitIdentification?.enumMbsTypeSid,
            enumClassSid: unitIdentification?.enumClassSid,
            enumKksSid: unitIdentification?.enumKksSid,
            enumFleetSid: unitIdentification?.enumFleetSid,
            enumRiverSid: unitIdentification?.enumRiverSid,
            enumRiverGroupSid: unitIdentification?.enumRiverGroupSid,
            enumTechnologySid: unitIdentification?.enumTechnologySid,
            reservoirSid: unitIdentification?.reservoirSid,
            bnetzaNumber: unitIdentification?.bnetzaNumber,
            operatingLicence: unitIdentification?.operatingLicence,
            pmlIdentifier: unitIdentification.pmlIdentifier,
            pmlName: unitIdentification.pmlName,
            powerHeatLinkAble: unitIdentification.powerHeatLinkAble ?? false,
            eegAble: unitIdentification.eegAble ?? false,
            deleted: unitIdentification.deleted ?? false,
            lastUpdateComment: unitIdentification?.lastUpdateComment,
            isReactRelevant: unitIdentification.isReactRelevant ?? false,
            ewisId: unitIdentification.ewisId,
            lastUpdatedBy: unitIdentification.lastUpdatedBy,
            lastUpdated: DateHelper.formatDate(new Date()),
            heatRegionFromSid: unitIdentification?.heatRegionFromSid,
            heatRegionToSid: unitIdentification?.heatRegionToSid,
            incidentPenaltyId: unitIdentification?.incidentPenaltyId,
        } as Unit;
    }

    public unitSid: number;
    public parentSid: number;
    public powerPlantSid: number;
    public identifier: string;
    public name: string;
    public description: string;
    public validFrom: string;
    public validTo: string;
    public enumObjectTypeSid: number;
    public eicIdentification: string;
    public eicCode: string;
    public eicName: string;
    public remitCode: string;
    public enumSapTypeSid?: number;
    public enumMbsTypeSid?: number;
    public enumClassSid?: number;
    public enumKksSid?: number;
    public enumFleetSid?: number;
    public enumRiverSid?: number;
    public enumRiverGroupSid?: number;
    public enumTechnologySid?: number;
    public reservoirSid?: number;
    public bnetzaNumber?: string;
    public operatingLicence?: Date;
    public pmlIdentifier: string;
    public pmlName: string;
    public powerHeatLinkAble: boolean;
    public eegAble: boolean;
    public deleted: boolean;
    public lastUpdateComment?: string;
    public isReactRelevant: boolean;
    public ewisId: number;
    public lastUpdatedBy: string;
    public lastUpdated: string;
    public heatRegionFromSid?: number;
    public heatRegionToSid?: number;
    public incidentPenaltyId?: number;

    constructor(data?: any, powerPlantId?: number, unitParentId?: number) {
        this.unitSid = data?.unitSid;
        this.parentSid = unitParentId ?? data?.parentSid;
        this.powerPlantSid = powerPlantId ?? data?.powerPlantSid;
        this.identifier = data?.identifier;
        this.name = data?.name;
        this.description = data?.description;
        this.validFrom = data?.validFrom ?? DateHelper.formatDate(new Date());
        this.validTo = data?.validTo;
        this.enumObjectTypeSid = data?.enumObjectTypeSid;
        this.eicIdentification = data?.eicIdentification;
        this.eicCode = data?.eicCode;
        this.eicName = data?.eicName;
        this.remitCode = data?.remitCode;
        this.enumSapTypeSid = data?.enumSapTypeSid;
        this.enumMbsTypeSid = data?.enumMbsTypeSid;
        this.enumClassSid = data?.enumClassSid;
        this.enumKksSid = data?.enumKksSid;
        this.enumFleetSid = data?.enumFleetSid;
        this.enumRiverSid = data?.enumRiverSid;
        this.enumRiverGroupSid = data?.enumRiverGroupSid;
        this.enumTechnologySid = data?.enumTechnologySid;
        this.reservoirSid = data?.reservoirSid;
        this.bnetzaNumber = data?.bnetzaNumber;
        this.operatingLicence = data?.operatingLicence;
        this.pmlIdentifier = data?.pmlIdentifier;
        this.pmlName = data?.pmlName;
        this.powerHeatLinkAble = data?.powerHeatLinkAble;
        this.eegAble = data?.eegAble;
        this.deleted = data?.deleted;
        this.lastUpdateComment = data?.lastUpdateComment;
        this.ewisId = data?.ewisId;
        this.isReactRelevant = data?.isReactRelevant;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdated = data?.lastUpdated;
        this.heatRegionFromSid = data?.heatRegionFromSid;
        this.heatRegionToSid = data?.heatRegionToSid;
        this.incidentPenaltyId = data?.incidentPenaltyId;
    }
}
