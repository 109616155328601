import { MaterialGroupAddRequest } from '@/models';
import format from 'date-fns/format';
import { DATE_FORMAT } from '../constants';

export class MaterialGroupFactory {
    public static creatMaterialGroupAddRequest(): MaterialGroupAddRequest {
        return {
            materialGroupSid: 0,
            shortName: '',
            materialGroupName: '',
            enumSuMaterialGroupSid: null,
            enumMaterialTypeSid: null,
            isDeleted: false,
            lastUpdateComment: '',
            lastUpdatedBy: '',
            lastUpdated: format(new Date(), DATE_FORMAT),
        };
    }
}
