<template>
    <card>
        <pui-grid-container>
            <pui-grid-row>
                <back-text
                    :title="$t('hydroComponents.titles.new')"
                    :on-press="onBackPress"
                />
            </pui-grid-row>
            <pui-form aria-label="New Transformer Form">
                <pui-grid-row>
                    <pui-grid-column class="pui-grid-column-zero-padding">
                        <hydro-components-transformer-form
                            :data="data"
                            @validate="validate"
                        />
                    </pui-grid-column>
                </pui-grid-row>
                <error-list :errors="invalidMessages" />
                <pui-grid-row class="pui-grid-row--end" style="padding: 16px 0;">
                    <pui-button
                        state="secondary"
                        @click.prevent="onBackPress"
                    >
                        {{ $t('cancel') }}
                    </pui-button>
                    <pui-button
                        class="save-button"
                        @click.prevent="onSave"
                    >
                        {{ $t('save') }}
                    </pui-button>
                </pui-grid-row>
            </pui-form>
        </pui-grid-container>
    </card>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import BackText from '@/components/text/back-text.vue';
import Card from '@/components/cards/card.vue';
import FakeSingleTab from '@/components/tab-bar/fake-single-tab.vue';
import HydroComponentsTransformerForm from '@/components/forms/hydro-components-transformer-form.vue';
import { TransformerForm } from '@/models/form/transformer-form';
import { transformerForm } from '@/utils/pebble-form/hydro-components/transformer-form';
import { FormValuesModel } from '@/models/pebble/form-values-model';
import { TransformersService } from '@/services/transformers-service';
import { AddTransformersData } from '@/models/hydro-components/transformers-data';
import { EventBus, extractErrorsFromResponse } from '@/utils';
import errorList from '@/components/error-list/error-list.vue';
import { MAX_DATE } from '@/utils/constants';

@Component({
    components: {errorList, HydroComponentsTransformerForm, FakeSingleTab, Card, BackText}
})
export default class HydroComponentsNew extends Vue {
    private readonly transformersService = new TransformersService();

    private data: TransformerForm | null = null;
    private invalidMessages: string[] = [];

    private created(): void {
        this.data = transformerForm();
    }

    private get isFormInvalid(): boolean {
        if (this.data) {
            const validator: boolean = Object.keys(this.data).map((inputName) => {
                    const input: FormValuesModel = this.data?.[inputName];
                    if (input.validator) {
                        return input.validator(input.value, this.data);
                    }
                    return true;
                },
            ).every((value) => value);
            return !(validator);
        }

        return false;
    }

    private validate(value: string, name: string): void {
        const input: FormValuesModel = this.data?.[name];

        if (input.validator) {
            input.isValid = input.validator(input.value, this.data);
        } else {
            input.isValid = true;
        }
    }

    private validateAll(): void {
        if (!this.data) {
            return;
        }

        Object.keys(this.data).forEach((inputName) => {
            const input: FormValuesModel = this.data?.[inputName];

            this.validate(input.value, inputName);
        });
    }

    private parseNumber(value: string | undefined | null): number | undefined {
        if (value === '' || value === undefined || value === null) {
            return undefined;
        }

        return Number(value);
    }

    private parseDate(value: string): string | undefined {
        if (value === '') {
            return undefined;
        }

        return (new Date(value)).toISOString();
    }

    private async onSave(): Promise<void> {
        this.validateAll();

        if (this.isFormInvalid) {
            return;
        }

        this.invalidMessages = [];
        this.$store.commit('loading');

        try {
            if (!this.data) {
                this.$store.commit('loading');
                return;
            }

            const addRequest: AddTransformersData = {
                transformerSid: this.data.componentId.value,
                validFrom: this.parseDate(this.data.validFrom.value),
                validTo: MAX_DATE.toISOString(),
                deleted: this.data.isDeleted.value,
                transformerName: this.data.transformerName.value,
                plantSid: Number(this.data.powerPlant.value),
                technicalLocation: this.data.functionalLocation.value,
                manufacturerEnumSid: this.parseNumber(this.data.manufacturer.value),
                transformerTypeEnumSid: this.parseNumber(this.data.transformerType.value),
                constructionYear: this.parseNumber(this.data.constructionYear.value),
                nominalPower: this.parseNumber(this.data.nominalPower.value),
                primaryVoltageOs: this.parseNumber(this.data.primaryVoltageOs.value),
                secondaryVoltageOs: this.parseNumber(this.data.secondaryVoltageUs.value),
                coolingSystemEnumSid: this.parseNumber(this.data.coolingSystem.value),
                oilWeight: this.parseNumber(this.data.oilWeight.value),
                totalWeight: this.parseNumber(this.data.totalWeight.value),
                decommissioningDate: this.parseDate(this.data.decommissioningDate.value),
                equipmentNumber: this.parseNumber(this.data.equipment.value),
                isReserve: this.data.isReserve.value,
                storagePlantSid: this.parseNumber(this.data.storagePowerPlant.value),
            };

            const response = await this.transformersService.create(addRequest);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');

            this.$router.push({
                name: 'hydro-components-edit',
                query: {
                    transformerSid: response.result.transformerSid,
                },
            });
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'hydroComponents.toastMessages.failedToAddNewComponent');
            this.invalidMessages = extractErrorsFromResponse(err.response.data);
        } finally {
            this.$store.commit('loading');
        }
    }

    private onBackPress(): void {
        this.$router.push({
            name: 'hydro-components'
        });
    }
}
</script>

<style scoped lang="css">
.save-button {
    margin-left: 0.5rem;
}
</style>
