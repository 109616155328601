<template>
    <view-details
        :sid="data.estId"
        :name="data.shortName || $t('newEtsUnit')"
        icon="ic-settings">
        <tab-headers 
            :tabs="tabs"
            :active-tab="activeTab"
            @select-tab="selectTab" />
        <component
            :is="activeTab.component"
            :data="data"
            @close="closeEditScreen()"
            @save="saveChanges" />
    </view-details>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { GenericTab, EtsUnit } from '@/models';
import TabHeaders from '@/components/view-details/tab-headers/tab-headers.vue';
import ETSUnitIdentification from '@/components/view-details/ets-units/ets-units-identification/ets-units-identification.vue';
import ViewDetails from '@/components/view-details/view-details.vue';
import ETSUnitKeyMapping from './ets-unit-key-mapping/ets-unit-key-mapping.vue';

@Component({
    name: 'ets-units-details',
    components: {
        tabHeaders: TabHeaders,
        etsUnitsIdentification: ETSUnitIdentification,
        viewDetails: ViewDetails,
        unitKeyMapping: ETSUnitKeyMapping,
    },
})
export default class ETSUnitsDetails extends Vue {
    /**
     * ETS unit for which to show details.
     */
    @Prop({ required: true })
    private data!: EtsUnit;

    private tabs: GenericTab[] = [
        { name: 'identification', component: 'etsUnitsIdentification'},
        { name: 'keyMapping', component: 'unitKeyMapping' },
    ];
    private activeTab = this.tabs[0];

    private async mounted(): Promise<void> {
        if (this.$route.query.tab) {
            this.activeTab = this.tabs.find((x) => x.name === this.$route.query.tab.toString()) ?? this.tabs[0];
        }
    }

    private selectTab(tab: GenericTab) {
        this.$router.push({
            name: 'manage-option',
            params: {
                option: this.$route.params.option,
            },
            query: {
                ...this.$route.query,
                tab: tab.name.toString(),
            },
        });
        this.activeTab = tab;
    }

    private closeEditScreen(): void {
        this.$emit('close');
    }

    private saveChanges(item: EtsUnit, isAdd: boolean): void {
        this.$emit('save', item, isAdd, null);
    }
}
</script>
