import { ApiResponse, KeyMappingAddRequest, KeyMappingGetResponse,
    ListResponse, PaginationQueryModel, KeyMappingVersion,
} from '@/models';
import { EventBus } from '@/utils';
import { store } from '@/store/index';
import ErrorHandler from '@/utils/error-handler';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
import { generateQuery } from '@/utils/api-helper';

class KeyMappingService {
    private api = new SdkGenericApiHelper('system-mapping');

    public async getVersions(systemSid: number, objectSid: number):
        Promise<ApiResponse<ListResponse<KeyMappingGetResponse>>> {
        return (await this.api.get(undefined, `${systemSid}/objects/${objectSid}/versions`)).data;
    }

    public async addVersion(systemSid: number, keyMapping: KeyMappingAddRequest, objectSid: number):
        Promise<ApiResponse<KeyMappingGetResponse>> {
        return (await this.api.post(keyMapping, `${systemSid}/objects/${objectSid}/versions`))
            .data;
    }

    public async editVersion(keyMapping: KeyMappingAddRequest, initialValidFrom: string, objectSid: number):
        Promise<ApiResponse<any>> {
        return (await this.api.put(`${keyMapping?.enumStreamSystemSid}/objects/${objectSid}/versions/${initialValidFrom}`, keyMapping))
            .data;
    }

    public async removeVersion(systemSid: number, validFrom: string, objectSid: number): Promise<ApiResponse<any>> {
        return (await this.api.delete(`${systemSid}/objects/${objectSid}/versions/${validFrom}`))
            .data;
    }

    public async getAllKeyMappings(systemSid: number | undefined, filter: PaginationQueryModel) {
        if (systemSid === undefined) {
            return;
        }
        const query = generateQuery(filter as any);
        return (await this.api.get(query, `${systemSid}/objects`))
            .data;
    }

    public async getSystemMappingVersions(systemSid: number, powerPlantSid: number): Promise<KeyMappingVersion[]> {
        try {
            store.commit('loading');
            const temp = (await this.getVersions(
                systemSid,
                powerPlantSid,
            ))?.result
                ?.items;
            return temp?.map((item) => new KeyMappingVersion(item)) ?? [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingVersions');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async addSystemMappingVersion(keyMappingVersion: KeyMappingVersion, systemSid: number, powerPlantSid: number): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await (await this.addVersion(
                systemSid,
                KeyMappingVersion.mapKeyMappingAddEditModel(keyMappingVersion),
                powerPlantSid,
            ));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async editSystemMappingVersion(keyMappingVersion: KeyMappingVersion, initialValidFrom: string, powerPlantSid: number): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await (await this.editVersion(
                KeyMappingVersion.mapKeyMappingAddEditModel(keyMappingVersion),
                initialValidFrom,
                powerPlantSid,
            ));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async deleteSystemMappingVersion(keyMappingVersion: KeyMappingVersion): Promise<boolean> {
        try {
            store.commit('loading');
            await (await this.removeVersion(
                keyMappingVersion.enumStreamSystemSid,
                keyMappingVersion.validFrom,
                keyMappingVersion.objectSid,
            ));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorRemovingItem');
            return false;
        } finally {
            store.commit('loading');
        }
    }
}

const keyMappingService = new KeyMappingService();

export {
    KeyMappingService,
    keyMappingService,
};
