<template>
    <card>
        <pui-grid-container v-if="sid != null">
            <pui-grid-row>
                <back-text :title="sid" :on-press="onBackPress"/>
            </pui-grid-row>
            <pui-grid-row>
                <fake-single-tab
                    :title="$t('synergyLife')"
                />
            </pui-grid-row>
            <pui-grid-row class="pui-grid-row-little-margin-top">
                <custom-table
                    :data="dataTable"
                    :columns-key="columns"
                    :heading="heading"
                    :sortable-key="[]"
                    :per-page="10"
                    :per-page-values="[2, 5, 10]"
                    :enable-pagination="true"
                    :enable-limit="true"
                    :on-click-action="onClickAction"
                    :custom-template="['action']"
                >
                    <template v-slot:custom_action="{data}">
                        <pui-link
                            :title="action.title"
                            :icon="action.icon"
                            @click="onClickAction(data)"
                        />
                    </template>
                </custom-table>
            </pui-grid-row>
            <pui-form
                aria-label="Form"
                v-if="dataLoaded"
            >
                <pui-grid-row>
                    <pui-grid-column class="pui-grid-column-zero-padding">
                        <sinergy-life-master-data-form
                            :data="data"
                            @validate="validate"
                        />
                    </pui-grid-column>
                </pui-grid-row>
                <pui-grid-row>
                    <pui-grid-column class="pui-grid-column-zero-padding">
                        <sinergy-life-data-form
                            :data="data"
                            @validate="validate"
                        />
                    </pui-grid-column>
                </pui-grid-row>
                <error-list :errors="invalidMessages" />
                <pui-grid-row class="pui-grid-row--end pui-grid-row-little-margin-top">
                    <pui-button
                        v-if="hasMasterDataAdmin && !isNewVersion"
                        v-on:click="addVersion"
                        icon="add"
                        state="secondary"
                        class="sinergy-life-footer-button remove-version-button"
                    >
                        {{ $t('addNewVersion') }}
                    </pui-button>
                    <pui-button
                        v-if="hasMasterDataAdmin && !isNewVersion"
                        v-on:click="onRemove"
                        icon="delete"
                        state="secondary"
                        class="sinergy-life-footer-button remove-version-button"
                    >
                        {{ $t('removeVersion') }}
                    </pui-button>
                    <pui-button
                        v-if="isNewVersion"
                        state="secondary"
                        v-on:click="onCancel"
                        class="sinergy-life-footer-button remove-version-button">
                        {{ $t('cancel') }}
                    </pui-button>
                    <pui-button
                        v-on:click="onSave"
                        :disabled="!hasMasterDataAdmin"
                        class="sinergy-life-footer-button">
                        {{ $t('save') }}
                    </pui-button>
                </pui-grid-row>
            </pui-form>
        </pui-grid-container>
    </card>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Card from '@/components/cards/card.vue';
import BackText from '@/components/text/back-text.vue';
import SinergyLifeMasterDataForm from '@/components/forms/sinergy-life-master-data-form.vue';
import SinergyLifeDataForm from '@/components/forms/sinergy-life-data-form.vue';
import CustomTable from '@/components/table/custom-table.vue';
import ErrorList from '@/components/error-list/error-list.vue';
import {SinergyLifeEditData, SinergyLifeEditDataColumnKeys} from '@/models/sinergy-life-edit-data';
import ComponentSecurity from '@/mixins/component-security';
import {Mixins} from 'vue-property-decorator';
import {SinergyLifeForm} from '@/models/form/sinergy-life-form';
import {sinergylifeForm} from '@/utils/pebble-form/sinergy-life-location/sinergylife-form';
import {FormValuesModel} from '@/models/pebble/form-values-model';
import {ActionTableModel} from '@/models/table/action-table-model';
import { SynergyLifeService } from '@/services';
import { DateHelper, EventBus, extractErrorsFromResponse } from '@/utils';
import FakeSingleTab from '@/components/tab-bar/fake-single-tab.vue';

@Component({
    name: 'sinergy-life-location-edit',
    components: {
        errorList: ErrorList,
        FakeSingleTab,
        CustomTable, SinergyLifeDataForm, SinergyLifeMasterDataForm, BackText, Card,
    },
})
export default class SinergyLifeLocationEdit extends Mixins(ComponentSecurity) {
    private syngeryLifeService: SynergyLifeService = new SynergyLifeService();
    private sid = '';
    private slLocation = '';
    private columns = SinergyLifeEditDataColumnKeys;
    private dataTable: SinergyLifeEditData[] = [];
    private invalidMessages: string[] = [];
    private isNewVersion = false;

    private data: SinergyLifeForm = {
        sid: {value: '', isValid: true, validator: () => true},
        slCountry: {value: '', isValid: true, validator: () => true},
        uniperLocationName: {value: '', isValid: true, validator: () => true},
        enumObjectTypeSid: {value: '', isValid: true, validator: () => true},
        uniperLocationShort: {value: '', isValid: true, validator: () => true},
        uniperCountryShort: {value: '', isValid: true, validator: () => true},
        tableName: {value: '', isValid: true, validator: () => true},
    };
    private saveButtonIsDisabled = false;
    private dataLoaded = false;
    private queryDetails = {
        sid: 0,
        validFrom: '',
        slLocation: '',
    };

    get action(): ActionTableModel {
        return {
            title: 'View detail',
            icon: '',
        };
    }

    get heading(): any {
        return {
            validFrom: 'Valid from',
            validTo: 'Valid to',
            sid: 'SID',
            uniperLocationName: 'Sinergy Life Location',
            lastUpdated: 'Last updated',
            lastUpdatedBy: 'Last updated by',
            action: '',
        };
    }

    public async mounted(): Promise<void> {
        this.sid = this.$route.query.sid.toString();
        this.slLocation = this.$route.query.slLocation.toString();
        await this.init();
        await this.onClickAction(this.dataTable[0]);
    }

    private async init(): Promise<void> {
        this.$store.commit('loading');
        let data: SinergyLifeEditData[] = [];
        try {
            data = (await this.syngeryLifeService.getById({
                sid: +this.sid,
                slLocation: this.slLocation,
            })).result.items;
            data.forEach((versionData) => {
                versionData.validFrom = DateHelper.formatDate(versionData.validFrom);
                versionData.validTo = DateHelper.formatDate(versionData.validTo);
                versionData.lastUpdated = DateHelper.formatDate(versionData.lastUpdated);
            });
            this.dataTable = data;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingVersions');
        } finally {
            this.saveButtonIsDisabled = this.shouldDisable();
            this.$store.commit('loading');
            this.dataLoaded = true;
        }
    }

    private onBackPress(): void {
        this.$router.push({
            name: `sinergy-life-location`,
            path: 'sinergy-life-location',
        });
    }

    private async onCancel(): Promise<void> {
        this.invalidMessages = [];
        this.isNewVersion = false;
        await this.onClickAction(this.dataTable[0]);
    }

    private async onClickAction(data: SinergyLifeEditData): Promise<void> {
        this.$store.commit('loading');
        try {
            const versionData = (await this.syngeryLifeService.getByValidFrom({
                sid: this.sid,
                slLocation: data.slLocationSid,
                validFrom: data.validFrom,
            })).result;
            this.queryDetails.slLocation = versionData.slLocation;
            versionData.validFrom = DateHelper.formatDate(versionData.validFrom);
            versionData.validTo = DateHelper.formatDate(versionData.validTo);
            this.queryDetails.validFrom = versionData.validFrom;
            this.data = sinergylifeForm(versionData);
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingVersionDetails');
        } finally {
            this.$store.commit('loading');
        }
    }

    private validate(value: string, name: string): void {
        const input: FormValuesModel = this.data[name];
        if (input.validator) {
            input.isValid = input.validator(input.value);
        }
    }

    private shouldDisable(): boolean {
        const validator: boolean = Object.keys(this.data).map((inputName) => {
                const input: FormValuesModel = this.data?.[inputName];
                if (input.validator) {
                    return input.validator(input.value);
                }
                return true;
            },
        ).every((value) => value);
        return !(validator);
    }

    private async addVersion(): Promise<void> {
        this.invalidMessages = [];
        this.isNewVersion = true;
    }

    private async onRemove(): Promise<void> {
        this.invalidMessages = [];
        this.$store.commit('loading');
        try {
            await this.syngeryLifeService.remove({
                sid: this.sid,
                slLocation: this.slLocation,
                validFrom: this.data.validFrom?.value,
            });
            this.$router.push({
                name: 'sinergy-life-location-edit',
                query: {
                    sid: this.sid,
                    slLocation: this.slLocation,
                },
            });
            await this.init();
            await this.onClickAction(this.dataTable[0]);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'successfullyRemoved');
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorRemovingVersion');
            this.invalidMessages = extractErrorsFromResponse(err.response.data);
        } finally {
            this.$store.commit('loading');
        }
    }

    private async onSave(): Promise<void> {
        this.invalidMessages = [];
        this.$store.commit('loading');
        try {
            this.queryDetails.sid = this.data.sid?.value;
            this.queryDetails.slLocation = this.slLocation;
            const editRequest = {
                sid: this.data.sid?.value,
                validFrom: this.data.validFrom?.value,
                existingSlLocation: this.slLocation?.toString(),
                slCountry: this.data.slCountry?.value,
                slLocation: this.data.slLocation?.value,
                slLocationSid: this.slLocation,
                objectTypeSid: +this.data.enumObjectTypeSid?.value,
                tableName: this.data.tableName?.value,
                uniperCountryShort: this.data.uniperCountryShort?.value,
                uniperLocationShort: this.data.uniperLocationShort?.value,
                slLocationLastLevel: this.data.slLocationLastLevel?.value,
                slLocationLevel1: this.data.slLocationLevel1?.value,
                slLocationLevel2: this.data.slLocationLevel2?.value,
                slLocationLevel3: this.data.slLocationLevel3?.value,
                slLocationLevel4: this.data.slLocationLevel4?.value,
                uniperLocationName: this.data.uniperLocationName?.value,
                locationType: this.data.locationType?.value,
                lastUpdateComment: this.data.lastUpdateComment?.value,
                isDeleted: this.data.isDeleted?.value,
                validTo: this.data.validTo?.value,
            };

            if (!this.isNewVersion) {
                await this.syngeryLifeService.edit(this.queryDetails, editRequest);
            } else {
                await this.syngeryLifeService.create(editRequest);
                this.isNewVersion = false;
            }

            await this.init();

            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            this.$router.push({
                name: 'sinergy-life-location-edit',
                query: {
                    sid: this.sid,
                    slLocation: this.slLocation,
                },
            });
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorUpdatingSynergyLife');
            this.invalidMessages = extractErrorsFromResponse(err.response.data);
        } finally {
            this.$store.commit('loading');
        }
    }
}
</script>

<style scoped>

.remove-version-button {
    margin-right: 16px;
}

</style>
