import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';

/**
 * Collection of helper methods for dates.
 */
export class DateHelper {
    /**
     * Static method to format dates as ISO strings without time.
     * @name formatDate
     * @param {string} dateString
     * @returns {string} Formatted date string.
     */
    public static formatDate(dateString: string | Date): string {
        const date = new Date(dateString);
        const monthPart = date.getMonth() + 1;
        const datePart = date.getDate();
        const monthString = `${monthPart < 10 ? `0${monthPart}` : monthPart}`;
        const dateDayString = `${datePart < 10 ? `0${datePart}` : datePart}`;
        return `${date.getFullYear()}-${monthString}-${dateDayString}`;
    }

    /**
     * Static method to format time hh:mm from a date string;
     * @param dateString date string yyyy-MM-dd format
     * @returns {string} formatted time.
     */
    public static formatTime(dateString: string): string {
        const date = new Date(dateString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const hoursString = hours < 10 ? `0${hours}` : hours;
        const minutesString = minutes < 10 ? `0${minutes}` : minutes;
        return `${hoursString}:${minutesString}`;
    }

    /**
     * Static method to format dates as ISO strings with time.
     * @name formatDateTime
     * @param {string} dateString
     * @returns {string} Formatted date string.
     */
    public static formatDateTime(dateString: string | Date): string {
        const date = new Date(dateString);
        const monthPart = date.getMonth() + 1;
        const datePart = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const monthString = `${monthPart < 10 ? `0${monthPart}` : monthPart}`;
        const dateDayString = `${datePart < 10 ? `0${datePart}` : datePart}`;
        const hoursString = hours < 10 ? `0${hours}` : hours;
        const minutesString = minutes < 10 ? `0${minutes}` : minutes;
        return `${date.getFullYear()}-${monthString}-${dateDayString} ${hoursString}:${minutesString}`;
    }

    /**
     * Check if the current time is inside a given time interval
     * @param {Date} start - interval start date
     * @param {Date} end - interval end date
     */
    public static isNowBetween(start: Date, end: Date) {
        const now = new Date();

        return startOfDay(start) <= now && endOfDay(end) >= now;
    }
}
