<template>
    <div class="md-confirm">
        <template>
            <h4 class="md-confirm__title">{{ messageHasTitle ? messageTitle : options.messageTitle }}</h4>
        </template>

        <div class="md-confirm__content">{{ messageBody }}</div>

        <div class="md-confirm__actions flex-row">
            <pui-button state="secondary" @click="handleCancel">{{ options.cancelText }}</pui-button>
            <pui-button @click="handleConfirm" class="md-confirm__confirm-button">{{ options.okText }}</pui-button>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from 'vue-property-decorator';
import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js';

/**
 * A custom component to be used with vuejs-dialog, with the confirm method.
 * Since component registration is performed by vuejs-dialog, the translations
 * are not passed and therefore cannot be used inside this components, so the
 * option properties for title and button texts are mandatory.
 */
@Component({
    name: 'md-confirm',
})
export default class MdConfirm extends Mixins(Vue, DialogMixin) {
    private handleCancel() {
        this.cancel();
    }
    private handleConfirm() {
        this.proceed();
    }
}
</script>

<style lang="less">
@import "~@/variables.less";

.dg-backdrop {
    background: rgba(0,0,0,0.5) !important;
}

.dg-main-content {
    padding: 2.4rem 2.4rem !important;
    width: 38.4rem;
}

.md-confirm {
    &__title {
        color: @black;
        margin-top: -0.2rem;
    }

    &__content {
        padding: 0.6rem 0 1.2rem 0;
        font-size: 1em;
        color: @black;
    }

    &__actions {
        padding-top: 1.1rem;
    }

    &__confirm-button {
        margin-left: 1.6rem;
    }
}
</style>
