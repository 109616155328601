<template>
    <pui-grid-container class="pui-grid-container-zero-padding">
        <pui-grid-row>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-text-field
                    type="date"
                    name="validFrom"
                    :model="data.validFrom"
                    :required="true"
                    :disable="disabled"
                    :label="$t('validFrom')"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-text-field
                    v-if="data.validTo.value"
                    type="date"
                    name="validTo"
                    :model="data.validTo"
                    :required="true"
                    :disable="true"
                    :label="$t('validUntil')"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-text-field
                    name="componentId"
                    :model="data.componentId"
                    :required="true"
                    :disable="disabled || isEditMode"
                    :label="$t('hydroComponents.labels.componentId')"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-drop-down-select
                    v-if="data.componentType"
                    name="componentType"
                    :required="true"
                    :label="$t('hydroComponents.labels.componentType')"
                    :model="data.componentType"
                    :options="componentTypeOptions"
                    :disable="true"
                    @on-input="validate"
                    @on-blur="(value, name) => validate(value, name, true)"
                />
            </pui-grid-column>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-drop-down-select
                    v-if="data.powerPlant"
                    name="powerPlant"
                    :required="true"
                    :disable="isStoragePowerPlantEnabled || disabled"
                    :label="$t('hydroComponents.labels.powerPlant')"
                    :model="data.powerPlant"
                    :options="powerPlantOptions"
                    @on-input="validate"
                    @on-blur="(value, name) => validate(value, name, true)"
                />
            </pui-grid-column>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-drop-down-select
                    v-if="isStoragePowerPlantEnabled"
                    name="storagePowerPlant"
                    :required="true"
                    :label="$t('hydroComponents.labels.storagePowerPlant')"
                    :model="data.storagePowerPlant"
                    :options="powerPlantOptions"
                    :disable="disabled"
                    @on-input="validate"
                    @on-blur="(value, name) => validate(value, name, true)"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-text-field
                    name="transformerName"
                    :model="data.transformerName"
                    :required="true"
                    :disable="disabled"
                    :label="$t('hydroComponents.labels.transformerName')"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-text-field
                    name="functionalLocation"
                    :model="data.functionalLocation"
                    :disable="disabled"
                    :label="$t('hydroComponents.labels.functionalLocation')"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-drop-down-select
                    v-if="data.equipment"
                    name="equipment"
                    :label="$t('hydroComponents.labels.equipment')"
                    :model="data.equipment"
                    :disable="disabled"
                    :options="createOptionsFromAssets(equipments)"
                    @on-input="validate"
                    @on-blur="(value, name) => validate(value, name, true)"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-drop-down-select
                    v-if="data.manufacturer"
                    name="manufacturer"
                    :label="$t('hydroComponents.labels.manufacturer')"
                    :model="data.manufacturer"
                    :disable="disabled"
                    :options="createOptionsFromAssets(manufacturers)"
                    @on-input="validate"
                    @on-blur="(value, name) => validate(value, name, true)"
                />
            </pui-grid-column>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-drop-down-select
                    v-if="data.transformerType"
                    name="transformerType"
                    :label="$t('hydroComponents.labels.transformerType')"
                    :model="data.transformerType"
                    :disable="disabled"
                    :options="createOptionsFromAssets(transformerTypes)"
                    @on-input="validate"
                    @on-blur="(value, name) => validate(value, name, true)"
                />
            </pui-grid-column>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-text-field
                    name="constructionYear"
                    :model="data.constructionYear"
                    :required="true"
                    :disable="disabled"
                    :label="$t('hydroComponents.labels.constructionYear')"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-text-field
                    name="nominalPower"
                    :model="data.nominalPower"
                    :disable="disabled"
                    :label="$t('hydroComponents.labels.nominalPower')"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-text-field
                    name="primaryVoltageOs"
                    :model="data.primaryVoltageOs"
                    :disable="disabled"
                    :label="$t('hydroComponents.labels.primaryVoltageOs')"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-text-field
                    name="secondaryVoltageUs"
                    :model="data.secondaryVoltageUs"
                    :disable="disabled"
                    :label="$t('hydroComponents.labels.secondaryVoltageUs')"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-drop-down-select
                    v-if="data.coolingSystem"
                    name="coolingSystem"
                    :label="$t('hydroComponents.labels.coolingSystem')"
                    :model="data.coolingSystem"
                    :disable="disabled"
                    :options="createOptionsFromAssets(coolingSystems)"
                    @on-input="validate"
                    @on-blur="(value, name) => validate(value, name, true)"
                />
            </pui-grid-column>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-text-field
                    name="oilWeight"
                    :model="data.oilWeight"
                    :disable="disabled"
                    :label="$t('hydroComponents.labels.oilWeight')"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-text-field
                    name="totalWeight"
                    :model="data.totalWeight"
                    :disable="disabled"
                    :label="$t('hydroComponents.labels.totalWeight')"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-text-field
                    type="date"
                    name="decommissioningDate"
                    :model="data.decommissioningDate"
                    :disable="disabled"
                    :label="$t('hydroComponents.labels.decommissioningDate')"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-check-box
                    name="deleted"
                    :label="$t('hydroComponents.labels.isDeleted')"
                    :disabled="disabled"
                    :model="data.isDeleted"
                    @on-change="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="smallItemFormCols">
                <pebble-check-box
                    name="reserve"
                    :label="$t('hydroComponents.labels.isReserve')"
                    :disabled="disabled"
                    :model="data.isReserve"
                    @on-change="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import PebbleTextField from '@/components/pebble-form-element/pebble-text-field.vue';
import { PebbleColConst } from '@/mixins/pebble-col-const';
import { TransformerForm } from '@/models/form/transformer-form';
import PebbleCheckBox from '@/components/pebble-form-element/pebble-check-box.vue';
import { EventBus } from '@/utils';
import PebbleDropDownSelect from '@/components/pebble-form-element/pebble-drop-down-select.vue';
import { PebbleDropDown } from '@/models/pebble/pebble-drop-down';
import { AssetService, PowerPlantService } from '@/services';
import { PowerPlantOverview } from '@/models/power-plant-overview';
import { HydroComponentsLocationTypes } from '@/models/enum-location-type';
import { Asset, IdTypeSid } from '@/models';

@Component({
    components: {PebbleDropDownSelect, PebbleCheckBox, PebbleTextField}
})
export default class HydroComponentsTransformerForm extends Mixins(PebbleColConst) {
    @Prop({ required: true })
    private data!: TransformerForm;

    @Prop({ default: () => false })
    private disabled!: boolean;

    @Prop({ default: () => false })
    private isEditMode!: boolean;

    private readonly RESERVE_PLANT_SID = 24676;

    private readonly powerPlantService = new PowerPlantService();
    private readonly assetService = new AssetService();

    private powerPlants: PowerPlantOverview[] = [];
    private manufacturers: Asset[] = [];
    private coolingSystems: Asset[] = [];
    private transformerTypes: Asset[] = [];
    private equipments: Asset[] = [];

    private mounted(): void {
        this.loadInitialData().then();
    }

    private get componentTypeOptions(): PebbleDropDown[] {
        return [
            {
                label: this.$t('hydroComponents.transformer') as string,
                value: 'transformer'
            }
        ];
    }

    private get powerPlantOptions(): PebbleDropDown[] {
        return this.powerPlants.map(e => ({
            label: e.locationName,
            value: e.locationSid,
            secondaryLabel: e.locationShort,
        }));
    }

    private get isStoragePowerPlantEnabled(): boolean {
        return this.data.isReserve.value;
    }

    private createOptionsFromAssets(assets: Asset[]): PebbleDropDown[] {
        return assets.map(e => ({
            label: e.name,
            value: e.sid,
            secondaryLabel: e.description,
        }));
    }

    private async loadInitialData(): Promise<void> {
        this.$store.commit('loading');

        try {
            await Promise.allSettled([
                this.loadPowerPlants(),
                this.loadAssetTypeItems(IdTypeSid.EQUIPMENT_CLASS_TYPE).then(data => this.equipments = data),
                this.loadAssetTypeItems(IdTypeSid.TRANSFORMER_MANUFACTURER).then(data => this.manufacturers = data),
                this.loadAssetTypeItems(IdTypeSid.TRANSFORMER_TYPE).then(data => this.transformerTypes = data),
                this.loadAssetTypeItems(IdTypeSid.COOLING_SYSTEM).then(data => this.coolingSystems = data),
            ]);
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'hydroComponents.toastMessages.failedToLoadDataForForm');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async loadPowerPlants(): Promise<void> {
        const response = await this.powerPlantService.get();
        const results: PowerPlantOverview[]  = response.result?.data ?? [];

        this.powerPlants = results.filter(e => HydroComponentsLocationTypes.includes(e.enumLocationTypeSid));
    }

    private async loadAssetTypeItems(typeSid: IdTypeSid): Promise<Asset[]> {
        const response = await this.assetService.getByIdTypeSid(typeSid, {size: 999, term: '', page: 1});
        return response?.result?.items ?? [];
    }

    private validate(value: string, name: string, isBlurEvent = false): void {
        this.$emit('validate', value, name);
        this.$forceUpdate();
    }

    @Watch('data.isReserve.value', { deep: true })
    private handleReserveChange(newIsReserve: boolean, oldIsReserve: boolean): void {
        if (newIsReserve === oldIsReserve) {
            return;
        }

        if (newIsReserve) {
            this.data.powerPlant.value = this.RESERVE_PLANT_SID;
        } else {
            this.data.powerPlant.value = null;
        }

        this.data.storagePowerPlant.value = null;
        this.validate(this.data.storagePowerPlant.value, 'storagePowerPlant')
    }
}
</script>
