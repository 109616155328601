<template>
    <md-card
        icon=""
        :name="card.assetName"
        :sid="card.assetId">
        <template v-slot:card-properties>
            <div class="flex-col asset-type border-right mr-2 pr-2 center-vertically">
                <div>
                    <b>{{ card.assetType }}</b>
                </div>
            </div>
            <div class="flex-col key-mapping border-right mr-2 pr-2 center-vertically">
                <div>
                    <b>{{ card.keyMapping }}</b>
                </div>
            </div>
            <div class="flex-col active border-right mr-2 pr-2 center-vertically">
                <div>
                    <b>
                        <span v-if="!card.isActive" class="color-amber">N</span>
                        <span v-else class="color-green">Y</span>
                    </b>
                </div>
            </div>
            <div class="flex-col valid-from border-right mr-2 pr-2 center-vertically">
                <div>
                    <div>
                        <b>{{ formatDateTime(card.validFrom) }}</b>
                    </div>
                </div>
            </div>
            <div class="flex-col valid-to border-right mr-2 pr-2 center-vertically">
                <div>
                    <div>
                        <b>{{ formatDateTime(card.validTo) }}</b>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:action-buttons>
            <adam-button
                icon="ic-settings"
                fab
                :outlined.prop="false"
                @click="edit()"
                inverted/>
        </template>
    </md-card>
</template>

<script lang="ts">

import { Component, Prop, Mixins } from 'vue-property-decorator';
import { DateHelper } from '@/utils';
import MdCard from '@/components/cards/cards-list/md-card/md-card.vue';
import ComponentSecurity from '@/mixins/component-security';
import { KeyMappingListingItem } from '@/models';

@Component({
    name: 'key-mapping-card-list',
    components: {
        mdCard: MdCard,
    },
})
export default class KeyMappingCardList extends Mixins(ComponentSecurity) {
    /*
     * Card information which will be displayed.
    */
    @Prop({ required: true })
    private card!: KeyMappingListingItem;

    private edit(): void {
        this.$emit('edit', this.card);
    }

    private formatDateTime(date: string): string {
        return DateHelper.formatDate(date);
    }
}

</script>
