<template>
    <div class="system-asset-card mb-1 mt-1 ml-1 mr-1">
        <span class="flex-row asset-name">
            <span>{{ $t('name') }}</span>
            <span v-if="card.assetName">: {{ card.assetName }}</span>
            <span v-else>: {{ $t('n/a') }}</span>
        </span>
        <span class="flex-row asset-details">
            <span>{{ $t('assetID') }}</span>
            <span v-if="card.assetId !== null || card.assetId !== undefined">
                : {{ card.assetId }}
            </span>
            <span v-else>: {{ $t('n/a') }}</span>
        </span>
    </div>
</template>

<script lang="ts">
import ComponentSecurity from '@/mixins/component-security';
import { StreamSystemAssetItem } from '@/models/stream-system-item';
import { Mixins, Component, Prop } from 'vue-property-decorator';
@Component({
    name: 'stream-system-asset-card-list',
})
export default class StreamSystemAssetCardList extends Mixins(ComponentSecurity) {
     /*
     * Card information which will be displayed.
    */
    @Prop({ required: true })
    private card!: StreamSystemAssetItem;
}
</script>

<style scoped lang='less'>
@import '~@/variables.less';
.system-asset-card {
    padding: 1rem;
    border-radius: @border-radius-standard;
    box-shadow: 0 1px 4px 0 rgba(@black, 0.22);
    cursor: pointer;
    vertical-align: middle;
    .asset-name {
        font-size: 1.6rem;
        color: @uniper-blue;
    }
    .asset-details {
        margin-top: 0.5rem;
        font-size: 1.4rem;
        color: black;
    }
}
</style>
