import i18n from '@/utils/i18n';

export const AssetTypeOptions = [
    {
        displayName: i18n.t('fleet') as string,
        value: 1270,
    },
    {
        displayName: i18n.t('powerPlant') as string,
        value: 1290,
    },
    {
        displayName: i18n.t('riverGroup') as string,
        value: 1550,
    },
    {
        displayName: i18n.t('unit') as string,
        value: 500,
    },
    {
        displayName: i18n.t('powerPlantGroup') as string,
        value: 1300,
    },
];

export const TableNameOptions = [
    {
        label: i18n.t('locationMaster') as string,
        value: 'LOCATION_MASTER',
    },
    {
        label: i18n.t('objectMaster') as string,
        value: 'OBJECT_MASTER',
    },
    {
        label: i18n.t('enumlistMaster') as string,
        value: 'ENUMLIST_MASTER',
    },
];

export default {AssetTypeOptions, TableNameOptions};
