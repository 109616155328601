import { DateHelper } from '@/utils/date-helper';

export class OperationalTimelineVersion {
    public static mapToAddEditRequest(data: any): any {
        return {
            unitSid: data?.unitSid,
            validFrom: data?.validFrom,
            validTo: data?.validTo,
            operatorId: data?.operatorId,
            contractualOperaterId: data?.contractualOperatorId,
            lastUpdateComment: data?.lastUpdateComment,
        };
    }

    public static mapToAddEditModel(data: any): any {
        return {
            unitSid: data?.unitSid,
            validFrom: data?.validFrom ?? DateHelper.formatDate(new Date()),
            validTo: data?.validTo,
            operatorId: data?.operatorId,
            contractualOperatorId: data?.contractualOperatorId,
            lastUpdateComment: data?.lastUpdateComment,
        };
    }

    public unitSid?: number;
    public validFrom: string;
    public validTo?: string;
    public contractualOperatorId: number;
    public contractualOperatorName: string;
    public operatorId?: number;
    public operatorName?: string;
    public lastUpdatedBy?: string;
    public lastUpdated?: string;
    public lastUpdateComment?: string;

    public constructor(data?: any, isCopyOperation = false) {
        this.unitSid = data?.unitSid;
        this.validFrom = data?.validFrom ?? DateHelper.formatDate(new Date());
        this.validTo = data?.validTo;
        this.contractualOperatorId = isCopyOperation
            ? data?.contractualOperatorId
            : data?.contractualOperaterId;
        this.contractualOperatorName = data?.contractualOperaterName;
        this.operatorId = data?.operatorId;
        this.operatorName = data?.operatorName;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdated = data?.lastUpdated;
        this.lastUpdateComment = data?.lastUpdateComment;
    }
}
