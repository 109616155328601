import { MasterDataTreeItem, NodeTypeSid, Unit, IdTypeSid, NodeType } from '@/models';
import format from 'date-fns/format';
import { DATE_FORMAT } from '../constants';

export class UnitFactory {
    public static create(defaults = { powerPlantSid: 0, powerPlantName: '' }): Unit {
        return {
            unitSid: 0,
            ewisId: 0,
            powerPlantSid: defaults.powerPlantSid,
            parentSid: 0,
            identifier: '',
            name: '',
            description: '',
            enumObjectTypeSid: NaN,
            eicIdentification: '',
            eicCode: '',
            eicName: '',
            remitCode: '',
            bnetzaNumber: '',
            pmlIdentifier: '',
            pmlName: '',
            powerHeatLinkAble: false,
            isReactRelevant: false,
            eegAble: false,
            deleted: false,
            validFrom: format(new Date(), DATE_FORMAT),
            validTo: '2999-12-31T00:00:00.000',
            lastUpdatedBy: '',
        };
    }

    public static createMasterDataTreeItem(source?: Unit): MasterDataTreeItem {
        return {
            name: source ? source.name : 'New Unit',
            isOpen: false,
            isFocused: false,
            guid: null,
            icon: {
                src: 'ic-unit-blue',
                alt: '',
                width: '',
                height: '',
            },
            displayOrder: 0,
            sid: source ? source.unitSid : 0,
            nodeLevel: 0,
            nodeLevelConv: '',
            nodeType: NodeType.MACHINE,
            idTypeSid: IdTypeSid.UNIT_TYPE,
            deleted: source ? source.deleted : false,
            children: [],
            childrenIdTypeSid: 0,
            filters: [],
            enumNodeTypeSid: NodeTypeSid.MACHINE,
            lastUpdated: source && source.lastUpdated ? source.lastUpdated : '',
            abbreviation: '',
        };
    }
}
