import {ObjectKeys} from '@/models/object-keys';
import {Asset, MaterialGroupModel, OpModeProduct} from '@/models';
import {firstUpperCase} from '@/utils/utils';

export interface PebbleDropDown extends ObjectKeys {
    label: string;
    secondaryLabel?: string;
    value: any;
}

export const pebbleDropDownFromAssets = (data: Asset[]): PebbleDropDown[] => {
    const temp: PebbleDropDown[] = [{label: '--', value: -1}];
    temp.push(...data.map((value) => {
        return {
            label: value.name === value.description ? `${firstUpperCase(value.name)}` : `${value.name} - ${value.description}`,
            value: value.sid,
        };
    }));
    return temp;
};

export const pebbleDropDownFromOpModeProducts = (data: OpModeProduct[]): PebbleDropDown[] => {
    const temp: PebbleDropDown[] = [{label: '--', value: -1}];
    temp.push(...data.map((value) => {
        return {
            label: value.name === value.description ? `${firstUpperCase(value.name)}` : `${value.name} - ${value.description}`,
            value: value.sid,
        };
    }));
    return temp;
};

export const pebbleDropDownFromMaterialGroups = (data: MaterialGroupModel[]): PebbleDropDown[] => {
    const temp: PebbleDropDown[] = [{label: '--', value: -1}];
    temp.push(...data.map((value) => {
        return {
            label: value.materialGroupName,
            value: value.materialGroupSid,
        };
    }));
    return temp;
};
