<template>
    <div class="material-base-management flex-col full-height">
        <div v-show="!selectedMaterial" class="flex-row material-base-details">
            <div class="flex flex-4">
                <div class="flex-col flex-1">
                    <md-filter-tags
                        v-model="filterMaterials"
                        @input="onFilterChange"
                    />
                </div>
            </div>
            <div class="flex-col">
                <div class="flex-row">
                    <div class="mr-1 pt-1 pb-1 center-align">
                        <search-component v-model="searchTerm" @input="onSearchTermChange"/>
                    </div>
                    <div
                        class="filter mr-1 ml-1 center-align"
                        v-click-outside="closeFilterContainer">
                        <adam-button
                            icon="ic-filter"
                            @click="onFilterButtonClick()"
                            secondary>
                            {{ $t('filter') }}
                            <span v-if="isFilterApplied" class="ml-1">({{ $t('on') }})</span>
                        </adam-button>
                        <fade-transition :fast="true">
                            <md-filter
                                v-if="showFilter"
                                v-model="filterMaterials"
                                :options="filterOptions"
                                ref="filter"
                                @input="onFilterChange"
                                @close="closeFilterContainer" />
                        </fade-transition>
                    </div>
                    <div class="mr-2 center-align">
                        <adam-button
                            v-if="shouldShowAddButton && hasMasterDataAdmin"
                            class="mr-1 mt-1"
                            icon="ic-add-dashboard"
                            @click="addNewEntity()"
                            secondary>
                            {{ $t('addNew') }}
                        </adam-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-area" id="view-items">
            <div v-if="!materialDetails.length" class="mt-2 ml-2">
                {{ $t('noDataToShow') }}
            </div>
            <div v-else-if="!selectedMaterial"
                class="cards-display">
                <sort-header
                    v-if="headerColumn.length"
                    :headerColumns="headerColumn"
                    :objectToSort="materialDetails"
                    @get-sorted-list="setSortedLocalMaterialDetails"
                ></sort-header>
                <card-list>
                    <template v-slot:cards>
                        <div v-for="unit of materialDetails"
                            :key="unit.materialSid"
                            class="card">
                            <material-base-card-list
                                :card="unit"
                                @edit="selectUnit"
                            />
                        </div>
                    </template>
                </card-list>
                <div v-if="hasNextPage" class="flex-row mt-2" >
                    <div class="flex-col load-more">
                        <adam-button
                            secondary
                            @click="calculateScroll()"
                        >
                            {{ $t('loadMore') }}
                        </adam-button>
                    </div>
                </div>
            </div>
            <div v-else-if="selectedMaterial"
                class="edit-screen">
                <material-base-details
                    :data="selectedMaterial"
                    @close="closeDetailsView()"
                    @save="saveChanges()"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import MaterialBaseCardList from '@/components/cards/cards-list/material-base-card-list/material-base-card-list.vue';
import CardList from '@/components/cards/cards-list/cards-list.vue';
import SearchComponent from '@/components/search-component/search-component.vue';
import ViewDetails from '@/components/view-details/view-details.vue';
import MaterialBaseDetails from '@/components/view-details/material-base/material-base-details.vue';
import clickOutside from '@/directives/click-outside';
import FadeTransition from '@/components/transitions/fade-transition/fade-transition.vue';
import MdFilterTags from '@/components/md-filter/md-filter-tags/md-filter-tags.vue';
import MdFilter from '@/components/md-filter/md-filter.vue';
import { InfiniteScrollingHelper, EventBus } from '@/utils';
import { FilterGroup, GenericTab, MaterialAddRequest, MaterialBaseModel,
MdFilterModel } from '@/models';
import { filterService, MaterialBaseService } from '@/services';
import { throttle } from 'lodash';
import { Component, Watch, Mixins } from 'vue-property-decorator';
import ComponentSecurity from '@/mixins/component-security';
import { FilterQueryFactory, MaterialBaseFactory } from '@/utils/factories';
import { MaterialsQueryModel } from '@/models/filter';
import SortHeader from '@/views/sort-header/sort-header.vue';


const SCROLL_THROTTLE_MILLISECONDS = 500;

@Component({
    name: 'material-base-management',
    components: {
        searchComponent: SearchComponent,
        cardList: CardList,
        viewDetails: ViewDetails,
        materialBaseCardList: MaterialBaseCardList,
        materialBaseDetails: MaterialBaseDetails,
        fadeTransition: FadeTransition,
        mdFilterTags: MdFilterTags,
        mdFilter: MdFilter,
        sortHeader: SortHeader,
    },
    directives: {
        clickOutside,
    },
})

export default class MaterialBaseManagement extends Mixins(ComponentSecurity) {
    private materialBaseService: MaterialBaseService = new MaterialBaseService();
    private selectedMaterial: MaterialBaseModel | MaterialAddRequest | null = null;
    private materialDetails: MaterialBaseModel[] = [];
    private routeMaterialID: string | undefined;
    private filterOptions: FilterGroup[] = [];
    private searchTerm = '';
    private isLoadingNextPage = false;
    private hasNextPage = false;
    private isNewUnit = false;
    private showFilter = false;
    private filterMaterials: MdFilterModel = FilterQueryFactory.mdFilterModel();
    private onScroll = throttle(this.calculateScroll, SCROLL_THROTTLE_MILLISECONDS);
    private filterModel: MaterialsQueryModel = {
        AggStateSid: [],
        EuEtsSid: [],
        DehstSid: [],
        includeDeleted: false,
        page: 1,
        size: 10,
        term: '',
        sortDirection: '',
        sortColumn: '',
    };
    private tabs: GenericTab[] = [
        { name: 'identification', component: 'estUnits'},
    ];
    private activeTab = this.tabs[0];
    private sortOrder = 'asc';
    private sortKey = 'name';
    private headerColumn: Array<{
        attributeName: string;
        name: string;
        itemCssClassName: string;
    }> = [];

    @Watch('$route.query.item')
    private async onQueryItemChanged(newValue: string | undefined, oldValue: string | undefined) {
        if (newValue === oldValue) {
            return;
        }

        if (!newValue) {
            this.routeMaterialID = undefined;
            if (!this.materialDetails.length) {
                await this.getMaterials();
            }
            this.addEventListener();
            return;
        }
        this.routeMaterialID = newValue;
        this.removeEventListener();
        await this.assignRouteMaterial();
    }

    private async mounted(): Promise<void> {
        await this.getMaterials();
        this.addEventListener();
        this.initSearchAndFilter();
        this.headerColumn = [
            {
                attributeName: 'materialName',
                name: this.$t('materialName').toString(),
                itemCssClassName: 'mbm-short-name',
            },
            {
                attributeName: 'materialSid',
                name: this.$t('sid').toString(),
                itemCssClassName: 'mbm-sid',
            },
            {
                attributeName: 'shortName',
                name: this.$t('shortName').toString(),
                itemCssClassName: 'mbm-material-name',
            },
            {
                attributeName: 'materialGroupName',
                name: this.$t('materialGroup').toString(),
                itemCssClassName: 'mbm-material-group',
            },
            {
                attributeName: 'euEts',
                name: this.$t('euEts').toString(),
                itemCssClassName: 'mbm-eu-ets',
            },
            {
                attributeName: 'dehst',
                name: this.$t('dehst').toString(),
                itemCssClassName: 'mbm-dehst',
            },
            {
                attributeName: 'lastUpdated',
                name: this.$t('lastUpdated').toString(),
                itemCssClassName: 'mbm-last-updated',
            },
            {
                attributeName: 'lastUpdatedBy',
                name: this.$t('lastUpdatedBy').toString(),
                itemCssClassName: 'mbm-last-updated-by',
            },
            {
                attributeName: 'isDeleted',
                name: this.$t('deleted').toString(),
                itemCssClassName: 'mbm-is-deleted',
            },
        ];

        if (!this.$route.query.item) {
            await this.loadFilteredMaterial();
            this.addEventListener();
            return;
        }
        this.routeMaterialID = this.$route.query.item?.toString();
        await this.assignRouteMaterial();
        this.removeEventListener();
    }

    private beforeDestroy(): void {
        this.removeEventListener();
    }

    private addEventListener(): void {
        const element = document.getElementById('view-items');
        if (element) {
            element.addEventListener('scroll', this.onScroll);
            element.addEventListener('wheel', this.onScroll);
        }
    }

    private removeEventListener(): void {
        const element = document.getElementById('view-items');
        if (element) {
            element.removeEventListener('scroll', this.onScroll);
            element.removeEventListener('wheel', this.onScroll);
        }
    }

    private calculateScroll(): void {
        if (this.selectedMaterial) {
            return;
        }
        InfiniteScrollingHelper.calculateScroll(document.getElementById('view-items'), this.getNextPage, 200);
    }

    private onFilterButtonClick(): void {
        this.showFilter = !this.showFilter;
    }

    private get isSearchOrFilterApplied(): boolean {
        return this.searchTerm.length > 0 || this.isFilterApplied;
    }

    private get shouldShowAddButton(): boolean {
        return this.isSearchOrFilterApplied ? false : true;
    }

    private async onSearchTermChange(): Promise<void> {
        await this.getMaterials();
        this.addEventListener();
    }

    private async assignRouteMaterial() {
        if (this.materialDetails?.length && this.routeMaterialID) {
            this.selectedMaterial =
                this.materialDetails.find(
                    (x) => this.routeMaterialID && x.materialSid === +this.routeMaterialID,
                ) ?? null;
            if (!this.selectedMaterial) {
                this.searchTerm = this.routeMaterialID;
                this.materialDetails = await this.loadFilteredMaterial();
                this.selectedMaterial =
                this.materialDetails.find(
                    (x) => this.routeMaterialID && x.materialSid === +this.routeMaterialID,
                ) ?? null;
            }
        } else {
            this.selectedMaterial = null;
        }
    }

    private async loadFilteredMaterial(): Promise<MaterialBaseModel[]> {
        this.$store.commit('loading');
        let materialDetails: MaterialBaseModel[] = [];
        this.filterModel = {
            AggStateSid: this.filterMaterials.aggState.map((aggState) => aggState.value),
            EuEtsSid: this.filterMaterials.euEts.map((euEts) => euEts.value),
            DehstSid: this.filterMaterials.dehst.map((dehst) => dehst.value),
            includeDeleted: this.filterMaterials.includeDeleted,
            page: this.filterModel.page,
            size: 10,
            term: this.searchTerm,
            sortDirection: this.filterModel.sortDirection,
            sortColumn: this.filterModel.sortColumn,
        };
        try {
            const { result: { items, hasNextPage } } = (await this.materialBaseService.get(this.filterModel));
            this.hasNextPage = hasNextPage;
            materialDetails = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingMaterials');
        } finally {
            this.$store.commit('loading');
        }
        return materialDetails;
    }

    private async onFilterChange(): Promise<void> {
        await this.getMaterials();
        this.addEventListener();
    }

    private closeFilterContainer(): void {
        this.showFilter = false;
    }

    private get isFilterApplied(): boolean {
        return Object.values(this.filterMaterials).some((value: any) => value.length || value === true);
    }

    private async initSearchAndFilter() {
        try {
            this.filterOptions = await filterService.getMaterialsFilter();
        } catch (error: any) {
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingFilterData');
            throw(error);
        }
    }

    private async closeDetailsView(): Promise<void> {
        this.selectedMaterial = null;
        if (this.isFilterApplied) {
            return;
        }
        if (!this.isNewUnit && !this.isFilterApplied) {
            this.$router.push({
                name: 'manage-option',
                params: {
                    option: 'material-base-management',
                },
            });
        }
        this.isNewUnit = false;
    }

    private addNewEntity(): void {
        this.selectedMaterial = MaterialBaseFactory.createMaterialAddRequest();
        this.isNewUnit = true;
    }

    private async searchCards(searchString: string): Promise<void> {
        this.filterModel.term = searchString;
        await this.getMaterials();
    }

    private async getMaterials(): Promise<void> {
        this.filterModel.page = 1;
        this.materialDetails = await this.loadFilteredMaterial();
        await this.assignRouteMaterial();
    }

    private async getNextPage(): Promise<void> {
        this.removeEventListener();
        if (this.isLoadingNextPage) {
            return;
        }

        if (!this.hasNextPage) {
            return;
        }
        this.filterModel.page++;
        this.isLoadingNextPage = true;
        const materialDetails = await this.loadFilteredMaterial();
        this.materialDetails.push(...materialDetails);
        await this.$nextTick();
        this.addEventListener();
        this.isLoadingNextPage = false;
    }

    private async saveChanges(material: MaterialBaseModel, isAdd: boolean): Promise<void> {
        if (isAdd) {
            return;
        }
        await this.getMaterials();
        this.addEventListener();
        this.closeDetailsView();
    }

    private selectUnit(material: MaterialBaseModel): void {
        this.selectedMaterial = {...material};
        if (this.$route.path === '/master-data-management/material-base-management' &&
            this.$route.query.item === this.selectedMaterial.materialSid.toString()) {
            return;
        }

        this.$router.push({
            name: 'manage-option',
            params: {
                option: this.$route.params.option,
            },
            query: {
                item: this.selectedMaterial.materialSid.toString(),
            },
        });
    }

    private async setSortedLocalMaterialDetails(obj: {
        sortedList: MaterialBaseModel[];
        order: string;
        sortKey: string;
    }): Promise<void>  {

        const oldpg = this.filterModel.page;
        this.filterModel.size *= oldpg;
        this.filterModel.page = 1;
        if (obj.order === 'asc') {
            this.filterModel.sortDirection = 'Ascending';
        } else {
            this.filterModel.sortDirection = 'Descending';
        }
        this.filterModel.sortColumn = obj.sortKey;

        try {
            this.materialDetails = await this.loadFilteredMaterial();
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingMaterials');
        }
        this.filterModel.size = 10;
    }
}

</script>

<style lang="less">
@import "~@/variables.less";
.material-base-management {
    .filter {
        position: relative;
        padding-bottom: 0.5rem;
    }
    .search, .filter {
        align-self: center;
    }
    .material-base-details {
        background: @white;
        border-bottom: 2px solid @grey-lighter;
    }
    .main-area {
        padding: 2.5rem;
        box-sizing: border-box;
        overflow-y: auto;
        height: 100%;
        .name {
            width: 20rem;
        }
        .sid {
            width: 10rem;
        }
        .deleted {
            width: 4rem;
        }
        .material-group {
            width: 14rem;
        }
        .material-name {
            width: 15rem
        }
        .euets {
            width: 12rem;
        }
        .dehst {
            width: 12rem;
        }
        .last-updated {
            width: 12rem;
        }
        .last-updated-by {
            width: 10rem;
        }
    }
    .header-row {
        &__item {
            padding-right: 6rem;
        }
        &__item-mbm-sid,
        &__item-mbm-short-name,
        &__item-mbm-material-name,
        &__item-mbm-material-group,
        &__item-mbm-is-deleted,
        &__item-mbm-eu-ets,
        &__item-mbm-dehst,
        &__item-mbm-last-updated,
        &__item-mbm-last-updated-by {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            box-sizing: border-box;
            border-left: 2px solid @lighter-grey;
            padding: 0 1rem;
        }

        &__item-mbm-short-name {
            border-left: none;
            width: 14rem;
        }
        &__item-mbm-sid{
            width: 12rem;
        }
        &__item-mbm-material-name {
            width: 17rem;
        }
        &__item-mbm-material-group {
            width: 16rem;
        }
        &__item-mbm-last-updated-by {
            width: 12rem;
        }
        &__item-mbm-eu-ets {
            width: 14rem;
        }
        &__item-mbm-dehst, &__item-mbm-last-updated {
            width: 14rem;
        }
        &__item-mbm-is-deleted {
            width: 10rem;
        }
    }
}
</style>
