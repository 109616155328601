<template>
    <card>
        <pui-grid-container v-if="check.dataLoaded">
            <card-header
                :title="name"
                :chip-title="sid"
                :on-click-back-button="onBackPress"
            />
            <pui-grid-row>
                <fake-single-tab
                    :title="$t('identification')"
                />
            </pui-grid-row>
            <pui-grid-row
                :class="errors.length > 0 ? 'pebble-errors-list-margin-top' : ''"
            >
                <pebble-errors-list
                    id="errors-list"
                    :errors="errors"
                />
            </pui-grid-row>
            <pui-form
                aria-label="Form"
            >
                <enum-types-identification-form
                    :data="formValues"
                    @validate="validate"
                />
            </pui-form>
            <cancel-save-buttons
                :on-click-cancel-button="onBackPress"
                :on-click-save-button="onClickSaveButton"
                :save-button-is-disabled="!hasMasterDataAdmin || check.saveButtonIsDisabled"
            />
        </pui-grid-container>
        <pui-grid-container v-else-if="check.showError">
            <card-header
                :title="name"
                :chip-title="sid"
                :on-click-back-button="onBackPress"
            />
            <pui-grid-row>
                <pui-loader-error
                    :title="$t('errorTitleDataLoaded')"
                    :message="$t('errorGettingAssets')"
                    icon="error-alert"
                    :buttons="[
                    {
                        state: 'secondary',
                        label: $t('refresh'),
                        onClick: onClickRefreshButton,
                    }
                ]"
                />
            </pui-grid-row>
        </pui-grid-container>
    </card>
</template>

<script lang="ts">
import {Mixins} from 'vue-property-decorator';
import ComponentSecurity from '@/mixins/component-security';
import Component from 'vue-class-component';
import {AssetService} from '@/services';
import Card from '@/components/cards/card.vue';
import BackText from '@/components/text/back-text.vue';
import {Asset} from '@/models';
import {EventBus} from '@/utils';
import {identificationEnumTypesForm} from '@/utils/pebble-form/master-data-management/identification-enum-types-form';
import {IdentificationEnumTypesForm} from '@/models/form/identification-enum-types-form';
import {generateDataFromFormValues, generateErrors, isValidInput, manageResolve, shouldDisableForm} from '@/utils/utils';
import FakeSingleTab from '@/components/tab-bar/fake-single-tab.vue';
import CancelSaveButtons from '@/components/buttons/cancel-save-buttons.vue';
import CardHeader from '@/components/cards/card-header.vue';
import EnumTypesIdentificationForm from '@/components/forms/enum-types-identification-form.vue';
import PebbleErrorsList from '@/components/error-list/pebble-errors-list.vue';
import {PebbleNotification} from '@/models/pebble/pebble-notification';
import {CheckModel} from '@/models/check-model';

@Component({
    name: 'management-enum-types-edit',
    components: {
        PebbleErrorsList,
        EnumTypesIdentificationForm,
        CardHeader,
        CancelSaveButtons,
        FakeSingleTab,
        BackText,
        Card,
    },
})
export default class ManagementEnumTypesEdit extends Mixins(ComponentSecurity) {
    /* VARIABLES */
    private service: AssetService = new AssetService();
    private errors: PebbleNotification[] = [];
    private sid?: string = '';
    private name?: string = '';
    private check: CheckModel = {
        dataLoaded: false,
        showError: false,
        saveButtonIsDisabled: true,
    };

    /* LATE VARIABLES */
    private formValues!: IdentificationEnumTypesForm;
    private selectedAsset?: Asset;

    /* PRIMITIVE METHODS */
    private mounted(): void {
        this.$router.beforeResolve((to, from, next) => manageResolve(!this.check.saveButtonIsDisabled, next, this));
        this.sid = this.$route.query['edit']?.toString();
        this.name = this.$route.query['name']?.toString() ?? this.$t('unknown');
        this.init();
    }

    private destroyed(): void {
        this.check.saveButtonIsDisabled = true;
    }

    /* METHODS */
    private async init(): Promise<void> {
        this.check.showError = false;
        this.check.dataLoaded = false;
        this.$store.commit('loading');
        const asset = await this.loadAsset();
        this.selectedAsset = asset;
        this.formValues = identificationEnumTypesForm(asset);
        this.check.dataLoaded = true;
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedAsset);
    }

    private validate(value: string, name: string): void {
        isValidInput(name, this.formValues[name]);
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedAsset);
    }

    /* ON CLICK BUTTONS */
    private onClickRefreshButton(): void {
        this.init();
    }

    private onBackPress(): void {
        this.$router.back();
    }

    private onClickSaveButton(): void {
        this.saveData();
    }

    /* API CALLS */
    private async loadAsset(): Promise<Asset | undefined> {
        try {
            const {result: item} = await this.service.getById(+`${this.sid}`);
            return item;
        } catch (err: any) {
            this.check.showError = true;
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveAsset(asset: Asset): Promise<boolean> {
        this.$store.commit('loading');
        try {
            await this.service.edit(asset);
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorEditingAsset');
            generateErrors(this.errors, error);
            return false;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveData(): Promise<void> {
        if (await this.saveAsset(generateDataFromFormValues(this.formValues) as Asset)) {
            this.check.saveButtonIsDisabled = true;
            this.onBackPress();
        }
    }

}
</script>


