import {IdentificationReservoirForm} from '@/models/form/identification-reservoir-form';
import {Reservoir, ReservoirAddRequest} from '@/models';
import {format} from 'date-fns';
import {MIN_DATE, ONLY_DATE_FORMAT} from '@/utils/constants';
import {ReservoirModel} from '@/models/reservoir';


export const identificationReservoirForm = (data?: Reservoir | ReservoirModel | ReservoirAddRequest): IdentificationReservoirForm => {
    return {
        validFrom: {
            value: data?.validFrom ? format(new Date(data?.validFrom), ONLY_DATE_FORMAT) : '',
            isValid: true,
            isDate: true,
            validator: (value: string) => new Date(value) > MIN_DATE,
        },
        validTo: {
            value: (data as Reservoir)?.validTo ? format(new Date((data as Reservoir)?.validTo), ONLY_DATE_FORMAT) : '',
            isValid: true,
            isDate: true,
        },
        enumRiverGroupSid: {
            value: (data as ReservoirModel)?.enumRiverGroupSid ?? -1,
            isValid: true,
            isDropDown: true,
            validator: (value: number) => value.toString() !== '-1',
        },
        enumRiverSid: {
            value: (data as ReservoirModel)?.enumRiverSid ?? -1,
            isValid: true,
            isDropDown: true,
            validator: (value: number) => value.toString() !== '-1',
        },
        reservoirName: {
            value: (data as ReservoirModel)?.reservoirName,
            isValid: true,
            validator: (value: string) => value?.length > 0,
        },
        reservoirSid: {
            value: data?.reservoirSid,
            isValid: true,
        },
        eqvFactor: {
            value: (data as ReservoirModel)?.eqvFactor,
            isValid: true,
        },
        lastUpdated: {
            value: (data as Reservoir)?.lastUpdated,
            isValid: true,
            isDate: true,
        },
        lastUpdatedBy: {
            value: (data as Reservoir)?.lastUpdatedBy,
            isValid: true,
        },
        lastUpdateComment: {
            value: (data as Reservoir)?.lastUpdateComment,
            isValid: true,
        },
    };
};
