<template>
    <div v-if="model">
        <pui-form-group
            :label="title"
            :isValid="model.isValid"
            :show-required-label="required"
        >
            <pui-form-checkbox
                :label="label"
                :isValid="model.isValid"
                v-model="model.value"
                @change="change"
                :is-disabled="disabled"
            />
        </pui-form-group>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {FormValuesModel} from '@/models/pebble/form-values-model';

@Component({
    name: 'pebble-check-box',
})
export default class PebbleCheckBox extends Vue {
    @Prop({required: true})
    private name!: string;

    @Prop({required: true})
    private model!: FormValuesModel;

    @Prop({required: true})
    private label!: string;

    @Prop({default: ''})
    private title!: string;

    @Prop({default: false})
    private required!: boolean;

    @Prop()
    private disabled!: boolean;

    private change(val: boolean): void {
        this.$emit('on-change', val, this.name);
        this.$forceUpdate();
    }
}
</script>


