var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pui-grid-container',{staticClass:"pui-grid-container-zero-padding"},[_c('pui-grid-row',[_c('pui-grid-column',{attrs:{"cols":{s:12, m:12, l:6, xl:6, xxl: 6}}},[_c('pebble-drop-down-select',{attrs:{"name":"selectExternalSystem","model":_vm.model,"label":_vm.$t('selectExternalSystem'),"options":_vm.options},on:{"on-input":_vm.onClickExternalSystem}})],1)],1),(_vm.check.dataLoaded)?_c('pui-grid-row',{staticClass:"pui-grid-row-little-margin-top"},[_c('pui-grid-column',{attrs:{"cols":{ s:12,m:12,l:12,xl:12,xxl:12 }}},[_c('custom-table',{attrs:{"columns-key":['validFrom','validTo','keyMapping','isActive','isDeleted', 'lastUpdated','lastUpdatedBy', 'action'],"data":_vm.keyMappings,"sortable-key":['validFrom','validTo','keyMapping','isActive','isDeleted', 'lastUpdated','lastUpdatedBy'],"heading":_vm.heading,"per-page":5,"enable-pagination":"","custom-template":['isActive', 'isDeleted', 'action']},scopedSlots:_vm._u([{key:"custom_isActive",fn:function(ref){
var data = ref.data;
return [_c('boolean-text',{attrs:{"titles":{
                            textTrue: _vm.$t('active'),
                            textFalse: _vm.$t('inactive'),
                        },"value":data}})]}},{key:"custom_isDeleted",fn:function(ref){
                        var data = ref.data;
return [_c('boolean-text',{attrs:{"titles":{
                            textTrue: _vm.$t('yes'),
                            textFalse: _vm.$t('no'),
                        },"value":data}})]}},{key:"custom_action",fn:function(ref){
                        var data = ref.data;
return [_c('pui-grid-container',{staticClass:"pui-grid-container-zero-padding"},[_c('pui-grid-row',{staticClass:"pui-grid-row--around"},[_c('pui-link',{attrs:{"title":_vm.$t('edit'),"icon":"edit"},on:{"click":function () { return _vm.onClickEditButton(data); }}}),_c('pui-link',{attrs:{"title":_vm.$t('remove'),"icon":"delete"},on:{"click":function () { return _vm.onClickDeleteButton(data); }}})],1)],1)]}}],null,false,3928247779)})],1),_c('pui-grid-column',{staticClass:"pui-grid-col--end pui-grid-row-little-margin-top"},[_c('pui-button',{attrs:{"icon":"add","state":"secondary"},on:{"click":_vm.onClickAddNewVersionButton}},[_vm._v(" "+_vm._s(_vm.$t('addNewVersion'))+" ")])],1),_c('pui-lightbox',{ref:"modalForm",attrs:{"default-footer-confirm-label":_vm.$t('save'),"default-footer-cancel-label":_vm.$t('cancel'),"show-lightbox-close-icon":false,"closeOnESC":false,"fit-content":true,"default-footer-confirm-disabled":!_vm.hasMasterDataAdmin || _vm.check.saveButtonIsDisabled,"on-cancel-callback":_vm.onClickCancelButton,"on-confirm-callback":_vm.onClickSaveButton}},[_c('pui-grid-container',[_c('pui-grid-row',[_c('pui-grid-column',{class:_vm.errors.length > 0 ? 'pebble-errors-list-margin-top' : ''},[_c('pebble-errors-list',{attrs:{"id":"errors-list","errors":_vm.errors}})],1),_c('pui-grid-column',[_c('pui-form',{attrs:{"aria-label":"Form"}},[_c('key-mapping-data-form',{attrs:{"data":_vm.formValues,"add-new":_vm.check.addNewVersion},on:{"validate":_vm.validate}})],1)],1)],1)],1)],1)],1):(_vm.check.showError)?_c('pui-grid-row',{staticClass:"pui-grid-row-little-margin-top"},[_c('pui-grid-column',{attrs:{"cols":{ s:12,m:12,l:12,xl:12,xxl:12 }}},[_c('pui-loader-error',{attrs:{"title":_vm.$t('errorTitleDataLoaded'),"message":_vm.$t('errorLoadingVersions'),"icon":"error-alert","buttons":[
                    {
                        state: 'secondary',
                        label: _vm.$t('refresh'),
                        onClick: function () { return _vm.onClickRefreshButton(); }
                    }
                ]}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }