var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"identification-container"},[_c('validation-observer',{ref:"validationObserver"},[_c('div',{staticClass:"identification"},[(_vm.data.materialGroupSid)?_c('div',{staticClass:"flex-row manage-input mt-3"},[_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"materialGroupSid"}},[_vm._v(_vm._s(_vm.$t('materialGroupSid'))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.materialGroupSid),expression:"data.materialGroupSid"}],staticClass:"default-input",attrs:{"placeholder":_vm.$t('materialGroupSid'),"id":"etsUnitId","disabled":""},domProps:{"value":(_vm.data.materialGroupSid)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "materialGroupSid", $event.target.value)}}})]):_vm._e(),_c('validation-provider',{staticClass:"manage-input mt-3",attrs:{"tag":"div","name":"shortName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"flex-row manage-input mt-3"},[_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"shortName"}},[_vm._v(_vm._s(_vm.$t('shortName'))+"*:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.shortName),expression:"data.shortName"}],staticClass:"default-input",class:{ 'invalid': failed },attrs:{"placeholder":_vm.$t('shortName'),"id":"shortName","maxlength":"20"},domProps:{"value":(_vm.data.shortName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "shortName", $event.target.value)}}})]),_c('error-list',{attrs:{"errors":errors}})]}}])}),_c('validation-provider',{staticClass:"manage-input mt-3",attrs:{"tag":"div","name":"materialGroupName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"flex-row manage-input mt-3"},[_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"materialGroupName"}},[_vm._v(_vm._s(_vm.$t('materialGroupName'))+"*:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.materialGroupName),expression:"data.materialGroupName"}],staticClass:"default-input",class:{ 'invalid': failed },attrs:{"placeholder":_vm.$t('materialGroupName'),"id":"materialGroupName","maxlength":"50"},domProps:{"value":(_vm.data.materialGroupName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "materialGroupName", $event.target.value)}}})]),_c('error-list',{attrs:{"errors":errors}})]}}])}),_c('validation-provider',{staticClass:"manage-input mt-3",attrs:{"tag":"div","name":"enumSuMaterialGroup","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"enumSuMaterialGroup"}},[_vm._v(_vm._s(_vm.$t('enumSuMaterialGroup'))+"*:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.enumSuMaterialGroupSid),expression:"data.enumSuMaterialGroupSid"}],staticClass:"default-select",class:{ 'invalid': failed },attrs:{"id":"enumSuMaterialGroup","placeholder":_vm.$t('enumSuMaterialGroup')},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.data, "enumSuMaterialGroupSid", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.enumSuMaterial),function(material){return _c('option',{key:material.sid,domProps:{"value":material.sid}},[_vm._v(" "+_vm._s(material.description)+" ")])}),0),_c('error-list',{attrs:{"errors":errors}})]}}])}),_c('validation-provider',{staticClass:"manage-input mt-3",attrs:{"tag":"div","name":"enumMaterialType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"enumMaterialType"}},[_vm._v(_vm._s(_vm.$t('enumMaterialType'))+"*:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.enumMaterialTypeSid),expression:"data.enumMaterialTypeSid"}],staticClass:"default-select",class:{ 'invalid': failed },attrs:{"id":"enumMaterialType","placeholder":_vm.$t('enumMaterialType')},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.data, "enumMaterialTypeSid", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.enumMaterialType),function(type){return _c('option',{key:type.sid,domProps:{"value":type.sid}},[_vm._v(" "+_vm._s(type.description)+" ")])}),0),_c('error-list',{attrs:{"errors":errors}})]}}])}),(_vm.data.materialGroupSid)?_c('validation-provider',{staticClass:"manage-input mt-3",attrs:{"tag":"div","name":"comments","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"lastUpdateComment"}},[_vm._v(_vm._s(_vm.$t('comments'))+":")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.lastUpdateComment),expression:"data.lastUpdateComment"}],staticClass:"default-textarea",class:{ 'invalid': failed },attrs:{"maxlength":"1000","placeholder":_vm.$t('comments'),"id":"lastUpdateComment","rows":"4"},domProps:{"value":(_vm.data.lastUpdateComment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "lastUpdateComment", $event.target.value)}}}),_c('error-list',{attrs:{"errors":errors}})]}}],null,false,3287004552)}):_vm._e(),_c('div',{staticClass:"flex-row mt-3"},[_c('checkbox-input',{attrs:{"label":_vm.$t('markAsDeleted')},model:{value:(_vm.data.isDeleted),callback:function ($$v) {_vm.$set(_vm.data, "isDeleted", $$v)},expression:"data.isDeleted"}})],1),(_vm.data.materialGroupSid)?_c('last-updated',{attrs:{"data":_vm.data}}):_vm._e(),_c('error-list',{attrs:{"errors":_vm.invalidMessages}})],1),_c('div',{staticClass:"members-footer"},[(_vm.hasMasterDataAdmin)?_c('adam-button',{staticClass:"ml-1",attrs:{"secondary":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }