export function extractErrorsFromResponse(errorResponseData: any): string[] {
    if (!errorResponseData) {
        return [];
    }

    if (errorResponseData.errors && Array.isArray(errorResponseData.errors)) {
        return errorResponseData.errors;
    } else if (errorResponseData.message) {
        return [errorResponseData.message];
    }

    return [];
}

const serializeArray = (parameterName: string, arr?: number[] | null): string => {
    let queryResult = '';
    if (arr) {
        queryResult += arr.filter(Boolean).map(entry => `${parameterName}=${entry}`).join('&')
    }
    return queryResult
}

export const generateQuery = (obj: Record<string, number | string | boolean | null | undefined | number[] | string[]>): string => {
    let queryResult = '';
    for (const property in obj) {
        if (!!obj[property] || typeof obj[property] === 'boolean') {
            if (queryResult.length && queryResult.lastIndexOf('&') !== queryResult.length - 1) {
                queryResult = `${queryResult}&`
            }
            if (typeof obj[property] === 'object') {
                queryResult += serializeArray(property, obj[property] as any)
            } else {
                queryResult += `${property}=${obj[property]}`
            }
        }
    }
    return queryResult
}
