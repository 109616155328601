import { Module } from 'vuex';
import { getters, mutations, actions } from '@/store/types';
import { filterService } from '@/services';
import { FilterRequestFieldName, MasterDataFilterItem } from '@/models';

const hierarchyModule: Module<any, any> = {
    state: {
        totalCount: 0,
        displayedCount: 0,

        countries: [],
        fleets: [],
        plantGroups: [],
        classTypes: [],
        machineTypes: [],
        filterOptionsLoaded: false,
    },

    mutations: {
        [mutations.SET_TOTAL_COUNT](state, totalCount: number): void {
            state.totalCount = totalCount;
        },
        [mutations.SET_DISPLAYED_COUNT](state, displayedCount: number): void {
            state.displayedCount = displayedCount;
        },
        [mutations.SET_COUNTRIES](state, countries: MasterDataFilterItem[]): void {
            state.countries = countries;
        },
        [mutations.SET_FLEETS](state, fleets: MasterDataFilterItem[]): void {
            state.fleets = fleets;
        },
        [mutations.SET_PLANT_GROUPS](state, plantGroups: MasterDataFilterItem[]): void {
            state.plantGroups = plantGroups;
        },
        [mutations.SET_CLASS_TYPES](state, classTypes: MasterDataFilterItem[]): void {
            state.classTypes = classTypes;
        },
        [mutations.SET_MACHINE_TYPES](state, machineTypes: MasterDataFilterItem[]): void {
            state.machineTypes = machineTypes;
        },
        [mutations.SET_FILTER_OPTIONS_LOADED](state, filterOptionsLoaded: boolean): void {
            state.filterOptionsLoaded = filterOptionsLoaded;
        },
    },

    actions: {
        async [actions.LOAD_FILTER_OPTIONS]({ commit, state }, resetLoad = false): Promise<void> {
            if (state.filterOptionsLoaded && !resetLoad) {
                return;
            }
            const result = await filterService.getFilters();
            const countries = result.find((item) => item.requestFieldName === FilterRequestFieldName.COUNTRIES)?.options ?? [];
            const fleets = result.find((item) => item.requestFieldName === FilterRequestFieldName.FLEETS)?.options ?? [];
            const plantGroups = result.find((item) => item.requestFieldName === FilterRequestFieldName.PLANT_GROUPS)?.options ?? [];
            const classTypes = result.find((item) => item.requestFieldName === FilterRequestFieldName.CLASS_TYPES)?.options ?? [];
            const machineTypes = result.find((item) => item.requestFieldName === FilterRequestFieldName.MACHINE_TYPES)?.options ?? [];

            commit(mutations.SET_COUNTRIES, countries);
            commit(mutations.SET_FLEETS, fleets);
            commit(mutations.SET_PLANT_GROUPS, plantGroups);
            commit(mutations.SET_CLASS_TYPES, classTypes);
            commit(mutations.SET_MACHINE_TYPES, machineTypes);
            commit(mutations.SET_FILTER_OPTIONS_LOADED, true);
        },
    },

    getters: {
        [getters.GET_TOTAL_COUNT]: (state) => state.totalCount,
        [getters.GET_DISPLAYED_COUNT]: (state) => state.displayedCount,
        [getters.GET_COUNTRIES]: (state) => state.countries,
        [getters.GET_FLEETS]: (state) => state.fleets,
        [getters.GET_PLANT_GROUPS]: (state) => state.plantGroups,
        [getters.GET_CLASS_TYPES]: (state) => state.classTypes,
        [getters.GET_MACHINE_TYPES]: (state) => state.machineTypes,
    },
    namespaced: true,
};

export default {
    state: hierarchyModule.state,
    mutations: hierarchyModule.mutations,
    actions: hierarchyModule.actions,
    getters: hierarchyModule.getters,
};
