<template>
    <md-card
        icon="ic-company"
        :name="card.abbreviation"
        :sid="card.businessPartnerSid">
        <template v-slot:card-properties>
            <div class="flex-col full-name border-right mr-2 pr-2 center-vertically">
                <div>
                    <b>{{ card.name }}</b>
                </div>
            </div>
            <div class="flex-col last-updated border-right mr-2 pr-2 center-vertically">
                <div>
                    <b>{{ formatDate(card.lastUpdated) }}</b>
                </div>
            </div>
            <div class="flex-col last-updated-by border-right mr-2 pr-2 center-vertically">
                <div>
                    <b>{{ card.lastUpdatedBy }}</b>
                </div>
            </div>
            <div class="flex-col deleted mr-2 pr-2 center-vertically">
                <span class="flex-row">
                    <b
                        :class="[
                            'ellipsis',
                            'ml-1',
                            card.deleted ? 'color-amber' : 'color-green'
                        ]"
                        :title="card.deleted"
                    >{{ card.deleted ? 'Y' : 'N' }}</b>
                </span>
            </div>
        </template>
        <template v-slot:action-buttons>
            <adam-button
                icon="ic-settings"
                :outlined.prop="false"
                fab
                @click="edit()"
                inverted/>
        </template>
    </md-card>
</template>

<script lang="ts">

import { Component, Mixins, Prop } from 'vue-property-decorator';
import { DateHelper } from '@/utils';
import { BusinessPartner } from '@/models';
import ComponentSecurity from '@/mixins/component-security';
import MdCard from '@/components/cards/cards-list/md-card/md-card.vue';

@Component({
    name: 'business-partner-card-list',
    components: {
        mdCard: MdCard,
    },
})
export default class BusinessPartnerCardList extends Mixins(ComponentSecurity) {
    /*
     * Card information which will be displayed.
    */
    @Prop()
    private card!: BusinessPartner;

    private edit(): void {
        this.$emit('edit', this.card);
    }

    private remove(): void {
        this.$emit('remove', this.card);
    }

    private formatDate(date: string): string {
        return date !== undefined ? DateHelper.formatDate(date) : '';
    }
}
</script>
