var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"identification-container"},[_c('validation-observer',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validateWithInfo = ref.validateWithInfo;
return [_c('div',{staticClass:"identification"},[_c('validation-provider',{staticClass:"manage-input mt-3",attrs:{"name":"name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"enumName"}},[_vm._v(_vm._s(_vm.$t('name'))+"*:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formModel.name),expression:"formModel.name"}],staticClass:"default-input",class:{ 'invalid': failed },attrs:{"placeholder":_vm.$t('name'),"id":"enumName","maxlength":"50"},domProps:{"value":(_vm.formModel.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formModel, "name", $event.target.value)}}}),_c('error-list',{attrs:{"errors":errors}})]}}],null,true)}),_c('validation-provider',{staticClass:"manage-input mt-3",attrs:{"name":"description","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"enumDescription"}},[_vm._v(_vm._s(_vm.$t('description'))+":")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formModel.description),expression:"formModel.description"}],staticClass:"default-textarea",class:{'invalid': failed },attrs:{"placeholder":_vm.$t('description'),"id":"groupDescription","rows":"4","maxlength":"50"},domProps:{"value":(_vm.formModel.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formModel, "description", $event.target.value)}}}),_c('error-list',{attrs:{"errors":errors}})]}}],null,true)}),_c('validation-provider',{staticClass:"mt-3",attrs:{"tag":"div"}},[_c('checkbox-input',{attrs:{"label":_vm.$t('markAsDeleted')},model:{value:(_vm.formModel.isDeleted),callback:function ($$v) {_vm.$set(_vm.formModel, "isDeleted", $$v)},expression:"formModel.isDeleted"}})],1),_c('error-list',{attrs:{"errors":_vm.invalidMessages}})],1),_c('div',{staticClass:"members-footer"},[(_vm.hasMasterDataAdmin)?_c('adam-button',{staticClass:"ml-1",attrs:{"secondary":""},on:{"click":function($event){validateWithInfo().then(_vm.save)}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }