import { MasterDataTreeItem, NodeTypeSid, IdTypeSid } from '@/models';

/**
 * Collection of helper methods for assets.
 */
export class AssetHelper {
    /**
     * Static method to check if the item is a power plant.
     * @name isPowerPlant
     * @param {MasterDataTreeItem} item
     * @returns {boolean} result of the check.
     */
    public static isPowerPlant(item: MasterDataTreeItem): boolean {
        if (item.enumNodeTypeSid === NodeTypeSid.POWER_PLANT &&
            item.idTypeSid === IdTypeSid.POWER_PLANT_TYPE) {
            return true;
        }
        return false;
    }

    /**
     * Static method to check if the item is a power plant.
     * @name isUnit
     * @param {MasterDataTreeItem} item
     * @returns {boolean} result of the check.
     */
    public static isUnit(item: MasterDataTreeItem): boolean {
        if (item.enumNodeTypeSid === NodeTypeSid.MACHINE &&
            item.idTypeSid === IdTypeSid.UNIT_TYPE) {
            return true;
        }
        return false;
    }
}
