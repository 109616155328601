<template>
    <div class="md-filter-checkbox">
        <checkbox-input
          :label="option.displayName"
          :value="value"
          @input="handleCheckboxInput" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import CheckboxInput from '@/components/checkbox-input/checkbox-input.vue';
import { FilterGroup } from '@/models';

@Component({
    name: 'filter-options',
    components: {
        checkboxInput: CheckboxInput,
    },
})
export default class MdFilterOptions extends Vue {
    @Prop()
    private option!: FilterGroup;

    @Prop()
    private value!: boolean;

    private handleCheckboxInput(value: boolean) {
        this.$emit('input', value);
    }
}
</script>

<style>
.md-filter-checkbox {
    display: flex;
    padding: 1rem;
}
</style>
