import { ApiResponse, SearchPaginationQueryModel, OperationModeVersionListDto,
    OperationModeVersionGetResponse, OperationModeGetResponse, OperationModeAddRequest,
    OperationModeAddResponse, OperationModeEditRequest, OperationModeEditResponse,
    OperationModeVersionAddRequest, OperationModeVersionAddResponse, OperationModeListDto,
    OperationModeVersionEditRequest, OperationModeVersionEditResponse, PagedResponse} from '@/models';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
import { generateQuery } from '@/utils/api-helper';

export class OperationModesService {
    private api = new SdkGenericApiHelper('operation-modes');

    public async create(operationMode: OperationModeAddRequest): Promise<ApiResponse<OperationModeAddResponse>> {
        return (await this.api.post(operationMode)).data;
    }

    public async get(filter: SearchPaginationQueryModel): Promise<ApiResponse<PagedResponse<OperationModeListDto>>> {
        const query = generateQuery(filter as any);
        return (await this.api.get(query)).data;
    }

    public async update(operationMode: OperationModeEditRequest, validFrom: string): Promise<ApiResponse<OperationModeEditResponse>> {
        return (await this.api.post(operationMode, `${operationMode.operationModeSid}/from/${validFrom}`))
            .data;
    }

    public async getById(sid: number): Promise<ApiResponse<OperationModeGetResponse>> {
        return (await this.api.getByPathOrParam(sid)).data;
    }

    public async createVersion(operationMode: OperationModeVersionAddRequest): Promise<ApiResponse<OperationModeVersionAddResponse>> {
        return (await this.api.post(operationMode, `${operationMode.operationModeSid}/versions`)).data;
    }

    public async getVersions(sid: number): Promise<OperationModeVersionListDto[]> {
        const { data: { result: { items } } } = (await this.api.getByPathOrParam(`${sid}/versions`));
        return items;
    }

    public async updateVersion(operationModeSid: number, validFrom: string, operationMode: OperationModeVersionEditRequest):
        Promise<ApiResponse<OperationModeVersionEditResponse>> {
        return (await this.api.put(`${operationModeSid}/versions/${validFrom}`, operationMode))
            .data;
    }

    public async deleteVersion(operationModeSid: number, validFrom: string): Promise<ApiResponse<OperationModeVersionGetResponse>> {
        return (await this.api.delete(`${operationModeSid}/versions/${validFrom}`))
            .data;
    }

    public async getVersion(sid: number, validFrom: string): Promise<OperationModeVersionGetResponse> {
        const { data: { result } } = (await this.api.getByPathOrParam(`${sid}/versions/${validFrom}`));
        return result;
    }
}
