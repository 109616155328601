var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',[(_vm.check.dataLoaded)?_c('pui-grid-container',[_c('card-header',{attrs:{"title":_vm.name,"chip-title":_vm.sid,"on-click-back-button":_vm.onBackPress}}),_c('pui-grid-row',[_c('fake-single-tab',{attrs:{"title":_vm.$t('identification')}})],1),_c('pui-grid-row',{class:_vm.errors.length > 0 ? 'pebble-errors-list-margin-top' : ''},[_c('pebble-errors-list',{attrs:{"id":"errors-list","errors":_vm.errors}})],1),_c('pui-form',{attrs:{"aria-label":"Form"}},[_c('pui-grid-row',[_c('pui-grid-column',{staticClass:"pui-grid-column-zero-padding"},[_c('business-partners-identification-form',{attrs:{"data":_vm.formValues,"countries":_vm.countries,"states":_vm.states,"types":_vm.types,"is-edit":true},on:{"validate":_vm.validate}})],1)],1)],1),_c('pui-grid-row',{staticClass:"pui-grid-row-little-margin-top"},[_c('pui-grid-column',{staticClass:"pui-grid-column-zero-padding",attrs:{"cols":{s:12, m:5, l:6, xl:6, xxl: 6}}},[_c('pebble-last-updated',{attrs:{"data":{
                        lastUpdated: _vm.selectedBusinessPartners.lastUpdated,
                        lastUpdatedBy: _vm.selectedBusinessPartners.lastUpdatedBy,
                    }}})],1),_c('pui-grid-column',{staticClass:"pui-grid-column-zero-padding",attrs:{"cols":{s:12, m:7, l:6, xl:6, xxl: 6}}},[_c('cancel-save-buttons',{attrs:{"save-button-is-disabled":!_vm.hasMasterDataAdmin || _vm.saveButtonIsDisabled,"on-click-cancel-button":_vm.onClickCancelButton,"on-click-save-button":_vm.onClickSaveButton}})],1)],1)],1):(_vm.check.showError)?_c('pui-grid-container',[_c('card-header',{attrs:{"title":_vm.name,"chip-title":_vm.sid,"on-click-back-button":_vm.onBackPress}}),_c('pui-grid-row',[_c('pui-loader-error',{attrs:{"title":_vm.$t('errorTitleDataLoaded'),"message":_vm.$t('errorGettingBusinessPartners'),"icon":"error-alert","buttons":[
                {
                    state: 'secondary',
                    label: _vm.$t('refresh'),
                    onClick: _vm.onClickRefreshButton,
                }
            ]}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }