<template>
    <pui-grid-container class="pui-grid-container-fix-margin">
        <pui-grid-row>
            <master-data-management-header
                :title="$t('manageMaterialGroup')"
                :filters="filters"
                :deleted-initial-value="filterResult.showDeleted"
                :on-add-new="onClickAddNewButton"
                :on-check="onClickCheckBox"
                :on-filter-change="onFilterChange"
            />
        </pui-grid-row>
        <pui-grid-row>
            <custom-table
                :columns-key="['shortName', 'materialGroupSid','materialGroupName','enumSuMaterialGroup','enumMaterialType', 'lastUpdated', 'lastUpdatedBy', 'isDeleted', 'action']"
                :data="data"
                :heading="heading"
                :sortable-key="['shortName', 'materialGroupSid','materialGroupName','enumSuMaterialGroup','enumMaterialType', 'lastUpdated', 'lastUpdatedBy', 'isDeleted']"
                :per-page="10"
                :per-page-values="[10, 50, 100]"
                enable-search
                enable-pagination
                enable-limit
                :custom-template="['isDeleted', 'action']"
            >
                <template v-slot:custom_isDeleted="{data}">
                    <boolean-text
                        :titles="{
                            textTrue: $t('yes'),
                            textFalse: $t('no'),
                        }"
                        :value="data"
                    />
                </template>
                <template v-slot:custom_action="{data}">
                    <pui-link
                        :title="$t('edit')"
                        icon="edit"
                        @click="onClickEditButton(data)"
                    />
                </template>
            </custom-table>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import MasterDataManagementHeader from '@/components/headers/master-data-management-header.vue';
import {Filter} from '@/models/custom-filter/filter';
import {filterService, MaterialGroupService} from '@/services';
import {CustomObject} from '@/models/custom-object';
import {FilterRequestFieldName, MaterialGroupModel} from '@/models';
import {MaterialGroupQueryModel} from '@/models/filter';
import {EventBus} from '@/utils';
import BooleanText from '@/components/text/boolean-text.vue';
import CustomTable from '@/components/table/custom-table.vue';

@Component({
    name: 'management-material-group',
    components: {CustomTable, BooleanText, MasterDataManagementHeader},
})
export default class ManagementMaterialGroup extends Vue {
    /* VARIABLES */
    private service: MaterialGroupService = new MaterialGroupService();
    private filters: Filter[] = [];
    private data: MaterialGroupModel[] = [];
    private filterResult = {
        showDeleted: false,
        enumMaterialType: [],
        enumSuMaterialGroup: [],
    };
    private filter: MaterialGroupQueryModel = {
        EnumSuMaterialGroupSid: [],
        EnumMaterialTypeSid: [],
        includeDeleted: false,
        page: 1,
        size: 100000,
        term: '',
        sortDirection: '',
        sortColumn: '',
    };

    get heading(): any {
        return {
            shortName: this.$t('identifier').toString(),
            materialGroupSid: this.$t('sid').toString(),
            materialGroupName: this.$t('materialGroupName').toString(),
            enumSuMaterialGroup: this.$t('suMaterialGroup').toString(),
            enumMaterialType: this.$t('materialType').toString(),
            lastUpdated: this.$t('lastUpdated').toString(),
            lastUpdatedBy: this.$t('lastUpdatedBy').toString(),
            isDeleted: this.$t('deleted').toString(),
            action: '',
        };
    }

    /* PRIMITIVE METHODS */
    private mounted() {
        this.filterResult.showDeleted = this.$router.currentRoute.query['deleted'] === 'true' ?? false;
        this.init();
        this.initFilters();
    }

    /* METHODS */
    private async initFilters(): Promise<void> {
        const filterGroup = await filterService.getNewMaterialGroupFilter();
        this.filters = [];
        filterGroup.forEach((value) => {
            const options = value.options?.filter((element) => {
                return element.name !== undefined;
            }).map((element) => {
                return {
                    value: element.value,
                    displayName: element.name,
                };
            }) ?? [];
            this.filters.push({
                name: value.requestFieldName,
                displayName: value.displayName,
                type: 'multiselect',
                isExpandable: true,
                isExpanded: true,
                appliedValues: {
                    options: [],
                },
                selectedValues: {
                    options: [],
                },
                config: {
                    hasSearchInput: true,
                    searchInputPlaceholder: this.$t(value.requestFieldName).toString(),
                    options,
                },
            });
        });
    }

    private async init(): Promise<void> {
        this.data = await this.loadGroupMaterial();
    }

    /* ON CLICK BUTTONS */
    private async onClickCheckBox(value: boolean): Promise<void> {
        this.filterResult.showDeleted = value;
        await this.$router.replace({
            path: this.$router.currentRoute.path,
            params: this.$router.currentRoute.params,
            query: {
                deleted: this.filterResult.showDeleted.toString(),
                t: new Date().getTime().toString(),
            },
        });
        await this.init();
    }

    private onFilterChange(value: CustomObject): void {
        this.filterResult = {
            enumMaterialType: value[FilterRequestFieldName.ENUM_MATERIAL_TYPE],
            enumSuMaterialGroup: value[FilterRequestFieldName.ENUM_SU_MATERIAL_GROUP],
            showDeleted: this.filterResult.showDeleted,
        };
        this.init();
    }

    private onClickAddNewButton(): void {
        this.$router.push({
            name: 'management-material-group-new',
            query: {
                add: 'true',
            },
        });
    }

    private onClickEditButton(value: MaterialGroupModel): void {
        this.$router.push({
            name: 'management-material-group-edit',
            query: {
                edit: value.materialGroupSid.toString(),
                name: value.shortName.toString(),
                tab: '0',
            },
        });
    }

    /* API CALLS */
    private async loadGroupMaterial(): Promise<MaterialGroupModel[]> {
        this.$store.commit('loading');
        let materials: MaterialGroupModel[] = [];
        try {
            this.filter.EnumMaterialTypeSid = this.filterResult.enumMaterialType;
            this.filter.EnumSuMaterialGroupSid = this.filterResult.enumSuMaterialGroup;
            this.filter.includeDeleted = this.filterResult.showDeleted;
            const {result: {items}} = (await this.service.get(this.filter));
            materials = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingMaterials');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
        return materials;
    }

}
</script>


