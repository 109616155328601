import { ApiResponse, AssetOverviewQuery, AssetOverviewModel, PagedResponse, AssetOverviewRequestParams, AssetOverviewListItem } from '@/models';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
import { EventBus } from '@/utils';
import { generateQuery } from '@/utils/api-helper';
import { store } from '@/store/index';

class AssetOverviewService {
    private api = new SdkGenericApiHelper('assets/overview');

    public async getTable(filter: AssetOverviewQuery): Promise<ApiResponse<PagedResponse<AssetOverviewModel>>> {
        const query = generateQuery(filter as any);
        return (await this.api.get(query)).data;
    }

    public async getExcelData(filter: AssetOverviewQuery): Promise<ApiResponse<any>> {
        const query = generateQuery(filter as any);
        return (await this.api.get(query, 'export')).data;
    }

    public async getAssetOverviewData(requestParams: AssetOverviewRequestParams): Promise<AssetOverviewListItem[]> {
        try {
            store.commit('loading');
            const result = (await this.getTable({
                CountryId: requestParams.countries,
                UnitTypeId: requestParams.machineTypes,
                UnitTechnologyId: requestParams?.unitTechnologies,
                PrimaryEnergyId: requestParams.primaryEnergies,
                StatusId: requestParams.statuses,
                RiverId: requestParams.rivers,
                RegionalUnitId: requestParams.regionalUnits,
                LegalEntityId: requestParams.legalEntities,
                page: 1,
                size: 10000,
                term: '',
                sortColumn: '',
                sortDirection: '',
            }))?.result;
            const assets = result?.items?.map((item) => new AssetOverviewListItem(item)) ?? [];
            return assets;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssetOverview');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async exportAssetsToExcel(requestParams: AssetOverviewRequestParams): Promise<any> {
        try {
            store.commit('loading');
            return (await this.getExcelData({
                CountryId: requestParams.countries,
                UnitTypeId: requestParams.machineTypes,
                UnitTechnologyId: requestParams?.unitTechnologies,
                PrimaryEnergyId: requestParams.primaryEnergies,
                StatusId: requestParams.statuses,
                RiverId: requestParams.rivers,
                RegionalUnitId: requestParams.regionalUnits,
                LegalEntityId: requestParams.legalEntities,
                page: 1,
                size: 10000,
                term: '',
                sortColumn: '',
                sortDirection: '',
            }))?.result;

        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorExporting');
            return undefined;
        } finally {
            store.commit('loading');
        }
    }
}

const assetOverviewService = new AssetOverviewService();

export {
    AssetOverviewService,
    assetOverviewService,
};
