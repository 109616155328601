import { PlantGroupAddRequest } from '@/models';
import { DateHelper } from '@/utils/date-helper';

export class GroupVersion {

    public static mapGroupAddEditModel(groupVersion: GroupVersion) {
        return {
            powerPlantSid: groupVersion?.powerPlantSid,
            validFrom: groupVersion.validFrom,
            plantGroupSid: groupVersion?.plantGroupSid,
            lastUpdateComment: groupVersion?.lastUpdateComment,
        } as PlantGroupAddRequest;
    }

    public powerPlantSid: number;
    public validFrom: string;
    public validTo?: string;
    public plantGroupSid?: number;
    public plantGroupName?: string;
    public lastUpdatedBy?: string;
    public lastUpdated?: string;
    public lastUpdateComment?: string;

    public constructor(data?: any) {
        this.powerPlantSid = data?.powerPlantSid;
        this.validFrom = data?.validFrom ?? DateHelper.formatDate(new Date());
        this.validTo = data?.validTo;
        this.plantGroupSid = data?.plantGroupSid;
        this.plantGroupName = data?.plantGroupName;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdated = data?.lastUpdated;
        this.lastUpdateComment = data?.lastUpdateComment;
    }
}
