<template>
    <div class="key-mapping-container">
        <key-mapping-page-header/>
        <div class="key-mapping-body">
            <card :enable-full-height="true" :enable-scroll="true">
                <pui-grid-container>
                    <pui-grid-row>
                        <pui-grid-column :cols="{s: 12, m:8, l:6, xl: 4, xxl: 4}">
                            <pebble-drop-down-select
                                name="selectExternalSystem"
                                :model="model"
                                :label="$t('selectExternalSystem').toString()"
                                :options="externalSystems"
                                @on-input="onClickExternalSystem"
                            />
                        </pui-grid-column>
                    </pui-grid-row>
                    <pui-grid-row class="key-mapping-margin" v-if="model.value !== -1">
                        <pui-grid-column :cols="{s: 12, m:12, l:12, xl: 12, xxl: 12}">
                            <pebble-loader
                                :status="dataStatus"
                                :error-sub-title="$t('errorLoadingExternalSystems').toString()"
                                @on-refresh-click="loadKeyMappings(model.value)"
                            >
                                <custom-table
                                    :columns-key="['assetName', 'assetId', 'assetType', 'keyMapping', 'validFrom', 'validTo', 'isActive', 'action']"
                                    :sortable-key="['assetName', 'assetId', 'assetType', 'keyMapping', 'validFrom', 'validTo', 'isActive']"
                                    :data="keyMappings"
                                    :heading="heading"
                                    :per-page="10"
                                    :per-page-values="[10, 50, 100]"
                                    :custom-template="['isActive', 'action']"
                                    enable-search
                                    enable-pagination
                                    enable-limit
                                >
                                    <template v-slot:custom_isActive="{data}">
                                        <boolean-text
                                            :titles="{
                                            textTrue: $t('yes').toString(),
                                            textFalse: $t('no').toString(),
                                        }"
                                            :value="data"
                                        />
                                    </template>
                                    <template v-slot:custom_action="{data}">
                                        <pui-link
                                            :title="$t('edit')"
                                            icon="edit"
                                            @click="onClickEdit(data)"
                                        />
                                    </template>
                                </custom-table>
                            </pebble-loader>
                        </pui-grid-column>
                    </pui-grid-row>
                </pui-grid-container>
            </card>
        </div>
    </div>
</template>

<script lang="ts">
import ComponentSecurity from '@/mixins/component-security';
import {Mixins} from 'vue-property-decorator';
import Component from 'vue-class-component';
import Card from '@/components/cards/card.vue';
import TitleFormText from '@/components/text/title-form-text.vue';
import KeyMappingPageHeader from '@/components/headers/key-mapping-page-header.vue';
import {AssetService, KeyMappingService} from '@/services';
import {Asset, IdTypeSid, KeyMappingListingItem} from '@/models';
import {EventBus} from '@/utils';
import PebbleDropDownSelect from '@/components/pebble-form-element/pebble-drop-down-select.vue';
import {PebbleDropDown, pebbleDropDownFromAssets} from '@/models/pebble/pebble-drop-down';
import {FormValuesModel} from '@/models/pebble/form-values-model';
import CustomTable from '@/components/table/custom-table.vue';
import BooleanText from '@/components/text/boolean-text.vue';
import PebbleLoader from '@/components/loader/pebble-loader.vue';
import {PebbleStatus} from '@/models/pebble/enums/pebble-status';
import {Dictionary} from 'vue-router/types/router';
import {CustomObject} from '@/models/custom-object';

@Component({
    name: 'key-mapping-page',
    components: {
        PebbleLoader,
        BooleanText,
        CustomTable,
        PebbleDropDownSelect,
        KeyMappingPageHeader,
        TitleFormText,
        Card,
    },
})
export default class KeyMappingPage extends Mixins(ComponentSecurity) {
    /* VARIABLES */
    private assetService: AssetService = new AssetService();
    private keyMappingService: KeyMappingService = new KeyMappingService();
    private externalSystems: PebbleDropDown[] = [];
    private keyMappings: KeyMappingListingItem[] = [];
    private model: FormValuesModel = {
        value: -1,
        isValid: true,
    };
    private dataStatus: PebbleStatus = PebbleStatus.LOADING;
    private routes: CustomObject = {
        'Ets Unit': 'management-ets-unit',
        'Material': 'management-materials',
        'Material Group': 'management-material-group',
    };

    get heading(): any {
        return {
            assetName: this.$t('assetName').toString(),
            assetId: this.$t('assetID').toString(),
            assetType: this.$t('assetType').toString(),
            keyMapping: this.$t('keyMapping').toString(),
            isActive: this.$t('active').toString(),
            validFrom: this.$t('validFrom').toString(),
            validTo: this.$t('validUntil').toString(),
            action: '',
        };
    }

    /* PRIMITIVE METHODS */
    private mounted(): void {
        this.init();
    }

    /* METHODS */

    private async init(): Promise<void> {
        this.externalSystems = pebbleDropDownFromAssets(await this.loadExternalSystem());
        if (this.$route.query.sid) {
            if (this.externalSystems.filter((e: PebbleDropDown) => e.value.toString() === this.$route.query.sid.toString()).length > 0) {
                this.model.value = +this.$route.query.sid;
                this.keyMappings = await this.loadKeyMappings(+this.$route.query.sid);
            } else {
                this.replacePage(-1);
            }
        }
    }

    private replacePage(sid: number): void {
        this.$router.replace({
            path: this.$router.currentRoute.path,
            params: this.$router.currentRoute.params,
            query: {
                sid: sid === -1 ? undefined : sid.toString(),
            },
        });
    }

    private goToPage(path: string, query: Dictionary<string | [] | null | undefined> | undefined): void {
        this.$router.push({
            path,
            query,
        });
    }

    /* ON CLICK METHODS */
    private async onClickExternalSystem(value: number): Promise<void> {
        if (value !== -1) {
            this.keyMappings = [];
            this.replacePage(value);
            this.keyMappings = await this.loadKeyMappings(value);
        }
    }

    private async onClickEdit(value: KeyMappingListingItem): Promise<void> {
        if (value.assetType === 'Unit') {
            this.goToPage(
                `asset-master-data/unit/${value.assetId}`,
                {
                    powerPlantId: '8022',
                    tabIndex: '3',
                    systemSid: this.model.value.toString(),
                });
        } else if (value.assetType === 'Power Plant') {
            this.goToPage(
                `asset-master-data/power-plant/${value.assetId}`,
                {
                    tabIndex: '2',
                    systemSid: this.model.value.toString(),
                });
        } else if (this.routes[value.assetType] !== undefined) {
            this.goToPage(
                `master-data-management-v2/${this.routes[value.assetType]}/edit`,
                {
                    edit: value.assetId.toString(),
                    name: value.assetName,
                    tab: '1',
                    versionSid: this.model.value.toString(),
                    backPage: 'true',
                });
        }
    }

    /* API CALLS */

    private async loadExternalSystem(): Promise<Asset[]> {
        let temp: Asset[] = [];
        this.$store.commit('loading');
        try {
            temp = (await this.assetService.getByIdTypeSid(IdTypeSid.EXTERNAL_SYSTEM, {
                size: 999,
                page: 1,
                term: '',
            })).result.items;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingExternalSystems');
            throw error;
        } finally {
            this.$store.commit('loading');
        }
        return temp;
    }

    private async loadKeyMappings(sid: number): Promise<KeyMappingListingItem[]> {
        let temp: KeyMappingListingItem[] = [];
        this.dataStatus = PebbleStatus.LOADING;
        try {
            temp = (await this.keyMappingService.getAllKeyMappings(sid, {
                size: 9999999,
                page: 1,
            })).result.items;
            this.dataStatus = PebbleStatus.LOADED;
        } catch (error: any) {
            this.dataStatus = PebbleStatus.ERROR;
            throw error;
        }
        return temp;
    }
}
</script>

<style scoped lang="less">
@import "~@/variables.less";

.key-mapping-container {
    height: 100%;
    display: flex;
    align-items: stretch;
    flex-flow: column;
}

.key-mapping-title {
    margin: 32px;
}

.key-mapping-body {
    flex-grow: 2;
    overflow: auto;
}

.key-mapping-margin {
    margin-top: 32px;
}

</style>
