<template>
    <div class="details-container">
        <div class="flex-row details-header">
            <div class="flex-col flex-8 pr-2"
                :class="{'border-right mr-2': sid}">
                <div class="flex-row flex-center">
                    <adam-icon :icon="icon" class="mr-2 details-container__icon" />
                    <span class="title">
                        <span>{{ name }}</span>
                    </span>
                </div>
            </div>
            <div class="flex-col border-right mr-2 pr-2"
                v-if="sid">
                <span class="sid">{{ sid }}</span>
            </div>
        </div>
        <slot />
    </div>
</template>

<script lang="ts">

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'view-details',
})

export default class ViewDetails extends Vue {
    /**
     * The sid to be displayed in the header
     */
    @Prop({ required: true })
    private sid!: number;

    /**
     * The name to be displayed in the header
     */
    @Prop({ required: true })
    private name!: string;

    /**
     * The icon to be used in the header
     */
    @Prop({ required: true })
    private icon!: string;
}

</script>

<style lang="less">
@import "~@/variables.less";

.details-container {
    padding: 2rem;
    background-color: @white;
    box-shadow: @shadow-popup;

    &__icon {
        width: 2.6rem;
        height: 2.6rem;
    }

    .details-header {
        margin-bottom: 2rem;
        font-family: Roboto-Light, sans-serif;
        font-size: 2rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid @grey-lighter;
        .active, .deleted {
            font-family: Roboto-Regular, sans-serif;
        }
        .title {
            vertical-align: top;
            font-family: Roboto-Regular, sans-serif;
        }
        @media(max-width: 1500px) {
            font-size: 1.8rem;
        }
    }
    .manage-input {
        position: relative;
        label {
            position: absolute;
            background: white;
            left: 1.9rem;
            top: -2.1rem;
            padding: 0 1rem;
            font-size: 1.6rem;
            z-index: 1;
        }
        input,
        select,
        textarea {
            font-size: 1.8rem;
            padding: 1rem 2.5rem;
            border-radius: @border-radius-input;
            border: 2px solid @dark-grey;
            transition: 0.2s ease-in;
            &:focus {
                border: 2px solid @uniper-blue;
                transition: 0.2s ease-in;
                outline: none;
            }
            &.invalid {
                border: 2px solid @red-darker;
                box-shadow: 0 1px 4px 0 rgba(255, 0, 0, 0.22);
                transition: 0.1s ease-in;
            }
        }
    }
    .tab-content {
        margin: 2rem;
        input {
            width: 100%;
        }
        label {
            min-width: fit-content;
            padding-top: 1rem;
        }
    }
    .details-view-content {
        height: calc(100% - 13.2rem);
    }
    .members-footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
        button {
            margin: 0 0 0 2rem;
        }
        .remove-button {
            padding: 0.8rem;
        }
    }
    .sid {
        width: 10rem;
        font-size: 1.5rem;
        height: 3rem;
        line-height: 3rem;
        background-color: @uniper-blue;
        border-color: @uniper-blue;
        color: @white;
        border-radius: 2rem;
        text-align: center;
    }
}
</style>
