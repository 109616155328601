<template>
    <card>
        <pui-grid-container v-if="!check.showError">
            <card-header
                :title="name"
                :chip-title="sid"
                :on-click-back-button="onClickBackButton"
            />
            <pui-grid-row class="pui-grid-row-little-margin-top">
                <custom-table
                    :columns-key="['validFrom', 'validTo', 'reservoirName', 'lastUpdated', 'lastUpdatedBy', 'action']"
                    :sortable-key="['validFrom', 'validTo', 'reservoirName', 'lastUpdated', 'lastUpdatedBy', ]"
                    :custom-template="['action']"
                    :data="versions"
                    :per-page="5"
                    :heading="heading"
                    enable-pagination
                    :selected="versionIsSelected"
                >
                    <template v-slot:custom_action="{data}">
                        <pui-grid-container class="pui-grid-container-zero-padding">
                            <pui-grid-row class="pui-grid-row--around">
                                <pui-link
                                    :title="$t('edit')"
                                    icon="edit"
                                    :disable="check.addNewVersion || versionIsSelected(data)"
                                    :variant="!check.addNewVersion && !versionIsSelected(data) ? 'primary' : 'secondary'"
                                    @click="() => !check.addNewVersion || versionIsSelected(data) ? onClickEditButton(data) : null"
                                    :class="check.addNewVersion || versionIsSelected(data) ? 'disable-pui-button': ''"
                                />
                                <pui-link
                                    :title="$t('remove')"
                                    icon="delete"
                                    :disable="check.addNewVersion "
                                    :variant="!check.addNewVersion ? 'primary' : 'secondary'"
                                    @click="() => !check.addNewVersion ? onClickDeleteButton(data) : null"
                                    :class="check.addNewVersion ? 'disable-pui-button': ''"
                                />
                            </pui-grid-row>
                        </pui-grid-container>
                    </template>
                </custom-table>
            </pui-grid-row>
            <pui-grid-row class="pui-grid-row--end pui-grid-row-little-margin-top">
                <pui-button
                    icon="add"
                    state="secondary"
                    @click="onClickAddNewVersionButton"
                    v-if="!check.addNewVersion"
                >
                    {{ $t('addNewVersion') }}
                </pui-button>
            </pui-grid-row>
            <pui-grid-row
                :class="errors.length > 0 ? 'pebble-errors-list-margin-top' : ''"
            >
                <pebble-errors-list
                    id="errors-list"
                    :errors="errors"
                />
            </pui-grid-row>
            <pui-form
                aria-label="Form"
                v-if="check.dataLoaded"
            >
                <pui-grid-row>
                    <pui-grid-column class="pui-grid-column-zero-padding">
                        <reservoir-identification-form
                            :data="formValues"
                            :rivers-list="riversList"
                            :rivers-group-list="riversGroupList"
                            :is-edit="true"
                            :is-add-new-version="check.addNewVersion"
                            @validate="validate"
                        />
                    </pui-grid-column>
                </pui-grid-row>
            </pui-form>
            <pui-grid-row class="form-group">
                <pui-grid-column class="pui-grid-column-zero-padding" :cols="{s:12, m:5, l:6, xl:6, xxl: 6}">
                    <pebble-last-updated
                        v-if="check.dataLoaded"
                        :data="{
                            lastUpdated: selectedVersion.lastUpdated,
                            lastUpdatedBy: selectedVersion.lastUpdatedBy,
                        }"
                    />
                </pui-grid-column>
                <pui-grid-column class="pui-grid-column-zero-padding" :cols="{s:12, m:7, l:6, xl:6, xxl: 6}">
                    <cancel-save-buttons
                        v-if="check.dataLoaded"
                        :cancel-button-is-disabled="!check.addNewVersion && check.saveButtonIsDisabled"
                        :save-button-is-disabled="!hasMasterDataAdmin || check.saveButtonIsDisabled"
                        :on-click-cancel-button="onClickCancelButton"
                        :on-click-save-button="onClickSaveButton"
                    />
                </pui-grid-column>
            </pui-grid-row>
        </pui-grid-container>
        <pui-grid-container v-else-if="check.showError">
            <card-header
                :title="name"
                :chip-title="sid"
                :on-click-back-button="onClickBackButton"
            />
            <pui-grid-row>
                <pui-loader-error
                    :title="$t('errorTitleDataLoaded')"
                    :message="$t('errorGettingReservoirs')"
                    icon="error-alert"
                    :buttons="[
                    {
                        state: 'secondary',
                        label: $t('refresh'),
                        onClick: onClickRefreshButton,
                    }
                ]"
                />
            </pui-grid-row>
        </pui-grid-container>
    </card>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import {AssetService, ReservoirService} from '@/services';
import {PebbleDropDown, pebbleDropDownFromAssets} from '@/models/pebble/pebble-drop-down';
import {PebbleNotification} from '@/models/pebble/pebble-notification';
import BackText from '@/components/text/back-text.vue';
import Card from '@/components/cards/card.vue';
import CustomTable from '@/components/table/custom-table.vue';
import {CheckModel} from '@/models/check-model';
import PebbleErrorsList from '@/components/error-list/pebble-errors-list.vue';
import {Asset, IdTypeSid, ReservoirAddRequest, ReservoirQueryModel} from '@/models';
import {DATE_FORMAT, EventBus} from '@/utils';
import {generateDataFromFormValues, generateErrors, isValidInput, manageResolve, shouldDisableForm, showDialog} from '@/utils/utils';
import {IdentificationReservoirForm} from '@/models/form/identification-reservoir-form';
import {identificationReservoirForm} from '@/utils/pebble-form/master-data-management/identification-reservoir-form';
import ReservoirIdentificationForm from '@/components/forms/reservoir-identification-form.vue';
import PebbleLastUpdated from '@/components/last-updated/pebble-last-updated.vue';
import CancelSaveButtons from '@/components/buttons/cancel-save-buttons.vue';
import ComponentSecurity from '@/mixins/component-security';
import format from 'date-fns/format';
import {ReservoirFactory} from '@/utils/factories';
import {ReservoirModel} from '@/models/reservoir';
import CardHeader from '@/components/cards/card-header.vue';

@Component({
    name: 'management-reservoirs-edit',
    components: {
        CardHeader,
        CancelSaveButtons,
        PebbleLastUpdated,
        ReservoirIdentificationForm,
        PebbleErrorsList,
        CustomTable,
        Card,
        BackText,
    },
})
export default class ManagementReservoirsEdit extends mixins(ComponentSecurity) {
    /* VARIABLES */
    private service: ReservoirService = new ReservoirService();
    private assetService: AssetService = new AssetService();
    private riversList: PebbleDropDown[] = [];
    private riversGroupList: PebbleDropDown[] = [];
    private versions: ReservoirQueryModel[] = [];
    private errors: PebbleNotification[] = [];
    private sid?: string = '';
    private name?: string = '';
    private check: CheckModel = {
        dataLoaded: false,
        addNewVersion: false,
        saveButtonIsDisabled: true,
        showError: false,
    };

    /* LATE VARIABLES */
    private formValues!: IdentificationReservoirForm;
    private selectedVersion?: ReservoirModel;

    get heading(): any {
        return {
            validFrom: this.$t('validFrom').toString(),
            validTo: this.$t('validUntil').toString(),
            reservoirName: this.$t('reservoirName').toString(),
            lastUpdated: this.$t('lastUpdated').toString(),
            lastUpdatedBy: this.$t('lastUpdatedBy').toString(),
            action: '',
        };
    }

    /* PRIMITIVE METHODS */
    private mounted(): void {
        this.sid = this.$route.query['edit']?.toString();
        this.name = this.$route.query['name']?.toString() ?? this.$t('unknown');
        this.init();
    }

    private created(): void {
        this.initCreated();
    }

    private destroyed(): void {
        this.check.saveButtonIsDisabled = true;
        this.check.addNewVersion = false;
    }

    /* METHODS */
    private async init(): Promise<void> {
        this.$router.beforeResolve((to, from, next) => manageResolve((!this.check.saveButtonIsDisabled), next, this));
        this.errors = [];
        this.check.dataLoaded = false;
        this.$store.commit('loading');
        this.versions = await this.loadReservoirVersions();
        const selectedVersion = await this.loadSelectedReservoir(this.$route.query.validFrom);
        this.$store.commit('loading');
        if (selectedVersion) {
            this.selectedVersion = ReservoirFactory.reservoirQueryModelToReservoirModel(selectedVersion);
            this.name = this.selectedVersion.reservoirName;
            this.check.dataLoaded = true;
            this.check.addNewVersion = false;
            this.formValues = identificationReservoirForm(this.selectedVersion);
            this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedVersion);
        } else {
            this.check.showError = true;
        }
    }

    private async initCreated(): Promise<void> {
        this.$store.commit('loading');
        const [rivers, riversGroup] = await Promise.all([
            this.loadAssets(IdTypeSid.RIVER),
            this.loadAssets(IdTypeSid.RIVER_GROUP),
        ]).finally(() => {
            this.$store.commit('loading');
        });
        this.riversList = pebbleDropDownFromAssets(rivers);
        this.riversGroupList = pebbleDropDownFromAssets(riversGroup);
    }

    private async replacePage(validFrom?: string): Promise<void> {
        await this.$router.replace({
            name: 'management-reservoirs-edit',
            query: {
                edit: this.$route.query.edit,
                name: this.$route.query.name,
                validFrom,
            },
        });
    }

    private versionIsSelected(item: ReservoirQueryModel): boolean {
        return item.validFrom === this.selectedVersion?.validFrom ?? false;
    }

    private validate(value: string, name: string): void {
        isValidInput(name, this.formValues[name]);
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedVersion);
    }

    /* ON CLICK BUTTONS */
    private onClickRefreshButton(): void {
        this.init();
    }

    private async onClickEditButton(value: ReservoirQueryModel): Promise<void> {
        if (this.selectedVersion?.validFrom !== value.validFrom) {
            await this.replacePage(value.validFrom);
            await this.init();
            this.$forceUpdate();
        }
    }

    private onClickDeleteButton(value: ReservoirQueryModel): void {
        this.deleteReservoirVersion(value);
    }

    private onClickBackButton(): void {
        this.$router.push({
            name: `management-reservoirs`,
        });
    }

    private onClickAddNewVersionButton(): void {
        if (this.sid) {
            this.formValues = identificationReservoirForm(this.selectedVersion
                ? {...this.selectedVersion, validFrom: format(new Date(), DATE_FORMAT)}
                : ReservoirFactory.createNewReservoirVersion(+this.sid));
            this.check.addNewVersion = true;
            this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedVersion);
        }
    }

    private async onClickCancelButton(): Promise<void> {
        if (await showDialog(this, 'areYouSureCancelUnsaved')) {
            this.formValues = {...identificationReservoirForm(this.selectedVersion)};
            this.errors = [];
            this.check.addNewVersion = false;
            this.check.saveButtonIsDisabled = true;
        }
    }

    private onClickSaveButton(): void {
        this.saveData();
    }

    /* API CALLS */
    private async loadAssets(idTypeSid: number): Promise<Asset[]> {
        let assets: Asset[] = [];
        try {
            const {result: {items}} = await this.assetService.getByIdTypeSid(idTypeSid, {page: 1, size: 1000, term: ''});
            assets = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
        return assets;
    }

    private async loadSelectedReservoir(defaultValidFrom?: string | any[]): Promise<ReservoirQueryModel | undefined> {
        try {
            const version = typeof defaultValidFrom === 'string'
                ? this.versions.find(({validFrom}) => validFrom === defaultValidFrom)
                : this.versions[0];
            if (!version || !this.sid) {
                return;
            }
            return (await this.service.getBySidAndValidFrom(this.sid, version.validFrom)).result;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingReservoirs');
            throw err;
        }
    }

    private async loadReservoirVersions(): Promise<ReservoirQueryModel[]> {
        let versions: ReservoirQueryModel[] = [];
        try {
            if (!this.sid) {
                return versions;
            }
            const {result: {items}} = await this.service.getReservoirVersionsV2(this.sid);
            versions = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingProcurementData');
            throw err;
        }
        return versions;
    }

    private async deleteReservoirVersion(model: ReservoirQueryModel): Promise<void> {
        this.$store.commit('loading');
        try {
            if (!await showDialog(this, 'areYouSureDeleteItem')) {
                return;
            }
            await this.service.delete(model);
            if (this.$route.query.validFrom) {
                await this.replacePage();
            }
            await this.init();
            this.$forceUpdate();
            return;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorRemovingReservoir');
            generateErrors(this.errors, error);
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveNewVersion(model: ReservoirAddRequest): Promise<void> {
        this.$store.commit('loading');
        try {
            const {result: {validFrom}} = await this.service.create(model);
            this.check.addNewVersion = false;
            this.check.saveButtonIsDisabled = true;
            await this.replacePage(validFrom);
            await this.init();
            this.$forceUpdate();
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingReservoir');
            generateErrors(this.errors, error);
        } finally {
            this.$store.commit('loading');
        }
    }

    private async updateVersion(model: ReservoirAddRequest): Promise<void> {
        if (!this.selectedVersion) {
            return;
        }
        this.$store.commit('loading');
        try {
            await this.service.editV2(model);
            await this.init();
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorUpdatingReservoir');
            generateErrors(this.errors, error);
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveData(): Promise<void> {
        if (this.check.addNewVersion) {
            await this.saveNewVersion(generateDataFromFormValues(this.formValues) as ReservoirAddRequest);
        } else {
            await this.updateVersion(generateDataFromFormValues(this.formValues) as ReservoirAddRequest);
        }
    }

}
</script>

<style scoped>
.form-group {
    margin-top: 32px !important;
    margin-bottom: 16px !important;
}
</style>
