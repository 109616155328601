<template>
    <span :style="generateStyle()">
        {{ value ? titles.textTrue : titles.textFalse }}
    </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'boolean-text',
})
export default class BooleanText extends Vue {
    @Prop({required: true})
    private titles!: {
        textTrue: string;
        textFalse: string;
    };
    @Prop({required: true})
    private value!: boolean;

    @Prop({
        type: Object,
        default: () => ({
            textTrue: 'green',
            textFalse: 'red',
        }),
    })
    private decoration!: {
        textTrue: string;
        textFalse: string;
    };

    private generateStyle(): any {
        return {
            color: this.value ? this.decoration.textTrue : this.decoration.textFalse,
        };
    }
}
</script>
