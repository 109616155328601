<template>
    <card>
        <pui-grid-container>
            <pui-grid-row>
                <back-text :title="$t('newSynergyLifeLocation')" :on-press="onBackPress"/>
            </pui-grid-row>
            <pui-grid-row>
                <fake-single-tab
                    :title="$t('synergyLife')"
                />
            </pui-grid-row>
            <pui-form
                aria-label="Form"
            >
                <pui-grid-row>
                    <pui-grid-column class="pui-grid-column-zero-padding">
                        <sinergy-life-master-data-form
                            :data="data"
                            @validate="validate"
                        />
                    </pui-grid-column>
                </pui-grid-row>
                <pui-grid-row>
                    <pui-grid-column class="pui-grid-column-zero-padding">
                        <sinergy-life-data-form
                            :data="data"
                            @validate="validate"
                        />
                    </pui-grid-column>
                </pui-grid-row>
                <error-list :errors="invalidMessages" />
                <pui-grid-row class="pui-grid-row--end" style="padding: 16px 0;">
                    <pui-button
                        v-on:click="onSave"
                        :disabled="!hasMasterDataAdmin || saveButtonIsDisabled"
                        class="save-button">
                        {{ $t('save') }}
                    </pui-button>
                </pui-grid-row>
            </pui-form>
        </pui-grid-container>

    </card>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import BackText from '@/components/text/back-text.vue';
import SinergyLifeMasterDataForm from '@/components/forms/sinergy-life-master-data-form.vue';
import SinergyLifeDataForm from '@/components/forms/sinergy-life-data-form.vue';
import Card from '@/components/cards/card.vue';
import ErrorList from '@/components/error-list/error-list.vue';
import {sinergylifeForm} from '@/utils/pebble-form/sinergy-life-location/sinergylife-form';
import PebbleTextFieldArea from '@/components/pebble-form-element/pebble-text-field-area.vue';
import {FormValuesModel} from '@/models/pebble/form-values-model';
import {Mixins} from 'vue-property-decorator';
import ComponentSecurity from '@/mixins/component-security';
import { SynergyLifeService } from '@/services';
import { EventBus, extractErrorsFromResponse } from '@/utils';
import { AddSynergyRequest } from '@/models';
import FakeSingleTab from '@/components/tab-bar/fake-single-tab.vue';

@Component({
    name: 'sinergy-life-location-new',
    components: {
        errorList: ErrorList,
        FakeSingleTab,
        PebbleTextFieldArea,
        Card,
        SinergyLifeDataForm,
        SinergyLifeMasterDataForm,
        BackText,
    },
})
export default class SinergyLifeLocationNew extends Mixins(ComponentSecurity) {
    private data: AddSynergyRequest | null = null;
    private saveButtonIsDisabled = false;
    private syngergylifeService = new SynergyLifeService();
    private invalidMessages: string[] = [];

    private created(): void {
        this.data = sinergylifeForm();
    }

    private onBackPress(): void {
        this.$router.push({
            name: `sinergy-life-location`,
            path: 'sinergy-life-location',
        });
    }

    private validate(value: string, name: string): void {
        const input: FormValuesModel = this.data?.[name];
        if (input.validator) {
            input.isValid = input.validator(input.value);
        }
    }

    private shouldDisable(): boolean {
        if (this.data) {
            const validator: boolean = Object.keys(this.data).map((inputName) => {
                    const input: FormValuesModel = this.data?.[inputName];
                    if (input.validator) {
                        return input.validator(input.value);
                    }
                    return true;
                },
            ).every((value) => value);
            return !(validator);
        }

        return false;
    }

    private async onSave(): Promise<void> {
        this.invalidMessages = [];
        this.$store.commit('loading');
        try {
            if (this.data) {
                const addRequest = {
                    sid: this.data.sid?.value,
                    slCountry: this.data.slCountry?.value,
                    slLocation: this.data.slLocation?.value,
                    objectTypeSid: +this.data.enumObjectTypeSid?.value,
                    tableName: this.data.tableName?.value,
                    uniperCountryShort: this.data.uniperCountryShort?.value,
                    uniperLocationShort: this.data.uniperLocationShort?.value,
                    uniperLocationName: this.data.uniperLocationName?.value,
                    locationType: this.data.locationType?.value,
                    slLocationLastLevel: this.data.slLocationLastLevel?.value,
                    slLocationLevel1: this.data.slLocationLevel1?.value,
                    slLocationLevel2: this.data.slLocationLevel2?.value,
                    slLocationLevel3: this.data.slLocationLevel3?.value,
                    slLocationLevel4: this.data.slLocationLevel4?.value,
                    lastUpdateComment: this.data.lastUpdateComment?.value,
                    validFrom: this.data.validFrom?.value,
                    isDeleted: this.data.isDeleted?.value,
                };
                await this.syngergylifeService.create(addRequest);
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
                this.$router.push({
                    name: 'sinergy-life-location',
                });
            }
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingSynergyLife');
            this.invalidMessages = extractErrorsFromResponse(err.response.data);
        } finally {
            this.$store.commit('loading');
        }
    }
}
</script>
