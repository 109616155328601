import { VuexModule, Module, Action, MutationAction } from 'vuex-module-decorators';
import { IdTypeSid, SelectOption } from '@/models';
import { assetService, businessPartnersService, GenericService } from '@/services';
import { reservoirService } from '@/services/reservoir-service';

@Module({ namespaced: true })
class HierarchyAssetsModule extends VuexModule {
    public timezones: SelectOption[] = [];
    public countries: SelectOption[] = [];
    public states: SelectOption[] = [];
    public regions: SelectOption[] = [];
    public locationTypes: SelectOption[] = [];
    public organizations: SelectOption[] = [];
    public plantGroups: SelectOption[] = [];
    public operators: SelectOption[] = [];
    public externalSystems: SelectOption[] = [];

    public fleets: SelectOption[] = [];

    public unitTypes: SelectOption[] = [];
    public sapEcTypes: SelectOption[] = [];
    public mbsBlockTypes: SelectOption[] = [];
    public technologies: SelectOption[] = [];
    public incidentPenaltyTypes: SelectOption[] = [];
    public kksCategories: SelectOption[] = [];
    public classes: SelectOption[] = [];
    public rivers: SelectOption[] = [];
    public riverGroups: SelectOption[] = [];
    public reservoirs: SelectOption[] = [];
    public operationStatuses: SelectOption[] = [];
    public operationStatusChangeReasons: SelectOption[] = [];
    public regionalUnits: SelectOption[] = [];
    public etsSites: SelectOption[] = [];
    public distributionTypes: SelectOption[] = [];
    public connectionAreas: SelectOption[] = [];
    public networkConnectionPoints: SelectOption[] = [];
    public networkLevels: SelectOption[] = [];

    public products: SelectOption[] = [];

    @Action
    public async loadPowerPlantAssets(): Promise<void> {
        await Promise.all([
            this.context.dispatch('loadTimezones'),
            this.context.dispatch('loadCountries'),
            this.context.dispatch('loadStates'),
            this.context.dispatch('loadRegions'),
            this.context.dispatch('loadLocationTypes'),
            this.context.dispatch('loadOrganizations'),
        ]);
    }

    @Action
    public async loadUnitAssets(): Promise<void> {
        await Promise.all([
            this.context.dispatch('loadUnitTypes'),
            this.context.dispatch('loadSapEcTypes'),
            this.context.dispatch('loadFleets'),
            this.context.dispatch('loadMbsBlockTypes'),
            this.context.dispatch('loadTechnologies'),
            this.context.dispatch('loadIncidentPenaltyTypes'),
            this.context.dispatch('loadKksCategories'),
            this.context.dispatch('loadClasses'),
            this.context.dispatch('loadRivers'),
            this.context.dispatch('loadRiverGroups'),
            this.context.dispatch('loadReservoirs'),
        ]);
    }


    @MutationAction({ mutate: ['timezones'] })
    public async loadTimezones(): Promise<any> {
        if (this.timezones.length > 0) {
            return undefined;
        }
        const timezones = (await assetService.getAssetsByIdType(IdTypeSid.TIME_ZONE))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { timezones };
    }

    @MutationAction({ mutate: ['countries'] })
    public async loadCountries(): Promise<any> {
        if (this.countries.length > 0) {
            return undefined;
        }
        const countries = (await assetService.getAssetsByIdType(IdTypeSid.COUNTRY))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: `${item.name ?? ''} - ${item.description ?? ''}`,
            }));
        return { countries };
    }

    @MutationAction({ mutate: ['states'] })
    public async loadStates(): Promise<any> {
        if (this.states.length > 0) {
            return undefined;
        }
        const states = (await assetService.getAssetsByIdType(IdTypeSid.STATE))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: `${item.name ?? ''} - ${item.description ?? ''}`,
            }));
        return { states };
    }

    @MutationAction({ mutate: ['regions'] })
    public async loadRegions(): Promise<any> {
        if (this.regions.length > 0) {
            return undefined;
        }
        const regions = (await assetService.getAssetsByIdType(IdTypeSid.REGION))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: `${item.name ?? ''} - ${item.description ?? ''}`,
            }));
        return { regions };
    }

    @MutationAction({ mutate: ['locationTypes'] })
    public async loadLocationTypes(): Promise<any> {
        if (this.locationTypes.length > 0) {
            return undefined;
        }
        const locationTypes = (await assetService.getAssetsByIdType(IdTypeSid.POWER_PLANT_TYPE))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: `${item.name ?? ''} - ${item.description ?? ''}`,
            }));
        return { locationTypes };
    }

    @MutationAction({ mutate: ['organizations'] })
    public async loadOrganizations(): Promise<any> {
        if (this.organizations.length > 0) {
            return undefined;
        }
        const organizations = (await assetService.getAssetsByIdType(IdTypeSid.ORGANIZATION_LIST))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { organizations };
    }

    @MutationAction({ mutate: ['plantGroups'] })
    public async loadPlantGroups(): Promise<any> {
        if (this.plantGroups.length > 0) {
            return undefined;
        }
        const plantGroups = (await assetService.getAssetsByIdType(IdTypeSid.PLANT_GROUP))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { plantGroups };
    }

    @MutationAction({ mutate: ['fleets'] })
    public async loadFleets(): Promise<any> {
        if (this.fleets.length > 0) {
            return undefined;
        }
        const fleets = (await assetService.getAssetsByIdType(IdTypeSid.FLEET))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { fleets };
    }

    // Unit

    @MutationAction({ mutate: ['unitTypes'] })
    public async loadUnitTypes(): Promise<any> {
        if (this.unitTypes.length > 0) {
            return undefined;
        }
        const unitTypes = (await assetService.getAssetsByIdType(IdTypeSid.UNIT_TYPE))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { unitTypes };
    }

    @MutationAction({ mutate: ['operators'] })
    public async loadOperators(): Promise<any> {
        if (this.operators.length > 0) {
            return undefined;
        }
        const operators = (await businessPartnersService.getBusinessPartners())
            .map((item: any): SelectOption => ({
                value: item.businessPartnerSid,
                label: item.name,
            }));
        return { operators };
    }

    @MutationAction({ mutate: ['sapEcTypes'] })
    public async loadSapEcTypes(): Promise<any> {
        if (this.sapEcTypes.length > 0) {
            return undefined;
        }
        const sapEcTypes = (await assetService.getAssetsByIdType(IdTypeSid.SAP_EC_TYPE))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: `${item.name ?? ''} - ${item.description ?? ''}`,
            }));
        return { sapEcTypes };
    }

    @MutationAction({ mutate: ['mbsBlockTypes'] })
    public async loadMbsBlockTypes(): Promise<any> {
        if (this.mbsBlockTypes.length > 0) {
            return undefined;
        }
        const mbsBlockTypes = (await assetService.getAssetsByIdType(IdTypeSid.MSB_TYPE))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: `${item.name ?? ''} - ${item.description ?? ''}`,
            }));
        return { mbsBlockTypes };
    }

    @MutationAction({ mutate: ['technologies'] })
    public async loadTechnologies(): Promise<any> {
        if (this.technologies.length > 0) {
            return undefined;
        }
        const technologies = (await assetService.getAssetsByIdType(IdTypeSid.TECHNOLOGY))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: `${item.name ?? ''} - ${item.description ?? ''}`,
            }));
        return { technologies };
    }

    @MutationAction({ mutate: ['incidentPenaltyTypes'] })
    public async loadIncidentPenaltyTypes(): Promise<any> {
        if (this.incidentPenaltyTypes.length > 0) {
            return undefined;
        }
        const incidentPenaltyTypes = (await assetService.getAssetsByIdType(IdTypeSid.INCIDENT_PENALTY_TYPE))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.description,
            }));
        return { incidentPenaltyTypes };
    }

    @MutationAction({ mutate: ['kksCategories'] })
    public async loadKksCategories(): Promise<any> {
        if (this.kksCategories.length > 0) {
            return undefined;
        }
        const kksCategories = (await assetService.getAssetsByIdType(IdTypeSid.KKS_CATEGORY))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: `${item.name ?? ''} - ${item.description ?? ''}`,
            }));
        return { kksCategories };
    }

    @MutationAction({ mutate: ['classes'] })
    public async loadClasses(): Promise<any> {
        if (this.classes.length > 0) {
            return undefined;
        }
        const classes = (await assetService.getAssetsByIdType(IdTypeSid.EQUIPMENT_CLASS_TYPE))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: `${item.name ?? ''} - ${item.description ?? ''}`,
            }));
        return { classes };
    }

    @MutationAction({ mutate: ['rivers'] })
    public async loadRivers(): Promise<any> {
        if (this.rivers.length > 0) {
            return undefined;
        }
        const rivers = (await assetService.getAssetsByIdType(IdTypeSid.RIVER))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: `${item.name ?? ''} - ${item.description ?? ''}`,
            }));
        return { rivers };
    }

    @MutationAction({ mutate: ['riverGroups'] })
    public async loadRiverGroups(): Promise<any> {
        if (this.riverGroups.length > 0) {
            return undefined;
        }
        const riverGroups = (await assetService.getAssetsByIdType(IdTypeSid.RIVER_GROUP))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: `${item.name ?? ''} - ${item.description ?? ''}`,
            }));
        return { riverGroups };
    }

    @MutationAction({ mutate: ['reservoirs'] })
    public async loadReservoirs(): Promise<any> {
        if (this.reservoirs.length > 0) {
            return undefined;
        }
        const reservoirs = (await reservoirService.getReservoirs())
            .map((item: any): SelectOption => ({
                value: item.reservoirSid,
                label: item.reservoirName,
            }));
        return { reservoirs };
    }

    @MutationAction({ mutate: ['externalSystems'] })
    public async loadExternalSystems(): Promise<any> {
        if (this.externalSystems.length > 0) {
            return;
        }
        const externalSystems =  (await assetService.getAssetsByIdType(IdTypeSid.EXTERNAL_SYSTEM))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { externalSystems };
    }

    @MutationAction({ mutate: ['operationStatuses'] })
    public async loadOperationStatuses(): Promise<any> {
        if (this.operationStatuses.length > 0) {
            return;
        }
        const operationStatuses =  (await assetService.getAssetsByIdType(IdTypeSid.OPERATION_STATUS_TYPE))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { operationStatuses };
    }

    @MutationAction({ mutate: ['operationStatusChangeReasons'] })
    public async loadOperationStatusChangeReasons(): Promise<any> {
        if (this.operationStatusChangeReasons.length > 0) {
            return;
        }
        const operationStatusChangeReasons =  (await assetService.getAssetsByIdType(IdTypeSid.OPERATION_STATUS_CHANGE_REASON))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { operationStatusChangeReasons };
    }

    @MutationAction({ mutate: ['regionalUnits'] })
    public async loadRegionalUnits(): Promise<any> {
        if (this.regionalUnits.length > 0) {
            return;
        }
        const regionalUnits =  (await assetService.getAssetsByIdType(IdTypeSid.REGIONAL_UNIT))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { regionalUnits };
    }

    @MutationAction({ mutate: ['etsSites'] })
    public async loadEtsSites(): Promise<any> {
        if (this.etsSites.length > 0) {
            return;
        }
        const etsSites =  (await new GenericService().getEtsUnits())?.result?.items
            ?.map((item: any): SelectOption => ({
                value: item.etsId,
                label: item.shortName,
            }));
        return { etsSites };
    }

    @MutationAction({ mutate: ['distributionTypes'] })
    public async loadDistributionTypes(): Promise<any> {
        if (this.distributionTypes.length > 0) {
            return;
        }
        const distributionTypes =  (await assetService.getAssetsByIdType(IdTypeSid.DISTRIBUTION_TYPE))
            ?.map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { distributionTypes };
    }

    @MutationAction({ mutate: ['connectionAreas'] })
    public async loadConnectionAreas(): Promise<any> {
        if (this.connectionAreas.length > 0) {
            return;
        }
        const connectionAreas =  (await assetService.getAssetsByIdType(IdTypeSid.CONNECTION_AREA))
            ?.map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { connectionAreas };
    }

    @MutationAction({ mutate: ['networkConnectionPoints'] })
    public async loadNetworkConnectionPoints(): Promise<any> {
        if (this.networkConnectionPoints.length > 0) {
            return;
        }
        const networkConnectionPoints =  (await assetService.getAssetsByIdType(IdTypeSid.NETWORK_CONNECTION_POINT))
            ?.map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { networkConnectionPoints };
    }

    @MutationAction({ mutate: ['networkLevels'] })
    public async loadNetworkLevels(): Promise<any> {
        if (this.networkLevels.length > 0) {
            return;
        }
        const networkLevels =  (await assetService.getAssetsByIdType(IdTypeSid.NETWORK_LEVEL))
            ?.map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { networkLevels };
    }

    @MutationAction({ mutate: ['products'] })
    public async loadProducts(): Promise<any> {
        if (this.products.length > 0) {
            return;
        }
        const products =  (await assetService.getAssetsByIdType(IdTypeSid.PRODUCT))
            ?.map((item: any): SelectOption => ({
                value: item.sid,
                label: `${item.name} - ${item.description}`,
            }));
        return { products };
    }
}

export default HierarchyAssetsModule;
