<template>
    <div class="operation-mode-product">
        <template>
            <table-view
                v-if="operationModeProducts.length && !isAddingNew"
                :header="header"
                :items="operationModeProducts"
                :is-selected-cb="isSelectedOperationModeProduct"
                @select-item="onSelectItem" />
        </template>
        <div v-if="formModel">
            <validation-observer ref="validationObserver">
                <div class="operation-mode-product__header">{{ $t('productDetails') }}</div>
                <validation-provider
                    tag="div"
                    class="manage-input mt-3"
                    name="operationModeProduct"
                    rules="required"
                    #default="{ failed, errors }">
                    <label class="mr-2 default-label">{{ $t('operationModeProducts') }}*:</label>
                    <select class="default-select" v-model="formModel.productSid"
                        :class="{ 'invalid': failed }"
                        :disabled="!isAddingNew"
                        :placeholder="$t('operationModeProduct')">
                        <option v-for="product of productList"
                            :key="product.sid"
                            :value="product.sid">
                            {{ product.name }} - {{ product.description }}
                        </option>
                    </select>
                    <error-list :errors="errors" />
                </validation-provider>
                <div
                    v-if="!isAddingNew"
                    class="manage-input mt-3"
                    name="lastUpdatedBy">
                    <label class="mr-2 default-label" for="lastUpdatedBy">{{ $t('lastUpdatedBy') }}:</label>
                    <input
                        v-model="formModel.lastUpdatedBy"
                        :placeholder="$t('lastUpdatedBy')"
                        disabled="true"
                        id="lastUpdatedBy" />
                </div>
                <div
                    v-if="!isAddingNew"
                    class="manage-input mt-3"
                    name="editedOn">
                    <label class="mr-2 default-label" for="editedOn">{{ $t('editedOn') }}:</label>
                    <input
                        :value="getFormatedDate(formModel.lastUpdated)"
                        :disabled="true"/>
                </div>
                <validation-provider
                    v-if="isAddingNew"
                    tag="div"
                    class="manage-input mt-3"
                    name="comments"
                    rules="required|max:50"
                    #default="{ errors, failed }">
                    <label class="mr-2 default-label" for="comments">{{ $t('comments') }}:</label>
                    <textarea class="default-textarea" :placeholder="$t('comments')" id="comments" rows="4"
                        maxlength="50"
                        :class="{ 'invalid': failed }"
                        v-model="formModel.lastUpdateComment" />
                    <error-list :errors="errors" />
                </validation-provider>
            </validation-observer>
        </div>
        <error-list :errors="invalidMessages" />
        <div class="operation-mode-product__actions mt-2">
            <adam-button v-if="selectedOperationModeProduct && !isAddingNew"
                secondary
                icon="ic-remove"
                class="ml-1"
                @click="handleRemoveClick">
                {{ $t('remove') }}
            </adam-button>
            <adam-button v-if="!isAddingNew" icon="ic-add-dashboard"
                class="ml-1"
                @click="handleAddNewClick"
                secondary>
                {{ $t('addNew') }}
            </adam-button>
            <adam-button v-if="isAddingNew"
                class="ml-1"
                secondary
                @click="handleCancelClick">
                {{ $t('cancel') }}
            </adam-button>
            <adam-button
                v-if="formModel && hasMasterDataAdmin && isAddingNew"
                class="ml-1"
                @click="handleSave()"
                secondary>
                {{ $t('save') }}
            </adam-button>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Ref, Mixins } from 'vue-property-decorator';
import { EventBus, extractErrorsFromResponse, InfiniteScrollingHelper } from '@/utils';
import {
    OperationModeGetResponse,
    OperationModeProductDto,
    OpModeProduct,
    Tabbable,
} from '@/models';
import { ProductService } from '@/services';
import CheckboxInput from '@/components/checkbox-input/checkbox-input.vue';
import ErrorList from '@/components/error-list/error-list.vue';
import tableHeader from './operation-mode-products-table-header';
import { ValidationObserver } from 'vee-validate';
import TableView from '@/components/view-details/table-view/table-view.vue';
import ComponentSecurity from '@/mixins/component-security';
import LastUpdated from '@/components/last-updated/last-updated.vue';
import { DateHelper } from '@/utils';

/**
 * Contents for the "Identification" tab of the
 * [`operation-mode-details` component](operation-mode-details.md)
 *
 * Show the versions of an operation mode, and enable the user to make changes
 */
@Component({
    name: 'operation-mode-product',
    components: {
        checkboxInput: CheckboxInput,
        errorList: ErrorList,
        tableView: TableView,
        lastUpdated: LastUpdated,
    },
})
export default class OperationModeProduct extends Mixins(ComponentSecurity) implements Tabbable {
    @Ref()
    private readonly validationObserver!: InstanceType<typeof ValidationObserver>;

    /**
     * Operation mode of which general details should be displayed
     */
    @Prop({ required: true })
    private data!: OperationModeGetResponse;

    private header = tableHeader;
    private operationModeProducts: OperationModeProductDto[] = [];
    private productList: OpModeProduct[] = [];
    private productService = new ProductService();

    private selectedOperationModeProduct: OperationModeProductDto | null = null;

    private isAddingNew = false;
    private invalidMessages: string[] = [];
    private formModel: OperationModeProductDto | null = null;

    public async beforeLeave(): Promise<boolean> {
        if (!this.validationObserver || !this.validationObserver.flags.dirty) {
            return true;
        }

        return await this.showDialog('areYouSureLeaveUnsaved');
    }

    private getFormatedDate(date: string): string {
        return DateHelper.formatDate(date);
    }

    private async showDialog(message: string): Promise<boolean> {
        try {
            await this.$dialog
                .confirm(
                    { body: this.$t(message).toString() },
                    { view: 'confirm' });
            return true;
        } catch (error: any) {
            return Promise.resolve(false);
        }
    }

    private async mounted(): Promise<void> {
        EventBus.$on(EventBus.DETAIL.CLOSE, this.closeDetailsView);
        this.productList = await this.productService.getAvailableProducts();
        await Promise.all([
            this.productService.getAvailableProducts(),
            this.setOperationModeProduct(),
        ]);
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.DETAIL.CLOSE);
    }

    private async getProducts(): Promise<void> {
        this.operationModeProducts = await this.productService.getProducts(
            this.data.operationModeSid);
    }

    private isSelectedOperationModeProduct(item: OperationModeProductDto): boolean {
        return this.selectedOperationModeProduct?.productSid === item.productSid;
    }

    private async setOperationModeProduct(): Promise<void> {
        await this.getProducts();

        const defaultVersion = this.operationModeProducts.length
            ? this.operationModeProducts[this.operationModeProducts.length - 1]
            : null;

        if (!defaultVersion) {
            return;
        }
        this.setSelectedProduct(defaultVersion);
    }

    private async onSelectItem(item: OperationModeProductDto): Promise<void> {
        const canLeave = await this.isFormDirty();
        if (canLeave) {
            this.setSelectedProduct(item);
            this.validationObserver?.reset();
        }
    }

    private async setSelectedProduct(item: OperationModeProductDto): Promise<void> {
        try {
            this.selectedOperationModeProduct = { ...item };
            this.formModel = { ...this.selectedOperationModeProduct };
            this.isAddingNew = false;
            this.invalidMessages = [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingOperationMode');
            throw error;
        }
    }

    private async closeDetailsView(): Promise<void> {
        const canLeave = await this.beforeLeave();
        if (canLeave) {
            /**
             * Fired when the user wishes to close the `view-details` screen without
             * performing any changes
             */
            this.$emit('close');
        }
    }

    private async handleSave(): Promise<void> {
        this.invalidMessages = [];

        if (!this.formModel) {
            return;
        }

        try {
            const isValid = await this.validationObserver.validate();
            if (!isValid) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.invalid');
                });
                return;
            }
        } catch (error: any) {
            return;
        }

        if (this.isAddingNew) {
            await this.saveNew(this.formModel);
        }
        this.isAddingNew = false;
        await this.setOperationModeProduct();
        this.validationObserver?.reset();
    }

    private async saveNew(model: OperationModeProductDto): Promise<void> {
        this.$store.commit('loading');
        try {
            await this.productService.addProduct(
                this.data.operationModeSid,
                {
                    operationModeSid: this.data.operationModeSid,
                    productSid: model.productSid,
                    lastUpdateComment: model.lastUpdateComment as string,
                },
            );
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingProduct');
            if (!error.response || !error.response.data) {
                throw error;
            }
            this.invalidMessages = extractErrorsFromResponse(error.response.data);
            if (this.invalidMessages.length) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.error-list__message');
                });
            }
        } finally {
            this.$store.commit('loading');
        }
    }

    private async isFormDirty(): Promise<boolean> {
        if (!this.validationObserver || !this.validationObserver.flags.dirty) {
            return true;
        }

        return await this.showDialog('areYouSureSelectAnotherVersion');
    }

    private async handleAddNewClick() {
        const canLeave = await this.isFormDirty();
        if (!canLeave) {
            return;
        }

        this.formModel = {
            productSid: this.productList[0].sid,
            productName: this.productList[0].name,
            lastUpdatedBy: '',
            lastUpdated: '',
            lastUpdateComment: '',
        };
        this.isAddingNew = true;
        if (this.validationObserver) {
            this.validationObserver?.reset();
        }
    }

    private async handleCancelClick() {
        this.isAddingNew = false;
        this.formModel = this.selectedOperationModeProduct
            ? { ...this.selectedOperationModeProduct }
            : null;
        this.invalidMessages = [];
        this.validationObserver?.reset();
    }

    private async handleRemoveClick() {
        if (!this.selectedOperationModeProduct) {
            return;
        }
        try {
            await this.$dialog
                .confirm(
                    { body: this.$t('areYouSureDeleteItem').toString() },
                    { view: 'confirm' });
        } catch (error: any) {
            return;
        }

        this.$store.commit('loading');
        try {
            await this.productService.deleteProduct(
                this.data.operationModeSid, this.selectedOperationModeProduct.productSid,
            );
            await this.setOperationModeProduct();
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorRemovingOperationModeVersion');
            if (!error.response || !error.response.data) {
                throw error;
            }
            this.invalidMessages = extractErrorsFromResponse(error.response.data);
            if (this.invalidMessages.length) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.error-list__message');
                });
            }
        } finally {
            this.$store.commit('loading');
        }
    }
}

</script>

<style scoped lang="less">
@import '~@/variables.less';

.operation-mode-product {
    &__actions {
        display: flex;
        justify-content: flex-end;
    }

    &__header {
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-size: 2rem;
        font-family: @font-roboto-md;
        border-bottom: 1px solid @grey-lighter;
    }
}

label {
    margin-top: 1rem;
    min-width: fit-content;
}
input, textarea {
    width: 100%;
}
</style>
