import {
    ApiResponse, ListResponse, OperationStatus, OperationStatusAddRequest, Unit, UnitOperationModeDto,
    UnitOperationModeAddRequest, UnitOperationModeAddResponse, UnitVersioningQuery, UnitVersion, UnitEtsUnit,
    UnitRegionalUnits, UnitOperatorUnit, UnitOwnerUnit, UpdateBase, OperationStatusVersion, RegionalUnitVersion,
    EtsSiteVersion, OperationalTimelineVersion, AssetOwnerModel, OperationModeListItem,
} from '@/models';
import {UnitIdentification} from '@/models/asset-master-data/unit-identification';
import {UnitVersionItem} from '@/models/asset-master-data/unit-version-item';
import {store} from '@/store';
import {EventBus} from '@/utils';
import ErrorHandler from '@/utils/error-handler';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
import { generateQuery } from '@/utils/api-helper';

class UnitService {
    private api = new SdkGenericApiHelper('units');

    public async add(unit: Unit): Promise<ApiResponse<Unit>> {
        return (await this.api.post(unit)).data;
    }

    public async get(queryParams?: {powerPlantId?: number; countryId?: number; unitTypeId?: number}): Promise<ApiResponse<any>> {
        const query = generateQuery(queryParams as any);
        return (await this.api.get(query)).data;
    }

    public async getUnitVersions(sid: number): Promise<ApiResponse<ListResponse<UnitVersion>>> {
        return (await this.api.getByPathOrParam(`${sid}/versions`)).data;
    }

    public async getById(sid: number, filter?: UnitVersioningQuery): Promise<ApiResponse<Unit>> {
        const query = generateQuery(filter ? (filter as any) : {});
        return (await this.api.get(query, `${sid}`)).data;
    }

    public async edit(unit: Unit, initialUnitVersionValidFrom: string): Promise<ApiResponse<Unit>> {
        return (await this.api.put(`${unit.unitSid}/versions/from/${initialUnitVersionValidFrom}`, unit)).data;
    }

    public async addVersion(unit: Unit): Promise<ApiResponse<Unit>> {
        return (await this.api.post(unit, `${unit.unitSid}/versions`)).data;
    }

    public async deleteVersion({unitSid}: Unit | UnitIdentification, validFrom: string): Promise<ApiResponse<Unit>> {
        return (await this.api.delete(`${unitSid}/versions/from/${validFrom}`)).data;
    }

    public async getOperationStatuses(unitSid: number): Promise<ApiResponse<ListResponse<OperationStatus>>> {
        return (await this.api.getByPathOrParam(`${unitSid}/operation-statuses`)).data;
    }

    public async getOperationModes(unitSid: number): Promise<ApiResponse<ListResponse<UnitOperationModeDto>>> {
        return (await this.api.getByPathOrParam(`${unitSid}/operation-modes`)).data;
    }

    public async getEtsUnits(unitSid: number): Promise<ApiResponse<ListResponse<UnitEtsUnit>>> {
        return (await this.api.getByPathOrParam(`${unitSid}/ets-sites`)).data;
    }

    public async getRegionalUnits(unitSid: number): Promise<ApiResponse<ListResponse<UnitRegionalUnits>>> {
        return (await this.api.getByPathOrParam(`${unitSid}/regional-units`)).data;
    }

    public async getOperatorUnits(unitSid: number): Promise<ApiResponse<ListResponse<UnitOperatorUnit>>> {
        return (await this.api.getByPathOrParam(`${unitSid}/operators`)).data;
    }

    public async getOwnerUnits(unitSid: number): Promise<ApiResponse<ListResponse<UnitOwnerUnit>>> {
        return (await this.api.getByPathOrParam(`${unitSid}/owners`)).data;
    }

    public async postUnit(
        unitSid: number, apiString: string, data: UpdateBase,
    ): Promise<ApiResponse<UpdateBase>> {
        return (await this.api.post(data, `${unitSid}/${apiString}`)).data;
    }

    public async putUnit(
        unitSid: number, apiString: string, validFrom: string, data: UpdateBase,
    ): Promise<ApiResponse<UpdateBase>> {
        return (await this.api.put(`${unitSid}/${apiString}/versions/${validFrom}`, data)).data;
    }

    public async deleteUnit(
        uSid: number, apiString: string, vFrom: string,
    ): Promise<ApiResponse<UpdateBase>> {
        return (await this.api.delete(`${uSid}/${apiString}/versions/${vFrom}`)).data;
    }

    public async putOwnerUnit(
        unitSid: number, apiString: string, ownerSid: number, data: UnitOwnerUnit,
    ): Promise<ApiResponse<UnitOwnerUnit>> {
        return (await this.api.put(`${unitSid}/${apiString}/${ownerSid}`, data)).data;
    }

    public async deleteOwnerUnit(
        unitSid: number, apiString: string, ownerSid: number,
    ): Promise<ApiResponse<UnitOwnerUnit>> {
        return (await this.api.delete(`${unitSid}/${apiString}/${ownerSid}`)).data;
    }

    public async addOperationStatus(unitSid: number,
                                    operationStatus: OperationStatusAddRequest,
    ): Promise<ApiResponse<OperationStatus>> {
        const {data} = await this.api.post(operationStatus, `${unitSid}/operation-statuses`);
        return data;
    }

    public async updateOperationStatus(unitSid: number,
                                       operationStatusValidFrom: string,
                                       operationStatus: OperationStatus,
    ): Promise<ApiResponse<OperationStatus>> {
        const {data} =
            await this.api.put(
                `${unitSid}/operation-statuses/from/${operationStatusValidFrom}`,
                operationStatus,
            );
        return data;
    }

    public async removeOperationStatus(unitSid: number,
                                       {validFrom}: OperationStatus,
    ): Promise<ApiResponse<unknown>> {
        const {data: {result}} = (await this.api.delete(`${unitSid}/operation-statuses/from/${validFrom}`));
        return result;
    }

    public async addOperationMode(unitSid: number,
                                  operationMode: UnitOperationModeAddRequest,
    ): Promise<ApiResponse<UnitOperationModeAddResponse>> {
        const {data} = await this.api.post(operationMode, `${unitSid}/operation-modes`);
        return data;
    }

    public async addNewOperationMode(
        operationMode: UnitOperationModeAddRequest,
    ): Promise<ApiResponse<UnitOperationModeAddResponse>> {
        const {data} = await this.api.post(operationMode, `${operationMode.unitSid}/operation-modes`);
        return data;
    }

    public async addUnit(unitIdentification: UnitIdentification): Promise<boolean> {
        store.commit('loading');
        try {
            await this.add(UnitIdentification.mapUnitAddModel(unitIdentification));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingAsset');
            return false;
        } finally {
            store.commit('loading');
        }
    }

    public async editUnit(unitIdentification: UnitIdentification, validFrom: string): Promise<void> {
        store.commit('loading');
        try {
            await this.edit(UnitIdentification.mapUnitAddModel(unitIdentification), validFrom);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorEditingAsset');
        } finally {
            store.commit('loading');
        }
    }

    public async getUnit(unitSid: number, fromDate?: Date): Promise<UnitIdentification> {
        store.commit('loading');
        try {
            const result = (await this.getById(unitSid, {
                from: fromDate ? new Date(fromDate).toISOString() : ''
            }))?.result;
            return new UnitIdentification(result);
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingPowerPlant');
            return new UnitIdentification();
        } finally {
            store.commit('loading');
        }
    }

    public async getVersionsOfUnit(unitId: number): Promise<UnitVersionItem[]> {
        store.commit('loading');
        try {
            const result = (await this.getUnitVersions(unitId))?.result?.items;
            return result?.map((item: UnitVersion, index: number) => new UnitVersionItem(item, index)) ?? [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingPowerPlant');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async getUnitByVersion(version: UnitVersionItem): Promise<any> {
        store.commit('loading');
        try {
            const querry = {
                from: version.validFrom,
            };
            return (await this.getById(version.unitSid, querry))?.result;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingPowerPlant');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async deleteUnitVersion(unit: Unit | any, validFrom: string): Promise<boolean> {
        try {
            store.commit('loading');
            await this.deleteVersion(unit, validFrom);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            const message = ErrorHandler.getAxiosErrorMessage(error) ?? 'errorRemovingItem';
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, message);
            return false;
        } finally {
            store.commit('loading');
        }
    }

    public async addUnitVersion(unit: Unit | any): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.addVersion(unit);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async getOperationStatusVersions(unitSid: number): Promise<OperationStatusVersion[]> {
        store.commit('loading');
        try {
            return (await this.getOperationStatuses(unitSid))
                ?.result
                ?.items
                ?.map((item) => new OperationStatusVersion(item)) ?? [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingOperationStatuses');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async addOperationStatusVersion(operationStatus: OperationStatusVersion): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.addOperationStatus(operationStatus.unitSid,
                OperationStatusVersion.mapAddEditOperationStatus(operationStatus));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async updateOperationStatusVersion(operationStatus: OperationStatusVersion, initialValidFrom: string): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.updateOperationStatus(operationStatus.unitSid,
                initialValidFrom,
                OperationStatusVersion.mapAddEditOperationStatus(operationStatus));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async deleteOperationStatusVersion(operationStatus: OperationStatusVersion): Promise<boolean> {
        try {
            store.commit('loading');
            await this.removeOperationStatus(operationStatus.unitSid,
                {validFrom: operationStatus.validFrom} as OperationStatus);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            const errorMessage = ErrorHandler.getAxiosErrorMessage(error) ?? 'errorRemovingItem';
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, errorMessage);
            return false;
        } finally {
            store.commit('loading');
        }
    }

    public async getRegionalUnitVersions(unitSid: number): Promise<RegionalUnitVersion[]> {
        store.commit('loading');
        try {
            return (await this.getRegionalUnits(unitSid))
                ?.result
                .items
                ?.map((item) => new RegionalUnitVersion(item)) ?? [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingRegionalUnits');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async addRegionalUnitVersion(regionalUnit: RegionalUnitVersion): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.api.post(regionalUnit, `${regionalUnit?.unitSid}/regional-units`);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async updateRegionalUnitVersion(regionalUnit: RegionalUnitVersion, initialValidFrom: string): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.api.put(`${regionalUnit?.unitSid}/regional-units/versions/${initialValidFrom}`,
                regionalUnit);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async deleteRegionalUnitVersion(regionalUnit: RegionalUnitVersion): Promise<boolean> {
        try {
            store.commit('loading');
            await this.api.delete(`${regionalUnit?.unitSid}/regional-units/versions/${regionalUnit?.validFrom}`);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            const errorMessage = ErrorHandler.getAxiosErrorMessage(error) ?? 'errorRemovingItem';
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, errorMessage);
            return false;
        } finally {
            store.commit('loading');
        }
    }

    public async getEtsSiteVersions(unitSid: number): Promise<EtsSiteVersion[]> {
        store.commit('loading');
        try {
            return (await this.getEtsUnits(unitSid))
                ?.result
                .items
                ?.map((item) => new EtsSiteVersion(item)) ?? [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingEtsSites');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async addEtsSiteVersion(etsSite: EtsSiteVersion): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.api.post(etsSite, `${etsSite?.unitSid}/ets-sites`);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingEtsUnit');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async updateEtsSiteVersion(etsSite: EtsSiteVersion, initialValidFrom: string): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.api.put(`${etsSite?.unitSid}/ets-sites/versions/${initialValidFrom}`, etsSite);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async deleteEtsSiteVersion(etsSite: EtsSiteVersion): Promise<boolean> {
        try {
            store.commit('loading');
            await this.api.delete(`${etsSite?.unitSid}/ets-sites/versions/${etsSite?.validFrom}`);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            const errorMessage = ErrorHandler.getAxiosErrorMessage(error) ?? 'errorRemovingItem';
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, errorMessage);
            return false;
        } finally {
            store.commit('loading');
        }
    }

    public async getOperationalTimelineVersions(unitSid: number): Promise<OperationalTimelineVersion[]> {
        store.commit('loading');
        try {
            return (await this.getOperatorUnits(unitSid))
                ?.result
                .items
                ?.map((item) => new OperationalTimelineVersion(item)) ?? [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingTimelineList');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async addOperationalTimelineVersion(operationalTimeline: OperationalTimelineVersion): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.api.post(OperationalTimelineVersion.mapToAddEditRequest(operationalTimeline),
                `${operationalTimeline?.unitSid}/operators`);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async updateOperationalTimelineVersion(operationalTimeline: OperationalTimelineVersion, initialValidFrom: string): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.api.put(`${operationalTimeline?.unitSid}/operators/versions/${initialValidFrom}`,
                OperationalTimelineVersion.mapToAddEditRequest(operationalTimeline));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async deleteOperationalTimelineVersion(operationalTimeline: OperationalTimelineVersion): Promise<boolean> {
        try {
            store.commit('loading');
            await this.api.delete(`${operationalTimeline?.unitSid}/operators/versions/${operationalTimeline?.validFrom}`);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            const errorMessage = ErrorHandler.getAxiosErrorMessage(error) ?? 'errorRemovingItem';
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, errorMessage);
            return false;
        } finally {
            store.commit('loading');
        }
    }

    public async getAssetOwners(unitSid: number): Promise<AssetOwnerModel[]> {
        store.commit('loading');
        try {
            return (await this.getOwnerUnits(unitSid))
                ?.result
                .items
                ?.map((item) => new AssetOwnerModel(item)) ?? [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssetOwners');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async addAssetOwner(assetOwner: AssetOwnerModel): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.api.post(AssetOwnerModel.mapToAddEditRequest(assetOwner),
                `${assetOwner?.unitSid}/owners`);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async updateAssetOwner(assetOwner: AssetOwnerModel): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.api.put(`${assetOwner.unitSid}/owners/${assetOwner.ownerSid}`,
                AssetOwnerModel.mapToAddEditRequest(assetOwner));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async deleteAssetOwner(assetOwner: AssetOwnerModel): Promise<boolean> {
        try {
            store.commit('loading');
            await this.api.delete(`${assetOwner.unitSid}/owners/${assetOwner.ownerSid}`);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            const errorMessage = ErrorHandler.getAxiosErrorMessage(error) ?? 'errorRemovingItem';
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, errorMessage);
            return false;
        } finally {
            store.commit('loading');
        }
    }

    public async getOperationModeDetails(unitSid: number): Promise<OperationModeListItem[]> {
        store.commit('loading');
        try {
            return (await this.getOperationModes(unitSid))
                ?.result
                ?.items
                ?.map((item) => new OperationModeListItem(item)) ?? [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingOperationModes');
            return [];
        } finally {
            store.commit('loading');
        }
    }

}

const unitService = new UnitService();

export {
    UnitService,
    unitService,
};
