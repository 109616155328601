export class AssetOverviewRequestParams {
    public countries: number[];
    public machineTypes: number[];
    public primaryEnergies: number[];
    public rivers: number[];
    public regionalUnits: number[];
    public statuses: number[];
    public legalEntities: number[];
    public unitTechnologies: number[];

    public sortColumn?: string;
    public sortDirection?: string;
    public page?: number;
    public size?: number;
    public term?: string;

    constructor(data?: any) {
        this.countries = data?.countries;
        this.machineTypes = data?.machineTypes;
        this.primaryEnergies = data?.primaryEnergies;
        this.rivers = data?.rivers;
        this.regionalUnits = data?.regionalUnits;
        this.statuses = data?.statuses;
        this.legalEntities = data?.legalEntities;
        this.unitTechnologies = data?.unitTechnologies;

        this.sortColumn = data?.sortColumn;
        this.sortDirection = data?.sortDirection;
        this.page = data?.page;
        this.size = data?.size;
        this.term = data?.term;
    }
}
