export class OperationModeProduct {
    public static mapToAddRequest(product?: OperationModeProduct): any {
        return {
            operationModeSid: product?.operationModeSid,
            productSid: product?.productSid,
            lastUpdateComment: product?.lastUpdateComment,
        };
    }

    public static mapToEditRequest(initialProductId: number, product?: OperationModeProduct): any {
        return {
            operationModeSid: product?.operationModeSid,
            existingProductId: initialProductId,
            productSid: product?.productSid,
            lastUpdateComment: product?.lastUpdateComment,
        };
    }

    public operationModeSid: number;
    public productSid: number;
    public productName?: string;
    public lastUpdatedBy?: string;
    public lastUpdated?: string;
    public lastUpdateComment?: string;

    constructor(data?: any) {
        this.operationModeSid = data?.operationModeSid;
        this.productSid = data?.productSid;
        this.productName = data?.productName;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdated = data?.lastUpdated;
        this.lastUpdateComment = data?.lastUpdateComment;
    }
}
