<template>
    <card>
        <pui-grid-container v-if="check.dataLoaded">
            <card-header
                :title="name"
                :chip-title="sid"
                :on-click-back-button="onBackPress"
            />
            <pui-grid-row>
                <pui-grid-column class="pui-grid-column-zero-padding pui-grid-row-little-margin-top">
                    <!--
                    TODO BUG: When I change tab there is no way to stop the change of
                        the tab if by chance a condition is not valid, in fact if you
                        make a change on the form of any page, the latter will be
                        automatically lost.
                    -->
                    <pui-tabs
                        :selected="selectedTab"
                        @changed="onTabChange"
                    >
                        <pui-tab
                            v-for="tab in tabs"
                            :key="tab.title"
                            :title="tab.title"
                        >
                            <component
                                :is="tab.componentName"
                                :data="data"
                                @on-form-changed="onFormChanged"
                            />
                        </pui-tab>
                    </pui-tabs>
                </pui-grid-column>
            </pui-grid-row>
        </pui-grid-container>
        <pui-grid-container v-else-if="check.showError">
            <card-header
                :title="name"
                :chip-title="sid"
                :on-click-back-button="onBackPress"
            />
            <pui-grid-row>
                <pui-loader-error
                    :title="$t('errorTitleDataLoaded')"
                    :message="$t('errorGettingOperationMode')"
                    icon="error-alert"
                    :buttons="[
                    {
                        state: 'secondary',
                        label: $t('refresh'),
                        onClick: onClickRefreshButton,
                    }
                ]"
                />
            </pui-grid-row>
        </pui-grid-container>
    </card>
</template>

<script lang="ts">
import ComponentSecurity from '@/mixins/component-security';
import Component, {mixins} from 'vue-class-component';
import {OperationModesService} from '@/services';
import {OperationModeGetResponse} from '@/models';
import Card from '@/components/cards/card.vue';
import BackText from '@/components/text/back-text.vue';
import IdentificationOperationModesTab from '@/components/tab-bar/tabs/identification-operation-modes-tab.vue';
import ProductsTable from '@/components/master-data-management-v2/manage-operation-modes/product-table.vue';
import {PebbleTab} from '@/models/pebble/pebble-tab';
import {manageResolve} from '@/utils/utils';
import CardHeader from '@/components/cards/card-header.vue';
import {CheckModel} from '@/models/check-model';
import { routeNames } from '@/router/route-names';
import { RawLocation } from 'vue-router';

@Component({
    name: 'management-operation-modes-edit',
    components: {
        CardHeader,
        BackText,
        Card,
        IdentificationOperationModesTab,
        ProductsTable,
    },
})
export default class ManagementOperationModesEdit extends mixins(ComponentSecurity) {
    /* VARIABLES */
    private operationModesService: OperationModesService = new OperationModesService();
    private sid?: string = '';
    private name?: string = '';
    private formIsNotEmpty = false;
    private selectedTab = 0;
    private check: CheckModel = {
        dataLoaded: false,
        saveButtonIsDisabled: true,
        showError: false,
    };

    /* LATE VARIABLES */
    private data!: OperationModeGetResponse;

    get tabs(): PebbleTab[] {
        return [
            {
                title: this.$t('identification').toString(),
                componentName: 'identification-operation-modes-tab',
            },
            {
                title: this.$t('products').toString(),
                componentName: 'products-table',
            },
        ];
    }

    /* PRIMITIVE METHODS */
    private mounted(): void {
        this.$router.beforeResolve((to, from, next) => manageResolve(this.formIsNotEmpty, next, this));
        this.check.dataLoaded = false;
        this.check.showError = false;
        this.sid = this.$route.query['edit']?.toString();
        this.name = this.$route.query['name']?.toString() ?? this.$t('unknown');
        this.selectedTab = +this.$route.query['tab'] ?? 0;
        if (this.selectedTab > this.tabs.length - 1) {
            this.selectedTab = 0;
            this.replacePage();
        }
        this.init();
    }

    private destroyed(): void {
        this.formIsNotEmpty = false;
    }

    /* METHODS */
    private async init(): Promise<void> {
        this.data = await this.loadMode();
        this.check.dataLoaded = true;
    }

    private onFormChanged(value: boolean): void {
        this.formIsNotEmpty = value;
    }

    private async replacePage(index = 0): Promise<void> {
        await this.$router.replace({
            name: 'management-operation-modes-edit',
            query: {
                edit: this.$route.query.edit,
                name: this.$route.query.name,
                backPage: this.$route.query.backPage,
                tab: `${index}`,
                ...(this.isRedirectionFromUnit()) && {
                    redirectFromUnit: 'true',
                    unitId: this.$route.query?.unitId,
                    powerPlantId: this.$route.query?.powerPlantId,
                },
            },
        });
    }

    /* ON CLICK BUTTONS */
    private onTabChange(index: number): void {
        this.replacePage(index);
    }

    private async onBackPress(): Promise<void> {
        const route = this.isRedirectionFromUnit()
            ? {
                name: routeNames.EDIT_UNIT,
                params: {
                    id: this.$route.query?.unitId,
                },
                query: {
                    powerPlantId: this.$route.query?.powerPlantId,
                    tabIndex: '2',
                },
            }
            : {
                name: `management-operation-modes`,
            };

        await this.$router.push(route as RawLocation);
    }

    private isRedirectionFromUnit(): boolean {
        const { powerPlantId,  redirectFromUnit } = this.$route.query;
        return !!powerPlantId && !!redirectFromUnit;
    }

    private onClickRefreshButton(): void {
        this.init();
    }

    /* API CALLS */
    private async loadMode(): Promise<OperationModeGetResponse> {
        this.$store.commit('loading');
        try {
            const {result: item} = await this.operationModesService.getById(+`${this.sid}`);
            return item;
        } catch (err: any) {
            this.check.showError = true;
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

}
</script>


