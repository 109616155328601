<template>
    <validation-provider
        :rules="rules"
        :mode="debounceMode"
    >
        <div slot-scope="{ errors }">
            <pui-form-group
                :label="name"
                :is-valid="!errors.length && !invalid"
                :show-required-label="isRequired"
            >
                <pui-form-input-field
                    :is-valid="!markAsError(errors.length) && !invalid"
                    :value="value"
                    :placeholder-text="placeholder"
                    :is-readonly="disabled"
                    type="date"
                    @input="handleInput"
                />
                <template #error-message>
                    {{ errors[0] || customErrorMessage }}
                </template>
            </pui-form-group>
        </div>
    </validation-provider>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import ValidationSetup from './validation-setup';
import { DateHelper } from '@/utils';

@Component({
    name: 'date-input',
    components: {
        ValidationProvider,
    },
})

export default class DateInput extends ValidationSetup {
    @Prop({required: false })
    private readonly placeholder!: string;

    @Prop({required: false, default: '' })
    private inputValue!: any;

    @Prop({required: false, default: '' })
    private name!: string;

    @Prop({required: false, default: false })
    private disabled!: boolean;

    @Prop({required: false, default: false })
    private invalid!: boolean;

    @Prop({required: false, default: '' })
    private customErrorMessage!: string;

    get value(): string {
        if (this.inputValue) {
            return this.formatDate(this.inputValue);
        }
        return '';
    }

    set value(changedValue: string ) {
        this.$emit('change', changedValue);
    }

    private handleInput(changedValue: string): void {
        this.value = changedValue;
    }

    private formatDate(date: string | Date): string {
        return DateHelper.formatDate(date);
    }
}
</script>
