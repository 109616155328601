<template>
    <pui-grid-container class="pui-grid-container-zero-padding">
        <pui-grid-row>
            <pui-grid-column :cols="fullCols">
                <title-form-text
                    :title="$t('baseData')"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column v-if="isEdit" :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('materialSid')"
                    :model="data.materialSid"
                    name="materialSid"
                    disable
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('shortName')"
                    :model="data.shortName"
                    name="shortName"
                    required
                    :max-length="20"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('materialName')"
                    :model="data.materialName"
                    name="materialName"
                    required
                    :max-length="50"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('materialGroupSid')"
                    :model="data.materialGroupSid"
                    name="materialGroupSid"
                    :options="materialGroupList"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('euEts')"
                    :model="data.euEtsSid"
                    name="euEtsSid"
                    :options="euEtsList"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('aggState')"
                    :model="data.aggStateSid"
                    name="aggStateSid"
                    :options="aggStateList"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('quality')"
                    :model="data.qualitySid"
                    name="qualitySid"
                    :options="qualityList"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('homeCountry')"
                    :model="data.homeCountrySid"
                    name="homeCountrySid"
                    :options="homeContryList"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('mine')"
                    :model="data.mineSid"
                    name="mineSid"
                    :options="mineList"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('dehst')"
                    :model="data.dehstSid"
                    name="dehstSid"
                    :options="dehstList"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('unitSettle')"
                    :model="data.unitSettleSid"
                    name="unitSettleSid"
                    :options="unitSettleList"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('unitAdmin')"
                    :model="data.unitAdminSid"
                    name="unitAdminSid"
                    :options="unitSettleList"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column v-if="isEdit" :cols="fullCols">
                <pebble-text-field-area
                    :label="$t('comments')"
                    :model="data.comments"
                    name="comments"
                    :max-length="1000"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols" class="pui-grid-col--bottom">
                <pebble-check-box
                    :label="$t('markAsDeleted')"
                    :model="data.isDeleted"
                    name="isDeleted"
                    @on-change="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="fullCols">
                <title-form-text
                    :title="$t('valuesData')"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    type="number"
                    :label="$t('standardCalorificValue')"
                    :model="data.standardCalorificValue"
                    name="standardCalorificValue"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    type="number"
                    :label="$t('standardDensity')"
                    :model="data.standardDensity"
                    name="standardDensity"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    type="number"
                    :label="$t('normDensity')"
                    :model="data.normDensity"
                    name="normDensity"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    type="number"
                    :label="$t('co2EmissionFactor')"
                    :model="data.co2EmissionFactor"
                    name="co2EmissionFactor"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    type="number"
                    :label="$t('so2EmissionFactor')"
                    :model="data.so2EmissionFactor"
                    name="so2EmissionFactor"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import TitleFormText from '@/components/text/title-form-text.vue';
import {Prop} from 'vue-property-decorator';
import {IdentificationMaterialsForm} from '@/models/form/identification-materials-form';
import {PebbleDropDown} from '@/models/pebble/pebble-drop-down';
import PebbleTextField from '@/components/pebble-form-element/pebble-text-field.vue';
import {PebbleValidate} from '@/mixins/pebble-validate';
import ComponentSecurity from '@/mixins/component-security';
import PebbleDropDownSelect from '@/components/pebble-form-element/pebble-drop-down-select.vue';
import PebbleTextFieldArea from '@/components/pebble-form-element/pebble-text-field-area.vue';
import PebbleCheckBox from '@/components/pebble-form-element/pebble-check-box.vue';
import {PebbleColConst} from '@/mixins/pebble-col-const';

@Component({
    name: 'materials-identification-form',
    components: {PebbleCheckBox, PebbleTextFieldArea, PebbleDropDownSelect, PebbleTextField, TitleFormText},
})
export default class MaterialsIdentificationForm extends mixins(PebbleValidate, ComponentSecurity, PebbleColConst) {
    @Prop({required: true})
    private data!: IdentificationMaterialsForm;

    @Prop({default: false})
    private isEdit!: boolean;

    @Prop({required: true})
    private materialGroupList!: PebbleDropDown[];

    @Prop({required: true})
    private euEtsList!: PebbleDropDown[];

    @Prop({required: true})
    private aggStateList!: PebbleDropDown[];

    @Prop({required: true})
    private qualityList!: PebbleDropDown[];

    @Prop({required: true})
    private homeContryList!: PebbleDropDown[];

    @Prop({required: true})
    private mineList!: PebbleDropDown[];

    @Prop({required: true})
    private dehstList!: PebbleDropDown[];

    @Prop({required: true})
    private unitSettleList!: PebbleDropDown[];
}
</script>
