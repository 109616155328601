import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/home/home.vue';
import KeyMappingListing from '@/views/key-mapping-listing/key-mapping-listing.vue';
import StreamMapping from '@/views/stream-mapping/stream-mapping.vue';
import StreamSystemAssets from '@/views/stream-system-assets/stream-system-assets.vue';
import AssetOverview from '@/components/asset-overview/asset-overview.vue';
import SinergyLifeLocation from '@/views/sinergy-life-location/sinergy-life-location.vue';
import MasterDataManagementV2 from '@/views/master-data-management-v2/master-data-management-v2.vue';
import ManagementEnumTypes
    from '@/views/master-data-management-v2/sub-pages/management-enum-types/management-enum-types.vue';
import ManagementEnumTypesNew
    from '@/views/master-data-management-v2/sub-pages/management-enum-types/management-enum-types-new.vue';
import ManagementEnumTypesEdit
    from '@/views/master-data-management-v2/sub-pages/management-enum-types/management-enum-types-edit.vue';
import ManagementOperationModes
    from '@/views/master-data-management-v2/sub-pages/management-operation-modes/management-operation-modes.vue';
import ManagementOperationModesEdit
    from '@/views/master-data-management-v2/sub-pages/management-operation-modes/management-operation-modes-edit.vue';
import MasterDataManagement from '@/views/master-data-management/master-data-management.vue';
import ManagementBusinessPartners
    from '@/views/master-data-management-v2/sub-pages/management-business-partners/management-business-partners.vue';
import ManagementBusinessPartnersEdit
    from '@/views/master-data-management-v2/sub-pages/management-business-partners/management-business-partners-edit.vue';
import ManagementBusinessPartnersNew
    from '@/views/master-data-management-v2/sub-pages/management-business-partners/management-business-partners-new.vue';
import ManagementEtsUnit from '@/views/master-data-management-v2/sub-pages/management-ets-unit/management-ets-unit.vue';
import ManagementEtsUnitNew
    from '@/views/master-data-management-v2/sub-pages/management-ets-unit/management-ets-unit-new.vue';
import ManagementMaterialGroup
    from '@/views/master-data-management-v2/sub-pages/management-material-group/management-material-group.vue';
import ManagementMaterials
    from '@/views/master-data-management-v2/sub-pages/management-materials/management-materials.vue';
import ManagementReservoirs
    from '@/views/master-data-management-v2/sub-pages/management-reservoirs/management-reservoirs.vue';
import ManagementMaterialsNew
    from '@/views/master-data-management-v2/sub-pages/management-materials/management-materials-new.vue';
import ManagementMaterialsEdit
    from '@/views/master-data-management-v2/sub-pages/management-materials/management-materials-edit.vue';
import ManagementMaterialGroupNew
    from '@/views/master-data-management-v2/sub-pages/management-material-group/management-material-group-new.vue';
import ManagementMaterialGroupEdit
    from '@/views/master-data-management-v2/sub-pages/management-material-group/management-material-group-edit.vue';
import ManagementReservoirsNew
    from '@/views/master-data-management-v2/sub-pages/management-reservoirs/management-reservoirs-new.vue';
import ManagementReservoirsEdit
    from '@/views/master-data-management-v2/sub-pages/management-reservoirs/management-reservoirs-edit.vue';

import { routeNames } from './route-names';
import ManagementOperationModesNew
    from '@/views/master-data-management-v2/sub-pages/management-operation-modes/management-operation-modes-new.vue';
import KeyMappingPage from '@/views/key-mapping-page/key-mapping-page.vue';
import HydroComponents from '@/views/hydro-components/hydro-components.vue';
import HydroComponentsNew from '@/views/hydro-components/hydro-components-new.vue';

// import { store } from '@/store';

Vue.use(Router);

export const router = new Router({
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/asset-master-data',
            name: 'home',
            component: Home,
            meta: {
                requiresAuthentication: false,
            },
        },
        /*
        // commented out for now in case debugging on previous version is required
        {
            path: '/asset-master-data',
            name: 'asset-master-data',
            component: AssetMasterData,
            meta: {
                requiresAuthentication: false,
            },
            children: [
                {
                    path: ':country',
                    name: 'asset-master-data-country',
                    component: AssetMasterData,
                    meta: {
                        requiresAuthentication: false,
                    },
                    children: [
                        {
                            path: 'new',
                            name: 'asset-master-data-powerplant-new',
                            component: AssetMasterData,
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                        {
                            path: ':powerPlant',
                            name: 'asset-master-data-powerplant',
                            component: AssetMasterData,
                            meta: {
                                requiresAuthentication: true,
                            },
                            children: [
                                {
                                    path: 'edit',
                                    name: 'asset-master-data-powerplant-edit',
                                    component: AssetMasterData,
                                    meta: {
                                        requiresAuthentication: true,
                                    },
                                },
                                {
                                    path: 'new',
                                    name: 'asset-master-data-unit-new',
                                    component: AssetMasterData,
                                    meta: {
                                        requiresAuthentication: true,
                                    },
                                },
                                {
                                    path: ':unit',
                                    name: 'asset-master-data-unit-edit',
                                    component: AssetMasterData,
                                    meta: {
                                        requiresAuthentication: true,
                                    },
                                    children: [
                                        {
                                            path: 'new',
                                            name: 'asset-master-data-subunit-new',
                                            component: AssetMasterData,
                                            meta: {
                                                requiresAuthentication: true,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        */
        {
            path: '/asset-master-data',
            component: (): Promise<any> => import(/* webpackChunkName: "AssetMasterData" */ '@/views/asset-master-data-v2/asset-master-data-v2.vue'),
            meta: {
                requiresAuthentication: false,
            },
            children: [
                {
                    path: '/',
                    name: routeNames.ASSET_MASTER_DATA,
                    component: (): Promise<any> => import(/* webpackChunkName: "AssetMasterData" */ '@/components/asset-master-data/index.vue'),
                    meta: {
                        requiresAuthentication: true,
                    },
                },
                {
                    path: 'power-plant',
                    component: (): Promise<any> => import(/* webpackChunkName: "PowerPlant" */ '@/components/asset-master-data/power-plant/index.vue'),
                    meta: {
                        requiresAuthentication: true,
                    },
                    children: [
                        {
                            path: '/',
                            name: routeNames.ADD_POWER_PLANT,
                            component: (): Promise<any> => import(/* webpackChunkName: "PowerPlant" */ '@/components/asset-master-data/power-plant/index.vue'),
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                        {
                            path: ':id',
                            name: routeNames.EDIT_POWER_PLANT,
                            component: (): Promise<any> => import(/* webpackChunkName: "PowerPlant" */ '@/components/asset-master-data/power-plant/index.vue'),
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                    ],
                },
                {
                    path: 'unit',
                    component: (): Promise<any> => import(/* webpackChunkName: "Unit" */ '@/components/asset-master-data/unit/index.vue'),
                    meta: {
                        requiresAuthentication: true,
                    },
                    children: [
                        {
                            path: '/',
                            name: routeNames.ADD_UNIT,
                            component: (): Promise<any> => import(/* webpackChunkName: "Unit" */ '@/components/asset-master-data/unit/index.vue'),
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                        {
                            path: ':id',
                            name: routeNames.EDIT_UNIT,
                            component: (): Promise<any> => import(/* webpackChunkName: "Unit" */ '@/components/asset-master-data/unit/index.vue'),
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: '/asset-overview',
            name: 'asset-overview',
            component: AssetOverview,
            meta: {
                requiresAuthentication: true,
            },
        },
        {
            path: '/master-data-management-v2',
            name: 'master-data-management-v2',
            component: MasterDataManagementV2,
            meta: {
                requiresAuthentication: true,
            },
            children: [
                {
                    path: 'management-enum-types',
                    name: 'management-enum-types',
                    component: ManagementEnumTypes,
                    props: true,
                    meta: {
                        requiresAuthentication: true,
                    },
                    children: [
                        {
                            path: 'edit',
                            name: 'management-enum-types-edit',
                            component: ManagementEnumTypesEdit,
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                        {
                            path: 'new',
                            name: 'management-enum-types-new',
                            component: ManagementEnumTypesNew,
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                    ],
                },
                {
                    path: 'management-operation-modes',
                    name: 'management-operation-modes',
                    props: true,
                    component: ManagementOperationModes,
                    meta: {
                        requiresAuthentication: true,
                    },
                    children: [
                        {
                            path: 'edit',
                            name: 'management-operation-modes-edit',
                            component: ManagementOperationModesEdit,
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                        {
                            path: 'new',
                            name: 'management-operation-modes-new',
                            component: ManagementOperationModesNew,
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                    ],
                },
                {
                    path: 'management-business-partners',
                    name: 'management-business-partners',
                    props: true,
                    component: ManagementBusinessPartners,
                    meta: {
                        requiresAuthentication: true,
                    },
                    children: [
                        {
                            path: 'edit',
                            name: 'management-business-partners-edit',
                            component: ManagementBusinessPartnersEdit,
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                        {
                            path: 'new',
                            name: 'management-business-partners-new',
                            component: ManagementBusinessPartnersNew,
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                    ],
                },
                {
                    path: 'management-ets-unit',
                    name: 'management-ets-unit',
                    props: true,
                    component: ManagementEtsUnit,
                    meta: {
                        requiresAuthentication: true,
                    },
                    children: [
                        {
                            path: 'new',
                            name: 'management-ets-unit-new',
                            component: ManagementEtsUnitNew,
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                        {
                            path: 'edit',
                            name: 'management-ets-unit-edit',
                            component: ManagementEtsUnitNew,
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                    ],
                },
                {
                    path: 'management-materials',
                    name: 'management-materials',
                    props: true,
                    component: ManagementMaterials,
                    meta: {
                        requiresAuthentication: true,
                    },
                    children: [
                        {
                            path: 'new',
                            name: 'management-materials-new',
                            component: ManagementMaterialsNew,
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                        {
                            path: 'edit',
                            name: 'management-materials-edit',
                            component: ManagementMaterialsEdit,
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                    ],
                },
                {
                    path: 'management-material-group',
                    name: 'management-material-group',
                    props: true,
                    component: ManagementMaterialGroup,
                    meta: {
                        requiresAuthentication: true,
                    },
                    children: [
                        {
                            path: 'new',
                            name: 'management-material-group-new',
                            props: true,
                            component: ManagementMaterialGroupNew,
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                        {
                            path: 'edit',
                            name: 'management-material-group-edit',
                            props: true,
                            component: ManagementMaterialGroupEdit,
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                    ],
                },
                {
                    path: 'management-reservoirs',
                    name: 'management-reservoirs',
                    props: true,
                    component: ManagementReservoirs,
                    meta: {
                        requiresAuthentication: true,
                    },
                    children: [
                        {
                            path: 'new',
                            name: 'management-reservoirs-new',
                            component: ManagementReservoirsNew,
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                        {
                            path: 'edit',
                            name: 'management-reservoirs-edit',
                            component: ManagementReservoirsEdit,
                            meta: {
                                requiresAuthentication: true,
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: '/key-mapping-legacy',
            name: 'key-mapping-legacy',
            component: KeyMappingListing,
            meta: {
                requiresAuthentication: true,
            },
        },
        {
            path: '/key-mapping',
            name: 'key-mapping',
            component: KeyMappingPage,
            meta: {
                requiresAuthentication: true,
            },
        },

        {
            path: '/stream-system-assets',
            name: 'stream-system-assets',
            component: StreamSystemAssets,
            meta: {
                requiresAuthentication: true,
            },
        },
        {
            path: '/stream-mapping',
            name: 'stream-mapping',
            component: StreamMapping,
            meta: {
                requiresAuthentication: true,
            },
        },
        {
            path: '/sinergy-life-location',
            name: 'sinergy-life-location',
            component: SinergyLifeLocation,
            meta: {
                requiresAuthentication: true,
            },
            children: [
                {
                    path: 'new',
                    name: 'sinergy-life-location-new',
                    component: SinergyLifeLocation,
                    meta: {
                        requiresAuthentication: true,
                    },
                },
                {
                    path: 'edit',
                    name: 'sinergy-life-location-edit',
                    component: SinergyLifeLocation,
                    meta: {
                        requiresAuthentication: true,
                    },
                },
            ],
        },
        {
            path: '/hydro-components',
            name: 'hydro-components',
            component: HydroComponents,
            meta: {
                requiresAuthentication: true,
            },
            children: [
                {
                    path: 'new',
                    name: 'hydro-components-new',
                    component: HydroComponentsNew,
                    meta: {
                        requiresAuthentication: true,
                    },
                },
                {
                    path: 'edit',
                    name: 'hydro-components-edit',
                    component: HydroComponentsNew,
                    meta: {
                        requiresAuthentication: true,
                    },
                },
            ]
        },
        {
            path: '/master-data-management',
            name: 'master-data-management',
            component: MasterDataManagement,
            meta: {
                requiresAuthentication: true,
            },
            children: [
                {
                    path: ':option',
                    name: 'manage-option',
                    component: MasterDataManagement,
                    meta: {
                        requiresAuthentication: true,
                    },
                },
            ],
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (from.query.app === 'true' && !to.query.app) {
        next({
            path: to.path,
            query: {
                app: 'true',
                ...to.query,
            },
            hash: to.hash,
        });
    } else {
        next();
    }
});

const shouldRestoreHierarchyPreviousState = (to: any, from: any) => {
    return (to.name === routeNames.ASSET_MASTER_DATA
        && (from.name === routeNames.EDIT_POWER_PLANT || from.name === routeNames.EDIT_UNIT || from.name === routeNames.ADD_UNIT)
        && !to.query?.shouldRestorePreviousState);
};

router.beforeEach((to, from, next) => {
    if (shouldRestoreHierarchyPreviousState(to, from)) {
        next({
            path: to.path,
            query: {
                shouldRestorePreviousState: 'true',
                ...to.query,
            },
        });
    } else {
        next();
    }
});

export default router;
