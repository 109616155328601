<template>
    <div class="identification-container">
        <validation-observer ref="validationObserver">
            <div class="identification">
                <div class="formModel">
                    <div v-if="data.materialSid" class="flex-row manage-input">
                        <label class="mr-2 default-label" for="materialSid">{{ $t('materialSid') }}:</label>
                        <input class="mb-1 default-input" v-model="data.materialSid" :placeholder="$t('materialSid')" id="etsUnitId" disabled />
                    </div>
                    <validation-provider
                        tag="div"
                        class="manage-input"
                        name="shortName"
                        rules="required"
                        #default="{ errors, failed }"
                    >
                        <div class="flex-row">
                            <label class="mr-2 default-label" for="shortName">{{ $t('shortName') }}*:</label>
                            <input class="default-input" v-model="data.shortName" :placeholder="$t('shortName')" id="shortName" maxlength="20"
                                :class="{ 'invalid': failed }"
                            />
                        </div>
                        <error-list :errors="errors" />
                    </validation-provider>
                    <validation-provider
                        tag="div"
                        class="manage-input"
                        name="materialName"
                        rules="required"
                        #default="{ errors, failed }"
                    >
                        <label class="mr-2 default-label" for="materialName">{{ $t('materialName') }}*:</label>
                        <input class="default-input" v-model="data.materialName" :placeholder="$t('materialName')" id="materialName" maxlength="50"
                            :class="{ 'invalid': failed }"
                        />
                        <error-list :errors="errors" />
                    </validation-provider>
                    <validation-provider
                        tag="div"
                        class="manage-input"
                        #default="{ errors, failed }"
                        name="materialGroupName"
                        rules="required"
                    >
                        <label class="mr-2 default-label" for="euEtsSid">{{ $t('materialGroupName') }}*:</label>
                        <select class="default-select" id="materialGroupName" v-model="data.materialGroupSid"
                            :placeholder="$t('materialGroupSid')"
                            :class="{ 'invalid': failed }"
                        >
                            <option
                                v-for="materialGroup of materialsGroupList"
                                :key="materialGroup.materialGroupSid"
                                :value="materialGroup.materialGroupSid"
                            >
                                {{ materialGroup.materialGroupName }}
                            </option>
                        </select>
                        <error-list :errors="errors" />
                    </validation-provider>
                    <validation-provider
                        tag="div"
                        class="manage-input"
                        name="euEts"
                        rules="required"
                        #default="{ errors, failed }"
                    >
                        <label class="mr-2 default-label" for="euEts">{{ $t('euEts') }}*:</label>
                        <select class="default-select" id="euEts" v-model="data.euEtsSid" :placeholder="$t('euEts')" :class="{ 'invalid': failed }">
                            <option v-for="euEtsObject of euEtsList"
                                :key="euEtsObject.sid"
                                :value="euEtsObject.sid"
                            >
                                {{ euEtsObject.name }}
                            </option>
                        </select>
                        <error-list :errors="errors" />
                    </validation-provider>
                    <div class="flex-row manage-input">
                        <label class="mr-2 default-label" for="aggState">{{ $t('aggState') }}:</label>
                        <select class="default-select mb-1" id="aggState" v-model="data.aggStateSid" :placeholder="$t('aggState')">
                            <option :value="null" key="default" selected>
                                {{ $t('none') }}
                            </option>
                            <option v-for="aggStateObject of aggStateList"
                                :key="aggStateObject.sid"
                                :value="aggStateObject.sid"
                            >
                                {{ aggStateObject.name }}
                            </option>
                        </select>
                    </div>
                    <div class="flex-row manage-input">
                        <label class="mr-2 default-label" for="quality">{{ $t('quality') }}:</label>
                        <select class="default-select" id="quality" v-model="data.qualitySid" :placeholder="$t('quality')">
                            <option :value="null" key="default" selected>
                                {{ $t('none') }}
                            </option>
                            <option v-for="qualityObject of qualityList"
                                :key="qualityObject.sid"
                                :value="qualityObject.sid"
                            >
                                {{ qualityObject.name }}
                            </option>
                        </select>
                    </div>
                    <div class="flex-row manage-input">
                        <label class="mr-2 default-label" for="homeCountry">{{ $t('homeCountry') }}:</label>
                        <select class="default-select" id="homeCountry" v-model="data.homeCountrySid" :placeholder="$t('homeCountry')">
                            <option :value="null" key="default" selected>
                                {{ $t('none') }}
                            </option>
                            <option v-for="homeCountry of homeContryList"
                                :key="homeCountry.sid"
                                :value="homeCountry.sid"
                            >
                                {{ homeCountry.name }}
                            </option>
                        </select>
                    </div>
                    <div class="flex-row manage-input">
                        <label class="mr-2 default-label" for="mine">{{ $t('mine') }}:</label>
                        <select class="default-select" id="mine" v-model="data.mineSid" :placeholder="$t('mine')">
                            <option :value="null" key="default" selected>
                                {{ $t('none') }}
                            </option>
                            <option v-for="mine of mineList"
                                :key="mine.sid"
                                :value="mine.sid"
                            >
                                {{ mine.name }}
                            </option>
                        </select>
                    </div>
                    <div class="flex-row manage-input">
                        <label class="mr-2 default-label" for="dehst">{{ $t('dehst') }}:</label>
                        <select class="default-select" id="dehst" v-model="data.dehstSid" :placeholder="$t('dehst')">
                            <option :value="null" key="default" selected>
                                {{ $t('none') }}
                            </option>
                            <option v-for="dehst of dehstList"
                                :key="dehst.sid"
                                :value="dehst.sid"
                            >
                                {{ dehst.name }}
                            </option>
                        </select>
                    </div>
                    <div class="flex-row manage-input">
                        <label class="mr-2 default-label" for="unitSettle">{{ $t('unitSettle') }}:</label>
                        <select class="default-select" id="unitSettle" v-model="data.unitSettleSid" :placeholder="$t('unitSettle')">
                            <option :value="null" key="default" selected>
                                {{ $t('none') }}
                            </option>
                            <option v-for="unitSettle of unitSettleList"
                                :key="unitSettle.sid"
                                :value="unitSettle.sid"
                            >
                                {{ unitSettle.name }}
                            </option>
                        </select>
                    </div>
                    <div class="flex-row manage-input">
                        <label class="mr-2 default-label" for="unitAdmin">{{ $t('unitAdmin') }}:</label>
                        <select class="default-select" id="unitAdmin" v-model="data.unitAdminSid" :placeholder="$t('unitAdmin')">
                            <option :value="null" key="default" selected>
                                {{ $t('none') }}
                            </option>
                            <option v-for="unitSettle of unitSettleList"
                                :key="unitSettle.sid"
                                :value="unitSettle.sid"
                            >
                                {{ unitSettle.name }}
                            </option>
                        </select>
                    </div>
                    <validation-provider
                        v-if="data.materialSid"
                        tag="div"
                        class="manage-input"
                        name="comments"
                        rules="max:1000"
                        #default="{ errors, failed }"
                    >
                        <div class="flex-row manage-input">
                            <label class="mr-2 default-label" for="lastUpdateComment">{{ $t('comments') }}:</label>
                            <textarea
                                class="default-textarea"
                                maxlength="1000"
                                v-model="comments"
                                :placeholder="$t('comments')"
                                id="lastUpdateComment"
                                :class="{ 'invalid': failed }"
                                rows="4"></textarea>
                        </div>
                        <error-list :errors="errors" />
                    </validation-provider>
                    <div>
                        <checkbox-input
                            v-model="data.isDeleted"
                            :label="$t('markAsDeleted')" />
                    </div>
                </div>
                <div class="material-detail-identification__header">{{ $t('valuesData') }}</div>
                <div class="formModel">
                    <div class="flex-row manage-input">
                        <label class="mr-2 default-label" for="standardCalorificValue">{{ $t('standardCalorificValue') }}:</label>
                        <input class="default-input" v-model.number="data.standardCalorificValue" :placeholder="$t('standardCalorificValue')" id="standardCalorificValue" />
                    </div>
                    <div class="flex-row manage-input">
                        <label class="mr-2 default-label" for="standardDensity">{{ $t('standardDensity') }}:</label>
                        <input class="default-input" v-model.number="data.standardDensity" :placeholder="$t('standardDensity')" id="standardDensity" />
                    </div>
                    <div class="flex-row manage-input">
                        <label class="mr-2 default-label" for="normDensity">{{ $t('normDensity') }}:</label>
                        <input class="default-input" v-model.number="data.normDensity" :placeholder="$t('normDensity')" id="normDensity" />
                    </div>
                    <div class="flex-row manage-input">
                        <label class="mr-2 default-label" for="co2EmissionFactor">{{ $t('co2EmissionFactor') }}:</label>
                        <input class="default-input" v-model.number="data.co2EmissionFactor" :placeholder="$t('co2EmissionFactor')" id="co2EmissionFactor" />
                    </div>
                    <div class="flex-row manage-input">
                        <label class="mr-2 default-label" for="so2EmissionFactor">{{ $t('so2EmissionFactor') }}:</label>
                        <input class="default-input" v-model.number="data.so2EmissionFactor" :placeholder="$t('so2EmissionFactor')" id="so2EmissionFactor" />
                    </div>
                </div>
                <last-updated v-if="data.materialSid" :data="data" />
                <error-list :errors="invalidMessages" />
            </div>
            <div class="members-footer">
                <adam-button
                    v-if="hasMasterDataAdmin"
                    class="ml-1"
                    @click="save"
                    secondary>
                    {{ $t('save') }}
                </adam-button>
            </div>
        </validation-observer>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Mixins, Ref } from 'vue-property-decorator';
import { EventBus, extractErrorsFromResponse, InfiniteScrollingHelper } from '@/utils';
import { Asset, IdTypeSid, MaterialBaseModel, MaterialGroupModel } from '@/models';
import { AssetService, MaterialBaseService, MaterialGroupService } from '@/services';
import { ValidationObserver } from 'vee-validate';
import CheckboxInput from '@/components/checkbox-input/checkbox-input.vue';
import ErrorList from '@/components/error-list/error-list.vue';
import LastUpdated from '@/components/last-updated/last-updated.vue';
import ComponentSecurity from '@/mixins/component-security';
import { MaterialGroupQueryModel } from '@/models/filter';

@Component({
    name: 'material-base-identification',
    components: {
        checkboxInput: CheckboxInput,
        errorList: ErrorList,
        lastUpdated: LastUpdated,
    },
})
export default class MaterialBaseIdentification extends Mixins(ComponentSecurity) {
    @Ref()
    private readonly validationObserver!: InstanceType<typeof ValidationObserver>;
    /*
     *  Material for which the identification tab from editing screen is displayed.
    */
    @Prop({ required: true })
    private data!: MaterialBaseModel;

    private materialBaseService: MaterialBaseService = new MaterialBaseService();
    private materialsGroupService: MaterialGroupService = new MaterialGroupService();
    private assetService: AssetService = new AssetService();

    private formModel: MaterialBaseModel = { ...this.data };
    private materialsGroupList: MaterialGroupModel[] = [];
    private euEtsList: Asset[] = [];
    private aggStateList: Asset[] = [];
    private qualityList: Asset[] = [];
    private homeContryList: Asset[] = [];
    private mineList: Asset[] = [];
    private dehstList: Asset[] = [];
    private unitSettleList: Asset[] = [];
    private errorList: string[] = [];
    private invalidMessages: string[] = [];
    private filterPage = {
        page: 1,
        size: 999,
        term: '',
    };
    private filterGroupModel: MaterialGroupQueryModel = {
        EnumSuMaterialGroupSid: [],
        EnumMaterialTypeSid: [],
        page: 1,
        size: 999,
        term: '',
        includeDeleted: false,
        sortDirection: '',
        sortColumn: '',
    };
    private comments = '';

    public async beforeLeave(): Promise<boolean> {
        if (!this.validationObserver.flags.dirty) {
            return true;
        }

        try {
            await this.$dialog
                .confirm(
                    { body: this.$t('areYouSureLeaveUnsaved').toString() },
                    { view: 'confirm' });
            return true;
        } catch (error: any) {
            return Promise.resolve(false);
        }
    }

    private async mounted(): Promise<void> {
        EventBus.$on(EventBus.DETAIL.CLOSE, this.closeDetailsView);
        await this.getEuEtsList();
        await this.getMaterialGroups();
        await this.getAggStateList();
        await this.getQualityList();
        await this.getHomeContryList();
        await this.getMineList();
        await this.getDehstList();
        await this.getUnitSettleList();
        this.validationObserver?.reset();
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.DETAIL.CLOSE);
    }

    private async getEuEtsList(): Promise<void> {
        this.euEtsList = (await this.assetService.getByIdTypeSid(IdTypeSid.EU_ETS, this.filterPage)).result.items;
    }

    private async getAggStateList(): Promise<void> {
        this.aggStateList = (await this.assetService.getByIdTypeSid(IdTypeSid.AGG_STATE, this.filterPage)).result.items;
    }

    private async getQualityList(): Promise<void> {
        this.qualityList = (await this.assetService.getByIdTypeSid(IdTypeSid.QUALITY, this.filterPage)).result.items;
    }

    private async getHomeContryList(): Promise<void> {
        this.homeContryList =
        (await this.assetService.getByIdTypeSid(IdTypeSid.HOME_COUNTRY, this.filterPage)).result.items;
    }

    private async getMineList(): Promise<void> {
        this.mineList = (await this.assetService.getByIdTypeSid(IdTypeSid.MINE, this.filterPage)).result.items;
    }

    private async getDehstList(): Promise<void> {
        this.dehstList = (await this.assetService.getByIdTypeSid(IdTypeSid.DEHST, this.filterPage)).result.items;
    }

    private async getUnitSettleList(): Promise<void> {
        this.unitSettleList = (await this.assetService.getByIdTypeSid(IdTypeSid.UNITS, this.filterPage)).result.items;
    }

    private checkInput(): void {
        this.errorList = [];
        this.invalidMessages = [];
    }

    private isInvalid(invalidKey: string): boolean {
        return this.errorList.indexOf(invalidKey) > -1;
    }

    private deleteInputFieldError(invalidKey: string): any {
        this.errorList = this.errorList.filter((error: string) => error !== invalidKey);
    }

    private async closeDetailsView(): Promise<void> {
        try {
            const canLeave = await this.beforeLeave();
            /**
             * Fired when the user wishes to exit the details view
             */
            if (canLeave) {
                this.$emit('close');
            }
        } catch (error: any) {
            // action canceled by user
        }
    }

    private async getMaterialGroups(): Promise<void> {
        this.materialsGroupList = (await this.materialsGroupService.get(this.filterGroupModel))
            ?.result
            ?.items;
    }

    private async save(): Promise<void> {
        this.invalidMessages = [];
        const isValid = await this.validationObserver.validate();

        if (!isValid) {
            await this.$nextTick();
            InfiniteScrollingHelper.scrollToFirstInvalidElement('.invalid');
            return;
        }
        let message = 'errorAddingMaterial';
        this.$store.commit('loading');
        this.formModel = {...this.data};
        try {
            if (this.formModel.materialSid > 0) {
                message = 'errorAddingMaterial';
                this.formModel.lastUpdateComment = this.comments;
                const { result } = await this.materialBaseService.edit(this.formModel);
                this.formModel = { ...result };
                this.$emit('save', this.formModel, false, null);
            } else {
                const addedAsset = (await this.materialBaseService.create(this.formModel)).result;
                this.$emit('save', addedAsset, true, null);
            }
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            this.validationObserver?.reset();
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, message);
            if (!err.response || !err.response.data) {
                throw err;
            }
            this.invalidMessages = extractErrorsFromResponse(err.response.data);
            if (this.invalidMessages.length) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.error-list__message');
                });
            }
        } finally {
            this.$store.commit('loading');
        }
        this.data.lastUpdateComment = '';
    }
}

</script>

<style scoped lang="less">
@import "~@/variables.less";
.identification-container {
    .identification {
        .formModel {
            display: grid;
            grid-template-columns: 40% 40%;
            grid-gap: 4rem;
            margin-top: 4rem;
        }
        label {
            margin-top: 1rem;
            min-width: fit-content;
        }
        input, textarea {
            width: 100%;
        }
    }
    .material-detail-identification {
        &__actions {
            display: flex;
            justify-content: flex-end;
        }

        &__header {
            margin-top: 2rem;
            margin-bottom: 1rem;
            font-size: 2rem;
            font-family: @font-roboto-md;
            border-bottom: 1px solid @grey-lighter;
        }
    }
}
</style>
