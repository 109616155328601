<template>
    <div class="search-component">
        <input
            type="text"
            v-model="searchItem"
            :placeholder="$t('search')"
            @input="onInput()"
            class="default-input search-input search-component__input" />
        <button
            type="button"
            class="default-button search-component__clear-btn"
            @click="resetSearch()"
            :disabled="!searchItem"
            :title="$t('clearSearch')">
            &times;
        </button>
    </div>
</template>

<script lang="ts">

import { debounce } from 'lodash';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

/**
 * Custom component `<search-component>`.
 *
 * Example usage:
 * ```
 * <search-component v-model="searchTerm" @input="onSearchTermChange" />
 * ```
 */
@Component({
    name: 'search-component',
})
export default class SearchComponent extends Vue {

    /**
     * Perceived value of the search input.
     * Can be used for two-way data binding using the `v-model` directive.
     */
    @Prop({
        type: String,
        default: '',
    })
    private value!: string;

    /**
     * The binding for the input of the search box.
     *
     * @type { string }
     * @initialValue this.value
     */
    private searchItem = this.value;

    /**
     * @ignore
     */
    private onInput = debounce(this.handleInput, 1000);

    @Watch('value')
    private onValueChange(newVal: string, oldVal: string): void {
        if (newVal === oldVal) {
            return;
        }

        if (newVal === this.searchItem) {
            return;
        }

        this.searchItem = newVal;
    }

    private resetSearch(): void {
        this.searchItem = '';
        this.handleInput();
    }

    private handleInput(): void {
        /**
         * Fired when percieved value should be changed, with a delay in order
         * not to trigger while the user is in the midst of typing.
         *
         * @argument { string } searchItem - the value of the search box
         */
        this.$emit('input', this.searchItem);
    }
}

</script>

<style scoped lang="less">
@import "~@/variables.less";

.search-component {
    position: relative;
    padding-right: 3.2rem;

    &__input {
        width: inherit;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &:focus {
            outline: none;
            border-color: @uniper-blue;
        }
    }

    &__clear-btn {
        position: absolute;
        right: 0;
        color: @dark-grey;
        font-size: 2.1rem;
        background: @white;
        height: 100%;
        width: 3.2rem;
        border-top-right-radius: @border-radius-standard;
        border-bottom-right-radius: @border-radius-standard;
        border: 2px solid @lighter-grey;
        border-left: none;

        &:hover,
        &:focus {
            background-color: lighten(@uniper-blue, 50%);
        }
    }
}
</style>
