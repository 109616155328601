var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pui-grid-container',{staticClass:"pui-grid-container-fix-margin"},[_c('pui-grid-row',[_c('master-data-management-header',{attrs:{"title":_vm.$t('manageMaterialGroup'),"filters":_vm.filters,"deleted-initial-value":_vm.filterResult.showDeleted,"on-add-new":_vm.onClickAddNewButton,"on-check":_vm.onClickCheckBox,"on-filter-change":_vm.onFilterChange}})],1),_c('pui-grid-row',[_c('custom-table',{attrs:{"columns-key":['shortName', 'materialGroupSid','materialGroupName','enumSuMaterialGroup','enumMaterialType', 'lastUpdated', 'lastUpdatedBy', 'isDeleted', 'action'],"data":_vm.data,"heading":_vm.heading,"sortable-key":['shortName', 'materialGroupSid','materialGroupName','enumSuMaterialGroup','enumMaterialType', 'lastUpdated', 'lastUpdatedBy', 'isDeleted'],"per-page":10,"per-page-values":[10, 50, 100],"enable-search":"","enable-pagination":"","enable-limit":"","custom-template":['isDeleted', 'action']},scopedSlots:_vm._u([{key:"custom_isDeleted",fn:function(ref){
var data = ref.data;
return [_c('boolean-text',{attrs:{"titles":{
                        textTrue: _vm.$t('yes'),
                        textFalse: _vm.$t('no'),
                    },"value":data}})]}},{key:"custom_action",fn:function(ref){
                    var data = ref.data;
return [_c('pui-link',{attrs:{"title":_vm.$t('edit'),"icon":"edit"},on:{"click":function($event){return _vm.onClickEditButton(data)}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }