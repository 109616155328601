<template>
    <md-card
        :icon="card.icon.src"
        :name="card.name"
        :sid="card.sid">
        <template v-slot:card-properties>
            <div class="flex-col description border-right mr-2 pr-2 center-vertically">
                <div>
                    <b>{{ card.description }}</b>
                </div>
            </div>
            <div class="flex-col is-deleted border-right mr-2 pr-2 center-vertically">
                <span class="flex-row">
                    <b
                        :class="[
                            'ellipsis',
                            'ml-1',
                            card.isDeleted ? 'color-amber' : 'color-green'
                        ]"
                        :title="card.isDeleted"
                    >{{ card.isDeleted ? 'Y' : 'N' }}</b>
                </span>
            </div>
        </template>
        <template v-slot:action-buttons>
            <div class="icon-settings">
                <adam-button
                    icon="ic-settings"
                    fab
                    :outlined.prop="false"
                    @click="edit()"
                    inverted/>
            </div>
        </template>
    </md-card>
</template>

<script lang="ts">

import { Component, Prop, Mixins } from 'vue-property-decorator';
import { Asset } from '@/models';
import MdCard from '@/components/cards/cards-list/md-card/md-card.vue';
import ComponentSecurity from '@/mixins/component-security';

@Component({
    name: 'enum-card-list',
    components: {
        mdCard: MdCard,
    },
})
export default class EnumCardList extends Mixins(ComponentSecurity) {
    /*
     * Card data.
    */
    @Prop()
    private card!: Asset;

    private edit(): void {
        this.$emit('edit', this.card);
    }

    private remove(): void {
        this.$emit('remove', this.card);
    }
}

</script>

<style scoped lang="less">
@import "~@/variables.less";

.ellipsis {
    width: 25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.color-amber {
    color: @contextual-amber;
}

.color-green {
    color: @contextual-green;
}
</style>
