import { render, staticRenderFns } from "./master-data-management-tree.vue?vue&type=template&id=2099a6e1&scoped=true"
import script from "./master-data-management-tree.vue?vue&type=script&lang=ts"
export * from "./master-data-management-tree.vue?vue&type=script&lang=ts"
import style0 from "./master-data-management-tree.vue?vue&type=style&index=0&id=2099a6e1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2099a6e1",
  null
  
)

export default component.exports