<template>
    <div class="container">
        <pui-headline type="h3" class="header-title">
            {{ $t('keyMapping').toString() }}
        </pui-headline>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    name: 'key-mapping-page-header',
})
export default class KeyMappingPageHeader extends Vue {
}
</script>

<style scoped>
.header-title {
    margin: 32px 32px 0;
}

.container {
    width: 100%;
}
</style>
