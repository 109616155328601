<template>
    <div class="flex-container main-container main-col stream-mapping">
        <div class="flex-col flex-1">
            <div class="flex-row details-container">
                <div class="flex-col manage-input mr-2">
                    <label for="streamSystem">{{ $t('streamSystem') }}</label>
                    <select class="default-select" id="streamSystem"
                        v-model="selectedStreamSystem"
                        :placeholder="$t('streamSystem')"
                        @change="selectStreamSystem()">
                        <option v-for="system of streamSystems"
                            :key="system.sid"
                            :value="system">
                            {{ system.name }}
                        </option>
                    </select>
                </div>
                <div class="flex-col manage-input mr-2">
                    <label for="assetType">{{ $t('assetType') }}</label>
                    <select class="default-select" id="assetType"
                        v-model="selectedAssetType"
                        :placeholder="$t('assetType')"
                        @change="selectAssetType()">
                        <option v-for="asset of assetTypes"
                            :key="asset.sid"
                            :value="asset">
                            {{ asset.name }}
                        </option>
                    </select>
                </div>
                <div class="flex-col">
                    <search-component
                        :search-input-id="streamMappingSearchId"
                        @change-search="searchCards" />
                </div>
            </div>
            <div class="cards-container">
                <div v-if="filteredSystemAssets.length === 0">
                    {{ $t('noDataToShow') }}
                </div>
                <card-list>
                    <template v-slot:cards>
                        <div v-for="asset of filteredSystemAssets"
                            :key="asset.sid">
                            <stream-mapping-card-list
                                :card="asset"
                                :selectedStreamSystemName="selectedStreamSystem.name" />
                        </div>
                    </template>
                </card-list>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import SearchComponent from '@/components/search-component/search-component.vue';
import { AssetType, MasterDataBase, StreamMappingAsset } from '@/models';
import CardList from '@/components/cards/cards-list/cards-list.vue';
import StreamMappingCardList from '@/components/cards/cards-list/stream-mapping-card-list/stream-mapping-card-list.vue';

@Component({
    name: 'stream-mapping',
    components: {
        searchComponent: SearchComponent,
        cardList: CardList,
        streamMappingCardList: StreamMappingCardList,
    },
})

export default class StreamMapping extends Vue  {
    private streamSystems: MasterDataBase[] = [];
    private selectedStreamSystem: MasterDataBase | null = null;
    private assetTypes: AssetType[] = [];
    private selectedAssetType: AssetType | null = null;
    private systemAssets: StreamMappingAsset[] = [];
    private filteredSystemAssets: StreamMappingAsset[] = [];
    private streamMappingSearchId = 'stream-mapping-search';

    private mounted(): void {
        // TODO: switch to BE data when it will be available
        this.loadStreamSystems();
        this.loadAssetTypes();
        this.selectedStreamSystem = {...this.streamSystems[0]};
        this.selectedAssetType = {...this.assetTypes[0]};
        this.selectStreamSystem();
    }

    private loadStreamSystems(): void {
        this.streamSystems = [
            {
                sid: 1,
                name: 'PML',
            },
            {
                sid: 2,
                name: 'MSP',
            },
            {
                sid: 3,
                name: 'EAS',
            },
            {
                sid: 4,
                name: 'Live Gen App',
            },
        ];
    }

    private loadAssetTypes(): void {
        this.assetTypes = [
            {
                sid: 1,
                name: 'Unit',
                createMethod: this.loadUnits,
            },
            {
                sid: 2,
                name: 'Power Plant',
                createMethod: this.loadPowerPlants,
            },
            {
                sid: 3,
                name: 'Countries',
                createMethod: this.loadCountries,
            },
        ];
    }

    private selectStreamSystem(): void {
        if (!this.assetTypes.length) {
            return;
        }

        this.selectedAssetType = {...this.assetTypes[0]};
        this.selectAssetType();
        this.filteredSystemAssets = [...this.systemAssets];
    }

    private selectAssetType(): void {
        if (!this.selectedAssetType || !this.selectedStreamSystem) {
            return;
        }

        this.selectedAssetType.createMethod();
        this.streamMappingSearchId = `${this.selectedStreamSystem.name}-${this.selectedAssetType.name}-search`;
        this.searchCards('');
    }

    private searchCards(searchString: string): void {
        if (searchString === '') {
            this.filteredSystemAssets = [...this.systemAssets];
            return;
        }
        this.filteredSystemAssets = this.systemAssets.filter((asset: StreamMappingAsset) =>
            this.matchingFilterCriteria(asset, searchString.trim()));
    }

    private matchingFilterCriteria(asset: StreamMappingAsset, searchString: string): boolean {
        if ((asset.sid && asset.sid.toString().includes(searchString)) ||
            asset.name && asset.name.toString().includes(searchString)) {
            return true;
        }
        return false;
    }

    private loadUnits(): void {
        this.systemAssets = [
            {
                sid: 1,
                name: 'Castleford-1',
                iconSrc: 'ic-unit-blue',
                assigned: false,
            },
            {
                sid: 2,
                name: 'T_CDCL-1',
                iconSrc: 'ic-unit-blue',
                assigned: true,
            },
            {
                sid: 3,
                name: 'T_KILLPG-2',
                iconSrc: 'ic-unit-blue',
                assigned: true,
            },
            {
                sid: 4,
                name: 'MV2',
                iconSrc: 'ic-unit-blue',
                assigned: false,
            },
            {
                sid: 5,
                name: 'EFG-3',
                iconSrc: 'ic-unit-blue',
                assigned: true,
            },
            {
                sid: 6,
                name: 'ROC2',
                iconSrc: 'ic-unit-blue',
                assigned: true,
            },
            {
                sid: 7,
                name: 'EDH-1',
                iconSrc: 'ic-unit-blue',
                assigned: false,
            },
            {
                sid: 8,
                name: 'B-ROT',
                iconSrc: 'ic-unit-blue',
                assigned: false,
            },
            {
                sid: 9,
                name: 'Fessenheim2',
                iconSrc: 'ic-unit-blue',
                assigned: false,
            },
            {
                sid: 10,
                name: 'Tihange 1-NL',
                iconSrc: 'ic-unit-blue',
                assigned: true,
            },
            {
                sid: 11,
                name: 'Grain',
                iconSrc: 'ic-unit-blue',
                assigned: false,
            },
            {
                sid: 12,
                name: 'Project explorer virtual',
                iconSrc: 'ic-unit-blue',
                assigned: false,
            },
            {
                sid: 13,
                name: 'Cottam',
                iconSrc: 'ic-unit-blue',
                assigned: true,
            },
            {
                sid: 14,
                name: 'UK Pipelines',
                iconSrc: 'ic-unit-blue',
                assigned: false,
            },
            {
                sid: 15,
                name: 'Gonyu',
                iconSrc: 'ic-unit-blue',
                assigned: true,
            },
        ];
    }

    private loadPowerPlants(): void {
        this.systemAssets = [
            {
                sid: 1,
                name: 'Datteln',
                iconSrc: 'ic-group',
                assigned: false,
            },
            {
                sid: 2,
                name: 'Franken 1',
                iconSrc: 'ic-group',
                assigned: true,
            },
            {
                sid: 3,
                name: 'Huntorf',
                iconSrc: 'ic-group',
                assigned: false,
            },
            {
                sid: 4,
                name: 'Isar 2',
                iconSrc: 'ic-group',
                assigned: false,
            },
            {
                sid: 5,
                name: 'Heyden',
                iconSrc: 'ic-group',
                assigned: false,
            },
            {
                sid: 6,
                name: 'Staudinger',
                iconSrc: 'ic-group',
                assigned: false,
            },
            {
                sid: 7,
                name: 'Schkopau',
                iconSrc: 'ic-group',
                assigned: true,
            },
            {
                sid: 8,
                name: 'Den Haag',
                iconSrc: 'ic-group',
                assigned: true,
            },
            {
                sid: 9,
                name: 'Leiden',
                iconSrc: 'ic-group',
                assigned: true,
            },
            {
                sid: 10,
                name: 'Enfield',
                iconSrc: 'ic-group',
                assigned: false,
            },
        ];
    }

    private loadCountries(): void {
        this.systemAssets = [
            {
                sid: 1,
                name: 'DE',
                iconSrc: 'ic-module',
                assigned: true,
            },
            {
                sid: 2,
                name: 'NL',
                iconSrc: 'ic-module',
                assigned: true,
            },
            {
                sid: 3,
                name: 'UK',
                iconSrc: 'ic-module',
                assigned: true,
            },
            {
                sid: 4,
                name: 'AT',
                iconSrc: 'ic-module',
                assigned: true,
            },
            {
                sid: 5,
                name: 'HU',
                iconSrc: 'ic-module',
                assigned: true,
            },
            {
                sid: 6,
                name: 'NO',
                iconSrc: 'ic-module',
                assigned: false,
            },
            {
                sid: 7,
                name: 'RU',
                iconSrc: 'ic-module',
                assigned: false,
            },
            {
                sid: 8,
                name: 'RO',
                iconSrc: 'ic-module',
                assigned: true,
            },
            {
                sid: 9,
                name: 'RU',
                iconSrc: 'ic-module',
                assigned: false,
            },
            {
                sid: 10,
                name: 'PL',
                iconSrc: 'ic-module',
                assigned: false,
            },
        ];
    }
}
</script>

<style scoped lang="less">
@import "~@/variables.less";

.stream-mapping {
    font-size: 1.8rem;
    height: 100%;
    overflow: hidden;
    .details-container {
        label {
            min-width: fit-content;
            top: -0.8rem;
        }
    }
    .cards-container {
        padding: 3rem 3rem 2rem 3rem;
        height: calc(100% - 18rem);
        overflow: hidden;
        overflow-y: auto;
    }
}
</style>
