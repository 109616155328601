import { Application } from '@/models';
import { sdk } from '@/utils/sdk';

export class AppService {

    public async getApps(): Promise<Application[]> {
        return (await sdk?.core.metaData.request.api.get<{result: {items: Application[]}}>('/use-cases'))
            .data
            .result
            .items;
    }
}
