<template>
    <div class="mt-2">
        <pui-table
            class="pui-table"
            :vendor-options="tableConfig"
        >
            <template :slot="columnHeaders.productSid" slot-scope="{ row }">
                {{ row.productSid }}
            </template>
            <template :slot="columnHeaders.productName" slot-scope="{ row }">
                {{ row.productName }}
            </template>
            <template :slot="columnHeaders.lastUpdated" slot-scope="{ row }">
                {{ formatDate(row.lastUpdated) }}
            </template>
            <template :slot="columnHeaders.lastUpdatedBy" slot-scope="{ row }">
                {{ row.lastUpdatedBy }}
            </template>
            <template
                v-if="hasMasterDataAdmin"
                :slot="columnHeaders.actions"
                slot-scope="{ row }"
            >
                <div class="flex-row justify-between">
                    <div />
                    <div class="flex-row actions">
                        <pui-button
                            state="primary"
                            small
                            icon="edit"
                            @click="openEditModal(row)"
                        >
                            {{ $t('edit') }}
                        </pui-button>
                        <pui-button
                            class="ml-1"
                            state="primary"
                            small
                            icon="delete"
                            @click="showDeleteConfirmation(row)"
                        >
                            {{ $t('remove') }}
                        </pui-button>
                    </div>
                </div>
            </template>
        </pui-table>
        <div class="flex-row justify-between mt-2">
            <div />
            <pui-button
                v-if="hasMasterDataAdmin"
                class="mr-3"
                state="secondary"
                icon="add"
                @click="openAddModal"
            >
                {{ $t('addNew') }}
            </pui-button>
        </div>
        <product-add-edit
            ref="productAddEdit"
            @reload-products="loadProducts()"
        />
        <confirmation-dialog
            ref="deleteConfirmationDialog"
            @confirm="deleteProduct"
        />
    </div>
</template>

<script lang="ts">
import { Mixins, Component } from 'vue-property-decorator';
import { productService } from '@/services';
import { OperationModeProduct, TableDetails } from '@/models';
import { DateHelper } from '@/utils';
import ProductAddEdit from './product-add-edit.vue';
import ConfirmationDialog from '@/components/shared/dialogs/confirmation-dialog.vue';
import ComponentSecurity from '@/mixins/component-security';

@Component({
    name: 'products-table',
    components: {
        ProductAddEdit,
        ConfirmationDialog,
    },
})

export default class ProductsTable extends Mixins(ComponentSecurity) {
    private products: OperationModeProduct[] = [];

    private get columnHeaders() {
        return {
            productSid: this.$t('productSid') as string,
            productName: this.$t('productName') as string,
            lastUpdated: this.$t('lastUpdated') as string,
            lastUpdatedBy: this.$t('lastUpdatedBy') as string,
            ...this.hasMasterDataAdmin && { actions: ' ' },
        };
    }

    private async created(): Promise<void> {
        await this.loadProducts();
    }

    private get operationModeId(): number {
        return Number(this.$route.query.edit);
    }

    private async loadProducts(): Promise<void> {
        this.products = await productService.getOperationModeProducts(this.operationModeId);
    }

    private get tableConfig(): TableDetails {
        return {
            columns: Object.values(this.columnHeaders),
            data: this.products,
            options: {
                sortable: [],
                perPage: 500,
                texts: {
                    noResults: this.$t('noDataToShow'),
                },
            },
        };
    }

    private formatDate(date: string | Date): string {
        return DateHelper.formatDate(date);
    }

    private openAddModal(): void {
        const newProduct = new OperationModeProduct({
            operationModeSid: Number(this.operationModeId),
        });
        (this.$refs?.productAddEdit as ProductAddEdit)?.open(newProduct);
    }

    private openEditModal(product: OperationModeProduct): void {
        (this.$refs?.productAddEdit as ProductAddEdit)?.open(product, true);
    }

    private showDeleteConfirmation(product: OperationModeProduct): void {
        const confirmationMessage = `${this.$t('areYouSureDeleteItem')}`;
        (this.$refs?.deleteConfirmationDialog as ConfirmationDialog).open(confirmationMessage, product);
    }

    private async deleteProduct(product: OperationModeProduct): Promise<void> {
        if (await productService.deleteOperationModeProduct(product)) {
            this.loadProducts();
        }
    }
}

</script>

<style scoped lang="less">
@import "~@/components/asset-master-data/common-assets/table-style.less";

</style>
