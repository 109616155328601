import { ApiResponse, BusinessPartner, BusinessPartnerQueryModel, BusinessPartnersDetails, PagedResponse } from '@/models';
import { EventBus } from '@/utils';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
import { generateQuery } from '@/utils/api-helper';

class BusinessPartnersService {
    private api = new SdkGenericApiHelper('business-partners');

    public async getAll(filter: BusinessPartnerQueryModel): Promise<BusinessPartner[]> {
        const query = generateQuery(filter as any);
        const { data: { result: { items } } } = await this.api.get(query);
        return items;
    }

    public async get(filter: BusinessPartnerQueryModel): Promise<ApiResponse<PagedResponse<BusinessPartner>>> {
        const query = generateQuery(filter as any);
        return (await this.api.get(query)).data;
    }

    public async getById(sid: number): Promise<ApiResponse<BusinessPartnersDetails>> {
        return (await this.api.getByPathOrParam(sid)).data;
    }

    public async create(partner: BusinessPartnersDetails): Promise<ApiResponse<BusinessPartnersDetails>> {
        return (await this.api.post(partner)).data;
    }

    public async edit(partner: BusinessPartnersDetails): Promise<ApiResponse<BusinessPartnersDetails>> {
        return (await this.api.put(partner.locationSid, partner)).data;
    }

    public async getBusinessPartners(): Promise<any> {
        try {
            return (await this.getAll(
                {includeDeleted: false} as unknown as BusinessPartnerQueryModel
            ));
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingBusinessPartners');
            return [];
        }
    }
}

const businessPartnersService = new BusinessPartnersService();

export {
    BusinessPartnersService,
    businessPartnersService,
};
