var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"identification-container"},[_c('validation-observer',{ref:"validationObserver"},[_c('div',{staticClass:"identification"},[(_vm.data.etsId !== 0)?_c('div',{staticClass:"flex-row manage-input mt-3"},[_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"etsUnitId"}},[_vm._v(_vm._s(_vm.$t('etsId'))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.etsId),expression:"data.etsId"}],staticClass:"default-input",attrs:{"placeholder":_vm.$t('etsId'),"id":"etsUnitId","disabled":""},domProps:{"value":(_vm.data.etsId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "etsId", $event.target.value)}}})]):_vm._e(),_c('validation-provider',{staticClass:"manage-input mt-3",attrs:{"tag":"div","name":"shortName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"flex-row manage-input mt-3"},[_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"identifier"}},[_vm._v(_vm._s(_vm.$t('shortName'))+"*:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.shortName),expression:"data.shortName"}],staticClass:"default-input",class:{ 'invalid': failed },attrs:{"placeholder":_vm.$t('shortName'),"id":"shortName","maxlength":"60"},domProps:{"value":(_vm.data.shortName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "shortName", $event.target.value)}}})]),_c('error-list',{attrs:{"errors":errors}})]}}])}),_c('validation-provider',{staticClass:"manage-input mt-3",attrs:{"tag":"div","name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"flex-row manage-input mt-3"},[_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('name'))+"*:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.name),expression:"data.name"}],staticClass:"default-input",class:{ 'invalid': failed },attrs:{"placeholder":_vm.$t('name'),"id":"name","maxlength":"120"},domProps:{"value":(_vm.data.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "name", $event.target.value)}}})]),_c('error-list',{attrs:{"errors":errors}})]}}])}),_c('div',{staticClass:"flex-row manage-input mt-3"},[_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"referenceNumber"}},[_vm._v(_vm._s(_vm.$t('referenceNumber'))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.referenceNumber),expression:"data.referenceNumber"}],staticClass:"default-input",attrs:{"placeholder":_vm.$t('referenceNumber'),"id":"referenceNumber","maxlength":"60"},domProps:{"value":(_vm.data.referenceNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "referenceNumber", $event.target.value)}}})]),_c('div',{staticClass:"flex-row manage-input mt-3"},[_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"registerName"}},[_vm._v(_vm._s(_vm.$t('registerName'))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.registerName),expression:"data.registerName"}],staticClass:"default-input",attrs:{"placeholder":_vm.$t('registerName'),"id":"registerName","maxlength":"120"},domProps:{"value":(_vm.data.registerName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "registerName", $event.target.value)}}})]),(_vm.data.etsId)?_c('validation-provider',{staticClass:"manage-input mt-3",attrs:{"tag":"div","name":"comments","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"flex-row manage-input mt-3"},[_c('label',{staticClass:"mr-2 default-label",attrs:{"for":"lastUpdateComment"}},[_vm._v(_vm._s(_vm.$t('comments'))+":")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.lastUpdateComment),expression:"data.lastUpdateComment"}],staticClass:"default-textarea",class:{ 'invalid': failed },attrs:{"maxlength":"1000","placeholder":_vm.$t('comments'),"id":"lastUpdateComment","rows":"4"},domProps:{"value":(_vm.data.lastUpdateComment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "lastUpdateComment", $event.target.value)}}})]),_c('error-list',{attrs:{"errors":errors}})]}}],null,false,3982397551)}):_vm._e(),_c('div',{staticClass:"flex-row mt-3"},[_c('checkbox-input',{attrs:{"label":_vm.$t('markAsDeleted')},model:{value:(_vm.data.isDeleted),callback:function ($$v) {_vm.$set(_vm.data, "isDeleted", $$v)},expression:"data.isDeleted"}})],1),(_vm.data.etsId)?_c('last-updated',{attrs:{"data":_vm.data}}):_vm._e(),_c('error-list',{attrs:{"errors":_vm.invalidMessages}})],1),_c('div',{staticClass:"members-footer"},[(_vm.hasMasterDataAdmin)?_c('adam-button',{staticClass:"ml-1",attrs:{"secondary":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }