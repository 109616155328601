import { IdTypeSid } from '@/models';

export function getAdamIcon(idTypeSid: number, selected = false): string {
    switch (idTypeSid) {
        case IdTypeSid.TIME_ZONE:
        case IdTypeSid.COUNTRY:
        case IdTypeSid.STATE:
        case IdTypeSid.REGION:
            return selected ? 'ic-module-selected' : 'ic-module';
        case IdTypeSid.FLEET:
            return 'ic-fuel';
        case IdTypeSid.PLANT_GROUP:
        case IdTypeSid.POWER_PLANT_TYPE:
            return selected ? 'ic-group-selected' : 'ic-group';
        case IdTypeSid.UNIT_TYPE:
        case IdTypeSid.OPERATION_MODE_ENERGY_TYPE:
        case IdTypeSid.OPERATION_STATUS_TYPE:
        case IdTypeSid.OPERATION_STATUS_CHANGE_REASON:
        case IdTypeSid.MSB_TYPE:
        case IdTypeSid.OPERATION_MODE_TYPE:
        case IdTypeSid.EQUIPMENT_CLASS_TYPE:
        case IdTypeSid.SAP_EC_TYPE:
            return 'ic-unit-blue';
        case IdTypeSid.ORGANIZATION_LIST:
            return 'ic-company';
        default:
            return 'ic-settings';
    }
}
