<template>
    <div v-if="model && options.length > 0">
        <pui-form-group
            :label="label"
            :isValid="model.isValid"
            :show-required-label="required"
        >
            <pui-form-select
                :label="placeholder"
                v-model.number="model.value"
                :isValid="model.isValid"
                :options="options"
                :is-readonly="disable"
                :has-searchable-secondary-labels="hasSearchableSecondaryLabels"
                @change="input"
                @blur="blur"
            />
            <span slot="error-message">
                {{ $t('validation.required') }}
            </span>
        </pui-form-group>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {FormValuesModel} from '@/models/pebble/form-values-model';
import {PebbleDropDown} from '@/models/pebble/pebble-drop-down';

@Component({
    name: 'pebble-drop-down-select',
})
export default class PebbleDropDownSelect extends Vue {
    @Prop({required: true})
    private name!: string;

    @Prop({required: true})
    private model!: FormValuesModel;

    @Prop({required: true})
    private options!: PebbleDropDown[];

    @Prop({required: true})
    private label!: string;

    @Prop({default: false})
    private required!: boolean;

    @Prop({default: false})
    private disable!: boolean;

    @Prop({default: false})
    private hasSearchableSecondaryLabels!: boolean;

    @Prop({default: 'Select a field'})
    private placeholder?: string;

    private input(val: string): void {
        this.$emit('on-input', val, this.name);
        this.$forceUpdate();
    }

    private blur(): void {
        this.$emit('on-blur', this.model.value, this.name);
        this.$forceUpdate();
    }
}
</script>


