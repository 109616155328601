<template>
    <div class="business-partners-management flex-col full-height">
        <div v-show="!selectedBusinessPartnerDetails" class="flex-row business-partners-details">
            <div class="flex flex-4"></div>
            <div class="flex-col">
                <div class="flex-row">
                    <div class="mr-1 pt-2 pb-1 show-deleted center-align">
                        <pui-form-checkbox
                            v-model="showDeletedBusinessPartners"
                            :label="$t('showDeletedItems')"
                            @change="reloadBusinessPartners"
                            :is-disabled="false"
                        />
                    </div>
                    <div class="mr-1 pt-1 pb-1 center-align">
                        <search-component v-model="searchTerm" @input="onSearchTermChange"/>
                    </div>
                    <div class="mr-2 center-align">
                        <adam-button
                            v-if="shouldShowAddButton && hasMasterDataAdmin"
                            class="mr-1 mt-1"
                            icon="ic-add-dashboard"
                            @click="addNewEntity()"
                            secondary>
                            {{ $t('addNew') }}
                        </adam-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-area" id="view-items">
            <div v-if="businessPartners.length === 0" class="mt-2 ml-2">
                {{ $t('noDataToShow') }}
            </div>
            <div v-else-if="!selectedBusinessPartnerDetails"
                class="cards-display">
                <sort-header
                    v-if="headerColumn.length"
                    :headerColumns="headerColumn"
                    :objectToSort="businessPartners"
                    @get-sorted-list="setSortedLocalBusinessPartners"
                ></sort-header>
                <card-list>
                    <template v-slot:cards>
                        <div v-for="partner of businessPartners"
                            :key="partner.businessPartnerSid"
                            class="card">
                            <business-partner-card-list
                                :card="partner"
                                @edit="selectBusinessPartner"
                            />
                        </div>
                    </template>
                </card-list>
                <div v-if="hasNextPage" class="flex-row mt-2" >
                    <div class="flex-col load-more">
                        <adam-button
                            @click="calculateScroll()"
                            secondary
                        >
                            {{ $t('loadMore') }}
                        </adam-button>
                    </div>
                </div>
            </div>
            <div v-else-if="selectedBusinessPartnerDetails"
                class="edit-screen">
                <business-partner-details
                    :data="selectedBusinessPartnerDetails"
                    @close="closeDetailsView()"
                    @save="saveChanges()"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import BusinessPartnerCardList from '@/components/cards/cards-list/business-partner-card-list/business-partner-card-list.vue';
import CardList from '@/components/cards/cards-list/cards-list.vue';
import SearchComponent from '@/components/search-component/search-component.vue';
import BusinessPartnerDetails from '@/components/view-details/business-partner/business-partner-details.vue';
import { BusinessPartner, BusinessPartnerQueryModel, BusinessPartnersDetails, FilterGroup, GenericTab } from '@/models';
import clickOutside from '@/directives/click-outside';
import FadeTransition from '@/components/transitions/fade-transition/fade-transition.vue';
import MdFilterTags from '@/components/md-filter/md-filter-tags/md-filter-tags.vue';
import MdFilter from '@/components/md-filter/md-filter.vue';
import { EventBus, InfiniteScrollingHelper } from '@/utils';
import { throttle } from 'lodash';
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { BusinessPartnerFactory} from '@/utils/factories';
import SortHeader from '@/views/sort-header/sort-header.vue';
import ComponentSecurity from '@/mixins/component-security';
import { filterService, BusinessPartnersService } from '@/services';

const SCROLL_THROTTLE_MILLISECONDS = 500;

@Component({
    name: 'business-partners-management',
    components: {
        searchComponent: SearchComponent,
        cardList: CardList,
        businessPartnerCardList: BusinessPartnerCardList,
        businessPartnerDetails: BusinessPartnerDetails,
        fadeTransition: FadeTransition,
        mdFilterTags: MdFilterTags,
        mdFilter: MdFilter,
        sortHeader: SortHeader,
    },
    directives: {
        clickOutside,
    },
})

export default class BusinessPartnersManagement extends Mixins(ComponentSecurity) {
    private businessPartnerService: BusinessPartnersService = new BusinessPartnersService();
    private selectedBusinessPartner: BusinessPartner | null = null;
    private selectedBusinessPartnerDetails: BusinessPartnersDetails | null = null;
    private businessPartners: BusinessPartner[] = [];
    private filterOptions: FilterGroup[] = [];
    private isLoadingNextPage = false;
    private hasNextPage = false;

    private searchTerm = '';
    private isNewUnit = false;
    private onScroll = throttle(this.calculateScroll, SCROLL_THROTTLE_MILLISECONDS);
    private showDeletedBusinessPartners = false;

    private filterModel: BusinessPartnerQueryModel = {
        page: 1,
        size: 10,
        term: '',
        sortDirection: '',
        sortColumn: '',
        includeDeleted: this.showDeletedBusinessPartners,
    };
    private tabs: GenericTab[] = [
        { name: 'identification', component: 'estUnits'},
    ];
    private activeTab = this.tabs[0];
    private sortOrder = 'asc';
    private sortKey = 'name';
    private headerColumn: Array<{
        attributeName: string;
        name: string;
        itemCssClassName: string;
    }> = [];

    @Watch('$route.query.item')
    private async onQueryItemChanged(newValue: string | undefined, oldValue: string | undefined) {
        if (newValue === oldValue) {
            return;
        }

        if (!newValue) {

            if (!this.businessPartners.length) {
                await this.getBusinessPartners();
            }
            this.addEventListener();
            return;
        }
        this.removeEventListener();
    }


    private async mounted(): Promise<void> {
        await this.getBusinessPartners();
        this.addEventListener();
        await this.initSearchAndFilter();
        this.headerColumn = [
            {
                attributeName: 'abbreviation',
                name: this.$t('identifier').toString(),
                itemCssClassName: 'bp-short-name',
            },
            {
                attributeName: 'businessPartnerSid',
                name: this.$t('sid').toString(),
                itemCssClassName: 'bp-sid',
            },
            {
                attributeName: 'name',
                name: this.$t('name').toString(),
                itemCssClassName: 'bp-name',
            },
            {
                attributeName: 'lastUpdated',
                name: this.$t('lastUpdated').toString(),
                itemCssClassName: 'bp-last-updated',
            },
            {
                attributeName: 'lastUpdatedBy',
                name: this.$t('lastUpdatedBy').toString(),
                itemCssClassName: 'bp-last-updated-by',
            },
            {
                attributeName: 'deleted',
                name: this.$t('deleted').toString(),
                itemCssClassName: 'bp-is-deleted',
            },
        ];
        if (!this.$route.query.item) {
            await this.loadFilteredBusinessPartner();
            this.addEventListener();
            return;
        }
        this.removeEventListener();

        if (!this.$route.query.item) {
            return;
        }

        const businessPartner = this.businessPartners.find((p: BusinessPartner) =>
            Number(this.$route.query.item) === p.businessPartnerSid);
        if (!businessPartner) {
            return;
        }

        this.selectBusinessPartner(businessPartner);
    }

    private beforeDestroy(): void {
        this.removeEventListener();
    }

    private addEventListener(): void {
        const element = document.getElementById('view-items');
        if (element) {
            element.addEventListener('scroll', this.onScroll);
            element.addEventListener('wheel', this.onScroll);
        }
    }


    private get isSearchApplied(): boolean {
        return this.searchTerm.length > 0 ;
    }

    private get shouldShowAddButton(): boolean {
        return this.isSearchApplied ? false : true;
    }

    private async onSearchTermChange(): Promise<void> {
        await this.getBusinessPartners();
        this.addEventListener();
    }

    private async loadFilteredBusinessPartner(): Promise<BusinessPartner[]> {
        this.$store.commit('loading');
        let businessPartners: BusinessPartner[] = [];
        this.filterModel = {
            includeDeleted: this.showDeletedBusinessPartners,
            page: this.filterModel.page,
            size: 10,
            term: this.searchTerm,
            sortDirection: '',
            sortColumn: '',
        };
        try {
            const { result: { items, hasNextPage } } = (await this.businessPartnerService.get(this.filterModel));
            this.hasNextPage = hasNextPage;
            businessPartners = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingBusinessPartners');
        } finally {
            this.$store.commit('loading');
        }
        return businessPartners;
    }

    private removeEventListener(): void {
        const element = document.getElementById('view-items');
        if (element) {
            element.removeEventListener('scroll', this.onScroll);
            element.removeEventListener('wheel', this.onScroll);
        }
    }

    private calculateScroll(): void {
        if (this.selectedBusinessPartnerDetails) {
            return;
        }
        InfiniteScrollingHelper.calculateScroll(document.getElementById('view-items'), this.getNextPage, 200);
    }

    private closeDetailsView(): void {
        this.selectedBusinessPartnerDetails = null;
        if (this.isSearchApplied) {
            return;
        }
        if (!this.isNewUnit && !this.isSearchApplied) {
            this.$router.push({
                name: 'manage-option',
                params: {
                    option: 'business-partners-management',
                },
            });
        }
        this.isNewUnit = false;
    }

    private addNewEntity(): void {
        this.selectedBusinessPartnerDetails = BusinessPartnerFactory.createBusinessPartnerAddRequest();
        this.isNewUnit = true;
    }

    private async initSearchAndFilter() {
        try {
            this.filterOptions = await filterService.getBusinessPartnerFilter();
        } catch (error: any) {
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingFilterData');
            console.error(error);
            throw(error);
        }
    }

    private async loadSelectedBusinessPartnerDetails(businessPartner: BusinessPartner):
    Promise<BusinessPartnersDetails> {
        this.$store.commit('loading');
        let businessPartnerDetails: BusinessPartnersDetails;
        try {
            const { result } = await this.businessPartnerService.getById(businessPartner.businessPartnerSid);
            businessPartnerDetails = result;
        } catch (error: any) {
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingBusinessPartnerDetails');
            console.error(error);
            throw(error);
        } finally {
            this.$store.commit('loading');
        }
        return businessPartnerDetails;
    }

    private async getNextPage(): Promise<void> {
        this.removeEventListener();
        if (this.isLoadingNextPage) {
            return;
        }

        if (!this.hasNextPage) {
            return;
        }
        this.filterModel.page++;
        this.isLoadingNextPage = true;
        const businessPartners = await this.loadFilteredBusinessPartner();
        businessPartners.forEach((p: BusinessPartner) => this.businessPartners.push(p));
        await this.$nextTick();
        this.addEventListener();
        this.isLoadingNextPage = false;
    }

    private async searchCards(searchString: string): Promise<void> {
        this.filterModel.term = searchString;
        await this.getBusinessPartners();
    }

    private async selectBusinessPartner(businessPartner: BusinessPartner): Promise<void> {
        this.selectedBusinessPartner = { ...businessPartner };
        this.selectedBusinessPartnerDetails = await this.loadSelectedBusinessPartnerDetails(businessPartner);
        if (this.$route.path === '/master-data-management/business-partners-management' &&
            this.$route.query.item === this.selectedBusinessPartner.businessPartnerSid.toString()) {
            return;
        }

        this.$router.push({
            name: 'manage-option',
            params: {
                option: this.$route.params.option,
            },
            query: {
                item: this.selectedBusinessPartner.businessPartnerSid.toString(),
            },
        });
    }

    private async reloadBusinessPartners(val: boolean): Promise<void> {
        this.showDeletedBusinessPartners = val;
        this.filterModel.includeDeleted = this.showDeletedBusinessPartners;
        await this.getBusinessPartners();
    }

    private async getBusinessPartners(): Promise<void> {
        this.filterModel.page = 1;
        this.businessPartners = await this.loadFilteredBusinessPartner();
    }

    private async saveChanges(partner: BusinessPartner, isAdd: boolean): Promise<void> {
        if (isAdd) {
            return;
        }
        await this.getBusinessPartners();
        this.addEventListener();
        this.closeDetailsView();
    }

    private async setSortedLocalBusinessPartners(obj: {
        sortedList: BusinessPartner[];
        order: string;
        sortKey: string;
    }): Promise<void> {

        this.sortOrder = obj.order;
        this.sortKey = obj.sortKey;

        const oldpg = this.filterModel.page;
        this.filterModel.size *= oldpg;
        this.filterModel.page = 1;
        if (obj.order === 'asc') {
            this.filterModel.sortDirection = 'Ascending';
        } else {
            this.filterModel.sortDirection = 'Descending';
        }
        this.filterModel.sortColumn = obj.sortKey;

        try {
            const { result: { items, hasNextPage } } = (await this.businessPartnerService.get(this.filterModel));
            this.hasNextPage = hasNextPage;
            this.businessPartners = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingBusinessPartners');
        }
        this.filterModel.page = oldpg;
        this.filterModel.size = 10;
    }
}

</script>

<style lang="less">
@import "~@/variables.less";


.business-partners-management {
    background: @white;
    border-bottom: 2px solid @grey-lighter;

    .business-partners-details {
        background: white;
        border-bottom: 2px solid #E0E0E0;
    }

    .main-area {
        padding: 2.5rem;
        box-sizing: border-box;
        overflow-y: auto;
        height: 100%;

        .name {
            width: 24rem;
        }
        .sid {
            width: 12rem;
        }
        .full-name {
            width: 22rem;
        }
        .last-updated {
            width: 22rem;
        }
        .last-updated-by {
            width: 22rem;
        }
        .deleted {
            width: 10rem;
        }
    }

    .filter {
        position: relative;
        padding-bottom: 0.5rem;
    }
    .search, .filter {
        align-self: center;
    }

    .header-row {
        &__item-bp-short-name,
        &__item-bp-sid,
        &__item-bp-name,
        &__item-bp-last-updated,
        &__item-bp-last-updated-by,
        &__item-bp-is-deleted{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            box-sizing: border-box;
            border-left: 2px solid @lighter-grey;
            padding: 0 1rem;
            box-shadow: 0px 0 4px 0 rgb(0 0 0 / 15%);
        }

        &__item-bp-short-name {
            border-left: none;
            width: 18rem;
        }

        &__item-bp-sid {
            width: 14rem;
        }

        &__item-bp-name {
            width: 24rem;
        }

        &__item-bp-last-updated {
            width: 24rem;
        }

        &__item-bp-last-updated-by {
            width: 24rem;
        }

         &__item-bp-is-deleted {
            width: 10rem;
        }
    }

    .show-deleted{
        width: 16rem;
    }
}

</style>
