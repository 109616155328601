<template>
    <pui-grid-container class="pui-grid-container-zero-padding">
        <pui-grid-row>
            <pui-grid-column :cols="{s:12, m:12, l:6, xl:6, xxl: 6}">
                <pebble-drop-down-select
                    name="selectExternalSystem"
                    :model="model"
                    :label="$t('selectExternalSystem')"
                    :options="options"
                    @on-input="onClickExternalSystem"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row class="pui-grid-row-little-margin-top" v-if="check.dataLoaded">
            <pui-grid-column :cols="{ s:12,m:12,l:12,xl:12,xxl:12 }">
                <custom-table
                    :columns-key="['validFrom','validTo','keyMapping','isActive','isDeleted', 'lastUpdated','lastUpdatedBy', 'action']"
                    :data="keyMappings"
                    :sortable-key="['validFrom','validTo','keyMapping','isActive','isDeleted', 'lastUpdated','lastUpdatedBy']"
                    :heading="heading"
                    :per-page="5"
                    enable-pagination
                    :custom-template="['isActive', 'isDeleted', 'action']"
                >
                    <template v-slot:custom_isActive="{data}">
                        <boolean-text
                            :titles="{
                                textTrue: $t('active'),
                                textFalse: $t('inactive'),
                            }"
                            :value="data"
                        />
                    </template>
                    <template v-slot:custom_isDeleted="{data}">
                        <boolean-text
                            :titles="{
                                textTrue: $t('yes'),
                                textFalse: $t('no'),
                            }"
                            :value="data"
                        />
                    </template>
                    <template v-slot:custom_action="{data}">
                        <pui-grid-container class="pui-grid-container-zero-padding">
                            <pui-grid-row class="pui-grid-row--around">
                                <pui-link
                                    :title="$t('edit')"
                                    icon="edit"
                                    @click="() => onClickEditButton(data)"
                                />
                                <pui-link
                                    :title="$t('remove')"
                                    icon="delete"
                                    @click="() => onClickDeleteButton(data)"
                                />
                            </pui-grid-row>
                        </pui-grid-container>
                    </template>
                </custom-table>
            </pui-grid-column>
            <pui-grid-column class="pui-grid-col--end pui-grid-row-little-margin-top">
                <pui-button
                    icon="add"
                    state="secondary"
                    @click="onClickAddNewVersionButton"
                >
                    {{ $t('addNewVersion') }}
                </pui-button>
            </pui-grid-column>
            <pui-lightbox
                ref="modalForm"
                :default-footer-confirm-label="$t('save')"
                :default-footer-cancel-label="$t('cancel')"
                :show-lightbox-close-icon="false"
                :closeOnESC="false"
                :fit-content="true"
                :default-footer-confirm-disabled="!hasMasterDataAdmin || check.saveButtonIsDisabled"
                :on-cancel-callback="onClickCancelButton"
                :on-confirm-callback="onClickSaveButton"
            >
                <pui-grid-container>
                    <pui-grid-row>
                        <pui-grid-column
                            :class="errors.length > 0 ? 'pebble-errors-list-margin-top' : ''"
                        >
                            <pebble-errors-list
                                id="errors-list"
                                :errors="errors"
                            />
                        </pui-grid-column>
                        <pui-grid-column>
                            <pui-form aria-label="Form">
                                <key-mapping-data-form
                                    :data="formValues"
                                    :add-new="check.addNewVersion"
                                    @validate="validate"
                                />
                            </pui-form>
                        </pui-grid-column>
                    </pui-grid-row>
                </pui-grid-container>
            </pui-lightbox>
        </pui-grid-row>
        <pui-grid-row class="pui-grid-row-little-margin-top" v-else-if="check.showError">
            <pui-grid-column :cols="{ s:12,m:12,l:12,xl:12,xxl:12 }">
                <pui-loader-error
                    :title="$t('errorTitleDataLoaded')"
                    :message="$t('errorLoadingVersions')"
                    icon="error-alert"
                    :buttons="[
                        {
                            state: 'secondary',
                            label: $t('refresh'),
                            onClick: () => onClickRefreshButton()
                        }
                    ]"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import ComponentSecurity from '@/mixins/component-security';
import Component, {mixins} from 'vue-class-component';
import PebbleDropDownSelect from '@/components/pebble-form-element/pebble-drop-down-select.vue';
import {Asset} from '@/models';
import {FormValuesModel} from '@/models/pebble/form-values-model';
import {PebbleDropDown, pebbleDropDownFromAssets} from '@/models/pebble/pebble-drop-down';
import {Prop} from 'vue-property-decorator';
import {KeyMappingService} from '@/services';
import {EventBus} from '@/utils';
import {KeyMappingModel} from '@/models/key-mapping-model';
import CustomTable from '@/components/table/custom-table.vue';
import {CheckModel} from '@/models/check-model';
import BooleanText from '@/components/text/boolean-text.vue';
import KeyMappingDataForm from '@/components/forms/key-mapping-data-form.vue';
import {KeyMappingFactory} from '@/utils/factories/key-mapping-factory';
import CancelSaveButtons from '@/components/buttons/cancel-save-buttons.vue';
import {generateDataFromFormValues, generateErrors, isValidInput, shouldDisableForm, showDialog} from '@/utils/utils';
import PebbleErrorsList from '@/components/error-list/pebble-errors-list.vue';
import {PebbleNotification} from '@/models/pebble/pebble-notification';
import {KeyMappingForm} from '@/models/form/key-mapping-form';
import {keyMappingForm} from '@/utils/pebble-form/master-data-management/key-mapping-form';

@Component({
    name: 'key-mapping-view',
    components: {
        PebbleErrorsList,
        CancelSaveButtons, KeyMappingDataForm, BooleanText, CustomTable, PebbleDropDownSelect,
    },
})
export default class KeyMappingView extends mixins(ComponentSecurity) {
    /* VARIABLES */
    private keyService: KeyMappingService = new KeyMappingService();
    private model: FormValuesModel = {
        value: -1,
        isValid: true,
    };
    private check: CheckModel = {
        showError: false,
        dataLoaded: false,
        saveButtonIsDisabled: true,
        addNewVersion: false,
    };
    private errors: PebbleNotification[] = [];

    /* LATE VARIABLES */
    private options!: PebbleDropDown[];
    private keyMappings: KeyMappingModel[] = [];
    private selectedVersion?: KeyMappingModel;
    private formValues?: KeyMappingForm;
    private selectAsset?: Asset;

    @Prop({required: true})
    private objectSid!: number;

    @Prop({default: () => []})
    private data!: Asset[];

    get heading(): any {
        return {
            validFrom: this.$t('validFrom'),
            validTo: this.$t('validUntil'),
            keyMapping: this.$t('keyMapping'),
            lastUpdated: this.$t('lastUpdated'),
            lastUpdatedBy: this.$t('lastUpdatedBy'),
            isActive: this.$t('active'),
            isDeleted: this.$t('deleted'),
            action: '',
        };
    }

    /* PRIMITIVE METHODS */
    private created(): void {
        this.options = pebbleDropDownFromAssets(this.data);
    }

    private mounted(): void {
        if (this.$route.query?.versionSid) {
            this.model.value = +this.$route.query?.versionSid;
            this.selectAsset = this.data.find((x) => x.sid === +this.model.value);
        }
    }

    /* METHODS */
    private async onSelectChange(value: string): Promise<void> {
        this.formValues = undefined;
        this.selectedVersion = undefined;
        this.check.addNewVersion = false;
        if (`${value}` !== '-1') {
            this.selectAsset = this.data.find((x) => x.sid === +value);
            this.keyMappings = await this.loadVersion(+value);
            this.check.dataLoaded = true;
            this.$forceUpdate();
        } else {
            this.check.dataLoaded = false;
        }
    }

    private replacePage(versionSid?: string): void {
        if (this.$route.query?.versionSid !== versionSid) {
            this.$router.replace({
                name: `${this.$route.name}`,
                query: {
                    ...this.$route.query,
                    versionSid,
                },
            });
        }
    }

    private validate(value: string, name: string): void {
        isValidInput(name, this.formValues?.[name]);
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, !this.check.addNewVersion ? this.selectedVersion : undefined);
    }

    /* ON CLICK BUTTON */
    private onClickExternalSystem(value: number): void {
        this.replacePage(value.toString());
        this.onSelectChange(`${value}`);
    }

    private onClickAddNewVersionButton(): void {
        this.formValues = keyMappingForm(KeyMappingFactory.create(this.objectSid, this.selectAsset?.sid));
        this.check.addNewVersion = true;
        this.errors = [];
        (this.$refs.modalForm as any)?.open();
    }

    private async onClickEditButton(data: KeyMappingModel): Promise<void> {
        this.selectedVersion = data;
        this.formValues = keyMappingForm(data);
        this.errors = [];
        (this.$refs.modalForm as any)?.open();
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedVersion);
        this.$forceUpdate();
    }

    private async onClickDeleteButton(data: KeyMappingModel): Promise<void> {
        if (await showDialog(this, 'areYouSureDeleteItem')) {
            if (await this.deleteVersion(data)) {
                await this.replacePage(this.model.value.toString());
                await this.onSelectChange(this.model.value);
                this.$forceUpdate();
            }
        }
    }

    private async onClickCancelButton(): Promise<void> {
        if (!this.check.saveButtonIsDisabled && !await showDialog(this, 'areYouSureCancelUnsaved')) {
            return;
        }
        this.formValues = keyMappingForm(this.selectedVersion);
        this.errors = [];
        this.check.addNewVersion = false;
        this.check.saveButtonIsDisabled = true;
        (this.$refs.modalForm as any)?.close();
        this.$forceUpdate();
    }

    private onClickSaveButton(): void {
        this.saveData();
    }

    private onClickRefreshButton(): void {
        this.onSelectChange(this.model.value);
    }

    /* API CALLS */
    private async loadVersion(sid: number): Promise<KeyMappingModel[]> {
        this.$store.commit('loading');
        try {
            const {result: {items}} = await this.keyService.getVersions(sid, this.objectSid);
            return items;
        } catch (err: any) {
            this.check.showError = true;
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async deleteVersion(data: KeyMappingModel): Promise<boolean> {
        this.$store.commit('loading');
        try {
            if (!this.selectAsset) {
                return false;
            }
            await this.keyService.removeVersion(this.selectAsset?.sid, data.validFrom, data.objectSid);
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorRemovingItem');
            generateErrors(this.errors, error);
            return false;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async editVersion(data: KeyMappingModel): Promise<boolean> {
        this.$store.commit('loading');
        try {
            if (!this.selectedVersion) {
                return false;
            }
            await this.keyService.editVersion(data, this.selectedVersion?.validFrom, this.objectSid);
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            generateErrors(this.errors, error);
            return false;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveNewVersion(data: KeyMappingModel): Promise<boolean> {
        this.$store.commit('loading');
        try {
            await this.keyService.addVersion(this.model.value, data, this.objectSid);
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingItem');
            generateErrors(this.errors, error);
            return false;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveData(): Promise<void> {
        if (this.check.addNewVersion
            ? await this.saveNewVersion(generateDataFromFormValues(this.formValues) as KeyMappingModel)
            : await this.editVersion(generateDataFromFormValues(this.formValues) as KeyMappingModel)
        ) {
            await this.onSelectChange(this.model.value);
            (this.$refs.modalForm as any)?.close();
            this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, !this.check.addNewVersion ? this.selectedVersion : undefined);
        }
    }
}
</script>

