<template>
    <div class="container">
        <pui-headline type="h3">
            {{ $t('hydroComponents.titles.list') }}
        </pui-headline>
        <pui-grid-row>
            <pui-grid-column :cols="{s:12, m:8, l:8, xl:8, xxl: 8}">
                <custom-filter
                    v-if="isInitialDataLoaded"
                    :filters="filters"
                    :on-filter-change="onFilterChange"
                />
            </pui-grid-column>
            <pui-grid-column
                :cols="{s:12, m:4, l:4, xl:4, xxl: 4}"
                class="add-new-button-container"
            >
                <pui-button
                    v-if="canEditHydroComponents"
                    icon="add"
                    class="add-new-button"
                    @click="onAddNewClick"
                >
                    {{ $t("hydroComponents.buttons.new") }}
                </pui-button>
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column
                :cols="{s:12, m:8, l:8, xl:8, xxl: 8}"
                class="checkbox-column"
            >
                <pebble-check-box
                    :label="$t('showDeletedItems')"
                    :model="showDeletedItemsModel"
                    name="isDeleted"
                    @on-change="onShowDeletedChange"
                />
            </pui-grid-column>
        </pui-grid-row>
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import CustomFilter from '@/components/filter/custom-filter.vue';
import { Filter } from '@/models/custom-filter/filter';
import { Asset, IdTypeSid, SearchPaginationQueryModel } from '@/models';
import { AssetService, PowerPlantService } from '@/services';
import { EventBus } from '@/utils';
import { FilterValue } from '@/models/custom-filter/filter-value';
import { PowerPlantOverview } from '@/models/power-plant-overview';
import { HydroComponentsLocationTypes } from '@/models/enum-location-type';
import ComponentSecurity from '@/mixins/component-security';
import PebbleCheckBox from '@/components/pebble-form-element/pebble-check-box.vue'
import { FormValuesModel } from '@/models/pebble/form-values-model'

@Component({
    name: 'hydro-components-header',
    components: {
        PebbleCheckBox,
        CustomFilter,
    }
})
export default class HydroComponentsHeader extends Mixins(ComponentSecurity) {
    @Prop({ required: true }) private selectedPlantGroups!: string[];
    @Prop({ required: true }) private selectedPlants!: string[];
    @Prop({ required: true }) private showDeletedItems!: boolean;

    private readonly assetService = new AssetService();
    private readonly powerPlantService = new PowerPlantService();

    private filters: Filter[] = [];

    private isInitialDataLoaded = false;

    private mounted(): void {
        this.loadInitialData().then();
    }

    private get showDeletedItemsModel(): FormValuesModel {
        return {
            value: this.showDeletedItems,
            isValid: true,
        }
    }

    private async loadInitialData(): Promise<void> {
        const plantGroups = await this.getPlantGroups();
        const plants = await this.getPlants();

        const plantGroupFilter = this.generatePlantGroupFilter(plantGroups.map(e => ({
            displayName: e.description,
            value: e.sid
        })));

        const plantFilter = this.generatePlantFilter(plants.map(e => ({
            displayName: e.locationName,
            value: e.locationSid,
        })));

        this.filters = [plantGroupFilter, plantFilter];
        this.isInitialDataLoaded = true;

        this.onFilterChange({
            plantGroup: this.selectedPlantGroups,
            plant: this.selectedPlants,
        });
    }

    private generatePlantGroupFilter(plantGroups: FilterValue[]): Filter {
        return {
            name: 'plantGroup',
            displayName: this.$t('hydroComponents.filters.plantGroup').toString(),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            appliedValues: {
                options: this.selectedPlantGroups,
            },
            selectedValues: {
                options: this.selectedPlantGroups,
            },
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: this.$t('hydroComponents.filters.plantGroup').toString(),
                options: plantGroups,
            },
        };
    }

    private generatePlantFilter(plants: FilterValue[]): Filter {
        return {
            name: 'plant',
            displayName: this.$t('hydroComponents.filters.plant').toString(),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            appliedValues: {
                options: this.selectedPlantGroups,
            },
            selectedValues: {
                options: this.selectedPlantGroups,
            },
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: this.$t('hydroComponents.filters.plant').toString(),
                options: plants,
            },
        };
    }

    private onFilterChange(data: any): void {
        this.$emit('change:filters', data);
    }

    private onShowDeletedChange(newValue: boolean): void {
        this.$emit('change:deleted', newValue);
    }

    private onAddNewClick(): void {
        this.$router.push({
            name: 'hydro-components-new'
        });
    }

    private async getPlantGroups(): Promise<Asset[]> {
        try {
            const filter: SearchPaginationQueryModel = {
                page: 1,
                size: 1000,
                term: '',
            };

            const { result: { items } } = await this.assetService.getByIdTypeSid(IdTypeSid.PLANT_GROUP, filter);

            items.sort((a, b) => a.description.localeCompare(b.description));

            return items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            return [];
        }
    }

    private async getPlants(): Promise<PowerPlantOverview[]> {
        const response = await this.powerPlantService.get();
        const results: PowerPlantOverview[]  = response.result?.data ?? [];
        const applicableItems = results.filter(e => HydroComponentsLocationTypes.includes(e.enumLocationTypeSid));

        applicableItems.sort((a, b) => a.locationName.localeCompare(b.locationName));

        return applicableItems;
    }
}
</script>

<style scoped>
.container {
    margin: 16px 0 16px 0;
    width: 100%;
}

.add-new-button-container {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: .8rem;
}

.checkbox-column {
    padding-left: 1rem;
    margin-top: -2rem;
}
</style>
