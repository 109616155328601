import { ObjectKeys } from '@/models/object-keys'

export interface TransformersData extends ObjectKeys {
    transformerSid: string;
    transformerName: string;
    plantGroupSid: number;
    plantGroupName: string;
    plantSid: number;
    plantName: string;
    technicalLocation: string;
    lastUpdated: string;
    lastUpdatedBy: string;
    isDeleted: boolean;
    isReserve: boolean;
}

export interface AddTransformersData extends ObjectKeys {
    transformerSid: string;
    validFrom?: string;
    validTo?: string;
    deleted: boolean;
    transformerName: string;
    plantSid: number;
    technicalLocation?: string;
    manufacturerEnumSid?: number;
    transformerTypeEnumSid?: number;
    constructionYear?: number;
    nominalPower?: number;
    primaryVoltageOs?: number;
    secondaryVoltageOs?: number;
    coolingSystemEnumSid?: number;
    oilWeight?: number;
    totalWeight?: number;
    decommissioningDate?: string;
    equipmentNumber?: number;
    isReserve: boolean;
    storagePlantSid?: number;
}

export interface TransformersQuery {
    assetGroupSids: number[];
    plantSids: number[];
    pageNumber: number;
    pageSize: number;
    term: string;
    showDeleted: boolean;
}

export const TransformersDataKeys = [
    'transformerSid',
    'plantGroupName',
    'plantName',
    'transformerName',
    'technicalLocation',
    'lastUpdated',
    'lastUpdatedBy',
    'isReserve',
    'isDeleted',
    'action'
] as const;
