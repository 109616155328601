import { DateHelper } from '@/utils/date-helper';

export class EmissionFactorVersion {
    public static mapToAddEditRequest(emissionFactor: EmissionFactorVersion): any {
        return {
            unitSid: emissionFactor?.unitSid,
            validFrom: emissionFactor?.validFrom,
            validTo: emissionFactor?.validTo,
            emissionFactorNOX: emissionFactor?.emissionFactorNox,
            emissionFactorSOX: emissionFactor?.emissionFactorSox,
            emissionFactorDUST: emissionFactor?.emissionFactorDust,
            updatedAt: emissionFactor?.lastUpdated,
            updatedBy: emissionFactor?.lastUpdatedBy,
            updComment: emissionFactor?.lastUpdateComment,
        };
    }

    public unitSid: number;
    public validFrom: string;
    public validTo: string;
    public emissionFactorNox: number;
    public emissionFactorSox: number;
    public emissionFactorDust: number;
    public lastUpdated: string;
    public lastUpdatedBy: string;
    public lastUpdateComment: string;

    constructor(data?: any) {
      this.unitSid = data?.unitSid;
      this.validFrom = data?.validFrom ?? DateHelper.formatDate(new Date());
      this.validTo = data?.validTo;
      this.emissionFactorNox = data?.emissionFactorNOX;
      this.emissionFactorSox = data?.emissionFactorSOX;
      this.emissionFactorDust = data?.emissionFactorDUST;
      this.lastUpdated = data?.updatedAt;
      this.lastUpdatedBy = data?.updatedBy;
      this.lastUpdateComment = data?.updComment;
    }
}
