var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c('pui-table',{staticClass:"pui-table",attrs:{"vendor-options":_vm.tableConfig},scopedSlots:_vm._u([{key:_vm.columnHeaders.productSid,fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.productSid)+" ")]}},{key:_vm.columnHeaders.productName,fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.productName)+" ")]}},{key:_vm.columnHeaders.lastUpdated,fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatDate(row.lastUpdated))+" ")]}},{key:_vm.columnHeaders.lastUpdatedBy,fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.lastUpdatedBy)+" ")]}},{key:_vm.columnHeaders.actions,fn:function(ref){
var row = ref.row;
return (_vm.hasMasterDataAdmin)?[_c('div',{staticClass:"flex-row justify-between"},[_c('div'),_c('div',{staticClass:"flex-row actions"},[_c('pui-button',{attrs:{"state":"primary","small":"","icon":"edit"},on:{"click":function($event){return _vm.openEditModal(row)}}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]),_c('pui-button',{staticClass:"ml-1",attrs:{"state":"primary","small":"","icon":"delete"},on:{"click":function($event){return _vm.showDeleteConfirmation(row)}}},[_vm._v(" "+_vm._s(_vm.$t('remove'))+" ")])],1)])]:undefined}}],null,true)}),_c('div',{staticClass:"flex-row justify-between mt-2"},[_c('div'),(_vm.hasMasterDataAdmin)?_c('pui-button',{staticClass:"mr-3",attrs:{"state":"secondary","icon":"add"},on:{"click":_vm.openAddModal}},[_vm._v(" "+_vm._s(_vm.$t('addNew'))+" ")]):_vm._e()],1),_c('product-add-edit',{ref:"productAddEdit",on:{"reload-products":function($event){return _vm.loadProducts()}}}),_c('confirmation-dialog',{ref:"deleteConfirmationDialog",on:{"confirm":_vm.deleteProduct}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }