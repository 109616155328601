<template>
    <pui-grid-container class="pui-grid-container-fix-margin">
        <pui-grid-row>
            <master-data-management-header
                :title="$t('manageEnums')"
                :data-loaded="dataLoaded"
                :dropdown-data="dropdownData"
                :dropdown-initial-value="dataLoaded ? selectedOption.idTypeSid : undefined"
                :deleted-initial-value="showDeleted"
                :on-add-new="onAddNew"
                :on-dropdown-select="onDropdownSelect"
                :on-check="onCheck"
            />
        </pui-grid-row>
        <pui-grid-row v-if="dataLoaded">
            <custom-table
                :columns-key="['name', 'sid', 'description', 'isDeleted', 'action']"
                :data="data"
                :sortable-key="['name', 'sid', 'description', 'isDeleted']"
                :heading="heading"
                :per-page="10"
                :per-page-values="[10, 50, 100]"
                :custom-template="['isDeleted', 'action']"
                enable-search
                enable-pagination
                enable-limit
            >
                <template v-slot:custom_isDeleted="{data}">
                    <boolean-text
                        :titles="{
                            textTrue: $t('yes'),
                            textFalse: $t('no'),
                        }"
                        :value="data"
                    />
                </template>
                <template v-slot:custom_action="{data}">
                    <pui-link
                        :title="$t('edit')"
                        icon="edit"
                        @click="onClickEdit(data)"
                    />
                </template>
            </custom-table>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import CustomTable from '@/components/table/custom-table.vue';
import MasterDataManagementHeader from '@/components/headers/master-data-management-header.vue';
import {AssetService} from '@/services';
import {Asset, EnumManagementQueryModel, ManagementOption} from '@/models';
import {EventBus} from '@/utils';
import BooleanText from '@/components/text/boolean-text.vue';

@Component({
    name: 'management-enum-types',
    components: {
        BooleanText,
        YesOrNotText: BooleanText,
        MasterDataManagementHeader,
        CustomTable,
    },
})
export default class ManagementEnumTypes extends Vue {
    private assetService: AssetService = new AssetService();
    private options!: ManagementOption[];
    private selectedOption!: ManagementOption;
    private dropdownData: any[] = [];
    private dataLoaded = false;
    private showDeleted = false;
    private data: any[] = [];
    private filter: EnumManagementQueryModel = {
        term: '',
        page: 1,
        size: 100000,
        sortDirection: '',
        sortColumn: '',
        includeDeleted: false,
    };

    private mounted(): void {
        this.dataLoaded = false;
        this.showDeleted = this.$router.currentRoute.query['deleted'] === 'true' ?? false;
        this.init();
    }

    private async init(): Promise<void> {
        this.$store.commit('loading');
        await this.loadOptions();
        this.data = await this.loadAsset();
        this.dataLoaded = true;
        this.$store.commit('loading');
    }

    private async loadOptions(): Promise<void> {
        this.options = await this.assetService.getEnumList();
        this.selectedOption = this.options.find((value) => value.idTypeSid?.toString() === this.$router.currentRoute.query['sid']) ?? this.options[0];
        this.dropdownData = [];
        this.options.forEach((value) => {
            this.dropdownData.push({label: value.name, value: value.idTypeSid});
        });
    }

    private async loadAsset(): Promise<Asset[]> {
        let assets: Asset[] = [];
        try {
            this.filter.includeDeleted = this.$router.currentRoute.query['deleted']?.toString().toLowerCase() === 'true' ?? false;
            const {result: {items}} = await this.assetService.getByIdTypeSid(this.selectedOption.idTypeSid ?? 0, this.filter);
            assets = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
        return assets;
    }

    get heading(): any {
        return {
            name: this.$t('name').toString(),
            sid: this.$t('sid').toString(),
            description: this.$t('description').toString(),
            isDeleted: this.$t('deleted').toString(),
            action: '',
        };
    }

    private async onDropdownSelect(data: number): Promise<void> {
        await this.$router.replace({
            path: this.$router.currentRoute.path,
            params: this.$router.currentRoute.params,
            query: {
                sid: data.toString(),
                deleted: this.$router.currentRoute.query['deleted'] ?? 'false',
                t: new Date().getTime().toString(),
            },
        });
        await this.init();
    }

    private async onCheck(data: boolean): Promise<void> {
        await this.$router.replace({
            path: this.$router.currentRoute.path,
            params: this.$router.currentRoute.params,
            query: {
                sid: this.$router.currentRoute.query['sid'],
                deleted: data.toString(),
                t: new Date().getTime().toString(),
            },
        });
        await this.init();
    }

    private onClickEdit(data: Asset): void {
        this.$router.push({
            name: 'management-enum-types-edit',
            query: {
                edit: `${data.sid}`,
                name: `${data.name}`,
            },
        });
    }

    private onAddNew(): void {
        const typeSid = this.selectedOption.idTypeSid;
        if (typeSid === null) {
            return;
        }
        this.$router.push({
            name: 'management-enum-types-new',
            query: {
                add: 'true',
                sid: typeSid.toString(),
            },
        });
    }
}
</script>
