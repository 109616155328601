<template>
    <label class="checkbox-container default-label" :class="classProp">
        <pui-form-checkbox
            :label="label"
            :checked="value"
            :is-disabled="disabled"
            @change="onInput"
        />
    </label>
</template>

<script lang="ts">

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'checkbox-input',
})
export default class CheckboxInput extends Vue {
    /**
     * The value of the checkbox.
     */
    @Prop({ required: true })
    private value!: boolean;

    /**
     * The label of the checkbox.
     */
    @Prop({ default: '' })
    private label!: string;

    /**
     * Optional class for the container.
     */
    @Prop({ default: '' })
    private classProp!: string;

    /**
     * Optional disabled attribute.
     */
    @Prop()
    private disabled!: boolean;

    private onInput(checked: boolean): void {
        if (checked !== this.value) {
            this.$emit('input', !this.value);
        }
    }
}

</script>

<style scoped lang="less">
@import "~@/variables.less";

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 3rem;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1.6rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-width: fit-content;
}
.checkbox-container pui-form-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: #fafafa;
    border: 1px solid #e6e6e6;
    border-radius: @border-radius-standard;
}
.checkbox-container:hover pui-form-checkbox ~ .checkmark {
    background-color: #f4f4f4;
}
.checkbox-container pui-form-checkbox:checked ~ .checkmark {
    background-color: @uniper-blue;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.checkbox-container pui-form-checkbox:checked ~ .checkmark:after {
    display: block;
}
.checkbox-container .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.disabled {
    background: @grey-lighter;
}
</style>
