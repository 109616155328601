<template>
    <key-mapping
        ref="materialKeyMapping"
        :object-sid="data.materialSid"
        @close="closeEditScreen"/>
</template>

<script lang="ts">
import {MaterialBaseModel, Tabbable} from '@/models';
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import KeyMapping from '@/components/view-details/key-mapping/key-mapping.vue';

@Component({
    name: 'material-key-mapping',
    components: {
        keyMapping: KeyMapping,
    },
})
export default class MaterialKeyMapping extends Vue implements Tabbable {
    @Ref()
    private readonly materialKeyMapping!: InstanceType<typeof KeyMapping>;
    /**
     * Power plant for which the key mapping tab from editing screen is displayed.
     */
    @Prop()
    private data!: MaterialBaseModel;

    public async beforeLeave(): Promise<boolean> {
        const isFormDirty = await this.materialKeyMapping.isFormDirty();
        if (!isFormDirty) {
            return true;
        }

        try {
            await this.$dialog
                .confirm(
                    {body: this.$t('areYouSureLeaveUnsaved').toString()},
                    {view: 'confirm'});
            return true;
        } catch (error: any) {
            return Promise.resolve(false);
        }
    }

    private async closeEditScreen() {
        const canLeave = await this.beforeLeave();
        if (canLeave) {
            this.$emit('close');
        }
    }
}
</script>
