import { render, staticRenderFns } from "./sinergy-life-location-list.vue?vue&type=template&id=cd3f0eb8&scoped=true"
import script from "./sinergy-life-location-list.vue?vue&type=script&lang=ts"
export * from "./sinergy-life-location-list.vue?vue&type=script&lang=ts"
import style0 from "./sinergy-life-location-list.vue?vue&type=style&index=0&id=cd3f0eb8&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd3f0eb8",
  null
  
)

export default component.exports