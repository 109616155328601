export default class PersistenFilter {
    private appModule = '';

    constructor(appModule: string) {
        this.appModule = appModule;
    }

    public setConfig(configKey: string, value: any): void {
        localStorage.setItem(`${this.appModule}.${configKey}`, JSON.stringify(value));
    }

    public getConfigArray(configKey: string): any {
        const filterValues = localStorage.getItem(`${this.appModule}.${configKey}`) ?? '';
        return this.isEmpty(filterValues)
            ? []
            : JSON.parse(filterValues);
    }

    public getConfigValue(configKey: string): number | undefined {
        const filterValue = localStorage.getItem(`${this.appModule}.${configKey}`) ?? '';
        return this.isEmpty(filterValue)
            ? undefined
            : JSON.parse(filterValue);
    }

    private isEmpty(value: any): boolean {
        return [undefined, null, 'undefined', ''].includes(value);
    }

}
