<template>
    <pui-grid-container v-if="check.dataLoaded" class="pui-grid-container-zero-padding">
        <pui-grid-row
            :class="errors.length > 0 ? 'pebble-errors-list-margin-top' : ''"
        >
            <pebble-errors-list
                id="errors-list"
                :errors="errors"
            />
        </pui-grid-row>
        <pui-form aria-label="Form">
            <pui-grid-row>
                <pui-grid-column>
                    <materials-identification-form
                        :data="formValues"
                        :material-group-list="materialGroupList"
                        :eu-ets-list="euEtsList"
                        :agg-state-list="aggStateList"
                        :quality-list="qualityList"
                        :home-contry-list="homeContryList"
                        :mine-list="mineList"
                        :dehst-list="dehstList"
                        :unit-settle-list="unitSettleList"
                        :is-edit="true"
                        @validate="validate"
                    />
                </pui-grid-column>
            </pui-grid-row>
        </pui-form>
        <pui-grid-row class="pui-grid-row-little-margin-top">
            <pui-grid-column :cols="{s:12, m:5, l:6, xl:6, xxl: 6}">
                <pebble-last-updated
                    :data="{
                        lastUpdatedBy: selectedMaterial.lastUpdatedBy,
                        lastUpdated: selectedMaterial.lastUpdated
                    }"
                />
            </pui-grid-column>
            <pui-grid-column :cols="{s:12, m:7, l:6, xl:6, xxl: 6}">
                <cancel-save-buttons
                    :cancel-button-is-disabled="check.saveButtonIsDisabled"
                    :save-button-is-disabled="!hasMasterDataAdmin || check.saveButtonIsDisabled"
                    :on-click-cancel-button="onClickCancelButton"
                    :on-click-save-button="onClickSaveButton"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
    <pui-grid-container v-else-if="check.showError">
        <pui-grid-row>
            <pui-loader-error
                :title="$t('errorTitleDataLoaded')"
                :message="$t('errorGettingMaterials')"
                icon="error-alert"
                :buttons="[
                    {
                        state: 'secondary',
                        label: $t('refresh'),
                        onClick: onClickRefreshButton,
                    }
                ]"
            />
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import ComponentSecurity from '@/mixins/component-security';
import Component, {mixins} from 'vue-class-component';
import {AssetService, MaterialBaseService, MaterialGroupService} from '@/services';
import {PebbleNotification} from '@/models/pebble/pebble-notification';
import {PebbleDropDown, pebbleDropDownFromAssets, pebbleDropDownFromMaterialGroups} from '@/models/pebble/pebble-drop-down';
import {IdentificationMaterialsForm} from '@/models/form/identification-materials-form';
import {Asset, IdTypeSid, MaterialBaseModel, MaterialEditRequest, MaterialGroupModel} from '@/models';
import {EventBus} from '@/utils';
import {Prop, Watch} from 'vue-property-decorator';
import MaterialsIdentificationForm from '@/components/forms/materials-identification-form.vue';
import {identificationMaterialsForm} from '@/utils/pebble-form/master-data-management/identification-materials-form';
import {CheckModel} from '@/models/check-model';
import {generateDataFromFormValues, generateErrors, isValidInput, shouldDisableForm, showDialog} from '@/utils/utils';
import PebbleErrorsList from '@/components/error-list/pebble-errors-list.vue';
import PebbleLastUpdated from '@/components/last-updated/pebble-last-updated.vue';
import CancelSaveButtons from '@/components/buttons/cancel-save-buttons.vue';

@Component({
    name: 'identification-materials-tab',
    components: {
        CancelSaveButtons, PebbleLastUpdated, PebbleErrorsList, MaterialsIdentificationForm,
    },
})
export default class IdentificationMaterialsTab extends mixins(ComponentSecurity) {
    /* VARIABLES */
    private service: MaterialBaseService = new MaterialBaseService();
    private assetService: AssetService = new AssetService();
    private materialsGroupService: MaterialGroupService = new MaterialGroupService();
    private errors: PebbleNotification[] = [];
    private materialGroupList: PebbleDropDown[] = [];
    private euEtsList: PebbleDropDown[] = [];
    private aggStateList: PebbleDropDown[] = [];
    private qualityList: PebbleDropDown[] = [];
    private homeContryList: PebbleDropDown[] = [];
    private mineList: PebbleDropDown[] = [];
    private dehstList: PebbleDropDown[] = [];
    private unitSettleList: PebbleDropDown[] = [];
    private check: CheckModel = {
        dataLoaded: false,
        showError: false,
        saveButtonIsDisabled: true,
    };

    /* LATE VARIABLES */
    private formValues!: IdentificationMaterialsForm;
    private selectedMaterial?: MaterialBaseModel;

    @Prop({required: true})
    private sid!: string | undefined;

    @Watch('check.saveButtonIsDisabled')
    private onChanged(value: boolean): void {
        this.$emit('on-form-changed', !value);
    }

    /* PRIMITIVE METHODS */
    private created(): void {
        this.initCreated();
    }

    private mounted(): void {
        this.init();
    }

    /* METHODS */
    private async initCreated(): Promise<void> {
        this.$store.commit('loading');
        const [materialsGroup, euEts, aggState, quality, homeCountry, mine, dehst, units] = await Promise.all([
            this.loadMaterialsGroup(),
            this.loadAssets(IdTypeSid.EU_ETS),
            this.loadAssets(IdTypeSid.AGG_STATE),
            this.loadAssets(IdTypeSid.QUALITY),
            this.loadAssets(IdTypeSid.HOME_COUNTRY),
            this.loadAssets(IdTypeSid.MINE),
            this.loadAssets(IdTypeSid.DEHST),
            this.loadAssets(IdTypeSid.UNITS),
        ]).finally(() => {
            this.$store.commit('loading');
        });
        this.materialGroupList = pebbleDropDownFromMaterialGroups(materialsGroup);
        this.euEtsList = pebbleDropDownFromAssets(euEts);
        this.aggStateList = pebbleDropDownFromAssets(aggState);
        this.qualityList = pebbleDropDownFromAssets(quality);
        this.homeContryList = pebbleDropDownFromAssets(homeCountry);
        this.mineList = pebbleDropDownFromAssets(mine);
        this.dehstList = pebbleDropDownFromAssets(dehst);
        this.unitSettleList = pebbleDropDownFromAssets(units);
    }

    private async init(): Promise<void> {
        this.check.dataLoaded = false;
        this.check.showError = false;
        this.selectedMaterial = await this.loadMaterial();
        this.formValues = identificationMaterialsForm(this.selectedMaterial);
        this.check.dataLoaded = true;
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedMaterial);
    }

    private validate(value: string, name: string): void {
        isValidInput(name, this.formValues[name]);
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedMaterial);
    }

    /* ON CLICK BUTTONS */
    private onClickRefreshButton(): void {
        this.init();
    }

    private async onClickCancelButton(): Promise<void> {
        if (await showDialog(this, 'areYouSureCancelUnsaved')) {
            this.formValues = {...identificationMaterialsForm(this.selectedMaterial)};
            this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedMaterial);
        }
    }

    private onClickSaveButton(): void {
        this.saveData();
    }

    /* API CALLS */
    private async loadAssets(idTypeSid: number): Promise<Asset[]> {
        let assets: Asset[] = [];
        try {
            const {result: {items}} = await this.assetService.getByIdTypeSid(idTypeSid, {page: 1, size: 1000, term: ''});
            assets = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
        return assets;
    }

    private async loadMaterialsGroup(): Promise<MaterialGroupModel[]> {
        let materials: MaterialGroupModel[] = [];
        try {
            const {result: {items}} = await this.materialsGroupService.get({
                page: 1,
                size: 1000,
                term: '',
                sortDirection: '',
                sortColumn: '',
                EnumSuMaterialGroupSid: [],
                EnumMaterialTypeSid: [],
                includeDeleted: false,
            });
            materials = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
        return materials;
    }

    private async loadMaterial(): Promise<MaterialBaseModel> {
        this.$store.commit('loading');
        try {
            const {result} = await this.service.getById(+`${this.sid}`);
            return result;
        } catch (err: any) {
            this.check.showError = true;
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveMaterial(data: MaterialEditRequest): Promise<boolean> {
        this.$store.commit('loading');
        try {
            await this.service.edit(data);
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorEditingUnit');
            generateErrors(this.errors, error);
            return false;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveData(): Promise<void> {
        if (await this.saveMaterial(generateDataFromFormValues(this.formValues) as MaterialEditRequest)) {
            await this.init();
        }
    }
}
</script>


