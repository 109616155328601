<template>
    <card>
        <pui-grid-container>
            <card-header
                :title="$t('addNew')"
                :on-click-back-button="onBackPress"
            />
            <pui-grid-row>
                <fake-single-tab
                    :title="$t('identification')"
                />
            </pui-grid-row>
            <pui-grid-row
                :class="errors.length > 0 ? 'pebble-errors-list-margin-top' : ''"
            >
                <pebble-errors-list
                    id="errors-list"
                    :errors="errors"
                />
            </pui-grid-row>
            <pui-form
                aria-label="Form"
                v-if="check.dataLoaded"
            >
                <enum-types-identification-form
                    :data="formValues"
                    @validate="validate"
                />
            </pui-form>
            <cancel-save-buttons
                :on-click-cancel-button="onBackPress"
                :on-click-save-button="onClickSaveButton"
                :save-button-is-disabled="!hasMasterDataAdmin || check.saveButtonIsDisabled"
            />
        </pui-grid-container>
    </card>
</template>

<script lang="ts">
import {Mixins} from 'vue-property-decorator';
import ComponentSecurity from '@/mixins/component-security';
import Component from 'vue-class-component';
import Card from '@/components/cards/card.vue';
import BackText from '@/components/text/back-text.vue';
import {identificationEnumTypesForm} from '@/utils/pebble-form/master-data-management/identification-enum-types-form';
import {IdentificationEnumTypesForm} from '@/models/form/identification-enum-types-form';
import {Asset} from '@/models';
import {EnumFactory, EventBus} from '@/utils';
import {AssetService} from '@/services';
import {generateDataFromFormValues, generateErrors, isValidInput, manageResolve, shouldDisableForm} from '@/utils/utils';
import FakeSingleTab from '@/components/tab-bar/fake-single-tab.vue';
import CancelSaveButtons from '@/components/buttons/cancel-save-buttons.vue';
import CardHeader from '@/components/cards/card-header.vue';
import EnumTypesIdentificationForm from '@/components/forms/enum-types-identification-form.vue';
import {PebbleNotification} from '@/models/pebble/pebble-notification';
import {CheckModel} from '@/models/check-model';
import PebbleErrorsList from '@/components/error-list/pebble-errors-list.vue';

@Component({
    name: 'management-enum-types-new',
    components: {
        PebbleErrorsList,
        EnumTypesIdentificationForm,
        CardHeader,
        CancelSaveButtons,
        FakeSingleTab,
        BackText,
        Card,
    },
})
export default class ManagementEnumTypesNew extends Mixins(ComponentSecurity) {
    /* VARIABLES */
    private service: AssetService = new AssetService();
    private errors: PebbleNotification[] = [];
    private check: CheckModel = {
        dataLoaded: false,
        saveButtonIsDisabled: true,
    };

    /* LATE VARIABLES */
    private formValues!: IdentificationEnumTypesForm;

    /* PRIMITIVE METHODS */
    private mounted(): void {
        this.$router.beforeResolve((to, from, next) => manageResolve(!this.check.saveButtonIsDisabled, next, this));
        if (!this.$route.query['sid']) {
            this.onBackPress();
        } else {
            this.formValues = identificationEnumTypesForm(EnumFactory.create(+this.$route.query['sid']));
            this.check.dataLoaded = true;
        }
    }

    private destroyed(): void {
        this.check.saveButtonIsDisabled = true;
    }

    /* METHODS */
    private validate(value: string, name: string): void {
        isValidInput(name, this.formValues[name]);
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues);
    }

    /* ON CLICK BUTTONS */
    private onBackPress(): void {
        this.$router.back();
    }

    private onClickSaveButton(): void {
        this.saveData();
    }

    /* API CALLS */
    private async saveAsset(asset: Asset): Promise<boolean> {
        this.$store.commit('loading');
        try {
            await this.service.add(asset);
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingAsset');
            generateErrors(this.errors, error);
            return false;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveData(): Promise<void> {
        if (await this.saveAsset(generateDataFromFormValues(this.formValues) as Asset)) {
            this.check.saveButtonIsDisabled = true;
            this.onBackPress();
        }
    }
}
</script>


