<template>
    <div class="header-row">
        <div class="header-row__item">
            <span
                class="header-row__expand"
            >
                <span class="header-row__expand-inner" />
            </span>
            <adam-icon v-if="showIcon" icon="ic-group" class="header-row__item-icon" />
            <span
                v-for="column in headerColumns"
                :key="column.attributeName"
                :class="['header-row__item-' + column.itemCssClassName, 'header-column']"
                @click="sortHierarchyBy(column.attributeName)"
            >
                <div :title="column.name" class="header-row__inner-element">
                    <div class="header-row__text">{{ column.name }}</div>
                    <div class="header-row__sort-icon">
                    <!-- <div class="header-row__sort-icon__inactive"> -->
                        <font-awesome-icon
                            :class="
                                sortKey===column.attributeName
                                ? 'header-row__sort-icon__active'
                                : 'header-row__sort-icon__inactive'
                            "
                            :icon="getIcon(column.attributeName)" />
                    </div>
                </div>
            </span>
            <slot name="action-space"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';
import ComponentSecurity from '@/mixins/component-security';

@Component({
    name: 'sort-header',
})
export default class SortHeader extends Mixins(ComponentSecurity) {
    /**
     * Set the column attribute name and name to display
     * object structure should be { attributeName: string, name: string }
     */
    @Prop({ required: true })
    private headerColumns!: {
        attributeName: string;
        name: string;
        itemCssClassName: string;
    };

    /**
     * Set the HierarchyPowerPlantObject to sort
     */
    @Prop({ required: true })
    private objectToSort!: any[];

    @Prop({ required: false, default: true })
    private showIcon!: boolean;

    private sortOrder = 'asc';
    private sortKey = '';

    private sortHierarchyBy(attr: string): void {
        try {
            const sortedObject: any[] = [...this.objectToSort];
            let trueVal = 1;
            let falseVal = -1;
            this.sortOrder = this.sortKey === attr
                ? this.sortOrder === 'asc' ? 'desc' : 'asc'
                : 'asc';
            if (this.sortOrder === 'desc') {
                trueVal = -1;
                falseVal = 1;
            } else {
                trueVal = 1;
                falseVal = -1;
            }
            this.sortKey = attr;
            sortedObject.sort(
                (a, b) => (a && b)
                    ? (!(this.sortKey in a)) || (!(this.sortKey in b))
                        ? (!(this.sortKey in a)) ? falseVal : trueVal
                        : a[this.sortKey] !== b[this.sortKey]
                            // eslint-disable-next-line
                            // @ts-ignore: Object is possibly 'null'.
                            ? (
                                isNaN(a[this.sortKey]) ? a[this.sortKey]?.toString().toLowerCase() : a[this.sortKey]
                            ) > (
                                isNaN(b[this.sortKey]) ? b[this.sortKey]?.toString().toLowerCase() : b[this.sortKey]
                            )
                                ? trueVal : falseVal
                            : falseVal
                    : a ? trueVal : falseVal,
            );
            this.$emit('get-sorted-list', { sortedList: sortedObject, order: this.sortOrder, sortKey: this.sortKey });
        } catch (error: any) {
            console.log(error);
        }
    }

    private getIcon(key: string) {
        return this.sortKey === key ? this.sortOrder === 'asc' ? 'caret-up' : 'caret-down' : 'sort';
    }
}
</script>

<style lang="less">
@import "~@/variables.less";
.header-row {
    display: block;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    background-color: white;
    box-shadow: 0px 0 4px 0 rgba(0, 0, 0, 0.15);
    margin-top: 1rem;
    margin-bottom: 3rem;
    position: relative;
    text-transform: capitalize;
    font-family: @font-roboto-md;

    &__item {
        padding-left: 2.5rem;
        padding-right: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }

    &__expand {
        position: absolute;
        left: 0.5rem;
        display: block;
        cursor: pointer;
        margin-right: 1rem;
        padding: 0 1rem;
    }

    &__expand-inner {
        display: block;
        transition: transform 200ms ease-out;

        &.expanded {
            transform: rotateZ(90deg);
        }
    }

    &__item-icon {
        margin-right: 1rem;
        width: 2.2rem;
        height: 2.2rem;
    }

    &__item-update-comment,
    &__item-deleted,
    &__item-active {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        box-sizing: border-box;
        border-left: 2px solid @lighter-grey;
        padding: 0 1rem;
    }

    &__item-active,
    &__item-deleted,
    &__item-update-comment {
        width: 6rem;
    }


    &__item-active,
    &__item-deleted {
        font-family: @font-roboto-md;
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
    }

    &__button {
        --button-fab-size: 3rem;
    }

    &--warn {
        border: 1px solid @contextual-amber;
        background-color: lighten(@contextual-amber, 48%);
    }
    &__inner-element {
        flex-direction: row;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
    }
    &__text {
        flex-direction: column;
        display: inline-block;
        justify-content: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
    }
    &__sort-icon {
        flex-direction: column;
        display: flex;
        box-sizing: border-box;
        justify-content: center;
        padding-left: 0.5rem;
        &__inactive {
            color: @warm-grey;
        }
        &__active {
            color: @dark-grey;
        }
    }
}

.sorting-inactive {
    color: grey;
}
</style>
