export enum NodeType {
    COUNTRY = 'country',
    FLEET = 'fleet',
    ASSET_GROUP = 'asset-group',
    POWER_PLANT = 'power-plant',
    MACHINE = 'machine',
}

export enum NodeTypeSid {
    COUNTRY = 610,
    POWER_PLANT = 630,
    MACHINE = 640,
    COMPONENT = 650,
}
