<template>
    <master-data-management-tree
        @on-click-item="onClickItem"
    />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import PowerPlantsTree from '@/components/tree/power-plants-tree.vue';
import MasterDataManagementTree from '@/components/tree/master-data-management-tree.vue';
import {ManagementOption} from '@/models';

@Component({
    name: 'side-bar-master-data-management',
    components: {MasterDataManagementTree, PowerPlantsTree},
})
export default class SideBarMasterDataManagement extends Vue {

    private onClickItem(selected: ManagementOption): void {
        if (this.$route.path === `/master-data-management-v2/${selected.componentName}`) {
            return;
        }
        this.$router.push({
            name: `${selected.componentName}`,
            path: `/master-data-management-v2/${selected.componentName}`,
        });
    }

}
</script>


