var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isInitialDataLoaded)?_c('pui-grid-container',{staticClass:"pui-grid-container-fix-margin"},[_c('pui-grid-row',[_c('hydro-components-header',{attrs:{"selected-plant-groups":_vm.selectedFilters.plantGroup,"selected-plants":_vm.selectedFilters.plant,"show-deleted-items":_vm.showDeletedItems},on:{"change:filters":_vm.getFilterValues,"change:deleted":_vm.changeShowDeletedItems}})],1),_c('pui-grid-row',[_c('custom-table',{attrs:{"columns-key":_vm.columns,"heading":_vm.heading,"data":_vm.dataTable,"per-page":10,"per-page-values":[10, 50, 100],"enable-pagination":true,"enable-search":true,"enable-limit":true,"custom-template":['isDeleted', 'isReserve', 'action']},scopedSlots:_vm._u([{key:"custom_isDeleted",fn:function(ref){
var data = ref.data;
return [_c('boolean-text',{attrs:{"titles":{
                        textTrue: _vm.$t('yes'),
                        textFalse: _vm.$t('no'),
                    },"value":data}})]}},{key:"custom_isReserve",fn:function(ref){
                    var data = ref.data;
return [_c('boolean-text',{attrs:{"titles":{
                        textTrue: _vm.$t('yes'),
                        textFalse: _vm.$t('no'),
                    },"value":data}})]}},{key:"custom_action",fn:function(ref){
                    var data = ref.data;
return [(_vm.canEditHydroComponents)?_c('pui-link',{attrs:{"title":_vm.$t('edit'),"icon":"edit"},on:{"click":function($event){return _vm.goToEdit(data)}}}):_c('pui-link',{attrs:{"title":_vm.$t('hydroComponents.actions.showDetails'),"icon":"visible"},on:{"click":function($event){return _vm.goToEdit(data)}}})]}}],null,false,2493319542)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }