import { ApiResponse, Asset, ManagementOption, PagedResponse, SearchPaginationQueryModel } from '@/models';
import { EventBus } from '@/utils';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
import { generateQuery } from '@/utils/api-helper';

class AssetService {
    private api = new SdkGenericApiHelper('assets');

    public async getEnumList(): Promise<ManagementOption[]> {
        try {
            const { data: { result: { items } } } = await this.api.getByPathOrParam(`enumerations`);
            return items;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingData');
            return [];
        }
    }

    public async getByIdTypeSid(idTypeSid: number, filter: SearchPaginationQueryModel):
        Promise<ApiResponse<PagedResponse<Asset>>> {
        const query = generateQuery(filter as any);
        return (await this.api.get(query, `enumerations/${idTypeSid}`))?.data;
    }

    public async getById(sid: number): Promise<ApiResponse<Asset>> {
        return (await this.api.getByPathOrParam(sid)).data;
    }

    public async add(asset: Asset): Promise<ApiResponse<Asset>> {
        return (await this.api.post(asset)).data;
    }

    public async edit(asset: Asset): Promise<ApiResponse<any>> {
        return (await this.api.put(asset.sid, asset)).data;
    }

    public async delete(assetSid: number): Promise<ApiResponse<any>> {
        return (await this.api.delete(assetSid)).data;
    }

    public async getAssetsByIdType(idTypeSid: number): Promise<any> {
        try {
            return (await this.getByIdTypeSid(idTypeSid, {
                page: 1,
                size: 999,
                term: ''
            })).result?.items;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingData');
            return [];
        }
    }
}

const assetService = new AssetService();

export {
    AssetService,
    assetService,
};

