import Vue from 'vue';
import Vuex from 'vuex';
import { Application, TreeViewEntity } from '@/models';
import { AppService, hierarchyService } from '@/services';
import { EventBus } from '@/utils';
import hierarchyModule from './hierarchy-module';
import HierarchyAssetsModule from './hierarchy-assets-module';
import HierarchyTableModule from './hierarchy-table-module';
import UserModule from './user-module';
import AssetOverviewModule from './asset-overview-module';

Vue.use(Vuex);

const applicationState: {
    isLoading: boolean;
    hierarchicData: TreeViewEntity[];
    selectedLanguage: string;
    selectedCountries: string[];
    selectedAssetType: string[];
    useCases: Application[];
} = {
    isLoading: false,
    hierarchicData: [],
    selectedLanguage: 'en',
    selectedCountries: [],
    selectedAssetType: [],
    useCases: [],
};

export type ApplicationState = typeof applicationState;

export const store = new Vuex.Store({
    state: applicationState,
    mutations: {
        loading(state) {
            state.isLoading = !state.isLoading;
        },
        hierarchicData(state, hierarchicData: TreeViewEntity[]) {
            state.hierarchicData = hierarchicData;
        },
        selectedLanguage(state, selectedLanguage) {
            state.selectedLanguage = selectedLanguage;
        },
        setSelectedCountries(state, data): void {
            state.selectedCountries = data;
        },
        setSelectedAssetType(state, data): void {
            state.selectedAssetType = data;
        },
        setUseCases(state: ApplicationState, apps: Application[]): void {
            state.useCases = apps;
        },
    },
    actions: {
        async loadTreeData({ commit }) {
            commit('loading');
            try {
                const { result: { items } } = await hierarchyService.getHierarchycalAssets();

                if (!items.length) {
                    return;
                }
                commit('hierarchicData', items);
            } catch (error: any) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingData');
            } finally {
                commit('loading');
            }
        },
        async loadUseCases({ commit }) {
            try {
                commit('loading');
                const apps = await new AppService().getApps();
                commit('setUseCases', apps);
            } catch (err: any) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingData');
            } finally {
                commit('loading');
            }
        },
    },
    getters: {
        isLoading(state) {
            return state.isLoading;
        },
        hierarchicData(state) {
            return state.hierarchicData;
        },
        selectedLanguage(state) {
            return state.selectedLanguage;
        },
        getSelectedCountries(state): string[] {
            return state.selectedCountries;
        },
        getSelectedAssetType(state): string[] {
            return state.selectedAssetType;
        },
        getUseCases(state: ApplicationState): Application[] {
            return state.useCases;
        },
    },

    modules: {
        hierarchyModule,
        HierarchyAssetsModule,
        HierarchyTableModule,
        UserModule,
        AssetOverviewModule,
    },
});
