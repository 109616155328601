import 'adam.ui-core/utils/scaling.css';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import './registerServiceWorker';
import { router } from './router';
import { store } from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCopy, faCaretRight, faCaretUp, faCaretDown, faSort, faGripLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VeeValidate from './plugins/vee-validate';
import VuejsDialog from './plugins/vuejs-dialog';
import i18n from '@/utils/i18n';
import MdDatePicker from '../src/components/md-date-picker/md-date-picker.vue';
import 'adam.ui-core/dist/umd';
import './vendors.less';
import './vendors.less';
import PebbleUI from '@enerlytics/pebble-ui/dist/pebble-ui';
import { sdk } from '@/utils/sdk';

Vue.config.productionTip = false;

Vue.component('md-date-picker', MdDatePicker);
Vue.component('font-awesome-icon', FontAwesomeIcon);

library.add(faCopy, faCaretRight, faCaretUp, faCaretDown, faSort, faGripLines);

Vue.use(VeeValidate);
Vue.use(VuejsDialog);
Vue.use(PebbleUI as any);
Vue.use(VueCookies);

sdk?.auth.init().then(() => {
    new Vue({
        i18n,
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app');
})
