export enum FilterRequestFieldName {
    ENUM_SU_MATERIAL_GROUP = 'enumSuMaterialGroup',
    ENUM_MATERIAL_TYPE = 'enumMaterialType',
    AGG_STATE = 'aggState',
    DEHST = 'dehst',
    COUNTRIES = 'countries',
    EU_ETS = 'euEts',
    FLEETS = 'fleets',
    CLASS_TYPES = 'classTypes',
    MACHINE_TYPES = 'machineTypes',
    PLANT_GROUPS = 'plantGroups',
    PRIMARY_ENERGY = 'primaryEnergy',
    UNIT_TYPE = 'unitType',
    RIVER = 'river',
    REGION = 'regionalUnit',
    SHOW_DELETED_ITEMS = 'includeDeleted',
    STATUS = 'status',
    TECHNOLOGY = 'technology',
    LEGAL_ENTITY = 'legalEntity',
}
