import { BusinessPartnersDetails } from '@/models';
import format from 'date-fns/format';
import { DATE_FORMAT } from '../constants';

export class BusinessPartnerFactory {
    public static createBusinessPartnerAddRequest(): BusinessPartnersDetails {
        return {
            abbreviation: '',
            name: '',
            locationSid: 0,
            enumLocationTypeSid: 4010,
            locationShort: '',
            locationName: '',
            zipCode: '',
            city: '',
            street: '',
            houseNumber: '',
            postbox: '',
            telephone: '',
            fax: '',
            mailAddress: '',
            enumCountrySid: null,
            enumStateSid: null,
            enumRegionSid: null,
            enumOrganisationSid: null,
            enumTimezoneSid: null,
            latitude: 0,
            longitude: 0,
            deleted: false,
            lastUpdateComment: '',
            lastUpdatedBy: '',
            lastUpdated: format(new Date(), DATE_FORMAT),
        };
    }
}
