<template>
    <div class="main-container">
        <div class="flex-row flex-center justify-between">
            <asset-overview-multi-filter
                class="multi-filter"
                :selected-countries="assetOverviewRequestParams.countries"
                :selected-machine-types="assetOverviewRequestParams.machineTypes"
                :selected-primary-energies="assetOverviewRequestParams.primaryEnergies"
                :selected-rivers="assetOverviewRequestParams.rivers"
                :selected-regional-units="assetOverviewRequestParams.regionalUnits"
                :selected-statuses="assetOverviewRequestParams.statuses"
                :selected-legal-entities="assetOverviewRequestParams.legalEntities"
                :selected-unit-technologies="assetOverviewRequestParams.unitTechnologies"
                @onFilterChange="handleFilterChanged"
            />
            <pui-button
                v-if="canExportExcel"
                icon="download"
                class="mr-1"
                @click="exportTable"
            >
                {{ $t('export') }}
            </pui-button>
        </div>
        <div class="table-wrapper">
            <asset-overview-table
                :assets="assets"
                :is-loading="isLoading"
            />
        </div>
    </div>
</template>
<script lang="ts">
import {Component, Mixins} from 'vue-property-decorator';
import { assetOverviewService } from '@/services';
import { AssetOverviewRequestParams, AssetOverviewListItem } from '@/models';
import ComponentSecurity from '../../mixins/component-security';
import { namespace } from 'vuex-class';

import AssetOverviewMultiFilter from './asset-overview-multi-filter.vue';
import { configKeys } from '@/utils/local-storage/config-keys';
import PersistentFilter from '@/utils/local-storage/persistent-filter';
import AssetOverviewTable from './asset-overview-table.vue';
import { EventBus } from '@/utils';

const userModule = namespace('UserModule');
const persistentFilter = new PersistentFilter(configKeys.ASSET_OVERVIEW);

@Component({
    components: {
        AssetOverviewTable,
        AssetOverviewMultiFilter,
    },
})
export default class AssetOverview extends Mixins(ComponentSecurity) {
    @userModule.Getter
    public canExportExcel!: boolean;

    private assetOverviewRequestParams = new AssetOverviewRequestParams({
        countries: persistentFilter.getConfigArray(configKeys.COUNTRY_IDS),
        machineTypes: persistentFilter.getConfigArray(configKeys.MACHINE_TYPE_IDS),
        primaryEnergies: persistentFilter.getConfigArray(configKeys.PRIMARY_ENERGY_IDS),
        rivers: persistentFilter.getConfigArray(configKeys.RIVER_IDS),
        regionalUnits: persistentFilter.getConfigArray(configKeys.REGIONAL_UNIT_IDS),
        statuses: persistentFilter.getConfigArray(configKeys.STATUS_IDS),
        legalEntities: persistentFilter.getConfigArray(configKeys.LEGAL_ENTITY_IDS),
        unitTechnologies: persistentFilter.getConfigArray(configKeys.UNIT_TECHNOLOGY_IDS),
    });

    private assets: AssetOverviewListItem[] = [];
    private isLoading = false;

    private async mounted(): Promise<void> {
        await this.loadData();
    }

    private async loadData(): Promise<void> {
        this.isLoading = true;
        this.assets = await assetOverviewService.getAssetOverviewData(this.assetOverviewRequestParams);
        this.isLoading = false;
    }

    private handleFilterChanged(event: any[]): void {
        const [countries, machineTypes, primaryEnergies, rivers, regionalUnits, statuses, legalEntities, unitTechnologies] = event;
        this.assetOverviewRequestParams.countries = countries;
        this.assetOverviewRequestParams.machineTypes = machineTypes;
        this.assetOverviewRequestParams.primaryEnergies = primaryEnergies;
        this.assetOverviewRequestParams.rivers = rivers;
        this.assetOverviewRequestParams.regionalUnits = regionalUnits;
        this.assetOverviewRequestParams.statuses = statuses;
        this.assetOverviewRequestParams.legalEntities = legalEntities;
        this.assetOverviewRequestParams.unitTechnologies = unitTechnologies;
        persistentFilter.setConfig(configKeys.COUNTRY_IDS, countries);
        persistentFilter.setConfig(configKeys.MACHINE_TYPE_IDS, machineTypes);
        persistentFilter.setConfig(configKeys.PRIMARY_ENERGY_IDS, primaryEnergies);
        persistentFilter.setConfig(configKeys.RIVER_IDS, rivers);
        persistentFilter.setConfig(configKeys.REGIONAL_UNIT_IDS, regionalUnits);
        persistentFilter.setConfig(configKeys.STATUS_IDS, statuses);
        persistentFilter.setConfig(configKeys.LEGAL_ENTITY_IDS, legalEntities);
        persistentFilter.setConfig(configKeys.UNIT_TECHNOLOGY_IDS, unitTechnologies);

        this.loadData();
    }


    private async exportTable(): Promise<void> {
        try {
            const exceldata = (await assetOverviewService.exportAssetsToExcel(this.assetOverviewRequestParams));
            const byteArray = this.base64ToArrayBuffer(exceldata.excelFile.fileContents);
            const a = window.document.createElement('a');

            a.href = window.URL.createObjectURL(new Blob([byteArray], {type: exceldata.excelFile.contentType}));
            a.download = exceldata.excelFile.fileDownloadName;

            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);

        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorExporting');
        }
    }


    private base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

}

</script>

<style scoped lang="less">
@import "~@/variables.less";

.main-container {
    padding: 0.5rem 3rem 5rem;

    .multi-filter {
        margin-bottom: 0.7rem;
        width: 150rem;
    }

    .table-wrapper {
        box-shadow: 0 0 0.2rem @warm-grey;
        border-radius: 0.3rem;
    }

}

</style>
