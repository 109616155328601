import {
    ApiResponse, ObjectDistribution,
    ObjectDistributionAddRequest, ObjectDistributionEditRequest, ObjectDistributionModel,
    ObjectDistributionQuery, PagedResponse, ObjectDistributionListItem, ObjectDistributionVersion,
} from '@/models';
import { store } from '@/store';
import { EventBus } from '@/utils';
import ErrorHandler from '@/utils/error-handler';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';

class ObjectDistributionService {
    private api = new SdkGenericApiHelper('units');

    public async get(machineId: any): Promise<ApiResponse<PagedResponse<ObjectDistributionModel>>> {
        return (await this.api.getByPathOrParam(`${machineId}/distribution-channels`)).data;
    }

    public async create(objectDistribution: ObjectDistributionAddRequest): Promise<ApiResponse<ObjectDistributionModel>> {
        return (await this.api.post(objectDistribution, `${objectDistribution.machineId}/distribution-channels`))
            .data;
    }

    public async getById(query: ObjectDistributionQuery): Promise<ApiResponse<ObjectDistribution>> {
        return (await this.api.getByPathOrParam(`${query.machineId}/distribution-channels/versions/${query.validFrom}`))
            .data;
    }

    public async edit(objectDistribution: ObjectDistributionEditRequest, validFrom: string): Promise<ApiResponse<ObjectDistribution>> {
        return (await this.api.put(`${objectDistribution.machineId}/distribution-channels/versions/${validFrom}`, objectDistribution))
            .data;
    }

    public async delete(query: ObjectDistributionQuery): Promise<ApiResponse<any>> {
        return (await this.api.delete(`${query.machineId}/distribution-channels/versions/${query.validFrom}`))
            .data;
    }

    public async getObjectDistributions(machineId: number): Promise<ObjectDistributionListItem[]> {
        try {
            store.commit('loading');
            return (await this.get(machineId))
                ?.result
                .items
                ?.map((item) => new ObjectDistributionListItem(item)) ?? [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingObjectDistributions');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async getObjectDistribution(machineId?: number, validFrom?: string): Promise<ObjectDistributionVersion> {
        try {
            store.commit('loading');
            const result = (await this.api.getByPathOrParam(`${machineId}/distribution-channels/versions/${validFrom}`))
                ?.data
                ?.result;
            return new ObjectDistributionVersion(result);
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingObjectDistributions');
            return new ObjectDistributionVersion();
        } finally {
            store.commit('loading');
        }
    }

    public async addObjectDistribution(objectDistribution: ObjectDistributionVersion): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.api.post(ObjectDistributionVersion.mapToAddEditRequest(objectDistribution),
                `${objectDistribution.machineId}/distribution-channels`);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorCreatingObjectDistribution');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async updateObjectDistribution(objectDistribution: ObjectDistributionVersion, initialValidFrom: string): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.api.put(`/${objectDistribution.machineId}}/distribution-channels/versions/${initialValidFrom}`,
                ObjectDistributionVersion.mapToAddEditRequest(objectDistribution));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorUpdatingObjectDistribution');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async deleteObjectDistribution(objectDistribution: ObjectDistributionListItem): Promise<boolean> {
        try {
            store.commit('loading');
            await this.api.delete(`/${objectDistribution.machineId}/distribution-channels/versions/${objectDistribution.validFrom}`);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            const errorMessage = ErrorHandler.getAxiosErrorMessage(error) ?? 'errorRemovingObjectDistribution';
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, errorMessage);
            return false;
        } finally {
            store.commit('loading');
        }
    }
}

const objectDistributionService = new ObjectDistributionService();

export {
    ObjectDistributionService,
    objectDistributionService,
};
