import { MasterDataTreeItem, NodeTypeSid, PowerPlant, IdTypeSid, NodeType } from '@/models';
import format from 'date-fns/format';
import { DATE_FORMAT } from '../constants';

export class PowerPlantFactory {
    public static create(defaults = { enumCountrySid: NaN }): PowerPlant {
        return {
            locationSid: 0,
            ewisId: 0,
            enumLocationTypeSid: NaN,
            locationShort: '',
            locationName: '',
            zipCode: '',
            businessIdentifier: '',
            businessName: '',
            city: '',
            street: '',
            houseNumber: '',
            postbox: '',
            telephone: '',
            fax: '',
            mailAddress: '',
            enumCountrySid: defaults.enumCountrySid,
            latitude: 0,
            longitude: 0,
            eicName: '',
            eicIdentification: '',
            eicCode: '',
            deleted: false,
            lastUpdatedBy: '',
            lastUpdated: format(new Date(), DATE_FORMAT),
            lastUpdateComment: '',
            sappmSid: null,
        };
    }

    public static createMasterDataTreeItem(source?: PowerPlant): MasterDataTreeItem {
        return {
            name: source ? source.locationName : 'New Power Plant',
            isOpen: false,
            isFocused: false,
            guid: null,
            icon: null,
            displayOrder: 0,
            sid: source ? source.locationSid : 0,
            nodeLevel: 0,
            nodeLevelConv: '',
            nodeType: NodeType.POWER_PLANT,
            idTypeSid: IdTypeSid.POWER_PLANT_TYPE,
            deleted: source ? source.deleted : false,
            children: [],
            childrenIdTypeSid: 0,
            filters: [],
            enumNodeTypeSid: NodeTypeSid.POWER_PLANT,
            lastUpdated: source && source.lastUpdated ? source.lastUpdated : '',
            abbreviation: '',
        };
    }
}
