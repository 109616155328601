import {KeyMappingModel} from '@/models/key-mapping-model';
import {KeyMappingForm} from '@/models/form/key-mapping-form';
import {format} from 'date-fns';
import {MIN_DATE, ONLY_DATE_FORMAT} from '@/utils/constants';

export const keyMappingForm = (data?: KeyMappingModel): KeyMappingForm => {
    return {
        objectSid: {
            value: data?.objectSid,
            isValid: true,
        },
        keyMapping: {
            value: data?.keyMapping,
            isValid: true,
            validator: (val: string) => val?.length > 0,
        },
        validFrom: {
            value: data?.validFrom ? format(new Date(data?.validFrom), ONLY_DATE_FORMAT) : '',
            isValid: true,
            isDate: true,
            validator: (val: string) => new Date(val) > MIN_DATE,
        },
        validTo: {
            value: data?.validTo ? format(new Date(data?.validTo), ONLY_DATE_FORMAT) : '',
            isValid: true,
            isDate: true,
            validator: (val: string) => new Date(val) > MIN_DATE,
        },
        lastUpdateComment: {
            value: data?.lastUpdateComment,
            isValid: true,
        },
        isActive: {
            value: data?.isActive ?? true,
            isValid: true,
        },
        isDeleted: {
            value: data?.isDeleted ?? false,
            isValid: true,
        },
        isExclusive: {
            value: data?.isExclusive ?? false,
            isValid: true,
        },
        enumStreamSystemSid: {
            value: data?.enumStreamSystemSid,
            isValid: true,
        },
        lastUpdatedBy: {
            value: data?.lastUpdatedBy,
            isValid: true,
        },
        lastUpdated: {
            value: data?.lastUpdated ? format(new Date(data?.lastUpdated), ONLY_DATE_FORMAT) : '',
            isValid: true,
            isDate: true,
        },
    };
};
