<template>
    <div class="app" v-if="isAppReady">
        <loader/>
        <PuiApplicationLayout>
            <slot slot="header">
                <navbar/>
                <tab-bar />
            </slot>
            <slot slot="main" class="layout">
                <div class="layout">
                    <router-view/>
                </div>
            </slot>
            <template slot="left" v-if="enableSideBar">
                <aside class="side-bar">
                    <side-bar/>
                </aside>
            </template>
        </PuiApplicationLayout>
    </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import './main.less';
import TabBar from '@/components/tab-bar/tab-bar.vue';
import PowerPlantsTree from '@/components/tree/power-plants-tree.vue';
import SideBar from '@/components/sidebar/side-bar.vue';
import Navbar from '@/components/navbar/navbar.vue';
import Loader from '@/components/loader/loader.vue';
import { namespace } from 'vuex-class';
import { sdk } from './utils/sdk';

const userModule = namespace('UserModule');

@Component({
    components: {
        Loader,
        Navbar,
        SideBar,
        PowerPlantsTree,
        TabBar,
    },
})

export default class App extends Vue {
    @userModule.Action
    public loadUserProfile!: () => void;

    private isAppReady = false;
    private enableSideBar = false;

    private async created(): Promise<void> {
        const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);
        await sdk?.core.userAccessManagement.setPermissions(useCaseId);

        this.loadUserProfile();

        await this.loadUseCases();
        this.isAppReady = true;
    }

    @Watch('$route.name')
    private onChangeRouteName(value: string): void {
        this.setEnableDrawer(value);
    }

    private setEnableDrawer(routeName?: string | null): void {
        switch (routeName) {
            case 'master-data-management-v2':
            case 'management-enum-types':
            case 'management-operation-modes':
            case 'management-business-partners':
            case 'management-ets-unit':
            case 'management-materials':
            case 'management-material-group':
            case 'management-reservoirs':
                this.enableSideBar = true;
                break;
            default:
                this.enableSideBar = false;
        }
    }

    private async loadUseCases() {
        await this.$store.dispatch('loadUseCases');
    }
}
</script>

<style lang="less">
@import '~@/variables.less';

.layout {
    overflow: auto !important;
    height: 100% !important;
    background-color: @background-grey;
}

.pui-application-layout__main {
    overflow: auto;
}

.side-bar {
    height: 100%;
}
</style>
