import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper'
import {
    AddTransformersData,
    TransformersData,
    TransformersQuery,
} from '@/models/hydro-components/transformers-data'
import { ApiResponse, PagedResponse } from '@/models'
import { generateQuery } from '@/utils/api-helper'
import { TransformerGetVersionData, TransformerVersionData } from '@/models/hydro-components/transformers-edit-data'

export class TransformersService {
    private api = new SdkGenericApiHelper('transformers');

    public async getTable(filter: TransformersQuery): Promise<ApiResponse<PagedResponse<TransformersData>>> {
        const query = generateQuery(filter as any);
        return (await this.api.get(query)).data;
    }

    public async create(payload: AddTransformersData): Promise<ApiResponse<TransformerGetVersionData>> {
        return (await this.api.post(payload)).data;
    }

    public async createVersion(transformerSid: string, payload: AddTransformersData): Promise<ApiResponse<TransformerGetVersionData>> {
        return (await this.api.post(payload, `/${transformerSid}/versions`)).data;
    }

    public async editVersion(transformerSid: string, validFrom: string, payload: AddTransformersData): Promise<ApiResponse<TransformerGetVersionData>> {
        return (await this.api.put(`/${transformerSid}/versions/${validFrom}`, payload)).data;
    }

    public async getByValidFrom(transformerSid: string, validFrom: string): Promise<ApiResponse<TransformerGetVersionData>> {
        const query = generateQuery({ from: validFrom });
        return (await this.api.get(query, `/${transformerSid}`))?.data;
    }

    public async getVersions(transformerSid: string): Promise<ApiResponse<PagedResponse<TransformerVersionData>>> {
        return (await this.api.get(undefined, `/${transformerSid}/versions`))?.data;
    }
}
