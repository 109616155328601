import { EtsUnit } from '@/models';


export class EtsUnitFactory {
    public static createEtsUnitAddRequest(): EtsUnit {
        return {
            etsId: 0,
            shortName: '',
            name: '',
            referenceNumber: '',
            registerName: '',
            isDeleted: false,
            lastUpdateComment: '',
            lastUpdatedBy: '',
            lastUpdated: '',
        };
    }
}
