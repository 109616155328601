export const mutations = {
    SET_TOTAL_COUNT: 'hierarchy/SET_TOTAL_COUNT' as string,
    SET_DISPLAYED_COUNT: 'hierarchy/SET_DISPLAYED_COUNT' as string,
    SET_COUNTRIES: 'hierarchy/SET_COUNTRIES' as string,
    SET_FLEETS: 'hierarchy/SET_FLEETS' as string,
    SET_PLANT_GROUPS: 'hierarchy/SET_PLANT_GROUPS' as string,
    SET_CLASS_TYPES: 'hierarchy/SET_CLASS_TYPES' as string,
    SET_MACHINE_TYPES: 'hierarchy/SET_MACHINE_TYPES' as string,
    SET_FILTER_OPTIONS_LOADED: 'hierarchy/SET_FILTER_OPTIONS_LOADED' as string,
};

export const actions = {
    LOAD_FILTER_OPTIONS: 'hierarchy/LOAD_FILTER_OPTIONS' as string,
};

export const getters = {
    GET_TOTAL_COUNT: 'hierarchy/GET_TOTAL_COUNT' as string,
    GET_DISPLAYED_COUNT: 'hierarchy/GET_DISPLAYED_COUNT' as string,
    GET_COUNTRIES: 'hierarchy/GET_COUNTRIES' as string,
    GET_FLEETS: 'hierarchy/GET_FLEETS' as string,
    GET_PLANT_GROUPS: 'hierarchy/GET_PLANT_GROUPS' as string,
    GET_CLASS_TYPES: 'hierarchy/GET_CLASS_TYPES' as string,
    GET_MACHINE_TYPES: 'hierarchy/GET_MACHINE_TYPES' as string,
};
