<template>
    <validation-provider
        :rules="rules"
        :mode="debounceMode"
    >
        <div slot-scope="{ errors }">
            <pui-form-group
                :label="name"
                :is-valid="!errors.length"
                :show-required-label="isRequired"
            >
                <pui-form-input-field
                    :is-valid="!markAsError(errors.length)"
                    v-model="value"
                    :placeholder-text="disabled ? '' : placeholder"
                    :is-readonly="disabled"
                    :type="type"
                    @blur="onBlur()"
                />
                <template #error-message>
                    {{ errors[0] }}
                </template>
                <template #helper-text>
                    {{ helperText }}
                </template>
            </pui-form-group>
        </div>
    </validation-provider>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import ValidationSetup from './validation-setup';

@Component({
    name: 'text-input',
    components: {
        ValidationProvider,
    },
})

export default class TextInput extends ValidationSetup {
    @Prop({ required: false })
    private readonly placeholder!: string;

    @Prop({ required: false, default: '' })
    private inputValue!: any;

    @Prop({ required: false, default: '' })
    private name!: string;

    @Prop({ required: false, default: false })
    private disabled!: boolean;

    @Prop({ required: false, default: 'text' })
    private type!: string;

    @Prop({ required: false })
    private helperText!: string;

    get value(): string {
        return this.inputValue === null || this.inputValue === undefined
            ? ''
            : `${this.inputValue}`;
    }

    set value(changedValue: string ) {
        switch (this.type) {
        case 'text':
            this.$emit('change', changedValue);
            break;
        case 'number':
            if (this.valueContainsMultipleZeros(changedValue)) { changedValue = ''; }
            changedValue === ''
                ? this.$emit('change', undefined)
                : this.$emit('change', Number(changedValue));
            break;
        default:
            break;
        }
    }

    private valueContainsMultipleZeros(value: string): boolean {
        const arrayOfChar = value.split('');
        return arrayOfChar.length > 1
            && arrayOfChar.every((char: string) => char === '0');
    }

    private onBlur(): void {
        this.$emit('blur');
    }
}
</script>
