import { Header } from '@/models';
import { DateHelper } from '@/utils';

const operationModeVersionsTableHeader: Header = {
    columns: [
        {
            text: 'validFrom',
            displayKey: 'validFrom',
            class: 'flex-1 mr-1',
            formatFunction: (date: string) => DateHelper.formatDate(date),
        },
        {
            text: 'validUntil',
            displayKey: 'validTo',
            class: 'flex-1 mr-1',
            formatFunction: (date: string) => DateHelper.formatDate(date),
        },
        {
            text: 'lastUpdated',
            displayKey: 'lastUpdated',
            class: 'flex-1 mr-1',
            formatFunction: (date: string) => DateHelper.formatDate(date),
        },
        {
            text: 'lastUpdatedBy',
            displayKey: 'lastUpdatedBy',
            class: 'flex-1 mr-1',
            formatFunction: (user: string) => user,
        },
        {
            text: 'active',
            displayKey: 'isActive',
            class: 'flex-1 mr-1',
            formatFunction: (active: boolean) => active ? 'Y' : 'N',
        },
        {
            text: 'standardOperationMode',
            displayKey: 'isStdOperationMode',
            class: 'flex-1 mr-1',
            formatFunction: (isStdOperationMode: boolean) => isStdOperationMode ? 'Y' : 'N',
        },
        {
            text: 'deleted',
            displayKey: 'isDeleted',
            class: 'flex-1 mr-1',
            formatFunction: (isDeleted: boolean) => isDeleted ? 'Y' : 'N',
        },
    ],
};

export default operationModeVersionsTableHeader;
