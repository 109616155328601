<template>
    <div v-if="data">
        <view-details
            :sid="data.locationSid"
            :name="data.locationName || $t('newBusinessPartner')"
            icon="ic-settings">
            <tab-headers
                :tabs="tabs"
                :active-tab="activeTab"
                @select-tab="selectTab" />
            <component
                :is="activeTab.component"
                :data="data"
                @close="closeEditScreen()"
                @save="saveChanges" />
        </view-details>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { GenericTab, BusinessPartner } from '@/models';
import TabHeaders from '@/components/view-details/tab-headers/tab-headers.vue';
import BusinessPartnerIdentification from '@/components/view-details/business-partner/business-partner-identification/business-partner-identification.vue';
import { BusinessPartnersService } from '@/services';
import ViewDetails from '@/components/view-details/view-details.vue';

@Component({
    name: 'operation-mode-details',
    components: {
        tabHeaders: TabHeaders,
        businessPartnerIdentification: BusinessPartnerIdentification,
        viewDetails: ViewDetails,
    },
})
export default class BusinessPartnerDetails extends Vue {
    /**
     * Business partner for which to show details
     */
    @Prop({ required: true })
    private data!: BusinessPartner;

    private businessParnersService: BusinessPartnersService = new BusinessPartnersService();

    private tabs: GenericTab[] = [
        { name: 'identification', component: 'businessPartnerIdentification'},
    ];
    private activeTab = this.tabs[0];

    private selectTab(tab: GenericTab) {
        this.activeTab = tab;
    }

    private closeEditScreen(): void {
        this.$emit('close');
    }

    private saveChanges(item: BusinessPartner, isAdd: boolean): void {
        this.$emit('save', item, isAdd, null);
    }
}
</script>
