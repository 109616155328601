<template>
    <pui-grid-container v-if="data" class="pui-grid-container-zero-padding">
        <pui-grid-row>
            <pui-grid-column :cols="fullCols">
                <title-form-text
                    :title="$t('masterData')"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :required="true"
                    type="date"
                    :label="$t('validFrom')"
                    name="validFrom"
                    :model="data.validFrom"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column v-if="shouldDisplayValidUntil" :cols="itemFormCols">
                <pebble-text-field
                    :disable="true"
                    type="date"
                    :label="$t('validUntil')"
                    name="validTo"
                    :model="data.validTo"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :required="true"
                    v-if="data.enumObjectTypeSid"
                    :label="$t('assetType')"
                    name="enumObjectTypeSid"
                    :model="data.enumObjectTypeSid"
                    :options="assetTypeOptions"
                    @on-input="validate"
                    @on-blur="(value, name) => validate(value, name, true)"
                    :disable="!isNewSynergyLocation"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :required="true"
                    v-if="data"
                    :label="$t('tableName')"
                    name="tableName"
                    :model="data.tableName"
                    :options="tableOptions"
                    :disable="true"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    v-if="data.enumObjectTypeSid.value"
                    :required="true"
                    :label="$t('country')"
                    name="uniperCountryShort"
                    :model="data.uniperCountryShort"
                    :options="countryOptions"
                    :disable="!isNewSynergyLocation"
                    @on-input="validate"
                    @on-blur="(value, name) => validate(value, name, true)"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :required="true"
                    :label="$t('identifier')"
                    name="uniperLocationShort"
                    :model="data.uniperLocationShort"
                    @on-input="validate"
                    @on-blur="validate"
                    :disable="true"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :required="true"
                    v-if="data.sid && data.uniperCountryShort.value"
                    :label="$t('asset')"
                    name="sid"
                    :model="data.sid"
                    :options="assetOptions"
                    has-searchable-secondary-labels
                    @on-input="validate"
                    @on-blur="(value, name) => validate(value, name, true)"
                    :disable="!isNewSynergyLocation"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols" class="check-box-form">
                <pebble-check-box
                    :label="$t('deleted')"
                    name="deleted"
                    :model="data.isDeleted"
                    @on-change="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import CheckboxInput from '@/components/checkbox-input/checkbox-input.vue';
import CheckBox from '@/components/checkbox/check-box.vue';
import {Prop, Watch} from 'vue-property-decorator';
import {SinergyLifeForm} from '@/models/form/sinergy-life-form';
import {AssetTypeOptions, EventBus} from '@/utils';
import PebbleTextField from '@/components/pebble-form-element/pebble-text-field.vue';
import PebbleDropDownSelect from '@/components/pebble-form-element/pebble-drop-down-select.vue';
import PebbleCheckBox from '@/components/pebble-form-element/pebble-check-box.vue';
import {PebbleDropDown} from '@/models/pebble/pebble-drop-down';
import {Asset, IdTypeSid, SearchPaginationQueryModel} from '@/models';
import {AssetService, PowerPlantService, UnitService} from '@/services';
import {TableNameOptions} from '@/utils/asset-type-synergy';
import TitleFormText from '@/components/text/title-form-text.vue';
import {PebbleColConst} from '@/mixins/pebble-col-const';
import ComponentSecurity from '@/mixins/component-security';

@Component({
    name: 'sinergy-life-master-data-form',
    components: {
        TitleFormText,
        PebbleCheckBox, PebbleDropDownSelect, PebbleTextField, CheckBox, CheckboxInput,
    },
})
export default class SinergyLifeMasterDataForm extends mixins(ComponentSecurity, PebbleColConst) {
    @Prop({required: true})
    private data!: SinergyLifeForm;
    private assetTypeOptions: PebbleDropDown[] = [];
    private tableOptions = TableNameOptions;
    private assetService: AssetService = new AssetService();
    private countryOptions: any[] = [];
    private assetOptions: any[] = [];
    private assetFilterModel: SearchPaginationQueryModel = {
        page: 1,
        size: 1000,
        term: '',
    };

    private get shouldDisplayValidUntil() {
        return this.$route.name !== 'sinergy-life-location-new';
    }

    @Watch('data')
    private async updateData(
        newData: SinergyLifeForm,
        oldData: SinergyLifeForm,
    ): Promise<void> {
        if (newData && newData !== oldData) {
            this.data = newData;
        }
        await this.getCountries();
        await this.setIdentifier();
    }

    private async created(): Promise<void> {
        await Promise.all([
            this.getCountries(),
            this.setIdentifier(),
        ]);
        this.getAsseTypes();
    }

    private get isNewSynergyLocation(): boolean {
        return !!this.$route.name
            && [
                'sinergy-life-location',
                'sinergy-life-location-new',
            ].includes(this.$route.name);
    }

    private resetSelectedAsset(): void {
        this.data.sid.value = '';
    }

    private async validate(value: string, name: string, isBlurEvent = false): Promise<void> {
        if (name === 'uniperCountryShort' && !isBlurEvent) {
            this.resetSelectedAsset();
            if ([IdTypeSid.UNIT_TYPE, IdTypeSid.POWER_PLANT_TYPE].includes(this.data.enumObjectTypeSid.value) || !this.assetOptions.length) {
                await this.getAssetOptions(this.data.enumObjectTypeSid.value);
            }
        }
        if (
            name === 'enumObjectTypeSid' &&
            !!this.data.uniperCountryShort.value &&
            !isNaN(+value) &&
            !isBlurEvent
        ) {
            this.resetSelectedAsset();
            await this.getAssetOptions(+value);
        }
        if (name === 'sid' && value !== '' && !isBlurEvent) {
            await this.setIdentifier();
        }
        this.$emit('validate', value, name);
        this.$forceUpdate();
    }

    private getAsseTypes(): void {
        this.assetTypeOptions = this
            .generateOptions(AssetTypeOptions, this.assetTypeOptions, 'displayName', 'value', 'displayName')
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    private async getCountries(): Promise<void> {
        const countries = await this.getAssets(IdTypeSid.COUNTRY);
        this.countryOptions = this.generateOptions(countries, this.countryOptions, 'description', 'name', 'sid');
    }


    private async getAssetOptions(assetType: number): Promise<void> {
        const selectedCountryId = this.countryOptions.find((c) => c.value === this.data.uniperCountryShort.value)?.identifier;
        let assetList = [];
        switch (+assetType) {
            case 1270:
                assetList = await this.getAssets(IdTypeSid.FLEET);
                this.assetOptions = this.generateOptions(assetList, this.assetOptions, 'description', 'sid', 'name', true);
                break;
            case 1290:
                assetList = (await (new PowerPlantService()).get(selectedCountryId)).result.data;
                this.assetOptions = this.generateOptions(assetList, this.assetOptions, 'locationName', 'locationSid', 'locationShort', true);
                break;
            case 1550:
                assetList = await this.getAssets(IdTypeSid.RIVER_GROUP);
                this.assetOptions = this.generateOptions(assetList, this.assetOptions, 'description', 'sid', 'name', true);
                break;
            case 500:
                assetList = (await (new UnitService()).get({countryId: selectedCountryId})).result.data;
                this.assetOptions = this.generateOptions(assetList, this.assetOptions, 'objectLong', 'machineSid', 'objectShort', true);
                break;
            case 1300:
                assetList = await this.getAssets(IdTypeSid.PLANT_GROUP);
                this.assetOptions = this.generateOptions(assetList, this.assetOptions, 'description', 'sid', 'name', true);
                break;
        }
    }

    private generateOptions(
        dataList: any[],
        optionsList: any[],
        name: string,
        value: string,
        identifier: string,
        hasSecondaryLabel = false,
    ): any[] {
        optionsList = [];
        dataList.map((listData) => {
            const option: any = {
                label: listData[name],
                value: listData[value],
                identifier: listData[identifier],
            };
            if (hasSecondaryLabel) {
                option.secondaryLabel = listData[value].toString();
            }
            optionsList.push(option);
        });
        return optionsList = optionsList.filter((element, i) => i === optionsList.indexOf(element));
    }

    private async setIdentifier(): Promise<void> {
        if (this.isNewSynergyLocation) {
            const selectedAsset = this.assetOptions.find((asset) => {
                if (this.data.sid.value === asset.value) {
                    return asset;
                }
            });
            if (selectedAsset !== undefined) {
                this.data.uniperLocationShort.value = selectedAsset.identifier;
                this.data.uniperLocationName.value = selectedAsset.label;
                this.autoSetTableName();
            }
        }
    }

    private autoSetTableName(): void {
        const typeSid = this.data.enumObjectTypeSid.value;
        if (typeSid === 1300 || typeSid === 1550 || typeSid === 1270) {
            this.data.tableName.value = this.tableOptions[2].value;
        } else if (typeSid === 500) {
            this.data.tableName.value = this.tableOptions[1].value;
        } else if (typeSid === 1290) {
            this.data.tableName.value = this.tableOptions[0].value;
        }
    }

    private async setCountry(sid: number): Promise<void> {
        try {
            const powerPlant = (await (new PowerPlantService()).getById(sid)).result;
            this.countryOptions.find((option) => {
                if (option.identifier === powerPlant.enumCountrySid) {
                    this.data.uniperCountryShort.value = option.value;
                }
            });
        } catch (err: any) {
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingPowerPlant');
            throw err;
        }
    }

    private async getAssets(idTypeSid: number): Promise<Asset[]> {
        try {
            const {result: {items}} = await this.assetService.getByIdTypeSid(idTypeSid, this.assetFilterModel);
            return items;
        } catch (err: any) {
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
    }
}
</script>

<style scoped>
.check-box-form {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: flex-start;
    align-items: flex-end;
}
</style>
