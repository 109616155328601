<template>
    <pui-grid-row class="pui-grid-row--end pui-grid-column-zero-margin buttons-margin">
        <pui-button
            :disabled="cancelButtonIsDisabled"
            @click="onClickCancelButton"
            state="secondary"
            class="cancel-button">
            {{ $t('cancel') }}
        </pui-button>
        <pui-button
            @click="onClickSaveButton"
            :disabled="saveButtonIsDisabled"
            class="save-button">
            {{ $t('save') }}
        </pui-button>
    </pui-grid-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'cancel-save-buttons',
})
export default class CancelSaveButtons extends Vue {
    @Prop({default: false})
    private cancelButtonIsDisabled!: boolean;

    @Prop({default: false})
    private saveButtonIsDisabled!: boolean;

    @Prop()
    private onClickCancelButton!: () => void;

    @Prop()
    private onClickSaveButton!: () => void;
}
</script>

<style scoped lang="less">
@import "~@/variables.less";

.buttons-margin {
    margin-top: 8px !important;
}

.cancel-button {
    margin-right: 8px;
}

.save-button {
    margin-left: 8px;
}


@media only screen and (max-width: @md) {
    .cancel-button {
        margin: 8px 0;
        width: 100%;
    }

    .save-button {
        margin: 8px 0;
        width: 100%;
    }
}
</style>
