var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataLoaded)?_c('pui-grid-container',{staticClass:"pui-grid-container-fix-margin"},[_c('pui-grid-row',[_c('sinergy-life-location-header',{attrs:{"selected-countries":_vm.selectedFilters.countries,"selected-asset-types":_vm.selectedFilters.assetTypes},on:{"filter-change":function (value) { return _vm.getFilterValues(value); }}})],1),_c('pui-grid-row',[_c('custom-table',{attrs:{"columns-key":_vm.columns,"data":_vm.dataTable,"heading":_vm.heading,"sortable-key":_vm.sortable,"per-page":10,"per-page-values":[10, 50, 100],"enable-pagination":true,"enable-search":true,"enable-limit":true,"custom-template":['deleted', 'action'],"initial-order-by":{ column: 'sid', ascending: true }},scopedSlots:_vm._u([{key:"custom_deleted",fn:function(ref){
var data = ref.data;
return [_c('boolean-text',{attrs:{"titles":{
                        textTrue: _vm.$t('yes'),
                        textFalse: _vm.$t('no'),
                    },"value":data}})]}},{key:"custom_action",fn:function(ref){
                    var data = ref.data;
return [_c('pui-link',{attrs:{"title":_vm.$t('edit'),"icon":"edit"},on:{"click":function($event){return _vm.goToEdit(data)}}})]}}],null,false,3852362744)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }