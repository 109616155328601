<template>
    <div v-if="errors.length > 0" class="entire-container">
        <div v-for="(error, index) in errors" class="error-container" :key="index">
            <div class="icon">
                <pui-icon icon-name="warning" size="2.5rem" color="rgba(255,0,0,1)"/>
            </div>
            <div class="text-container">
                <pui-headline type="h5" looks-like="form-section-title">{{ error.title }}</pui-headline>
                <pui-headline type="h6">{{ error.text }}</pui-headline>
            </div>
            <div @click="removeError(index)" class="close-icon">
                <pui-icon icon-name="close" size="2.5rem"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {PebbleNotification} from '@/models/pebble/pebble-notification';

@Component({
    name: 'pebble-errors-list',
})
export default class PebbleErrorsList extends Vue {
    @Prop({required: true})
    private errors!: PebbleNotification[];

    private removeError(index: number): void {
        this.errors.splice(index, 1);
    }
}
</script>

<style scoped>
.entire-container {
    width: 100%;
}

.error-container {
    background-color: rgba(252, 13, 27, 0.1);
    border-radius: 4px;
    border: 1px solid #fc0d1b;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 0 16px 0;
    margin: 8px 0;
}

.icon {
    margin: 4px;
}

.close-icon {
    cursor: pointer;
    margin: 4px;
}

.text-container {
    flex: auto;
    margin: 0 8px;
}

</style>
