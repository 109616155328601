
export class AssetOwnerModel {
    public static mapToAddEditRequest(assetOwner: AssetOwnerModel): any {
        return {
            unitSid: assetOwner?.unitSid,
            ownerSid: assetOwner?.ownerSid,
            share: assetOwner?.share,
            lastUpdateComment: assetOwner?.lastUpdateComment,
        };
    }

    public unitSid: number;
    public ownerSid: number;
    public ownerName: string;
    public share: number;
    public lastUpdated?: string;
    public lastUpdatedBy?: string;
    public lastUpdateComment?: string;

    constructor(data?: any) {
        this.unitSid = data?.unitSid;
        this.ownerSid = data?.ownerSid;
        this.ownerName = data?.ownerName;
        this.share = data?.share;
        this.lastUpdated = data?.lastUpdated;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdateComment = data?.lastUpdateComment;
    }
}
