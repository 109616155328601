<template>
    <card>
        <pui-grid-container>
            <card-header
                :title="$t('addNew')"
                :on-click-back-button="onClickBackButton"
            />
            <pui-grid-row>
                <fake-single-tab
                    :title="$t('identification')"
                />
            </pui-grid-row>
            <pui-grid-row
                :class="errors.length > 0 ? 'pebble-errors-list-margin-top' : ''"
            >
                <pebble-errors-list
                    id="errors-list"
                    :errors="errors"
                />
            </pui-grid-row>
            <pui-form aria-label="Form">
                <pui-grid-row>
                    <pui-grid-column class="pui-grid-column-zero-padding">
                        <material-group-identification-form
                            :data="formValues"
                            :enum-material-type-list="enumMaterialTypeList"
                            :enum-su-material-list="enumSuMaterialList"
                            @validate="validate"
                        />
                    </pui-grid-column>
                </pui-grid-row>
            </pui-form>
            <cancel-save-buttons
                :save-button-is-disabled="!hasMasterDataAdmin || saveButtonIsDisabled"
                :on-click-cancel-button="onClickBackButton"
                :on-click-save-button="onClickSaveButton"
            />
        </pui-grid-container>
    </card>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import Card from '@/components/cards/card.vue';
import BackText from '@/components/text/back-text.vue';
import FakeSingleTab from '@/components/tab-bar/fake-single-tab.vue';
import MaterialGroupIdentificationForm from '@/components/forms/material-group-identification-form.vue';
import {PebbleDropDown, pebbleDropDownFromAssets} from '@/models/pebble/pebble-drop-down';
import {Asset, IdTypeSid, MaterialGroupModel} from '@/models';
import {EventBus} from '@/utils';
import {AssetService, MaterialGroupService} from '@/services';
import {generateDataFromFormValues, generateErrors, isValidInput, manageResolve, shouldDisableForm} from '@/utils/utils';
import {PebbleNotification} from '@/models/pebble/pebble-notification';
import PebbleErrorsList from '@/components/error-list/pebble-errors-list.vue';
import {IdentificationMaterialGroupForm} from '@/models/form/identification-material-group-form';
import {identificationMaterialGroupForm} from '@/utils/pebble-form/master-data-management/identification-material-group-form';
import {MaterialGroupFactory} from '@/utils/factories';
import CancelSaveButtons from '@/components/buttons/cancel-save-buttons.vue';
import ComponentSecurity from '@/mixins/component-security';
import CardHeader from '@/components/cards/card-header.vue';

@Component({
    name: 'management-material-group-new',
    components: {
        CardHeader,
        CancelSaveButtons,
        PebbleErrorsList,
        MaterialGroupIdentificationForm,
        FakeSingleTab,
        BackText,
        Card,
    },
})
export default class ManagementMaterialGroupNew extends mixins(ComponentSecurity) {
    /* VARIABLES */
    private service: MaterialGroupService = new MaterialGroupService();
    private assetService: AssetService = new AssetService();
    private errors: PebbleNotification[] = [];
    private enumSuMaterialList: PebbleDropDown[] = [];
    private enumMaterialTypeList: PebbleDropDown[] = [];
    private saveButtonIsDisabled = true;

    /* LATE VARIABLES */
    private formValues!: IdentificationMaterialGroupForm;

    /* PRIMITIVE METHODS */
    private created(): void {
        this.formValues = identificationMaterialGroupForm(MaterialGroupFactory.creatMaterialGroupAddRequest());
    }

    private mounted(): void {
        this.$router.beforeResolve((to, from, next) => manageResolve(!this.saveButtonIsDisabled, next, this));
        this.init();
        this.saveButtonIsDisabled = shouldDisableForm(this.formValues);
    }

    private destroyed(): void {
        this.saveButtonIsDisabled = true;
    }

    /* METHODS */
    private async init(): Promise<void> {
        this.$store.commit('loading');
        const [enumSuMaterial, enumMaterialType] = await Promise.all([
            this.loadAssets(IdTypeSid.ENUM_SU_MATERIAL_GROUP),
            this.loadAssets(IdTypeSid.ENUM_MATERIAL_TYPE),
        ]).finally(() => {
            this.$store.commit('loading');
        });
        this.enumSuMaterialList = pebbleDropDownFromAssets(enumSuMaterial);
        this.enumMaterialTypeList = pebbleDropDownFromAssets(enumMaterialType);
    }

    private validate(value: string, name: string): void {
        isValidInput(name, this.formValues[name]);
        this.saveButtonIsDisabled = shouldDisableForm(this.formValues);
    }

    /* ON CLICK BUTTONS */
    private onClickBackButton(): void {
        this.$router.push({
            name: `management-material-group`,
        });
    }

    private onClickSaveButton(): void {
        this.saveData();
    }

    /* API CALLS */
    private async loadAssets(idTypeSid: number): Promise<Asset[]> {
        let assets: Asset[] = [];
        try {
            const {result: {items}} = await this.assetService.getByIdTypeSid(idTypeSid, {page: 1, size: 1000, term: ''});
            assets = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
        return assets;
    }

    private async saveNewMaterialGroup(model: MaterialGroupModel): Promise<boolean> {
        try {
            this.$store.commit('loading');
            await this.service.create(model);
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingMaterial');
            generateErrors(this.errors, error);
            return false;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveData(): Promise<void> {
        if (await this.saveNewMaterialGroup(generateDataFromFormValues(this.formValues) as MaterialGroupModel)) {
            this.saveButtonIsDisabled = true;
            this.onClickBackButton();
        }
    }
}
</script>
