<template>
    <key-mapping-view
        v-if="check.dataLoaded"
        :object-sid="+sid"
        :data="assets"
    />
    <pui-loader-error
        v-else-if="check.showError"
        :title="$t('errorTitleDataLoaded')"
        :message="$t('errorLoadingExternalSystems')"
        icon="error-alert"
        :buttons="[
            {
                state: 'secondary',
                label: $t('refresh'),
                onClick: onClickRefresh
            }
        ]"
    />
</template>

<script lang="ts">
import ComponentSecurity from '@/mixins/component-security';
import Component, {mixins} from 'vue-class-component';
import KeyMappingView from '@/components/view/key-mapping-view.vue';
import {AssetService} from '@/services';
import {CheckModel} from '@/models/check-model';
import {Asset} from '@/models';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'key-mapping-tab',
    components: {KeyMappingView},
})
export default class KeyMappingTab extends mixins(ComponentSecurity) {/* VARIABLES */
    private service: AssetService = new AssetService();
    private check: CheckModel = {
        showError: false,
        dataLoaded: false,
        saveButtonIsDisabled: true,
    };

    /* LATE VARIABLES */
    private assets?: Asset[];

    @Prop({required: true})
    private sid!: string | undefined;

    @Prop({required: true})
    private idTypeSid!: number;

    /* PRIMITIVE METHODS */
    private mounted(): void {
        this.init();
    }

    /* METHODS */
    private async init(): Promise<void> {
        this.assets = await this.loadAssets();
        this.check.dataLoaded = true;
    }

    /* ON CLICK BUTTON */
    private onClickRefresh(): void {
        this.init();
    }

    /* API CALLS */
    private async loadAssets(): Promise<Asset[]> {
        this.$store.commit('loading');
        try {
            const {result: {items}} = await this.service.getByIdTypeSid(
                this.idTypeSid,
                {
                    size: 999, page: 1, term: '',
                },
            );
            return items;
        } catch (err: any) {
            this.check.showError = true;
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }
}
</script>

