import { HierarchicPowerPlantSystem } from '@/models';

export class PowerPlantListItem {
    public sid: number;
    public abbreviation?: string;
    public name?: string;
    public plantType?: string;
    public sapType?: string;
    public country?: string;
    public fleet?: string;
    public plantGroup?: string;
    public regionalUnit?: string;
    public lastUpdated?: string;
    public lastUpdatedBy?: string;
    public deleted?: boolean;
    public inHierarchy: boolean;

    public parentSid: number;
    public children?: PowerPlantListItem[];
    public nodeTypeSid?: number;
    public powerPlantSid?: number;
    public countrySid?: number;
    public nodeLevel?: number;
    public hasChildren?: boolean;
    public isRowExpanded?: boolean;

    constructor(data: HierarchicPowerPlantSystem | any) {
        this.sid = data.sid;
        this.abbreviation = data?.abbreviation;
        this.name = data?.name;
        this.plantType = data?.plantType;
        this.sapType = data?.sapType;
        this.country = data?.country;
        this.fleet = data?.fleet;
        this.plantGroup = data?.plantGroup;
        this.regionalUnit = data?.regionalUnit;
        this.lastUpdated = data?.lastUpdated;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.deleted = data?.deleted;
        this.inHierarchy = data?.inHierarchy;

        this.parentSid = data?.parentSid;
        this.children = data?.children?.map((child: PowerPlantListItem) => new PowerPlantListItem(child));
        this.nodeTypeSid = data?.nodeTypeSid;
        this.powerPlantSid = data?.powerPlantSid;
        this.countrySid = data?.countrySid;
        this.nodeLevel = data?.nodeLevel;
        this.hasChildren = data.hasChildren;
        this.isRowExpanded = false;
    }
}
