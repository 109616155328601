import { VuexModule, Module, Action, MutationAction } from 'vuex-module-decorators';
import { IdTypeSid, SelectOption } from '@/models';
import { assetService, businessPartnersService } from '@/services';

@Module({ namespaced: true })
class AssetOverviewModule extends VuexModule {
    public countries: SelectOption[] = [];
    public machineTypes: SelectOption[] = [];
    public primaryEnergies: SelectOption[] = [];
    public rivers: SelectOption[] = [];
    public regionalUnits: SelectOption[] = [];
    public statuses: SelectOption[] = [];
    public legalEntities: SelectOption[] = [];
    public unitTechnologies: SelectOption[] = [];

    @Action
    public async loadAssetOverviewAssets(): Promise<void> {
        await Promise.all([
            this.context.dispatch('loadCountries'),
            this.context.dispatch('loadMachineTypes'),
            this.context.dispatch('loadPrimaryEnergies'),
            this.context.dispatch('loadRivers'),
            this.context.dispatch('loadRegionalUnits'),
            this.context.dispatch('loadStatuses'),
            this.context.dispatch('loadLegalEntities'),
            this.context.dispatch('loadUnitTechnologies'),
        ]);
    }

    @MutationAction({ mutate: ['countries'] })
    public async loadCountries(): Promise<any> {
        if (this.countries.length > 0) {
            return undefined;
        }
        const countries = (await assetService.getAssetsByIdType(IdTypeSid.COUNTRY))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: `${item.name ?? ''} - ${item.description ?? ''}`,
            }));
        return { countries };
    }

    @MutationAction({ mutate: ['machineTypes'] })
    public async loadMachineTypes(): Promise<any> {
        if (this.machineTypes.length > 0) {
            return undefined;
        }
        const machineTypes = (await assetService.getAssetsByIdType(IdTypeSid.SAP_EC_TYPE))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name ?? '',
            }));
        return { machineTypes };
    }

    @MutationAction({ mutate: ['primaryEnergies'] })
    public async loadPrimaryEnergies(): Promise<any> {
        if (this.primaryEnergies.length > 0) {
            return undefined;
        }
        const primaryEnergies = (await assetService.getAssetsByIdType(IdTypeSid.OPERATION_MODE_ENERGY_TYPE))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name ?? '',
            }));
        return { primaryEnergies };
    }


    @MutationAction({ mutate: ['rivers'] })
    public async loadRivers(): Promise<any> {
        if (this.rivers.length > 0) {
            return undefined;
        }
        const rivers = (await assetService.getAssetsByIdType(IdTypeSid.RIVER))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name ?? '',
            }));
        return { rivers };
    }

    @MutationAction({ mutate: ['regionalUnits'] })
    public async loadRegionalUnits(): Promise<any> {
        if (this.regionalUnits.length > 0) {
            return;
        }
        const regionalUnits = (await assetService.getAssetsByIdType(IdTypeSid.REGIONAL_UNIT))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { regionalUnits };
    }

    @MutationAction({ mutate: ['statuses'] })
    public async loadStatuses(): Promise<any> {
        if (this.statuses.length > 0) {
            return;
        }
        const statuses =  (await assetService.getAssetsByIdType(IdTypeSid.OPERATION_STATUS_TYPE))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { statuses };
    }

    @MutationAction({ mutate: ['legalEntities'] })
    public async loadLegalEntities(): Promise<any> {
        if (this.legalEntities.length > 0) {
            return;
        }
        const legalEntities = (await businessPartnersService.getBusinessPartners())
            .map((item: any): SelectOption => ({
                value: item.businessPartnerSid,
                label: item.name,
            }));
        return { legalEntities };
    }

    @MutationAction({ mutate: ['unitTechnologies'] })
    public async loadUnitTechnologies(): Promise<any> {
        if (this.unitTechnologies.length > 0) {
            return;
        }
        const unitTechnologies =  (await assetService.getAssetsByIdType(IdTypeSid.TECHNOLOGY))
            .map((item: any): SelectOption => ({
                value: item.sid,
                label: item.name,
            }));
        return { unitTechnologies };
    }
}

export default AssetOverviewModule;
