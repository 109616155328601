import { ObjectKeys } from './object-keys';
import { FormValuesModel } from './pebble/form-values-model';

export interface SinergyLifeEditData {
    validFrom: string;
    validTo: string;
    slLocation: string;
    slLocationSid: string;
    uniperLocationName: string;
    enumObjectTypeSid: string;
    lastUpdateComment: string;
    lastUpdated: string;
    lastUpdatedBy: string;
    isDeleted: boolean;
    action?: string;
}

export interface AddSynergyRequest extends ObjectKeys {
    sid?: FormValuesModel;
    slCountry?: FormValuesModel;
    slLocation?: FormValuesModel;
    objectTypeSid?: FormValuesModel;
    tableName?: FormValuesModel;
    uniperCountryShort?: FormValuesModel;
    uniperLocationShort?: FormValuesModel;
    uniperLocationName?: FormValuesModel;
    locationType?: FormValuesModel;
    slLocationLastLevel?: FormValuesModel;
    slLocationLevel1?: FormValuesModel;
    slLocationLevel2?: FormValuesModel;
    slLocationLevel3?: FormValuesModel;
    slLocationLevel4?: FormValuesModel;
    lastUpdateComment?: FormValuesModel;
    validFrom?: FormValuesModel;
    isDeleted?: FormValuesModel;
}

export interface EditSyngeryRequest {
    sid?: number;
    validFrom?: string;
    existingSlLocation?: string;
    slCountry?: string;
    slLocation?: string;
    slLocationSid?: string;
    objectTypeSid?: number;
    tableName?: string;
    uniperCountryShort?: string;
    uniperLocationShort?: string;
    slLocationLastLevel?: string;
    slLocationLevel1?: string;
    slLocationLevel2?: string;
    slLocationLevel3?: string;
    slLocationLevel4?: string;
    uniperLocationName?: string;
    locationType?: string;
    lastUpdateComment?: string;
    isDeleted?: true;
    validTo?: string;
}

export const SinergyLifeEditDataColumnKeys = [
    'validFrom',
    'validTo',
    'sid',
    'uniperLocationName',
    'lastUpdated',
    'lastUpdatedBy',
    'action',
];
