<template>
    <pui-grid-container class="pui-grid-container-zero-padding">
        <pui-grid-row>
            <pui-grid-column :cols="fullCols">
                <title-form-text
                    :title="$t('performanceData')"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('grossCapacity')"
                    :model="data.grossCapacity"
                    name="grossCapacity"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('netCapacityMW')"
                    :model="data.netCapacity"
                    name="netCapacity"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('accountingNetCapacity')"
                    :model="data.accountingNetCapacity"
                    name="accountingNetCapacity"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('netCapacityMin')"
                    :model="data.netMinCapacityTec"
                    name="netMinCapacityTec"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('netCapacityContract')"
                    :model="data.netCapacityContract"
                    name="netCapacityContract"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('thermalShareCapacity')"
                    :model="data.thermalCapacityChp"
                    name="thermalCapacityChp"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('thermalCapacity')"
                    :model="data.thermalCapacity"
                    name="thermalCapacity"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('netMinThermalCapacity')"
                    :model="data.netMinThermalCapacity"
                    name="netMinThermalCapacity"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('firingThermalCapacity')"
                    :model="data.firingThermalCapacity"
                    name="firingThermalCapacity"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('primaryReservePos')"
                    :model="data.primaryReservePos"
                    name="primaryReservePos"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('primaryReserveNeg')"
                    :model="data.primaryReserveNeg"
                    name="primaryReserveNeg"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('secondaryReservePos')"
                    :model="data.secondaryReservePos"
                    name="secondaryReservePos"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('secondaryReserveNeg')"
                    :model="data.secondaryReserveNeg"
                    name="secondaryReserveNeg"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('minuteReservePos')"
                    :model="data.minuteReservePos"
                    name="minuteReservePos"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('minuteReserveNeg')"
                    :model="data.minuteReserveNeg"
                    name="minuteReserveNeg"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('maxSpeedPowerInc')"
                    :model="data.maxSpeedPowerInc"
                    name="maxSpeedPowerInc"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('startUpRampGradient')"
                    :model="data.startUpRampGradient"
                    name="startUpRampGradient"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('startUpRamp')"
                    :model="data.startUpRamp"
                    name="startUpRamp"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('startUpRampGradZ2')"
                    :model="data.startUpRampGradZ2"
                    name="startUpRampGradZ2"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('startUpRampGradZ3')"
                    :model="data.startUpRampGradZ3"
                    name="startUpRampGradZ3"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('startUpRampGradZ4')"
                    :model="data.startUpRampGradZ4"
                    name="startUpRampGradZ3"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('maxSpeedPowerDec')"
                    :model="data.maxSpeedPowerDec"
                    name="maxSpeedPowerDec"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('shutdownRampGradient')"
                    :model="data.shutdownRampGradient"
                    name="shutdownRampGradient"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('shutdownRamp')"
                    :model="data.shutdownRamp"
                    name="shutdownRamp"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('shutdownRampGradZ2')"
                    :model="data.shutdownRampGradZ2"
                    name="shutdownRampGradZ2"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('shutdownRampGradZ3')"
                    :model="data.shutdownRampGradZ3"
                    name="shutdownRampGradZ3"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('shutdownRampGradZ4')"
                    :model="data.shutdownRampGradZ4"
                    name="shutdownRampGradZ4"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('downtimeMin')"
                    :model="data.downtimeMin"
                    name="downtimeMin"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('minOperationTime')"
                    :model="data.minOperationTime"
                    name="minOperationTime"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('startuptimeMin')"
                    :model="data.startuptimeMin"
                    name="startuptimeMin"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('startUpTimeConditionCold')"
                    :model="data.startUpTimeConditionCold"
                    name="startUpTimeConditionCold"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('startUpTimeConditionWarm')"
                    :model="data.startUpTimeConditionWarm"
                    name="startUpTimeConditionWarm"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('startUpTimePminCold')"
                    :model="data.startUpTimePminCold"
                    name="startUpTimePminCold"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('startUpTimePminWarm')"
                    :model="data.startUpTimePminWarm"
                    name="startUpTimePminWarm"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('durationUpMin')"
                    :model="data.durationUpMin"
                    name="durationUpMin"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('durationDownMin')"
                    :model="data.durationDownMin"
                    name="durationDownMin"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('shutdownTimePminGrid')"
                    :model="data.shutdownTimePminGrid"
                    name="shutdownTimePminGrid"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols" class="pui-grid-col--bottom">
                <pebble-check-box
                    :disabled="disabled"
                    :label="$t('externalDispatch')"
                    :model="data.isExternalOperation"
                    name="isExternalOperation"
                    @on-change="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('efficiency')"
                    :model="data.efficiency"
                    name="efficiency"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('hourlyLossFactor')"
                    :model="data.hourlyLossFactor"
                    name="hourlyLossFactor"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('flowRateIntoStoreMax')"
                    :model="data.flowRateIntoStoreMax"
                    name="flowRateIntoStoreMax"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('flowRateOutOfStoreMax')"
                    :model="data.flowRateOutOfStoreMax"
                    name="flowRateOutOfStoreMax"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('storageLevelMax')"
                    :model="data.storageLevelMax"
                    name="storageLevelMax"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('storageLevelStart')"
                    :model="data.storageLevelStart"
                    name="storageLevelStart"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('forwardCapacity')"
                    :model="data.forwardCapacity"
                    name="forwardCapacity"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('reverseCapacity')"
                    :model="data.reverseCapacity"
                    name="reverseCapacity"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('incidentPenaltyThreshold')"
                    :model="data.incidentPenaltyThreshold"
                    name="incidentPenaltyThreshold"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="number"
                    :label="$t('mWhLossMWCapacityThreshold')"
                    :model="data.mwhLossOrMwCapacityThreshold"
                    name="mwhLossOrMwCapacityThreshold"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import {Prop} from 'vue-property-decorator';
import ComponentSecurity from '@/mixins/component-security';
import Component, {mixins} from 'vue-class-component';
import {IdentificationOperationModesForm} from '@/models/form/identification-operation-modes-form';
import {OperationModesModel} from '@/models/operation-modes-model';
import PebbleTextField from '@/components/pebble-form-element/pebble-text-field.vue';
import PebbleCheckBox from '@/components/pebble-form-element/pebble-check-box.vue';
import {PebbleValidate} from '@/mixins/pebble-validate';
import TitleFormText from '@/components/text/title-form-text.vue';
import {PebbleColConst} from '@/mixins/pebble-col-const';

@Component({
    name: 'operation-modes-performance-data-form',
    components: {TitleFormText, PebbleCheckBox, PebbleTextField},
})
export default class OperationModesPerformanceDataForm extends mixins(PebbleValidate, ComponentSecurity, PebbleColConst) {
    @Prop({required: true})
    private data!: IdentificationOperationModesForm;

    @Prop({required: false})
    private selectedVersion?: OperationModesModel;

    @Prop({default: false})
    private disabled!: boolean;
}
</script>


