<template>
    <pui-filter
        v-if="filterIsEnabled"
        :config="filterOptions"
        :use-router="false"
        vuex-namespace="filter"
        @changed:applied-values="onFilterChange"
    />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Filter} from '@/models/custom-filter/filter';
import {FilterOptions} from '@/models/custom-filter/filter-options';
import {Prop} from 'vue-property-decorator';
import {CustomObject} from '@/models/custom-object';

@Component({
    name: 'custom-filter',
})
export default class CustomFilter extends Vue {
    @Prop({default: () => []})
    private filters!: Filter[];

    @Prop({required: false})
    private onFilterChange?: (data: CustomObject) => void;

    private filterIsEnabled = false;
    private filterOptions: FilterOptions = {
        filters: [],
    };

    private mounted(): void {
        this.filterIsEnabled = false;
        this.filterOptions.filters = [];
        this.filters.forEach((value) => {
            this.filterOptions.filters.push(value);
        });
        this.filterIsEnabled = true;
    }
}
</script>
