import _Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate';
import { required, max, min } from 'vee-validate/dist/rules';
import i18n from '@/utils/i18n';

export default function VeeValidate(Vue: typeof _Vue): void {
    configure({
        defaultMessage: (field, values) => {
            values._field_ = i18n.t(`fields.${field}`).toString();

            return i18n.t(`validation.${values._rule_}`, values).toString();
        },
    });
    extend('required', (value) => {
        return typeof value === 'number'
            ? !isNaN(value)
            : required.validate(value);
    });
    extend('max', {
        ...max,
        params: ['max'],
        message: i18n.t('validations.maxCaracters').toString(),
        validate(value = '', args: any) {
            return value.length <= args.max;
        },
    });

    extend('min', {
        ...min,
        params: ['min'],
        message: i18n.t('validations.minCaracters').toString(),
        validate(value = '', args: any) {
            return value.length >= args.min;
        },
    });

    Vue.component('validation-provider', {
        extends: ValidationProvider,
        props: {
            skipIfEmpty: {
                type: Boolean,
                default: false,
            },
        },
    });
    Vue.component('validation-observer', ValidationObserver);
}
