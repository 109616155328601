
export class AssetOverviewListItem {
    [key: string]: any;
    public plantName: string;
    public unitName: string;
    public unitNameModule: string;
    public river: string;
    public country: string;
    public regionalUnit: string;
    public technology: string;
    public legalEntity: string;
    public shareHolder: string;
    public shares: string;
    public operatingCompany: string;
    public grossCapacity: string;
    public netCapacity: string;
    public thermalCapacity: string;
    public legallyAttributableCapacity: string;
    public accountingView: string;
    public inclusionMethod: string;
    public unitType: string;
    public unitTechnology: string;
    public detailTechnology: string;
    public primaryEnergy: string;
    public secondaryEnergy: string;
    public mainFuelProcurement: string;
    public co2Procurement: string;
    public marketingOfEnergy: string;
    public capacityContracts: string;
    public capacityContractDetails: string;
    public commissioningDate: string;
    public status: string;
    public reasonOfUnitStatusChange: string;
    public comment: string;
    public aprEndDateOfUnit: string;
    public aprEndDateOfUnitStatus: string;
    public updateDate: string;
    public plantId: number;
    public machineId: number;

    constructor(data?: any) {
        this.plantName = data?.plantName;
        this.unitName = data?.unitName;
        this.unitNameModule = data?.unitNameModule;
        this.river = data?.river;
        this.country = data?.country;
        this.regionalUnit = data?.regionalUnit;
        this.technology = data?.technology;
        this.legalEntity = data?.legalEntity;
        this.shareHolder = data?.shareHolder;
        this.shares = data?.shares;
        this.operatingCompany = data?.operatingCompany;
        this.grossCapacity = data?.grossCapacity;
        this.netCapacity = data?.netCapacity;
        this.thermalCapacity = data?.thermalCapacity;
        this.legallyAttributableCapacity = data?.legallyAttributableCapacity;
        this.accountingView = data?.accountingView;
        this.inclusionMethod = data?.inclusionMethod;
        this.unitType = data?.unitType;
        this.unitTechnology = data?.unitTechnology;
        this.detailTechnology = data?.detailTechnology;
        this.primaryEnergy = data?.primaryEnergy;
        this.secondaryEnergy = data?.secondaryEnergy;
        this.mainFuelProcurement = data?.mainFuelProcurement;
        this.co2Procurement = data?.co2Procurement;
        this.marketingOfEnergy = data?.marketingOfEnergy;
        this.capacityContracts = data?.capacityContracts;
        this.capacityContractDetails = data?.capacityContractDetails;
        this.commissioningDate = data?.commissioningDate;
        this.status = data?.status;
        this.reasonOfUnitStatusChange = data?.reasonOfUnitStatusChange;
        this.comment = data?.comment;
        this.aprEndDateOfUnit = data?.aprEndDateOfUnit;
        this.aprEndDateOfUnitStatus = data?.aprEndDateOfUnitStatus;
        this.updateDate = data?.updateDate;
        this.plantId = data?.plantId;
        this.machineId = data?.machineId;
    }
}

