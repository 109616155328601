const PERMISSION_NAMES: any = {
    MD_ADMIN: 'MasterDataAdmin',
    MD_VIEW: 'MasterDataView',
    MD_EXCEL_EXPORT: 'CanExportToExcel',
    MD_STREAM_ASSETS: 'ManageStreamAssets',
    MD_SYNERGY_LIFE_MAPPING: 'SynergiLifeMapping',
    MD_HYDRO_READ: 'HydroRead',
    MD_HYDRO_WRITE: 'HydroWrite',
};

export default PERMISSION_NAMES;
