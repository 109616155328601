import _Vue from 'vue';
import VuejsDialog from 'vuejs-dialog';
import MdConfirm from '../components/vuejs-dialog/md-confirm.vue';
import i18n from '@/utils/i18n';

export default function Dialog(Vue: typeof _Vue): void {
    Vue.use(VuejsDialog, {
        messageTitle: i18n.t('pleaseConfirm'),
        okText: i18n.t('yes'),
        cancelText: i18n.t('no'),
    });
    Vue.dialog.registerComponent('confirm', MdConfirm);
}
