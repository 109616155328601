<template>
    <pui-grid-container v-if="check.dataLoaded" class="pui-grid-container-zero-padding">
        <pui-grid-row
            :class="errors.length > 0 ? 'pebble-errors-list-margin-top' : ''"
        >
            <pebble-errors-list
                id="errors-list"
                :errors="errors"
            />
        </pui-grid-row>
        <pui-form aria-label="Form">
            <pui-grid-row>
                <pui-grid-column>
                    <material-group-identification-form
                        :data="formValues"
                        :enum-material-type-list="enumMaterialTypeList"
                        :enum-su-material-list="enumSuMaterialList"
                        :is-edit="true"
                        @validate="validate"
                    />
                </pui-grid-column>
            </pui-grid-row>
        </pui-form>
        <pui-grid-row class="pui-grid-row-little-margin-top">
            <pui-grid-column :cols="{s:12, m:5, l:6, xl:6, xxl: 6}">
                <pebble-last-updated
                    :data="{
                        lastUpdatedBy: selectedMaterialGroup.lastUpdatedBy,
                        lastUpdated: selectedMaterialGroup.lastUpdated
                    }"
                />
            </pui-grid-column>
            <pui-grid-column :cols="{s:12, m:7, l:6, xl:6, xxl: 6}">
                <cancel-save-buttons
                    :cancel-button-is-disabled="check.saveButtonIsDisabled"
                    :save-button-is-disabled="!hasMasterDataAdmin || check.saveButtonIsDisabled"
                    :on-click-cancel-button="onClickCancelButton"
                    :on-click-save-button="onClickSaveButton"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
    <pui-grid-container v-else-if="check.showError">
        <pui-grid-row>
            <pui-loader-error
                :title="$t('errorTitleDataLoaded')"
                :message="$t('errorGettingMaterialGroup')"
                icon="error-alert"
                :buttons="[
                    {
                        state: 'secondary',
                        label: $t('refresh'),
                        onClick: onClickRefreshButton,
                    }
                ]"
            />
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import {CheckModel} from '@/models/check-model';
import {IdentificationMaterialGroupForm} from '@/models/form/identification-material-group-form';
import {AssetService, MaterialGroupService} from '@/services';
import {PebbleNotification} from '@/models/pebble/pebble-notification';
import {PebbleDropDown, pebbleDropDownFromAssets} from '@/models/pebble/pebble-drop-down';
import {Asset, IdTypeSid, MaterialGroupEditRequest, MaterialGroupModel} from '@/models';
import {Prop, Watch} from 'vue-property-decorator';
import {EventBus} from '@/utils';
import {generateDataFromFormValues, generateErrors, isValidInput, shouldDisableForm, showDialog} from '@/utils/utils';
import {identificationMaterialGroupForm} from '@/utils/pebble-form/master-data-management/identification-material-group-form';
import CancelSaveButtons from '@/components/buttons/cancel-save-buttons.vue';
import ComponentSecurity from '@/mixins/component-security';
import PebbleLastUpdated from '@/components/last-updated/pebble-last-updated.vue';
import MaterialGroupIdentificationForm from '@/components/forms/material-group-identification-form.vue';
import PebbleErrorsList from '@/components/error-list/pebble-errors-list.vue';

@Component({
    name: 'identification-material-group-tab',
    components: {PebbleErrorsList, MaterialGroupIdentificationForm, PebbleLastUpdated, CancelSaveButtons},
})
export default class IdentificationMaterialGroupTab extends mixins(ComponentSecurity) {
    /* VARIABLES */
    private service: MaterialGroupService = new MaterialGroupService();
    private assetService: AssetService = new AssetService();
    private errors: PebbleNotification[] = [];
    private enumSuMaterialList: PebbleDropDown[] = [];
    private enumMaterialTypeList: PebbleDropDown[] = [];
    private check: CheckModel = {
        dataLoaded: false,
        showError: false,
        saveButtonIsDisabled: true,
    };

    /* LATE VARIABLES */
    private formValues!: IdentificationMaterialGroupForm;
    private selectedMaterialGroup!: MaterialGroupModel;

    @Prop({required: true})
    private sid!: string | undefined;

    @Watch('check.saveButtonIsDisabled')
    private onChanged(value: boolean): void {
        this.$emit('on-form-changed', !value);
    }

    /* PRIMITIVE METHODS */
    private created(): void {
        this.initCreated();
    }

    private mounted(): void {
        this.init();
    }

    /* METHODS */
    private async initCreated(): Promise<void> {
        this.$store.commit('loading');
        const [enumSuMaterial, enumMaterialType] = await Promise.all([
            this.loadAssets(IdTypeSid.ENUM_SU_MATERIAL_GROUP),
            this.loadAssets(IdTypeSid.ENUM_MATERIAL_TYPE),
        ]).finally(() => {
            this.$store.commit('loading');
        });
        this.enumSuMaterialList = pebbleDropDownFromAssets(enumSuMaterial);
        this.enumMaterialTypeList = pebbleDropDownFromAssets(enumMaterialType);
    }

    private async init(): Promise<void> {
        this.check.dataLoaded = false;
        this.check.showError = false;
        this.selectedMaterialGroup = await this.loadMaterialGroup();
        this.formValues = identificationMaterialGroupForm(this.selectedMaterialGroup);
        this.check.dataLoaded = true;
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedMaterialGroup);
    }

    private validate(value: string, name: string): void {
        isValidInput(name, this.formValues[name]);
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedMaterialGroup);
    }

    /* ON CLICK BUTTONS */
    private onClickRefreshButton(): void {
        this.init();
    }

    private async onClickCancelButton(): Promise<void> {
        if (await showDialog(this, 'areYouSureCancelUnsaved')) {
            this.formValues = {...identificationMaterialGroupForm(this.selectedMaterialGroup)};
            this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedMaterialGroup);
        }
    }

    private onClickSaveButton(): void {
        this.saveData();
    }

    /* API CALLS */
    private async loadAssets(idTypeSid: number): Promise<Asset[]> {
        let assets: Asset[] = [];
        try {
            const {result: {items}} = await this.assetService.getByIdTypeSid(idTypeSid, {page: 1, size: 1000, term: ''});
            assets = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
        return assets;
    }

    private async loadMaterialGroup(): Promise<MaterialGroupModel> {
        this.$store.commit('loading');
        try {
            const {result} = await this.service.getById(+`${this.sid}`);
            return result;
        } catch (err: any) {
            this.check.showError = true;
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveMaterialGroup(data: MaterialGroupEditRequest): Promise<boolean> {
        this.$store.commit('loading');
        try {
            await this.service.edit(data);
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorEditingMaterialGroup');
            generateErrors(this.errors, error);
            return false;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveData(): Promise<void> {
        if (await this.saveMaterialGroup(generateDataFromFormValues(this.formValues) as MaterialGroupEditRequest)) {
            await this.init();
        }
    }
}
</script>


