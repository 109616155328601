<template>
    <card>
        <pui-grid-container>
            <card-header
                :title="$t('addNew')"
                :on-click-back-button="onClickBackButton"
            />
            <pui-grid-row>
                <fake-single-tab
                    :title="$t('identification')"
                />
            </pui-grid-row>
            <pui-grid-row
                :class="errors.length > 0 ? 'pebble-errors-list-margin-top' : ''"
            >
                <pebble-errors-list
                    id="errors-list"
                    :errors="errors"
                />
            </pui-grid-row>
            <pui-form aria-label="Form">
                <pui-grid-row>
                    <pui-grid-column class="pui-grid-column-zero-padding">
                        <materials-identification-form
                            :data="formValues"
                            :material-group-list="materialGroupList"
                            :eu-ets-list="euEtsList"
                            :agg-state-list="aggStateList"
                            :quality-list="qualityList"
                            :home-contry-list="homeContryList"
                            :mine-list="mineList"
                            :dehst-list="dehstList"
                            :unit-settle-list="unitSettleList"
                            @validate="validate"
                        />
                    </pui-grid-column>
                </pui-grid-row>
            </pui-form>
            <cancel-save-buttons
                :save-button-is-disabled="!hasMasterDataAdmin || saveButtonIsDisabled"
                :on-click-cancel-button="onClickBackButton"
                :on-click-save-button="onClickSaveButton"
            />
        </pui-grid-container>
    </card>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import Card from '@/components/cards/card.vue';
import BackText from '@/components/text/back-text.vue';
import FakeSingleTab from '@/components/tab-bar/fake-single-tab.vue';
import {PebbleNotification} from '@/models/pebble/pebble-notification';
import PebbleErrorsList from '@/components/error-list/pebble-errors-list.vue';
import CancelSaveButtons from '@/components/buttons/cancel-save-buttons.vue';
import ComponentSecurity from '@/mixins/component-security';
import {generateDataFromFormValues, generateErrors, isValidInput, manageResolve, shouldDisableForm } from '@/utils/utils';
import {AssetService, MaterialBaseService, MaterialGroupService} from '@/services';
import {IdentificationMaterialsForm} from '@/models/form/identification-materials-form';
import MaterialsIdentificationForm from '@/components/forms/materials-identification-form.vue';
import {identificationMaterialsForm} from '@/utils/pebble-form/master-data-management/identification-materials-form';
import {MaterialBaseFactory} from '@/utils/factories';
import {PebbleDropDown, pebbleDropDownFromAssets, pebbleDropDownFromMaterialGroups} from '@/models/pebble/pebble-drop-down';
import {Asset, IdTypeSid, MaterialBaseModel, MaterialGroupModel} from '@/models';
import {EventBus} from '@/utils';
import CardHeader from '@/components/cards/card-header.vue';

@Component({
    name: 'management-materials-new',
    components: {
        CardHeader,
        MaterialsIdentificationForm,
        CancelSaveButtons,
        PebbleErrorsList,
        FakeSingleTab,
        BackText,
        Card,
    },
})
export default class ManagementMaterialsNew extends mixins(ComponentSecurity) {
    /* VARIABLES */
    private service: MaterialBaseService = new MaterialBaseService();
    private assetService: AssetService = new AssetService();
    private materialsGroupService: MaterialGroupService = new MaterialGroupService();
    private errors: PebbleNotification[] = [];
    private materialGroupList: PebbleDropDown[] = [];
    private euEtsList: PebbleDropDown[] = [];
    private aggStateList: PebbleDropDown[] = [];
    private qualityList: PebbleDropDown[] = [];
    private homeContryList: PebbleDropDown[] = [];
    private mineList: PebbleDropDown[] = [];
    private dehstList: PebbleDropDown[] = [];
    private unitSettleList: PebbleDropDown[] = [];
    private saveButtonIsDisabled = true;

    /* LATE VARIABLES */
    private formValues!: IdentificationMaterialsForm;

    /* PRIMITIVE METHODS */
    private created(): void {
        this.formValues = identificationMaterialsForm(MaterialBaseFactory.createMaterialAddRequest());
    }

    private mounted(): void {
        this.$router.beforeResolve((to, from, next) => manageResolve(!this.saveButtonIsDisabled, next, this));
        this.init();
        this.saveButtonIsDisabled = shouldDisableForm(this.formValues);
    }

    private destroyed(): void {
        this.saveButtonIsDisabled = true;
    }

    /* METHODS */
    private async init(): Promise<void> {
        this.$store.commit('loading');
        const [materialsGroup, euEts, aggState, quality, homeCountry, mine, dehst, units] = await Promise.all([
            this.loadMaterialsGroup(),
            this.loadAssets(IdTypeSid.EU_ETS),
            this.loadAssets(IdTypeSid.AGG_STATE),
            this.loadAssets(IdTypeSid.QUALITY),
            this.loadAssets(IdTypeSid.HOME_COUNTRY),
            this.loadAssets(IdTypeSid.MINE),
            this.loadAssets(IdTypeSid.DEHST),
            this.loadAssets(IdTypeSid.UNITS),
        ]).finally(() => {
            this.$store.commit('loading');
        });
        this.materialGroupList = pebbleDropDownFromMaterialGroups(materialsGroup);
        this.euEtsList = pebbleDropDownFromAssets(euEts);
        this.aggStateList = pebbleDropDownFromAssets(aggState);
        this.qualityList = pebbleDropDownFromAssets(quality);
        this.homeContryList = pebbleDropDownFromAssets(homeCountry);
        this.mineList = pebbleDropDownFromAssets(mine);
        this.dehstList = pebbleDropDownFromAssets(dehst);
        this.unitSettleList = pebbleDropDownFromAssets(units);
    }

    private validate(value: string, name: string): void {
        isValidInput(name, this.formValues[name]);
        this.saveButtonIsDisabled = shouldDisableForm(this.formValues);
    }

    /* ON CLICK BUTTON */
    private onClickBackButton(): void {
        this.$router.push({
            name: `management-materials`,
        });
    }

    private onClickSaveButton(): void {
        this.saveData();
    }

    /* API CALSS */
    private async loadAssets(idTypeSid: number): Promise<Asset[]> {
        let assets: Asset[] = [];
        try {
            const {result: {items}} = await this.assetService.getByIdTypeSid(idTypeSid, {page: 1, size: 1000, term: ''});
            assets = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
        return assets;
    }

    private async loadMaterialsGroup(): Promise<MaterialGroupModel[]> {
        let materials: MaterialGroupModel[] = [];
        try {
            const {result: {items}} = await this.materialsGroupService.get({
                page: 1,
                size: 1000,
                term: '',
                sortDirection: '',
                sortColumn: '',
                EnumSuMaterialGroupSid: [],
                EnumMaterialTypeSid: [],
                includeDeleted: false,
            });
            materials = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
        return materials;
    }

    private async saveNewMaterial(model: MaterialBaseModel): Promise<boolean> {
        try {
            this.$store.commit('loading');
            await this.service.create(model);
            return true;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingMaterial');
            generateErrors(this.errors, error);
            return false;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveData(): Promise<void> {
        if (await this.saveNewMaterial(generateDataFromFormValues(this.formValues) as MaterialBaseModel)) {
            this.saveButtonIsDisabled = true;
            this.onClickBackButton();
        }
    }
}
</script>


