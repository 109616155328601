<template>
    <pui-grid-container class="pui-grid-container-fix-margin">
        <pui-grid-row>
            <master-data-management-header
                :title="$t('manageReservoirs')"
                :on-add-new="onClickAddNewButton"
            />
        </pui-grid-row>
        <pui-grid-row>
            <custom-table
                :columns-key="['reservoirName', 'reservoirSid', 'river', 'riverGroup', 'action']"
                :data="data"
                :heading="heading"
                :sortable-key="['reservoirName', 'reservoirSid', 'river', 'riverGroup']"
                :per-page="10"
                :per-page-values="[10, 50, 100]"
                enable-search
                enable-pagination
                enable-limit
                :custom-template="['action']"
            >
                <template v-slot:custom_action="{data}">
                    <pui-link
                        :title="$t('edit')"
                        icon="edit"
                        @click="onClickEditButton(data)"
                    />
                </template>
            </custom-table>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import MasterDataManagementHeader from '@/components/headers/master-data-management-header.vue';
import CustomTable from '@/components/table/custom-table.vue';
import {ReservoirService} from '@/services';
import {Reservoir, ReservoirManagementQueryModel} from '@/models';
import {EventBus} from '@/utils';

@Component({
    name: 'management-reservoirs',
    components: {CustomTable, MasterDataManagementHeader},
})
export default class ManagementReservoirs extends Vue {
    /* VARIABLES */
    private service: ReservoirService = new ReservoirService();
    private data: Reservoir[] = [];
    private filter: ReservoirManagementQueryModel = {
        page: 1,
        size: 100000,
        term: '',
        sortDirection: '',
        sortColumn: '',
    };

    get heading(): any {
        return {
            reservoirName: this.$t('reservoirName').toString(),
            reservoirSid: this.$t('sid').toString(),
            river: this.$t('river').toString(),
            riverGroup: this.$t('riverGroup').toString(),
            action: '',
        };
    }

    /* PRIMITIVE METHODS */
    private mounted(): void {
        this.init();
    }

    /* METHODS */
    private async init(): Promise<void> {
        this.data = await this.loadReservoirs();
    }

    /* ON CLICK BUTTONS */
    private onClickAddNewButton(): void {
        this.$router.push({
            name: 'management-reservoirs-new',
            query: {
                add: 'true',
            },
        });
    }

    private onClickEditButton(value: Reservoir): void {
        this.$router.push({
            name: 'management-reservoirs-edit',
            query: {
                edit: value.reservoirSid.toString(),
                name: value.reservoirName.toString(),
            },
        });
    }

    /* API CALLS*/
    private async loadReservoirs(): Promise<Reservoir[]> {
        this.$store.commit('loading');
        let reservoirs: Reservoir[] = [];
        try {
            reservoirs = (await this.service.get(this.filter)).result.items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingReservoirs');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
        return reservoirs;
    }
}
</script>


