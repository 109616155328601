import {
    ApiResponse, PagedResponse, ProcurementAddRequest, ProcurementEditRequest,
    ProcurementModel, ProcurementTimelineVersion,
} from '@/models';
import { store } from '@/store';
import { EventBus } from '@/utils';
import ErrorHandler from '@/utils/error-handler';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';

class ProcurementService {
    private api = new SdkGenericApiHelper('units');

    public async get(machineId: number): Promise<ApiResponse<PagedResponse<ProcurementModel>>> {
        return (await this.api.getByPathOrParam(`${machineId}/procurements`)).data;
    }

    public async create(procurementDetails: ProcurementAddRequest):
        Promise<ApiResponse<ProcurementModel>> {
        return (await this.api.post(procurementDetails, `${procurementDetails.machineId}/procurements`))
            .data;
    }

    public async getById(query: any): Promise<ApiResponse<ProcurementModel>> {
        return (await this.api.getByPathOrParam(`${query.machineId}/procurements/versions/${query.validFrom}`))
            .data;
    }

    public async edit(procurementDetails: ProcurementEditRequest, validFrom: string):
        Promise<ApiResponse<ProcurementModel>> {
        return (await this.api.put(`/${procurementDetails.machineId}/procurements/versions/${validFrom}`, procurementDetails))
            .data;
    }

    public async delete(query: any): Promise<ApiResponse<any>> {
        return (await this.api.delete(`/${query.machineId}/procurements/versions/${query.validFrom}`)).data;
    }

    public async getProcurementTimelines(machineId: number): Promise<ProcurementTimelineVersion[]> {
        try {
            store.commit('loading');
            return (await this.get(machineId))
                ?.result
                .items
                ?.map((item) => new ProcurementTimelineVersion(item)) ?? [];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingProcurementData');
            return [];
        } finally {
            store.commit('loading');
        }
    }

    public async addProcurementTimeline(procurementTimeline: ProcurementTimelineVersion): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.api.post(ProcurementTimelineVersion.mapToAddEditRequest(procurementTimeline),
                `${procurementTimeline.machineId}/procurements`);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorCreatingProcurement');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async updateProcurementTimeline(procurementTimeline: ProcurementTimelineVersion, initialValidFrom: string): Promise<[boolean, string | undefined]> {
        try {
            store.commit('loading');
            await this.api.put(`/${procurementTimeline.machineId}/procurements/versions/${initialValidFrom}`,
                ProcurementTimelineVersion.mapToAddEditRequest(procurementTimeline));
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return [true, undefined];
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorUpdatingProcurement');
            return [false, ErrorHandler.getAxiosErrorMessage(error)];
        } finally {
            store.commit('loading');
        }
    }

    public async deleteProcurementTimeline(procurementTimeline: ProcurementTimelineVersion): Promise<boolean> {
        try {
            store.commit('loading');
            await this.api.delete(`/${procurementTimeline.machineId}/procurements/versions/${procurementTimeline.validFrom}`);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            return true;
        } catch (error: any) {
            const errorMessage = ErrorHandler.getAxiosErrorMessage(error) ?? 'errorRemovingProcurement';
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, errorMessage);
            return false;
        } finally {
            store.commit('loading');
        }
    }
}

const procurementService = new ProcurementService();

export {
    ProcurementService,
    procurementService,
};
