import { DateHelper } from '@/utils/date-helper';

export class ProcurementTimelineVersion {
    public static mapToAddEditRequest(procurementTimeline: ProcurementTimelineVersion): any {
        return {
            validFrom: procurementTimeline?.validFrom,
            validTo: procurementTimeline?.validTo,
            machineId: procurementTimeline?.machineId,
            mainFuelLocationSid: procurementTimeline?.mainFuelLocationSid,
            cO2CertLocationSid: procurementTimeline?.cO2CertLocationSid,
            lastUpdateComment: procurementTimeline?.lastUpdateComment,
            isDeleted: procurementTimeline?.isDeleted ?? false,
        };
    }

    public machineId: number;
    public validFrom: string;
    public validTo: string;
    public mainFuelLocationSid: number;
    public cO2CertLocationSid: number;
    public mainFuelLocationName: string;
    public cO2CertLocationName: string;
    public lastUpdateComment: string;
    public isDeleted: boolean;
    public lastUpdatedBy: string;
    public lastUpdated: string;

    constructor(data?: any) {
        this.machineId = data?.machineId;
        this.validFrom = data?.validFrom ?? DateHelper.formatDate(new Date());
        this.validTo = data?.validTo;
        this.mainFuelLocationSid = data?.mainFuelLocationSid;
        this.cO2CertLocationSid = data?.cO2CertLocationSid;
        this.mainFuelLocationName = data?.mainFuelLocationName;
        this.cO2CertLocationName = data?.cO2CertLocationName;
        this.lastUpdateComment = data?.lastUpdateComment;
        this.isDeleted = data?.isDeleted;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdated = data?.lastUpdated;
    }
}
