import { TransformerForm } from '@/models/form/transformer-form'
import { DateHelper, MIN_DATE } from '@/utils'
import { isNumberAsString, validateIfNotEmpty, validateRequired, isNotEmpty } from '@/utils/utils'
import { TransformerGetVersionData } from '@/models/hydro-components/transformers-edit-data'

export const transformerForm = (data?: TransformerGetVersionData): TransformerForm => ({
    validFrom: {
        value: data?.validFrom ? DateHelper.formatDate(data.validFrom) : '',
        isValid: true,
        isDate: true,
        validator: (value: string) => new Date(value) > MIN_DATE,
    },
    validTo: {
        value: data?.validTo ? DateHelper.formatDate(data.validTo) : '',
        isValid: true,
        isDate: true,
        validator: validateIfNotEmpty((value: string) => new Date(value) > MIN_DATE),
    },
    componentId: {
        value: data?.transformerSid ?? '',
        isValid: true,
        validator: (value: string) => value.length > 0,
    },
    componentType: {
        value: data?.componentType ?? 'transformer',
        isValid: true,
    },
    powerPlant: {
        value: data?.plantSid ?? null,
        isValid: true,
        isDropDown: true,
        validator: isNotEmpty,
    },
    storagePowerPlant: {
        value: data?.storagePlantSid ?? null,
        isValid: true,
        isDropDown: true,
        validator: (value: number | null, context: TransformerForm) => context.isReserve.value ? isNotEmpty(value) : true
    },
    transformerName: {
        value: data?.transformerName ?? '',
        isValid: true,
        validator: (value: string) => value.length > 0,
    },
    functionalLocation: {
        value: data?.technicalLocation ?? '',
        isValid: true,
    },
    equipment: {
        value: data?.equipmentNumber ?? null,
        isValid: true,
        isDropDown: true,
    },
    manufacturer: {
        value: data?.manufacturerEnumSid ?? null,
        isValid: true,
        isDropDown: true,
    },
    transformerType: {
        value: data?.transformerTypeEnumSid ?? null,
        isValid: true,
        isDropDown: true,
    },
    constructionYear: {
        value: data?.constructionYear?.toString() ?? '',
        isValid: true,
        validator: validateRequired(isNumberAsString),
    },
    nominalPower: {
        value: data?.nominalPower?.toString() ?? '',
        isValid: true,
        validator: validateIfNotEmpty(isNumberAsString),
    },
    primaryVoltageOs: {
        value: data?.primaryVoltageOs?.toString() ?? '',
        isValid: true,
        validator: validateIfNotEmpty(isNumberAsString),
    },
    secondaryVoltageUs: {
        value: data?.secondaryVoltageOs?.toString() ?? '',
        isValid: true,
        validator: validateIfNotEmpty(isNumberAsString),
    },
    coolingSystem: {
        value: data?.coolingSystemEnumSid ?? null,
        isValid: true,
        isDropDown: true,
    },
    oilWeight: {
        value: data?.oilWeight?.toString() ?? '',
        isValid: true,
        validator: validateIfNotEmpty(isNumberAsString),
    },
    totalWeight: {
        value: data?.totalWeight?.toString() ?? '',
        isValid: true,
        validator: validateIfNotEmpty(isNumberAsString),
    },
    decommissioningDate: {
        value: data?.decommissioningDate ? DateHelper.formatDate(data.decommissioningDate) : '',
        isValid: true,
        isDate: true,
        validator: validateIfNotEmpty((value: string) => new Date(value) > MIN_DATE),
    },
    isDeleted: {
        value: data?.deleted ?? false,
        isValid: true,
    },
    isReserve: {
        value: data?.isReserve ?? false,
        isValid: true,
    }
})
