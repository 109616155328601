import {ReservoirAddRequest, ReservoirQueryModel} from '@/models';
import {ReservoirModel} from '@/models/reservoir';

export class ReservoirFactory {
    public static createReservoir(): ReservoirAddRequest {
        return {
            reservoirSid: 0,
            reservoirName: '',
            enumRiverSid: 0,
            enumRiverGroupSid: 0,
            eqvFactor: 0,
            lastUpdateComment: '',
            validFrom: '',
        };
    }

    public static createNewReservoir(): ReservoirAddRequest {
        return {
            reservoirSid: 0,
            reservoirName: '',
            enumRiverSid: -1,
            enumRiverGroupSid: -1,
            eqvFactor: 0,
            lastUpdateComment: '',
            validFrom: '',
        };
    }

    public static createReservoirVersion(reservoirId: number): ReservoirAddRequest {
        return {
            reservoirSid: reservoirId,
            reservoirName: '',
            enumRiverSid: 0,
            enumRiverGroupSid: 0,
            eqvFactor: 0,
            lastUpdateComment: '',
            validFrom: '',
        };
    }

    public static createNewReservoirVersion(reservoirSid: number): ReservoirAddRequest {
        return {
            reservoirSid,
            reservoirName: '',
            enumRiverSid: -1,
            enumRiverGroupSid: -1,
            eqvFactor: 0,
            lastUpdateComment: '',
            validFrom: '',
        };
    }

    public static reservoirQueryModelToReservoirModel(data: ReservoirQueryModel): ReservoirModel {
        return {
            enumRiverGroupSid: data.enumRiverGroupSid,
            enumRiverSid: data.enumRiverSid,
            eqvFactor: data.eqvFactor,
            reservoirName: data.reservoirName,
            reservoirSid: data.sid,
            validFrom: data.validFrom,
            validTo: data.validTo,
            lastUpdated: data.lastUpdated,
            lastUpdatedBy: data.lastUpdatedBy,
            lastUpdateComment: data.lastUpdateComment,
        };
    }
}
