<template>
    <div class="identification-container">
        <validation-observer ref="validationObserver">
            <div class="identification">
                <div v-if="data.materialGroupSid" class="flex-row manage-input mt-3">
                    <label class="mr-2 default-label" for="materialGroupSid">{{ $t('materialGroupSid') }}:</label>
                    <input class="default-input" v-model="data.materialGroupSid" :placeholder="$t('materialGroupSid')" id="etsUnitId" disabled />
                </div>
                <validation-provider
                    tag="div"
                    class="manage-input mt-3"
                    name="shortName"
                    rules="required"
                    #default="{ errors, failed }"
                >
                    <div class="flex-row manage-input mt-3">
                        <label class="mr-2 default-label" for="shortName">{{ $t('shortName') }}*:</label>
                        <input class="default-input" v-model="data.shortName" :placeholder="$t('shortName')" id="shortName" maxlength="20"
                            :class="{ 'invalid': failed }"
                        />
                    </div>
                    <error-list :errors="errors" />
                </validation-provider>
                <validation-provider
                    tag="div"
                    class="manage-input mt-3"
                    name="materialGroupName"
                    rules="required"
                    #default="{ errors, failed }"
                >
                    <div class="flex-row manage-input mt-3">
                        <label class="mr-2 default-label" for="materialGroupName">{{ $t('materialGroupName') }}*:</label>
                        <input class="default-input" v-model="data.materialGroupName" :placeholder="$t('materialGroupName')" id="materialGroupName" maxlength="50"
                            :class="{ 'invalid': failed }"
                        />
                    </div>
                    <error-list :errors="errors" />
                </validation-provider>
                <validation-provider
                    tag="div"
                    class="manage-input mt-3"
                    #default="{ errors, failed }"
                    name="enumSuMaterialGroup"
                    rules="required"
                >
                    <label class="mr-2 default-label" for="enumSuMaterialGroup">{{ $t('enumSuMaterialGroup') }}*:</label>
                    <select class="default-select" id="enumSuMaterialGroup" v-model="data.enumSuMaterialGroupSid"
                        :placeholder="$t('enumSuMaterialGroup')"
                        :class="{ 'invalid': failed }"
                    >
                        <option
                            v-for="material of enumSuMaterial"
                            :key="material.sid"
                            :value="material.sid"
                        >
                            {{ material.description }}
                        </option>
                    </select>
                    <error-list :errors="errors" />
                </validation-provider>
                <validation-provider
                    tag="div"
                    class="manage-input mt-3"
                    name="enumMaterialType"
                    rules="required"
                    #default="{ errors, failed }"
                >
                    <label class="mr-2 default-label" for="enumMaterialType">{{ $t('enumMaterialType') }}*:</label>
                    <select class="default-select" id="enumMaterialType" v-model="data.enumMaterialTypeSid" :placeholder="$t('enumMaterialType')" :class="{ 'invalid': failed }">
                        <option v-for="type of enumMaterialType"
                            :key="type.sid"
                            :value="type.sid"
                        >
                            {{ type.description }}
                        </option>
                    </select>
                    <error-list :errors="errors" />
                </validation-provider>
                <validation-provider
                    v-if="data.materialGroupSid"
                    tag="div"
                    class="manage-input mt-3"
                    name="comments"
                    rules="max:1000"
                    #default="{ errors, failed }"
                >
                    <label class="mr-2 default-label" for="lastUpdateComment">{{ $t('comments') }}:</label>
                        <textarea
                            class="default-textarea"
                            maxlength="1000"
                            v-model="data.lastUpdateComment"
                            :placeholder="$t('comments')"
                            id="lastUpdateComment"
                            :class="{ 'invalid': failed }"
                            rows="4"></textarea>
                    <error-list :errors="errors" />
                </validation-provider>
                <div class="flex-row mt-3">
                    <checkbox-input
                        v-model="data.isDeleted"
                        :label="$t('markAsDeleted')" />
                </div>
                <last-updated v-if="data.materialGroupSid" :data="data" />
                <error-list :errors="invalidMessages" />
            </div>
            <div class="members-footer">
                <adam-button
                    v-if="hasMasterDataAdmin"
                    class="ml-1"
                    @click="save"
                    secondary>
                    {{ $t('save') }}
                </adam-button>
            </div>
        </validation-observer>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Mixins, Ref } from 'vue-property-decorator';
import { EventBus, extractErrorsFromResponse, InfiniteScrollingHelper } from '@/utils';
import { Asset, IdTypeSid, MaterialGroupModel } from '@/models';
import { AssetService, MaterialGroupService } from '@/services';
import { ValidationObserver } from 'vee-validate';
import CheckboxInput from '@/components/checkbox-input/checkbox-input.vue';
import ErrorList from '@/components/error-list/error-list.vue';
import LastUpdated from '@/components/last-updated/last-updated.vue';
import ComponentSecurity from '@/mixins/component-security';

@Component({
    name: 'material-group-identification',
    components: {
        checkboxInput: CheckboxInput,
        errorList: ErrorList,
        lastUpdated: LastUpdated,
    },
})
export default class MaterialGroupIdentification extends Mixins(ComponentSecurity) {
    @Ref()
    private readonly validationObserver!: InstanceType<typeof ValidationObserver>;
    /*
     *  Material Group for which the identification tab from editing screen is displayed.
    */
    @Prop({ required: true })
    private data!: MaterialGroupModel;

    private materialsGroupService: MaterialGroupService = new MaterialGroupService();
    private assetService: AssetService = new AssetService();

    private formModel: MaterialGroupModel = { ...this.data };
    private materialsGroupList: MaterialGroupModel[] = [];
    private enumMaterialType: Asset[] = [];
    private enumSuMaterial: Asset[] = [];
    private errorList: string[] = [];
    private invalidMessages: string[] = [];
    private filterPage = {
        page: 1,
        size: 999,
        term: '',
    };
    private comments = '';

    public async beforeLeave(): Promise<boolean> {
        if (!this.validationObserver.flags.dirty) {
            return true;
        }

        try {
            await this.$dialog
                .confirm(
                    { body: this.$t('areYouSureLeaveUnsaved').toString() },
                    { view: 'confirm' });
            return true;
        } catch (error: any) {
            return Promise.resolve(false);
        }
    }

    private async mounted(): Promise<void> {
        EventBus.$on(EventBus.DETAIL.CLOSE, this.closeDetailsView);
        await this.getEnumSuMaterial();
        await this.getEnumMaterialType();
        this.validationObserver?.reset();
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.DETAIL.CLOSE);
    }

    private async getEnumSuMaterial(): Promise<void> {
        this.enumSuMaterial = (await this.assetService.
            getByIdTypeSid(IdTypeSid.ENUM_SU_MATERIAL_GROUP, this.filterPage)).result.items;
    }

    private async getEnumMaterialType(): Promise<void> {
        this.enumMaterialType = (await this.assetService.
            getByIdTypeSid(IdTypeSid.ENUM_MATERIAL_TYPE, this.filterPage)).result.items;
    }

    private checkInput(): void {
        this.errorList = [];
        this.invalidMessages = [];
    }

    private isInvalid(invalidKey: string): boolean {
        return this.errorList.indexOf(invalidKey) > -1;
    }

    private deleteInputFieldError(invalidKey: string): any {
        this.errorList = this.errorList.filter((error: string) => error !== invalidKey);
    }

    private async closeDetailsView(): Promise<void> {
        try {
            const canLeave = await this.beforeLeave();
            /**
             * Fired when the user wishes to exit the details view
             */
            if (canLeave) {
                this.$emit('close');
            }
        } catch (error: any) {
            // action canceled by user
        }
    }

    private async save(): Promise<void> {
        this.invalidMessages = [];
        const isValid = await this.validationObserver.validate();

        if (!isValid) {
            await this.$nextTick();
            InfiniteScrollingHelper.scrollToFirstInvalidElement('.invalid');
            return;
        }
        let message = 'errorAddingMaterialGroup';
        this.$store.commit('loading');
        this.formModel = {...this.data};
        try {
            if (this.formModel.materialGroupSid > 0) {
                message = 'errorEditingMaterialGroup';
                const { result } = await this.materialsGroupService.edit(this.formModel);
                this.formModel = { ...result };
                this.$emit('save', this.formModel, false, null);
            } else {
                const addedAsset = (await this.materialsGroupService.create(this.formModel)).result;
                this.$emit('save', addedAsset, true, null);
            }
            /**
             * @ignore
             */
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            this.validationObserver?.reset();
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, message);
            if (!err.response || !err.response.data) {
                throw err;
            }
            this.invalidMessages = extractErrorsFromResponse(err.response.data);
            if (this.invalidMessages.length) {
                this.$nextTick(() => {
                    InfiniteScrollingHelper.scrollToFirstInvalidElement('.error-list__message');
                });
            }
        } finally {
            this.$store.commit('loading');
        }
        this.data.lastUpdateComment = '';
    }
}

</script>

<style scoped lang="less">
@import "~@/variables.less";
.identification-container {
    .identification {
        label {
            margin-top: 1rem;
            min-width: fit-content;
        }
        input, textarea {
            width: 100%;
        }
    }
}
</style>
