<template>
    <div v-if="model">
    <pui-form-group
        :label="label"
        :isValid="model.isValid"
        :show-required-label="required"
    >
        <pui-form-input-field
            v-if="type !== 'number'"
            :type="type"
            v-model="model.value"
            :isValid="model.isValid"
            :placeholder-text="placeholder"
            :max-length="maxLength"
            :is-readonly="disable"
            @input="input"
            @blur="blur"
        />
        <pui-form-input-field
            v-else
            type="number"
            v-model.number="model.value"
            :isValid="model.isValid"
            :placeholder-text="placeholder"
            :max-length="maxLength"
            :is-readonly="disable"
            @input="input"
            @blur="blur"
        />
        <span slot="error-message">
            {{ $t('validation.required') }}
        </span>
    </pui-form-group>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {FormValuesModel} from '@/models/pebble/form-values-model';

@Component({
    name: 'pebble-text-field',
})
export default class PebbleTextField extends Vue {
    @Prop({required: true})
    private name!: string;

    @Prop({required: true})
    private model!: FormValuesModel;

    @Prop({required: true})
    private label!: string;

    @Prop({default: 'text'})
    private type!: string;

    @Prop({default: false})
    private required!: boolean;

    @Prop({default: false})
    private disable!: boolean;

    @Prop()
    private maxLength?: number;

    @Prop()
    private placeholder?: string;

    private input(val: string): void {
        this.$emit('on-input', val, this.name);
        this.$forceUpdate();
    }

    private blur(val: string): void {
        this.$emit('on-blur', val, this.name);
        this.$forceUpdate();
    }

}
</script>


