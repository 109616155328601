<template>
    <div class="error-list mb-1">
        <div
            v-for="(error, index) in errors"
            :key="index"
            class="error-list__message mt-1">
            {{ error }}
        </div>
    </div>
</template>

<script lang="ts">

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'error-list',
})
export default class ErrorList extends Vue {
    /*
     *  List of invalid rules that need to be corrected.
    */
    @Prop({ required: true })
    private errors!: string[];
}
</script>

<style scoped lang="less">
@import "~@/variables.less";
.error-list {
    &__message {
        color: @red-darker;
        font-size: 1.6rem;
    }
}
</style>
