import { DateHelper } from '@/utils/date-helper';
import { OperationStatus } from '@/models';
export class OperationStatusVersion {

    public static mapAddEditOperationStatus(operationStatus: OperationStatusVersion): OperationStatus {
        return {
            sid: operationStatus?.sid,
            unitSid: operationStatus?.unitSid,
            validFrom: operationStatus?.validFrom,
            validTo: operationStatus?.validTo,
            enumOpStatusSid: operationStatus?.enumOpStatusSid,
            expectedCessation: operationStatus?.expectedCessation,
            expectedChangeOs: operationStatus?.expectedChangeOs,
            enumChangeReasonSid: operationStatus?.enumChangeReasonSid,
            operationStatusName: operationStatus?.operationStatusName,
            lastUpdated: operationStatus.lastUpdated,
            lastUpdatedBy: operationStatus.lastUpdatedBy,
            lastUpdateComment: operationStatus?.lastUpdateComment,
        } as OperationStatus;
    }

    public sid: number;
    public unitSid: number;
    public validFrom: string;
    public validTo: string;
    public enumOpStatusSid: number;
    public expectedCessation?: string;
    public expectedChangeOs?: string;
    public enumChangeReasonSid?: number;
    public operationStatusName?: string;
    public lastUpdated?: string;
    public lastUpdatedBy?: string;
    public lastUpdateComment?: string;

    constructor(data?: any) {
        this.sid = data?.sid;
        this.unitSid = data?.unitSid;
        this.validFrom = data?.validFrom ?? DateHelper.formatDate(new Date());
        this.validTo = data?.validTo;
        this.enumOpStatusSid = data?.enumOpStatusSid;
        this.expectedCessation = data?.expectedCessation;
        this.expectedChangeOs = data?.expectedChangeOs;
        this.enumChangeReasonSid = data?.enumChangeReasonSid;
        this.operationStatusName = data?.operationStatusName;
        this.lastUpdated = data?.lastUpdated;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdateComment = data?.lastUpdateComment;
    }
}
