<template>
    <datepicker
        :value="innerValue"
        type="date"
        value-type="format"
        format="YYYY-MM-DD"
        :disabled-date="disabledDate"
        :disabled="disabled"
        @input="onInput"/>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import format from 'date-fns/format';
import add from 'date-fns/add';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { DATE_FORMAT } from '@/utils';

/**
 * Wrapper over vue2-datepicker to encapsulate logic of handling UTC dates
 */
@Component({
    name: 'md-date-picker',
    components: {
        datepicker: DatePicker,
    },
})
export default class MdDatePicker extends Vue {
    /**
     * Value of the datepicker, used for two way data binding
     */
    @Prop({ default: null })
    private value!: string | null;

    /**
     * Indicates whether the field can be interacted with.
     */
    @Prop({ default: false })
    private disabled!: boolean;

    /**
     * Callback function to determine whether some dates are disabled
     */
    @Prop()
    private disabledDate!: (date: Date) => boolean;

    private innerValue: string | null = this.value
        ? this.format(this.value)
        : null;

    @Watch('value')
    private onValueChange(newValue: string | null, oldValue: string | null) {
        if (newValue === oldValue) {
            return;
        }
        this.innerValue = newValue
            ? this.format(newValue)
            : null;
    }

    private onInput(value: Date) {
        const utcDateString = value
            ? format(new Date(value), DATE_FORMAT)
            : null;
        /**
         * Fired when user interacts with the datepicker
         *
         * @argument {string | null} utcDateString - null, or the ISO string
         * representation of the selection, UTC timezone
         */
        this.$emit('input', utcDateString);
    }

    private format(dateString: string, ignoreOffset = false): string {
        let date = new Date(dateString);
        if (ignoreOffset) {
            date = add(date, { minutes: date.getTimezoneOffset() });
        }
        return format(date, 'yyyy-MM-dd');
    }
}
</script>