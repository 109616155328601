import { ApiResponse, BusinessPartnersUnit, EtsUnit, ListResponse, RegionalUnit } from '@/models';
import { sdk } from '@/utils/sdk';

export class GenericService {
    public async getEtsUnits(): Promise<ApiResponse<ListResponse<EtsUnit>>> {
        return (await sdk?.backend.request.api.get<ApiResponse<ListResponse<EtsUnit>>>(`ets-sites?page=1&size=1000`)).data;
    }

    public async getRegionalUnits(idTypeSid: number): Promise<ApiResponse<ListResponse<RegionalUnit>>> {
        return (await sdk?.backend.request.api.get<ApiResponse<ListResponse<RegionalUnit>>>(`assets/enumerations/${idTypeSid}`)).data;
    }

    public async getBusinessPartners(): Promise<ApiResponse<ListResponse<BusinessPartnersUnit>>> {
        return (await sdk?.backend.request.api.get<ApiResponse<ListResponse<BusinessPartnersUnit>>>(`business-partners`)).data;
    }
}
