<template>
    <transition-group name="list" :tag="tag">
        <slot/>
    </transition-group>
</template>

<script lang="ts">

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class VerticalListTransition extends Vue {

    @Prop()
    private tag!: string;

    constructor() {
        super();
    }
}
</script>

<style lang="less">
.list-enter-active, .list-leave-active {
    transition: all 0.5s;
}
.list-enter, .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
