<template>
    <transition :name="fast ? 'fade-fast' : 'fade'" :appear="appear">
        <slot/>
    </transition>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class FadeTransition extends Vue {

    @Prop({default: false})
    private appear!: boolean;

    @Prop({default: false})
    private fast!: boolean;

    constructor() {
        super();
    }
}
</script>

<style lang="less">
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

.fade-fast-enter-active, .fade-fast-leave-active {
    transition: opacity 0.2s;
}
.fade-fast-enter, .fade-fast-leave-to {
    opacity: 0;
}
</style>
