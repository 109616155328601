import { DateHelper } from '@/utils/date-helper';

export class EtsSiteVersion {
    public unitSid: number;
    public validFrom: string;
    public validTo: string;
    public etsSiteName: string;
    public etsSiteSid: number;
    public lastUpdated?: string;
    public lastUpdatedBy?: string;
    public lastUpdateComment?: string;

    constructor(data?: any) {
        this.unitSid = data?.unitSid;
        this.validFrom = data?.validFrom ?? DateHelper.formatDate(new Date());
        this.validTo = data?.validTo;
        this.etsSiteName = data?.etsSiteName;
        this.etsSiteSid = data?.etsSiteSid;
        this.lastUpdated = data?.lastUpdated;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdateComment = data?.lastUpdateComment;
    }
}
