import { VersioningBase } from '@/models';
import { DateHelper } from '.';

/**
 * Static method for getting the default version from the tabel
 * The default version is the version that has today between start date and end date
 * @name isNowBetween
 * @param {T} list of generic items
 * @return {T | undefined} depending on the result of the method
 */
export function findDefaultVersion<T extends VersioningBase>(list: T[]): T | undefined {
    return list.find(({ validFrom, validTo}) => {
        return validFrom
            && validTo
            && DateHelper.isNowBetween(new Date(validFrom), new Date(validTo));
    });
}
