import { ApiResponse, SearchPaginationQueryModel, EtsUnit, PagedResponse, EtsUnitEditRequest} from '@/models';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
import { generateQuery } from '@/utils/api-helper';

export class ETSService {
    private api = new SdkGenericApiHelper('ets-sites');

    public async create(etsUnit: EtsUnit): Promise<ApiResponse<EtsUnit>> {
        return (await this.api.post(etsUnit)).data;
    }

    public async get(filter: SearchPaginationQueryModel): Promise<ApiResponse<PagedResponse<EtsUnit>>> {
        const query = generateQuery(filter as any);
        return (await this.api.get(query)).data;
    }

    public async getById(sid: number): Promise<ApiResponse<EtsUnit>> {
        return (await this.api.getByPathOrParam(sid)).data;
    }

    public async edit(etsUnit: EtsUnitEditRequest): Promise<ApiResponse<EtsUnit>> {
        return (await this.api.put(etsUnit.etsId, etsUnit)).data;
    }

    public async delete(etsId: number): Promise<ApiResponse<any>> {
        return (await this.api.delete(etsId)).data;
    }
}
