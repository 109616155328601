import { ErrorResponse } from '@/models';

export default class MessageHandler {
    public static getProxyErrorMessage(error: ErrorResponse): string | undefined {
        if (error.statusCode === 400) {
            const errorMessages =  error?.errors?.join('\r\n');
            return errorMessages;
        } else {
            return error?.message;
        }
    }

    public static getAxiosErrorMessage({ response }: any): string | undefined {
        const errorData = response?.data;
        if (response?.data?.statusCode === 500) {
            return undefined;
        } else if (response?.data?.statusCode === 400) {
            return errorData?.errors
                ? errorData?.errors?.join('\r\n')
                : errorData?.message;
        }
    }

}
