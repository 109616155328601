<template>
    <div class="flex-container pb-3">
        <div class="flex-row flex-1 tab-header">
            <div class="flex-col flex-8">
                <div class="flex-row tabs">
                    <div v-for="(tab, tabIndex) in viewTabs"
                        :key="tabIndex"
                        class="flex-col tab-container"
                        @click="selectTab(tab)">
                        <span class="tab"
                            :class="{'selected': activeTab.name === tab.name}">
                            {{ $t(`${tab.name}`) }}
                        </span>
                    </div>
                    <div v-if="moreTabs.length > 0"
                        class="more-tabs-container"
                        v-click-outside="closeMoreItems">
                        <button class="default-button show-more-button ml-2"
                            :class="{'selected': shouldShowMore}"
                            @click="showMoreItems()">
                            <span class="mr-1">{{ $t('more') }}</span>
                            <adam-icon
                                icon="ic-nav-breadcrumbs"
                                :class="['tabs__chevron', {'tabs__chevron--rotate': shouldShowMore}]" />
                        </button>
                        <div v-if="shouldShowMore"
                            class="vertical-tabs-container">
                            <div v-for="(tab, tabIndex) of moreTabs"
                                :key="tabIndex"
                                class="vertical-tab-container"
                                @click="addTabToView(tab)">
                                    {{ $t(`${tab.name}`) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-col flex-1">
                <div class="flex-row reverse">
                    <adam-button
                        secondary
                        icon="ic-close-panel"
                        class="ml-1 mr-2"
                        @click="closeDetails">
                        {{ $t('close') }}
                    </adam-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { GenericTab } from '@/models';
import { Vue, Component, Prop } from 'vue-property-decorator';
import clickOutside from '@/directives/click-outside';
import { EventBus } from '@/utils';

@Component({
    name: 'tabs-headers',
    directives: {
        clickOutside,
    },
})
export default class TabHeaders extends Vue {
    /*
    ** Recieves the tabs for each entity.
    */
    @Prop()
    private tabs!: GenericTab[];

    /*
    ** Recieves the active tab for further processing.
    */
    @Prop()
    private activeTab!: GenericTab;

    private viewTabs: GenericTab[] = [];
    private moreTabs: GenericTab[] = [];
    private shouldShowMore = false;

    private mounted(): void {
        if (this.tabs.length > 6) {
            this.viewTabs = this.tabs.slice(0, 4);
            this.moreTabs = this.tabs.slice(4);
        } else {
            this.viewTabs = [...this.tabs];
        }
    }

    private selectTab(tab: GenericTab): void {
        if (tab !== this.activeTab) {
            this.$emit('select-tab', tab);
        }
    }

    private addTabToView(tab: GenericTab): void {
        const removedTabs = this.viewTabs.splice(0, 1);
        this.viewTabs.push(tab);
        this.moreTabs.splice(this.moreTabs.indexOf(tab), 1);
        if (removedTabs.length > 0) {
            this.moreTabs.push(removedTabs[0]);
        }
        this.selectTab(tab);
        this.closeMoreItems();
    }

    private showMoreItems(): void {
        this.shouldShowMore = !this.shouldShowMore;
    }

    private closeMoreItems(): void {
        this.shouldShowMore = false;
    }

    private closeDetails(): void {
        EventBus.$emit(EventBus.DETAIL.CLOSE);
    }
}
</script>

<style scoped lang="less">
@import "~@/variables.less";
.tab-header{
    border-bottom: 1px solid @grey-lighter;
}
.close-btn {
    width: 15rem;
    color: grey;
    font-size: 1.5rem;
    border: 1px solid grey;
    border-radius: 0.6rem;
}
.tabs {
    font-family: Roboto, sans-serif;
    width: 100%;
    font-size: 1.8rem;
    height: auto;
    transition: 0.2s ease-in;
    position: relative;

    .tab-container {
        width: auto;
        text-align: center;
        cursor: pointer;
        .tab {
            display: inline-block;
            height: 100%;
            padding: 1rem 2rem;
            box-sizing: border-box;
            &.selected {
                transition: 0.2s ease-in;
                color: @uniper-blue;
                border-bottom: 0.5rem solid @uniper-blue;
                font-family: Roboto-Medium, sans-serif;
            }
        }
    }
    .more-tabs-container {
        position: relative;

        .vertical-tabs-container {
            position: absolute;
            top: 6rem;
            right: 0;
            box-shadow: 0 0 3px 0 rgba(0,0,0,.19);
            border-radius: @border-radius-standard;
            background: white;
            z-index: 2;
            .vertical-tab-container {
                min-width: max-content;
                padding: 1rem 2rem;
                cursor: pointer;
                &:hover {
                    color: white;
                    background: @uniper-blue;
                }
            }
        }
    }

    &__chevron {
        width: 0.8rem;
        transition: 0.2s ease-in;
        transform: rotate(90deg);

        &--rotate {
            transform: rotate(270deg);
        }
    }

    .show-more-button {
        font-size: 1.8rem;
        padding: 1.5rem 3rem;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        @media(max-width: 1500px) {
            font-size: 1.6rem;
        }
        &:hover, &.selected {
            box-shadow: 0 0 3px 0 rgba(0,0,0,.19);
            border-radius: @border-radius-standard;
        }
    }

    @media(max-width: 1500px) {
        font-size: 1.8rem;
    }
}
</style>
