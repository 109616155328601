<template>
    <pui-grid-container class="pui-grid-container-fix-margin">
        <pui-grid-row>
            <master-data-management-header
                :title="$t('manageMaterialBase')"
                :filters="filters"
                :on-add-new="onClickAddNewButton"
                :on-check="onClickCheckBox"
                :on-filter-change="onFilterChange"
                :deleted-initial-value="filterResult.showDeleted"
            />
        </pui-grid-row>
        <pui-grid-row>
            <custom-table
                :columns-key="['materialName','materialSid','shortName', 'materialGroupName', 'euEts', 'dehst', 'lastUpdated', 'lastUpdatedBy', 'isDeleted', 'action']"
                :data="data"
                :heading="heading"
                :sortable-key="['materialName','materialSid','shortName', 'materialGroupName', 'euEts', 'dehst', 'lastUpdated', 'lastUpdatedBy', 'isDeleted']"
                :per-page="10"
                :per-page-values="[10, 50, 100]"
                enable-search
                enable-pagination
                enable-limit
                :custom-template="['isDeleted', 'action']"
            >
                <template v-slot:custom_isDeleted="{data}">
                    <boolean-text
                        :titles="{
                            textTrue: $t('yes'),
                            textFalse: $t('no'),
                        }"
                        :value="data"
                    />
                </template>
                <template v-slot:custom_action="{data}">
                    <pui-link
                        :title="$t('edit')"
                        icon="edit"
                        @click="onClickEditButton(data)"
                    />
                </template>
            </custom-table>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import MasterDataManagementHeader from '@/components/headers/master-data-management-header.vue';
import {filterService, MaterialBaseService} from '@/services';
import {Filter} from '@/models/custom-filter/filter';
import CustomTable from '@/components/table/custom-table.vue';
import BooleanText from '@/components/text/boolean-text.vue';
import {FilterRequestFieldName, MaterialBaseModel} from '@/models';
import {EventBus} from '@/utils';
import {MaterialsQueryModel} from '@/models/filter';
import {CustomObject} from '@/models/custom-object';

@Component({
    name: 'management-materials',
    components: {
        BooleanText,
        CustomTable,
        MasterDataManagementHeader,
    },
})
export default class ManagementMaterials extends Vue {
    /* VARIABLES */
    private service: MaterialBaseService = new MaterialBaseService();
    private filters: Filter[] = [];
    private data: MaterialBaseModel[] = [];
    private filterResult = {
        showDeleted: false,
        aggState: [],
        dehst: [],
        euEts: [],
    };
    private filter: MaterialsQueryModel = {
        AggStateSid: [],
        EuEtsSid: [],
        DehstSid: [],
        includeDeleted: false,
        page: 1,
        size: 100000,
        term: '',
        sortDirection: '',
        sortColumn: '',
    };

    get heading(): any {
        return {
            materialName: this.$t('materialName').toString(),
            materialSid: this.$t('sid').toString(),
            shortName: this.$t('shortName').toString(),
            materialGroupName: this.$t('materialGroup').toString(),
            euEts: this.$t('euEts').toString(),
            dehst: this.$t('dehst').toString(),
            lastUpdated: this.$t('lastUpdated').toString(),
            lastUpdatedBy: this.$t('lastUpdatedBy').toString(),
            isDeleted: this.$t('deleted').toString(),
            action: '',
        };
    }

    /* PRIMITIVE METHODS */
    private mounted() {
        this.filterResult.showDeleted = this.$router.currentRoute.query['deleted'] === 'true' ?? false;
        this.init();
        this.initFilters();
    }

    /* METHODS */
    private async initFilters(): Promise<void> {
        const filterGroup = await filterService.getNewMaterialsFilter();
        this.filters = [];
        filterGroup.forEach((value) => {
            const options = value.options?.filter((element) => {
                return element.name !== undefined;
            }).map((element) => {
                return {
                    value: element.value,
                    displayName: element.name,
                };
            }) ?? [];
            this.filters.push({
                name: value.requestFieldName,
                displayName: value.displayName,
                type: 'multiselect',
                isExpandable: true,
                isExpanded: true,
                appliedValues: {
                    options: [],
                },
                selectedValues: {
                    options: [],
                },
                config: {
                    hasSearchInput: true,
                    searchInputPlaceholder: this.$t(value.requestFieldName).toString(),
                    options,
                },
            });
        });
    }

    private async init(): Promise<void> {
        this.data = await this.loadMaterials();
    }

    /* ON CLICK BUTTONS */
    private async onClickCheckBox(value: boolean): Promise<void> {
        this.filterResult.showDeleted = value;
        await this.$router.replace({
            path: this.$router.currentRoute.path,
            params: this.$router.currentRoute.params,
            query: {
                deleted: this.filterResult.showDeleted.toString(),
                t: new Date().getTime().toString(),
            },
        });
        await this.init();
    }

    private onFilterChange(value: CustomObject): void {
        this.filterResult = {
            aggState: value[FilterRequestFieldName.AGG_STATE],
            euEts: value[FilterRequestFieldName.EU_ETS],
            dehst: value[FilterRequestFieldName.DEHST],
            showDeleted: this.filterResult.showDeleted,
        };
        this.init();
    }

    private onClickAddNewButton(): void {
        this.$router.push({
            name: 'management-materials-new',
            query: {
                add: 'true',
            },
        });
    }

    private onClickEditButton(value: MaterialBaseModel): void {
        this.$router.push({
            name: 'management-materials-edit',
            query: {
                edit: value.materialSid.toString(),
                name: value.shortName.toString(),
                tab: '0',
            },
        });
    }

    /* API CALLS */
    private async loadMaterials(): Promise<MaterialBaseModel[]> {
        this.$store.commit('loading');
        let materials: MaterialBaseModel[] = [];
        try {
            this.filter.AggStateSid = this.filterResult.aggState;
            this.filter.DehstSid = this.filterResult.dehst;
            this.filter.EuEtsSid = this.filterResult.euEts;
            this.filter.includeDeleted = this.filterResult.showDeleted;
            const {result: {items}} = (await this.service.get(this.filter));
            materials = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingMaterials');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
        return materials;
    }
}
</script>


