<template>
    <pui-grid-container class="pui-grid-container-zero-padding">
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols" v-if="isEdit">
                <pebble-text-field
                    :label="$t('etsId')"
                    :model="data.etsId"
                    name="etsId"
                    disable
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('shortName')"
                    :model="data.shortName"
                    name="shortName"
                    required
                    :max-length="60"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('name')"
                    :model="data.name"
                    name="name"
                    required
                    :max-length="120"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('referenceNumber')"
                    :model="data.referenceNumber"
                    name="referenceNumber"
                    :max-length="60"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('registerName')"
                    :model="data.registerName"
                    name="registerName"
                    :max-length="120"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="fullCols" v-if="isEdit">
                <pebble-text-field-area
                    :label="$t('comments')"
                    :model="data.lastUpdateComment"
                    name="lastUpdateComment"
                    :max-length="1000"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols" class="pui-grid-col--bottom">
                <pebble-check-box
                    :label="$t('markAsDeleted')"
                    :model="data.isDeleted"
                    name="isDeleted"
                    @on-change="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import PebbleTextField from '@/components/pebble-form-element/pebble-text-field.vue';
import {Prop} from 'vue-property-decorator';
import PebbleTextFieldArea from '@/components/pebble-form-element/pebble-text-field-area.vue';
import PebbleCheckBox from '@/components/pebble-form-element/pebble-check-box.vue';
import {IdentificationEtsUnitForm} from '@/models/form/identification-ets-unit-form';
import {PebbleValidate} from '@/mixins/pebble-validate';
import {PebbleColConst} from '@/mixins/pebble-col-const';

@Component({
    name: 'ets-unit-identification-form',
    components: {PebbleCheckBox, PebbleTextFieldArea, PebbleTextField},
})
export default class EtsUnitIdentificationForm extends mixins(PebbleValidate, PebbleColConst) {
    @Prop({required: true})
    private data!: IdentificationEtsUnitForm;

    @Prop({default: true})
    private isEdit!: boolean;
}
</script>


