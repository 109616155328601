export class InfiniteScrollingHelper {
    /**
     * Static method for triggering a method when the scroll reaches the end
     * of the page
     * @name calculateScroll
     * @param {HTMLElement} aray an non-selectable array
     * @param {Function} an updated selectable array
     * @return {void}
     */
    public static calculateScroll(
        element: HTMLElement | null,
        createMethod: () => void,
        offset = 0): void {
        if (!element) {
            return;
        }
        const scrollTop = element.scrollTop;
        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;

        if ((scrollHeight === clientHeight && scrollTop === 0) ||
            (clientHeight + scrollTop >= scrollHeight - offset)) {
            createMethod();
        }
    }

    /**
     * Static method for scrolling to the first invalid element of a edit screen page
     * @name scrollToFirstInvalidElement
     * @param {string} invalidClass the invalid class name we are searching for
     */
    public static scrollToFirstInvalidElement(invalidClass: string): void {
        const invalidElement = document.querySelector(invalidClass);
        if (!invalidElement) {
            return;
        }

        invalidElement.classList.forEach((cls: string) => {
            if (cls === 'mx-datepicker') {
                const mxInput = document.querySelector('.mx-input-wrapper');
                if (mxInput) {
                    (mxInput as HTMLElement).focus();
                    return;
                }
            }
        });

        (invalidElement as HTMLElement).scrollIntoView();
    }
}
