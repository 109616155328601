import { IdTypeSid, ManagementOption } from '@/models';

export const MANAGEMENT_OPTIONS: ManagementOption[] = [
    { name: 'manageEnums', idTypeSid: null, icon: '',
        componentName: 'management-enum-types' },
    { name: 'manageOperationModes', idTypeSid: null, icon: '',
        componentName: 'management-operation-modes' },
    { name: 'manageBusinessPartners', idTypeSid: null, icon: '',
        componentName: 'management-business-partners' },
    { name: 'manageEtsUnits', idTypeSid: null, icon: '',
        componentName: 'management-ets-unit' },
    { name: 'manageMaterialBase', idTypeSid: null, icon: '',
        componentName: 'management-materials' },
    { name: 'manageMaterialGroup', idTypeSid: null, icon: '',
        componentName: 'management-material-group' },
    { name: 'manageReservoirs', idTypeSid: null, icon: '',
        componentName: 'management-reservoirs' },
];
export const MANAGEMENT_OPTIONS_OLD: ManagementOption[] = [
    { name: 'manageEnums', idTypeSid: null, icon: '',
        componentName: 'enum-management' },
    { name: 'manageOperationModes', idTypeSid: null, icon: '',
        componentName: 'operation-modes-management' },
    { name: 'manageBusinessPartners', idTypeSid: null, icon: '',
        componentName: 'business-partners-management' },
    { name: 'manageEtsUnits', idTypeSid: null, icon: '',
        componentName: 'ets-units-management' },
    { name: 'manageMaterialBase', idTypeSid: null, icon: '',
        componentName: 'material-base-management' },
    { name: 'manageMaterialGroup', idTypeSid: null, icon: '',
        componentName: 'material-group-management' },
    { name: 'manageReservoirs', idTypeSid: null, icon: '',
        componentName: 'reservoirs-management' },
];

export const ENUM_MANAGEMENT_OPTIONS: ManagementOption[] = [
    { name: 'manageTimeZones', idTypeSid: IdTypeSid.TIME_ZONE, icon: 'ic-module',
        componentName: 'enumManagement' },
    { name: 'manageUnitTypes', idTypeSid: IdTypeSid.UNIT_TYPE, icon: 'ic-unit-blue',
        componentName: 'enumManagement' },
    { name: 'manageOperationStatusTypes', idTypeSid: IdTypeSid.OPERATION_STATUS_TYPE, icon: 'ic-unit-blue',
        componentName: 'enumManagement' },
    { name: 'manageOperationStatusChangeReason', idTypeSid: IdTypeSid.OPERATION_STATUS_CHANGE_REASON,
        icon: 'ic-unit-blue', componentName: 'enumManagement' },
    { name: 'manageStates', idTypeSid: IdTypeSid.STATE, icon: 'ic-module',
        componentName: 'enumManagement' },
    { name: 'manageOperationModeTypes', idTypeSid: IdTypeSid.OPERATION_MODE_TYPE, icon: 'ic-unit-blue',
        componentName: 'enumManagement' },
    { name: 'manageFleets', idTypeSid: IdTypeSid.FLEET, icon: 'ic-fuel',
        componentName: 'enumManagement' },
    { name: 'managePowerPlantTypes', idTypeSid: IdTypeSid.POWER_PLANT_TYPE, icon: 'ic-group',
        componentName: 'enumManagement' },
    { name: 'managePlantGroups', idTypeSid: IdTypeSid.PLANT_GROUP, icon: 'ic-group',
        componentName: 'enumManagement' },
    { name: 'manageCountries', idTypeSid: IdTypeSid.COUNTRY, icon: 'ic-module',
        componentName: 'enumManagement' },
    { name: 'manageMbsTypes', idTypeSid: IdTypeSid.MSB_TYPE, icon: 'ic-unit-blue',
        componentName: 'enumManagement' },
    { name: 'manageEquipmentClassTypes', idTypeSid: IdTypeSid.EQUIPMENT_CLASS_TYPE, icon: 'ic-unit-blue',
        componentName: 'enumManagement' },
    { name: 'manageOrganizationList', idTypeSid: IdTypeSid.ORGANIZATION_LIST, icon: 'ic-company',
        componentName: 'enumManagement' },
    { name: 'manageOperationModeEnergyTypes', idTypeSid: IdTypeSid.OPERATION_MODE_ENERGY_TYPE, icon: 'ic-unit-blue',
        componentName: 'enumManagement' },
    { name: 'manageRegions', idTypeSid: IdTypeSid.REGION, icon: 'ic-module',
        componentName: 'enumManagement' },
    { name: 'manageSapEcTypes', idTypeSid: IdTypeSid.SAP_EC_TYPE, icon: 'ic-unit-blue',
        componentName: 'enumManagement' },
];
