export enum EnumLocationType {
    RealPowerPlant = 4001,
    VirtualPowerPlant = 24678,
    PumpStation = 23116,
    Weir = 28896,
}

export const HydroComponentsLocationTypes = [
    EnumLocationType.RealPowerPlant,
    EnumLocationType.VirtualPowerPlant,
    EnumLocationType.PumpStation,
    EnumLocationType.Weir
] as const;
