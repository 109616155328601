<template>
    <adam-card
        class="md-card">
        <div slot>
            <div class="flex-row full-width">
                <div class="properties flex-row">
                    <div class="flex-col name border-right mr-2 pr-2 center-vertically">
                        <div class="flex-row">
                            <div class="flex-col">
                                <adam-icon v-if="icon"
                                    :icon="icon"
                                    class="md-card__icon mr-2" />
                            </div>
                            <div class="flex-col flex-1 name-tag ml-2">
                                <b class="md-card__title">{{ name }}</b>
                            </div>
                        </div>
                    </div>
                    <div class="flex-col sid border-right mr-2 pr-2 center-vertically">
                        <div>
                            <b>{{ sid }}</b>
                        </div>
                    </div>
                    <slot name="card-properties"></slot>
                </div>
                <div class="actions flex-row"><slot name="action-buttons"></slot></div>
            </div>
        </div>
    </adam-card>
</template>

<script lang="ts">

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'md-card',
})
export default class MdCard extends Vue {
    /*
     * Icon for the card which will be displayed.
    */
    @Prop({ required: true })
    private icon!: string;

    /*
     * Name of the card which will be displayed.
    */
    @Prop({ required: true })
    private name!: string;

    /*
     * Sid of the card which will be displayed.
    */
    @Prop({ required: true })
    private sid!: number;
}

</script>

<style lang="less">
@import "~@/variables.less";

.md-card {
    --card-padding: 1.5rem 1rem;
    --card-header-margin: 0;
    --card-content-padding: 0;
    --card-header-height: 0;
    &__icon {
        width: 2.6rem;
        height: 2.6rem;
    }
    &__title {
        font-size: 1.7rem;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .properties{
        width: 95%;
    }
    .name-tag {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
</style>
