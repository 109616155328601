import { DateHelper } from '@/utils/date-helper';
import { ObjectDistributionType } from './object-distribution-type';
import { ObjectDistributionEnergyMarketing } from './object-distribution-energy-marketing';

export class ObjectDistributionVersion {
    public static mapToAddEditRequest(objectDistribution: ObjectDistributionVersion): any {
        return {
            validFrom: objectDistribution?.validFrom,
            validTo: objectDistribution?.validTo,
            distributionChannelId: objectDistribution?.distributionChannelId,
            machineId: objectDistribution?.machineId,
            lastUpdateComment: objectDistribution?.lastUpdateComment,
            isDeleted: objectDistribution?.isDeleted ?? false,
            distributionTypes: objectDistribution?.distributionTypes?.map((item) => item),
            energyMarketings: objectDistribution?.energyMarketings?.map((item) => item),
        };
    }

    public distributionChannelId: number;
    public machineId: number;
    public validFrom: string;
    public validTo: string;
    public isDeleted: boolean;
    public lastUpdateComment: string;
    public lastUpdatedBy: string;
    public lastUpdated: string;
    public distributionTypes: ObjectDistributionType[];
    public energyMarketings: ObjectDistributionEnergyMarketing[];

    constructor(data?: any) {
        this.distributionChannelId = data?.distributionChannelId;
        this.machineId = data?.machineId;
        this.validFrom = data?.validFrom ?? DateHelper.formatDate(new Date());
        this.validTo = data?.validTo;
        this.isDeleted = data?.isDeleted;
        this.lastUpdateComment = data?.lastUpdateComment;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdated = data?.lastUpdated;
        this.distributionTypes = data?.distributionTypes?.map((distributionType: any) =>
            new ObjectDistributionType(distributionType));
        this.energyMarketings = data?.energyMarketings?.map((energyMarketing: any) =>
            new ObjectDistributionEnergyMarketing(energyMarketing));
    }
}
