import { ApiResponse, MaterialBaseModel, PagedResponse, MaterialEditRequest } from '@/models';
import { MaterialsQueryModel } from '@/models/filter';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
import { generateQuery } from '@/utils/api-helper';

export class MaterialBaseService {
    private api = new SdkGenericApiHelper('materials');

    public async create(material: MaterialBaseModel): Promise<ApiResponse<MaterialBaseModel>> {
        return (await this.api.post(material)).data;
    }

    public async get(filter: MaterialsQueryModel): Promise<ApiResponse<PagedResponse<MaterialBaseModel>>> {
        const query = generateQuery(filter as any);
        return (await this.api.get(query)).data;
    }

    public async getById(sid: number): Promise<ApiResponse<MaterialBaseModel>> {
        return (await this.api.getByPathOrParam(sid)).data;
    }

    public async edit(material: MaterialEditRequest): Promise<ApiResponse<MaterialBaseModel>> {
        return (await this.api.put(material.materialSid, material)).data;
    }

    public async delete(materialSid: number): Promise<ApiResponse<any>> {
        return (await this.api.delete(materialSid)).data;
    }
}
