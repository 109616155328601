<template>
    <pui-grid-container>
        <pui-grid-row class="pui-grid-row-little-margin-top">
            <custom-table
                :columns-key="['validFrom', 'validTo', 'lastUpdated', 'lastUpdatedBy', 'isActive', 'isStdOperationMode','isDeleted', 'action']"
                :sortable-key="['validFrom', 'validTo', 'lastUpdated', 'lastUpdatedBy', 'isActive', 'isStdOperationMode','isDeleted']"
                :custom-template="['isActive','isStdOperationMode', 'isDeleted', 'action']"
                :data="versions"
                :per-page="5"
                :heading="heading"
                :selected="versionIsSelected"
                enable-pagination
            >
                <template v-slot:custom_isActive="{data}">
                    <boolean-text
                        :titles="{
                                textTrue: $t('active'),
                                textFalse: $t('inactive'),
                            }"
                        :value="data"
                    />
                </template>
                <template v-slot:custom_isStdOperationMode="{data}">
                    <boolean-text
                        :titles="{
                                textTrue: $t('yes'),
                                textFalse: $t('no'),
                            }"
                        :value="data"
                    />
                </template>
                <template v-slot:custom_isDeleted="{data}">
                    <boolean-text
                        :titles="{
                                textTrue: $t('yes'),
                                textFalse: $t('no'),
                            }"
                        :value="data"
                    />
                </template>
                <template v-slot:custom_action="{data}">
                    <pui-grid-container class="pui-grid-container-zero-padding">
                        <pui-grid-row class="pui-grid-row--around">
                            <pui-link
                                :title="hasMasterDataAdmin ? $t('edit') : $t('viewDetails')"
                                :icon="hasMasterDataAdmin ? 'edit' : 'circle-input'"
                                :disable="check.addNewVersion || versionIsSelected(data)"
                                :variant="!check.addNewVersion && !versionIsSelected(data) ? 'primary' : 'secondary'"
                                @click="() => !check.addNewVersion || versionIsSelected(data) ? onClickEditButton(data) : null"
                                :class="check.addNewVersion || versionIsSelected(data) ? 'disable-pui-button': ''"
                            />
                            <pui-link
                                v-if="hasMasterDataAdmin"
                                :title="$t('remove')"
                                icon="delete"
                                :disable="check.addNewVersion"
                                :variant="!check.addNewVersion ? 'primary' : 'secondary'"
                                @click="() => !check.addNewVersion ? onClickDeleteButton(data) : null"
                                :class="check.addNewVersion ? 'disable-pui-button': ''"
                            />
                        </pui-grid-row>
                    </pui-grid-container>
                </template>
            </custom-table>
        </pui-grid-row>
        <pui-grid-row class="pui-grid-row--end pui-grid-row-little-margin-top">
            <pui-button
                icon="add"
                state="secondary"
                @click="onClickAddNewVersionButton"
                v-if="!check.addNewVersion && hasMasterDataAdmin"
            >
                {{ $t('addNewVersion') }}
            </pui-button>
        </pui-grid-row>
        <pui-grid-row
            :class="errors.length > 0 ? 'pebble-errors-list-margin-top' : ''"
        >
            <pebble-errors-list
                id="errors-list"
                :errors="errors"
            />
        </pui-grid-row>
        <pui-form
            aria-label="Form"
            v-if="check.dataLoaded"
        >
            <pui-grid-row>
                <pui-grid-column class="pui-grid-column-zero-padding">
                    <operation-modes-base-data-form
                        :disabled="!hasMasterDataAdmin"
                        :data="formValues"
                        :selected-version="selectedVersion"
                        :operation-mode-types="operationModeTypes"
                        :inclusion-method-list="inclusionMethodList"
                        :operation-mode-energy-types="operationModeEnergyTypes"
                        :adding-new-version="check.addNewVersion"
                        @validate="validate"
                    />
                </pui-grid-column>
            </pui-grid-row>
            <pui-grid-row>
                <pui-grid-column class="pui-grid-column-zero-padding">
                    <operation-modes-performance-data-form
                        :disabled="!hasMasterDataAdmin"
                        :data="formValues"
                        :selected-version="selectedVersion"
                        @validate="validate"
                    />
                </pui-grid-column>
            </pui-grid-row>
        </pui-form>
        <pui-grid-row v-if="check.dataLoaded" class="form-group">
            <pui-grid-column class="pui-grid-column-zero-padding" :cols="{s:12, m:5, l:6, xl:6, xxl: 6}">
                <pebble-last-updated
                    :data="{
                        lastUpdated: data.lastUpdated,
                        lastUpdatedBy: data.lastUpdatedBy,
                    }"
                />
            </pui-grid-column>
            <pui-grid-column class="pui-grid-column-zero-padding" :cols="{s:12, m:7, l:6, xl:6, xxl: 6}">
                <cancel-save-buttons
                    v-if="check.dataLoaded && hasMasterDataAdmin"
                    :cancel-button-is-disabled="!check.addNewVersion && check.saveButtonIsDisabled"
                    :save-button-is-disabled="!hasMasterDataAdmin || check.saveButtonIsDisabled"
                    :on-click-cancel-button="onClickCancelButton"
                    :on-click-save-button="onClickSaveButton"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import {Prop, Watch} from 'vue-property-decorator';
import ComponentSecurity from '@/mixins/component-security';
import Component, {mixins} from 'vue-class-component';
import {DATE_FORMAT, EventBus} from '@/utils';
import {AssetService, OperationModesService} from '@/services';
import CustomTable from '@/components/table/custom-table.vue';
import BooleanText from '@/components/text/boolean-text.vue';
import OperationModesBaseDataForm from '@/components/forms/operation-modes-base-data-form.vue';
import {OperationModesModel} from '@/models/operation-modes-model';
import {IdentificationOperationModesForm} from '@/models/form/identification-operation-modes-form';
import {identificationOperationModesForm} from '@/utils/pebble-form/master-data-management/identification-operation-modes-form';
import {PebbleDropDown, pebbleDropDownFromAssets} from '@/models/pebble/pebble-drop-down';
import OperationModesPerformanceDataForm from '@/components/forms/operation-modes-performance-data-form.vue';
import {OperationModeFactory} from '@/utils/factories';
import format from 'date-fns/format';
import {PebbleNotification} from '@/models/pebble/pebble-notification';
import PebbleErrorsList from '@/components/error-list/pebble-errors-list.vue';
import {generateDataFromFormValues, generateErrors, isValidInput, shouldDisableForm, showDialog} from '@/utils/utils';
import PebbleLastUpdated from '@/components/last-updated/pebble-last-updated.vue';
import {
    Asset,
    IdTypeSid,
    OperationModeGetResponse,
    OperationModeVersionAddRequest,
    OperationModeVersionEditRequest,
    OperationModeVersionListDto,
} from '@/models';
import CancelSaveButtons from '@/components/buttons/cancel-save-buttons.vue';
import {CheckModel} from '@/models/check-model';

@Component({
    name: 'identification-operation-modes-tab',
    components: {
        CancelSaveButtons,
        PebbleLastUpdated,
        PebbleErrorsList,
        OperationModesPerformanceDataForm,
        OperationModesBaseDataForm,
        BooleanText,
        CustomTable,
    },
})
export default class IdentificationOperationModesTab extends mixins(ComponentSecurity) {
    /* VARIABLES */
    private operationModesService: OperationModesService = new OperationModesService();
    private assetsService: AssetService = new AssetService();
    private versions: OperationModeVersionListDto[] = [];
    private errors: PebbleNotification[] = [];
    private check: CheckModel = {
        dataLoaded: false,
        addNewVersion: false,
        saveButtonIsDisabled: true,
        showError: false,
    };

    /* LATE VARIABLES */
    private selectedVersion?: OperationModesModel;
    private formValues!: IdentificationOperationModesForm;
    private operationModeTypes!: PebbleDropDown[];
    private operationModeEnergyTypes!: PebbleDropDown[];
    private inclusionMethodList!: PebbleDropDown[];

    @Prop()
    private data!: OperationModeGetResponse;

    @Watch('check.addNewVersion')
    @Watch('check.saveButtonIsDisabled')
    private dirtyForm(): void {
        this.$emit('on-form-changed', this.check.addNewVersion || !this.check.saveButtonIsDisabled);
    }

    get heading(): any {
        return {
            validFrom: this.$t('validFrom'),
            validTo: this.$t('validUntil'),
            lastUpdated: this.$t('lastUpdated'),
            lastUpdatedBy: this.$t('lastUpdatedBy'),
            isActive: this.$t('active'),
            isStdOperationMode: this.$t('standardOperationMode'),
            isDeleted: this.$t('deleted'),
            action: '',
        };
    }

    /* PRIMITIVE METHODS */
    private created(): void {
        this.initCreated();
    }

    private mounted(): void {
        this.init();
    }

    /* METHODS */
    private async initCreated(): Promise<void> {
        const [operationModeTypes, operationModeEnergyTypes, inclusionMethodList] = await Promise.all([
            this.loadAssets(IdTypeSid.OPERATION_MODE_TYPE),
            this.loadAssets(IdTypeSid.OPERATION_MODE_ENERGY_TYPE),
            this.loadAssets(IdTypeSid.CONSOLIDATION),
        ]);
        this.operationModeTypes = pebbleDropDownFromAssets(operationModeTypes);
        this.operationModeEnergyTypes = pebbleDropDownFromAssets(operationModeEnergyTypes);
        this.inclusionMethodList = pebbleDropDownFromAssets(inclusionMethodList);
    }

    private async init(): Promise<void> {
        this.errors = [];
        this.check.dataLoaded = false;
        this.$store.commit('loading');
        this.versions = await this.loadVersions();
        this.selectedVersion = await this.loadSelectedVersion(this.$route.query.validFrom);
        this.versions = this.versions.map((v) => ({ ...v }));
        this.formValues = identificationOperationModesForm(this.selectedVersion);
        this.$store.commit('loading');
        this.check.dataLoaded = true;
        this.check.addNewVersion = false;
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedVersion);
    }

    private isRedirectionFromUnit(): boolean {
        const { powerPlantId,  redirectFromUnit } = this.$route.query;
        return !!powerPlantId && !!redirectFromUnit;
    }

    private async replacePage(validFrom?: string): Promise<void> {
        await this.$router.replace({
            name: 'management-operation-modes-edit',
            query: {
                edit: this.$route.query.edit,
                name: this.$route.query.name,
                backPage: this.$route.query.backPage,
                validFrom,
                ...(this.isRedirectionFromUnit()) && {
                    redirectFromUnit: 'true',
                    unitId: this.$route.query?.unitId,
                    powerPlantId: this.$route.query?.powerPlantId,
                },
            },
        });
    }

    private validate(value: string, name: string): void {
        isValidInput(name, this.formValues[name]);
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedVersion);
    }

    private versionIsSelected(item: OperationModeVersionListDto): boolean {
        return item.validFrom === this.selectedVersion?.validFrom ?? false;
    }

    /* ON CLICK BUTTON */
    private async onClickEditButton(value: OperationModeVersionListDto): Promise<void> {
        if (this.selectedVersion?.validFrom !== value.validFrom) {
            await this.replacePage(value.validFrom);
            await this.init();
            this.$forceUpdate();
        }
    }

    private onClickDeleteButton(value: OperationModeVersionListDto): void {
        this.deleteVersion(value.operationModeSid, value.validFrom);
    }

    private onClickAddNewVersionButton(): void {
        this.formValues = identificationOperationModesForm(this.selectedVersion
            ? {...this.selectedVersion, validFrom: format(new Date(), DATE_FORMAT)}
            : OperationModeFactory.createVersion(this.data.operationModeSid));
        this.check.addNewVersion = true;
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedVersion);
    }

    private onClickCancelButton(): void {
        this.formValues = {...identificationOperationModesForm(this.selectedVersion)};
        this.errors = [];
        this.check.addNewVersion = false;
        this.check.saveButtonIsDisabled = shouldDisableForm(this.formValues, this.selectedVersion);
    }

    private onClickSaveButton(): void {
        this.saveData();
    }

    /* API CALLS */
    private async loadAssets(idTypeSid: number): Promise<Asset[]> {
        let assets: Asset[] = [];
        try {
            const {result: {items}} = await this.assetsService.getByIdTypeSid(idTypeSid, {page: 1, size: 1000, term: ''});
            assets = items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
        return assets;
    }

    private async loadVersions(): Promise<OperationModeVersionListDto[]> {
        let versions: OperationModeVersionListDto[] = [];
        try {
            versions = await this.operationModesService.getVersions(this.data.operationModeSid);
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
        return versions;
    }

    private async loadSelectedVersion(defaultValidFrom?: string | any[]): Promise<OperationModesModel | undefined> {
        let version = typeof defaultValidFrom === 'string'
            ? this.versions.find(({validFrom}) => validFrom === defaultValidFrom)
            : this.versions.find(({isActive}) => isActive);
        if (!version) {
            version = this.versions[0];
        }
        let selectedVersion: OperationModesModel;
        try {
            selectedVersion = await this.operationModesService.getVersion(this.data.operationModeSid, version?.validFrom);
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            throw err;
        }
        return selectedVersion;
    }

    private async deleteVersion(operationModeSid: number, validFrom: string): Promise<void> {
        if (!await showDialog(this, 'areYouSureDeleteItem')) {
                return;
            }
        try {
            this.$store.commit('loading');
            await this.operationModesService.deleteVersion(operationModeSid, validFrom);
            if (this.$route.query.validFrom) {
                await this.replacePage();
            }
            await this.init();
            this.$forceUpdate();
            return;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorRemovingOperationModeVersion');
            generateErrors(this.errors, error);
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveNewVersion(model: OperationModeVersionAddRequest): Promise<void> {
        try {
            this.$store.commit('loading');
            const {result: {validFrom}} = await new OperationModesService().createVersion(model);
            this.check.addNewVersion = false;
            this.check.saveButtonIsDisabled = true;
            await this.init();
            await this.replacePage(validFrom);
            this.$forceUpdate();
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAddingOperationModeVersion');
            generateErrors(this.errors, error);
        } finally {
            this.$store.commit('loading');
        }
    }

    private async updateVersion(model: OperationModeVersionEditRequest): Promise<void> {
        if (!this.selectedVersion) {
            return;
        }

        try {
            this.$store.commit('loading');
            await this.operationModesService.updateVersion(
                this.data.operationModeSid,
                this.selectedVersion?.validFrom,
                model,
            );
            await this.init();
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorUpdatingOperationModeVersion');
            generateErrors(this.errors, error);
        } finally {
            this.$store.commit('loading');
        }
    }

    private async saveData(): Promise<void> {
        if (this.check.addNewVersion) {
            await this.saveNewVersion(generateDataFromFormValues(this.formValues) as OperationModeVersionAddRequest);
        } else {
            await this.updateVersion(generateDataFromFormValues(this.formValues) as OperationModeVersionEditRequest);
        }
    }
}
</script>

<style scoped>
.form-group {
    margin-top: 32px !important;
    margin-bottom: 16px !important;
}
</style>

