<template>
    <pui-headline type="h2" looks-like="form-sheet-subtitle" class="pebble-last-updated">
        {{ text() }}
    </pui-headline>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {DateHelper} from '@/utils';

@Component({
    name: 'pebble-last-updated',
})
export default class PebbleLastUpdated extends Vue {
    @Prop({required: true})
    private data!: {
        lastUpdatedBy: string;
        lastUpdated: string;
    };

    private text(): string {
        if (this.data.lastUpdatedBy) {
            return `${this.$t('lastUpdatedBy')} ${this.data.lastUpdatedBy} ${this.$t('on')} ${DateHelper.formatDate(this.data.lastUpdated)}`;
        } else {
            return `${this.$t('lastUpdated')} ${DateHelper.formatDate(this.data.lastUpdated)}`;
        }
    }
}
</script>

<style scoped>
.pebble-last-updated {
    margin-bottom: 16px !important;
}
</style>

