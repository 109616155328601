export class OperationModeListItem {
    public unitSid: number;
    public operationModeSid: number;
    public operationModeName?: string;
    public isActive: boolean;
    public isStdOperationMode: boolean;
    public lastUpdatedBy?: string;
    public lastUpdated?: string;
    public lastUpdateComment?: string;
    public validTo: string;
    public validFrom: string;

    constructor(data?: any) {
        this.unitSid = data?.unitSid;
        this.validFrom = data?.validFrom;
        this.validTo = data?.validTo;
        this.operationModeName = data?.operationModeName;
        this.operationModeSid = data?.operationModeSid;
        this.isActive = data?.isActive;
        this.isStdOperationMode = data?.isStdOperationMode;
        this.lastUpdatedBy = data?.lastUpdatedBy;
        this.lastUpdated = data?.lastUpdated;
        this.lastUpdateComment = data?.lastUpdateComment;
    }
}
