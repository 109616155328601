<template>
    <pui-grid-row>
        <pui-grid-column
            :cols="{s:8, m:10, l:10, xl:10, xxl: 10}"
            class="pui-grid-col--start pui-grid-column-zero-left-padding">
            <back-text :title="title" :on-press="onClickBackButton"/>
        </pui-grid-column>
        <pui-grid-column
            v-if="chipTitle"
            :cols="{s:4, m:2, l:2, xl:2, xxl: 2}"
            class="pui-grid-col--end">
            <pui-badge>{{ chipTitle }}</pui-badge>
        </pui-grid-column>
    </pui-grid-row>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import BackText from '@/components/text/back-text.vue';
import FakeSingleTab from '@/components/tab-bar/fake-single-tab.vue';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'card-header',
    components: {FakeSingleTab, BackText},
})
export default class CardHeader extends Vue {
    @Prop({required: true})
    private title!: string;

    @Prop({ required: false, default: ''})
    private chipTitle!: string;

    @Prop()
    private onClickBackButton!: () => void;
}
</script>


