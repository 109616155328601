<template>
    <pui-grid-container class="pui-grid-container-zero-padding">
        <pui-grid-row>
            <pui-grid-column :cols="fullCols">
                <title-form-text
                    :title="$t('baseData')"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    type="date"
                    :label="$t('validFrom')"
                    :model="data.validFrom"
                    name="validFrom"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column v-if="isEdit && !isAddNewVersion" :cols="itemFormCols">
                <pebble-text-field
                    type="date"
                    :label="$t('validUntil')"
                    :model="data.validTo"
                    name="validTo"
                    disable
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column v-if="isEdit" :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('reservoirSid')"
                    :model="data.reservoirSid"
                    name="reservoirSid"
                    disable
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('reservoirName')"
                    :model="data.reservoirName"
                    name="reservoirName"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('river')"
                    :model="data.enumRiverSid"
                    name="enumRiverSid"
                    :options="riversList"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('riverGroup')"
                    :model="data.enumRiverGroupSid"
                    name="enumRiverGroupSid"
                    :options="riversGroupList"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    type="number"
                    :label="$t('eqvFactor')"
                    :model="data.eqvFactor"
                    name="eqvFactor"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column v-if="isEdit" :cols="fullCols">
                <pebble-text-field-area
                    :label="$t('comments')"
                    :model="data.lastUpdateComment"
                    name="lastUpdateComment"
                    :max-length="1000"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import {PebbleValidate} from '@/mixins/pebble-validate';
import {PebbleColConst} from '@/mixins/pebble-col-const';
import ComponentSecurity from '@/mixins/component-security';
import TitleFormText from '@/components/text/title-form-text.vue';
import PebbleTextField from '@/components/pebble-form-element/pebble-text-field.vue';
import {Prop} from 'vue-property-decorator';
import PebbleDropDownSelect from '@/components/pebble-form-element/pebble-drop-down-select.vue';
import PebbleTextFieldArea from '@/components/pebble-form-element/pebble-text-field-area.vue';
import {IdentificationReservoirForm} from '@/models/form/identification-reservoir-form';
import {PebbleDropDown} from '@/models/pebble/pebble-drop-down';

@Component({
    name: 'reservoir-identification-form',
    components: {PebbleTextFieldArea, PebbleDropDownSelect, PebbleTextField, TitleFormText},
})
export default class ReservoirIdentificationForm extends mixins(PebbleValidate, PebbleColConst, ComponentSecurity) {
    @Prop({required: true})
    private data!: IdentificationReservoirForm;

    @Prop({default: false})
    private isEdit!: boolean;

    @Prop({default: false})
    private isAddNewVersion!: boolean;

    @Prop({required: true})
    private riversList!: PebbleDropDown[];

    @Prop({required: true})
    private riversGroupList!: PebbleDropDown[];
}
</script>
