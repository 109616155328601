<template>
    <view-details
        :sid="data.materialGroupSid"
        :name="data.shortName || $t('newMaterialGroup')"
        icon="ic-settings">
        <tab-headers 
            :tabs="tabs"
            :active-tab="activeTab"
            @select-tab="selectTab" />
        <component
            :is="activeTab.component"
            :data="data"
            @close="closeEditScreen()"
            @save="saveChanges" />
    </view-details>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { GenericTab, EtsUnit, MaterialBaseModel } from '@/models';
import TabHeaders from '@/components/view-details/tab-headers/tab-headers.vue';
import MaterialGroupIdentification from '@/components/view-details/material-group/material-group-identification/material-group-identification.vue';
import ViewDetails from '@/components/view-details/view-details.vue';
import MaterialGroupKeyMapping from './material-group-key-mapping/material-group-key-mapping.vue';

@Component({
    name: 'material-group-details',
    components: {
        tabHeaders: TabHeaders,
        materialGroupIdentification: MaterialGroupIdentification,
        viewDetails: ViewDetails,
        unitKeyMapping: MaterialGroupKeyMapping,
    },
})
export default class MaterialGroupDetails extends Vue {
    /**
     * Material group for which to show details.
     */
    @Prop({ required: true })
    private data!: MaterialBaseModel;

    private tabs: GenericTab[] = [
        { name: 'identification', component: 'materialGroupIdentification'},
        { name: 'keyMapping', component: 'unitKeyMapping' },
    ];
    private activeTab = this.tabs[0];

    private async mounted(): Promise<void> {
        if (this.$route.query.tab) {
            this.activeTab = this.tabs.find((x) => x.name === this.$route.query.tab.toString()) ?? this.tabs[0];
        }
    }

    private selectTab(tab: GenericTab) {
        this.$router.push({
            name: 'manage-option',
            params: {
                option: this.$route.params.option,
            },
            query: {
                ...this.$route.query,
                tab: tab.name.toString(),
            },
        });
        this.activeTab = tab;
    }

    private closeEditScreen(): void {
        this.$emit('close');
    }

    private saveChanges(item: EtsUnit, isAdd: boolean): void {
        this.$emit('save', item, isAdd, null);
    }
}
</script>
