<template>
    <div class="table-container">
        <pui-table
            :vendor-options="tableData"
            :with-pagination="enablePagination"
            :with-search="enableSearch"
            :with-limit="enableLimit"
            class="table">
            <template v-for="item in tableData.columns" :slot="item" slot-scope="data">
                <div :key="item" :class="generateClass(data.row)">
                    <span v-if="!customTemplate.includes(item)">{{ text(data.row[item]) }}</span>
                    <slot v-else-if="item === 'action'" :name="'custom_' + item" :data="data.row">
                        <pui-icon icon-name="edit"/>
                    </slot>
                    <slot v-else :name="'custom_' + item" :data="data.row[item]">{{ text(data.row[item]) }}</slot>
                </div>
            </template>
        </pui-table>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import {ONLY_DATE_FORMAT} from '@/utils/constants';
import {isDate} from '@/utils/utils';
import {format} from 'date-fns';

@Component({
    name: 'custom-table',
    components: {},
})
export default class CustomTable<T> extends Vue {
    private tableData: any = {};

    @Prop({required: true})
    private columnsKey!: string[];

    @Prop({required: true, default: () => ([])})
    private data!: T[];

    @Prop({required: false, default: () => ([])})
    private sortableKey?: string[];

    @Prop({required: false})
    private heading!: any;

    @Prop({default: false})
    private enableSearch!: boolean;

    @Prop({default: false})
    private enablePagination!: boolean;

    @Prop({default: false})
    private enableLimit!: boolean;

    @Prop({required: false, default: () => ([])})
    private customTemplate!: string[];

    @Prop({default: () => ([10, 50, 100])})
    private perPageValues!: number[];

    @Prop({default: 2})
    private perPage!: number;

    @Prop({default: () => () => false})
    private selected!: (item: T) => boolean;

    @Prop({default: () => null})
    private initialOrderBy!: { column: string; ascending: boolean } | null;

    private created(): void {
        this.tableData = {
            columns: this.columnsKey,
            data: this.data,
            options: {
                sortable: this.sortableKey,
                headings: this.heading,
                texts: {
                    limit: this.$t('resultsPerPage').toString(),
                    filter: this.$t('searchResults').toString(),
                    filterPlaceholder: this.$t('search').toString(),
                    noResults: this.$t('noMatchingRecords').toString(),
                    page: this.$t('page').toString(),
                },
                perPage: this.perPage,
                perPageValues: this.perPageValues,
                pagination: {
                    edge: true,
                },
                orderBy: this.initialOrderBy,
            },
        };
    }

    @Watch('data')
    private reloadData(value: T[]): void {
        this.tableData.data = value;
        this.$forceUpdate();
    }

    private text(value: any): string {
        const text = value;
        if (typeof value === 'string' && isDate(value)) {
            return format(new Date(text), ONLY_DATE_FORMAT);
        }
        return text?.toString() ?? '';
    }

    private generateClass(item: T): string {
        if (this.selected) {
            return this.selected(item) ? 'row-selected' : 'row-unselected';
        }
        return 'row-unselected';
    }
}
</script>

<style scoped lang="less">
@import '../../variables.less';

.custom-table {
    width: 100%;

    .table select {
        width: auto !important;
    }

    .table {
        -webkit-box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.4);
        box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.4);
    }
}

/deep/ .pui-table .VueTables__table thead th {
    font-weight: 600;
}

.table-container {
    width: 100%;
}

.row-selected {
    font-weight: bold !important;
}

.row-unselected {
    font-weight: normal;
}
</style>
