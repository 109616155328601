<template>
    <pui-grid-container class="pui-grid-container-zero-padding">
        <pui-grid-row>
            <pui-grid-column :cols="fullCols">
                <title-form-text
                    :title="$t('baseData')"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols" v-if="isEdit">
                <pebble-text-field
                    :label="$t('materialGroupSid')"
                    :model="data.materialGroupSid"
                    name="materialGroupSid"
                    disable
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('shortName')"
                    :model="data.shortName"
                    name="shortName"
                    :max-length="20"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('materialGroupName')"
                    :model="data.materialGroupName"
                    name="materialGroupName"
                    :max-length="50"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('enumSuMaterialGroup')"
                    :model="data.enumSuMaterialGroupSid"
                    name="enumSuMaterialGroupSid"
                    :options="enumSuMaterialList"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('enumMaterialType')"
                    :model="data.enumMaterialTypeSid"
                    name="enumMaterialTypeSid"
                    :options="enumMaterialTypeList"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="fullCols" v-if="isEdit">
                <pebble-text-field-area
                    :label="$t('comments')"
                    :model="data.lastUpdateComment"
                    name="lastUpdateComment"
                    :max-length="1000"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols" class="pui-grid-col--bottom">
                <pebble-check-box
                    :label="$t('markAsDeleted')"
                    :model="data.isDeleted"
                    name="isDeleted"
                    @on-change="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import TitleFormText from '@/components/text/title-form-text.vue';
import ComponentSecurity from '@/mixins/component-security';
import {PebbleValidate} from '@/mixins/pebble-validate';
import PebbleTextField from '@/components/pebble-form-element/pebble-text-field.vue';
import PebbleDropDownSelect from '@/components/pebble-form-element/pebble-drop-down-select.vue';
import PebbleTextFieldArea from '@/components/pebble-form-element/pebble-text-field-area.vue';
import PebbleCheckBox from '@/components/pebble-form-element/pebble-check-box.vue';
import {Prop} from 'vue-property-decorator';
import {PebbleDropDown} from '@/models/pebble/pebble-drop-down';
import {IdentificationMaterialGroupForm} from '@/models/form/identification-material-group-form';
import {PebbleColConst} from '@/mixins/pebble-col-const';

@Component({
    name: 'material-group-identification-form',
    components: {PebbleCheckBox, PebbleTextFieldArea, PebbleDropDownSelect, PebbleTextField, TitleFormText},
})
export default class MaterialGroupIdentificationForm extends mixins(PebbleValidate, ComponentSecurity, PebbleColConst) {
    @Prop({required: true})
    private data!: IdentificationMaterialGroupForm;

    @Prop({default: false})
    private isEdit!: boolean;

    @Prop({required: true})
    private enumSuMaterialList!: PebbleDropDown[];

    @Prop({required: true})
    private enumMaterialTypeList!: PebbleDropDown[];
}
</script>


