<template>
    <pui-grid-container class="pui-grid-container-zero-padding">
        <pui-grid-row>
            <pui-grid-column :cols="fullCols">
                <title-form-text
                    :title="$t('baseData')"
                />
            </pui-grid-column>
        </pui-grid-row>
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    type="date"
                    :label="$t('validFrom')"
                    :model="data.validFrom"
                    name="validFrom"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols" v-if="validToIsVisible">
                <pebble-text-field
                    type="date"
                    :label="$t('validUntil')"
                    :model="data.validTo"
                    name="validTo"
                    disable
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols" v-if="!isAddNewOperation">
                <pebble-text-field
                    type="number"
                    :label="$t('sid')"
                    :model="data.operationModeSid"
                    name="operationModeSid"
                    disable
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    type="number"
                    :label="$t('unitSid')"
                    :model="data.unitSid"
                    name="unitSid"
                    disable
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('unitType')"
                    :model="data.unitSapType"
                    name="unitSapType"
                    disable
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :disable="disabled"
                    :label="$t('operationModeName')"
                    :model="data.operationModeName"
                    name="operationModeName"
                    :max-length="50"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :disable="disabled"
                    :label="$t('operationModeType')"
                    :model="data.enumOpModeTypeSid"
                    name="enumOpModeTypeSid"
                    :options="operationModeTypes"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :disable="disabled"
                    :label="$t('operationModePrimaryEnergy')"
                    :model="data.enumPriEnergySid"
                    name="enumPriEnergySid"
                    :options="operationModeEnergyTypes"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :disable="disabled"
                    :label="$t('operationModeSecondaryEnergy')"
                    :model="data.enumSecEnergySid"
                    name="enumSecEnergySid"
                    :options="operationModeEnergyTypes"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :disable="disabled"
                    :label="$t('inclusionMethod')"
                    :model="data.enumConsolidationSid"
                    name="enumConsolidationSid"
                    :options="inclusionMethodList"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols" class="pui-grid-col--bottom">
                <pebble-check-box
                    :disabled="disabled"
                    :label="$t('standardOperationMode')"
                    :model="data.isStdOperationMode"
                    name="isStdOperationMode"
                    @on-change="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-check-box
                    :disabled="disabled"
                    :label="$t('isRedispatchAvailable')"
                    :model="data.isRedispatchAvailable"
                    name="isRedispatchAvailable"
                    @on-change="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-check-box
                    :disabled="disabled"
                    :label="$t('isBlackstart')"
                    :model="data.isBlackstart"
                    name="isBlackstart"
                    @on-change="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols" v-if="!isAddNewOperation">
                <pebble-check-box
                    :disabled="disabled"
                    :label="$t('markAsDeleted')"
                    :model="data.isDeleted"
                    name="isDeleted"
                    @on-change="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-check-box
                    :disabled="disabled"
                    :label="$t('markAsActive')"
                    :model="data.isActive"
                    name="isActive"
                    @on-change="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import {Prop} from 'vue-property-decorator';
import ComponentSecurity from '@/mixins/component-security';
import Component, {mixins} from 'vue-class-component';
import PebbleTextField from '@/components/pebble-form-element/pebble-text-field.vue';
import {IdentificationOperationModesForm} from '@/models/form/identification-operation-modes-form';
import PebbleDropDownSelect from '@/components/pebble-form-element/pebble-drop-down-select.vue';
import {PebbleDropDown} from '@/models/pebble/pebble-drop-down';
import PebbleCheckBox from '@/components/pebble-form-element/pebble-check-box.vue';
import CheckboxInput from '@/components/checkbox-input/checkbox-input.vue';
import {OperationModesModel} from '@/models/operation-modes-model';
import {format} from 'date-fns';
import {ONLY_DATE_FORMAT} from '@/utils/constants';
import {isDate} from '@/utils/utils';
import {PebbleValidate} from '@/mixins/pebble-validate';
import TitleFormText from '@/components/text/title-form-text.vue';
import {PebbleColConst} from '@/mixins/pebble-col-const';

@Component({
    name: 'operation-modes-base-data-form',
    components: {
        TitleFormText,
        CheckboxInput, PebbleCheckBox, PebbleDropDownSelect, PebbleTextField,
    },
})
export default class OperationModesBaseDataForm extends mixins(PebbleValidate, ComponentSecurity, PebbleColConst) {
    @Prop({required: true})
    private data!: IdentificationOperationModesForm;

    @Prop({default: false})
    private isAddNewOperation!: boolean;

    @Prop({required: false})
    private selectedVersion?: OperationModesModel;

    @Prop({required: true})
    private operationModeTypes!: PebbleDropDown[];

    @Prop({required: true})
    private operationModeEnergyTypes!: PebbleDropDown[];

    @Prop({required: true})
    private inclusionMethodList!: PebbleDropDown[];

    @Prop({default: false})
    private isAddingNewVersion!: boolean;

    @Prop({default: false})
    private disabled!: boolean;

    private get validToIsVisible(): boolean {
        return this.selectedVersion && this.data ?
            !this.isAddingNewVersion &&
            isDate(this.data.validFrom.value)
                ? format(new Date(this.data.validFrom.value), ONLY_DATE_FORMAT) === format(new Date(this.selectedVersion.validFrom), ONLY_DATE_FORMAT)
                : false
            : false;
    }
}
</script>

