<template>
    <div class="home flex-container main-container flex-wrap pl-2 pr-2">
        <h1 class="home-welcome">{{$t('appWelcome')}}</h1>
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'home',
})
export default class Home extends Vue {
}

</script>

<style scoped lang="less">
.home-welcome {
    margin-top: 8rem;
}
</style>
