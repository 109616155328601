import {
    ApiResponse,
    ListResponse,
    PagedResponse,
    Reservoir,
    ReservoirAddRequest,
    ReservoirQueryModel,
    SearchPaginationQueryModel,
} from '@/models';
import { EventBus } from '@/utils';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
import { generateQuery } from '@/utils/api-helper';

class ReservoirService {
    private api = new SdkGenericApiHelper('reservoirs');

    public async create(reservoir: any): Promise<ApiResponse<ReservoirQueryModel>> {
        return (await this.api.post(reservoir)).data;
    }

    public async get(filter: SearchPaginationQueryModel): Promise<ApiResponse<PagedResponse<Reservoir>>> {
        const query = generateQuery(filter as any);
        return (await this.api.get(query)).data;
    }

    public async getReservoirVersions(reservoir: Reservoir): Promise<ApiResponse<ListResponse<ReservoirQueryModel>>> {
        return (await this.api.getByPathOrParam(`${reservoir.reservoirSid}/versions`)).data;
    }

    public async getReservoirVersionsV2(sid: string): Promise<ApiResponse<ListResponse<ReservoirQueryModel>>> {
        return (await this.api.getByPathOrParam(`${sid}/versions`)).data;
    }

    public async getById(reservoir: any): Promise<ApiResponse<ReservoirQueryModel>> {
        return (await this.api.getByPathOrParam(`${reservoir.sid}/versions/${reservoir.validFrom}`)).data;
    }

    public async getBySidAndValidFrom(sid: string, validFrom: string): Promise<ApiResponse<ReservoirQueryModel>> {
        return (await this.api.getByPathOrParam(`${sid}/versions/${validFrom}`)).data;
    }

    public async edit(reservoir: ReservoirAddRequest, validFrom: string): Promise<ApiResponse<any>> {
        return (await this.api.put(`${reservoir.reservoirSid}/versions/${validFrom}`, reservoir)).data;
    }

    public async editV2(reservoir: ReservoirAddRequest): Promise<ApiResponse<any>> {
        return (await this.api.put(`${reservoir.reservoirSid}/versions/${reservoir.validFrom}`, reservoir)).data;
    }

    public async delete(reservoir: any): Promise<ApiResponse<any>> {
        return (await this.api.delete(`${reservoir.sid}/versions/${reservoir.validFrom}`)).data;
    }

    public async getReservoirs(): Promise<any> {
        try {
            return (await this.get({page: 1, size: 1000, term: ''})).result?.items;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingData');
            return [];
        }
    }
}

const reservoirService = new ReservoirService();

export {
    ReservoirService,
    reservoirService,
};
