import {Asset} from '@/models';
import {IdentificationEnumTypesForm} from '../../../models/form/identification-enum-types-form';

export const identificationEnumTypesForm = (data?: Asset): IdentificationEnumTypesForm => {
    return {
        name: {
            value: data?.name ?? '',
            isValid: true,
            validator: (val: string) => val.length > 0,
        },
        description: {
            value: data?.description ?? '',
            isValid: true,
        },
        isDeleted: {
            value: data?.isDeleted ?? false,
            isValid: true,
        },
        idTypeSid: {
            value: data?.idTypeSid,
            isValid: true,
        },
        sid: {
            value: data?.sid,
            isValid: true,
        },
        objectType: {
            value: data?.objectType,
            isValid: true,
        },
    };
};
