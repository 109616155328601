<template>
    <div class="fake-single-tab">
        <pui-headline type="h5" looks-like="form-section-title" class="fake-single-tab-title">
            {{ title }}
        </pui-headline>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'fake-single-tab',
})
export default class FakeSingleTab extends Vue {
    @Prop({default: 'title'})
    private title!: string;
}
</script>

<style scoped lang="less">
@import "~@/variables.less";

.fake-single-tab{
    border-bottom: 2px solid @pebble-blue;
    color: @pebble-blue;
    margin-top: 16px;
}

.fake-single-tab-title{
    margin-bottom: 4px;
}
</style>

