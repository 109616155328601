<template>
    <pui-grid-container class="pui-grid-container-zero-padding">
        <pui-grid-row>
            <pui-grid-column :cols="itemFormCols" v-if="isEdit">
                <pebble-text-field
                    :label="$t('sid')"
                    name="locationSid"
                    :model="data.locationSid"
                    disable
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('identifier')"
                    name="locationShort"
                    :model="data.locationShort"
                    :max-length="8"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('name')"
                    name="locationName"
                    :model="data.locationName"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('type')"
                    name="enumLocationTypeSid"
                    :model="data.enumLocationTypeSid"
                    :options="types"
                    required
                    disable
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('country')"
                    name="enumCountrySid"
                    :model="data.enumCountrySid"
                    :options="countries"
                    required
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-drop-down-select
                    :label="$t('state')"
                    name="enumStateSid"
                    :model="data.enumStateSid"
                    :options="states"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('zipCode')"
                    name="zipCode"
                    :model="data.zipCode"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('city')"
                    name="city"
                    :model="data.city"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('street')"
                    name="street"
                    :model="data.street"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('streetNumber')"
                    name="houseNumber"
                    :model="data.houseNumber"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('postbox')"
                    name="postbox"
                    :model="data.postbox"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols">
                <pebble-text-field
                    :label="$t('phone')"
                    name="telephone"
                    :model="data.telephone"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="fullCols" v-if="isEdit">
                <pebble-text-field-area
                    :label="$t('comments')"
                    name="lastUpdateComment"
                    :model="data.lastUpdateComment"
                    :max-length="1000"
                    @on-input="validate"
                    @on-blur="validate"
                />
            </pui-grid-column>
            <pui-grid-column :cols="itemFormCols" class="pui-grid-col--bottom">
                <pebble-check-box
                    :label="$t('markAsDeleted')"
                    name="deleted"
                    :model="data.deleted"
                    @on-change="validate"
                />
            </pui-grid-column>
        </pui-grid-row>
    </pui-grid-container>
</template>

<script lang="ts">
import Component, {mixins} from 'vue-class-component';
import ComponentSecurity from '@/mixins/component-security';
import PebbleTextField from '@/components/pebble-form-element/pebble-text-field.vue';
import {Prop} from 'vue-property-decorator';
import {IdentificationBusinessPartnersForm} from '@/models/form/identification-business-partners-form';
import PebbleDropDownSelect from '@/components/pebble-form-element/pebble-drop-down-select.vue';
import PebbleTextFieldArea from '@/components/pebble-form-element/pebble-text-field-area.vue';
import PebbleCheckBox from '@/components/pebble-form-element/pebble-check-box.vue';
import {PebbleDropDown} from '@/models/pebble/pebble-drop-down';
import {PebbleValidate} from '@/mixins/pebble-validate';
import {PebbleColConst} from '@/mixins/pebble-col-const';

@Component({
    name: 'business-partners-identification-form',
    components: {PebbleCheckBox, PebbleTextFieldArea, PebbleDropDownSelect, PebbleTextField},
})
export default class BusinessPartnersIdentificationForm extends mixins(PebbleValidate, ComponentSecurity, PebbleColConst) {
    @Prop({required: true})
    private data!: IdentificationBusinessPartnersForm;

    @Prop({required: true})
    private countries!: PebbleDropDown[];

    @Prop({required: true})
    private types!: PebbleDropDown[];

    @Prop({required: true})
    private states!: PebbleDropDown[];

    @Prop({default: true})
    private isEdit!: boolean;
}
</script>


