<template>
    <div class="main-container flex-col key-mapping-listing full-height">
            <div class="flex-row managing-options pl-2 pr-2 pt-2 pb-2">
                <div class="flex-col">
                    <div class="manage-input flex-col">
                        <label for="externalSystemSelection">{{ $t('selectExternalSystem') }}:</label>
                        <select class="default-select" id="externalSystemSelection"
                            v-model="selectedExternalSystem"
                            @change="selectExternalSystem">
                            <option :value="null" key="default" selected disabled>
                                {{ $t('selectExternalSystem') }}
                            </option>
                            <option v-for="system of externalSystems"
                                :key="system.sid"
                                :value="system">
                                {{ system.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="flex flex-4"></div>
                <div class="flex-col">
                    <search-component
                        v-if="cardList && cardList.length | searchTerm"
                        v-model="searchTerm"
                        @input="onSearchTermChange" />
                </div>
            </div>
            <div class="main-area" id="view-items">
                <div v-if="!cardList || cardList.length <= 0" class="mt-2 ml-2">
                    {{ $t('noDataToShow') }}
                </div>
                <div v-else id="key-mapping-cards">
                    <sort-header
                        v-if="headerColumn.length"
                        :headerColumns="headerColumn"
                        :objectToSort="cardList"
                        :showIcon="false"
                        @get-sorted-list="setSortedLocalKeyMaps"
                    ></sort-header>
                    <card-list>
                        <template v-slot:cards>
                            <div v-for="card of cardList"
                                :key="card.assetId"
                                class="card">
                                <key-mapping-card-list
                                    :card="card"
                                    @edit="edit" />
                            </div>
                        </template>
                    </card-list>
                    <div v-if="hasNextPage" class="flex-row mt-2" >
                        <div class="flex-col load-more">
                            <adam-button
                                @click="calculateScroll()"
                            >
                                {{ $t('loadMore') }}
                            </adam-button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script lang="ts">
import TableView from '@/components/view-details/table-view/table-view.vue';
import { Asset, IdTypeSid, KeyMappingListingItem } from '@/models';
import CardList from '@/components/cards/cards-list/cards-list.vue';
import SortHeader from '@/views/sort-header/sort-header.vue';
import { AssetService, hierarchyService, KeyMappingService } from '@/services';
import { EventBus, InfiniteScrollingHelper } from '@/utils';
import { Vue, Component } from 'vue-property-decorator';
import KeyMappingCardList from '@/components/cards/cards-list/key-mapping-card-list/key-mapping-card-list.vue';
import { throttle } from 'lodash';
import { PaginationQueryModel } from '@/models/filter';
import {
    HiearchicPowerPlantParentQueryModel,
    HiearchicPowerPlantQueryModel,
    SearchPaginationQueryModel,
} from '@/models/filter/query-filter';
import SearchComponent from '@/components/search-component/search-component.vue';

const SCROLL_THROTTLE_MILLISECONDS = 500;
enum TypeRouteMap {
    'Material' = 'material-base-management',
    'Ets Unit' = 'ets-units-management',
    'Material Group' = 'material-group-management',
}

@Component({
    name: 'key-mapping-listing',
    components: {
        tableView: TableView,
        cardList: CardList,
        sortHeader: SortHeader,
        keyMappingCardList: KeyMappingCardList,
        searchComponent: SearchComponent,
    },
})
export default class KeyMappingListing extends Vue {

    private externalSystems: Asset[] = [];
    private isLoadingNextPage = false;
    private hasNextPage = false;
    private currentPage = 0;
    private selectedExternalSystem: Asset | null = null;
    private cardList: KeyMappingListingItem[] | null = null;
    private sortOrder = 'asc';
    private sortKey = 'assetId';
    private onScroll = throttle(this.calculateScroll, SCROLL_THROTTLE_MILLISECONDS);
    private searchTerm = '';
    private headerColumn: Array<{
        attributeName: string;
        name: string;
        itemCssClassName: string;
    }> = [];
    private filterModel: SearchPaginationQueryModel = {
        size: 10,
        page: 1,
        term: '',
    };

    private async mounted(): Promise<void> {
        await this.loadExternalSystems();
        this.headerColumn = [
            {
                attributeName: 'assetName',
                name: this.$t('assetName').toString(),
                itemCssClassName: 'em-assetName',
            },
            {
                attributeName: 'assetId',
                name: this.$t('assetID').toString(),
                itemCssClassName: 'em-asset-id',
            },
            {
                attributeName: 'assetType',
                name: this.$t('assetType').toString(),
                itemCssClassName: 'em-assetType',
            },
            {
                attributeName: 'keyMapping',
                name: this.$t('keyMapping').toString(),
                itemCssClassName: 'em-keyMapping',
            },
            {
                attributeName: 'isActive',
                name: this.$t('active').toString() + '(Y/N)',
                itemCssClassName: 'em-active',
            },
            {
                attributeName: 'validFrom',
                name: this.$t('validFrom').toString(),
                itemCssClassName: 'em-validFrom',
            },
            {
                attributeName: 'validTo',
                name: this.$t('validUntil').toString(),
                itemCssClassName: 'em-validUntil',
            },
        ];
    }

    private async loadExternalSystems(): Promise<void> {
        this.$store.commit('loading');
        try {
            this.externalSystems = (await new AssetService().getByIdTypeSid(IdTypeSid.EXTERNAL_SYSTEM,
                {size: 999, page: 1, term: ''})).result.items;
        } catch (error: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingExternalSystems');
            throw error;
        } finally {
            this.$store.commit('loading');
        }
    }

    private onSearchTermChange(term: string): void {
        this.filterModel.term = term;
        this.selectExternalSystem();
    }

    private async getNextPage(): Promise<void> {
        this.removeEventListener();
        if (this.isLoadingNextPage) {
            return;
        }
        if (!this.selectedExternalSystem || this.isLoadingNextPage || !this.hasNextPage) {
            return;
        }
        this.isLoadingNextPage = true;
        this.filterModel.page++;
        const result = await this.getAllKeyMappings(this.selectedExternalSystem?.sid, this.filterModel);
        this.cardList?.push(...result.items);
        this.hasNextPage = result.hasNextPage;
        this.filterModel.page = result.currentPage;
        await this.$nextTick();
        this.addEventListener();
        this.isLoadingNextPage = false;
    }

    private calculateScroll(): void {
        InfiniteScrollingHelper.calculateScroll(document.getElementById('view-items'), this.getNextPage, 200);
    }

    private async edit(item: KeyMappingListingItem) {
        let path = '';
        let query = {};
        if (item.assetType === 'Unit') {
            const params: HiearchicPowerPlantQueryModel = {
                term: item.assetId.toString(),
                countries: [],
                machineTypes: [],
                fleets: [],
                plantGroups: [],
                classTypes: [],
                includeDeleted: false,
            };
            const powerPlantDetails = await hierarchyService.getHierarchicPowerPlants(params);
            if (powerPlantDetails && powerPlantDetails.length > 0) {
                path = `asset-master-data/${powerPlantDetails[0].countrySid}/`
                    + `${powerPlantDetails[0].powerPlantSid}/${item.assetId}`;
                query = {
                    tab: 'keyMapping',
                    system: this.selectedExternalSystem?.sid.toString(),
                };
            }
        } else if (item.assetType === 'Power Plant') {
            const params: HiearchicPowerPlantParentQueryModel = {
                powerPlantSid: item.assetId,
            };
            const powerPlantDetails = await hierarchyService.getHierarchicPowerPlantsParent(params);
            if (powerPlantDetails && powerPlantDetails.length > 0) {
                path = `asset-master-data/${powerPlantDetails[0].countrySid}/${item.assetId}/edit`;
                query = {
                    tab: 'keyMapping',
                    system: this.selectedExternalSystem?.sid.toString(),
                };
            }
        } else if (TypeRouteMap[item.assetType as keyof typeof TypeRouteMap]) {
            path = `master-data-management/${TypeRouteMap[item.assetType as keyof typeof TypeRouteMap]}`;
            query = {
                item: item.assetId?.toString(),
                tab: 'KeyMapping',
                system: this.selectedExternalSystem?.sid.toString(),
            };
        }
        if (path !== '') {
            this.$router.push({
                path,
                params: {
                    option: this.$route.params.option,
                },
                query,
            });
        }
    }

    private async getAllKeyMappings(systemSid: number | undefined, filterModel: PaginationQueryModel) {
        const result =
            (await new KeyMappingService().getAllKeyMappings(systemSid, filterModel))
            .result;
        return result;
    }

    private async selectExternalSystem() {
        this.$store.commit('loading');
        try {
            this.removeEventListener();
            if (!this.selectedExternalSystem) {
                this.cardList = null;
                return;
            }
            this.isLoadingNextPage = true;
            this.filterModel.page = 1;
            const result = await this.getAllKeyMappings(this.selectedExternalSystem?.sid, this.filterModel);
            this.filterModel.page = result.currentPage;
            this.hasNextPage = result.hasNextPage;
            this.cardList = result.items;
            this.isLoadingNextPage = false;
            this.addEventListener();
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingData');
        } finally {
            this.$store.commit('loading');
        }
    }

    private setSortedLocalKeyMaps(obj: {
        sortedList: any[];
        sortOrder: string;
        sortKey: string;
    }): void {
        this.cardList = [...obj.sortedList];
        this.sortOrder = obj.sortOrder;
        this.sortKey = obj.sortKey;
    }

    private beforeDestroy(): void {
        this.removeEventListener();
    }

    private addEventListener(): void {
        const element = document.getElementById('view-items');
        if (element) {
            element.addEventListener('scroll', this.onScroll);
            element.addEventListener('wheel', this.onScroll);
        }
    }

    private removeEventListener(): void {
        const element = document.getElementById('view-items');
        if (element) {
            element.removeEventListener('scroll', this.onScroll);
            element.removeEventListener('wheel', this.onScroll);
        }
    }
}
</script>

<style lang="less">
@import "~@/variables.less";
.key-mapping-listing {
    .managing-options {
        background-color: @white;
        box-shadow: 0 0 6px 0 rgba(@white, 0.15);
        margin-bottom: 0.5rem;
    }
    label {
        margin-top: 1rem;
        min-width: fit-content;
    }
    .main-area {
        padding: 2.5rem;
        box-sizing: border-box;
        overflow-y: auto;
        height: 100%;
        .name {
            width: 21rem;
        }
        .sid {
            width: 10rem;
        }
        .description {
            width: 41rem;
        }

        .is-deleted {
            width: 10rem;
        }
    }
    .manage-input {
        position: relative;
        label {
            position: absolute;
            background: white;
            left: 1.9rem;
            top: -2.1rem;
            padding: 0 1rem;
            font-size: 1.6rem;
            z-index: 1;
        }
        input,
        select,
        textarea {
            font-size: 1.8rem;
            padding: 1rem 2.5rem;
            border-radius: @border-radius-standard;
            border: 2px solid @dark-grey;
            transition: 0.2s ease-in;
            &:focus {
                border: 2px solid @uniper-blue;
                transition: 0.2s ease-in;
                outline: none;
            }
            &.invalid {
                border: 2px solid @red-darker;
                box-shadow: 0 1px 4px 0 rgba(255, 0, 0, 0.22);
                transition: 0.1s ease-in;
            }
        }
    }
    .header-row {
        &__item-em-asset-id,
        &__item-em-assetName,
        &__item-em-assetType,
        &__item-em-keyMapping,
        &__item-em-active,
        &__item-em-validFrom,
        &__item-em-validUntil {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            box-sizing: border-box;
            border-right: 2px solid @lighter-grey;
            padding: 0 1rem;
        }

        &__item-em-asset-id {
            border-left: none;
            width: 12rem;
        }

        &__item-em-assetName {
            width: 27rem;
        }

        &__item-em-assetType {
            width: 15rem;
        }

        &__item-em-keyMapping {
            width: 15rem;
        }

        &__item-em-active {
            width: 15rem;
        }

        &__item-em-validFrom {
            width: 17rem;
        }

        &__item-em-validUntil {
            width: 17rem;
        }
    }
    .properties {
        .name {
            width: 29.5rem;
        }
        .asset-type {
            width: 13rem;
        }
        .key-mapping {
            width: 13rem;
        }
        .active {
            width: 13rem;
        }
        .valid-from {
            width: 15rem;
        }
        .valid-to {
            width: 15rem;
        }
    }
}
</style>
