import { Header } from '@/models';
import { DateHelper } from '@/utils';

const operationModeProductsTableHeader: Header = {
    columns: [
        {
            text: 'productSid',
            displayKey: 'productSid',
            class: 'flex-1 mr-1',
            formatFunction: (productSid: number) => productSid,
        },
        {
            text: 'productName',
            displayKey: 'productName',
            class: 'flex-1 mr-1',
            formatFunction: (productName: string) => productName,
        },
        {
            text: 'lastUpdated',
            displayKey: 'lastUpdated',
            class: 'flex-1 mr-1',
            formatFunction: (date: string) => DateHelper.formatDate(date),
        },
        {
            text: 'lastUpdatedBy',
            displayKey: 'lastUpdatedBy',
            class: 'flex-1 mr-1',
            formatFunction: (user: string) => user,
        },
    ],
};

export default operationModeProductsTableHeader;
